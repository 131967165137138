import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Table from '../../Table/Table';
import header from '../../Table/HeaderWithSort';
import TableStyles from '../../Table/TableStyles';
import CellWithTitle from '../../Table/Cell';

import dateFormat from '../../../utils/dateFormat';
import { color } from '../../../constants';
import { FormSubTitle } from '../../FormComponents/TitleSection';

const withLoader = (WrappedComponent) => (
  class Loader extends React.PureComponent {
    render() {
      const {
        // eslint-disable-next-line react/prop-types
        isLoading, errorCode, dailyData, ...passThroughProps
      } = this.props;
      if (isLoading) return <FontAwesomeIcon icon="spinner" size="2x" spin style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }} />;
      if (errorCode || !dailyData) {
        return (
          <>
            <p>
              <FontAwesomeIcon icon="exclamation-triangle" style={{ color: color.darkorange }} />
              &nbsp;Cannot get statistics from&nbsp;
              <i>stats.eplane.com</i>
            </p>
          </>
        );
      }

      // eslint-disable-next-line react/prop-types
      const { inventory, repair } = dailyData;
      // eslint-disable-next-line react/prop-types
      if (inventory && !inventory.length && repair && !repair.length) {
        return (
          <p>
            No updates for today from&nbsp;
            <i>stats.eplane.com</i>
          </p>
        );
      }
      return <WrappedComponent dailyData={dailyData} {...passThroughProps} />;
    }
  }
);

const UserDailyStatus = ({ dailyData, ...props }) => {
  const { inventory, repair } = dailyData;

  const inventoryStatus = useMemo(
    () => ((inventory?.length !== 0)
      ? [
        {
          Header: () => header('Date'),
          accessor: 'date',
          Cell: ({ value }) => dateFormat(value, false),
        }, {
          Header: () => header('Seller'),
          accessor: 'seller',
          Cell: CellWithTitle,
        }, {
          Header: () => header('PN\'s'),
          accessor: 'part_numbers',
          Cell: CellWithTitle,
        }, {
          Header: () => header('DB lines'),
          accessor: 'db_lines',
          Cell: CellWithTitle,
        }, {
          Header: () => header('Items'),
          accessor: 'items',
          Cell: CellWithTitle,
        },
      ]
      : null
    ), [inventory],
  );

  const repairStatus = useMemo(
    () => ((repair?.length !== 0)
      ? [
        {
          Header: () => header('Date'),
          accessor: 'date',
          Cell: ({ value }) => dateFormat(value, false),
        }, {
          Header: () => header('Seller'),
          accessor: 'seller',
          Cell: CellWithTitle,
        }, {
          Header: () => header('PN\'s'),
          accessor: 'part_numbers',
          Cell: CellWithTitle,
        },
      ]
      : null
    ), [repair],
  );

  const formSubTitle = (inventory && repair && (inventory.length || repair.length)) ? (
    <FormSubTitle
      title="Daily Status"
      showEditButton={false}
    />
  ) : null;

  return (
    <div>
      {formSubTitle}
      {inventoryStatus && (
        <TableStyles>
          <Table columns={inventoryStatus} data={inventory} {...props} />
        </TableStyles>
      )}

      {repairStatus && (
        <TableStyles>
          <Table columns={repairStatus} data={repair} {...props} />
        </TableStyles>
      )}
    </div>
  );
};

UserDailyStatus.propTypes = {
  dailyData: PropTypes.shape({
    inventory: PropTypes.arrayOf(PropTypes.object),
    repair: PropTypes.arrayOf(PropTypes.object),
  }),
  inventory: PropTypes.arrayOf(PropTypes.shape({})),
  repair: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withLoader(UserDailyStatus);
