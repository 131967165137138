import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dompurify from 'dompurify';

import Img from '../../Img';
import { StyledLinkButton as LinkButton } from '../../StyledComponents';
import { color } from '../../../constants';

const A = styled.a`
  cursor: pointer;
  && {
    text-decoration: none;
  }
`;
const NewsBlock = styled.div`
  margin: 10px 10px;
  padding: 10px 10px;
  border: 1px solid ${(props) => (props.isVisible ? color.lightCyan : color.altoGrey)};
  border-radius: 3px;
  width: 100%;
  background-color: ${(props) => (props.isVisible ? color.lightBirch : color.whiteSmoke)};
  border-left: 3px solid ${(props) => (props.isVisible ? color.oracle : color.grey)};
`;
const BlogPostContainer = styled.div`
  box-sizing: content-box;
  display: block;
  min-height: 240px;
  position: relative;
`;
const Image = styled(Img)`
  height: 127px;
  padding-bottom: 23px;
  width: 435px;
  border-style: none;
  box-sizing: content-box;
`;
const BlogPostContent = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 435px;
`;
const Index = styled.div`
  margin-top: 20px;
  font-weight: bold;
  min-width: 20px;
`;
const NewsItem = styled.div`
  display: flex;
  max-width: 700px;
  padding-left: 15px;
  ${(props) => (props.isDragging && 'background-color: #FFCA8A;')}
  &&&&&:hover {
    .edit-news-button {
      visibility: visible;
    }
  }
`;
const Title = styled.p`
  color: #529fb1;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .8px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Subtitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 6px;
  p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #153a54;
    display: -webkit-box;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 1.38;
    margin: 0;
    overflow: hidden;
  }
`;
const EditButton = styled(LinkButton)`
  visibility: hidden;
`;
const HiddenBadge = styled.div`
  font-size: 14px;
  color: ${color.grey};
`;
const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const NewsList = ({ news, onRowClickHandler }) => (
  (news && news.map((v, i) => (
    <Draggable draggableId={v.news_id} index={i} key={v.news_id}>
      {(provided, draggableSnapshot) => (
        <NewsItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={draggableSnapshot.isDragging}
        >
          <Index>{`${i + 1}.`}</Index>
          <NewsBlock isVisible={v.is_visible}>
            <BlogPostContainer>
              <A target={v.target_type} rel="noopener noreferrer" to={`${v.link}`}>
                <BlogPostContent>
                  <Image src={v.image_url} alt={v.image_alt_text} />
                  <TitleBlock>
                    <Title><b>{v.title}</b></Title>
                    {!v.is_visible && <HiddenBadge>Hidden</HiddenBadge>}
                  </TitleBlock>
                  <Subtitle>
                    { /* eslint-disable-next-line react/no-danger */ }
                    <p dangerouslySetInnerHTML={{ __html: dompurify.sanitize(v.subtitle) }} />
                  </Subtitle>
                </BlogPostContent>
              </A>
            </BlogPostContainer>
          </NewsBlock>
          <EditButton className="edit-news-button" variant="link" onClick={() => onRowClickHandler(v.news_id)}>
            <FontAwesomeIcon icon="edit" size="lg" />
          </EditButton>
        </NewsItem>
      )}
    </Draggable>
  ))) || null
);

export default NewsList;
