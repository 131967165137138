import axios from 'axios';

import {
  COMPANY_LIST_FETCH,
  COMPANY_LIST_IS_FETCHED,
  COMPANY_LIST_ERROR_SET,
  COMPANY_LIST_ERROR_REMOVE,
} from '../constants';
import errorHandler from './requestErrorHandler';

const startFetchingCompanyList = () => ({
  type: COMPANY_LIST_FETCH,
});
const finishFetchingCompanyList = (companyList) => ({
  type: COMPANY_LIST_IS_FETCHED,
  payload: companyList,
});

export const setError = (error) => ({
  type: COMPANY_LIST_ERROR_SET,
  payload: error,
});

export const errorRemove = () => ({
  type: COMPANY_LIST_ERROR_REMOVE,
});

export const fetchCompanyList = () => (dispatch) => {
  dispatch(startFetchingCompanyList());

  axios('/rest/V1/eplane/backoffice/companies').then((response) => {
    const companies = response.data && response.data.sort(companyCompare);
    dispatch(finishFetchingCompanyList(companies));
  }).catch((e) => {
    errorHandler(e.response, dispatch, setError, e);
  });
};

const companyCompare = (a, b) => {
  if (!a.company_name) return 1;
  if (!b.company_name) return -1;
  return a.company_name.localeCompare(b.company_name);
};
