import axios from 'axios';

import {
  FILTER_LIST_GET,
  FILTER_LIST_GET_SUCCESS,

  FILTER_SAVE,
  FILTER_SAVE_SUCCESS,

  FILTER_REMOVE,
  FILTER_REMOVE_SUCCESS,

  TIME_ZONES_GET,
  TIME_ZONES_SUCCESS,
  AGGREGATION_EMAILS_SAVE,
  AGGREGATION_EMAILS_SAVE_SUCCESS,

  FILTERS_FAIL,

  FILTER_NOTIFICATION,
  FILTER_NOTIFICATION_SUCCESS,
  FILTER_NOTIFICATION_FAIL,

  FILTER_ERRORS_CLEAR,
  ERRORS,
} from '../../constants';
import errorHandler from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startFetchingFilters = () => ({
  type: FILTER_LIST_GET,
});
const fetchFiltersSuccess = (filters) => ({
  type: FILTER_LIST_GET_SUCCESS,
  payload: filters,
});

const startSavingFilter = () => ({
  type: FILTER_SAVE,
});
const saveFiltersSuccess = (filters) => ({
  type: FILTER_SAVE_SUCCESS,
  payload: filters,
});

const startRemovingFilter = () => ({
  type: FILTER_REMOVE,
});
const removeFilterSuccess = (filters) => ({
  type: FILTER_REMOVE_SUCCESS,
  payload: filters,
});

const filtersFail = (errorCode) => ({
  type: FILTERS_FAIL,
  payload: errorCode,
});

const changeNotification = () => ({
  type: FILTER_NOTIFICATION,
});
const changeNotificationSuccess = (filter) => ({
  type: FILTER_NOTIFICATION_SUCCESS,
  payload: filter,
});
const changeNotificationFail = (errorCode) => ({
  type: FILTER_NOTIFICATION_FAIL,
  payload: errorCode,
});

const startFetchingTimeZones = () => ({
  type: TIME_ZONES_GET,
});
const fetchTimeZonesSuccess = (timeZones) => ({
  type: TIME_ZONES_SUCCESS,
  payload: timeZones,
});

const startSavingAggregationEmailsSettings = () => ({
  type: AGGREGATION_EMAILS_SAVE,
});
const saveAggregationEmailsSettingsSuccess = (settings) => ({
  type: AGGREGATION_EMAILS_SAVE_SUCCESS,
  payload: settings,
});

export const removeFilterError = () => ({
  type: FILTER_ERRORS_CLEAR,
});

export const fetchFiltersList = (customerId) => async (dispatch) => {
  dispatch(startFetchingFilters());
  try {
    const response = await axios(`/rest/default/V1/eplane/backoffice/users/${customerId}/rfqFilters`);
    if (response.data.code === ERRORS.SUCCESS) dispatch(fetchFiltersSuccess(response.data));
    else errorHandler(response, dispatch, filtersFail);
  } catch (e) {
    errorHandler(e.response, dispatch, filtersFail, e);
  }
};

export const removeFilter = (customerId, filterId) => async (dispatch) => {
  dispatch(startRemovingFilter());
  try {
    const response = await axios.delete(`/rest/default/V1/eplane/backoffice/users/${customerId}/rfqFilter/${filterId}`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(removeFilterSuccess(response.data));
      dispatch(updateComments());
    } else errorHandler(response, dispatch, filtersFail);
  } catch (e) {
    errorHandler(e.response, dispatch, filtersFail, e);
  }
};

export const saveFilter = (data, customerId, filterId) => async (dispatch) => {
  dispatch(startSavingFilter());

  const body = {
    request: data,
  };
  const path = filterId
    ? `/rest/default/V1/eplane/backoffice/users/${customerId}/rfqFilter/${filterId}`
    : `/rest/default/V1/eplane/backoffice/users/${customerId}/rfqFilter`;
  try {
    const response = await axios.post(path, body);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveFiltersSuccess(response.data));
      dispatch(updateComments());
    } else errorHandler(response, dispatch, filtersFail);
  } catch (e) {
    errorHandler(e.response, dispatch, filtersFail, e);
  }
};

export const notificationToggle = (enabled, customerId, filterId) => async (dispatch) => {
  dispatch(changeNotification());

  const body = {
    request: {
      enabled,
    },
  };
  try {
    const response = await axios.post(`/rest/default/V1/eplane/backoffice/users/${customerId}/rfqFilter/${filterId}/toggle`, body);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(changeNotificationSuccess(response.data));
      dispatch(updateComments());
    } errorHandler(response, dispatch, changeNotificationFail);
  } catch (e) {
    errorHandler(e.response, dispatch, changeNotificationFail, e);
  }
};

export const fetchTimeZones = () => async (dispatch) => {
  dispatch(startFetchingTimeZones());
  try {
    const response = await axios('/rest/default/V2/eplane/backoffice/time-zone-list');
    if (response.data.code === ERRORS.SUCCESS) dispatch(fetchTimeZonesSuccess(response.data.payload && response.data.payload.time_zones));
    else errorHandler(response, dispatch, filtersFail);
  } catch (e) {
    errorHandler(e.response, dispatch, filtersFail, e);
  }
};

export const saveAggregationEmailsSettings = (customerId, data) => async (dispatch) => {
  dispatch(startSavingAggregationEmailsSettings());

  try {
    const response = await axios.post(`/rest/V2/eplane/backoffice/${customerId}/email-aggregation-settings`, data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveAggregationEmailsSettingsSuccess(response.data.payload));
      dispatch(updateComments());
    } else errorHandler(response, dispatch, filtersFail);
  } catch (e) {
    errorHandler(e.response, dispatch, filtersFail, e);
  }
};
