import React from 'react';
import { Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import UniversalFormControl from '../../FormComponents/UniversalControl';
import FormTitleSection from '../../FormComponents/TitleSection';
import FormWrapper from '../../FormComponents/Wrapper';
import FormActionButtons from '../../FormComponents/ActionButtons';
import ConfirmLeaveModal from '../../Modals/ConfirmLeaveModal';
import diffMapper from '../../../utils/diffMapper';
import { TabContainer } from '../../StyledComponents';
import { getUserStore, getCountries } from '../../../selectors';
import { saveUser } from '../../../actions/userManagement/userActions';
import getCountryRegionLists from '../../../utils/getCountryRegionLists';
import {
  DEFAULT_COUNTRY_VALUE_SELECT,
  DEFAULT_REGION_VALUE_SELECT,
} from '../../../constants';
import billingValidators from './billingValidators';

class BillingAddressForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      showConfirmModal: false,
      isChanged: false,
      user: {
        street: '',
        city: '',
        country_id: '',
        zip_code: '',
        state: '',
        region_id: '',
        phone: '',
      },
      validationState: {},
    };
  }

  onChangeEditMode = () => {
    this.setState((prevState) => {
      if (prevState.editMode) {
        return { editMode: false, isChanged: false };
      }
      const { userData: { user } } = this.props;
      return {
        editMode: true,
        user: {
          street: user.street,
          city: user.city,
          country_id: user.country_id,
          zip_code: user.zip_code,
          state: user.state,
          region_id: user.region_id,
          phone: user.phone,
        },
      };
    });
  };

  onEditButtonClick = () => {
    const {
      editMode,
      isChanged,
    } = this.state;

    if (editMode && isChanged) {
      this.setState({ showConfirmModal: true });
    } else {
      this.onChangeEditMode();
    }
  };

  onUserChangeHandler = (e) => {
    const { value, name } = e.target;

    this.setState((prevState, props) => {
      const user = { ...prevState.user };
      let { validationState } = prevState;
      user[name] = value;

      if (name === 'country_id') {
        user.region_id = props.regionList[value] ? DEFAULT_REGION_VALUE_SELECT : null;
      }

      if (billingValidators[name]) {
        validationState = { ...validationState };
        validationState[name] = billingValidators[name](value);
      }
      return { user, validationState };
    });
  };

  onChange = (e) => {
    const { isChanged } = this.state;
    if (!isChanged) {
      this.setState({ isChanged: true });
    }
    this.onUserChangeHandler(e);
  };

  onHideConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  };

  onSaveHandler = () => {
    const {
      userData: { user: propsUser },
      saveUser: saveUserData,
    } = this.props;
    const { user: stateUser } = this.state;
    if (!propsUser) return;

    const newValidationState = this.getValidationObj();

    if (Object.values(newValidationState).some((value) => !!value)) {
      // error
      this.setState({ validationState: newValidationState });
    } else {
      const userChanges = diffMapper(stateUser, propsUser);
      if (userChanges.country_id && !userChanges.region_id) {
        userChanges.region_id = null;
      } else if (userChanges.region_id && !userChanges.country_id) {
        userChanges.country_id = stateUser.country_id;
      }
      saveUserData(propsUser.user_id, userChanges);
      this.onChangeEditMode();
    }
  };

  getValidationObj = () => Object.keys(billingValidators).reduce((validationState, fieldName) => {
    const { user } = this.state;
    // eslint-disable-next-line no-param-reassign
    validationState[fieldName] = billingValidators[fieldName](user[fieldName]);
    return validationState;
  }, {});

  render() {
    const {
      userData: { isSaving, user: userPr },
      regionList,
      countriesList,
    } = this.props;
    const {
      user: userSt,
      validationState,
      showConfirmModal,
      editMode,
    } = this.state;
    const user = editMode || isSaving ? userSt : userPr;
    const {
      street,
      city,
      country_id: countryId,
      zip_code: zipCode,
      state,
      region_id: regionId,
      phone,
    } = user || {};

    let regionContent;

    if (regionList[countryId]) {
      regionContent = (
        <UniversalFormControl
          labelText="State/Province (optional)"
          value={(regionId && regionId.toString()) || ''}
          name="region_id"
          as="select"
          optionsForSelect={regionList[countryId]}
          onChange={this.onChange}
          disabled={!editMode}
          feedBack={false}
          {...validationState.region_id}
        />
      );
    } else {
      regionContent = (
        <UniversalFormControl
          labelText="State/Province (optional)"
          value={state || ''}
          name="state"
          onChange={this.onChange}
          disabled={!editMode}
        />
      );
    }

    return (
      <FormWrapper>
        <FormTitleSection title="Billing Address" EditButtonClick={this.onEditButtonClick} showEditButton={!editMode} />
        <ConfirmLeaveModal show={showConfirmModal} onLeave={this.onChangeEditMode} onCancel={this.onHideConfirmModal} />
        <TabContainer>
          <Form>
            <Col sm={6}>
              <UniversalFormControl
                labelText="Street address"
                value={street || ''}
                name="street"
                onChange={this.onChange}
                disabled={!editMode}
                {...validationState.street}
              />

              <UniversalFormControl
                labelText="City"
                value={city || ''}
                name="city"
                onChange={this.onChange}
                disabled={!editMode}
                {...validationState.city}
              />

              <UniversalFormControl
                labelText="Country"
                value={countryId || ''}
                name="country_id"
                onChange={this.onChange}
                disabled={!editMode}
                optionsForSelect={countriesList}
                as="select"
                feedBack={false}
                {...validationState.country_id}
              />

              {regionContent}

              <UniversalFormControl
                labelText="Zip code"
                value={zipCode || ''}
                name="zip_code"
                onChange={this.onChange}
                disabled={!editMode}
                {...validationState.zip_code}
              />

              <UniversalFormControl
                labelText="Phone"
                value={phone || ''}
                name="phone"
                onChange={this.onChange}
                disabled={!editMode}
                {...validationState.phone}
              />

              {
                editMode
                && <FormActionButtons saveHandler={this.onSaveHandler} cancelHandler={this.onChangeEditMode} />
              }

            </Col>
            <Col sm={6} />
          </Form>
        </TabContainer>
      </FormWrapper>
    );
  }
}

BillingAddressForm.propTypes = {

  userData: PropTypes.shape({
    user: PropTypes.shape({
      street: PropTypes.string,
      city: PropTypes.string,
      country_id: PropTypes.string,
      zip_code: PropTypes.string,
      state: PropTypes.string,
      region_id: PropTypes.number,
      phone: PropTypes.string,
      user_id: PropTypes.string,
    }),
    isSaving: PropTypes.bool,
  }),
  countriesList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  regionList: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.array)),
  saveUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    countriesList,
    regionList,
  } = getCountryRegionLists(getCountries(state) || []);

  const newRegionList = processRegionList(regionList);
  const newCountryList = countriesList.slice();

  newCountryList.unshift([DEFAULT_COUNTRY_VALUE_SELECT, DEFAULT_COUNTRY_VALUE_SELECT]);

  return {
    userData: getUserStore(state),
    countriesList: newCountryList,
    regionList: newRegionList,
  };
}

export default connect(
  mapStateToProps, { saveUser },
)(BillingAddressForm);

const processRegionList = (regionList) => {
  const newRegionList = {};

  Object.keys(regionList).forEach((countryId) => {
    newRegionList[countryId] = [[DEFAULT_REGION_VALUE_SELECT, DEFAULT_REGION_VALUE_SELECT]];
    newRegionList[countryId] = newRegionList[countryId].concat(regionList[countryId]);
  });

  return newRegionList;
};
