import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const NameCol = styled.dt`
  font-weight: bold;
`;
const ValueCol = styled.dd`
  white-space: normal;
`;

const ExpandComponent = ({ row }) => (
  <dl>
    <div>
      <NameCol sm={3}>Delivery status description:</NameCol>
      <ValueCol>{row.delivery_status_description}</ValueCol>
    </div>
    <div>
      <NameCol sm={3}>Delivery status message:</NameCol>
      <ValueCol>{row.delivery_status_message}</ValueCol>
    </div>
    <div>
      <NameCol sm={3}>Envelope sending ip:</NameCol>
      <ValueCol>{row.envelope_sending_ip}</ValueCol>
    </div>
    <div>
      <NameCol sm={3}>Data event:</NameCol>
      <ValueCol>{row.event_data_event}</ValueCol>
    </div>
    <div>
      <NameCol sm={3}>Event data id:</NameCol>
      <ValueCol>{row.event_data_id}</ValueCol>
    </div>
    <div>
      <NameCol sm={3}>Event data log level:</NameCol>
      <ValueCol>{row.event_data_log_level}</ValueCol>
    </div>
    <div>
      <NameCol sm={3}>Event data reason:</NameCol>
      <ValueCol>{row.event_data_reason}</ValueCol>
    </div>
    <div>
      <NameCol sm={3}>Event data recipient domain:</NameCol>
      <ValueCol>{row.event_data_recipient_domain}</ValueCol>
    </div>
    <div>
      <NameCol sm={3}>Header from:</NameCol>
      <ValueCol>{row.header_from}</ValueCol>
    </div>
    <div>
      <NameCol sm={3}>Header to:</NameCol>
      <ValueCol>{row.header_to}</ValueCol>
    </div>
    <div>
      <NameCol sm={3}>Header message id:</NameCol>
      <ValueCol>{row.header_message_id}</ValueCol>
    </div>
    <div>
      <NameCol sm={3}>Header subject:</NameCol>
      <ValueCol>{row.header_subject}</ValueCol>
    </div>
  </dl>
);

ExpandComponent.propTypes = {
  row: PropTypes.shape({
    delivery_status_description: PropTypes.string,
    delivery_status_message: PropTypes.string,
    envelope_sending_ip: PropTypes.string,
    event_data_event: PropTypes.string,
    event_data_id: PropTypes.string,
    event_data_log_level: PropTypes.string,
    event_data_reason: PropTypes.string,
    event_data_recipient_domain: PropTypes.string,
    header_from: PropTypes.string,
    header_to: PropTypes.string,
    header_message_id: PropTypes.string,
    header_subject: PropTypes.string,
  }),
};

export default ExpandComponent;
