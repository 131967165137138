import axios from 'axios';

import {
  MAILCHIMP_CONDITIONS_GET,
  MAILCHIMP_CONDITIONS_SUCCESS,
  MAILCHIMP_COMMAND_ADD,
  MAILCHIMP_COMMAND_ADD_SUCCESS,
  MAILCHIMP_COMMAND_DELETE,
  MAILCHIMP_COMMAND_DELETE_SUCCESS,
  MAILCHIMP_PROCESS,
  MAILCHIMP_PROCESS_SUCCESS,
  MAILCHIMP_PROCESS_ERROR_GET,
  MAILCHIMP_PROCESS_ERROR_GET_SUCCESS,
  MAILCHIMP_PROCESS_FAIL,

  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';

const startFetchingMailchimpConditions = () => ({ type: MAILCHIMP_CONDITIONS_GET });
const fetchMailchimpConditionsSuccess = (conditions) => ({
  type: MAILCHIMP_CONDITIONS_SUCCESS,
  payload: conditions,
});

const startAddCommand = () => ({ type: MAILCHIMP_COMMAND_ADD });
const addCommandSuccess = (command) => ({
  type: MAILCHIMP_COMMAND_ADD_SUCCESS,
  payload: command,
});

const startDeleteCommand = () => ({ type: MAILCHIMP_COMMAND_DELETE });
const deleteCommandSuccess = (commandId) => ({
  type: MAILCHIMP_COMMAND_DELETE_SUCCESS,
  payload: commandId,
});

const startProcess = () => ({ type: MAILCHIMP_PROCESS });
const processSuccess = (commandId) => ({
  type: MAILCHIMP_PROCESS_SUCCESS,
  payload: commandId,
});

const startFetchingErrorLog = () => ({ type: MAILCHIMP_PROCESS_ERROR_GET });
const fetchingErrorLogSuccess = (errors) => ({
  type: MAILCHIMP_PROCESS_ERROR_GET_SUCCESS,
  payload: errors,
});

const processFail = () => ({ type: MAILCHIMP_PROCESS_FAIL });

export const fetchMailchimpConditions = () => async (dispatch) => {
  dispatch(startFetchingMailchimpConditions());
  try {
    const response = await axios('/rest/default/V2/eplane/backoffice/mailchimp/conditions');
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchMailchimpConditionsSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, processFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, processFail, e);
  }
};

export const addMailchimpCommand = (data) => async (dispatch) => {
  dispatch(startAddCommand());
  try {
    const response = await axios.post('/rest/default/V2/eplane/backoffice/mailchimp/condition', data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(addCommandSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, processFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, processFail, e);
  }
};

export const deleteMailchimpCommand = (conditionId) => async (dispatch) => {
  dispatch(startDeleteCommand());
  try {
    const response = await axios.delete(`/rest/default/V2/eplane/backoffice/mailchimp/condition/${conditionId}`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(deleteCommandSuccess(conditionId));
    } else {
      requestErrorHandler(response, dispatch, processFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, processFail, e);
  }
};

export const processCommands = (data) => async (dispatch) => {
  dispatch(startProcess());
  try {
    const response = await axios.post('/rest/default/V2/eplane/backoffice/mailchimp/apply', data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(processSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, processFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, processFail, e);
  }
};

export const fetchErrorLog = (batchId) => async (dispatch) => {
  dispatch(startFetchingErrorLog());
  try {
    const response = await axios(`/rest/default/V2/eplane/backoffice/mailchimp/syncronizationErrors/${batchId}`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchingErrorLogSuccess(response.data && response.data.payload && response.data.payload.sync_errors));
    } else {
      requestErrorHandler(response, dispatch, processFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, processFail, e);
  }
};
