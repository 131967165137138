import styled from 'styled-components';

const FormWrapper = styled.div`
  &&& {
    margin: 25px auto;
    overflow: hidden;
  }
`;

export default FormWrapper;
