import React from 'react';
import styled from 'styled-components';
import Autosuggest from 'react-autosuggest';
import * as consts from '../../../constants';

const AutosuggestStyled = styled.div`

&& .react-autosuggest__suggestions-container {
  position: relative;
}
&& .react-autosuggest__suggestions-list {
  z-index: 99;
  position: absolute;
  list-style-type: none;
  background-color: white;
  width: 100%;
  padding-left: 0px;  

  border: 1px solid ${consts.color.inputBorder};
  border-top: 0px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}

&& .react-autosuggest__suggestions-list li {
  padding-left: 12px;
}


&& .react-autosuggest__suggestion--highlighted {
  background-color: #E0E0E0;
  cursor: pointer;
}
`;

export default function (props) {
  return (
    <AutosuggestStyled>
      <Autosuggest {...props} />
    </AutosuggestStyled>
  );
}
