import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SimpleLoader from '../../SimpleLoader';
import A from '../../A';
import EmailStatusMark from '../../EmailStatusMark';
import * as constants from '../../../constants/index';

const Wrapper = styled.div`
  font-size: 14px;
`;

const ListWrapper = styled.ul`
  max-height: 350px;
  overflow: auto;
`;

const InfoContent = (props) => {
  const {
    isLoading,
    isCompanyUser,
    currentUserMail,
    currentEmailStatus,
    currentCompanyName,
    currentUsers,
  } = props;
  let companyContent = null;

  if (isLoading) return <SimpleLoader />;

  if (isCompanyUser) {
    const userList = currentUsers && currentUsers.map((u) => (
      <li key={u.entity_id}>
        <A to={`/userManagement/${u.user_id}/${u.entity_id}/associateUser`} target="_blank">{u.email}</A>
      </li>
    ));
    companyContent = (
      <div>
        <div>
          <strong>Current Company name:</strong>
          &nbsp;
          {currentCompanyName}
        </div>
        <div>
          <strong>
            {currentCompanyName}
            &apos;s
          </strong>
          &nbsp;
          users:
        </div>
        <ListWrapper>
          {userList}
        </ListWrapper>
      </div>
    );
  } else {
    companyContent = <div>This User doesn&apos;t belong to a Company.</div>;
  }

  return (
    <Wrapper>
      <div>
        <strong>Current User:</strong>
        &nbsp;
        {currentUserMail}
        <EmailStatusMark
          statusId={currentEmailStatus}
          customerEmailStatusConstants={constants && constants.customerEmailStatus}
          withDescription
        />
      </div>
      {companyContent}
    </Wrapper>
  );
};

InfoContent.propTypes = {
  isLoading: PropTypes.bool,
  isCompanyUser: PropTypes.bool,
  currentUserMail: PropTypes.string,
  currentEmailStatus: PropTypes.number,
  currentCompanyName: PropTypes.string,
  currentUsers: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

export default InfoContent;
