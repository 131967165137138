import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { Text, Button } from '../StyleComponents';

const SuccessModal = ({ onHide, text, ...other }) => (
  <Modal centered onHide={onHide} {...other}>
    <Modal.Body>
      <Text>
        Upload completed!
      </Text>
      <br />
      <p>{text}</p>
      <br />
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onHide}>Close</Button>
    </Modal.Footer>
  </Modal>
);

SuccessModal.propTypes = {
  text: PropTypes.string,
  now: PropTypes.number,
  onHide: PropTypes.func,
};
export default SuccessModal;
