import createErrorValidatorObj from '../../../utils/createErrorValidationObj';
import { units as unitsConst } from '../../../constants/index';

export default {
  qty: checkQty,
  taxes: checkPriceField,
  price_total: checkMandatoryPrice,
  price_per_unit: checkMandatoryPrice,
  lead_time: checkLeadTimeField,
  valid_for_value: checkLeadTimeField,
  warranty: checkPositiveWholeNumber,

  description: () => null,
  part_number: (value) => (!value ? createErrorValidatorObj('Please enter a part number') : null),
  tagged_by: () => null,
  comment: () => null,
  minimum_order_value: () => null,

  place: checkPickupPlace,
};

export function checkNonNegativeNumber(value) {
  if (value === '') return null;
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal <= 0) {
    return createErrorValidatorObj('Please enter a positive number');
  }
  return null;
}

export function checkPositiveWholeNumber(value) {
  if (value === '') return null;
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal < 1 || !Number.isInteger(numVal)) {
    return createErrorValidatorObj('Please enter a positive, whole number');
  }
  return null;
}

export function checkPriceField(value) {
  if (value === '') return null;
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal < 0) {
    return createErrorValidatorObj('Please enter a positive number');
  }
  if (numVal < 0.001 || numVal > 10000000) {
    return createErrorValidatorObj('Price range is $0.001-10,000,000');
  }
  return null;
}

export function checkMandatoryPrice(value) {
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal < 0) {
    return createErrorValidatorObj('Please enter a positive number');
  }
  if (numVal < 0.001 || numVal > 1000000) {
    return createErrorValidatorObj('Price range is $0.001-1,000,000');
  }
  return null;
}

export function checkEvaluationFeeField(value) {
  if (value === '') return null;
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal < 0) {
    return createErrorValidatorObj('Please enter a positive number');
  }
  if (numVal > 10000000) {
    return createErrorValidatorObj('The price of this item exceeds the $10,000,000 maximum');
  }
  return null;
}

export function checkPickupPlace(value) {
  if (value === null || ((value !== '') && !value.place_id)) {
    return createErrorValidatorObj('Please select a valid address');
  }
  return null;
}

export function checkLeadTimeField(value) {
  if (value === '') return null;
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal < 1 || !Number.isInteger(numVal)) {
    return createErrorValidatorObj('Please enter a positive, whole number');
  }
  if (numVal > 9999) {
    return createErrorValidatorObj('Please enter a positive, whole number, up to 9999');
  }
  return null;
}

export function checkQty(qty, units) {
  const numVal = +qty;
  // eslint-disable-next-line no-restricted-globals
  if (units === unitsConst.EACH.id && (isNaN(numVal) || numVal <= 0 || !Number.isInteger(numVal))) {
    return createErrorValidatorObj('Please enter a positive, whole number');
  }
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal <= 0) {
    return createErrorValidatorObj('Please enter a positive number');
  }
  return null;
}
