import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const statusFormat = (cell, reqResultsConst) => (
  <div style={{ textAlign: 'center' }}>
    {(cell === reqResultsConst.APPROVED.id) ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="times" />}
  </div>
);

export default statusFormat;
