import React from 'react';
import { connect } from 'react-redux';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { color } from '../../../constants';
import dateFormat from '../../../utils/dateFormat';
import { getChatStore } from '../../../selectors';
import { getPN } from '../../../reducers/orderManagement/chatSelectors';
import { userType, tier, dealTypes } from '../../../constants/index';
import { orderIconType } from '../../../constants/operationCenter';

const HeaderContainer = styled.div`
  padding-bottom: 9px;
  border-bottom: 3px solid ${color.lightGrey};
  margin-bottom: 20px;
`;
const HeaderText = styled.h1`
  font-size: 22px;
  font-weight: bold;
  color: ${color.blue};
  letter-spacing: 0.55px;
  margin: 0 0 10px;
  padding-right: 140px;
  line-height: normal;
  overflow-wrap: break-word;
  max-width: 1000px;
`;
const LevelTwoDetails = styled.div`
  font-size: 18px;
  margin-bottom: 4px;
`;
const GreyedOut = styled.span`
  color: ${color.grey};
`;
const Row = styled.div`
  & > div {
    display: inline-block;
    font-size: 14px;

    margin-left: 8px;
    padding-left: 9px;
    position: relative;
  }

  & > div:first-child {
    margin-left: 0;
    padding-left: 0;
  }

  & > div:not(:first-child)::before {
    content: '|';
    color: ${color.grey};
    display: block;
    position: absolute;
    left: -1px;
    top: 0;
  }
`;
const NotVerifiedBadge = styled.span`
  color: ${color.grey};
  font-size: 12px;
  padding: 3px 5px;
  border: 1px solid ${color.grey};
`;
const VerifiedBadge = styled.span`
  color: #3cb878;
  border: 1px solid #3cb878;
  font-size: 12px;
  padding: 3px 5px;
`;

const getCustomerStatusBadge = (isSeller, isSellerVerified) => {
  if (isSellerVerified) {
    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={(
          <Popover id="popover-trigger-verified-company">
            <Popover.Content>Verified as a company by ePlane</Popover.Content>
          </Popover>
        )}
      >
        <VerifiedBadge>
          <FontAwesomeIcon icon="check" />
          &nbsp;
          {
            isSeller
              ? 'Verified seller'
              : 'Verified buyer'
          }
        </VerifiedBadge>
      </OverlayTrigger>
    );
  }
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={(
        <Popover id="popover-trigger-not-verified-company">
          <Popover.Content>Company is not verified by ePlane</Popover.Content>
        </Popover>
      )}
    >
      <NotVerifiedBadge>
        {
          isSeller
            ? 'Non verified seller'
            : 'Non verified buyer'
        }
      </NotVerifiedBadge>
    </OverlayTrigger>
  );
};

const getOrderIcon = (s) => {
  if (s === orderIconType.SYNCED) {
    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={(
          <Popover id="popover-trigger-erp">
            <Popover.Content>ERP synced inventory</Popover.Content>
          </Popover>
        )}
      >
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon="circle" color={color.iconGreen} />
          <FontAwesomeIcon icon="sync-alt" inverse transform="shrink-6 rotate-90 flip-v" />
        </span>
      </OverlayTrigger>
    );
  }

  if (s === orderIconType.NOT_SYNCED) {
    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        delay={{ show: 250, hide: 450 }}
        overlay={(
          <Popover id="popover-trigger-smart-recommendation">
            <Popover.Content>
              This inquiry was sent according to ePlane smart algorithm recommendation
            </Popover.Content>
          </Popover>
        )}
      >
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon="circle" color={color.silverGrey} />
          <FontAwesomeIcon icon="sync-alt" inverse transform="shrink-6 rotate-90 flip-v" />
        </span>
      </OverlayTrigger>
    );
  }

  if (s === orderIconType.REPAIR) {
    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={(
          <Popover id="popover-trigger-repair-capability">
            <Popover.Content>Repair capability</Popover.Content>
          </Popover>
        )}
      >
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon="circle" color={color.darkorange} />
          <FontAwesomeIcon icon="wrench" inverse transform="shrink-6" />
        </span>
      </OverlayTrigger>
    );
  }
  return null;
};

const ChatHeader = ({
  buyerCompany,
  buyerOrderNo,
  buyerContact,
  buyerOrderPosition,
  pn,
  sellerCompany,
  sellerContact,
  sellerOrderNo,
  sellerOrderPosition,
  isSellerVerified,
  isBuyerVerified,
  orderIcon,
  issueData,
  orderId,
}) => {
  let buyerName;

  if (buyerCompany) {
    buyerName = `${buyerCompany} (${buyerContact})`;
  } else {
    buyerName = buyerContact;
  }
  const notAvailableContent = <GreyedOut>Not available</GreyedOut>;

  return (
    <HeaderContainer>
      <HeaderText>
        {getOrderIcon(orderIcon)}
        Inquiry for PN&nbsp;
        {pn}
      </HeaderText>

      <LevelTwoDetails>
        <div>
          <strong>Seller:</strong>
          &nbsp;
          {sellerCompany}
          &nbsp;
          {sellerContact !== ' ' && sellerContact !== '' && `(${sellerContact})`}
          &nbsp;
          {getCustomerStatusBadge(true, isSellerVerified)}
        </div>
        <div>
          <strong>Buyer:</strong>
          &nbsp;
          {buyerName}
          &nbsp;
          {getCustomerStatusBadge(false, isBuyerVerified)}
        </div>
      </LevelTwoDetails>

      <Row>
        <div>
          <strong>Issue date:</strong>
          &nbsp;
          {dateFormat(issueData)}
        </div>
        <div>
          <strong>
            ePlane&nbsp;
            <i>#</i>
            &nbsp;:
          </strong>
          &nbsp;
          {orderId}
        </div>
      </Row>

      <Row>
        <div>
          <strong>
            {`Seller (${sellerCompany}) order `}
            <i>#</i>
            &nbsp;:
          </strong>
          &nbsp;
          {sellerOrderNo || notAvailableContent}
          <strong> Position: </strong>
          {sellerOrderPosition || notAvailableContent}
        </div>
        <div>
          <strong>
            {`Buyer (${buyerCompany || 'no company'}) order `}
            <i>#</i>
            &nbsp;:
          </strong>
          &nbsp;
          {buyerOrderNo || notAvailableContent}
          <strong> Position: </strong>
          {buyerOrderPosition || notAvailableContent}
        </div>
      </Row>
    </HeaderContainer>
  );
};

ChatHeader.propTypes = {
  pn: PropTypes.string,

  sellerCompany: PropTypes.string,
  sellerContact: PropTypes.string,
  sellerOrderNo: PropTypes.string,
  sellerOrderPosition: PropTypes.number,
  isSellerVerified: PropTypes.bool,
  isBuyerVerified: PropTypes.bool,

  buyerCompany: PropTypes.string,
  buyerContact: PropTypes.string,
  buyerOrderNo: PropTypes.string,
  buyerOrderPosition: PropTypes.number,

  orderIcon: PropTypes.string,
  orderId: PropTypes.string,
  issueData: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { order, messages } = getChatStore(state);
  if (!order) return {}; // Fixes unnecessary trigger of [mapStateToProps] during routing

  const {
    buyer, seller, customer,
  } = order;
  const lastSeller = (Array.isArray(messages)
    && messages.slice().reverse().find((e) => e.creator_type === 'customer' && e.user_id !== buyer.user_id)) || {};
  const { sender_info: lastSellerSender = '' } = lastSeller;

  let orderIcon = orderIconType.NOT_SYNCED;
  if (order.deal_type_list?.includes(dealTypes.REPAIR.id)) orderIcon = orderIconType.REPAIR;
  else if (order.tier === tier.TIER_1) orderIcon = orderIconType.SYNCED;

  return {
    pn: getPN(order),

    sellerCompany: seller.company_name,
    sellerContact: (lastSellerSender && `${lastSellerSender.firstname ?? ''} ${lastSellerSender.lastname ?? ''}`) || seller.contact_name,
    sellerOrderNo: order.seller_internal_order_number,
    sellerOrderPosition: order.seller_internal_order_position,
    isSellerVerified: !seller.is_external && seller.user_type === userType.COMPANY.id,
    isBuyerVerified: !buyer.is_external && buyer.user_type === userType.COMPANY.id,
    buyerCompany: buyer.company_name,
    buyerContact: `${customer.firstname || ''} ${customer.lastname || ''}`,
    buyerOrderNo: order.buyer_internal_order_number,
    buyerOrderPosition: order.buyer_internal_order_position,

    orderIcon,
    orderId: order.increment_id,
    issueData: order.created_at,
  };
};

export default connect(mapStateToProps)(ChatHeader);
