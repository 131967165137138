import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import Select from 'react-select';

import {
  uploadDemandList, resetUploadingProgress, setUploadingErrorCode, removeErrorCode,
} from '../../../actions/companiesManagement/demandListActions';
import { fetchCompanyCustomers, removeCompanyCustomersError } from '../../../actions/companiesManagement/activeCompanyCustomersActions';
import { fetchUserSettings } from '../../../actions/companiesManagement/userSettingsActions';
import { getAgentOptions } from '../../../reducers/userManagement/filterSelector';
import A from '../../A';
import { ButtonContainer, HorizontalLine } from '../StyleComponents';
import { TabContainer } from '../../StyledComponents';
import FormTitleSection from '../../FormComponents/TitleSection';
import { getDemandListStore, getUserSettingsStore, getActiveCompanyCustomersStore } from '../../../selectors';
import { dropezoneContent, filledDropezoneContent } from '../UploadInventory/dropezoneContent';
import ProgressModal from '../../Modals/ProgressModal';
import SuccessModal from '../UploadInventory/SuccessModal';
import ErrorModalsForDemandSection from './ErrorModalsForDemandSection';
import Loader from '../../Loader';
import { color, PUBLIC_URL, ERRORS } from '../../../constants';
import BaseFormGroup from '../../FormComponents/BaseFormGroup/index';
import createErrorValidationObj from '../../../utils/createErrorValidationObj';

const PageContainer = styled.div`
  padding-bottom: 30px;
`;
const WarningMessage = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: ${color.textRed};
  & a {
    text-decoration: underline;
    color: ${color.darkBirch};
  }
  & a:hover {
    color: ${color.birch};
  }
`;
const Container = styled.div`
  margin: 0 0 15px 0;
`;

const commentValidator = (value) => {
  const newValue = value && value.trim();
  if (!newValue) return null;
  if (newValue.length > 50) return createErrorValidationObj('Please enter a maximum of 50 characters');
  return null;
};

export default function DemandListPage() {
  const { userId } = useParams();
  const dispatch = useDispatch();

  const [upload, setUpload] = useState({
    demandFiles: [],
    customer: [],
    comment: '',
  });

  const userSettings = useSelector(getUserSettingsStore);
  const activeCompanyCustomersData = useSelector(getActiveCompanyCustomersStore);
  const { uploadingProgress, isUploading, errorCode: demandError } = useSelector(getDemandListStore);

  const activeCompanyCustomers = getAgentOptions(activeCompanyCustomersData.customers);
  const { isFetching } = activeCompanyCustomersData.isFetching;
  const autopilotEnabled = userSettings && userSettings.data && userSettings.data.autopilot_enabled;
  const errorCode = activeCompanyCustomers.errorCode || demandError;

  useEffect(() => {
    dispatch(fetchCompanyCustomers(userId));
    dispatch(fetchUserSettings(userId));
  }, [dispatch, userId]);

  const onChangeCustomer = (selectedOption, action) => {
    setUpload((prev) => ({ ...prev, [action.name]: selectedOption }));
  };

  const onChangeComment = (e) => {
    const { name, value } = e.target;

    setUpload((prev) => {
      const newData = { ...prev };
      newData[name] = value;
      return newData;
    });
  };

  const onDocumentDrop = (files) => {
    setUpload((prev) => ({ ...prev, demandFiles: [...files] }));
  };

  const onUploadClick = () => {
    const { demandFiles, customer, comment } = upload;
    const formatComment = comment.trim();
    dispatch(uploadDemandList(demandFiles[0], userId, customer.value, formatComment));
  };

  const onDocumentRemove = () => {
    setUpload((prev) => ({ ...prev, demandFiles: [] }));
  };

  const uploadingSuccess = () => {
    dispatch(resetUploadingProgress());
    setUpload({
      demandFiles: [],
      customer: [],
      comment: '',
    });
  };

  const uploadingFail = () => {
    removeErrors();
    uploadingSuccess();
  };

  const onDropRejected = () => {
    dispatch(setUploadingErrorCode(ERRORS.ERR_WRONG_FORMAT));
  };

  const removeErrors = () => {
    dispatch(removeErrorCode());
    dispatch(removeCompanyCustomersError());
  };

  const { demandFiles, customer, comment } = upload;

  const showSuccessModal = !isUploading && uploadingProgress === 100;
  const uploadDisabled = !autopilotEnabled || demandFiles.length === 0;
  let demandContent = null;

  if (isFetching || autopilotEnabled === null) return <Loader />;

  if (demandFiles.length > 0) {
    demandContent = filledDropezoneContent(demandFiles[0], onDocumentRemove);
  } else {
    demandContent = dropezoneContent('Drag file here', '(.xls / .xlsx / .csv)', !autopilotEnabled);
  }

  return (
    <PageContainer>
      <FormTitleSection title="Autopilot — Upload Demand List" showEditButton={false} />
      <TabContainer>
        {
          !autopilotEnabled
            ? (
              <WarningMessage>
                Uploading is disabled for this company.
                Go to the&nbsp;
                <A rel="noopener noreferrer" to={`${PUBLIC_URL}/companies/${userId}/userSettings`}>
                  Company Settings
                </A>
                &nbsp;to enable autopilot.
              </WarningMessage>
            )
            : null
        }
        <Row>
          <Col sm={4}>
            <Container>
              <Select
                value={customer}
                name="customer"
                placeholder="Choose customer..."
                onChange={onChangeCustomer}
                options={activeCompanyCustomers}
                hideSelectedOptions={false}
                isDisabled={!autopilotEnabled}
              />
            </Container>
          </Col>
        </Row>
        <Dropzone
          onDrop={onDocumentDrop}
          disabled={(customer.length === 0)}
          accept=".xls,.xlsx,.csv"
          onDropRejected={onDropRejected}
          multiple={false}
          disableClick
        >
          {demandContent}
        </Dropzone>
        <HorizontalLine color={color.darkBlue} />
        <Row>
          <Col sm={4}>
            <BaseFormGroup
              labelText="Internal comment:"
              name="comment"
              disabled={(customer.length === 0)}
              value={comment}
              onChange={onChangeComment}
              {...commentValidator(comment)}
            />
          </Col>
        </Row>
        <ButtonContainer btnWidth="120px">
          <Button onClick={onUploadClick} variant="primary" disabled={uploadDisabled}>Upload</Button>
        </ButtonContainer>

        <ErrorModalsForDemandSection
          errorCode={errorCode}
          onHide={uploadingFail}
        />
        <ProgressModal show={isUploading} now={uploadingProgress} />
        <SuccessModal show={showSuccessModal} text="We are currently processing your Demand List." onHide={uploadingSuccess} />
      </TabContainer>
    </PageContainer>
  );
}
