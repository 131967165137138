import {
  BLOCK_APPROVED_SELLERS_GET,
  BLOCK_APPROVED_SELLERS_GET_SUCCESS,

  CUSTOM_SELLERS_LISTS_GET,
  CUSTOM_SELLERS_LISTS_SUCCESS,

  CUSTOM_SELLERS_LISTS_SAVE,
  CUSTOM_SELLERS_LISTS_SAVE_SUCCESS,

  CUSTOM_SELLERS_LISTS_DELETE,
  CUSTOM_SELLERS_LISTS_DELETE_SUCCESS,

  CUSTOM_SELLERS_LISTS_FAIL,
} from '../../constants';

const initialState = {
  approvedBlockedList: null,
  items: null,
  isFetching: false,
  isApprovedBlockedListFetching: false,
  isProcessing: false,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case BLOCK_APPROVED_SELLERS_GET:
      return {
        ...state,
        isApprovedBlockedListFetching: true,
      };
    case BLOCK_APPROVED_SELLERS_GET_SUCCESS:
      return {
        ...state,
        approvedBlockedList: payload || [],
        isApprovedBlockedListFetching: false,
      };

    case CUSTOM_SELLERS_LISTS_GET:
      return {
        ...state,
        isFetching: true,
      };
    case CUSTOM_SELLERS_LISTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: payload,
      };

    case CUSTOM_SELLERS_LISTS_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case CUSTOM_SELLERS_LISTS_SAVE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
      };

    case CUSTOM_SELLERS_LISTS_DELETE:
      return {
        ...state,
        isProcessing: true,
      };
    case CUSTOM_SELLERS_LISTS_DELETE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        items: state.items && state.items.filter((v) => v.list_id !== payload),
        approvedBlockedList: state.approvedBlockedList && state.approvedBlockedList.list_id === payload ? {} : state.approvedBlockedList,
      };
    case CUSTOM_SELLERS_LISTS_FAIL:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
        isApprovedBlockedListFetching: false,
      };
    default: return state;
  }
};
