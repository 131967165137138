import {
  INCOMING_REQUESTS_FETCH,
  INCOMING_REQUESTS_ARE_FETCHED,
  INCOMING_REQUESTS_SEARCHING_START,
  INCOMING_REQUESTS_SEARCHING_FINISHED,
  INCOMING_REQUEST_ERROR_OCCURRED,
} from '../../constants';
import mapRequests from './mapRequests';

const initialState = {
  items: [],
  isSearching: false,
  isFetching: false,
  isSearchingMode: false,
};

export default function incomingRequestReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case INCOMING_REQUESTS_FETCH:
      return {
        ...state,
        isFetching: true,
        isSearchingMode: false,
      };
    case INCOMING_REQUESTS_ARE_FETCHED:
      return {
        ...state,
        items: payload,
        isFetching: false,
      };
    // in this case of starting searching payload is search string
    case INCOMING_REQUESTS_SEARCHING_START: return {
      ...state,
      isSearching: true,
      isSearchingMode: !!payload, /* search string */
    };
    case INCOMING_REQUESTS_SEARCHING_FINISHED:
      return {
        ...state,
        items: payload,
        isSearching: false,
      };
    case INCOMING_REQUEST_ERROR_OCCURRED:
      return {
        ...state,
        isFetching: false,
        isSearching: false,
      };

    default: return state;
  }
}

export function getIncomingRequestById(id, incomingRequestsState) {
  return incomingRequestsState.items.find((req) => req.entity_id === id);
}

export function getIncomingReqList(state) {
  return mapRequests(state.items);
}
