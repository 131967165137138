import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function RadioGroup({
  radioList,
  checkedValue,
  name,
  disabled: groupDisabled,
  validationState,
  ...other
}) {
  const list = radioList.map(({ value, labelText, disabled }) => (
    <Form.Check
      id={`custom-inline-${labelText}`}
      type="radio"
      key={value}
      value={value}
      checked={value === checkedValue}
      name={name}
      disabled={groupDisabled || disabled}
      label={labelText}
      isInvalid={validationState === 'error'}
      isValid={validationState === 'success'}
      {...other}
    />
  ));
  return (
    <>
      {list}
    </>
  );
}

const valuePT = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

RadioGroup.propTypes = {
  radioList: PropTypes.arrayOf(PropTypes.shape({
    value: valuePT,
    label: PropTypes.string,
  })),
  checkedValue: valuePT,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  validationState: PropTypes.oneOf(['success', 'warning', 'error', null]),
};
