import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinkButton, Icon } from './StyledComponents';

const sortOptions = [{
  label: 'Date', value: 'created_at',
}];
const sortDirectionOptions = {
  DEFAULT: { label: 'Default order', value: null },
  ASC: { label: 'Ascending order', value: 'asc' },
  DESC: { label: 'Descending order', value: 'desc' },
};

const SortDropDown = ({
  queryParams,
  taskListFetchingParams,

  fetchTasksListWithParams,
  changeTaskListFetchingParams,
}) => {
  const [sortState, setSortState] = useState({
    sortColumn: null,
    sortDirection: null,
  });

  useEffect(() => {
    setSortState({
      sortColumn: queryParams?.o && queryParams?.d
        ? sortOptions.find((v) => v.value === queryParams?.o)
        : null,
      sortDirection: queryParams?.d || null,
    });
  }, [queryParams, taskListFetchingParams]);

  const onSortChange = (name) => {
    let newParams = null;
    if (name === sortDirectionOptions.ASC.value) {
      newParams = {
        ...taskListFetchingParams,
        sortDirection: sortDirectionOptions.DESC.value,
        sortColumn: sortOptions[0].value,
      };
    } else if (name === sortDirectionOptions.DESC.value) {
      newParams = {
        ...taskListFetchingParams,
        sortDirection: sortDirectionOptions.DEFAULT.value,
        sortColumn: null,
      };
    } else {
      newParams = {
        ...taskListFetchingParams,
        sortDirection: sortDirectionOptions.ASC.value,
        sortColumn: sortOptions[0].value,
      };
    }

    changeTaskListFetchingParams(newParams);
    fetchTasksListWithParams(newParams);

    setSortState({
      sortColumn: sortOptions[0].value,
      sortDirection: newParams.sortDirection,
    });
  };

  let sortButton = null;

  if (sortState.sortDirection === sortDirectionOptions.ASC.value) {
    sortButton = (
      <LinkButton
        variant="link"
        title={sortDirectionOptions.ASC.label}
        onClick={() => onSortChange(sortDirectionOptions.ASC.value)}
      >
        <Icon icon="sort-up" />
      </LinkButton>
    );
  } else if (sortState.sortDirection === sortDirectionOptions.DESC.value) {
    sortButton = (
      <LinkButton
        variant="link"
        title={sortDirectionOptions.DESC.label}
        onClick={() => onSortChange(sortDirectionOptions.DESC.value)}
      >
        <Icon icon="sort-down" />
      </LinkButton>
    );
  } else {
    sortButton = (
      <LinkButton
        variant="link"
        title={sortDirectionOptions.DEFAULT.label}
        onClick={() => onSortChange(sortDirectionOptions.DEFAULT.value)}
      >
        <Icon icon="sort" />
      </LinkButton>
    );
  }

  return (
    <>{sortButton}</>
  );
};

SortDropDown.propTypes = {
  queryParams: PropTypes.shape({
    o: PropTypes.string,
    d: PropTypes.string,
  }),
  taskListFetchingParams: PropTypes.shape({
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
  }),
  fetchTasksListWithParams: PropTypes.func.isRequired,
  changeTaskListFetchingParams: PropTypes.func.isRequired,
};

export default SortDropDown;
