import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Collapse, FormGroup, FormLabel, FormControl, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import dateFormat from '../../../utils/dateFormat';
import A from '../../A';
import * as consts from '../../../constants';

const FileLink = styled(A)`
  color: ${consts.color.birch};
  &:hover {
    color: ${consts.color.darkBirch};
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 10px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
`;

const TableWrapper = styled.table`
  width: auto;
  table-layout: fixed;

  & td {
    padding: 7px 10px;
    line-height: 1;
  };

  & td:first-child {
    padding-left: 0;
    text-align: right;
  };

  & td:last-child {
    padding-right: 0;
    padding-left: 5px;
  };

  & tr:first-child td {
    padding-top: 0;
  }
  & tr:last-child td {
    padding-bottom: 0;
  }
`;
const FilesTableWrapper = styled.table`
  width: auto;
  table-layout: fixed;

  & td {
    padding: 5px 10px;
    line-height: 1;
  };

  & tr:first-child td {
    padding-top: 0;
  }
  & tr:last-child td {
    padding-bottom: 0;
  }
`;

const TextAreaContainer = styled(FormGroup)`
  textarea { 
    resize: vertical;
  }
  padding: 10px 10px 0 10px;
`;

const spanStyle = {
  color: '#0bb9e2',
  fontSize: '80%',
  textDecoration: 'underline',
  fontWeight: '600',
  cursor: 'pointer',
};

export default function ExpandComponent({ row }) {
  const errorsTextArea = (
    <TextAreaContainer controlId="formControlsTextarea">
      <FormLabel>Errors log</FormLabel>
      <FormControl as="textarea" value={row.errors} rows="5" disabled />
    </TextAreaContainer>
  );
  const counterContent = [
    ['Created count:', row.created_count],
    ['Updated count:', row.updated_count],
    ['Deleted count:', row.deleted_count],
    ['Duplicate count:', row.duplicate_count],
    ['Unchanged count:', row.unchanged_count],
    ['Failed count:', row.failed_count],
  ];
  const datesContent = [
    ['Created at:', row.created_at && dateFormat(row.created_at)],
    ['Updated at:', row.updated_at && dateFormat(row.updated_at)],
    ['Started at:', row.started_at && dateFormat(row.started_at)],
    ['Finished at:', row.finished_at && dateFormat(row.finished_at)],
  ];
  const additionalContent = [
    ['Source:', row.source],
    ['Report email:', row.report_email],
  ];

  return (
    <Collapse in appear>
      <div>
        <Container>
          {dataRender(counterContent)}
          {dataRender(datesContent)}
          {dataRender(additionalContent)}
          {filesRender(row.inventory_file, row.archive_files, row.job_id)}
        </Container>
        {row.errors && errorsTextArea}
      </div>
    </Collapse>
  );
}

const dataRender = (fields) => {
  const data = fields.filter((v) => v[1] !== null);
  if (data.length === 0) return null;

  return (
    /* eslint-disable react/no-array-index-key */
    <ColumnContainer>
      <TableWrapper>
        <tbody>
          {
          data.map((v, i) => (
            <tr key={i}>
              <td><strong>{v[0]}</strong></td>
              <td>{v[1]}</td>
            </tr>
          ))
          }
        </tbody>
      </TableWrapper>
    </ColumnContainer>
    /* eslint-disable react/no-array-index-key */
  );
};

const filesRender = (inventory, archive, jobId) => (
  <ColumnContainer>
    <FilesTableWrapper>
      <tbody>
        <tr><td>{inventory && linkFormat(inventory, jobId)}</td></tr>
        {archive && archiveFilesRender(archive, jobId)}
      </tbody>
    </FilesTableWrapper>
  </ColumnContainer>
);

const archiveFilesRender = (string, jobId) => {
  let archiveFiles = null;

  try {
    archiveFiles = JSON.parse(string);
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
  if (archiveFiles.length === 0) return null;

  const listArchiveFiles = archiveFiles.map((v, i) => (
    <p key={i}>{linkFormat(v, jobId)}</p>
  ));

  const tooltip = (
    <Tooltip id="tooltip" bsPrefix="customers-company-links-tooltip tooltip">
      <div>{listArchiveFiles}</div>
    </Tooltip>
  );

  return (
    <>
      <tr><td>{linkFormat(archiveFiles[0], jobId)}</td></tr>
      <tr>
        <td>
          <OverlayTrigger trigger="click" overlay={tooltip} rootClose>
            <span style={spanStyle}>View all archive files</span>
          </OverlayTrigger>
        </td>
      </tr>
    </>
  );
};

const linkFormat = (cell, jobId) => (
  <FileLink to={`${consts.API_URL}/uploader/import/download/job/${jobId}/${cell}`} target="_blank">
    <FontAwesomeIcon icon="file" />
    &nbsp;
    {cell.split('/').pop()}
  </FileLink>
);

ExpandComponent.propTypes = {
  row: PropTypes.shape({
    created_count: PropTypes.number,
    updated_count: PropTypes.number,
    deleted_count: PropTypes.number,
    duplicate_count: PropTypes.number,
    unchanged_count: PropTypes.number,
    failed_count: PropTypes.number,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    started_at: PropTypes.string,
    finished_at: PropTypes.string,
    source: PropTypes.string,
    report_email: PropTypes.string,
    inventory_file: PropTypes.string,
    archive_files: PropTypes.string,
    job_id: PropTypes.string,
    errors: PropTypes.string,
  }),
};
