import axios from 'axios';

import {
  VENDOR_CATEGORIES_GET,
  VENDOR_CATEGORIES_GET_SUCCESS,

  VENDOR_CATEGORIES_SAVE,
  VENDOR_CATEGORIES_SAVE_SUCCESS,

  VENDOR_CATEGORIES_FAIL,
  MISC_SETTINGS_EDIT_MODE_TOGGLE,
  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';

const startFetchCategories = () => ({ type: VENDOR_CATEGORIES_GET });
const fetchCategoriesSuccess = (item) => ({
  type: VENDOR_CATEGORIES_GET_SUCCESS,
  payload: item,
});
const categoriesFail = () => ({ type: VENDOR_CATEGORIES_FAIL });

const startSaveCategories = () => ({ type: VENDOR_CATEGORIES_SAVE });
const saveCategoriesSuccess = (item) => ({
  type: VENDOR_CATEGORIES_SAVE_SUCCESS,
  payload: item,
});

export const toggleEditMode = () => ({
  type: MISC_SETTINGS_EDIT_MODE_TOGGLE,
});

export const fetchCategories = () => async (dispatch) => {
  dispatch(startFetchCategories());
  try {
    const response = await axios('/rest/V2/eplane/backoffice/categories');
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchCategoriesSuccess(response.data && response.data.payload && response.data.payload.categories));
    } else {
      requestErrorHandler(response, dispatch, categoriesFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, categoriesFail, e);
  }
};

export const saveCategories = (data) => async (dispatch) => {
  dispatch(startSaveCategories());
  try {
    const response = await axios.post('/rest/V2/eplane/backoffice/categories', data);
    dispatch(saveCategoriesSuccess(response.data));
  } catch (e) {
    requestErrorHandler(e.response, dispatch, categoriesFail, e);
  }
};
