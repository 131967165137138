import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import FormTitleSection, { FormSubTitle, FormSubTitleItem } from '../../FormComponents/TitleSection';
import { TabContainer } from '../../StyledComponents';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import FormActionButtons from '../../FormComponents/ActionButtons';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import ErrorModal from '../../ErrorModal';
import InternalErrorContent from '../../InternalErrorContent';
import QuotingTimeForm from './QuotingTimeForm';
import Loader from '../../Loader';
import {
  fetchUserSettings, saveUserSettings, removeError, toggleEditMode,
} from '../../../actions/companiesManagement/userSettingsActions';
import { fetchCompanyCustomerList } from '../../../actions/companiesManagement/companyCustomerActions';
import { getUserSettingsStore, getUserStore, getCompanyCustomer } from '../../../selectors';
import { fetchCompanyList } from '../../../actions/companyListActions';
import ModalLoader from '../../ModalLoader';
import changesMapper from '../../../utils/diffMapper';
import {
  naturalNumberValidator, autopilotRfqLimitValidator, demandsRowCountLimitValidator, erpValidator,
} from './validators';
import TierCheckBoxGroup from './TierCheckBoxGroup';
import { DEFAULT_SELECT_KEY } from '../../../constants';
import { tier2Status } from '../../../constants/';
import * as constants from '../../../constants/index';

const TextAreaContainer = styled.div`
  textarea {
    width: 350px;
    height: auto;
  }
`;

export default function UserSettingsPage() {
  const refChanges = useRef(null);

  const dispatch = useDispatch();
  const { userId } = useParams();

  const [data, setData] = useState(null);
  const [modals, setModals] = useState({
    showConfirmationModal: false,
    showTier3DemandModal: false,
  });

  const {
    data: propsData,
    fatalErrorCode,
    editMode,
    isFetching,
    isSaving,
    savingErrorCode,
  } = useSelector(getUserSettingsStore);
  const {
    customers,
    fetchingErrorCode: fetchingCustomerErrorCode,
  } = useSelector(getCompanyCustomer);
  const { user } = useSelector(getUserStore);

  const isCompanyUser = (user?.user_type === constants.userType.COMPANY.id);

  useEffect(() => {
    batch(() => {
      dispatch(fetchUserSettings(userId));
      dispatch(fetchCompanyCustomerList(userId));
      dispatch(fetchCompanyList());
    });
  }, [dispatch, userId]);

  useEffect(() => {
    if (editMode) setData({ ...propsData });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const onChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setData((prevState) => {
      const newData = { ...prevState };
      newData[target.name] = value;
      return newData;
    });
  };

  const onChangeAutopilot = (e) => {
    const { target } = e;
    setData((prevState) => {
      const newData = { ...prevState };
      newData[target.name] = target.checked;
      newData.autopilot_send_to_tier_1 = propsData.autopilot_send_to_tier_1;
      newData.autopilot_send_to_tier_2 = propsData.autopilot_send_to_tier_2;
      newData.autopilot_send_to_tier_3 = propsData.autopilot_send_to_tier_3;
      return newData;
    });
  };

  const onChangeDemandsTier3 = (e) => {
    const { target } = e;
    const value = target.checked;
    const showModal = !value;
    setData((prevState) => {
      const newData = { ...prevState };
      newData[target.name] = value;
      return newData;
    });
    setModals((prev) => ({ ...prev, showTier3DemandModal: showModal }));
  };

  const onSaveBtnClick = () => {
    const changes = changesMapper(data, propsData);

    if (!dataIsValid(changes)) return;

    if (Object.keys(changes).length) {
      refChanges.current = changes;
      setModals((prev) => ({ ...prev, showConfirmationModal: true }));
    } else {
      dispatch(toggleEditMode());
    }
  };

  const onHideConfirmationModal = () => {
    setModals((prev) => ({ ...prev, showConfirmationModal: false }));
  };

  const onHideTier3DemandModal = () => {
    setModals((prev) => ({ ...prev, showTier3DemandModal: false }));
  };

  const onCancelTier3DemandModal = () => {
    setData((prevState) => {
      const newData = { ...prevState };
      newData.receive_all_demands_enabled = true;
      return newData;
    });
    onHideTier3DemandModal();
  };

  const onQuotingChange = (e) => {
    const { value, name } = e.target;

    setData((prevState) => {
      const newData = { ...prevState };

      newData.quoting_time = { ...newData.quoting_time, [name]: value };
      return newData;
    });
  };

  const getCustomerListForSelect = () => {
    if (!customers) return [];
    const { customerStatus } = constants;

    const optionList = customers
      .filter((c) => c.customer_status === customerStatus.ACTIVE.id)
      .map((c) => [c.entity_id, c.email]);

    optionList.splice(0, 0, [DEFAULT_SELECT_KEY, 'not selected']);

    return optionList;
  };

  const getTagListForSelect = () => [['none', 'none'], ['oem', 'OEM'], ['distributor', 'Distributor']];

  const getTier2ListForSelect = () => [[tier2Status.DISABLED, 'Tier2 listing disabled'],
    [tier2Status.AUTOPILOT_ONLY, 'Tier2 listing enabled (autopilot only)'],
    [tier2Status.ENABLED, 'Tier2 listing enabled (both autopilot and search)']];

  const dataIsValid = (d) => {
    const qTime = d.quoting_time;
    if (qTime && Object.values(qTime).some((qt) => naturalNumberValidator(qt) !== null)) {
      return false;
    }

    if (autopilotRfqLimitValidator(d.autopilot_enabled, d.autopilot_rfq_limit) !== null) {
      return false;
    }

    return true;
  };

  const save = () => {
    onHideConfirmationModal();
    const changes = refChanges.current;
    if (changes.seller_demands_autosync_customer_id === DEFAULT_SELECT_KEY) {
      changes.seller_demands_autosync_customer_id = null;
    }
    if (changes.auto_built_in_quote_customer_id === DEFAULT_SELECT_KEY) {
      changes.auto_built_in_quote_customer_id = null;
    }
    if (changes.rfq_creator_customer_id === DEFAULT_SELECT_KEY) {
      changes.rfq_creator_customer_id = null;
    }

    dispatch(saveUserSettings(userId, changes));
  };

  if (fatalErrorCode || fetchingCustomerErrorCode) return <InternalErrorContent />;
  if (isFetching) return <Loader />;

  const settings = (editMode ? data : propsData) || {};
  const title = isCompanyUser ? 'Company Settings' : "Warning! This user doesn't belong to any approved Company";
  const saveBtnProps = { disabled: !editMode || !dataIsValid(settings) };
  const tiers = [
    { name: 'autopilot_send_to_tier_1', value: settings.autopilot_enabled && settings.autopilot_send_to_tier_1 },
    { name: 'autopilot_send_to_tier_2', value: settings.autopilot_enabled && settings.autopilot_send_to_tier_2 },
    { name: 'autopilot_send_to_tier_3', value: settings.autopilot_enabled && settings.autopilot_send_to_tier_3 },
  ];

  return (
    <>
      <ModalLoader show={isSaving} />
      <FormTitleSection
        title={title}
        EditButtonClick={() => dispatch(toggleEditMode())}
        showEditButton={!editMode}
      />

      <TabContainer marginTop="10px">
        <Form>
          <FormSubTitle title="General" showEditButton={false} />
          <BaseFormGroup
            labelText="Company tag in search results:"
            as="select"
            options={getTagListForSelect()}
            value={settings.company_tag_in_search_results || ''}
            disabled={!editMode}
            name="company_tag_in_search_results"
            onChange={onChange}
            maxWidth="350px"
          />

          <BaseFormGroup
            labelText="Choose the user who will review join company requests:"
            as="select"
            options={getCustomerListForSelect()}
            value={settings.join_reviewer_customer_id || ''}
            disabled={!editMode}
            name="join_reviewer_customer_id"
            onChange={onChange}
            maxWidth="350px"
          />

          <FormSubTitle title="Inventory" showEditButton={false} />

          <Form.Group>
            <CheckboxStyled
              id="tags_required_checkbox"
              checked={settings.tags_required}
              label="Tags required (this company must provide tags when uploading inventory)"
              disabled={!editMode}
              name="tags_required"
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <CheckboxStyled
              id="parts_serial_number_required_checkbox"
              checked={settings.parts_serial_number_required}
              label="Part serial number required (this company must provide serial numbers when uploading inventory)"
              disabled={!editMode}
              name="parts_serial_number_required"
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <CheckboxStyled
              id="parts_quantity_required_checkbox"
              checked={settings.parts_quantity_required}
              label="Parts quantity required (this company must provide quantity when uploading inventory)"
              disabled={!editMode}
              name="parts_quantity_required"
              onChange={onChange}
            />
          </Form.Group>

          <BaseFormGroup
            labelText="ERP system used by company:"
            value={settings.erp}
            disabled={!editMode}
            name="erp"
            onChange={onChange}
            style={{ width: 350 }}
            {...erpValidator(settings.erp)}
          />

          <FormSubTitle title="Options" showEditButton={false} />
          <BaseFormGroup
            labelText="Tier 2 items visibility:"
            as="select"
            options={getTier2ListForSelect()}
            value={settings.tier2_status || ''}
            disabled={!editMode}
            name="tier2_status"
            onChange={onChange}
            maxWidth="350px"
          />

          <FormSubTitle title="Autopilot" showEditButton={false} />
          <FormSubTitleItem title="Seller Settings:" />

          <Form.Group>
            <CheckboxStyled
              id="receive_all_demands_enabled_checkbox"
              checked={settings.receive_all_demands_enabled}
              label="This company accepts TIER 3 RFQs"
              disabled={!editMode}
              name="receive_all_demands_enabled"
              onChange={onChangeDemandsTier3}
            />
          </Form.Group>

          <BaseFormGroup
            labelText="Choose the user on whose behalf the automatic quote will be sent:"
            as="select"
            options={getCustomerListForSelect()}
            value={settings.auto_built_in_quote_customer_id || ''}
            disabled={!editMode}
            name="auto_built_in_quote_customer_id"
            onChange={onChange}
            maxWidth="350px"
          />

          <Form.Group>
            <CheckboxStyled
              id="catch_mail_rfq_replies_checkbox"
              checked={settings.catch_mail_rfq_replies}
              label="Enable reply by email (this company’s users will be able to reply
                to RFQs/inquiries by email and not only through the ePlane system)"
              disabled={!editMode}
              name="catch_mail_rfq_replies"
              onChange={onChange}
            />
          </Form.Group>

          <FormSubTitleItem title="Buyer Settings:" />
          <Form.Group>
            <CheckboxStyled
              id="autopilot_enabled_checkbox"
              checked={settings.autopilot_enabled}
              label="Enable Autopilot"
              disabled={!editMode || !isCompanyUser}
              name="autopilot_enabled"
              onChange={onChangeAutopilot}
            />
          </Form.Group>
          <TierCheckBoxGroup
            values={tiers}
            disabled={!editMode || !settings.autopilot_enabled}
            onChange={onChange}
          />

          <BaseFormGroup
            labelText="Define max number of RFQs sent per Autopilot demand:"
            value={settings.autopilot_rfq_limit != null ? `${settings.autopilot_rfq_limit}` : ''}
            disabled={!editMode}
            name="autopilot_rfq_limit"
            onChange={onChange}
            style={{ width: 350 }}
            {...autopilotRfqLimitValidator(settings.autopilot_enabled, settings.autopilot_rfq_limit)}
          />

          <BaseFormGroup
            labelText="Define maximum number of lines to process in the uploaded demand:"
            value={settings.demands_upload_row_count_limit != null ? `${settings.demands_upload_row_count_limit}` : ''}
            disabled={!editMode}
            name="demands_upload_row_count_limit"
            onChange={onChange}
            style={{ width: 350 }}
            {...demandsRowCountLimitValidator(settings.demands_upload_row_count_limit)}
          />

          <BaseFormGroup
            labelText="Choose the user on whose behalf the RFQs will be sent when a demands list is uploaded automatically:"
            as="select"
            options={getCustomerListForSelect()}
            value={settings.seller_demands_autosync_customer_id || ''}
            disabled={!editMode}
            name="seller_demands_autosync_customer_id"
            onChange={onChange}
            maxWidth="350px"
          />

          <BaseFormGroup
            labelText="Choose user on whose behalf RFQ with PO will be created (SPEC2000 support flow):"
            as="select"
            options={getCustomerListForSelect()}
            value={settings.rfq_creator_customer_id || ''}
            disabled={!editMode}
            name="rfq_creator_customer_id"
            onChange={onChange}
            maxWidth="350px"
          />

          <TextAreaContainer>
            <BaseFormGroup
              labelText="RFQ Additional comment:"
              as="textarea"
              value={settings.rfq_additional_comment || ''}
              disabled={!editMode}
              name="rfq_additional_comment"
              onChange={onChange}
              rows={6}
            />
          </TextAreaContainer>

          <QuotingTimeForm
            constants={constants}
            quotingTime={settings.quoting_time}
            disabled={!editMode}
            onChange={onQuotingChange}
          />

          {
            editMode && (
              <FormActionButtons
                saveProps={saveBtnProps}
                saveHandler={onSaveBtnClick}
                cancelHandler={() => dispatch(toggleEditMode())}
              />
            )
          }
        </Form>
      </TabContainer>

      <ConfirmationModal
        show={modals.showConfirmationModal}
        confirm={save}
        onHide={onHideConfirmationModal}
      >
        Are you sure you want to apply the changes?
      </ConfirmationModal>

      <ConfirmationModal
        show={modals.showTier3DemandModal}
        confirmBtnText="CONFIRM"
        confirm={onHideTier3DemandModal}
        onHide={onCancelTier3DemandModal}
      >
        By removing this Tier 3 checkbox, the vendor will be automatically removed from all airlines it is associated on tier 3 level
      </ConfirmationModal>
      <ErrorModal show={savingErrorCode} onHide={() => dispatch(removeError())} />
    </>
  );
}

UserSettingsPage.propTypes = {
  fatalErrorCode: PropTypes.number,
  editMode: PropTypes.bool,
  isFetching: PropTypes.bool,
  savingErrorCode: PropTypes.bool,
  isSaving: PropTypes.bool,

  data: PropTypes.shape({
    autopilot_enabled: PropTypes.bool,
    catch_mail_rfq_replies: PropTypes.bool,
    receive_all_demands_enabled: PropTypes.bool,
    tier2_status: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    seller_demands_autosync_customer_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    auto_built_in_quote_customer_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    rfq_creator_customer_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    company_tag_in_search_results: PropTypes.string,
    rfq_additional_comment: PropTypes.string,
    seller_weight: PropTypes.number,
    tags_required: PropTypes.bool,
    approved_distributor: PropTypes.bool,
    approved_oem: PropTypes.bool,
    autopilot_send_to_tier_1: PropTypes.bool,
    autopilot_send_to_tier_2: PropTypes.bool,
    autopilot_send_to_tier_3: PropTypes.bool,
    erp: PropTypes.string,
    sellers_weights: PropTypes.arrayOf(PropTypes.object),
    quoting_time: PropTypes.shape({}),
    autopilot_rfq_limit: PropTypes.number,
    parts_serial_number_required: PropTypes.bool,
    parts_quantity_required: PropTypes.bool,

    aggregation_emails_editable: PropTypes.bool,
    pro_buyer_filters: PropTypes.bool,
    pro_demand_export: PropTypes.bool,
    pro_rfq_export: PropTypes.bool,
    pro_rfq_filters: PropTypes.bool,
    sellers_lists_enabled: PropTypes.bool,
  }),

  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }),

  constants: PropTypes.shape({
    customerStatus: PropTypes.shape({
      PENDING: PropTypes.shape({
        id: PropTypes.number,
      }),
      INACTIVE: PropTypes.shape({
        id: PropTypes.number,
      }),
      ACTIVE: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  }),

  companyCustomer: PropTypes.shape({
    customers: PropTypes.arrayOf(PropTypes.object),
    isFetching: PropTypes.bool,
    fetchingErrorCode: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  isCompanyUser: PropTypes.bool,
};
