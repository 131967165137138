import moment from 'moment';

import { getConstNameById } from '../../utils/constantsHandlers';
import dateFormat from '../../utils/dateFormat';
import { quoteTypes, quoteOriginal, supportedCurrencies } from '../../constants/index';
import * as constants from '../../constants/index';
import {
  formatTime, formatUnits, getSiteTitle, getTagName, getTraceTo, getPriceForQuote,
} from './helpers';

export default function processQuoteMessage(msg, items, isRepair, isTagDocRemoved) {
  const { quote: q, quote_final: qFin } = msg;
  const pickupSite = q.pickup_site && q.pickup_site.location;

  let quoteFinalData = null;
  let quoteExtraData = null;
  let initData;
  let quoteRaw = null;
  let priceBreak = null;
  let leadTimePnDetRes = null;
  let pnDetailsAvailable = false;

  if (qFin) {
    quoteFinalData = getQuoteFinalDataForQuote(q, qFin);
    priceBreak = getPriceBreaksForQuote(qFin.price_breaks);
    initData = [
      q.price && qFin.unit_price_amount_icr === supportedCurrencies.USD && `Price (per unit): ${formatUnits(q.price, q.currency)}`,
      (q.condition && `Condition: ${getConstNameById(q.condition, constants, 'condition')}`)
        || (qFin.condition_code && `Condition: ${qFin.condition_code}`),
      q.qty && `Qty: ${formatUnits(q.qty, q.qty_units)}`,
      q.lead_time && `Lead Time: ${formatTime(q.lead_time, q.lead_time_units)}`,
    ];
  } else if (q.quote_original === quoteOriginal.QUOTE_TIER1) {
    const pn = items && items[0] && items[0].part && items[0].part.p_n;
    const description = items && items[0] && items[0].part && items[0].part.description;
    const leadTime = q && q.lead_time;
    quoteExtraData = [
      pn && `PN: ${pn}`,
      description && `Description: ${description}`,
      leadTime && `Lead Time: ${Math.floor(leadTime / 24)} day${Math.floor(leadTime / 24) > 1 ? 's' : ''}`,
    ];

    initData = [
      q.price && `Price (per unit): $${q.price}`,
      q.condition && `Condition: ${getConstNameById(q.condition, constants, 'condition')}`,
      q.qty && Number(q.qty) !== 0 && `Qty: ${q.qty}`,
    ];
  } else if (q.pn_details_result) {
    const {
      pn, keyword, leadTime, priceBreak: priceBr,
    } = q.pn_details_result;
    pnDetailsAvailable = true;
    const minQty = (q.pn_details_result.minQty && q.pn_details_result.minQty.value) || '0';
    const units = (q.pn_details_result.minQty && q.pn_details_result.minQty.units) || '';

    quoteExtraData = [
      pn && `PN: ${pn}`,
      keyword && `Description: ${keyword}`,
    ];

    leadTimePnDetRes = leadTime && `Lead Time: ${leadTime} day${leadTime > 1 ? 's' : ''}`;

    const filtPrBreak = priceBr && priceBr.length !== 0 && priceBr.filter((v) => v.price.value > 0);
    priceBreak = filtPrBreak && filtPrBreak.map((v) => [`Qty ${v.qty.value}`, `Price: $${v.price.value}`]);

    initData = [
      q.price && `Price (per unit): $${q.price}`,
      q.condition && `Condition: ${getConstNameById(q.condition, constants, 'condition')}`,
      q.qty && Number(q.qty) !== 0 && `Qty: ${q.qty}`,
      minQty && Number(minQty) !== 0 && `Min. Order Qty: ${minQty}${units ? ' ' : ''}${units}`,
    ];
  } else if (q.quote_type && q.quote_type === quoteTypes.QUOTE_EXCHANGE) {
    quoteRaw = {
      part_number: q.is_alt_pn ? `Alternative PN: ${q.part_number}` : null,
      description: q.description && `Description: ${q.description}`,
      condition: `Condition: ${q.condition ? getConstNameById(q.condition, constants, 'condition') : '-'}`,
      warranty: `Warranty: ${formatTime(q.warranty, 'month')}`,
      lead_time: `Lead Time: ${formatTime(q.lead_time, q.lead_time_units)}`,
      qty: `Qty: ${formatUnits(q.qty, q.qty_units)}`,
      pickup_site: getSiteTitle(false, pickupSite),
      exchange_fee: `Exchange Fee (per unit): ${formatUnits(q.fee, q.currency)}`,
      tag_type: getTagName(q.tag_type, q.tag_name, isTagDocRemoved),
      exchange_period: `Exchange Period: ${formatTime(q.period, 'day')}`,
      tagged_by: `Tagged By: ${q.tagged_by ?? '-'}`,
      outright_price: `Outright Price: ${formatUnits(q.outright_price, q.currency)}`,
      tag_date: `Tag Date: ${q.tag_date ? dateFormat(q.tag_date, false) : '-'}`,
      minimum_order_value: `Minimum Order Value: ${formatUnits(q.minimum_order_value, q.currency)}`,
      trace_to: `Trace To: ${getTraceTo(q.trace_to)}`,
    };
  } else if (q.quote_type && q.quote_type === quoteTypes.QUOTE_LOAN) {
    quoteRaw = {
      part_number: q.is_alt_pn ? `Alternative PN: ${q.part_number}` : null,
      description: q.description && `Description: ${q.description}`,
      condition: `Condition: ${q.condition ? getConstNameById(q.condition, constants, 'condition') : '-'}`,
      warranty: `Warranty: ${formatTime(q.warranty, 'month')}`,
      lead_time: `Lead Time: ${formatTime(q.lead_time, q.lead_time_units)}`,
      qty: `Qty: ${formatUnits(q.qty, q.qty_units)}`,
      pickup_site: getSiteTitle(false, pickupSite),
      loan_fee: `Loan Fee: ${formatUnits(q.fee, q.currency)}`,
      tag_type: getTagName(q.tag_type, q.tag_name, isTagDocRemoved),
      loan_period: `Loan Period: ${formatTime(q.period, 'day')}`,
      tagged_by: `Tagged By: ${q.tagged_by ?? '-'}`,
      outright_price: `Outright Price: ${formatUnits(q.outright_price, q.currency)}`,
      tag_date: `Tag Date: ${q.tag_date ? dateFormat(q.tag_date, false) : '-'}`,
      minimum_order_value: `Minimum Order Value: ${formatUnits(q.minimum_order_value, q.currency)}`,
      trace_to: `Trace To: ${getTraceTo(q.trace_to)}`,
    };
  } else if (q.quote_type && q.quote_type === quoteTypes.QUOTE_REPAIR) {
    const { repair_price: repPrice, repair_lead_time: leadTime } = q;
    quoteRaw = {
      pickup_site: getSiteTitle(true, pickupSite),
      warranty: `Warranty: ${formatTime(q.warranty, 'month')}`,
      qty: `Qty: ${formatUnits(q.qty, q.qty_units)}`,
      evaluation_fee: `Evaluation Fee (per unit): ${formatUnits(repPrice.evaluation, q.currency)}`,
      evaluation_time: `Evaluation Lead Time: ${formatTime(leadTime.evaluation, q.lead_time_units)}`,
      test_price: `Test Price (per unit): ${formatUnits(repPrice.test, q.currency)}`,
      test_time: `Test Lead Time: ${formatTime(leadTime.test, q.lead_time_units)}`,
      repair_price: `Repair Price (per unit): ${formatUnits(repPrice.repair, q.currency)}`,
      repair_time: `Repair Lead Time: ${formatTime(leadTime.repair, q.lead_time_units)}`,
      overhaul_price: `Overhaul Price (per unit): ${formatUnits(repPrice.overhaul, q.currency)}`,
      overhaul_time: `Overhaul Lead Time: ${formatTime(leadTime.overhaul, q.lead_time_units)}`,
    };
  } else {
    quoteRaw = {
      part_number: q.is_alt_pn ? `Alternative PN: ${q.part_number}` : null,
      description: q.description && `Description: ${q.description}`,
      condition: `Condition: ${q.condition ? getConstNameById(q.condition, constants, 'condition') : '-'}`,
      warranty: `Warranty: ${formatTime(q.warranty, 'month')}`,
      lead_time: `Lead Time: ${formatTime(q.lead_time, q.lead_time_units)}`,
      qty: `Qty: ${formatUnits(q.qty, q.qty_units)}`,
      pickup_site: getSiteTitle(false, pickupSite),
      price: `Price (per unit): ${formatUnits(q.price, q.currency)}`,
      tag_type: getTagName(q.tag_type, q.tag_name, isTagDocRemoved),
      taxes: `Taxes: ${formatUnits(q.taxes, q.currency)}`,
      tagged_by: `Tagged By: ${q.tagged_by ?? '-'}`,
      total_price: `Total Price: ${formatUnits(q.total_price, q.currency)}`,
      tag_date: `Tag Date: ${q.tag_date ? `${moment.utc(q.tag_date).format('MMM D, YYYY')}` : '-'}`,
      minimum_order_value: `Minimum Order Value: ${formatUnits(q.minimum_order_value, q.currency)}`,
      trace_to: `Trace To: ${getTraceTo(q.trace_to)}`,
      is_alt_pn: q.is_alt_pn,
    };
  }

  return {
    citedMessageId: q.cited_message_id || null,
    quoteType: q.quote_type,
    quoteOriginal: q.quote_original,
    quote: initData,
    quoteRaw,
    quoteFinal: quoteFinalData || null,
    quotePrice: priceBreak || null,
    quoteExtraData,
    leadTime: leadTimePnDetRes,
    pnDetailsAvailable,
    lowBidIndicator: q.low_bid_indicator,
  };
}

const getPriceBreaksForQuote = (priceBreaks) => (
  priceBreaks && priceBreaks.filter((v) => v.price_amount && v.quantity)
    .map((v) => [`Qty ${v.quantity}`, `Price: ${v.price_amount} ${v.price_amount_icr}`])
);

const getQuoteFinalDataForQuote = (q, qFin) => {
  const additionalPrice = getPriceForQuote(q, qFin);
  return [
    qFin.unit_price_amount
      && qFin.unit_price_amount_icr !== supportedCurrencies.USD
      && `Price (per unit): ${qFin.unit_price_amount} ${qFin.unit_price_amount_icr}`,
    qFin.remarks && `Remarks: ${qFin.remarks}`,
    qFin.replacing_part_number && `Alternative Part Number: ${qFin.replacing_part_number}`,
    qFin.reason_for_part_number_change && `Reason for Part Number Change - Text: ${qFin.reason_for_part_number_change}`,
    additionalPrice,
    qFin.keyword_name && `Keyword Name: ${qFin.keyword_name}`,
    qFin.price_held_firm_time && `Price Held Firm - Time: ${qFin.price_held_firm_time}`,
    qFin.quotation_number && `Quotation Number: ${qFin.quotation_number}`,
    qFin.quotation_date && `Quotation Date: ${dateFormat(qFin.quotation_date, false)}`,
    qFin.total_times && qFin.total_times.length > 0 && 'Total Time Text:',
    ...getTotalTimesForQuote(qFin.total_times),
    qFin.standard_package_quatity && `Standard Package: ${qFin.standard_package_quatity}`,
    qFin.minimum_sales_quantity && `Minimum Sales: ${qFin.minimum_sales_quantity}`,
    qFin.category_i_container_price_amount && `Category I Container Price Amount: ${qFin.category_i_container_price_amount}`,
    qFin.manufacturer_code && `Manufacturer Code: ${qFin.manufacturer_code}`,
    qFin.discount_percent && `Discount Percent: ${qFin.discount_percent}`,
    qFin.interchangeability_code && `Interchangeability Code: ${qFin.interchangeability_code}`,
    qFin.traceability_data_indicator && `Traceability Data Indicator: ${qFin.traceability_data_indicator}`,
    qFin.universal_serial_tracking_number && `Universal Serial Tracking Number: ${qFin.universal_serial_tracking_number}`,
    qFin.document_reference_indicator && `Document Reference Indicator: ${qFin.document_reference_indicator}`,
    qFin.part_source_code && `Part Source Code: ${qFin.part_source_code}`,
    qFin.source_name && `Source Name: ${qFin.source_name}`,
    qFin.certificate_source && `Certificate Source: ${qFin.certificate_source}`,
    qFin.certificate_type && `Certificate Type: ${qFin.certificate_type}`,
    qFin.certificate_date && `Certificate Date: ${dateFormat(qFin.certificate_date, false)}`,
    qFin.part_serial_number && `Part Serial Number: ${qFin.part_serial_number}`,
    qFin.work_order_number && `Work Order Number: ${qFin.work_order_number}`,
    qFin.manufacture_date && `Manufacture Date: ${dateFormat(qFin.manufacture_date, false)}`,
    qFin.shelf_life_expiration_date && `Shelf Life Expiration Date: ${dateFormat(qFin.shelf_life_expiration_date, false)}`,
    qFin.batch_item_identifier && `Batch Item Identifier: ${qFin.batch_item_identifier}`,
    qFin.oem_original_packaging_code && `OEM Original Packaging Code: ${qFin.oem_original_packaging_code}`,
  ];
};

const getTotalTimesForQuote = (totalTimes) => {
  const totalTimesFiltered = totalTimes.filter((v) => v.operating_cycle_count || v.operating_time || v.time_cycle_ref_code);

  return totalTimesFiltered.map((v, i) => {
    const totalTime = [];

    if (v.operating_time) {
      totalTime.push(`Operating Time ${v.operating_time}`);
    }

    if (v.operating_cycle_count) {
      totalTime.push(`Operating Cycle Count ${v.operating_cycle_count}`);
    }

    if (v.time_cycle_ref_code) {
      totalTime.push(`Time/Cycle Reference Code  ${v.time_cycle_ref_code}`);
    }
    return `${i + 1}. ${totalTime.join(', ')}`;
  });
};
