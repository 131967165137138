import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Row, Col, Form, Button as ButtonBS, FormGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WeightListTitle } from '../StyleComponents';
import { ButtonContainer } from '../../StyledComponents';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import { PUBLIC_URL } from '../../../constants';

const EmptyText = styled.div`
  padding-top: 10px;
  font-size: 16px;
  text-align: center;
`;
const IndexColumn = styled.div`
  padding-top: 6px;
`;
const Button = styled(ButtonBS)`
  && {
    padding: 3px 6px 3px 6px;
  }
  &&:hover {
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
  }
`;

const CodesManagementTable = ({
  data, companyColumnTitle = 'Company name', usePartnerId = true, showControls, onEditCodeClick, onRemoveCodeClick, onGo,
}) => (
  <Form>
    <Row>
      <Col sm={1}><WeightListTitle>Partner</WeightListTitle></Col>
      <Col sm={4}><WeightListTitle>{companyColumnTitle}</WeightListTitle></Col>
      <Col sm={2}><WeightListTitle>Code</WeightListTitle></Col>
    </Row>
    {data?.length === 0 && (
      <Row>
        <Col sm={7}><EmptyText>There are no data</EmptyText></Col>
      </Row>
    )}
    {data.map((v) => (
      <FormGroup key={v.id}>
        <Row>
          <Col sm={1}>
            <ButtonContainer btnWidth="80px">
              <Button
                variant="success"
                onClick={() => onGo(
                  `${PUBLIC_URL}/companies/${usePartnerId ? v.partner_id : v.user_id}/companyDetails/`,
                )}
              >
                <FontAwesomeIcon icon={['fab', 'black-tie']} />
                  &nbsp;
                {`${usePartnerId ? v.partner_id : v.user_id}`}
              </Button>
            </ButtonContainer>
          </Col>
          <Col sm={4}>
            <IndexColumn>
              <span>{v.company_name}</span>
            </IndexColumn>
          </Col>
          <Col sm={2}>
            <BaseFormGroup
              value={v.code}
              name="code"
              readOnly
            />
          </Col>
          {showControls && (
            <Col sm={2}>
              <ButtonContainer>
                <Button variant="primary" onClick={() => onEditCodeClick(v.id)} title="Edit code">
                  <FontAwesomeIcon icon="pencil-alt" />
                </Button>
                <Button onClick={() => onRemoveCodeClick(v.id)} title="Remove code">
                  <FontAwesomeIcon icon="trash-alt" />
                </Button>
              </ButtonContainer>
            </Col>
          )}
        </Row>
      </FormGroup>
    ))}
  </Form>
);

CodesManagementTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.strings,
    id: PropTypes.string,
    company_name: PropTypes.string,
    user_id: PropTypes.string,
  })),
  companyColumnTitle: PropTypes.string,
  usePartnerId: PropTypes.bool,
  showControls: PropTypes.bool,
  onEditCodeClick: PropTypes.func,
  onRemoveCodeClick: PropTypes.func,
  onGo: PropTypes.func,
};

export default CodesManagementTable;
