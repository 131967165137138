export const integrationTypeList = {
  INTEGRATION_SITA: 'sita',
  INTEGRATION_ROTABULL: 'rotabull',
  INTEGRATION_SPEC2MAIL: 'spec2mail',
  INTEGRATION_CUSTOM: 'custom',
};

export const MessageType = {
  INFORMATION_SET: 'ATA_InformationSet',
  SPARES_ORDER: 'ATA_SparesOrder',
  SPARES_MESSAGE_ACK: 'ATA_SparesMessageAck',
  SPARES_ORDER_BOOKBACK: 'ATA_SparesOrderBookback',
  SPARES_ORDER_EXC: 'ATA_SparesOrderExc',
  SPARES_ORDER_STATUS_INQUIRY: 'ATA_SparesOrderStatusInquiry',
  SPARES_ORDER_STATUS_RESPONSE: 'ATA_SparesOrderStatusResponse',
  SPARES_SUPPLIER_SHIP_NOTICE: 'ATA_SparesSupplierShipNotice',
  SPARES_INVOICE: 'ATA_SparesInvoice',
  INVOICE_MESSAGE_ACK: 'ATA_InvoiceMessageAck',
  SPARES_QUOTE_REQUEST: 'ATA_SparesQuoteRequest',
  SPARES_QUOTE_INTERIM: 'ATA_SparesQuoteInterim',
  SPARES_QUOTE_FINAL: 'ATA_SparesQuoteFinal',
};

export const requestMethodsOptions = [
  { value: 'post', label: 'POST' },
  { value: 'get', label: 'GET' },
];

export const contentTypeOptions = [
  { value: 'application/xml', label: 'application/xml' },
  { value: 'text/xml', label: 'text/xml' },
  { value: 'application/json', label: 'application/json' },
  { value: 'text/plain', label: 'text/plain' },
];

export const authTypeOptions = [
  { value: 'none', label: 'none' },
  { value: 'header_token', label: 'header_token' },
  { value: 'client_cert', label: 'client_cert' },
];
