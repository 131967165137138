import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';

import { color } from '../../constants';

const WarningText = styled.div`
  color: ${color.goldenTainoi};
`;
const ErrorText = styled.div`
  font-size: 14px;
`;

const renderValidation = (helpText, validationState) => {
  if (validationState === 'success') {
    return (
      <Form.Control.Feedback type="valid">{helpText}</Form.Control.Feedback>
    );
  }
  if (validationState === 'warning') {
    return (
      <Form.Text id="helpBlock" as={WarningText}>
        {helpText}
      </Form.Text>
    );
  }
  if (validationState === 'error') {
    return (
      <Form.Control.Feedback type="invalid" as={ErrorText}>{helpText}</Form.Control.Feedback>
    );
  }
  return null;
};

export default renderValidation;
