import {
  ERRORS_ADD,
  ERRORS_RESET,
  ERRORS,
} from '../constants';
import { authorizationFail } from './authActions';
import errorExtractor from '../utils/errorExtractor';

export const addError = (error, message, url) => ({
  type: ERRORS_ADD,
  payload: { error, message, url },
});

export const errorsRemove = (removedError) => ({
  type: ERRORS_RESET,
  payload: removedError,
});

export default (response, dispatch, handlerAction, error) => {
  if (response) {
    const { status, data } = response;
    if (status === 403) dispatch(authorizationFail());
    else if (status === 400) dispatch(handlerAction(data.code || ERRORS.ERR_API_BASE));
    else if (status === 404) dispatch(handlerAction(ERRORS.ERR_ENTITY_NOT_FOUND));
    else dispatch(handlerAction((data && data.code) || ERRORS.ERR_API_BASE, data && data.message));
  } else {
    // eslint-disable-next-line no-console
    console.error(error.toString());
    dispatch(handlerAction(ERRORS.ERR_API_BASE));
  }
};

export const requestErrorHandler = (response, dispatch, handlerAction, error) => {
  if (response) {
    const { status, data } = response;
    if (status === 403) {
      dispatch(authorizationFail());
    } else if (status === 400) {
      dispatch(addError(data.code, formDetailsMessage(data), response.request?.responseURL));
      dispatch(handlerAction(data.code || ERRORS.ERR_API_BASE));
    } else if (status === 404) {
      dispatch(addError(ERRORS.ERR_ENTITY_NOT_FOUND, formDetailsMessage(data, ERRORS.ERR_ENTITY_NOT_FOUND, response.request?.responseURL)));
      dispatch(handlerAction(ERRORS.ERR_ENTITY_NOT_FOUND));
    } else {
      dispatch(addError(data?.code || ERRORS.ERR_API_BASE, formDetailsMessage(data), response.request?.responseURL));
      dispatch(handlerAction(data?.code || ERRORS.ERR_API_BASE, data?.message));
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(error.toString());
    dispatch(addError(ERRORS.ERR_API_BASE, error.toString()));
    dispatch(handlerAction(ERRORS.ERR_API_BASE));
  }
};

const formDetailsMessage = (data, errorCode) => (
  `${data?.message || errorExtractor(errorCode || data?.code)}` || ''
);
