import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
} from 'react-bootstrap';
import styled from 'styled-components';

const HeaderModal = styled.h1`
  text-align: center;
  letter-spacing: -0.2px;
  display: block;
`;
const TextArea = styled.textarea`
  &&& { 
    resize: vertical;
    height: 150px;
    max-height: 500px;
    min-height: 50px;
  }
`;

export default class DeletePopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { reason: '' };
  }

  onTextAreaChange = (e) => {
    this.setState({
      reason: e.target.value,
    });
  }

  onDeleteBtnClick = () => {
    const { onSubmit } = this.props;
    const { reason } = this.state;
    onSubmit(reason);
  }

  render() {
    const { onSubmit, onHide, ...rest } = this.props;
    const { reason } = this.state;
    return (
      <Modal centered {...rest}>
        <Modal.Header>
          <HeaderModal>DELETE ACCOUNT</HeaderModal>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormLabel>Enter the reason for deleting this user/account:</FormLabel>
            <FormControl
              as={TextArea}
              onChange={this.onTextAreaChange}
              value={reason}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!reason} variant="danger" onClick={this.onDeleteBtnClick}>
            DELETE ACCOUNT
          </Button>
          <Button variant="outline-secondary" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

DeletePopup.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};
