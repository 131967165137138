import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';

import Triangle from '../../assets/sprite-arrow-icons.png';
import NavA from '../NavA';

import { color } from '../../constants';

const ArrowContainer = styled.span`
  display: inline-block;
  min-width: 27px;
  background: url(${Triangle}) no-repeat 0 ${(props) => (props.isVisible ? '0' : '-16')}px;
  color: #18273e;
  width: 14px;
  height: 11px;
  margin-left: 20px;
`;
const NestedList = styled.ul`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  padding-left: 0px;
  margin-bottom: 0;
`;
const NestedNav = styled(NavA)`
  padding-left: 25px;
`;
const TabHeader = styled.p`
  color: #18273e;
  text-decoration: none;
  padding: 0.5em;
  display: block;
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    color: #153a54;
  }
`;
const TopNav = styled.div`
  color: ${color.blue};
  text-decoration: none;
  padding: 0.5em;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
`;

export default class NestedNavList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isNestedShown: props.isActive };
  }

  toggleNestedList = () => {
    const { isNestedShown } = this.state;
    const targetVisibility = !isNestedShown;
    this.setState({ isNestedShown: targetVisibility });
  };

  handleKey = (e) => {
    const { isNestedShown } = this.state;
    if (!isNestedShown && (e.key === 'Enter' || e.keyCode === 13)) {
      this.toggleNestedList();
    }
  };

  render() {
    const { linksObj, urlPrefix, isActive } = this.props;
    const { isNestedShown } = this.state;

    return (
      <TopNav className={isActive ? 'activeTopNavLink' : ''} onSelect={this.onSelect} onKeyDown={this.handleKey} tabIndex="0">
        <TabHeader onClick={this.toggleNestedList}>
          {linksObj.tabHeader}
          <ArrowContainer isVisible={isNestedShown} />
        </TabHeader>
        <Collapse in={isNestedShown}>
          <NestedList isVisible>
            {
              Object.keys(linksObj.mapRoutes).map((el) => (
                <NestedNav
                  onSelect={this.onSelect}
                  replace
                  key={el}
                  to={urlPrefix + el}
                >
                  {linksObj.mapRoutes[el]}
                </NestedNav>
              ))
            }
          </NestedList>
        </Collapse>
      </TopNav>
    );
  }
}

NestedNavList.propTypes = {
  isActive: PropTypes.bool,
  linksObj: PropTypes.shape({
    tabHeader: PropTypes.string.isRequired,
    mapRoutes: PropTypes.shape({}).isRequired,
  }).isRequired,
  urlPrefix: PropTypes.string.isRequired,
};
