import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';

import { color } from '../../../constants';

const Container = styled.div`
  margin: 10px 0 0 0;
`;
const ToggleButtonContainer = styled.div`
  position: relative;
  width: 35px;
  -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;

  input {
    display: none;
  }

  label {
    display: block; overflow: hidden; cursor: pointer;
    height: 12px; padding: 0; line-height: 20px;
    border: 0px solid #FFFFFF; border-radius: 30px;
    background-color: ${color.silverGrey};
  }

  label:before {
    content: "";
    display: block;
    width: 20px; margin: -4px;
    height: 20px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    border-radius: 50%;
    border: 1px solid  ${color.borderLightGrey};
  }

  input:checked + label {
    background-color: ${color.birch};
    border-color: #42A5F5;
  }

  input:checked + label:before {
    right: 0px; 
    border-color: ${color.borderLightGrey};
  }
`;

export default function Toggle({
  id,
  checked,
  labelText,
  labelColumnWidth,
  controlColumnWidth,
  ...other
}) {
  return (
    <Form.Group as={Row} controlId={id}>
      <Form.Label column sm={labelColumnWidth}>
        {labelText}
      </Form.Label>
      <Col sm={controlColumnWidth}>
        <Container>
          <ToggleButtonContainer>
            <input {...other} type="checkbox" id="toggle_button" checked={checked} />
            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/label-has-associated-control */ }
            <label htmlFor="toggle_button" />
          </ToggleButtonContainer>
        </Container>
      </Col>
    </Form.Group>
  );
}

Toggle.propTypes = {
  value: PropTypes.bool,
  checked: PropTypes.bool,
  id: PropTypes.string,
  labelText: PropTypes.string,
  labelColumnWidth: PropTypes.number,
  controlColumnWidth: PropTypes.number,
  onChange: PropTypes.func,
};
