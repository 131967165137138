import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { color } from '../../../constants';

const Text = styled.span`
  color: ${(props) => (props.color || '')};
  font-size: 16px;
`;

const reportStatusFormater = (value) => {
  let icon = null;
  switch (value) {
    case 'onhold':
      icon = <Text color={color.darkorange}><FontAwesomeIcon icon="pause-circle" /></Text>;
      break;
    case 'pending':
      icon = <Text color={color.darkorange}><FontAwesomeIcon icon={['far', 'clock']} /></Text>;
      break;
    case 'running':
      icon = <Text color={color.inputBorder}><FontAwesomeIcon icon="spinner" /></Text>;
      break;
    case 'error':
      icon = <Text color={color.textRed}><FontAwesomeIcon icon="times" /></Text>;
      break;
    case 'success':
      icon = <Text color={color.textGreen}><FontAwesomeIcon icon="check" /></Text>;
      break;
    default: console.error(`Unknown report status id: ${value};`); // eslint-disable-line no-console
  }
  return <div title={value} style={{ textAlign: 'center' }}>{icon}</div>;
};

export default reportStatusFormater;
