import React from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BASE_DECLINE_COMMENT } from '../../constants';

class UpdateDetailsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { declineComment: '' };
  }

  onChangeComment = (e) => {
    const value = e.target.value.slice(BASE_DECLINE_COMMENT.length);
    this.setState({ declineComment: value });
  }

  onDecline = () => {
    const { decline } = this.props;
    const { declineComment } = this.state;
    decline(declineComment || '');
  }

  render() {
    const {
      decline,
      onHide,
      ...other
    } = this.props;
    const { declineComment } = this.state;
    return (
      <Modal centered onHide={onHide} {...other}>
        <Modal.Header closeButton>
          Add the decline reason so the user will know what to send upon re-sending a request (your text will be
          displayed in the email sent to the user).
        </Modal.Header>
        <Modal.Body>
          <FormControl
            name="declineComment"
            value={BASE_DECLINE_COMMENT + declineComment}
            onChange={this.onChangeComment}
            rows={3}
            className="PopUp-Comment"
            as="textarea"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onDecline} disabled={!declineComment.trim()}>
            DECLINE
          </Button>
          <Button variant="outline-secondary" onClick={onHide}>CANCEL</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

UpdateDetailsModal.propTypes = {
  decline: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

export default UpdateDetailsModal;
