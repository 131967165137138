import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledLinkButton } from '../StyledComponents';
import getSelectedText from '../../utils/getSelectedText';
import { color } from '../../constants';

const Menu = styled.ul`
  list-style-type: none;
  position: fixed;
  padding: 0;
  z-index: 100;
  top: 500px;
  left: 500px;
  
  background-color: ${color.white};
  opacity: 0.95;
  border-radius: 6px;
  transform: translate(-50%, -50%);

  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  &::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${color.white} transparent transparent transparent;
  }
`;
const ContextMenu = ({ addToIgnoreList, children }) => {
  const [context, setContext] = useState({
    xPos: '0px',
    yPos: '0px',
    show: false,
  });

  const handleContextMenu = useCallback((e) => {
    e.preventDefault();
    const selection = getSelectedText();
    const text = selection.toString();
    if (text) {
      const rect = selection.getRangeAt(0).getBoundingClientRect();
      setContext(({
        xPos: rect.left + rect.width / 2,
        yPos: rect.top - 30,
        show: true,
      }));
    } else {
      setContext((prev) => ({ ...prev, show: false }));
    }
  }, []);

  const onMenuItemClick = () => {
    setContext((prev) => ({ ...prev, show: false }));
    addToIgnoreList();
  };

  const { show, yPos, xPos } = context;

  return (
    <div onPointerUp={handleContextMenu}>
      {children}
      {show && (
        <Menu style={{ top: yPos, left: xPos }}>
          <li>
            <StyledLinkButton variant="link" onClick={onMenuItemClick} title="Add to the ignore list">
              <FontAwesomeIcon icon={['far', 'eye-slash']} size="lg" />
            </StyledLinkButton>
          </li>
        </Menu>
      )}
    </div>
  );
};

ContextMenu.propTypes = {
  children: PropTypes.element,
  addToIgnoreList: PropTypes.func.isRequired,
};

export default ContextMenu;
