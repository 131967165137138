import React from 'react';
import ReactDOM from 'react-dom';

import { Router, Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { GoogleOAuthProvider } from '@react-oauth/google';

import reducer from './reducers';
import rootSaga from './sagas';
import redirect from './middlewares/redirect';

import history from './history';

import * as serviceWorker from './serviceWorker';
import { setAPIUrl, setAuthToken } from './utils/request';
import { GAPI_CLIENT_ID } from './constants';

import App from './containers/App';

import GlobalStyle from './global-styles';
import './fonts.css';
import './fontawesome';

const token = localStorage.getItem('token'); // eslint-disable-line no-undef
if (token) setAuthToken(token);

setAPIUrl();

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  thunk,
  sagaMiddleware,
  redirect,
];
const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <>
    <GlobalStyle />
    <Provider store={store}>
      <GoogleOAuthProvider clientId={GAPI_CLIENT_ID}>
        <Router history={history}>
          <Route component={App} />
        </Router>
      </GoogleOAuthProvider>
    </Provider>
  </>,
  document.getElementById('app'), // eslint-disable-line no-undef
);

serviceWorker.register();
