import axios from 'axios';

import {
  VENDOR_CATEGORIZATION_LIST_GET,
  VENDOR_CATEGORIZATION_LIST_GET_SUCCESS,

  VENDOR_CATEGORIZATION_SAVE,
  VENDOR_CATEGORIZATION_SAVE_SUCCESS,

  VENDOR_CATEGORIZATION_FAIL,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startFetchingVendorCategorization = () => ({
  type: VENDOR_CATEGORIZATION_LIST_GET,
});
const fetchVendorCategorizationSuccess = (list) => ({
  type: VENDOR_CATEGORIZATION_LIST_GET_SUCCESS,
  payload: list,
});

const startSaveVendorCategorization = () => ({
  type: VENDOR_CATEGORIZATION_SAVE,
});
const saveVendorCategorizationSuccess = (list) => ({
  type: VENDOR_CATEGORIZATION_SAVE_SUCCESS,
  payload: list,
});

const vendorCategorizationFail = () => ({
  type: VENDOR_CATEGORIZATION_FAIL,
});

export const fetchVendorsCategory = (userId) => async (dispatch) => {
  dispatch(startFetchingVendorCategorization());

  try {
    const response = await axios(`/rest/default/V1/eplane/backoffice/user/${userId}/categories`);
    dispatch(fetchVendorCategorizationSuccess(response.data));
  } catch (e) {
    requestErrorHandler(e.response, dispatch, vendorCategorizationFail, e);
  }
};

export const saveVendorsCategory = (dataArray, userId) => async (dispatch) => {
  dispatch(startSaveVendorCategorization());
  const body = {
    req: {
      user_categories: dataArray,
    },
  };

  try {
    const response = await axios.post(`/rest/default/V1/eplane/backoffice/user/${userId}/categories`, body);
    dispatch(saveVendorCategorizationSuccess(response.data));
    dispatch(updateComments());
  } catch (e) {
    requestErrorHandler(e.response, dispatch, vendorCategorizationFail, e);
  }
};
