import {
  EMAIL_TEMPLATES_FETCH,
  EMAIL_TEMPLATES_IS_FETCHED,
  ERROR,
} from '../../constants';

const initialState = {
  templatesList: [],
  isFetching: false,
  isError: false,
};

const emails = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EMAIL_TEMPLATES_FETCH: return {
      ...state,
      isFetching: true,
      isError: false,
    };
    case EMAIL_TEMPLATES_IS_FETCHED:
      return {
        ...state,
        templatesList: payload,
        isFetching: false,
      };
    case ERROR:
      return {
        ...state,
        isError: true,
        isFetching: false,
      };
    default: return state;
  }
};

export default emails;
