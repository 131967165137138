import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import Select from 'react-select';

import {
  ButtonsWrapper, Button, Text, Body,
} from '../styledComponents';
import UniversalFormControl from '../../FormComponents/BaseFormGroup';
import createErrorValidationObj from '../../../utils/createErrorValidationObj';

const InputContainer = styled.div`
  display: flex;
  justify-content: center;

  input[name=fee] {
    height: 38px;
  }
  span {
    margin-left: 8px;
    font-size: 16px;
  }
`;

const commissionValidation = (value) => {
  if (!value) return createErrorValidationObj('Please enter a commission');
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || !value.match(/\d+$/)) return createErrorValidationObj('Please enter only digits');
  if (numVal < 0) return createErrorValidationObj('Please enter a positive number');
  return null;
};

const CommissionModal = ({
  fee, onCommissionChange, onHide, onConfirm, companyName, title, text, ...other
}) => (
  <Modal centered onHide={onHide} {...other}>
    <Body>
      <Text>{title}</Text>
      <br />
      <p>
        {text
          ? `${text}`
          : `Please make sure you supply the correct value for ${companyName}:`}
      </p>
      <InputContainer>
        <UniversalFormControl
          placeholder=""
          name="fee"
          value={(fee && fee.amount) || ''}
          onChange={onCommissionChange}
          maxLength={5}
          autoComplete="off"
          {...commissionValidation(fee && fee.amount)}
        />
        <span>
          <Select
            value={{ label: '%', value: 'd' }}
            name="reason"
            options={[{ label: '%', value: 'd' }]}
            isDisabled
          />
        </span>
      </InputContainer>
    </Body>
    <Modal.Footer>
      <ButtonsWrapper>
        <Button variant="primary" onClick={onConfirm} disabled={!!commissionValidation(fee && fee.amount)}>Save</Button>
        <Button variant="outline-secondary" onClick={onHide}>Cancel</Button>
      </ButtonsWrapper>
    </Modal.Footer>
  </Modal>
);

CommissionModal.propTypes = {
  fee: PropTypes.shape({
    amount: PropTypes.string,
  }),
  title: PropTypes.string,
  text: PropTypes.string,
  companyName: PropTypes.string,
  onHide: PropTypes.func,
  onCommissionChange: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default CommissionModal;
