import React, { useState, createRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button, ButtonGroup, OverlayTrigger, Tooltip, Row, Col, FormGroup, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ConfirmationModal from '../../Modals/ConfirmationModal';
import { ApprovedBlockedSellersOption, ApprovedBlockedSellersSingleValue } from '../SellersLists/Helpers';
import { HorizontalLine as Hr } from '../StyleComponents';
import { sellersListTypes } from '../../../constants/index';
import { color } from '../../../constants';

const ButtonContainer = styled.div`
  align-self: flex-start;
  padding-right: 10px;
  padding-bottom: 15px;
`;
const RadioGroupTitle = styled.p`
  margin-bottom: 25px;
`;
const HorizontalLine = styled(Hr)`
  margin: 30px 0 40px 0;
`;

const tooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

const WhiteBlackListForm = ({
  whiteList, blackList, customListSellerIds, isApprovedList, options, onChange, onListTypeChange, disabled,
}) => {
  const ref = createRef();
  const [modals, setModal] = useState({
    removeCustomListConfirmation: null,
  });

  useEffect(() => {
    if (ref.current) ref.current.focus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whiteList, blackList]);

  let whiteListContent = null;
  let blackListContent = null;

  const handleAddWhiteList = () => {
    if (whiteList[whiteList.length - 1] && !whiteList[whiteList.length - 1].value) return;
    onChange({ whiteList: [...whiteList, {}] });
  };

  const handleRemoveWhiteList = (idx) => {
    if (customListSellerIds.includes(whiteList[idx].value)) {
      setModal({ ...modals, removeCustomListConfirmation: { show: true, data: idx } });
    } else {
      const newList = [...whiteList];
      newList.splice(idx, 1);
      onChange({ whiteList: newList });
    }
  };

  const handleAddBlackList = () => {
    if (blackList[blackList.length - 1] && !blackList[blackList.length - 1].value) return;
    onChange({ blackList: [...blackList, {}] });
  };

  const handleWhiteListKey = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      handleAddWhiteList();
    }
  };

  const handleRemoveBlackList = (idx) => {
    const newList = [...blackList];
    newList.splice(idx, 1);
    onChange({ blackList: newList });
  };

  const onListItemChange = (selectedOption, action, idx) => {
    const newList = action.name === 'autopilot_buyer_whitelist' ? [...whiteList] : [...blackList];
    newList[idx] = selectedOption;
    const name = action.name === 'autopilot_buyer_whitelist' ? 'whiteList' : 'blackList';
    onChange({ [name]: newList });
  };

  const onCustomSellerAddConfirm = () => {
    const newList = [...whiteList];
    newList.splice(modals.removeCustomListConfirmation.data, 1);
    onChange({ whiteList: newList });
    setModal({ ...modals, removeCustomListConfirmation: null });
  };

  const handleBlackListKey = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      handleAddBlackList();
    }
  };

  const emptyListContent = (title, text, actionHandler) => (
    <>
      <p><b>{title}</b></p>
      <p>{text}</p>
      <div>
        <OverlayTrigger placement="right" overlay={tooltip('Add')}>
          <Button
            onClick={actionHandler}
            variant="success"
            disabled={disabled}
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            Add sellers
          </Button>
        </OverlayTrigger>
      </div>
      <br />
    </>
  );

  if (whiteList && whiteList.length > 0) {
    whiteListContent = (
      whiteList.map((v, i) => (
        <Row key={v.value || i.toString()}>
          <Col sm={6}>
            <Select
              ref={ref}
              value={v}
              name="autopilot_buyer_whitelist"
              options={options.filter((t) => whiteList.every((t1) => t1.value !== t.value))}
              hideSelectedOptions={false}
              isDisabled={disabled}
              onChange={(selectedOption, action) => onListItemChange(selectedOption, action, i)}
              onKeyDown={handleWhiteListKey}
            />
          </Col>

          <Col sm={6}>
            <ButtonContainer>
              <ButtonGroup>
                <OverlayTrigger placement="top" overlay={tooltip('Add company (hotkey: enter)')}>
                  <Button disabled={disabled} onClick={handleAddWhiteList}><b>+</b></Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={tooltip('Remove company')}>
                  <Button disabled={disabled} onClick={() => handleRemoveWhiteList(i)}><b>-</b></Button>
                </OverlayTrigger>
              </ButtonGroup>
            </ButtonContainer>
          </Col>
        </Row>
      )));
  } else if (whiteList) {
    whiteListContent = <>{emptyListContent('Approved seller management', 'Add sellers to your approved list: ', handleAddWhiteList)}</>;
  }

  if (blackList && blackList.length > 0) {
    blackListContent = (
      blackList.map((v, i) => (
        <Row key={v.value || i.toString()}>
          <Col sm={6}>
            <Select
              ref={ref}
              value={v}
              name="autopilot_buyer_blacklist"
              options={options.filter((t) => blackList.every((t1) => t1.value !== t.value))}
              hideSelectedOptions={false}
              isDisabled={disabled}
              onChange={(selectedOption, action) => onListItemChange(selectedOption, action, i)}
              onKeyDown={handleBlackListKey}
              components={{ Option: ApprovedBlockedSellersOption, SingleValue: ApprovedBlockedSellersSingleValue }}
            />
          </Col>
          <Col sm={6}>
            <ButtonContainer>
              <ButtonGroup>
                <OverlayTrigger placement="top" overlay={tooltip('Add')}>
                  <Button variant="outline-secondary" disabled={disabled} onClick={handleAddBlackList}><b>+</b></Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={tooltip('Remove')}>
                  <Button variant="outline-secondary" disabled={disabled} onClick={() => handleRemoveBlackList(i)}><b>-</b></Button>
                </OverlayTrigger>
              </ButtonGroup>
            </ButtonContainer>
          </Col>
        </Row>
      )));
  } else if (blackList) {
    blackListContent = <>{emptyListContent('Blocked seller management', 'Add sellers to your blocked list: ', handleAddBlackList)}</>;
  }

  return (
    <>
      <RadioGroupTitle>Select the list you would like to manage:</RadioGroupTitle>
      <FormGroup controlId="changeListTypeForm">
        <Form.Check
          label={(
            <p>
              <b>Blocked sellers</b>
              &nbsp;- ePlane Autopilot will not send RFQs to sellers in this list
            </p>
          )}
          type="radio"
          name="listTypeGroup"
          onChange={onListTypeChange}
          checked={!isApprovedList}
          disabled={disabled}
          value={sellersListTypes.BLOCKED}
        />
        <Form.Check
          label={(
            <p>
              <b>Approved sellers</b>
              &nbsp;- ePlane Autopilot will send RFQs only to sellers in this list
            </p>
          )}
          type="radio"
          name="listTypeGroup"
          onChange={onListTypeChange}
          checked={isApprovedList}
          disabled={disabled}
          value={sellersListTypes.APPROVED}
        />
      </FormGroup>
      <HorizontalLine />
      {isApprovedList ? whiteListContent : blackListContent}

      <ConfirmationModal
        show={!!modals.removeCustomListConfirmation}
        confirm={onCustomSellerAddConfirm}
        onHide={() => setModal({ ...modals, removeCustomListConfirmation: null })}
        confirmBtnText="I'm sure"
        cancelBtnText="Cancel"
      >
        <>
          <FontAwesomeIcon icon="exclamation-circle" color={`${color.goldenTainoi}`} size="2x" />
          <h3>This seller is listed in your custom lists.</h3>
          <br />
          <p>
            Removing it from your approved sellers, will remove it from custom lists as well. Are you sure you wish to remove it?
          </p>
        </>
      </ConfirmationModal>
    </>
  );
};

WhiteBlackListForm.propTypes = {
  whiteList: PropTypes.arrayOf(PropTypes.object),
  blackList: PropTypes.arrayOf(PropTypes.object),
  customListSellerIds: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  isApprovedList: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onListTypeChange: PropTypes.func.isRequired,
};

export default WhiteBlackListForm;
