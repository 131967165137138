import React from 'react';
import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';

import InventoryPage from '../../components/CompaniesManagement/InventoryManagementPage';
import WeightCompanyGroups from '../../components/GeneralSettings/WeightCompanyGroups';
import DemandCategories from '../../components/GeneralSettings/DemandCategories';
import CommissionsPage from '../../components/GeneralSettings/Commission';
import EmailsPage from '../../components/GeneralSettings/Emails';
import MailchimpPage from '../../components/GeneralSettings/MailchimpPage';
import DevNews from '../../components/GeneralSettings/News';
import FeaturedIn from '../../components/GeneralSettings/FeaturedIn';
import OrdersLimitedValuesPage from '../../components/GeneralSettings/OrderLimits';
import BankAccountPage from '../../components/GeneralSettings/BankAccount';
import ProPricePage from '../../components/GeneralSettings/Pro';
import TasksManagement from '../../components/GeneralSettings/TasksManagement';

const TabsSettings = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Redirect exact from={match.path} to={match.url[match.url.length - 1] === '/' ? `${match.url}inventory` : `${match.url}/inventory`} />
      <Route path={`${match.path}/inventory`}><InventoryPage /></Route>
      <Route exact path={`${match.path}/companyGroup/`}><WeightCompanyGroups /></Route>
      <Route path={`${match.path}/companyGroup/:listId`}><WeightCompanyGroups /></Route>
      <Route path={`${match.path}/demandCategories`}><DemandCategories /></Route>
      <Route path={`${match.path}/commission`}><CommissionsPage /></Route>
      <Route path={`${match.path}/emails`}><EmailsPage /></Route>
      <Route path={`${match.path}/mailchimp`}><MailchimpPage /></Route>
      <Route path={`${match.path}/proPrice`}><ProPricePage /></Route>
      <Route path={`${match.path}/blogPosts`}><DevNews /></Route>
      <Route path={`${match.path}/featuredIn`}><FeaturedIn /></Route>
      <Route path={`${match.path}/ordersLimits`}><OrdersLimitedValuesPage /></Route>
      <Route path={`${match.path}/bankAccount`}><BankAccountPage /></Route>
      <Route path={`${match.path}/tasks`}><TasksManagement /></Route>
    </Switch>
  );
};

export default TabsSettings;
