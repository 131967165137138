import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormTitleSection from '../../FormComponents/TitleSection/index';
import { TabContainer } from '../../StyledComponents';
import { fetchOrderLimits, saveOrderLimits, resetOrderLimits } from '../../../actions/settings/orderLimits';
import { getOrderLimitsStore, getErrorsStore } from '../../../selectors';
import LimitSetting from './LimitSetting';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';
import LimitModal from './LimitModal';
import createErrorValidationObj from '../../../utils/createErrorValidationObj';

const limitValidation = (value) => {
  if (!value) return createErrorValidationObj('Please enter a limit');
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || !value.match(/\d+$/)) return createErrorValidationObj('Please enter only digits');
  if (numVal < 0) return createErrorValidationObj('Please enter a positive number');
  return null;
};

const limits = [{
  name: 'ep_limit_min',
  title: 'Minimum Order Value (for a single order)',
  label: (
    <>
      The minimum value allowed per a single order that is paid through ePlane.
      <br />
      It will not be allowed to pay through ePlane if the order total is below this value.
    </>
  ),
  modalTitle: 'Edit minimum order value ',
}, {
  name: 'ep_limit_max',
  title: 'Maximum order value (for a single order)',
  label: (
    <>
      The maximum value allowed per a single order that is paid through ePlane.
      <br />
      It will not be allowed to pay through ePlane if the order total is above this value.
    </>
  ),
  modalTitle: 'Edit maximum order value ',
}, /* {
  name: 'max_month_order_limit',
  title: 'Monthly Maximum order value',
  label: (
    <>
      The maximum orders sum allowed for orders that were paid through ePlane
      by all the users of the same vetted company/ by a private user, in the last 30 days.
      <br />
      It will not be allowed to pay through ePlane if the sum of those order is above this value.
    </>
  ),
  isDisabled: true,
  modalTitle: 'Edit Monthly Maximum Orders Sum',
} */];

const MovPage = () => {
  const dispatch = useDispatch();

  const [orderLimit, setOrderLimit] = useState(null);

  const {
    orderLimits, isFetching, isProcessing,
  } = useSelector(getOrderLimitsStore);
  const { errors } = useSelector(getErrorsStore);

  const saveOrderLimit = () => {
    const d = orderLimits.filter((v) => v.setting !== orderLimit.setting);
    dispatch(saveOrderLimits({
      settings: [...d, orderLimit],
    }));
    setOrderLimit(null);
  };

  useEffect(() => {
    dispatch(fetchOrderLimits());
    return () => dispatch(resetOrderLimits());
  }, [dispatch]);

  const onLimitChange = (name) => {
    const item = orderLimits.find((v) => v.setting === name);
    setOrderLimit({ setting: name, value: item?.value ?? '' });
  };

  if ((!orderLimits || isFetching) && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <>
      <FormTitleSection title="Orders Limited Values (When Paying Through ePlane)" showEditButton={false} />
      <TabContainer>
        <p>
          There are 2 limitations on the values of orders paid through ePlane.
          <br />
          The values described here are general values, meaning,&nbsp;
          they are relevant for all the vetted and companies private users registered on ePlane.
          <br />
          {
          // (If you wish to customize these values for a specific company private user,&nbsp;
          // you may do that from the company&apos;s private user&apos;s settings.)
          }
        </p>
        <br />

        {limits.map((v) => (
          <LimitSetting
            key={v.name}
            name={v.name}
            title={v.title}
            labelText={v.label}
            amount={orderLimits.find((item) => (item.setting === v.name))?.value ?? ''}
            currency="USD"
            isDisabled={v.isDisabled}
            onEditClick={() => onLimitChange(v.name)}
          />
        ))}
      </TabContainer>

      <LimitModal
        title={orderLimit && limits.find((v) => v.name === orderLimit.setting).title}
        text="Please make sure you supply the correct value:"
        amount={orderLimit?.value ?? ''}
        currency="USD"
        validation={limitValidation}
        onConfirm={saveOrderLimit}
        onLimitChange={(e) => setOrderLimit({ ...orderLimit, value: e.target.value })}
        show={!!orderLimit}
        onHide={() => setOrderLimit(null)}
      />
      <ModalLoader show={isProcessing} />
    </>
  );
};

export default MovPage;
