import {
  UPLOADING_START,
  UPLOADING_FINISH,
  UPLOADING_PROGRESS_SET,
  UPLOADING_PROGRESS_RESET,
  UPLOADING_TERMINATE,
  UPLOADING_ERROR_OCCURRED,
  UPLOADING_RESET,

  AXIOS_CANCELLED_MESSAGE,
} from '../constants';

const initialState = {
  files: null,
  uploadingProgress: null,
  source: null,
  isUploading: false,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPLOADING_START:
      return {
        ...state,
        isUploading: true,
        uploadingProgress: null,
        source: payload,
      };

    case UPLOADING_FINISH:
      return {
        ...state,
        files: payload,
        isUploading: false,
        source: false,
      };

    case UPLOADING_PROGRESS_SET:
      return { ...state, uploadingProgress: payload };
    case UPLOADING_PROGRESS_RESET:
      return { ...state, uploadingProgress: null };
    case UPLOADING_TERMINATE:
      if (state.source) {
        state.source.cancel(AXIOS_CANCELLED_MESSAGE);
      }
      return {
        ...state,
        uploadingProgress: null,
        isUploading: false,
        source: null,
      };
    case UPLOADING_ERROR_OCCURRED:
      return {
        ...state,
        error: payload,
        isUploading: false,
        uploadingProgress: null,
      };

    case UPLOADING_RESET:
      return { ...initialState };

    default:
      return state;
  }
}
