import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ClosedRequests from '../../components/ClosedRequests';
import ClosedDetailsPage from '../../components/ClosedDetailsPage';
import { resetDataOfClosedRequests } from '../../actions/closedRequestsActions';

export default function ClosedRequestsPage() {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  useEffect(() => () => {
    dispatch(resetDataOfClosedRequests());
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path={match.path}><ClosedRequests /></Route>
      <Route path={`${match.path}/:id`}><ClosedDetailsPage /></Route>
    </Switch>
  );
}
