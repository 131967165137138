import {
  take, takeEvery, call, put, fork, select, cancel,
} from 'redux-saga/effects';

import {
  CHECK_LOGGED_IN_START,
  CHECK_LOGGED_IN_VERIFIED,
  CHECK_LOGGED_IN_FAILED,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_REQUEST,
  AUTHORIZATION_FAIL,
  SERVER_CONSTANTS_GET_SUCCESS,

  PUBLIC_URL,
} from '../constants';
import { replaceRedirect, pushRedirect } from '../actions/redirectActions';
import Api from '../API';
import roles from '../constants/backofficeRoles';
import { setAuthToken } from '../utils/request';

import nocTaskChecker from './nocTasksChecker';

function* authorize(jwt) {
  try {
    const { data } = yield call(Api.loginViaGoogle, jwt.token);

    localStorage.setItem('token', data.token); // eslint-disable-line no-undef
    localStorage.setItem('adminCustomerId', data.customer.entity_id); // eslint-disable-line no-undef
    localStorage.setItem('role', roles.ADMIN); // eslint-disable-line no-undef
    setAuthToken(data.token);

    yield put({
      type: LOGIN_SUCCESS,
      payload: {
        token: data.token,
        customerId: data.customer.entity_id,
        role: roles.ADMIN,
      },
    });

    const prevPath = yield select((state) => state?.authorization.path || PUBLIC_URL);
    yield put(replaceRedirect(prevPath));
  } catch (error) {
    let message = null;
    if (error?.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      message = 'Wrong credentials or permission denied.';
    } else if (error?.request) {
      // The request was made but no response was received
      // `error.request` is an intance of XMLHttpReqsuest in the browser
      message = 'Network Error.';
      console.error('NETWORK_ERROR', error?.request); // eslint-disable-line no-console
    } else {
      // Something happened in setting up the request that triggered an Error
      message = 'Something went wrong.';
      console.error('ERROR', error?.message); // eslint-disable-line no-console
    }
    yield put({ type: LOGIN_FAIL, payload: message });
  }
}

function* checkLoggedIn() {
  try {
    const { data } = yield call(Api.checkLoggedIn);
    if (data) {
      yield put({ type: CHECK_LOGGED_IN_VERIFIED });
    } else {
      yield put({ type: CHECK_LOGGED_IN_FAILED });
      yield put(pushRedirect(`${PUBLIC_URL}/login`));
    }

    return data;
  } catch (error) {
    yield put({ type: CHECK_LOGGED_IN_FAILED });
    yield put(pushRedirect(`${PUBLIC_URL}/login`));
    return false;
  }
}

export default function* loginFlow() {
  while (true) {
    const a = yield take([CHECK_LOGGED_IN_START, LOGIN]);
    if (a.type === CHECK_LOGGED_IN_START) {
      yield fork(checkLoggedIn);
    } else if (a.type === LOGIN) {
      yield fork(authorize, a.payload);
    }

    const constsFetching = yield takeEvery([CHECK_LOGGED_IN_VERIFIED, LOGIN_SUCCESS], function* fetching() {
      const serverConstants = yield call(Api.fetchServerConstants);
      yield put({
        type: SERVER_CONSTANTS_GET_SUCCESS,
        payload: serverConstants,
      });
    });

    const tasksChecker = yield fork(nocTaskChecker);

    const action = yield take([LOGOUT_REQUEST, CHECK_LOGGED_IN_FAILED, LOGIN_FAIL, AUTHORIZATION_FAIL]);

    yield cancel(constsFetching);
    yield cancel(tasksChecker);

    if (action.type === LOGOUT_REQUEST) {
      yield call(Api.logOut);
    }

    localStorage.setItem('token', ''); // eslint-disable-line no-undef
    yield put(pushRedirect(`${PUBLIC_URL}/login`));
  }
}

export const checkAuth = () => ({ type: CHECK_LOGGED_IN_START });

export const login = (tokenId) => ({ type: LOGIN, payload: tokenId });

export const logout = ({ type: LOGOUT_REQUEST });
