import {
  ERRORS_ADD,
  ERRORS_RESET,
} from '../constants';

const initialState = {
  errors: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ERRORS_ADD:
      return {
        ...state,
        errors: [...state.errors, payload],
      };

    case ERRORS_RESET:
      return {
        ...state,
        errors: payload ? state.errors.filter((error) => error.error !== payload) : [],
      };

    default: return state;
  }
}
