import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import * as consts from '../../../constants';

const ToggleButtonContainer = styled.div`
  position: relative;
  width: 35px;
  -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;

  input {
    display: none;
  }

  label {
    display: block; overflow: hidden; cursor: pointer;
    height: 12px; padding: 0; line-height: 20px;
    border: 0px solid #FFFFFF; border-radius: 30px;
    background-color: ${consts.color.silverGrey};
  }

  label:before {
    content: "";
    display: block;
    width: 20px; margin: -4px;
    height: 20px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    border-radius: 50%;
    border: 1px solid  ${consts.color.borderLightGrey};
  }

  input:checked + label {
    background-color: ${consts.color.birch};
    border-color: #42A5F5;
  }

  input:checked + label:before {
    right: 0px; 
    border-color: ${consts.color.borderLightGrey};
  }
`;

class ToggleButton extends React.PureComponent {
  render() {
    const {
      id, onNotificationChange, checked, ...props
    } = this.props;
    return (
      <ToggleButtonContainer>
        <input
          type="checkbox"
          id={id}
          onChange={onNotificationChange}
          checked={checked}
          data-testid={`toggle_${id}`}
          {...props}
        />
        { /* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/label-has-associated-control */ }
        <label htmlFor={id} />
      </ToggleButtonContainer>
    );
  }
}

ToggleButton.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  onNotificationChange: PropTypes.func,
};

export default ToggleButton;
