import {
  BOUNCE_EMAILS_GET,
  BOUNCE_EMAILS_GET_SUCCESS,
  BOUNCE_EMAILS_FAIL,
} from '../../constants';

const initialState = {
  items: [],
  filters: null,
  totalPages: null,
  totalItems: null,
  pageSize: null,
  page: null,
  isFetching: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case BOUNCE_EMAILS_GET: return {
      ...state,
      isFetching: true,
    };
    case BOUNCE_EMAILS_GET_SUCCESS:
      return {
        ...state,
        items: payload.items,
        filters: payload.filter,
        pageSize: payload.per_page,
        totalPages: payload.total_pages,
        totalItems: payload.total_items,
        page: payload.page,
        isFetching: false,
      };
    case BOUNCE_EMAILS_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    default: return state;
  }
};
