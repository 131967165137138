import axios from 'axios';

import {
  LEGAL_ENTITIES_GET,
  LEGAL_ENTITIES_GET_SUCCESS,
  LEGAL_ENTITIES_GET_FAIL,
  LEGAL_ENTITIES_ERROR_REMOVE,
} from '../constants';
import errorHandler from './requestErrorHandler';

const startFetchingLegalEntities = () => ({
  type: LEGAL_ENTITIES_GET,
});
const fetchLegalEntitiesSuccess = (filters) => ({
  type: LEGAL_ENTITIES_GET_SUCCESS,
  payload: filters,
});
const fetchLegalEntitiesFail = (errorCode) => ({
  type: LEGAL_ENTITIES_GET_FAIL,
  payload: errorCode,
});

export const removeLegalEntitiesError = () => ({
  type: LEGAL_ENTITIES_ERROR_REMOVE,
});

export const fetchLegalEntities = () => async (dispatch) => {
  dispatch(startFetchingLegalEntities);
  try {
    const response = await axios('rest/V1/eplane/backoffice/legalEntities');
    dispatch(fetchLegalEntitiesSuccess(response.data));
  } catch (e) {
    errorHandler(e.response, dispatch, fetchLegalEntitiesFail, e);
  }
};
