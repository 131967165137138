import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchInventoryList } from '../../../actions/inventoryListActions';
import { fetchCompanyList, errorRemove as removeCompanyListError } from '../../../actions/companyListActions';
import { getInventoryListStore, getCompanyListStore } from '../../../selectors';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import { TabContainer } from '../../StyledComponents';
import Loader from '../../Loader';
import ModalLoader from '../../ModalLoader';
import IMTable from '../Table';
import PaginationTable from '../../OrderManagement/SearchOrders/PaginationTable';
import ErrorModal from '../../ErrorModal';
import InventoryUploadModal from '../Table/InventoryUploadModal';

export default function InventoryManagementPage() {
  const refLocation = useRef(null);

  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  const [showInventoryModal, setInventoryModal] = useState(false);

  const {
    inventoryList: {
      queue,
      totalRecords, pageSize, page,
    },
    isFetching,
  } = useSelector(getInventoryListStore);
  const {
    companies,
    isFetching: isCompanyListFetching,
    errorCode,
  } = useSelector(getCompanyListStore);

  useEffect(() => {
    const currQuery = queryString.parse(location.search);

    dispatch(fetchCompanyList());
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({ page: currQuery.page || 1 }),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const currQuery = queryString.parse(location.search);
    const prevQuery = queryString.parse(refLocation.current);
    if (currQuery.page !== prevQuery.page) {
      dispatch(fetchInventoryList(currQuery.page || 1));
    }

    refLocation.current = location.search;
  }, [dispatch, location.search]);

  useEffect(() => {
    const currQuery = queryString.parse(location.search);
    if (!showInventoryModal) {
      dispatch(fetchInventoryList(currQuery.page || 1));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, showInventoryModal]);

  const onUploadClick = () => setInventoryModal(true);

  const onUploadHide = () => setInventoryModal(false);

  const pageChanged = (pg) => {
    const query = queryString.parse(location.search);
    query.page = pg;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(query),
    });
  };

  const loader = !queue ? <Loader /> : null;

  const length = queue && queue.length;
  const totalPages = Math.ceil(totalRecords / pageSize);

  const jobData = queue && companies && queue.map((v) => {
    const company = companies.find((c) => c.company_id === v.user_id);
    const companyName = company && company.company_name;
    return { ...v, companyName };
  });
  const options = {
    expanding: [],
  };

  return (
    <>
      <FormTitleSection title="Inventory" showEditButton={false} />
      <TabContainer>
        {loader || (
          <>
            <Button onClick={onUploadClick} variant="success" size="sm">
              <FontAwesomeIcon icon="plus" />
              &nbsp;Upload
            </Button>

            <IMTable data={jobData} options={options} />
            {length < totalRecords && <PaginationTable onChange={pageChanged} currentPage={page} totalPages={totalPages} />}
            <InventoryUploadModal show={showInventoryModal} onHide={onUploadHide} />
            <ModalLoader show={isFetching || isCompanyListFetching} />
          </>
        )}
        <ErrorModal error={errorCode} onHide={() => dispatch(removeCompanyListError())} />
      </TabContainer>
    </>
  );
}
