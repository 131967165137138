import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ExpandableTableStyles } from '../../Table/TableStyles';
import Table from '../../Table/TableWithExpanding';
import header from '../../Table/HeaderWithSort';
import CellExpandable from '../../Table/CellExpandable';
import CellWithTitle from '../../Table/Cell';

import dateFormat from '../../../utils/dateFormat';
import ExpandComponent from './ExpandComponent';
import reportStatusFormatter from './reportStatusFormater';
import inventoryStatusFormatter from './inventoryStatusFormatter';
import originStatusFormatter from './originStatusFormatter';

const TableContainer = styled(ExpandableTableStyles)`
  margin: 5px 0 20px 0;
`;

export default function IMTable({ data, title, noDataContent }) {
  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: CellExpandable,
        style: { width: '35px' },
      }, {
        Header: () => header('Job id'),
        accessor: 'job_id',
        style: { width: '80px' },
      }, {
        Header: () => header('User id'),
        accessor: 'user_id',
        Cell: CellWithTitle,
        style: { width: '80px' },
      }, {
        Header: () => header('Company'),
        accessor: 'companyName',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Site id'),
        accessor: 'site_id',
        Cell: CellWithTitle,
        style: { width: '80px' },
      }, {
        Header: () => header('Origin'),
        accessor: 'origin',
        Cell: ({ value }) => originStatusFormatter(value),
        style: { width: '60px' },
      }, {
        Header: () => header('Job Type'),
        accessor: 'job_type',
        Cell: CellWithTitle,
        style: { width: '100px' },
      }, {
        Header: () => header('Status'),
        accessor: 'status',
        Cell: ({ value }) => inventoryStatusFormatter(value),
        style: { width: '60px' },
      }, {
        Header: () => header('Report status'),
        accessor: 'report_status',
        Cell: ({ value }) => reportStatusFormatter(value),
        style: { width: '100px' },
      }, {
        Header: () => header('Created at'),
        accessor: 'created_at',
        Cell: ({ value }) => dateFormat(value),
        style: { width: '180px' },
      }, {
        Header: () => header('Updated at'),
        accessor: 'updated_at',
        Cell: ({ value }) => dateFormat(value),
        style: { width: '180px' },
      },
    ], [],
  );

  const options = useMemo(
    () => ({
      renderRowSubComponent: ExpandComponent,
      noDataContent,
    }), [noDataContent],
  );

  return (
    <TableContainer>
      {title}
      <Table columns={columns} data={data} options={options} />
    </TableContainer>
  );
}

IMTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]),
  title: PropTypes.string,
  noDataContent: PropTypes.element,
};
