import axios from 'axios';

import {
  BUYERS_LISTS_GET,
  BUYERS_LISTS_SUCCESS,

  BUYERS_LISTS_SAVE,
  BUYERS_LISTS_SAVE_SUCCESS,

  BUYERS_LISTS_DELETE,
  BUYERS_LISTS_DELETE_SUCCESS,

  BUYERS_LISTS_FAIL,
  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';
import { updateComments } from '../chatActions';
import { buyerFilterTypes } from '../../constants/index';

const startFetchBuyersLists = () => ({ type: BUYERS_LISTS_GET });
const fetchBuyersListsSuccess = (list) => ({
  type: BUYERS_LISTS_SUCCESS,
  payload: list,
});

const startSaveBuyersLists = () => ({ type: BUYERS_LISTS_SAVE });
const saveBuyersListsSuccess = (list) => ({
  type: BUYERS_LISTS_SAVE_SUCCESS,
  payload: list,
});

const startRemoveBuyersList = () => ({ type: BUYERS_LISTS_DELETE });

const removeBuyersSuccess = (filterId) => ({
  type: BUYERS_LISTS_DELETE_SUCCESS,
  payload: filterId,
});

const buyersListsFail = () => ({ type: BUYERS_LISTS_FAIL });

export const fetchBuyersLists = (userId, sortParam) => async (dispatch) => {
  dispatch(startFetchBuyersLists());
  try {
    const response = await axios(`/rest/V2/eplane/backoffice/users/${userId}/buyer-lists/filters`, { params: sortParam });
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchBuyersListsSuccess(response.data?.payload));
    } else {
      requestErrorHandler(response, dispatch, buyersListsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, buyersListsFail, e);
  }
};

export const saveApprovedBlockedList = (data, userId, category) => async (dispatch) => {
  dispatch(startSaveBuyersLists());
  let url = null;
  if (category === buyerFilterTypes.buyer_type_filters) {
    url = `/rest/V2/eplane/backoffice/users/${userId}/buyer-lists/filters/type`;
  } else if (category === buyerFilterTypes.buyer_country_filters) {
    url = `/rest/V2/eplane/backoffice/users/${userId}/buyer-lists/filters/country`;
  } else if (category === buyerFilterTypes.buyer_company_filters) {
    url = `/rest/V2/eplane/backoffice/users/${userId}/buyer-lists/filters/company`;
  } else if (category === buyerFilterTypes.buyer_private_user_filters) {
    url = `/rest/V2/eplane/backoffice/users/${userId}/buyer-lists/filters/private`;
  }

  try {
    const response = await axios.post(url, data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveBuyersListsSuccess(response.data?.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, buyersListsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, buyersListsFail, e);
  }
};

export const removeSellersList = (filterId, userId, sortParam) => async (dispatch) => {
  dispatch(startRemoveBuyersList());
  const params = { filter_id: filterId, ...sortParam };

  try {
    const response = await axios.post(`/rest/V2/eplane/backoffice/users/${userId}/buyer-lists/filters/delete`, params);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(removeBuyersSuccess(response.data?.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, buyersListsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, buyersListsFail, e);
  }
};
