import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Autosuggest from 'react-autosuggest';

import FiltersDropDown from './FiltersDropDown';
import SortDropDown from './SortDropDown';
import searchButtonImg from '../../../../assets/sprite-search-icons.png';
import { color } from '../../../../constants';

import { match, parse } from '../../../../utils/suggestionHighlight';

const InputContainer = styled.div`
  margin-top: 14px;
  display: inline-block;
  min-width: 270px;
  border-bottom: 2px solid #cecece;

  .react-autosuggest__container {
    width: calc(100% - 33px);
    position: relative;
    display: inline-block;
  }
  .react-autosuggest__input {
    border: none;
    padding: 5px 0 8px 10px;
    font-weight: 400;
    font-size: 16px;
    background-color: transparent;
    outline: none;
    width: 95%;
  }
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 40px;
    width: calc(100% - 33px);
    border: 1px solid #aaa;
    background-color: ${color.white};
    font-weight: 300;
    font-size: 14px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
`;
const ErrorSpan = styled.span`
  color: red;
  margin-left: 20px;
`;
const ClearButton = styled(FontAwesomeIcon)`
  cursor: pointer;
`;
const SearchButton = styled.div`
  display: inline-block;
  background: url(${searchButtonImg}) no-repeat 0 0;
  background-position: 0 0px;
  width: 22px;
  height: 21px;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
`;
const FiltersContainer = styled.div`
  margin-top: 15px;
  margin-left: 5px;
  button:first-child {
    padding-left: 0;
  }
  button:last-child {
    padding-right: 0;
  }
`;
const FiltersFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 15px 15px 20px;
`;
const SuggestionItem = styled.span`
  .highlight {
    font-weight: bold;
  }
`;

const Filters = ({
  fetchTasksListWithParams,
  changeTaskListFetchingParams,
  taskListFetchingParams,
  getSearchSuggestions,
  queryParams,
  setTaskFetchingParams,
}) => {
  const [searchString, setSearchString] = useState('');
  const [prevSearchString, setPrevSearchString] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isShownErrorMessage, toggleErrorMessage] = useState(false);
  const [isShownClearButton, toggleClearInputButton] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (taskListFetchingParams.searchQuery && !prevSearchString) {
      setSearchString(taskListFetchingParams.searchQuery);
      toggleClearInputButton(true);
    }
    if (taskListFetchingParams.clearFiltersState) {
      setTaskFetchingParams({
        clearFiltersState: false,
      });
      setSearchString('');
      setPrevSearchString('');
    }
  });

  const onSearchChange = (e, { newValue }) => {
    toggleClearInputButton(newValue.length > 0);
    setSearchString(newValue);
    if (newValue) {
      setPrevSearchString(newValue);
    }
    if (newValue.length > 2 || newValue.length === 0) {
      toggleErrorMessage(false);
    }
  };

  const onSearchClear = () => {
    setSearchString('');
    setPrevSearchString('');
    changeTaskListFetchingParams({ ...taskListFetchingParams, searchQuery: '' });
    fetchTasksListWithParams({ fetchWithEmptyQuery: true });
    toggleErrorMessage(false);
    toggleClearInputButton(false);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setSearchString(suggestion);
    setPrevSearchString(suggestion);
    changeTaskListFetchingParams({ ...taskListFetchingParams, searchQuery: suggestion });
    fetchTasksListWithParams({ searchQuery: suggestion });
  };

  const onSearchClick = (e) => {
    const key = e.key || e.keyCode;
    if (!key || key === 'Enter' || key === 13) {
      if (searchString.length === 0 || searchString.length > 2) {
        setPrevSearchString(searchString);
        changeTaskListFetchingParams({ ...taskListFetchingParams, searchQuery: searchString });
        if (searchString === '') {
          fetchTasksListWithParams({ fetchWithEmptyQuery: true });
        } else {
          fetchTasksListWithParams({ searchQuery: searchString });
        }
      }
      toggleErrorMessage(searchString.length !== 0 && searchString.length < 3);
    }
  };

  const renderSuggestion = (suggestion, { query }) => {
    const matches = match(suggestion, query);
    const parts = parse(suggestion, matches);

    return (
      <SuggestionItem>
        {parts.map((part, index) => (
          <span className={part.highlight ? 'highlight' : null} key={index.toString()}>{part.text}</span>
        ))}
      </SuggestionItem>
    );
  };

  const inputProps = {
    placeholder: 'Search by company name, EP#',
    value: searchString,
    maxLength: 35,
    onKeyUp: onSearchClick,
    onChange: onSearchChange,
  };

  const onSuggestionCalculate = async ({ value }) => setSuggestions(await getSearchSuggestions(value));

  return (
    <FiltersFlexContainer>
      <InputContainer>
        <SearchButton onClick={onSearchClick} data-testid="task-search-button" />
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionCalculate}
          onSuggestionsClearRequested={() => setSuggestions([])}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={(v) => v}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        { isShownClearButton && <ClearButton icon="times" onClick={onSearchClear} />}
      </InputContainer>
      <FiltersContainer>
        <FiltersDropDown
          queryParams={queryParams}
          fetchTasksListWithParams={fetchTasksListWithParams}
          changeTaskListFetchingParams={changeTaskListFetchingParams}
          taskListFetchingParams={taskListFetchingParams}
          setTaskFetchingParams={setTaskFetchingParams}
        />
        <SortDropDown
          queryParams={queryParams}
          fetchTasksListWithParams={fetchTasksListWithParams}
          changeTaskListFetchingParams={changeTaskListFetchingParams}
          taskListFetchingParams={taskListFetchingParams}
          setTaskFetchingParams={setTaskFetchingParams}
        />
      </FiltersContainer>
      {isShownErrorMessage && <ErrorSpan>Please type 3 or more characters</ErrorSpan>}
    </FiltersFlexContainer>
  );
};

Filters.propTypes = {
  setTaskFetchingParams: PropTypes.func,
  fetchTasksListWithParams: PropTypes.func.isRequired,
  changeTaskListFetchingParams: PropTypes.func.isRequired,
  taskListFetchingParams: PropTypes.shape({
    searchQuery: PropTypes.string,
    clearFiltersState: PropTypes.bool,
  }),
  queryParams: PropTypes.shape({
    q: PropTypes.string,
    f: PropTypes.string,
    t: PropTypes.string,
    s: PropTypes.string,
    c: PropTypes.string,
  }),
  getSearchSuggestions: PropTypes.func.isRequired,
};
export default Filters;
