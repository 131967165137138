import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Alert, Modal, Button, Form, Row, Col,
} from 'react-bootstrap';
import sshpk from 'sshpk';

import UniversalControl from '../../FormComponents/UniversalControl';
import { ButtonContainer, ModalTitle, FlexContainer } from '../../StyledComponents';
import createErrorValidationObj from '../../../utils/createErrorValidationObj';

const TextAreaContainer = styled.div`
  textarea { 
    resize: none;
    padding-bottom: 0px;
  }
  &&& .glyphicon-remove {
    right: 25px;
  }
`;

export default class AddKeyModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      key: '',
      validationState: null,
      showAlert: true,
    };
  }

  onChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      const newData = { ...prevState };
      newData[name] = value;
      return newData;
    });
  };

  onSaveClickHandler = () => {
    const { saveClickHandler } = this.props;
    const { key } = this.state;
    let convertedKey = '';
    try {
      convertedKey = sshpk.parseKey(key.trim()).toString('ssh');
    } catch (e) {
      const keyValidation = createErrorValidationObj('Please enter SSH key in valid form');
      this.setState({ validationState: keyValidation });
      return;
    }
    this.setState({
      key: '',
      validationState: null,
      showAlert: true,
    });

    saveClickHandler(convertedKey);
  }

  handleDismiss = () => {
    this.setState({ showAlert: false });
  }

  handleShow = () => {
    this.setState({ showAlert: true });
  }

  onHideClick =() => {
    const { onHide } = this.props;
    this.setState({
      key: '',
      validationState: null,
      showAlert: true,
    });
    onHide();
  }

  render() {
    const {
      isSaving, onHide, saveClickHandler, ...other
    } = this.props;
    const { key, validationState, showAlert } = this.state;
    return (
      <Modal centered onHide={onHide} {...other}>
        <Modal.Header>
          <ModalTitle>ADD SFTP Key</ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.onSubmit}>
            <Row>
              <Col sm={12}>
                {showAlert && alertInfo(this.handleDismiss)}
              </Col>
            </Row>
            <TextAreaContainer>
              <UniversalControl
                name="key"
                type="text"
                labelText="Key"
                value={key}
                onBlur={this.onBlur}
                onChange={this.onChange}
                defaultInputLength={2048}
                as="textarea"
                rows="3"
                labelColumnWidth={2}
                controlColumnWidth={10}
                {...validationState}
              />
            </TextAreaContainer>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <FlexContainer>
            <ButtonContainer btnWidth="120px" space="10px">
              <Button variant="primary" disabled={isSaving} onClick={this.onSaveClickHandler}>Save</Button>
              <Button onClick={this.onHideClick}>Cancel</Button>
            </ButtonContainer>
          </FlexContainer>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddKeyModal.propTypes = {
  isSaving: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  saveClickHandler: PropTypes.func.isRequired,
};

function alertInfo(handleDismiss) {
  return (
    <Alert variant="info" onClose={handleDismiss}>
      <p>
        The key will be automatically converted to OpenSSH format.
      </p>
    </Alert>
  );
}
