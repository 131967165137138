import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FilesImage from '../../../assets/files-icons.png';

const FileIconContainer = styled.div`
  margin: 0 auto;
  width: 36px;
  height: 45px;
  background: url(${FilesImage}) no-repeat 0 ${(props) => props.position}px;
`;

export default function FileIcon({ extension }) {
  if (!extension) return null;

  switch (extension) {
    case 'csv':
      return <FileIconContainer position={0} />;
    case 'xls':
      return <FileIconContainer position={-300} />;
    case 'xlsx':
      return <FileIconContainer position={-350} />;
    case 'zip':
      return <FileIconContainer position={-400} />;
    default:
      return <FileIconContainer position={-450} />;
  }
}

FileIcon.propTypes = {
  extension: PropTypes.string,
};
