import {
  TOAST_ADD,
  TOAST_DELETE,
} from '../constants';

const initialState = {
  toasts: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TOAST_ADD: {
      let totalDelay = 0;
      for (let i = 0; i < state.toasts.length; i += 1) {
        totalDelay = state.toasts[i].delay || 0;
      }

      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            ...payload,
            delay: totalDelay + payload.delay,
          },
        ],
      };
    }
    case TOAST_DELETE:
      return {
        ...state,
        toasts: payload ? state.toasts.filter((toast) => toast.id !== payload.id && toast.type !== payload.type) : [],
      };

    default:
      return state;
  }
}
