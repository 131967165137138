import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {
  Collapse, Fade, Row, Col,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';

import {
  getPartsWatchlistStore, getUserSettingsStore, getErrorsStore, getFileUploaderStore,
} from '../../../selectors';
import { getProActiveState } from '../../../selectors/pro';
import { fetchUserSettings } from '../../../actions/companiesManagement/userSettingsActions';
import {
  fetchPartWatchlist, fetchAllPNs, addPartsWatchlist, deletePartsWatchlist, uploadPartWatchlist,
} from '../../../actions/companiesManagement/partsWatchlist';
import { resetUploadState, resetUploadingFileProgress, terminateUploadingFileProgress } from '../../../actions/fileUploaderActions';

import AddPartNumberModal from './AddPartNumberModal';
import UploadPartsModal from './UploadPartsModal';
import WatchListFullModal from './WatchListFullModal';
import RemoveConfirmationModal from './RemoveConfirmationModal';
import UploadProgressModal from './UploadProgressModal';
import UploadErrorModals from './UploadErrorModals';

import PartsTable from './PartsTable';
import Loader from '../../Loader';
import { checkboxStates } from '../../FormComponents/IndeterminateCheckbox';
import PaginationTable from '../../OrderManagement/SearchOrders/PaginationTable';
import AutosuggestControl from '../../FormComponents/AutosuggestControl';
import EmptyPartsContent from './EmptyPartsContent';
import ModalLoader from '../../ModalLoader';
import { TabContainer, StyledLinkButton as LinkButton } from '../../StyledComponents';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import { MAX_PARTS_WATCHLIST_ITEMS, AXIOS_CANCELLED_MESSAGE, color } from '../../../constants';

const RemoveBlock = styled.div`
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
`;
const RemoveAllButton = styled(LinkButton)`
  padding-left: 5px;
  padding-bottom: 6px;
`;
const Separator = styled.span`
  border-left: 2px solid ${color.borderLightGrey};
`;
const Panel = styled.div`
  margin-bottom: 20px;
  button {
    margin: 0 15px;
  }
`;
const FullListMessage = styled.div`
  font-size: 1.1rem;
`;
const SelectedCount = styled.div`
  color: ${color.borderLightGrey};
`;

export default function PartsWatchlistPage() {
  const source = useRef(null);
  const sortOrder = useRef(null);

  const { userId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [modals, setModals] = useState({
    addPartNumberModal: false,
    removeConfirmationModal: false,
    uploadPartModal: false,
    watchlistFullModal: false,
  });
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectAllFlag, setSelectAllFlag] = useState(false);
  const [tableSelectionState, setTableSelectionState] = useState(checkboxStates.UNCHECKED);
  const [selectedParts, setSelectedParts] = useState([]);

  const {
    partNumbers,
    watchlistItems,
    page,
    lastPage,
    total,

    isFetching,
    isProcessing,
  } = useSelector(getPartsWatchlistStore);
  const { error: uploadingError, uploadingProgress, isUploading } = useSelector(getFileUploaderStore);
  const { data: userData, isFetching: isUserSettingsFetching } = useSelector(getUserSettingsStore);
  const isProActive = useSelector(getProActiveState);
  const { errors } = useSelector(getErrorsStore);

  const tableOptions = useMemo(() => ({
    noDataContent: <EmptyPartsContent
      onAddPnHandler={() => setModals((prev) => ({ ...prev, addPartNumberModal: true }))}
      onUploadHandler={() => setModals((prev) => ({ ...prev, uploadPartModal: true }))}
    />,
  }), []);

  // Updates selected PNs after fetching full list of PNs
  useEffect(() => setSelectedParts(partNumbers), [partNumbers]);

  // Updates table select checkbox
  useEffect(() => {
    if (watchlistItems.every((item) => selectedParts.every((selected) => selected !== item.pn))) {
      setTableSelectionState(checkboxStates.UNCHECKED);
    } else if (watchlistItems.every((item) => selectedParts.some((selected) => selected === item.pn))) {
      setTableSelectionState(checkboxStates.CHECKED);
    } else {
      setTableSelectionState(checkboxStates.INDETERMINATE);
    }
    setSelectAllFlag(total > 0 && selectedParts.length === total);
  }, [selectedParts, watchlistItems]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchUserSettings(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (location.search === '') {
      dispatch(fetchPartWatchlist(userId, { sort: '-created_at' }));
    } else {
      const currQuery = queryString.parse(location.search);
      if (currQuery.sort) {
        sortOrder.current = {
          columnName: currQuery?.sort.replace(/-/g, ''),
          order: currQuery?.sort.startsWith('-') ? 'asc' : 'desc',
        };
      }
      if (currQuery.page) currQuery.page = +currQuery.page;
      if (currQuery.query) setSearch(currQuery.query);

      dispatch(fetchPartWatchlist(userId, currQuery));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.search]);

  const onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length < 3) return;
    if (source.current) source.current.cancel(AXIOS_CANCELLED_MESSAGE);
    source.current = axios.CancelToken.source();

    try {
      const response = await axios(`/suggest/parts-watchlist/part?q=${value}&user_id=${userId}`, { cancelToken: source.current.token });
      const data = response.data.payload?.items;
      setSuggestions(data.slice(0, 5));
      source.current = null;
    } catch (e) {
      if (e.message === AXIOS_CANCELLED_MESSAGE) ;
      else console.warn('Can\'t get parts suggestions'); // eslint-disable-line
    }
  };

  const searchPartNumber = (e, { suggestion }) => {
    onClearAllSelectionButton();

    const query = queryString.parse(location.search);
    query.query = suggestion.title;

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(query),
    });
    setSearch(suggestion.title);
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const getSuggestionValue = (suggestion) => suggestion.title;

  const renderSuggestion = (suggestion) => <span>{suggestion.title}</span>;

  const onUploadHandler = (file) => {
    dispatch(uploadPartWatchlist(file[0], userId));
    setModals((prev) => ({ ...prev, uploadPartModal: false }));
  };

  const onPageChanged = (pg) => {
    const query = queryString.parse(location.search);
    query.page = pg;

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(query),
    });
  };

  const showConfirmationModal = (pns) => {
    setModals((prev) => ({ ...prev, removeConfirmationModal: { data: pns } }));
  };

  const onRemovePn = () => {
    const currQuery = queryString.parse(location.search);
    const searchParam = location.search ? { sort: currQuery.sort } : {};
    const removedPns = modals.removeConfirmationModal.data;

    const removeData = {
      pns: Array.isArray(removedPns) ? removedPns : null,
      ...searchParam,
    };
    dispatch(deletePartsWatchlist(removeData, userId));

    setSelectedParts((prev) => (
      removedPns ? prev.filter((selected) => removedPns.every((removedPn) => removedPn !== selected)) : []
    ));
    setModals((prev) => ({ ...prev, removeConfirmationModal: false }));
    setSelectAllFlag(false);

    const query = queryString.parse(location.search);
    delete query.page;

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(query),
    });
  };

  const addNewPartNumber = (pn) => {
    const currQuery = queryString.parse(location.search);
    const searchParam = location.search ? { sort: currQuery.sort } : {};
    dispatch(addPartsWatchlist({ pn, ...searchParam }, userId));
    setModals((prev) => ({ ...prev, addPartNumberModal: false }));
  };

  const onSortOrderChange = (e) => {
    const { sort } = queryString.parse(location.search);

    sortOrder.current = {
      columnName: e.target.name,
      order: (sort && sort.startsWith('-')) ? 'asc' : 'desc',
    };

    history.push({
      pathname: location.pathname,
      search: `sort=${sortOrder.current.order === 'desc' ? '-' : ''}${sortOrder.current.columnName}`,
    });
  };

  const onSearchClear = () => {
    setSearch('');
    const query = queryString.parse(location.search);
    delete query.query;

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(query),
    });
    dispatch(fetchPartWatchlist(userId, query));
  };

  const onSelectionCheckboxHandler = (e, pn) => {
    if (e.target.name === 'table_multicheckbox') {
      setTableSelectionState(e.target.checked ? checkboxStates.CHECKED : checkboxStates.UNCHECKED);
      if (e.target.checked) {
        setSelectedParts((prev) => [...prev, ...watchlistItems.map((item) => item.pn)]);
      } else {
        setSelectedParts((prev) => prev.filter((selected) => watchlistItems.every((v) => v.pn !== selected)));
      }
    } else if (e.target.checked) {
      setSelectedParts((prev) => [...prev, pn]);
    } else if (!e.target.checked) {
      setSelectedParts((prev) => prev.filter((selected) => selected !== pn));
    }
  };

  const onSelectAllButtonClick = () => {
    dispatch(fetchAllPNs(userId));
    setSelectAllFlag(true);
  };

  const onClearAllSelectionButton = () => {
    setSelectedParts([]);
    setSelectAllFlag(false);
  };

  if (isFetching && uploadingProgress !== 100 && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <>
      <FormTitleSection title="Parts Watchlist" showEditButton={false} />
      <TabContainer>
        {userData && !(userData.pro_parts_watchlist || isProActive) ? (
          <p>Feature is available for companies with enabled parts watchlist feature or activated Pro Plan.</p>
        ) : (
          <>
            <p>Add part numbers to monitor and receive updates by email when new items are added to ePlane&apos;s inventory.</p>
            <Row noGutters>
              {total === MAX_PARTS_WATCHLIST_ITEMS && (
                <FullListMessage>
                  <FontAwesomeIcon icon="exclamation-triangle" color={color.darkorange} />
                  &nbsp;
                  Parts Watchlist is limited to 4000 parts. Remove some parts from the list to add new items.
                </FullListMessage>
              )}
            </Row>
            <Row>
              <Col sm={8}>
                <Panel>
                  <span><strong>Upload Parts:</strong></span>
                  <LinkButton
                    variant="link"
                    disabled={total === MAX_PARTS_WATCHLIST_ITEMS}
                    onClick={() => setModals((prev) => ({ ...prev, addPartNumberModal: true }))}
                  >
                    <FontAwesomeIcon icon="plus" />
                    &nbsp;
                    Add manually
                  </LinkButton>
                  <Separator />
                  <LinkButton
                    variant="link"
                    disabled={total === MAX_PARTS_WATCHLIST_ITEMS}
                    onClick={() => setModals((prev) => ({ ...prev, uploadPartModal: true }))}
                  >
                    <FontAwesomeIcon icon="arrow-circle-up" />
                    &nbsp;
                    Upload list
                  </LinkButton>
                </Panel>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <AutosuggestControl
                  labelColumnWidth={0}
                  controlColumnWidth={12}
                  value={search}
                  suggestions={suggestions}
                  placeholder="Search by part number"
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionSelected={searchPartNumber}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  onChange={(e) => setSearch(e.target.value)}
                  onClear={onSearchClear}
                  maxLength={35}
                />
              </Col>
            </Row>

            <LinkButton
              onClick={() => setModals((prev) => ({ ...prev, removeConfirmationModal: { data: selectedParts } }))}
              variant="link"
              title="Remove"
              disabled={selectedParts.length === 0}
            >
              <FontAwesomeIcon icon="trash-alt" />
              &nbsp;
              Remove
            </LinkButton>
            <Collapse in={tableSelectionState === checkboxStates.CHECKED && !selectAllFlag && total > 50}>
              <RemoveBlock>
                All 50 parts on this page are selected.
                <RemoveAllButton
                  variant="link"
                  title="Select all"
                  onClick={onSelectAllButtonClick}
                >
                  {`Select all ${total} parts in "Parts Watchlist"`}
                </RemoveAllButton>
              </RemoveBlock>
            </Collapse>

            <Fade in={selectAllFlag}>
              <RemoveBlock>
                {`All ${total} parts in "Parts Watchlist" are selected.`}
                <RemoveAllButton
                  variant="link"
                  title="Clear selection"
                  onClick={onClearAllSelectionButton}
                >
                  Clear selection
                </RemoveAllButton>
              </RemoveBlock>
            </Fade>

            {selectedParts.length > 0 && (
              <SelectedCount>{`You have selected ${selectedParts.length} ${selectedParts.length > 1 ? 'PNs' : 'PN'}`}</SelectedCount>
            )}

            <PartsTable
              data={watchlistItems}
              selectedItems={selectedParts}
              selectAllFlag={selectAllFlag}
              sortOrder={sortOrder.current}
              tableSelectionState={tableSelectionState}
              onSelectionCheckboxClick={onSelectionCheckboxHandler}
              onRemoveHandler={showConfirmationModal}
              onSort={onSortOrderChange}
              options={tableOptions}
            />
            {lastPage > 1 && <PaginationTable onChange={onPageChanged} currentPage={page} totalPages={lastPage} />}
          </>
        )}
      </TabContainer>
      <ModalLoader show={isProcessing || isUserSettingsFetching} />

      <AddPartNumberModal
        userId={userId}
        show={modals.addPartNumberModal}
        onConfirm={addNewPartNumber}
        onHide={() => setModals((prev) => ({ ...prev, addPartNumberModal: false }))}
      />

      <UploadPartsModal
        show={modals.uploadPartModal}
        onUpload={onUploadHandler}
        onHide={() => setModals((prev) => ({ ...prev, uploadPartModal: false }))}
      />

      <UploadProgressModal
        show={isUploading || (uploadingProgress === 100)}
        now={isUploading ? uploadingProgress : 100}
        onConfirm={() => dispatch(resetUploadingFileProgress())}
        onTerminate={() => dispatch(terminateUploadingFileProgress())}
      />

      <RemoveConfirmationModal
        show={!!modals.removeConfirmationModal}
        data={modals.removeConfirmationModal.data}
        confirm={onRemovePn}
        onHide={() => setModals((prev) => ({ ...prev, removeConfirmationModal: false }))}
      />

      <WatchListFullModal
        show={modals.watchlistFullModal}
        onHide={() => setModals((prev) => ({ ...prev, watchlistFullModal: false }))}
      />
      <UploadErrorModals error={uploadingError} onHide={() => dispatch(resetUploadState())} />
    </>
  );
}
