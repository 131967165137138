import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import dateFormat from '../../../utils/dateFormat';
import { color } from '../../../constants';

const Date = styled.span`
  color: ${color.silverGrey};
`;

const ProviderStatus = ({ date, isConnected }) => (
  <>
    {isConnected ? (
      <>
        <FontAwesomeIcon icon="check-circle" color={`${color.textGreen}`} />
        &nbsp;Connected
        <br />
        <Date>
          On&nbsp;
          {date && dateFormat(date, false)}
        </Date>
      </>
    ) : (
      <>Disconnected</>
    )}
  </>
);

ProviderStatus.propTypes = {
  date: PropTypes.string,
  isConnected: PropTypes.bool,
};

export default ProviderStatus;
