import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { color } from '../../constants';

const Text = styled.span`
  color: ${(props) => (props.isVerified ? color.textGreen : color.textRed)};
  margin-left: 20px;
`;

export default function VerificationMark({ isVerified }) {
  if (isVerified) {
    return (
      <Text isVerified={isVerified}>
        <FontAwesomeIcon icon="check" />
        &nbsp;verified
      </Text>
    );
  }
  return (
    <Text>
      <FontAwesomeIcon icon="times" />
      &nbsp;not verified
    </Text>
  );
}

VerificationMark.propTypes = {
  isVerified: PropTypes.bool.isRequired,
};
