import styled from 'styled-components';
import { Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { color } from '../../constants';

export const Container = styled.div`
  display: flex;
  padding-bottom: 50px;
`;
export const TasksContainer = styled.div`
  position: relative;
  width: 20%;
  order: 1;
  border-right: 2px solid ${color.altoGrey};
  ${(props) => (props.isHidden ? 'width: 35px; min-width: auto;' : '')};
  transition: 1s all;
  .tasks-sidebar {
    ${(props) => (props.isHidden && 'display: none;')};
  }

  @media (max-width: 1400px) {
    ${(props) => (!props.isHidden && 'width: 300px;')}
  }
`;
export const ChatContainer = styled.div`
  flex: 1;
  order: 2;
`;
export const HistoryContainer = styled.div`
  position: relative;
  width: 20%;
  order: 3;
  border-left: 2px solid ${color.altoGrey};
  ${(props) => (props.isLarge && 'width: 40%;')};
  ${(props) => (props.isHidden ? 'width: 35px; min-width: auto;' : '')};
  transition: 1s all;
  .history-sidebar {
    ${(props) => (props.isHidden && 'display: none;')};
  }

  @media (max-width: 1400px) {
    ${(props) => (!props.isHidden && 'width: 300px;')}
  }
`;
export const ButtonContainer = styled.div`
  position: fixed;
  top: 90%;
  left: 65%;
`;
export const CloseButton = styled(Button)`
  margin-left: 25px;
  color: ${color.silverGrey};
  border: 1px solid ${color.silverGrey};
  border-radius: 10px;
  &:hover {
    color: ${color.silverGrey};
    background-color: ${color.lightGrey};
  }
  &:focus {
    color: ${color.grey};
    background-color: ${color.lightGrey};
  }
`;
export const StyledButton = styled.button`
  outline: none;
  padding: 6px;
  border: 2px solid #fd612c;
  width: 160px;
  height: 50px;
  border-radius: 25px;
  color: black;
  background: white;

  &:active {
    outline: none;
    border: 3px solid #fd612c;
  }

  &:hover {
    background-color: ${color.whiteRose};
  }

  &:disabled {
    display: none;
  }
`;
export const Styled = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  left: 12px;
  color: #fd612c;
  textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)';
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
  color: ${color.darkGrey};
`;
export const GroupContainer = styled.div`
  display: flex;
  margin-left: 5px;
  div:first-child {
    margin-right: 20px;
  }
  .form-check, .form-radio {
    margin-top: 10px;
  }
`;
export const RepairLeadTime = styled.div`
  margin-top: 28px;
`;
export const TextContainer = styled.div`
  text-align: center;
`;
export const SectionText = styled.p`
  margin-bottom: 0;
`;
export const RowContainer = styled(Row)`
  .form-group {
    margin-left: 0;
    margin-right: 0;
  }
  input {
    height: 38px;
  }
  && label {
    padding-top: 0;
  }
`;
export const TwoColumnContainer = styled.div`
  display: flex;
`;
export const LeftColumn = styled.div`
  width: 100%;
  input {
    height: 38px;
  }
`;
export const RightColumn = styled.div`
  & > div {
    width: 100px;
  }
`;
export const UnitContainer = styled.div`
  padding-top: 28px;
  padding-left: 5px;
  width: 90px;
`;
export const FormButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const TextAreaContainer = styled.div`
  textarea { 
    resize: none;
    padding-bottom: 0px;
  }
  &&& .glyphicon-remove {
    right: 25px;
  }
`;
export const MultilineText = styled.div`
  margin-top: -8px;
  margin-right: -8px;
`;
export const TasksCollapseButton = styled.button`
  font-size: 26px;
  position: absolute;
  right: 0px;
  top: 50%;
  opacity: 50%;
  border-radius: 0px 10px 10px 0;
  z-index: 2;
  transform: translate(100%);

  color: ${color.darkBirch};
  border: none;
  background-color: transparent;
  &:hover {
    opacity: 100%;
    transition: 0.5s all;
  }
  ${(props) => (!props.showTasksBar && 'right: 30px;')};
  transition: 0.5s all;
`;
