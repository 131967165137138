import styled from 'styled-components';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { logObjects } from '../../../constants/index';

const LabelText = styled.p`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 15px;
`;
const StyledRow = styled(Row)`
  margin-bottom:15px;
`;
const StyledCol = styled(Col)`
  float: right;
 `;

const DevLogsFilters = ({
  selectedActionsType,
  selectedPageSize,
  selectedObjectType,
  mapConstantsToSelectOptions,
  defineActionTypesList,
  onChangeSelectedObject,
  onChangePageSize,
  onChangeSelectedAction,
}) => {
  const selectActionType = selectedObjectType
    ? (
      <>
        <LabelText>Action type</LabelText>
        <Select
          options={mapConstantsToSelectOptions(defineActionTypesList(selectedObjectType.value))}
          onChange={onChangeSelectedAction}
          value={selectedActionsType}
          isClearable={!!selectedActionsType}
          name="selectedActionsType"
        />
      </>
    ) : '';

  const pageSizeOptions = [
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '150', label: '150' },
  ];

  return (
    <StyledRow>
      <Col sm={4}>
        <LabelText>Object type</LabelText>
        <Select
          options={mapConstantsToSelectOptions(logObjects)}
          onChange={onChangeSelectedObject}
          value={selectedObjectType}
          isClearable={!!selectedObjectType}
          name="selectedObjectType"
        />
      </Col>
      <Col sm={4}>
        {selectActionType}
      </Col>
      <StyledCol sm={{ offset: 2, span: 2 }}>
        <LabelText>Page size</LabelText>
        <Select
          name="pageSize"
          options={pageSizeOptions}
          onChange={onChangePageSize}
          value={selectedPageSize}
          isClearable={selectedPageSize && selectedPageSize.value !== '50'}
        />
      </StyledCol>
    </StyledRow>
  );
};

DevLogsFilters.propTypes = {
  selectedObjectType: PropTypes.shape({
    value: PropTypes.number,
  }),
  selectedPageSize: PropTypes.shape({
    value: PropTypes.string,
  }),
  selectedActionsType: PropTypes.shape({}),
  mapConstantsToSelectOptions: PropTypes.func,
  defineActionTypesList: PropTypes.func,
  onChangeSelectedObject: PropTypes.func,
  onChangePageSize: PropTypes.func,
  onChangeSelectedAction: PropTypes.func,
};
export default DevLogsFilters;
