import axios from 'axios';

import {
  COMPANY_CUSTOMER_GET_LIST,
  COMPANY_CUSTOMER_GET_LIST_SUCCESS,
  COMPANY_CUSTOMER_GET_LIST_FAIL,

  COMPANY_CUSTOMER_INVITE,
  COMPANY_CUSTOMER_INVITE_SUCCESS,
  COMPANY_CUSTOMER_INVITE_FAIL,

  COMPANY_CUSTOMER_REINVITE,
  COMPANY_CUSTOMER_REINVITE_SUCCESS,
  COMPANY_CUSTOMER_REINVITE_FAIL,

  COMPANY_CUSTOMER_UPDATE,
  COMPANY_CUSTOMER_UPDATE_SUCCESS,
  COMPANY_CUSTOMER_UPDATE_FAIL,

  COMPANY_CUSTOMER_DEACTIVATE,
  COMPANY_CUSTOMER_DEACTIVATE_SUCCESS,
  COMPANY_CUSTOMER_DEACTIVATE_FAIL,

  COMPANY_CUSTOMER_REACTIVATE,
  COMPANY_CUSTOMER_REACTIVATE_SUCCESS,
  COMPANY_CUSTOMER_REACTIVATE_FAIL,

  COMPANY_CUSTOMER_DISMISS,
  COMPANY_CUSTOMER_DISMISS_SUCCESS,
  COMPANY_CUSTOMER_DISMISS_FAIL,

  COMPANY_CUSTOMER_REMOVE,
  COMPANY_CUSTOMER_REMOVE_SUCCESS,
  COMPANY_CUSTOMER_REMOVE_FAIL,

  COMPANY_CUSTOMER_ERROR_REMOVE,
  PUBLIC_URL,
} from '../../constants';
import { pushRedirect } from '../redirectActions';
import errorHandler from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startFetchCompanyCustomerList = () => ({
  type: COMPANY_CUSTOMER_GET_LIST,
});
const fetchCompanyCustomerListSuccess = (sites) => ({
  type: COMPANY_CUSTOMER_GET_LIST_SUCCESS,
  payload: sites,
});
const fetchCompanyCustomerListFail = (errCode) => ({
  type: COMPANY_CUSTOMER_GET_LIST_FAIL,
  payload: errCode,
});

const startCompanyCustomerInvite = () => ({
  type: COMPANY_CUSTOMER_INVITE,
});
const companyCustomerInviteSuccess = (sites) => ({
  type: COMPANY_CUSTOMER_INVITE_SUCCESS,
  payload: sites,
});
const companyCustomerInviteFail = (errCode) => ({
  type: COMPANY_CUSTOMER_INVITE_FAIL,
  payload: errCode,
});

const startCompanyCustomerReinvite = () => ({
  type: COMPANY_CUSTOMER_REINVITE,
});
const companyCustomerReinviteSuccess = (sites) => ({
  type: COMPANY_CUSTOMER_REINVITE_SUCCESS,
  payload: sites,
});
const companyCustomerReinviteFail = (errCode) => ({
  type: COMPANY_CUSTOMER_REINVITE_FAIL,
  payload: errCode,
});

const startCompanyCustomerUpdate = () => ({
  type: COMPANY_CUSTOMER_UPDATE,
});
const companyCustomerUpdateSuccess = (sites) => ({
  type: COMPANY_CUSTOMER_UPDATE_SUCCESS,
  payload: sites,
});
const companyCustomerUpdateFail = (errCode) => ({
  type: COMPANY_CUSTOMER_UPDATE_FAIL,
  payload: errCode,
});

const startCompanyCustomerDeactivate = () => ({
  type: COMPANY_CUSTOMER_DEACTIVATE,
});
const companyCustomerDeactivateSuccess = (sites) => ({
  type: COMPANY_CUSTOMER_DEACTIVATE_SUCCESS,
  payload: sites,
});
const companyCustomerDeactivateFail = (errCode) => ({
  type: COMPANY_CUSTOMER_DEACTIVATE_FAIL,
  payload: errCode,
});

const startCompanyCustomerReactivate = () => ({
  type: COMPANY_CUSTOMER_REACTIVATE,
});
const companyCustomerReactivateSuccess = (sites) => ({
  type: COMPANY_CUSTOMER_REACTIVATE_SUCCESS,
  payload: sites,
});
const companyCustomerReactivateFail = (errCode) => ({
  type: COMPANY_CUSTOMER_REACTIVATE_FAIL,
  payload: errCode,
});

const startCompanyCustomerDismiss = () => ({
  type: COMPANY_CUSTOMER_DISMISS,
});
const companyCustomerDismissSuccess = (sites) => ({
  type: COMPANY_CUSTOMER_DISMISS_SUCCESS,
  payload: sites,
});
const companyCustomerDismissFail = (errCode) => ({
  type: COMPANY_CUSTOMER_DISMISS_FAIL,
  payload: errCode,
});

const startRemovingCustomer = () => ({
  type: COMPANY_CUSTOMER_REMOVE,
});
const removeCustomerSuccess = (customers) => ({
  type: COMPANY_CUSTOMER_REMOVE_SUCCESS,
  payload: customers,
});
const removeCustomerFail = (errorCode) => ({
  type: COMPANY_CUSTOMER_REMOVE_FAIL,
  payload: errorCode,
});

export const removeErrorsCompanyCustomer = () => ({
  type: COMPANY_CUSTOMER_ERROR_REMOVE,
});

export const fetchCompanyCustomerList = (userId) => (dispatch) => {
  dispatch(startFetchCompanyCustomerList());

  axios(`/rest/default/V1/eplane/backoffice/user/${userId}/companyCustomers`).then((response) => {
    dispatch(fetchCompanyCustomerListSuccess(response.data && response.data.customers));
  }).catch((e) => {
    errorHandler(e.response, dispatch, fetchCompanyCustomerListFail, e);
  });
};

export const companyCustomerInvite = (userId, customerData) => (dispatch) => {
  dispatch(startCompanyCustomerInvite());

  axios.post(`/rest/default/V1/eplane/backoffice/user/${userId}/companyCustomers`, { customerData }).then((response) => {
    dispatch(companyCustomerInviteSuccess(response.data && response.data.customers));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, companyCustomerInviteFail, e);
  });
};

export const companyCustomerReinvite = (userId, customerId) => (dispatch) => {
  dispatch(startCompanyCustomerReinvite());

  axios.post(`/rest/default/V1/eplane/backoffice/user/${userId}/companyCustomers/${customerId}/reinvite`).then((response) => {
    dispatch(companyCustomerReinviteSuccess(response.data && response.data.customers));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, companyCustomerReinviteFail, e);
  });
};

export const companyCustomerUpdate = (userId, customerId, customerData, reactivate) => (dispatch) => {
  dispatch(startCompanyCustomerUpdate());

  axios.post(`/rest/default/V1/eplane/backoffice/user/${userId}/companyCustomers/${customerId}`,
    { customerData, reactivate }).then((response) => {
    dispatch(companyCustomerUpdateSuccess(response.data && response.data.customers));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, companyCustomerUpdateFail, e);
  });
};

export const companyCustomerDeactivate = (userId, customerIds) => (dispatch) => {
  dispatch(startCompanyCustomerDeactivate());

  axios.post(`/rest/default/V1/eplane/backoffice/user/${userId}/companyCustomers/deactivate`, { customerIds }).then((response) => {
    dispatch(companyCustomerDeactivateSuccess(response.data.customers));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, companyCustomerDeactivateFail, e);
  });
};

export const companyCustomerReactivate = (userId, customerId) => (dispatch) => {
  dispatch(startCompanyCustomerReactivate());

  axios.post(`/rest/default/V1/eplane/backoffice/user/${userId}/companyCustomers/${customerId}/reactivate`, '').then((response) => {
    dispatch(companyCustomerReactivateSuccess(response.data.customers));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, companyCustomerReactivateFail, e);
  });
};

export const removeCustomer = (userId, customerId, reason) => async (dispatch) => {
  dispatch(startRemovingCustomer());

  const reqBody = { reason };
  try {
    const response = await axios.put(createURLForRemovingUser(userId, customerId), reqBody);
    dispatch(removeCustomerSuccess(response.data && response.data.customers));
    dispatch(updateComments());
  } catch (e) {
    errorHandler(e.response, dispatch, removeCustomerFail, e);
  }
};

export const companyCustomerDismiss = (customerId, userId, redirect) => async (dispatch) => {
  dispatch(startCompanyCustomerDismiss());

  try {
    const response = await axios.post(`/rest/default/V1/eplane/backoffice/user/${userId}/companyCustomers/${customerId}/uncage`);
    dispatch(companyCustomerDismissSuccess(response.data && response.data.customers && response.data.customers[0]
      && response.data.customers[0].entity_id));
    dispatch(updateComments());

    if (redirect) {
      const oldUserId = response.data && response.data.customers && response.data.customers[0]
        && response.data.customers[0].user_id;
      dispatch(pushRedirect(`${PUBLIC_URL}/userManagement/${oldUserId}/${customerId}/associateUser`));
    }
  } catch (e) {
    errorHandler(e.response, dispatch, companyCustomerDismissFail, e);
  }
};

function createURLForRemovingUser(userId, customerId) {
  return `/rest/default/V1/eplane/backoffice/user/${userId}/companyCustomers/${customerId}/toTrash`;
}
