import { combineReducers } from 'redux';

import customersList from './customersList';
import user from './userEntity';
import customer from './customerEntity';
import filters from './filter';
import notifications from './notifications';

export default combineReducers({
  customersList,
  customer,
  user,
  filters,
  notifications,
});
