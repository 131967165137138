import axios from 'axios';

import {
  USER_PAYMENT_PROVIDERS_GET,
  USER_PAYMENT_PROVIDERS_GET_SUCCESS,
  USER_PAYMENT_PROVIDERS_SAVE,
  USER_PAYMENT_PROVIDERS_SAVE_SUCCESS,
  USER_PAYMENT_PROVIDER_DETACH,
  USER_PAYMENT_PROVIDER_DETACH_SUCCESS,
  USER_PAYMENT_PROVIDER_COMMISSION_REMOVE,
  USER_PAYMENT_PROVIDER_COMMISSION_REMOVE_SUCCESS,
  USER_PAYMENT_PROVIDER_METHODS_SAVE,
  USER_PAYMENT_PROVIDER_METHODS_SAVE_SUCCESS,
  USER_PAYMENT_PROVIDERS_FAIL,

  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startFetchingPaymentProviders = () => ({
  type: USER_PAYMENT_PROVIDERS_GET,
});
const fetchPaymentProvidersSuccess = (providersList) => ({
  type: USER_PAYMENT_PROVIDERS_GET_SUCCESS,
  payload: providersList,
});

const startSavingPaymentProviders = () => ({
  type: USER_PAYMENT_PROVIDERS_SAVE,
});
const savePaymentProvidersSuccess = (providersList) => ({
  type: USER_PAYMENT_PROVIDERS_SAVE_SUCCESS,
  payload: providersList,
});

const startDetachingProvider = () => ({
  type: USER_PAYMENT_PROVIDER_DETACH,
});
const detachProviderSuccess = (providersList) => ({
  type: USER_PAYMENT_PROVIDER_DETACH_SUCCESS,
  payload: providersList,
});

const startCommissionRemove = () => ({
  type: USER_PAYMENT_PROVIDER_COMMISSION_REMOVE,
});
const commissionRemoveSuccess = (providersList) => ({
  type: USER_PAYMENT_PROVIDER_COMMISSION_REMOVE_SUCCESS,
  payload: providersList,
});

const startMethodsSave = () => ({
  type: USER_PAYMENT_PROVIDER_METHODS_SAVE,
});
const methodsSaveSuccess = (providersList) => ({
  type: USER_PAYMENT_PROVIDER_METHODS_SAVE_SUCCESS,
  payload: providersList,
});

const paymentProvidersFail = () => ({ type: USER_PAYMENT_PROVIDERS_FAIL });

export const fetchProviders = (userId) => async (dispatch) => {
  dispatch(startFetchingPaymentProviders());
  try {
    const response = await axios(`rest/V2/eplane/backoffice/payment/user/${userId}/providers`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchPaymentProvidersSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, paymentProvidersFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, paymentProvidersFail, e);
  }
};

export const saveProviders = (data) => async (dispatch) => {
  dispatch(startSavingPaymentProviders());
  try {
    const response = await axios.post('rest/V2/eplane/backoffice/payment/change-user-provider-fee', data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(savePaymentProvidersSuccess(response.data && response.data.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, paymentProvidersFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, paymentProvidersFail, e);
  }
};

export const disconnectProvider = (data) => async (dispatch) => {
  dispatch(startDetachingProvider());
  try {
    const response = await axios.post('rest/V2/eplane/backoffice/payment/detach-provider-from-user', data);
    if (response.data.code === ERRORS.SUCCESS) {
      // Resolve issue with disconnect status of payment provider
      setTimeout(() => {
        dispatch(fetchProviders(data.user_id));
        dispatch(detachProviderSuccess(response.data && response.data.payload));
        dispatch(updateComments());
      }, 5000);
    } else {
      requestErrorHandler(response, dispatch, paymentProvidersFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, paymentProvidersFail, e);
  }
};

export const removeCommission = (data) => async (dispatch) => {
  dispatch(startCommissionRemove());
  try {
    const response = await axios.post('rest/V2/eplane/backoffice/payment/reset-user-provider-fee', data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(commissionRemoveSuccess(response.data && response.data.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, paymentProvidersFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, paymentProvidersFail, e);
  }
};

export const saveMethods = (data) => async (dispatch) => {
  dispatch(startMethodsSave());
  try {
    const response = await axios.post('rest/V2/eplane/backoffice/payment/change-user-provider-methods', data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(methodsSaveSuccess(response.data && response.data.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, paymentProvidersFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, paymentProvidersFail, e);
  }
};
