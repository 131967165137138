import { combineReducers } from 'redux';

import bounceEmails from './bounceEmails';
import emails from './emails';
import logs from './logs';

export default combineReducers({
  emails,
  bounceEmails,
  logs,
});
