import createErrorValidatorObj from '../../../utils/createErrorValidationObj';
import { units as unitsConst } from '../../../constants/index';

export default {
  qty: checkQty,
  price: checkPriceField,
  taxes: checkPriceField,
  total_price: checkPriceField,
  fee: checkPriceField,
  period: checkLeadTimeField,
  outright_price: checkPriceField,
  lead_time: checkLeadTimeField,
  warranty: checkPositiveWholeNumber,

  description: () => null,
  part_number: (value) => (!value ? createErrorValidatorObj('Please enter a part number') : null),
  tagged_by: () => null,
  comment: () => null,
  minimum_order_value: checkPriceField,

  evaluation_price: checkEvaluationFeeField,
  evaluation_time: checkLeadTimeField,
  test_price: checkPriceField,
  test_time: checkLeadTimeField,
  repair_price: checkPriceField,
  repair_time: checkLeadTimeField,
  overhaul_price: checkPriceField,
  overhaul_time: checkLeadTimeField,
  place: checkPickupPlace,
};

export function checkNonNegativeNumber(value) {
  if (value === '') return null;
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal <= 0) {
    return createErrorValidatorObj('Positive number required');
  }
  return null;
}

export function checkPositiveWholeNumber(value) {
  if (value === '') return null;
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal < 1 || !Number.isInteger(numVal)) {
    return createErrorValidatorObj('Positive number required');
  }
  return null;
}

export function checkPriceField(value) {
  if (value === '') return null;
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal < 0) {
    return createErrorValidatorObj('Positive number required');
  }
  if (numVal < 0.001 || numVal > 10000000) {
    return createErrorValidatorObj('Range: $0.001-10,000,000');
  }
  return null;
}

export function checkMandatoryPrice(value) {
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal < 0) {
    return createErrorValidatorObj('Positive number required');
  }
  if (numVal < 0.001 || numVal > 10000000) {
    return createErrorValidatorObj('Range: $0.001-10,000,000');
  }
  return null;
}

export function checkEvaluationFeeField(value) {
  if (value === '') return null;
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal < 0) {
    return createErrorValidatorObj('Positive number required');
  }
  if (numVal > 10000000) {
    return createErrorValidatorObj('The price of this item exceeds the $10,000,000 maximum');
  }
  return null;
}

export function checkPickupPlace(value) {
  if (value === null || ((value !== '') && !value.place_id)) {
    return createErrorValidatorObj('Please select a valid address');
  }
  return null;
}

export function checkLeadTimeField(value) {
  if (value === '') return null;
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal < 1 || !Number.isInteger(numVal)) {
    return createErrorValidatorObj('Positive number required');
  }
  if (numVal > 9999) {
    return createErrorValidatorObj('Enter a number up to 9999');
  }
  return null;
}

export function checkQty(qty, units) {
  const numVal = +qty;
  // eslint-disable-next-line no-restricted-globals
  if (units === unitsConst.EACH.id && (isNaN(numVal) || numVal <= 0 || !Number.isInteger(numVal))) {
    return createErrorValidatorObj('Whole number required');
  }
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal <= 0) {
    return createErrorValidatorObj('Positive number required');
  }
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || numVal > 1E10) {
    return createErrorValidatorObj('The value is too large');
  }
  return null;
}
