import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import dateFormat from '../../../utils/dateFormat';

const BaseTaskContainer = styled.div`
  padding: 6px 7px 18px 20px;
  width:100%;
  font-weight: 400;
  color: #808080;
  font-size: 16px;
  position: relative;
  word-break: break-word;
`;
const Date = styled.p`
  margin-right: 10px;
  font-size: 10px;
  display:block;
  float: right;
`;
const SellerName = styled.p`
  font-size: 16px;
  color: #808080;
  margin-bottom:5px;
`;
const StyledLi = styled.li`
  margin-top: -15px;
  padding-left: 25px;
  &::before {
    content: "";
    display: inline-block;
    width: 9px;
    height: 9px;
    position: relative;
    top: 28px;
    border-radius: 50%;
    background-color: #52A0B2;
  }
`;

const Action = ({ sellerName, date, message }) => (
  <StyledLi>
    <BaseTaskContainer>
      <SellerName>{sellerName.concat(' ', message)}</SellerName>
      <Date>{dateFormat(date)}</Date>
    </BaseTaskContainer>
  </StyledLi>
);

Action.propTypes = {
  sellerName: PropTypes.string,
  date: PropTypes.string,
  message: PropTypes.string,
};

export default Action;
