import { chatMsgType } from './index';

export const WIRETRANSFER = 'WIRETRANSFER';

export const WIRETRANSFER_COMPLETE = '{"pay_prv":"wiretransfer_broker"}';

export const NOC_FORMS = {
  BUILT_IN_QUOTE: 'built_in_quote',
  SEND_MESSAGE: 'send_message',
  PAYMENT_REQUEST: 'payment_request',
  WIRE_TRANSFER: 'wire_transfer',
  ORDER_STATUS: 'order_status',
  REPORT_PAYMENT: 'report_payment',
  IGNORE_TASK: 'ignore_task',
};

export const paymentsOrderStatus = [
  { value: chatMsgType.EPLANE_REVIEW, label: 'Under ePlane\'s review' },
  { value: chatMsgType.EPLANE_PAYMENT_REQUEST, label: 'ePlane sent payment request to the buyer' },
  { value: chatMsgType.EPLANE_PAYMENT_COMPLETE, label: 'Stripe payment to ePlane completed' },
  { value: chatMsgType.EPLANE_PAYMENT_FAILED, label: 'Stripe payment to ePlane failed' },
  { value: chatMsgType.EPLANE_PAYMENT_PROCESSING, label: 'Processing Stripe payment to ePlane' },
  { value: WIRETRANSFER, label: 'Wire transfer payment to ePlane completed' },
  { value: chatMsgType.EPLANE_BOUGHT_ITEM, label: 'ePlane purchased the item from the seller (payment completed)' },
  { value: chatMsgType.PAYMENT_COMPLETE, label: 'Buyer\'s payment to the seller completed' },
  { value: chatMsgType.PAYMENT_FAILED, label: 'Buyer\'s payment to the seller failed' },
  { value: chatMsgType.PAYMENT_PROCESSING, label: 'Processing buyer\'s payment to the seller' },
];

export const rfqMessageType = {
  KEY_META: 'meta',
  KEY_QUOTE: 'quote',
  KEY_PO_EDIT: 'po_edit',
  KEY_SHIPPING: 'shipping',
  KEY_CITED_MESSAGE_ID: 'cited_message_id',
  KEY_PAYMENT_REQUEST: 'payment_request',
  KEY_PAYMENT: 'payment',
  KEY_REQUEST_TO_PAY: 'request_to_pay',
  KEY_CONFIRM_WT: 'confirm_wt',
};

export const orderIconType = {
  SYNCED: 'synced',
  NOT_SYNCED: 'not_synced',
  REPAIR: 'repair',
};
