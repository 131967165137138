import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { color } from '../../../constants';

const Text = styled.span`
  color: ${(props) => (props.color || '')};
  font-size: 16px;
`;

const originStatusFormatter = (value) => {
  let icon = null;
  switch (value) {
    case 'sftp':
      icon = <Text color={color.goldenTainoi}><FontAwesomeIcon icon="folder-open" /></Text>;
      break;
    case 'website':
      icon = <Text color={color.inputBorder}><FontAwesomeIcon icon="globe" /></Text>;
      break;
    case 'backoffice':
      icon = <Text color={color.grey}><FontAwesomeIcon icon="cog" /></Text>;
      break;
    case 'other':
      icon = <Text color={color.darkorange}><FontAwesomeIcon icon="spinner" /></Text>;
      break;
    default: console.error(`Unknown report status id: ${value};`); // eslint-disable-line no-console
  }
  return <div title={value} style={{ textAlign: 'center' }}>{icon}</div>;
};

export default originStatusFormatter;
