import styled from 'styled-components';

import { color } from '../../constants';

export default styled.table`
  width: 100%;
  table-layout: fixed;

  thead {
    border-bottom: 2px solid ${color.grey};
  }
  th, td {
    padding: 20px 0;
    padding-right: 10px;
    text-align: left;
    vertical-align: top;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  tr {
    border-bottom: 2px solid ${color.lightGrey};
  }
  th:first-child, td:first-child {
    padding-left: 10px;
    vertical-align: top;
  }
  th:last-child, td:last-child {
    text-align: right;
    padding-right: 5px;
    vertical-align: top;
  }
  .no-content-data {
    border-bottom: none;
  }
`;
