import {
  MAILCHIMP_CONDITIONS_GET,
  MAILCHIMP_CONDITIONS_SUCCESS,
  MAILCHIMP_COMMAND_ADD,
  MAILCHIMP_COMMAND_ADD_SUCCESS,
  MAILCHIMP_PROCESS_ERROR_GET,
  MAILCHIMP_PROCESS_ERROR_GET_SUCCESS,
  MAILCHIMP_COMMAND_DELETE,
  MAILCHIMP_COMMAND_DELETE_SUCCESS,
  MAILCHIMP_PROCESS,
  MAILCHIMP_PROCESS_SUCCESS,
  MAILCHIMP_PROCESS_FAIL,
} from '../../constants';

const initialState = {
  items: null,
  status: null,
  errorLog: null,
  isFetching: false,
  isSaving: false,
  isFetchingError: false,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case MAILCHIMP_CONDITIONS_GET:
      return {
        ...state,
        errorLog: null,
        isFetching: true,
      };
    case MAILCHIMP_CONDITIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: payload.conditions,
        status: payload.status,
      };
    case MAILCHIMP_COMMAND_ADD:
      return {
        ...state,
        isSaving: true,
      };
    case MAILCHIMP_COMMAND_ADD_SUCCESS:
      return {
        ...state,
        isSaving: false,
        items: [...state.items, ...payload],
      };
    case MAILCHIMP_COMMAND_DELETE:
      return {
        ...state,
        isFetching: true,
      };
    case MAILCHIMP_COMMAND_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: state.items.filter((v) => v.condition_id !== payload),
      };
    case MAILCHIMP_PROCESS:
      return {
        ...state,
        errorLog: null,
        isSaving: true,
      };
    case MAILCHIMP_PROCESS_SUCCESS:
      return {
        ...state,
        isSaving: false,
        items: payload.conditions,
        status: payload.status,
      };
    case MAILCHIMP_PROCESS_ERROR_GET:
      return {
        ...state,
        isFetchingError: true,
      };
    case MAILCHIMP_PROCESS_ERROR_GET_SUCCESS:
      return {
        ...state,
        isFetchingError: false,
        errorLog: payload,
      };
    case MAILCHIMP_PROCESS_FAIL:
      return {
        ...state,
        isFetching: false,
        isSaving: false,
        isFetchingError: false,
      };
    default: return state;
  }
};
