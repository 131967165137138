import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FormTitleSection from '../../FormComponents/TitleSection/index';
import { TabContainer } from '../../StyledComponents';
import CustomCommissionForm from './CustomCommissionForm';
import { fetchProviders, saveProviders, removeCommission } from '../../../actions/companiesManagement/paymentProviders';
import { getUserPaymentProviders, getErrorsStore } from '../../../selectors';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';

const PaymentsPage = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();

  const {
    providers, isFetching, isProcessing,
  } = useSelector(getUserPaymentProviders);
  const { errors } = useSelector(getErrorsStore);

  const saveCustomCommission = (commission) => {
    dispatch(saveProviders({ user_id: userId, ...commission }));
  };

  const removeCustomCommission = (providerCode) => {
    dispatch(removeCommission({
      provider_code: providerCode,
      user_id: userId,
    }));
  };

  useEffect(() => {
    dispatch(fetchProviders());
  }, [dispatch]);

  if ((!providers || isFetching) && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <div>
      <FormTitleSection title="Payments Settings" showEditButton={false} />
      <TabContainer>
        <CustomCommissionForm
          title="Buyer's custom commissions when purchasing directly from ePlane"
          providers={providers}
          saveCustomCommission={saveCustomCommission}
          removeCustomCommission={removeCustomCommission}
          disabled={false}
        />
      </TabContainer>

      <ModalLoader show={isProcessing} />
    </div>
  );
};

export default PaymentsPage;
