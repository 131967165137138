import axios from 'axios';

import {
  COMPANY_CUSTOMERS_GET,
  COMPANY_CUSTOMERS_GET_SUCCESS,
  COMPANY_CUSTOMERS_GET_FAIL,
  COMPANY_CUSTOMERS_ERROR_REMOVE,
} from '../../constants';
import errorHandler from '../requestErrorHandler';

const startFetchingCompanyCustomers = () => ({
  type: COMPANY_CUSTOMERS_GET,
});
const fetchCompanyCustomersSuccess = (filters) => ({
  type: COMPANY_CUSTOMERS_GET_SUCCESS,
  payload: filters,
});
const fetchCompanyCustomersFail = (errorCode) => ({
  type: COMPANY_CUSTOMERS_GET_FAIL,
  payload: errorCode,
});

export const removeCompanyCustomersError = () => ({
  type: COMPANY_CUSTOMERS_ERROR_REMOVE,
});

export const fetchCompanyCustomers = (userId) => async (dispatch) => {
  dispatch(startFetchingCompanyCustomers);
  try {
    const response = await axios(`rest/V1/eplane/backoffice/user/${userId}/activeCompanyCustomers`);
    dispatch(fetchCompanyCustomersSuccess(response.data));
  } catch (e) {
    errorHandler(e.response, dispatch, fetchCompanyCustomersFail, e);
  }
};
