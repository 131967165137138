import {
  FILTER_LIST_GET,
  FILTER_LIST_GET_SUCCESS,

  FILTER_SAVE,
  FILTER_SAVE_SUCCESS,

  FILTER_REMOVE,
  FILTER_REMOVE_SUCCESS,

  FILTERS_FAIL,
  FILTER_NOTIFICATION,
  FILTER_NOTIFICATION_SUCCESS,
  FILTER_NOTIFICATION_FAIL,
  FILTER_ERRORS_CLEAR,

  TIME_ZONES_GET,
  TIME_ZONES_SUCCESS,
  AGGREGATION_EMAILS_SAVE,
  AGGREGATION_EMAILS_SAVE_SUCCESS,
} from '../../constants';

const initialState = {
  filters: [],
  aggregationSettings: null,
  timeZones: null,
  isFetching: false,
  isSaving: false,
  isTimeZonesFetching: false,
  errorCode: null,
  notificationErrorCode: null,
};

const filterReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FILTER_LIST_GET:
      return {
        ...state,
        isFetching: true,
        errorCode: null,
      };
    case FILTER_LIST_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        filters: payload.filters,
        aggregationSettings: payload.aggregation_settings,
      };

    case FILTER_SAVE:
      return {
        ...state,
        isSaving: true,
        errorCode: null,
      };
    case FILTER_SAVE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        filters: payload.filters,
      };

    case FILTER_REMOVE:
      return {
        ...state,
        isSaving: true,
        errorCode: null,
      };
    case FILTER_REMOVE_SUCCESS:
      return {
        ...state,
        filters: payload.filters,
        isSaving: false,
      };

    case FILTERS_FAIL:
      return {
        ...state,
        isFetching: false,
        isSaving: false,
        isTimeZonesFetching: false,
        errorCode: payload,
      };

    case FILTER_NOTIFICATION:
      return {
        ...state,
        notificationErrorCode: null,
      };
    case FILTER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        filters: payload.filters,
      };
    case FILTER_NOTIFICATION_FAIL:
      return {
        ...state,
        notificationErrorCode: payload,
      };
    case FILTER_ERRORS_CLEAR:
      return {
        ...state,
        errorCode: null,
        notificationErrorCode: null,
      };

    case TIME_ZONES_GET:
      return {
        ...state,
        isTimeZonesFetching: true,
      };
    case TIME_ZONES_SUCCESS:
      return {
        ...state,
        timeZones: payload,
        isTimeZonesFetching: false,
      };

    case AGGREGATION_EMAILS_SAVE:
      return {
        ...state,
        isSaving: true,
      };
    case AGGREGATION_EMAILS_SAVE_SUCCESS:
      return {
        ...state,
        aggregationSettings: state.aggregationSettings?.map((v) => ((v.aggregation_type === payload.aggregation_type) ? payload : v)),
        isSaving: false,
      };
    default: return state;
  }
};

export default filterReducer;
