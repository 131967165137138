import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Form,
  FormGroup as FormGroupNormal,
  FormControl,
  InputGroup as InputGroupNormal,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import moment from 'moment';

import DatesPicker from '../DatesPicker';

const InputGroup = styled(InputGroupNormal)`
  input {
    padding-right: 30px;
  }
`;
const SearchContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;
const FormGroup = styled(FormGroupNormal)`
`;
const CalendarWrapper = styled.div`
  padding-top: 5px;
  display: flex;
  justify-content: center;
`;
const ClearElement = styled.span`
  position: absolute;
  top: 7px;
`;

const spanStyle = {
  right: '75px',
  zIndex: '3',
  pointerEvents: 'all',
};

const SearchBar = ({
  startDate,
  endDate,
  onCalendarChange,
  showCalendar,
  searchOnTheFly,
  placeholder,
  defaultInputValue,
  onSearchHandler,
}) => {
  const [searchValue, setSearchValue] = useState(defaultInputValue || '');

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (!searchOnTheFly) {
      onSearchHandler(searchValue);
    }
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    setSearchValue('');
    onSearchHandler('');
  };

  const onSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    if (searchOnTheFly) {
      onSearchHandler(value);
    }
  };

  const calendar = showCalendar ? (
    <CalendarWrapper>
      <DatesPicker
        startDate={startDate}
        endDate={endDate}
        onChange={(range) => onCalendarChange(range, searchValue)}
        minimumNights={0}
        numberOfMonths={2}
        readOnly
        isShownDatePresets
      />
    </CalendarWrapper>
  ) : null;

  const searchButton = searchOnTheFly || (
    <InputGroup.Append>
      <Button type="submit" variant="primary">Search</Button>
    </InputGroup.Append>
  );

  return (
    <Row>
      <Col lg={{ span: 6, offset: 3 }} md={{ span: 12, offset: 0 }}>
        <SearchContainer>
          <Form onSubmit={onSubmitHandler}>
            <FormGroup>
              <InputGroup>
                <FormControl
                  bsPrefix="form-control search-form"
                  type="text"
                  placeholder={placeholder}
                  value={searchValue}
                  onChange={onSearchInputChange}
                />
                { !!searchValue && (
                  <ClearElement
                    onClick={onResetHandler}
                    style={spanStyle}
                    role="button"
                    tabIndex={-1}
                    onKeyPress={null}
                  >
                    <FontAwesomeIcon icon="times" />
                  </ClearElement>
                )}
                {searchButton}
              </InputGroup>
              {calendar}
            </FormGroup>
          </Form>
        </SearchContainer>
      </Col>
    </Row>
  );
};

const momentPT = PropTypes.instanceOf(moment);

SearchBar.propTypes = {
  onSearchHandler: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showCalendar: PropTypes.bool,
  onCalendarChange: PropTypes.func,
  startDate: momentPT,
  endDate: momentPT,
  searchOnTheFly: PropTypes.bool,
  defaultInputValue: PropTypes.string,
};

SearchBar.defaultProps = {
  searchOnTheFly: true,
};

export default SearchBar;
