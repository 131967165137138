import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import A from '../../A';
import { color, PUBLIC_URL } from '../../../constants';

const Text = styled.span`
  color: ${(props) => (props.color || '')};
  font-size: 16px;
`;
const CompanyNameText = styled.span`
  display: list-item; 
  font-weight: bold;
  font-size: 14px;
  margin-right: 15px;
  min-width: 100px;
`;
const WarningMessage = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: ${color.darkorange};
  & a {
    text-decoration: underline;
    color: ${color.darkBirch};
  }
  & a:hover {
    color: ${color.birch};
  }
`;
const ListContainer = styled.span`
  display: flex;
`;

const CloneWeightReport = ({ cloneResult }) => {
  let generalStatContent = null;
  const autopilotNotEnabledList = cloneResult && cloneResult.filter((v) => !v.autopilot_enabled);
  if (cloneResult && cloneResult.length > 0) {
    generalStatContent = (
      <div>
        <br />
        <p>
          <Text color={color.textGreen}><FontAwesomeIcon icon="check" /></Text>
          &nbsp;Successfully cloned:&nbsp;
          {cloneResult.length}
          &nbsp;
          {`${cloneResult.length === 1 ? 'company' : 'companies'}.`}
        </p>
        <p>
          <Text color={color.darkorange}><FontAwesomeIcon icon="exclamation-triangle" /></Text>
          &nbsp;Need to enable autopilot:&nbsp;
          {autopilotNotEnabledList && autopilotNotEnabledList.length}
          &nbsp;
          {`${autopilotNotEnabledList && autopilotNotEnabledList.length === 1 ? 'company' : 'companies'}.`}
        </p>
      </div>
    );
  }
  const listCompanyItems = autopilotNotEnabledList && autopilotNotEnabledList.map((v) => (
    <ListContainer key={v.user_id.toString()}>
      <CompanyNameText>{v.company_name}</CompanyNameText>
      <WarningMessage>
        Go to the&nbsp;
        <A target="_blank" rel="noopener noreferrer" to={`${PUBLIC_URL}/companies/${v.user_id}/userSettings`}>
          Company Settings
        </A>
        &nbsp;to enable autopilot.
      </WarningMessage>
    </ListContainer>
  ));
  return (
    <>
      {generalStatContent}
      <ul>
        {listCompanyItems}
      </ul>
    </>
  );
};

CloneWeightReport.propTypes = {
  cloneResult: PropTypes.arrayOf(PropTypes.object),
};

export default CloneWeightReport;
