import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button, Row, Col, Tooltip, OverlayTrigger,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import axios from 'axios';

import { getWeightGroupsStore, getCompanyListStore } from '../../../selectors';
import {
  fetchWeightGroups, removeGroup, saveGroup, fetchGroupList,
} from '../../../actions/settings/weightGroups';
import { fetchCompanyList } from '../../../actions/companyListActions';
import CompanyWeightForm from '../../CompaniesManagement/UserSettingsWeights/CompanyWeightForm';
import RemoveModal from '../../Modals/RemoveModal';
import ListManagement from './ListManagementModal';
import FormActionButtons from '../../FormComponents/ActionButtons';
import Loader from '../../Loader';
import ModalLoader from '../../ModalLoader';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import { TabContainer } from '../../StyledComponents';
import createErrorValidationObj from '../../../utils/createErrorValidationObj';
import textTruncate from '../../../utils/textTruncate';
import { color } from '../../../constants';
import { companyWeightValidator } from '../../CompaniesManagement/UserSettingsWeights/validators';

const WeightsLoading = styled.div`
  margin-top: 50px;
  text-align: center;
`;
const ListButtonsContainer = styled.div`
  margin-top: 2px;
  display: flex;
  justify-content: flex-start;
  button:nth-child(even) {
    margin: 0 10px;
  }
`;

const tooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

const cache = new Map();

const createValidationObj = (state, helpText) => ({
  validationState: state,
  helpText,
});

const WeightCompanyGroup = () => {
  const dispatch = useDispatch();

  const { listId } = useParams();
  const history = useHistory();

  const {
    selectedItem, weightGroups, changedListId,
    isFetching, isProcessing, isListFetching,
  } = useSelector(getWeightGroupsStore);
  const { companies } = useSelector(getCompanyListStore);

  const [selectedList, setSelectedList] = useState({
    currentList: null,
    listSellers: [],
  });
  const [modals, setModals] = useState({
    showRemoveModal: false,
    showAddModal: false,
    showEditModal: false,
    showConfirmationModal: false,
  });
  const [dataChanged, setDataChanged] = useState(false);

  const weightListNameOptions = useMemo(
    () => weightGroups && weightGroups.map((v) => ({ label: v.list_name, value: v.id })).sort((a, b) => a.label.localeCompare(b.label)),
    [weightGroups],
  );

  useEffect(() => {
    dispatch(fetchWeightGroups());
    dispatch(fetchCompanyList());
  }, [dispatch]);

  useEffect(() => {
    setSelectedList({
      ...selectedList,
      listSellers: (selectedItem) || [],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  useEffect(() => {
    if (weightListNameOptions && weightListNameOptions.length !== 0) {
      const routeItem = weightListNameOptions.find((v) => v.value === listId);
      onSellersListChange(routeItem || weightListNameOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weightListNameOptions]);

  useEffect(() => {
    if (changedListId) {
      const item = weightListNameOptions.find((v) => v.value === changedListId);
      onSellersListChange(item);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, changedListId]);

  useEffect(() => {
    if (listId) {
      dispatch(fetchGroupList(listId));
    }
  }, [dispatch, listId]);

  const closeAddEditModals = () => {
    setModals({ ...modals, showAddModal: false, showEditModal: false });
  };

  const removeList = () => {
    dispatch(removeGroup([selectedList.currentList.value]));
    setModals({ ...modals, showRemoveModal: false });
  };

  const onSaveHandler = () => {
    setModals({ ...modals, showConfirmationModal: false });

    let changes = selectedList.listSellers;
    let validationFlag = false;

    if (changes && changes.some((e) => companyWeightValidator(e.seller_weight) !== null)) {
      return;
    }

    changes = changes.map((elem, index) => {
      const item = companies.find((obj) => obj.company_name === elem.name);

      const finalChanges = {
        seller_id: item && item.company_id,
        seller_weight: elem.seller_weight,
      };

      let errorText = null;
      if (!elem.name) errorText = 'Please choose a company';
      else if (!item) {
        // save text in company input
        finalChanges.name = elem.name;
        errorText = 'Company doesn\'t exist';
      } else if (changes.findIndex((v, i) => v.name === item.company_name && index !== i) >= 0) {
        errorText = 'You have already added this company';
      }

      if (errorText) {
        validationFlag = true;
        finalChanges.validation = createValidationObj('error', errorText);
      }

      return finalChanges;
    });

    if (validationFlag) {
      // copy warnings from state in case there isn't error validation object
      changes = changes.map((v) => {
        if (!v.validation) {
          const item = selectedList.listSellers.find((obj) => obj.seller_id === v.seller_id);
          return { ...v, validation: item && item.validation };
        }
        return v;
      });

      setSelectedList({ ...selectedList, listSellers: changes });
      return;
    }

    setDataChanged(false);
    const data = {
      id: selectedList.currentList.value,
      list_name: selectedList.currentList.label,
      sellers_weights: changes,
    };

    dispatch(saveGroup(data, true));
  };

  const notEmptyValidatorCreator = (value) => {
    if (!value.trim()) return createErrorValidationObj('Please enter list name');
    if (weightListNameOptions.some((v) => v.label.toLowerCase() === value.toLowerCase())) {
      return createErrorValidationObj(`${value} already exists`);
    }
    return null;
  };

  const onAddCompanyBtnClick = () => {
    const lastItem = selectedList.listSellers[selectedList.listSellers.length - 1];
    if (selectedList.listSellers.length !== 0 && !lastItem.name && !lastItem.seller_weight) return;
    const newData = [
      ...selectedList.listSellers,
      { seller_id: null, seller_weight: null },
    ];

    setDataChanged(true);
    setSelectedList({ ...selectedList, listSellers: newData });
  };

  const onDeleteCompanyBtbClick = (id) => () => {
    if (selectedList.listSellers.length < 1) return;

    const newData = selectedList.listSellers.map((v) => {
      // clear error validation, warning validation are left
      if (v.validation && v.validation.validationState === 'error') {
        return { ...v, validation: null };
      }
      return { ...v };
    });
    newData.splice(id, 1);

    setDataChanged(true);
    setSelectedList({ ...selectedList, listSellers: newData });
  };

  const onCompanyFocusout = (name, value, index) => {
    const searchValue = companies.find((v) => v.company_name === value);

    if (searchValue) {
      const companyId = searchValue.company_id;
      if (cache.has(companyId)) {
        const acceptRFQ = cache.get(companyId);
        setValidationState(index, searchValue, acceptRFQ);
      } else {
        axios(`/rest/default/V1/eplane/backoffice/user/${companyId}/settings`).then((response) => {
          const showWarning = !response.data.receive_all_demands_enabled;
          cache.set(companyId, showWarning);
          setValidationState(index, searchValue, showWarning);
        });
      }
    }
  };

  const onCompanyWeightChange = (name, value, index) => {
    const newData = selectedList.listSellers.map((v) => {
      // clear error validation, warning validation are left
      if (v.validation && v.validation.validationState === 'error') {
        return { ...v, validation: null };
      }
      return { ...v };
    });
    newData[index][name] = value;

    setDataChanged(true);
    setSelectedList({ ...selectedList, listSellers: newData });
  };

  const onDeleteAllClick = () => {
    setDataChanged(true);
    setSelectedList({ ...selectedList, listSellers: [] });
  };

  const setValidationState = (index, company, showWarning) => {
    if (!selectedList.listSellers.length) {
      setSelectedList({ ...selectedList, listSellers: [] });
    }

    const indexCheck = index < selectedList.listSellers.length;
    const hasValidObj = selectedList.listSellers[index].validation
      && selectedList.listSellers[index].validation.validationState !== 'warning';
    const newData = selectedList.listSellers.map((v) => ({ ...v }));

    // if company has validation obj do not replace with new warning validation obj
    if (indexCheck && !hasValidObj && newData[index].name === company.company_name) {
      newData[index].seller_id = company.company_id;
      newData[index].validation = showWarning
        ? createValidationObj('warning', `Warning: ${textTruncate(company.company_name)} doesn't accept Tier3 RFQs`)
        : createValidationObj('success');
    }
    setSelectedList({ ...selectedList, listSellers: newData });
  };

  const onSellersListChange = (selectedOption) => {
    setSelectedList({ ...selectedList, currentList: selectedOption });
    history.push({
      pathname: `/eplaneSettings/companyGroup/${selectedOption.value}`,
    });
  };

  const onCancelHandler = () => {
    setSelectedList({
      ...selectedList,
      listSellers: (selectedItem) || [],
    });
    setDataChanged(false);
  };

  if (isFetching) return <Loader />;

  return (
    <>
      <FormTitleSection title="TIER 3 lists" showEditButton={false} />
      <TabContainer>
        <p>Current Tier 3 lists:</p>
        <Row>
          <Col sm={10}>
            <Row>
              <Col sm={7}>
                <Select
                  value={selectedList.currentList}
                  name="clone_companies"
                  onChange={onSellersListChange}
                  options={weightListNameOptions}
                />
              </Col>
              <Col sm={2}>
                <ListButtonsContainer>
                  <OverlayTrigger placement="top" overlay={tooltip('Add new list')}>
                    <Button
                      onClick={() => setModals({ ...modals, showAddModal: true })}
                      variant="success"
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={tooltip('Rename selected list')}>
                    <Button
                      onClick={() => setModals({ ...modals, showEditModal: true })}
                      variant="primary"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={tooltip('Remove selected list')}>
                    <Button
                      onClick={() => setModals({ ...modals, showRemoveModal: true })}
                      variant="danger"
                    >
                      <FontAwesomeIcon icon="trash-alt" />
                    </Button>
                  </OverlayTrigger>
                </ListButtonsContainer>
              </Col>
            </Row>
          </Col>
        </Row>

        {!isListFetching
          ? (
            <Row>
              <Col sm={10}>
                <CompanyWeightForm
                  companies={selectedList.listSellers}
                  suggestion={companies}
                  onChange={onCompanyWeightChange}
                  onAddCompanyBtnClick={onAddCompanyBtnClick}
                  onDeleteCompanyBtbClick={onDeleteCompanyBtbClick}
                  onDeleteAllClick={onDeleteAllClick}
                  onCompanyFocusout={onCompanyFocusout}
                />
              </Col>
            </Row>
          )
          : (
            <Row>
              <Col sm={6}>
                <WeightsLoading>
                  <FontAwesomeIcon icon="spinner" size="3x" color={color.blue} pulse />
                </WeightsLoading>
              </Col>
            </Row>
          )}

        {dataChanged && (
          <>
            <hr />
            <FormActionButtons
              saveHandler={() => setModals({ ...modals, showConfirmationModal: true })}
              cancelHandler={onCancelHandler}
            />
          </>
        )}

        <RemoveModal
          show={modals.showRemoveModal}
          onRemove={removeList}
          onHide={() => setModals({ ...modals, showRemoveModal: false })}
          onExited={() => setModals({ ...modals, showRemoveModal: false })}
          isRemoving={false}
          textRemoving="Removing"
          textRemove="Remove"
          text="Are you sure you want to remove this list?"
        />
        <ConfirmationModal
          show={modals.showConfirmationModal}
          confirm={onSaveHandler}
          onHide={() => setModals({ ...modals, showConfirmationModal: false })}
        >
          Are you sure you want to apply the changes?
        </ConfirmationModal>
        <ListManagement
          list={modals.showEditModal ? selectedList.currentList : null}
          title={modals.showAddModal ? 'Add new list' : 'Edit list name'}
          show={modals.showAddModal || modals.showEditModal}
          onSaveHandler={(data, editMode) => dispatch(saveGroup(data, editMode))}
          onHide={closeAddEditModals}
          validation={notEmptyValidatorCreator}
        />
        <ModalLoader show={isProcessing} />
      </TabContainer>
    </>
  );
};

export default WeightCompanyGroup;
