import { combineReducers } from 'redux';

import weightGroups from './weightGroups';
import vendorCategories from './vendorCategories';
import emails from './emails';
import paymentProviders from './paymentProviders';
import news from './news';
import mailchimp from './mailchimp';
import orderLimits from './orderLimits';
import bankAccount from './bankAccount';
import proPrice from './proPrice';

export default combineReducers({
  weightGroups,
  vendorCategories,
  emails,
  paymentProviders,
  news,
  mailchimp,
  orderLimits,
  bankAccount,
  proPrice,
});
