import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { color } from '../../constants';

// import NormalA from './../../components/A';

const A = styled(NavLink)`
  padding: 1em;
  display: inline-block;
  text-transform: uppercase;

  color: ${color.blue};

  &:hover {
    color: ${color.darkBlue};
    text-decoration: underline;
  }
  
  &:active, &:focus {
    color: ${color.blue};
    text-decoration: none;
  }
`;

const NavA = ({ children, ...props }) => (
  <A {...props} activeStyle={{ fontWeight: 'bold' }}>{children}</A>
);

NavA.propTypes = {
  children: PropTypes.string,
};

export default NavA;
