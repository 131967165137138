import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import IgnoreRuleForm from '../../NetworkOperationsCenter/ProvideQuote/TasksIgnoreForm';

export default function IgnoreRuleModal({ onHide, ...other }) {
  return (
    <Modal centered onHide={onHide} {...other}>
      <Modal.Body>
        <IgnoreRuleForm />
      </Modal.Body>
    </Modal>
  );
}

IgnoreRuleModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};
