import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ScrollableContainer as TasksScrollableContainer } from '../../../StyledComponents';
import Task from '../Task';

const StyledUl = styled.ul`
  list-style:none;
  padding: 0;
`;

const TasksList = ({
  tasks,
  openedTaskId,
  height,
  onTaskClick,
  totalTaskPages,
  fetchTasksListWithParams,
  isTasksFetching,
  isLoadMore,
  isPaymentTasks,
}) => {
  const ref = createRef();

  const listCompanyItems = tasks && tasks.map((task) => (
    <Task
      key={task.task_id}
      orderId={task.order_increment_id}
      taskId={task.task_id}
      sellerName={isPaymentTasks ? task.seller_name : `${task.seller_name} - Seller`}
      date={task.created_at}
      status={task.task_status}
      message={task.chat_status}
      isViewed={task.customer_view_status}
      isChosen={task.task_id === openedTaskId}
      onTaskClick={onTaskClick}
    />
  ));

  const loadMoreTasks = () => fetchTasksListWithParams({ loadMore: true });

  const handleTaskListScroll = (e) => {
    const element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight && totalTaskPages > 1) {
      loadMoreTasks();
    }
  };

  useEffect(() => {
    if (!isLoadMore && ref.current.scrollTo) ref.current.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTasksFetching]);

  return (
    <TasksScrollableContainer ref={ref} maxHeight={height - 250} onScroll={handleTaskListScroll}>
      <StyledUl>
        { listCompanyItems }
      </StyledUl>
    </TasksScrollableContainer>
  );
};

TasksList.propTypes = {
  fetchTasksListWithParams: PropTypes.func.isRequired,
  totalTaskPages: PropTypes.number,
  height: PropTypes.number,
  tasks: PropTypes.arrayOf(PropTypes.shape({
    orderId: PropTypes.string,
    sellerName: PropTypes.string,
    date: PropTypes.string,
    message: PropTypes.string,
  })),
  openedTaskId: PropTypes.string,
  isTasksFetching: PropTypes.bool,
  isLoadMore: PropTypes.bool,
  isPaymentTasks: PropTypes.bool,
  onTaskClick: PropTypes.func.isRequired,
};

export default TasksList;
