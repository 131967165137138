import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Modal, Button, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import UniversalControl from '../../FormComponents/UniversalControl';
import {
  ButtonContainer,
  ModalTitle,
  FlexContainer as FlexContainerNormal,
  ModalErrorText,
} from '../../StyledComponents';
import emailValidator from '../../../utils/emailValidator';
import pickupSiteEplaneEmailValidator from '../../../utils/pickupSiteEplaneEmailValidator';
import IATAcodeInput from '../../UserCompanyManagement/ShippingDetailsPage/SiteModal/IATAcodeInput';
import createErrorValidationObj from '../../../utils/createErrorValidationObj';
import emailButtonImg from '../../../assets/email.png';
import {
  DEFAULT_COUNTRY_VALUE_SELECT,
  DEFAULT_REGION_VALUE_SELECT,
} from '../../../constants';

const FlexContainer = styled(FlexContainerNormal)`
  align-items: center;
`;
const EmailButton = styled.span`
  cursor: pointer;
  background: url(${emailButtonImg}) no-repeat 0 0;
  width: 32px;
  height: 32px;
`;
export const EmailFlexColumn = styled.div`
  display: flex;
  width:5%;
  justify-content: center;
`;
const EmailCont = styled.div`
  display: flex;
  justify-content: end;
  height: 275px;
  align-items: flex-end;
`;

const initialSiteDataObj = {
  name: '',
  iata_code: null,
  street: '',
  city: '',
  country_id: null,
  region_id: null,
  state: null,
  zip_code: '',
  contact_name: '',
  contact_phone: '',
  contact_mobile: null,
  contact_email: '',
  eplane_email: '',
};

const initialValidationStateObj = {
  name: null,
  iata_code: null,
  street: null,
  city: null,
  country_id: null,
  region_id: null,
  state: null,
  zip_code: null,
  contact_name: null,
  contact_phone: null,
  contact_mobile: null,
  contact_email: null,
  eplane_email: null,
};

/*
  This component works in two modes: edit mode and create mode.

  Edit mode:
  This mode works when siteData props is specified.

  Create mode:
  This mode works when siteData props is NOT specified.
 */

const mapSiteData = (siteData) => ({
  name: siteData.name,
  iata_code: siteData.iata_code,
  street: siteData.street,
  city: siteData.city,
  country_id: siteData.country_id,
  region_id: siteData.region_id,
  state: siteData.state,
  zip_code: siteData.zip_code,
  contact_name: siteData.contact_name,
  contact_phone: siteData.contact_phone,
  contact_mobile: siteData.contact_mobile,
  contact_email: siteData.contact_email,
  eplane_email: siteData.eplane_email,
});

export default class PickupSiteModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      validationState: initialValidationStateObj,
      siteData: props.siteData ? mapSiteData(props.siteData) : initialSiteDataObj,
    };
  }

  onSave = () => {
    const formIsValid = this.checkForValidity();
    const { onSaveHandler } = this.props;
    const { siteData } = this.state;
    if (formIsValid) {
      onSaveHandler(siteData);
    }
  };

  onIATAvalidationChanged = (validationObj) => {
    this.setState((prevState) => {
      const newValidationState = { ...prevState.validationState };
      newValidationState.iata_code = validationObj;

      return {
        validationState: newValidationState,
      };
    });
  };

  onChange = (e) => {
    const { regionList } = this.props;
    const { value, name } = e.target;

    this.setState((prevState) => {
      const updatedSiteData = { ...prevState.siteData };

      if (name === 'iata_code') updatedSiteData.iata_code = value.toUpperCase();
      else updatedSiteData[name] = value;

      if (name === 'country_id') {
        if (regionList[value]) updatedSiteData.region_id = DEFAULT_REGION_VALUE_SELECT;
        // if it was switched to country for which regions isn't specified
        else updatedSiteData.region_id = null;
      }

      return {
        siteData: updatedSiteData,
      };
    });
  };

  onBlur = (e) => {
    const { value, name } = e.target;
    const { regionList, listOfSiteNames, siteData: siteDataProps } = this.props;
    const { siteData } = this.state;
    let validation = null;
    // validation for IATA code is located in IATAcodeInput Component
    if (name === 'iata_code') return;
    if (name === 'name') {
      validation = validationMap.name(value, listOfSiteNames, siteDataProps);
    } else if (name === 'region_id') {
      validation = validationMap.region_id(value, siteData.country_id, regionList);
    } else {
      validation = validationMap[name](value);
    }

    this.setState((prevState) => {
      const newValidationState = { ...prevState.validationState };

      newValidationState[name] = validation;
      return { validationState: newValidationState };
    });
  };

  /** function checks validation on all inputs and
   *  fills the state.validationState object with validation values
   * @returns {Boolean} if true validation is passed, otherwise false
   */
  checkForValidity = () => {
    let fieldsAreValid = true;
    const { siteData, validationState } = this.state;
    const { listOfSiteNames, regionList, siteData: siteDataProps } = this.props;
    const newValidationStateObj = {};
    Object.keys(siteData).forEach((nameOfField) => {
      let validation = validationState[nameOfField];

      // validation for IATA code is located in IATAcodeInput Component
      if (nameOfField === 'iata_code' && validationState.iata_code) {
        fieldsAreValid = false;
      } else if (nameOfField === 'name') {
        validation = validationMap.name(siteData.name, listOfSiteNames, siteDataProps);
      } else if (nameOfField === 'region_id') {
        validation = validationMap.region_id(siteData[nameOfField], siteData.country_id, regionList);
      } else {
        validation = validationMap[nameOfField](siteData[nameOfField]);
      }

      newValidationStateObj[nameOfField] = validation;
      if (validation) fieldsAreValid = false;
    });
    this.setState({
      validationState: newValidationStateObj,
    });
    return fieldsAreValid;
  };

  generateEplaneEmail = () => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    // eslint-disable-next-line
    return 'inventory+' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '@eplane.com';
  };

  setEplaneEmail = () => {
    const email = this.generateEplaneEmail();

    const { siteData: siteDataA, validationState } = this.state;
    const validation = validationMap.eplane_email(email);
    const newValidationState = { ...validationState, eplane_email: validation };

    this.setState({
      siteData: { ...siteDataA, eplane_email: email },
      validationState: newValidationState,
    });
  };

  render() {
    const {
      countriesList,
      regionList,
      listOfSiteNames,
      onSaveHandler,
      siteData: siteDataProps,
      errorCode,
      isSaving,
      isUserExists,
      ...other
    } = this.props;
    const { siteData, validationState } = this.state;
    let regionControl;
    const regions = regionList[siteData.country_id];

    const headerText = siteDataProps ? 'Edit Site Details' : 'Add a New Site';
    const saveButtonText = isSaving ? 'Saving...' : 'Save';

    const countriesOptions = countriesList.slice();
    countriesOptions.unshift([DEFAULT_COUNTRY_VALUE_SELECT, DEFAULT_COUNTRY_VALUE_SELECT]);
    const sitesEplaneEmailField = (
      <UniversalControl
        name="eplane_email"
        type="email"
        labelText="ePlane's Email"
        disabled={!isUserExists}
        onChange={this.onChange}
        onBlur={this.onBlur}
        value={siteData.eplane_email || ''}
        {...validationState.eplane_email}
      />
    );

    if (regions) {
      const regionsOptions = regions.slice();
      regionsOptions.unshift([DEFAULT_REGION_VALUE_SELECT, DEFAULT_REGION_VALUE_SELECT]);
      regionControl = (
        <UniversalControl
          name="region_id"
          as="select"
          labelText="State/Province"
          optionsForSelect={regionsOptions}
          value={siteData.region_id || ''}
          onChange={this.onChange}
          onBlur={this.onBlur}
          feedBack={false}
          {...validationState.region_id}
        />
      );
    } else {
      regionControl = (
        <UniversalControl
          name="state"
          type="text"
          labelText="State/Province (optional)"
          value={siteData.state || ''}
          onChange={this.onChange}
          onBlur={this.onBlur}
          {...validationState.state}
        />
      );
    }

    return (
      <Modal centered size="xl" {...other}>
        <Modal.Header closeButton>
          <ModalTitle>{headerText}</ModalTitle>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Form>
              <Row>
                <Col>
                  <UniversalControl
                    name="name"
                    type="text"
                    labelText="Site name"
                    value={siteData.name}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    {...validationState.name}
                  />
                  <IATAcodeInput
                    name="iata_code"
                    type="text"
                    labelText="IATA code (optional)"
                    value={siteData.iata_code || ''}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onValidationStateChange={this.onIATAvalidationChanged}
                    {...validationState.iata_code}
                  />
                  <UniversalControl
                    name="street"
                    type="text"
                    labelText="Street address"
                    value={siteData.street}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    {...validationState.street}
                  />
                  <UniversalControl
                    name="city"
                    type="text"
                    labelText="City"
                    value={siteData.city}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    {...validationState.city}
                  />
                  <UniversalControl
                    name="country_id"
                    as="select"
                    optionsForSelect={countriesOptions}
                    type="text"
                    labelText="Country"
                    value={siteData.country_id || ''}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    feedBack={false}
                    {...validationState.country_id}
                  />
                  {regionControl}
                  <UniversalControl
                    name="zip_code"
                    labelText="Zip Code"
                    value={siteData.zip_code}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    {...validationState.zip_code}
                  />
                </Col>

                <Col>
                  <UniversalControl
                    name="contact_name"
                    type="text"
                    labelText="Full name"
                    value={siteData.contact_name}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    {...validationState.contact_name}
                  />
                  <UniversalControl
                    name="contact_phone"
                    type="text"
                    labelText="Phone"
                    value={siteData.contact_phone}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    {...validationState.contact_phone}
                  />
                  <UniversalControl
                    name="contact_mobile"
                    type="text"
                    labelText="Mobile (optional)"
                    value={siteData.contact_mobile || ''}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    {...validationState.contact_mobile}
                  />
                  <UniversalControl
                    name="contact_email"
                    type="email"
                    labelText="Email"
                    value={siteData.contact_email}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    {...validationState.contact_email}
                  />
                  {(siteData.eplane_email || isUserExists) && sitesEplaneEmailField }
                </Col>
              </Row>
              {isUserExists && (
                <EmailFlexColumn>
                  <EmailCont>
                    <EmailButton name="email_button" onClick={this.setEplaneEmail} />
                  </EmailCont>
                </EmailFlexColumn>
              )}
            </Form>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <FlexContainer>
            <ModalErrorText show={errorCode}>
              <FontAwesomeIcon icon="exclamation-triangle" />
              &nbsp;Something went wrong.
            </ModalErrorText>
            <ButtonContainer btnWidth="120px" space="10px">
              <Button disabled={isSaving} variant="primary" onClick={this.onSave}>{saveButtonText}</Button>
              <Button variant="outline-secondary" onClick={other.onHide}>Cancel</Button>
            </ButtonContainer>
          </FlexContainer>
        </Modal.Footer>
      </Modal>
    );
  }
}

const siteDataPT = PropTypes.shape({
  name: PropTypes.string,
  iata_code: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  country_id: PropTypes.string,
  region_id: PropTypes.string,
  state: PropTypes.string,
  zip_code: PropTypes.string,
  contact_name: PropTypes.string,
  contact_phone: PropTypes.string,
  contact_mobile: PropTypes.string,
  contact_email: PropTypes.string,
  eplane_email: PropTypes.string,

  site_type: PropTypes.number,
});

PickupSiteModal.propTypes = {
  show: PropTypes.bool,
  countriesList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  regionList: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.array)),
  siteData: siteDataPT,
  listOfSiteNames: PropTypes.arrayOf(PropTypes.string),
  constants: PropTypes.shape({}),
  isSaving: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onSaveHandler: PropTypes.func,
  errorCode: PropTypes.number,
  isUserExists: PropTypes.bool,
};

const notEmptyValidatorCreator = (errorText) => (value) => {
  if (!value.trim()) return createErrorValidationObj(errorText);
  return null;
};

const validationMap = {

  name: (name, listOfSiteNames, siteDataProps) => {
    const nameTrimmed = name.trim();
    if (!nameTrimmed) return createErrorValidationObj('Please enter the site name');
    if (siteDataProps && siteDataProps.name === nameTrimmed) return null;
    const lowerCaseListOfSiteNames = listOfSiteNames.map((n) => n.toLowerCase());
    if (lowerCaseListOfSiteNames.indexOf(nameTrimmed.toLowerCase()) !== -1) {
      return createErrorValidationObj('This site name already exists');
    }
    return null;
  },

  iata_code: () => null,

  street: notEmptyValidatorCreator('Please enter the street address'),

  city: notEmptyValidatorCreator('Please enter the city'),

  country_id: (value) => {
    if (!value || value === DEFAULT_COUNTRY_VALUE_SELECT) return createErrorValidationObj('Please select the country');
    return null;
  },

  region_id: (value, country_id, regionList) => { // eslint-disable-line camelcase
    if (!regionList[country_id]) return null;
    if (!value || value === DEFAULT_REGION_VALUE_SELECT) return createErrorValidationObj('Please select the country');
    return null;
  },

  state: () => null,

  zip_code: notEmptyValidatorCreator('Please enter the zip code'),

  contact_name: (fullName) => {
    const trimmedName = fullName.trim();
    if (!trimmedName) return createErrorValidationObj('Please enter a full name');
    if (trimmedName.split(' ').length === 1) return createErrorValidationObj('Please enter a full name');
    return null;
  },

  contact_phone: notEmptyValidatorCreator('Please enter a phone number'),

  contact_mobile: () => null,

  contact_email: (email) => {
    const trimmedEmail = email.trim();
    if (!trimmedEmail) return createErrorValidationObj('Please enter an email address ');
    if (!emailValidator(trimmedEmail)) return createErrorValidationObj('Please enter a valid email address');
    return null;
  },

  eplane_email: (eplaneEmail) => {
    if (eplaneEmail && !pickupSiteEplaneEmailValidator(eplaneEmail)) {
      return createErrorValidationObj('Please generate a valid eplane email address');
    }
    return null;
  },
};
