import {
  COMPANY_LIST_FETCH,
  COMPANY_LIST_IS_FETCHED,
  COMPANY_LIST_ERROR_SET,
  COMPANY_LIST_ERROR_REMOVE,
} from '../constants';

const initialState = {
  companies: [],
  isFetching: false,
  errorCode: null,
};

const companyListReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case COMPANY_LIST_FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case COMPANY_LIST_IS_FETCHED:
      return {
        ...state,
        companies: payload,
        isFetching: false,
      };

    case COMPANY_LIST_ERROR_SET:
      return {
        ...state,
        isFetching: false,
        errorCode: payload,
      };

    case COMPANY_LIST_ERROR_REMOVE:
      return {
        ...state,
        errorCode: null,
      };
    default: return state;
  }
};

export default companyListReducer;
