import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ButtonContainer } from '../StyleComponents';
import UploadImage from '../../../assets/upload-icon.png';
import { color } from '../../../constants';

const LinkButtonContainer = styled(ButtonContainer)`
  margin-top: -10px;
  margin-bottom: 10px;
  button {
    font-size: 16px;
  }
`;
const Container = styled.section`
  ${(props) => (props.filled ? `border: 3px solid ${color.lightBirch}` : `border: 3px dashed ${color.lightGrey}`)};
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 220px;
  padding: 10px 10px 10px 10px;
  div {
    text-align: center;
    color: ${color.grey};
    font-size: 18px;
    line-height: 30px;
  }
`;
const UploadImageElement = styled.div`
  width: 41px;
  height: 37px;
  background: url(${UploadImage}) no-repeat center;
`;
const FileNameContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
`;
const LineOnSidesText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;

  &:before, &:after {
    content: '';
    background-color: gray;
    border-top: 1px solid;
    margin: 0 10px 0 0;
    flex: 1 0 20px;
  }
  &:after {
    margin: 0 0 0 10px;
  }
`;
// eslint-disable-next-line react/prop-types
export const dropezoneContent = (title, extensions, disabled) => ({ onDropAccepted, getRootProps, getInputProps }) => (
  <Container {...getRootProps()}>
    <input {...getInputProps()} />
    <UploadImageElement />
    <div>{title}</div>

    <LineOnSidesText>or</LineOnSidesText>

    <ButtonContainer btnWidth="120px">
      <Button variant="outline-info" onClick={onDropAccepted} disabled={disabled}>Browse</Button>
    </ButtonContainer>
  </Container>
);

export const filledDropezoneContent = (file, onRemove) => () => (
  <Container filled>
    <FontAwesomeIcon icon="check-circle" size="3x" color={color.birch} />
    <FileNameContainer title={file?.name}>{file?.name}</FileNameContainer>

    <ButtonContainer btnWidth="120px">
      <Button variant="outline-info" onClick={onRemove}>Remove</Button>
    </ButtonContainer>
  </Container>
);

export const filledDropezoneContentWithUpload = (file, onRemove, onUpload) => () => (
  <Container>
    <FontAwesomeIcon icon="check-circle" size="3x" />
    <FileNameContainer>{file?.name}</FileNameContainer>

    <LinkButtonContainer btnWidth="120px">
      <Button variant="link" onClick={onRemove}>Remove</Button>
    </LinkButtonContainer>
    <ButtonContainer btnWidth="120px">
      <Button variant="info" onClick={onUpload}>Upload</Button>
    </ButtonContainer>
  </Container>
);
