import {
  PN_LIST_GET,
  PN_LIST_GET_SUCCESS,
  PN_LIST_GET_FAIL,

  PN_LIST_UPLOADING_START,
  PN_LIST_UPLOADING_FINISH,
  PN_LIST_UPLOADING_PROGRESS_SET,
  PN_LIST_UPLOADING_PROGRESS_RESET,
  PN_LIST_UPLOADING_TERMINATE,
  PN_LIST_ERROR_REMOVE,
  PN_LIST_UPLOADING_ERROR_OCCURRED,

  AXIOS_CANCELLED_MESSAGE,
} from '../../constants';

const initialState = {
  pnData: null,
  uploadingProgress: null,
  source: null,
  isFetching: false,
  isUploading: false,
  fetchingErrorCode: null,
  uploadingErrorCode: null,
};

export default function pnListReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case PN_LIST_GET:
      return { ...state, isFetching: true };

    case PN_LIST_GET_SUCCESS:
      return { ...state, isFetching: false, pnData: payload && payload.import };
    case PN_LIST_GET_FAIL:
      return {
        ...state,
        fetchingErrorCode: payload,
        isFetching: false,
      };

    case PN_LIST_UPLOADING_START:
      return {
        ...state,
        isUploading: true,
        uploadingProgress: null,
        source: payload,
      };

    case PN_LIST_UPLOADING_FINISH:
      return {
        ...state,
        isUploading: false,
        source: false,
      };

    case PN_LIST_UPLOADING_PROGRESS_SET: return { ...state, uploadingProgress: payload };
    case PN_LIST_UPLOADING_PROGRESS_RESET: return { ...state, uploadingProgress: null };
    case PN_LIST_UPLOADING_TERMINATE:
      if (state.source) {
        state.source.cancel(AXIOS_CANCELLED_MESSAGE);
      }
      return {
        ...state,
        uploadingProgress: null,
        isUploading: false,
        source: null,
      };
    case PN_LIST_UPLOADING_ERROR_OCCURRED:
      return {
        ...state,
        uploadingErrorCode: payload,
        isUploading: false,
        uploadingProgress: null,
      };
    case PN_LIST_ERROR_REMOVE: return { ...state, uploadingErrorCode: null };

    default: return state;
  }
}
