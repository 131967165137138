import {
  ORDER_LIMITS_GET,
  ORDER_LIMITS_GET_SUCCESS,

  ORDER_LIMITS_SAVE,
  ORDER_LIMITS_SAVE_SUCCESS,

  ORDER_LIMITS_FAIL,
  ORDER_LIMITS_RESET,
} from '../../constants';

const initialState = {
  orderLimits: null,
  isFetching: false,
  isProcessing: false,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case ORDER_LIMITS_GET:
      return {
        ...state,
        isFetching: true,
      };
    case ORDER_LIMITS_GET_SUCCESS:
      return {
        ...state,
        orderLimits: payload,
        isFetching: false,
      };

    case ORDER_LIMITS_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case ORDER_LIMITS_SAVE_SUCCESS:
      return {
        ...state,
        orderLimits: payload,
        isProcessing: false,
      };

    case ORDER_LIMITS_FAIL:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
      };
    case ORDER_LIMITS_RESET:
      return {
        ...initialState,
      };
    default: return state;
  }
};
