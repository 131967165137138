import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import emailValidator from 'validator/lib/isEmail';

import UniversalControl from '../../../FormComponents/UniversalControl';
import {
  ButtonContainer,
  ModalTitle,
  FlexContainer as FlexContainerNormal,
  ModalErrorText,
} from '../../../StyledComponents';

import createErrorValidationObj from '../../../../utils/createErrorValidationObj';
import { ERRORS } from '../../../../constants';

const FlexContainer = styled(FlexContainerNormal)`
  align-items: center;
`;
const initialCustomerDataObj = {
  email: '',
  firstname: '',
  lastname: '',
  company_role: '',
};

const initialValidationStateObj = {
  email: null,
  firstname: null,
  lastname: null,
  company_role: null,
};

const mapCustomerData = (customerData) => ({
  email: customerData.email || '',
  firstname: customerData.firstname || '',
  lastname: customerData.lastname || '',
  company_role: customerData.company_role || '',
});

export default class CustomerModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      validationState: initialValidationStateObj,
      customerData: props.customerData ? mapCustomerData(props.customerData) : initialCustomerDataObj,
    };
  }

  onSave = () => {
    const formIsValid = this.checkForValidity();

    if (formIsValid) {
      this.props.onSaveHandler && this.props.onSaveHandler(this.state.customerData); // eslint-disable-line
    }
  };

  onChange = (e) => {
    const { value, name } = e.target;

    this.setState((prevState) => {
      const updatedCustomerData = { ...prevState.customerData };
      updatedCustomerData[name] = value;
      return {
        customerData: updatedCustomerData,
      };
    });
  };

  onBlur = (e) => {
    const { value, name } = e.target;
    let validation = null;

    validation = validationMap[name](value);

    this.setState((prevState) => {
      const newValidationState = { ...prevState.validationState };

      newValidationState[name] = validation;
      return { validationState: newValidationState };
    });
  };

  /** function checks validation on all inputs and
   *  fills the state.validationState object with validation values
   * @returns {Boolean} if true validation is passed, otherwise false
   */
  checkForValidity = () => {
    let fieldsAreValid = true;
    const { customerData, validationState } = this.state;
    const newValidationStateObj = {};
    Object.keys(customerData).forEach((nameOfField) => {
      let validation = validationState[nameOfField];

      validation = validationMap[nameOfField](customerData[nameOfField]);

      newValidationStateObj[nameOfField] = validation;
      if (validation) fieldsAreValid = false;
    });
    this.setState({
      validationState: newValidationStateObj,
    });
    return fieldsAreValid;
  };

  render() {
    const {
      customerData: customerDataProps,
      onSaveHandler,
      errorCode,
      isSaving,
      isReactivate,
      ...other
    } = this.props;
    const { customerData, validationState } = this.state;

    let headerText;
    let saveButtonText;
    let emailDisabled = false;
    if (customerDataProps) {
      headerText = isReactivate ? 'Reactivate User' : 'Edit User Details';
      saveButtonText = isReactivate ? 'Send Invitation' : 'Save';
      emailDisabled = true;
    } else {
      headerText = 'Add a Company User';
      saveButtonText = 'Save';
    }
    saveButtonText = isSaving ? 'Saving...' : saveButtonText;
    return (
      <Modal {...other}>
        <Modal.Header closeButton>
          <ModalTitle>{headerText}</ModalTitle>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <UniversalControl
              name="email"
              type="text"
              labelText="Email"
              value={customerData.email}
              onChange={this.onChange}
              onBlur={this.onBlur}
              disabled={emailDisabled}
              {...validationState.email}
            />
            <UniversalControl
              name="firstname"
              type="text"
              labelText="First name"
              value={customerData.firstname}
              onChange={this.onChange}
              onBlur={this.onBlur}
              {...validationState.firstname}
            />
            <UniversalControl
              name="lastname"
              type="text"
              labelText="Last name"
              value={customerData.lastname}
              onChange={this.onChange}
              onBlur={this.onBlur}
              {...validationState.lastname}
            />
            <UniversalControl
              name="company_role"
              type="text"
              labelText="Company role"
              value={customerData.company_role}
              onChange={this.onChange}
              onBlur={this.onBlur}
              {...validationState.company_role}
            />
          </Form>
          <ModalErrorText show={!!errorCode}>
            <FontAwesomeIcon icon="exclamation-triangle" />
            {' '}
            {getErrorText(errorCode)}
          </ModalErrorText>
        </Modal.Body>

        <Modal.Footer>
          <FlexContainer>
            <ButtonContainer btnWidth="110px" space="10px">
              <Button disabled={isSaving} variant="primary" onClick={this.onSave}>{saveButtonText}</Button>
              <Button variant="outline-secondary" onClick={other.onHide}>Cancel</Button>
            </ButtonContainer>
          </FlexContainer>
        </Modal.Footer>
      </Modal>
    );
  }
}

CustomerModal.propTypes = {
  show: PropTypes.bool,
  customerData: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    company_role: PropTypes.string,
  }),
  isSaving: PropTypes.bool,
  isReactivate: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onSaveHandler: PropTypes.func,
  errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const notEmptyValidatorCreator = (errorText) => (value) => {
  if (!value.trim()) return createErrorValidationObj(errorText);
  return null;
};

const validationMap = {
  email: (email) => {
    const trimmedEmail = email.trim();
    if (!trimmedEmail) return createErrorValidationObj('Please enter an email address ');
    if (!emailValidator(trimmedEmail)) return createErrorValidationObj('Please enter a valid email address');
    return null;
  },
  firstname: notEmptyValidatorCreator('Please enter first name'),
  lastname: notEmptyValidatorCreator('Please enter last name'),
  company_role: notEmptyValidatorCreator('Please enter user\'s role'),
};

const getErrorText = (errorCode) => {
  if (errorCode === ERRORS.ERR_ENTITY_ALREADY_EXIST) {
    return 'This email address is already registered with ePlane';
  }
  return 'Something went wrong';
};
