import {
  COMPANIES_GET_LIST,
  COMPANIES_GET_LIST_SUCCESS,
  COMPANIES_SEARCH_START,
  COMPANIES_SEARCH_FINISHED,

  COMPANIES_GET_LIST_FAIL,
  COMPANIES_ERROR_REMOVE,
} from '../../constants';

const initialState = {
  currItems: [],
  isFetching: false,
  isSearching: false,
  isSearchingMode: false,
  isError: null,
};

export default function companiesReducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case COMPANIES_GET_LIST:
      return {
        ...state,
        totalRecords: null,
        lastPage: null,
        isFetching: true,
        isSearchingMode: false,
      };

    case COMPANIES_GET_LIST_SUCCESS:
      return {
        ...state,
        currItems: payload.companies.items,
        isFetching: false,
      };

    case COMPANIES_SEARCH_START:
      return { ...state, isSearching: true };

    case COMPANIES_SEARCH_FINISHED:
      return {
        ...state,
        isFetching: false,
        isSearching: false,
        isSearchingMode: true,
        totalRecords: payload.companies.totalRecords,
        page: payload.companies.page,
        lastPage: payload.companies.lastPage,
        currItems: payload.companies.items,
      };

    case COMPANIES_GET_LIST_FAIL:
      return {
        ...state,
        isFetching: false,
        isSearching: false,
        isError: true,
      };

    case COMPANIES_ERROR_REMOVE:
      return { ...state, isError: false };
    default: return state;
  }
}
