import axios from 'axios';

export default {
  loginViaGoogle: async (token) => (
    axios.post('/rest/default/V1/eplane/backoffice/signIn', {
      id_token: token,
    })
  ),
  checkLoggedIn: async () => (
    axios('/rest/default/V1/eplane/users/checkLoggedIn')
  ),
  logOut: async () => (
    axios.get('/rest/default/V1/eplane/backoffice/logOut')
  ),
  fetchServerConstants: async () => (
    axios.all([
      axios('/rest/default/V1/eplane/backoffice/consts'),
      axios('/rest/default/V1/eplane/directory/countries'),
    ]).then(axios.spread((constResponse, countriesResponse) => ({
      constants: constResponse.data,
      countries: countriesResponse.data,
    })))
  ),

  getNocTasksList: async (params) => (
    axios.get('/rest/default/V2/eplane/backoffice/tasks', { params })
  ),
  deleteRfqAttachment: async (orderId, attachmentId) => (
    axios.delete(`/rest/default/V2/eplane/backoffice/rfq/${orderId}/attachment/${attachmentId}`)
  ),

  // Ignore list management for NOC tasks
  getIgnoreTaskList: async () => (
    axios.get('/rest/V2/eplane/backoffice/bot_autoactions')
  ),
  addIgnoreRule: async (params) => (
    axios.post('/rest/V2/eplane/backoffice/bot_autoactions', params)
  ),
  updateIgnoreRule: async (params, autoActionId) => (
    axios.post(`/rest/V2/eplane/backoffice/bot_autoactions/${autoActionId}`, params)
  ),
  deleteIgnoreRule: async (autoActionId) => (
    axios.delete(`/rest/V2/eplane/backoffice/bot_autoactions/${autoActionId}`)
  ),
};
