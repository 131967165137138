import React from 'react';
import styled from 'styled-components';

import Img from '../../Img';
import PlaneImg from '../../../assets/plane.svg';
import { color } from '../../../constants';

const Plane = styled(Img)`
  height: 150px;
  margin-right: 50px;
`;
export const Container = styled.div`
  margin-top: 180px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1400px) {
    flex-direction: column;
    img {
      width: 350px;
      margin: 0 auto;
      margin-bottom: 25px;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    img {
      width: 200px;
      height: auto;
      margin: 0 auto;
      margin-bottom: 25px;
    }
  }
`;
export const Title = styled.h1`
  font-size: 18px;
  font-weight: bold;
  color: ${color.blue};
`;
export const Text = styled.p`
  font-size: 16px;
  margin-bottom: 0;
  color: ${color.grey};
`;

export default () => (
  <Container>
    <Plane src={PlaneImg} alt="Welcome To NOC" />
    <div>
      <Title>Welcome to Network Operation Center!</Title>
      <Text>
        To view a chat and add a built-in quote open the relevant task from Tasks section.
      </Text>
    </div>
  </Container>
);
