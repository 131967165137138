import React from 'react';
import EmailStatusMark from '../components/EmailStatusMark';

function emailStatusFormatter(cell, serverconsts) {
  return (
    <EmailStatusMark
      statusId={cell}
      customerEmailStatusConstants={serverconsts?.customerEmailStatus}
      withDescription
    />
  );
}
export default emailStatusFormatter;
