import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Table from '../../Table/Table';
import header from '../../Table/HeaderWithSort';
import TableStyles from '../../Table/TableStyles';
import CellWithTitle from '../../Table/Cell';

import { tier as tierConstants } from '../../../constants/index';
import dateFormat from '../../../utils/dateFormat';

const TableContainer = styled(TableStyles)`
  margin-bottom: 10px;
  tbody tr td {
    cursor: pointer;
  }
`;

const tierNumber = (value, tier) => {
  switch (value) {
    case tier.TIER_1:
      return '1';
    case tier.TIER_2:
      return '2';
    case tier.TIER_3:
      return '3';
    case tier.TIER_4:
      return '4';
    case tier.TIER_SPEC2000_PO:
      return 'S2K';
    default:
      return '';
  }
};

const tierFormat = (value) => <div><span title={value}>{tierNumber(value, tierConstants)}</span></div>;

export default function OrderTable({ data, title, onRowClick }) {
  const columns = useMemo(
    () => [
      {
        Header: () => header('Order id'),
        id: 'order_id',
        accessor: 'order_id',
        Cell: CellWithTitle,
        style: {
          width: '110px',
        },
      }, {
        Header: () => header('Part Number'),
        accessor: 'part_number',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Part Description'),
        accessor: 'part_description',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Status'),
        accessor: 'seller_status',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Company (buyer)'),
        accessor: 'company_buyer',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Full Name (buyer)'),
        accessor: 'fullname_buyer',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Company (seller)'),
        accessor: 'company_seller',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Full Name (seller)'),
        accessor: 'fullname_seller',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Tier'),
        accessor: 'tier',
        Cell: ({ value }) => tierFormat(value),
        style: {
          width: '50px',
        },
      }, {
        Header: () => header('Placed on'),
        accessor: 'created_at',
        Cell: ({ value }) => dateFormat(value),
        style: {
          width: '180',
        },
      },
    ], [],
  );

  return (
    <TableContainer>
      {title}
      <Table columns={columns} data={data} onRowClick={onRowClick} />
    </TableContainer>
  );
}

const orderPT = PropTypes.shape({
  entity_id: PropTypes.number,
  part_number: PropTypes.string,
  part_description: PropTypes.string,
  status: PropTypes.string,
  company_buyer: PropTypes.string,
  fullname_buyer: PropTypes.string,
  company_seller: PropTypes.string,
  fullname_seller: PropTypes.string,
  created_at: PropTypes.string,
});

OrderTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(orderPT),
  onRowClick: PropTypes.func,
};
