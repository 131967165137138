import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse, Button, FormGroup, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';

import DatesPicker from '../../../DatesPicker';
import BaseFormGroup from '../../../FormComponents/BaseFormGroup';
import { color, AXIOS_CANCELLED_MESSAGE } from '../../../../constants';
import AutosuggestControl from '../../../FormComponents/AutosuggestControl';

const Title = styled.div`
  font-size: 18px;
`;
const Container = styled.div`
  max-width: 500px;
  margin: 20px auto;
`;
const MainContent = styled.div`
  display: block;
  border-radius: 5px;
  padding: 10px;
  background-color: ${color.altoGrey};
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LinkButton = styled(Button)`
  &&& {
    text-decoration: none;
  }
  &&&[disabled] {
    color: #777;
    text-decoration: none;
    cursor: auto;
  }
`;
const ModeButton = styled(LinkButton)`
  &&& {
    font-size: 16px;
    display: block;
    width: 100%;
    margin-top: 3px;
    padding: 0;
  }
`;
const FormGroupCentered = styled(FormGroup)`
  display: flex;
  justify-content: center;
`;

function renderSuggestion(suggestionText) {
  return <span>{suggestionText}</span>;
}

const getSuggestionsValue = (suggestionText) => suggestionText;

const SearchOrderBar = ({
  onShowAll,
  isShowAll,
  onSearch,
  companyList,
  statusList,
  query,
}) => {
  const fetchPnAutosuggestState = useRef(null);
  const fetchAutosuggestState = useRef(null);

  const [searchParams, setSearchParams] = useState({
    orderID: query.orderID || '',
    purchaseOrder: query.purchaseOrder || '',
    company: query.company || '',
    email: query.email || '',
    partNumber: query.partNumber || '',
    status: query.status || '',
    from: query.from ? moment.utc(query.from) : null,
    to: query.to ? moment.utc(query.to) : null,
  });
  const [suggestionsOrderId, setSuggestionsOrderId] = useState([]);
  const [suggestionsPN, setPNSuggestions] = useState([]);
  const [isExpanded, setExpanded] = useState(true);

  useEffect(() => {
    if (query.orderID !== searchParams.orderID
      || query.purchaseOrder !== searchParams.purchaseOrder
      || query.company !== searchParams.company
      || query.email !== searchParams.email
      || query.partNumber !== searchParams.partNumber
      || query.status !== searchParams.status
      || query.from !== searchParams.from
      || query.to !== searchParams.to) {
      setSearchParams({
        orderID: query.orderID || '',
        purchaseOrder: query.purchaseOrder || '',
        company: query.company || '',
        email: query.email || '',
        partNumber: query.partNumber || '',
        status: query.status || '',
        from: query.from ? moment.utc(query.from) : null,
        to: query.to ? moment.utc(query.to) : null,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const onCalendarApply = ({ startDate, endDate }) => setSearchParams({ ...searchParams, from: startDate, to: endDate });

  const onChange = (e) => {
    const { name, value } = e.target;
    setSearchParams({ ...searchParams, [name]: value });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    onSearch({
      fields: { ...searchParams },
    });
  };

  const onGo = () => {
    onSearch({
      fields: { ...searchParams },
    });
  };

  const onChangeMode = () => setExpanded(!isExpanded);

  const onOrderIdSuggestionRequested = ({ value }) => {
    if (value && value.length > 2) {
      // enable autocomplete for order id suggestions
      if (fetchAutosuggestState.current) fetchAutosuggestState.current.cancel(AXIOS_CANCELLED_MESSAGE);
      fetchAutosuggestState.current = axios.CancelToken.source();
      axios(`/rest/V1/eplane/backoffice/orders/search/suggest?order_id=${value}`, { cancelToken: fetchAutosuggestState.current.token })
        .then((responseArr) => {
          const { data: suggestions } = responseArr;
          if (suggestions) {
            setSuggestionsOrderId(suggestions);
          }
          fetchAutosuggestState.current = null;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          if (error.message === AXIOS_CANCELLED_MESSAGE) console.warn(AXIOS_CANCELLED_MESSAGE);
          else console.warn('Can\'t get order id suggestions:', error); // eslint-disable-line
        });
    }
  };

  const onPnSuggestionRequested = async ({ value }) => {
    if (value.length < 3) return;

    if (fetchPnAutosuggestState.current) fetchPnAutosuggestState.current.cancel(AXIOS_CANCELLED_MESSAGE);
    fetchPnAutosuggestState.current = axios.CancelToken.source();

    try {
      const response = await axios(`/suggest/catalog?q=${value}&max_results=5`, { cancelToken: fetchPnAutosuggestState.current.token });
      setPNSuggestions(response.data.slice(0, 5));
      fetchPnAutosuggestState.current = null;
    } catch (e) {
      if (e.message === AXIOS_CANCELLED_MESSAGE) console.warn(AXIOS_CANCELLED_MESSAGE); // eslint-disable-line
      else console.warn('Can\'t get pn suggestions:', error); // eslint-disable-line
    }
  };

  const onOrderIdSuggestionClear = () => setSuggestionsOrderId([]);

  let restInputField = null;
  const modeButtonOptions = isExpanded
    ? {
      title: 'show less',
      glyph: 'chevron-up',
    } : {
      title: 'show more',
      glyph: 'chevron-down',
    };

  restInputField = (
    <Collapse in={isExpanded}>
      <div>
        <BaseFormGroup
          placeholder="Purchase Order #"
          name="purchaseOrder"
          value={searchParams.purchaseOrder}
          onChange={onChange}
          disabled={!!searchParams.orderID}
        />
        <BaseFormGroup
          placeholder="Company"
          name="company"
          value={searchParams.company}
          options={companyList}
          onChange={onChange}
          disabled={!!searchParams.orderID}
        />
        <BaseFormGroup
          placeholder="Email"
          name="email"
          value={searchParams.email}
          onChange={onChange}
          disabled={!!searchParams.orderID}
        />
        <AutosuggestControl
          suggestions={suggestionsPN}
          onSuggestionsFetchRequested={onPnSuggestionRequested}
          onSuggestionsClearRequested={() => setPNSuggestions([])}
          getSuggestionValue={(suggestion) => suggestion.title}
          renderSuggestion={(suggestion) => <span>{suggestion.title}</span>}
          placeholder="PN"
          name="partNumber"
          value={searchParams.partNumber}
          onChange={onChange}
          disabled={!!searchParams.orderID}
        />
        <BaseFormGroup
          placeholder="Status"
          name="status"
          value={searchParams.status}
          options={statusList}
          onChange={onChange}
          disabled={!!searchParams.orderID}
        />
        <FormGroupCentered>
          <DatesPicker
            startDate={searchParams.from}
            endDate={searchParams.to}
            onChange={onCalendarApply}
            onGo={onGo}
            disabled={!!searchParams.orderID}
            minimumNights={0}
            numberOfMonths={2}
            readOnly
            isShownDatePresets
          />
        </FormGroupCentered>
      </div>
    </Collapse>
  );

  return (
    <Container>
      <Title>Search for an order:</Title>
      <MainContent>
        <Form onSubmit={onFormSubmit}>
          <AutosuggestControl
            suggestions={suggestionsOrderId.slice(0, 10)}
            onSuggestionsFetchRequested={onOrderIdSuggestionRequested}
            onSuggestionsClearRequested={onOrderIdSuggestionClear}
            getSuggestionValue={getSuggestionsValue}
            renderSuggestion={renderSuggestion}
            placeholder="Order id"
            name="orderID"
            value={searchParams.orderID}
            onChange={onChange}
          />

          {restInputField}

          <ButtonsContainer>
            <LinkButton variant="link" onClick={onShowAll} disabled={!!isShowAll}>
              <FontAwesomeIcon icon="th" />
              &nbsp;Show All
            </LinkButton>
            <Button variant="primary" type="submit">Search Orders</Button>
          </ButtonsContainer>
        </Form>
        <ModeButton variant="link" title={modeButtonOptions.title} onClick={onChangeMode}>
          <FontAwesomeIcon icon={modeButtonOptions.glyph} />
        </ModeButton>
      </MainContent>
    </Container>
  );
};

SearchOrderBar.propTypes = {
  companyList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  statusList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  onSearch: PropTypes.func.isRequired,
  onShowAll: PropTypes.func.isRequired,
  isShowAll: PropTypes.string,
  query: PropTypes.shape({
    orderID: PropTypes.string,
    purchaseOrder: PropTypes.string,
    company: PropTypes.string,
    email: PropTypes.string,
    partNumber: PropTypes.string,
    status: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
  }),
};

export default SearchOrderBar;
