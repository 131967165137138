import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text, Button } from '../styledComponents';

const HeaderText = styled.div`
  font-size: 36px;
  text-align: center;
  font-weight: bold;
`;

const TooBigFileModal = ({
  text, headerText, onHide, ...other
}) => (
  <Modal centered onHide={onHide} {...other}>
    <Modal.Header closeButton>
      <HeaderText>{headerText}</HeaderText>
    </Modal.Header>
    <Modal.Body>
      <Text>
        {text}
      </Text>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={onHide}>OK</Button>
    </Modal.Footer>
  </Modal>
);

TooBigFileModal.propTypes = {
  onHide: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headerText: PropTypes.string,
};

TooBigFileModal.defaultProps = {
  headerText: 'File is too big',
};

export default TooBigFileModal;
