import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button as ButtonBS } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TableStyles from '../../Table/TableStyles';
import Table from '../../Table/Table';
import header from '../../Table/HeaderWithSort';

import { ButtonContainer } from '../../StyledComponents';
import { PUBLIC_URL } from '../../../constants';
import * as serverConstants from '../../../constants/index';
import dateFormat from '../../../utils/dateFormat';
import statusFormat from './statusFormat';
import { getCompanyTypeById } from '../../../utils/constantsHandlers';

const Button = styled(ButtonBS)`
  && {
    padding: 3px 6px 3px 6px;
  }
  &&:hover {
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
  }
`;
const TextOverflow = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const TableContainer = styled(TableStyles)`
  margin: 5px 0 20px 0;
`;

export default function CompaniesTable({
  data, title, goTo, sName, sOrder, onSort,
}) {
  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => header('User id', true, (column.id === sName && sOrder), column.id, onSort),
        id: 'user_id',
        accessor: 'user_id',
        Cell: ({ value, row }) => tdClickableFormatter(value, row.original, goTo),
        style: {
          width: '100px',
        },
      }, {
        Header: ({ column }) => header('Company name', true, (column.id === sName && sOrder), column.id, onSort),
        accessor: 'company_name',
      }, {
        Header: ({ column }) => header('Company type', true, (column.id === sName && sOrder), column.id, onSort),
        accessor: 'company_type',
        Cell: ({ value }) => companyTypeFormat(value),
        style: {
          width: '120px',
        },
      }, {
        Header: ({ column }) => header('Company contact', true, (column.id === sName && sOrder), column.id, onSort),
        accessor: 'contact_name',
      }, {
        Header: ({ column }) => header('Contact email', true, (column.id === sName && sOrder), column.id, onSort),
        accessor: 'contact_email',
      }, {
        Header: ({ column }) => header('Approval date', true, (column.id === sName && sOrder), column.id, onSort),
        accessor: 'updated_at',
        Cell: ({ value }) => dateFormat(value),
        style: {
          width: '180px',
        },
      }, {
        Header: ({ column }) => header('Approved by', false, (column.id === sName && sOrder), column.id, onSort),
        accessor: 'admin_customer_email',
      }, {
        Header: ({ column }) => header('Status', true, (column.id === sName && sOrder), column.id, onSort),
        accessor: 'vetted_status',
        Cell: ({ value, row }) => vettedFormat(value, row.original),
        style: {
          width: '200px',
          textAlign: 'center',
        },
      }, {
        Header: ({ column }) => header('Autopilot', false, (column.id === sName && sOrder), column.id, onSort),
        accessor: 'autopilot_enabled',
        Cell: statusFormat,
        style: {
          width: '90px',
          textAlign: 'center',
        },
      },
      {
        Header: ({ column }) => header('Pro plan', true, (column.id === sName && sOrder), column.id, onSort),
        accessor: 'is_pro',
        Cell: statusFormat,
        style: {
          width: '90px',
          textAlign: 'center',
        },
      },
    ], [goTo, onSort, sName, sOrder],
  );

  return (
    <TableContainer>
      {title}
      <Table columns={columns} data={data} />
    </TableContainer>
  );
}

CompaniesTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]),
  title: PropTypes.string,
  goTo: PropTypes.func.isRequired,
  sName: PropTypes.string,
  sOrder: PropTypes.string,
  onSort: PropTypes.func.isRequired,
};

const companyTypeFormat = (cell) => {
  if (!cell) return '';
  return <TextOverflow>{cell.map((typeId) => getCompanyTypeById(typeId, serverConstants)).join(', ')}</TextOverflow>;
};

const vettedFormat = (cell, row) => {
  if (!cell) return '';
  const vettedStatus = (cell === serverConstants.vettedStatus.VETTED)
    ? 'Vetted'
    : 'Not vetted';
  const text = row.partner_id
    ? `${vettedStatus} (${row.partner_name})`
    : vettedStatus;
  return (
    <div style={{ textAlign: 'center' }}>
      <TextOverflow>{text}</TextOverflow>
    </div>
  );
};

const tdClickableFormatter = (cell, row, goTo) => (
  <ButtonContainer btnWidth="80px">
    {!row.is_pro
      ? (
        <Button
          variant="success"
          onClick={() => goTo(`${PUBLIC_URL}/companies/${row.user_id}`)}
        >
          <FontAwesomeIcon icon={['fab', 'black-tie']} />
          &nbsp;
          {cell}
        </Button>
      ) : (
        <Button
          variant="outline-success"
          title="Pro"
          onClick={() => goTo(`${PUBLIC_URL}/companies/${row.user_id}`)}
        >
          <FontAwesomeIcon icon="crown" />
          &nbsp;
          {cell}
        </Button>
      )}
  </ButtonContainer>
);
