import {
  RFQ_EMAIL_SETTINGS_GET,
  RFQ_EMAIL_SETTINGS_SUCCESS,
  RFQ_EMAIL_SETTINGS_SAVE,
  RFQ_EMAIL_SETTINGS_SAVE_SUCCESS,

  EMAILS_FAIL,
} from '../../constants';

const initialState = {
  settings: null,
  isFetching: false,
  isProcessing: false,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case RFQ_EMAIL_SETTINGS_GET:
      return {
        ...state,
        isFetching: true,
      };
    case RFQ_EMAIL_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: payload,
        isFetching: false,
      };
    case RFQ_EMAIL_SETTINGS_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case RFQ_EMAIL_SETTINGS_SAVE_SUCCESS:
      return {
        ...state,
        settings: payload,
        isProcessing: false,
      };
    case EMAILS_FAIL:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
      };

    default: return state;
  }
};
