import createErrorValidationObj from '../../../utils/createErrorValidationObj';

export const autopilotRfqLimitValidator = (autopilot, value) => {
  if (!autopilot || (autopilot && (value === undefined))) return null;
  if (value == null || value === '') return createErrorValidationObj('Value is required when autopilot enabled');
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal)) return createErrorValidationObj('Please enter a number');
  if (numVal < 0) return createErrorValidationObj('Please enter a positive number');
  return null;
};

export const demandsRowCountLimitValidator = (value) => {
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal)) return createErrorValidationObj('Please enter a number');
  if (numVal < 0) return createErrorValidationObj('Please enter a positive number');
  return null;
};

export const erpValidator = (value) => {
  const newValue = value && value.trim();
  if (!newValue) return null;
  if (newValue.length > 255) return createErrorValidationObj('Name is too long');
  return null;
};

export const naturalNumberValidator = (value) => {
  if (!value) return createErrorValidationObj('Please enter quoting time');
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal)) return createErrorValidationObj('Please enter a number of minutes');
  if (numVal < 0) return createErrorValidationObj('Please enter a positive number');
  return null;
};
