import moment from 'moment';

import {
  TOAST_ADD,
  TOAST_DELETE,
  TOASTS_DEFAULT_DELAY,

  notificationTypes,
} from '../constants';

export const addToast = (title, message, delay = TOASTS_DEFAULT_DELAY, type = notificationTypes.GENERAL) => ({
  type: TOAST_ADD,
  payload: {
    id: moment.now(),
    delay,
    title,
    message,
    date: moment().format('MMM D, YYYY, HH:mm'),
    type,
  },
});

export const deleteToast = (id, type) => ({
  type: TOAST_DELETE,
  payload: { id, type },
});
