import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button, Form, Row, Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { WeightListTitle } from '../../CompaniesManagement/StyleComponents';
import UniversalFormControl from '../../FormComponents/UniversalControl';
import CheckboxStyled from '../../FormComponents/CheckboxStyled';

const CheckBox = styled(CheckboxStyled)`
  margin-bottom: 0;
`;
const AC_PARTS_ID = '-1';

const Categories = ({
  categories,
  disabled,
  showControls,
  validation,
  onAddCategory,
  onCheckChange,
  onCategoryChange,
  onBlur,
}) => (
  <Form>
    {categories.length !== 0 && (
      <Row>
        <Col sm={2}><WeightListTitle>Identifier</WeightListTitle></Col>
        <Col sm={4}><WeightListTitle>Category Name</WeightListTitle></Col>
      </Row>
    )}
    {categories && categories.map((v, i) => (
      <Row key={i.toString()}>
        <Col sm={2}>
          <UniversalFormControl
            value={v.category}
            name="category"
            disabled={disabled || v.category_id === AC_PARTS_ID}
            labelColumnWidth={0}
            controlColumnWidth={12}
            defaultInputLength={2}
            onChange={(e) => onCategoryChange(i, e.target.name, e.target.value)}
            onBlur={onBlur}
            {...(validation && validation[i] && validation[i].category)}
          />
        </Col>
        <Col sm={4}>
          <UniversalFormControl
            value={v.category_name}
            name="category_name"
            disabled={disabled || v.category_id === AC_PARTS_ID}
            labelColumnWidth={0}
            controlColumnWidth={12}
            onChange={(e) => onCategoryChange(i, e.target.name, e.target.value)}
            {...(validation && validation[i] && validation[i].category_name)}
          />
        </Col>
        {showControls && (
          <Col sm={2}>
            <CheckBox
              id={`checkbox-${v.category}-${i}`}
              label={v.is_enabled ? 'Active' : 'Inactive'}
              checked={(v.is_enabled || false)}
              name="enable-reminder"
              onChange={(e) => onCheckChange(i, e.target.checked)}
              disabled={disabled || v.category_id === AC_PARTS_ID}
            />
          </Col>
        )}
      </Row>
    ))}
    {categories.length === 0 && (
      <Row>
        <Col sm={4}>
          <p>There is no custom demand categories.</p>
        </Col>
      </Row>
    )}
    {showControls && (
      <Row>
        <Col sm={6}>
          <Button onClick={() => onAddCategory(null)} disabled={disabled} variant="success" size="sm">
            <FontAwesomeIcon icon="plus" />
            &nbsp;Add
          </Button>
        </Col>
      </Row>
    )}
  </Form>
);

Categories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  showControls: PropTypes.bool,
  validation: PropTypes.arrayOf(PropTypes.object),
  onCheckChange: PropTypes.func,
  onAddCategory: PropTypes.func,
  onCategoryChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Categories;
