import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import WarningModal from '../../../WarningModal';

const BodyContainer = styled.div`
  margin-top: 38px;
  text-align: center;
`;

const HeaderModal = styled.strong`
  font-size: 26px;
  letter-spacing: -0.2px;
  display: block;
`;

const Text = styled.div`
  font-size: 26px;
  letter-spacing: -0.2px;
  line-height: 1.35;
`;

export default function ErrorDeactivateModal(props) {
  const body = (
    <BodyContainer>
      <HeaderModal>Error!</HeaderModal>
      <Text>You can not deactivate the last active user</Text>
    </BodyContainer>
  );
  const { onHide } = props;
  const footer = (
    <div>
      <Button onClick={onHide}>CLOSE</Button>
    </div>
  );
  return <WarningModal body={body} footer={footer} {...props} />;
}

ErrorDeactivateModal.propTypes = {
  onHide: PropTypes.func,
};
