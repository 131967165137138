import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import { ButtonsWrapper, Button, Text } from '../styledComponents';

const Body = styled(Modal.Body)`
  margin-left: 50px;
  margin-bottom: 20px;
`;

const SupportedMethodsModal = ({
  data,
  onHide,
  saveMethods,
  companyName,
  show,
  ...other
}) => {
  const [methods, setMethods] = useState([]);

  useEffect(() => {
    setMethods(data);
  }, [data, show]);

  const onConfirmClick = () => {
    saveMethods(methods);
  };

  const onChange = (value, idx) => {
    setMethods(methods.map((el, i) => (
      (idx === i) ? { ...el, enabled: value } : el
    )));
  };

  const disabledSave = methods && (methods.every((v, i) => data[i].enabled === v.enabled) || methods.every((v) => v.enabled === false));

  return (
    <Modal centered show={show} onHide={onHide} {...other}>
      <Body>
        <Text>Configure supported methods</Text>
        <br />
        {methods && methods.map((v, i) => (
          <Form.Group>
            <CheckboxStyled
              key={v.code}
              id={v.code}
              label={v.title}
              checked={v.enabled}
              name={v.code}
              onChange={(e) => onChange(e.target.checked, i)}
            />
          </Form.Group>
        ))}
      </Body>
      <Modal.Footer>
        <ButtonsWrapper>
          <Button
            variant="primary"
            disabled={disabledSave}
            onClick={onConfirmClick}
          >
            Save
          </Button>
          <Button onClick={onHide} variant="secondary">Cancel</Button>
        </ButtonsWrapper>
      </Modal.Footer>
    </Modal>
  );
};

SupportedMethodsModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  companyName: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  saveMethods: PropTypes.func,
};

export default SupportedMethodsModal;
