import {
  COMPANY_CUSTOMERS_GET,
  COMPANY_CUSTOMERS_GET_SUCCESS,
  COMPANY_CUSTOMERS_GET_FAIL,
  COMPANY_CUSTOMERS_ERROR_REMOVE,
} from '../../constants';

const initialState = {
  customers: {},
  isFetching: false,
  errorCode: null,
};

const companyCustomersReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case COMPANY_CUSTOMERS_GET:
      return {
        ...state,
        isFetching: true,
        errorCode: null,
      };
    case COMPANY_CUSTOMERS_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customers: payload.customers,
      };
    case COMPANY_CUSTOMERS_GET_FAIL:
      return {
        ...state,
        isFetching: false,
        errorCode: payload,
      };
    case COMPANY_CUSTOMERS_ERROR_REMOVE:
      return {
        ...state,
        isFetching: false,
        errorCode: null,
      };

    default: return state;
  }
};

export default companyCustomersReducer;
