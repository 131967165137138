import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StatusFormat = ({ value }) => (
  <div style={{ textAlign: 'center' }} title={value?.toString()}>
    {value ? <FontAwesomeIcon icon="check" /> : null }
  </div>
);

StatusFormat.propTypes = {
  value: PropTypes.bool,
};

export default StatusFormat;
