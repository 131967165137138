import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button, Modal, Form, Row, Col,
} from 'react-bootstrap';

import { ModalTitle, ButtonContainer } from '../../StyledComponents';
import { DEFAULT_TIMEZONE } from '../../../constants/aggregationEmails';
import { color } from '../../../constants';

const Text = styled.p`
  margin-left: 15px;
`;
const FormGroupContainer = styled(Row)`
  .select_hours__value-container, .select_days__value-container {
    max-height: 32px;
    text-overflow: ellipsis;
    overflow-y: auto;
    margin-right: 5px;

    scrollbar-width: thin;
    ::-webkit-scrollbar-track
    {
      border-radius: 10px;
      background-color: ${color.lightGrey};
    }

    ::-webkit-scrollbar
    {
      width: 4px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      background-color: #808080;
    }
  }
`;

function* generateHours() {
  yield { label: '12:00 AM', value: '00:00' };
  for (let i = 1; i <= 11; i += 1) {
    yield { label: `${i.toString().padStart(2, '0')}:00 AM`, value: `${i.toString().padStart(2, '0')}:00` };
  }
  yield { label: '12:00 PM', value: '12:00' };
  for (let i = 1; i <= 11; i += 1) {
    yield { label: `${i.toString().padStart(2, '0')}:00 PM`, value: `${(i + 12).toString().padStart(2, '0')}:00` };
  }
}

export default function NotificationModal({
  aggregationData,
  timeZones: timeZoneOptions,
  isTimeZonesFetching,
  title,
  onHide,
  modalName,
  onSaveHandler,
  ...other
}) {
  const [data, setData] = useState({ hours: null, timeZone: null });

  const hoursOptions = useMemo(
    () => [...generateHours()],
    [],
  );

  useEffect(() => {
    if (aggregationData && timeZoneOptions) {
      setData({
        hours: hoursOptions.find((el) => aggregationData.pwl_notification_time === el.value) ?? hoursOptions[0],
        timeZone: timeZoneOptions.find((v) => v.value === aggregationData.pwl_notification_time_zone),
      });
    }
  }, [aggregationData, hoursOptions, timeZoneOptions]);

  const onSelectChange = (selectedOption, action) => {
    setData({ ...data, [action.name]: selectedOption });
  };

  const onSaveClickHandler = () => {
    onSaveHandler({
      pwl_notifications: true,
      pwl_notification_time: data.hours.value,
      pwl_notification_time_zone: data.timeZone.value ?? 'UTC',
    });
  };

  const onCancelHandler = () => {
    onHide();
    setData({
      hours: hoursOptions.find((el) => aggregationData.pwl_notification_time === el.value) ?? hoursOptions[0],
      timeZone: timeZoneOptions.find((v) => v.value === aggregationData.pwl_notification_time_zone),
    });
  };

  return (
    <Modal centered onHide={onCancelHandler} {...other} dialogClassName="aggregation_email_modal">
      <Modal.Header>
        <ModalTitle>{title}</ModalTitle>
      </Modal.Header>

      <Modal.Body>
        <Text>
          Schedule your email digest for&nbsp;
          <strong>{`new ${modalName}`}</strong>
          &nbsp;notifications:
        </Text>
        <br />
        <Form>
          <Form.Group as={FormGroupContainer}>
            <Form.Label column sm="2">
              Email user
            </Form.Label>
            <Col sm={5}>
              <Select
                defaultValue={[hoursOptions[9]]}
                value={data.hours}
                name="hours"
                onChange={onSelectChange}
                options={hoursOptions}
                isClearable={false}
                isSearchable={false}
                classNamePrefix="select_hours"
              />
            </Col>
            <Col sm={5}>
              <Select
                value={data.timeZone}
                name="timeZone"
                onChange={onSelectChange}
                defaultValue={timeZoneOptions.find((v) => v.value === DEFAULT_TIMEZONE)}
                options={timeZoneOptions}
                isLoading={isTimeZonesFetching}
                hideSelectedOptions={false}
                isSearchable
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <ButtonContainer btnWidth="120px" space="10px">
          <Button variant="primary" onClick={onSaveClickHandler}>Save</Button>
          <Button variant="outline-secondary" onClick={onCancelHandler}>Cancel</Button>
        </ButtonContainer>
      </Modal.Footer>
    </Modal>
  );
}

NotificationModal.propTypes = {
  aggregationData: PropTypes.shape({
    pwl_notifications: PropTypes.bool,
    pwl_notification_time: PropTypes.string,
    pwl_notification_time_zone: PropTypes.string,
  }),
  timeZones: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  isTimeZonesFetching: PropTypes.bool,
  title: PropTypes.string,
  modalName: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onSaveHandler: PropTypes.func.isRequired,
};
