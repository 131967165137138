export const ALL_DAYS = -1;

export const aggregationTypes = {
  NEW_SALES_INQURIES: 0,
  NEW_QUOTES_AND_MESSAGES: 1,
};

export const frequencyOptions = [
  { value: 0, label: 'Every 10 minutes' },
  { value: 1, label: 'Once an hour' },
  { value: 3, label: 'Once every 2 hours' },
  { value: 4, label: 'Once every 6 hours' },
  { value: 2, label: 'Choose time...' },
];

export const foLast = frequencyOptions.length - 1;

export const daysOptions = [
  { value: ALL_DAYS, label: 'Daily (Select All)' },
  { value: 0, label: 'Sun' },
  { value: 1, label: 'Mon' },
  { value: 2, label: 'Tue' },
  { value: 3, label: 'Wed' },
  { value: 4, label: 'Thu' },
  { value: 5, label: 'Fri' },
  { value: 6, label: 'Sat' },
];

export const DEFAULT_TIMEZONE = 'UTC';

export const defaultNotificationSettings = {
  aggregation_frequency: 1,
  is_enabled: true,
  sending_days: [0, 1, 2, 3, 4, 5, 6],
  sending_hours: null,
  time_zone: null,
};
