import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AccountSettingsForm from './AccountSettingsForm';
import BillingAddressForm from './BillingAddressForm';
import RegistrationDetails from './RegistrationDetails';

import { getUserStore, getCustomerStore } from '../../../selectors';
import { fetchCustomer } from '../../../actions/userManagement/customerActions';
import { fetchCompanyCustomerList } from '../../../actions/companiesManagement/companyCustomerActions';
import { USER_TYPE_PRIVATE } from '../../../constants';
import InternalErrorContent from '../../InternalErrorContent';
import Loader from '../../Loader';

export default function AccountSettingsPage() {
  const { userId, customerId } = useParams();
  const dispatch = useDispatch();

  const { user, isCustomerChecked } = useSelector(getUserStore);
  const { customer, errorCode } = useSelector(getCustomerStore);

  useEffect(() => {
    dispatch(fetchCompanyCustomerList(userId));
    if (!customer && isCustomerChecked) {
      dispatch(fetchCustomer(customerId));
    }
  }, [dispatch, customer, customerId, isCustomerChecked, userId]);

  const isPrivate = user && user.user_type === USER_TYPE_PRIVATE;

  if (errorCode) return <InternalErrorContent />;
  if (!customer) return <Loader />;

  return (
    <div>
      <AccountSettingsForm />
      <RegistrationDetails />
      {isPrivate && <BillingAddressForm />}
    </div>
  );
}
