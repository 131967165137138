import { getConstNameById } from '../../utils/constantsHandlers';
import dateFormat from '../../utils/dateFormat';
import processQuoteMessage from './processQuoteMessage';
import processPaymentRequest from './processPaymentRequest';
import {
  chatMsgType, paymentStatus, paymentMethods, chatAtchRfqRole as atchRfqRole, creatorTypes, orderDealTypes, dealTypes,
} from '../../constants/index';
import * as constants from '../../constants/index';
import { WIRETRANSFER_COMPLETE } from '../../constants/operationCenter';

/**
 * Returns actual attachments that was used in chat
 * @param {Object} orderStore
 * @param {Object} constants serve constants
 * @returns {Object}
 */

export const getChatDocuments = (attachments, order, messages) => {
  if (!attachments || !order) return null;

  const { chatAtchRfqRole } = constants;
  const actualAttachments = attachments.filter((att) => !att.deleted_at);

  const returnDataSample = {
    buyer: {
      [chatAtchRfqRole.GENERAL]: [],
      [chatAtchRfqRole.PO]: [],
    },
    seller: {
      [chatAtchRfqRole.GENERAL]: [],
      [chatAtchRfqRole.INVOICE]: [],
      [chatAtchRfqRole.QUOTE]: [],
      [chatAtchRfqRole.TAG]: {},
      originalTags: createTagMapOfOrder(order),
    },
    eplane: {
      [chatAtchRfqRole.GENERAL]: [],
      [chatAtchRfqRole.PO]: [],
      [chatAtchRfqRole.INVOICE]: [],
      [chatAtchRfqRole.QUOTE]: [],
      [chatAtchRfqRole.TAG]: {},
    },
  };

  return actualAttachments.reduce((acc, doc) => {
    const associatedMsg = messages.find((v) => v.message_id === doc.message_id);

    if (doc.rfq_role === chatAtchRfqRole.TAG) {
      const tags = (doc.user_id === order.seller.user_id && doc.creator_type !== constants.creatorTypes.BOT)
        ? acc.seller[chatAtchRfqRole.TAG]
        : acc.eplane[chatAtchRfqRole.TAG];
      const tagName = (doc.tag_type === constants.tagType.CUSTOM.id)
        ? doc.tag_name
        : getConstNameById(doc.tag_type, constants, 'tagType');
      tags[tagName] = (tags[tagName] && tags[tagName].concat(doc)) || [doc];
    } else if (doc.user_id === order.buyer.user_id
        && (doc.creator_type !== constants.creatorTypes.BOT || associatedMsg.message_type === chatMsgType.PAYMENT_MSG)
    ) {
      acc.buyer[doc.rfq_role].push(doc);
    } else if (doc.user_id === order.seller.user_id && doc.creator_type !== constants.creatorTypes.BOT) {
      acc.seller[doc.rfq_role].push(doc);
    } else {
      acc.eplane[doc.rfq_role].push(doc);
    }

    return acc;
  }, returnDataSample);
};

export const getChatMessages = (order, attachments, messages) => {
  if (!order || !messages) return null;

  const isRepair = order.deal_type_list && order.deal_type_list.some((dt) => dt === orderDealTypes.REPAIR.id);

  return messages.map((msg) => (
    mapMessages(msg, order, attachments, isRepair, messages)
  )).filter((v) => v !== null);
};

export const getPN = (order) => {
  if (!order) return '';

  const item = order.items && order.items[0];

  if (order.demand_id) {
    return (order.demand && order.demand.pn) || '';
  }
  if (order.deal_type_list.includes(dealTypes.REPAIR.id)) {
    return (item && item.product && item.product.p_n) || '';
  }

  return (item && ((item.part && item.part.p_n) || item.p_n)) || '';
};

const mapMessages = (msg, order, attachments, isRepair, messages, isQuoted) => {
  const {
    buyer,
    items,
    order_id: orderId,
    increment_id: incrementId,
  } = order;

  const isBuyer = msg.user_id === buyer.user_id && msg.creator_type !== creatorTypes.BOT;
  const m = messages && messages.find((v) => v.message_id === msg.cited_message_id);

  switch (msg.message_type) {
    case chatMsgType.NO_QUOTE:
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        isBuyer,
        text: msg.text || 'No quote.',
        senderInfo: { ...msg.sender_info },
        date: msg.created_at,
        creatorType: msg.creator_type || null,
        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };

    case chatMsgType.TEXT:
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: msg.creator_type === creatorTypes.BOT ? creatorTypes.EPLANE : msg.creator_type,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        date: msg.created_at,
      };

    case chatMsgType.ATCH_ADD: {
      const checkPoFileRegExp = /\.pdf$/i;
      const attachedDocs = attachments && attachments.filter((att) => att.message_id === msg.message_id)
        .map((v) => ({
          ...v,
          canBeMarkedPo: v.rfq_role === atchRfqRole.GENERAL && isBuyer && !v.deleted_at && checkPoFileRegExp.test(v.name),
          orderId,
        }));
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: (msg.creator_type === creatorTypes.BOT ? creatorTypes.EPLANE : msg.creator_type) || null,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs,
        removedDocs: null,
        date: msg.created_at,
      };
    }

    case chatMsgType.ATCH_DEL: {
      let extraData;
      try {
        extraData = JSON.parse(msg.extra_info);
      } catch (e) {
        console.error(`JSON parse error of: ${msg.extra_info}`); // eslint-disable-line no-console
        return null;
      }

      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        isBuyer,
        text: null,
        senderInfo: { ...msg.sender_info },
        attachedDocs: null,
        removedDocNames: extraData && [extraData.atch_del.name],
        date: msg.created_at,
      };
    }

    case chatMsgType.QUOTE: {
      const tagDoc = attachments?.filter((att) => (
        att.message_id === msg.message_id
        && att.rfq_role === constants.chatAtchRfqRole.TAG
        && att.deleted_at === null
      ));
      const isTagDocRemoved = !!attachments?.find((att) => (
        att.message_id === msg.message_id
        && att.rfq_role === constants.chatAtchRfqRole.TAG
        && att.deleted_at
      )) && (!tagDoc || tagDoc.length === 0);

      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: msg.creator_type,
        quoteValidationStatus: msg?.quote.validation_status,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        tagDoc,
        removedDocs: null,
        date: msg.created_at,

        ...processQuoteMessage(msg, items, isRepair, isTagDocRemoved),

        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.ACK_PO:
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        removedDocs: null,
        date: msg.created_at,
      };

    case chatMsgType.EDIT_PO: {
      const detailsList = msg.po_edit && msg.po_edit.schedule_details_list;
      const shippingDate = detailsList && detailsList[detailsList.length - 1]
        && detailsList[detailsList.length - 1].scheduled_shipping_date;
      const changes = msg.po_edit && [
        ['PN#:', msg.po_edit.replacing_part_number],
        ['Qty:', msg.po_edit.quantity_adjustment_order_quantity],
        ['UM:', msg.po_edit.quantity_adjustment_order_quantity_unt],
        ['Target Date:', shippingDate && dateFormat(shippingDate, false)],
        [`Unit Price (${msg.po_edit.unit_price_amount_icr}):`, msg.po_edit.unit_price_amount && `${msg.po_edit.unit_price_amount}`],
        ['Remarks:', msg.po_edit.remarks],
      ];
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        isBuyer,
        senderInfo: { ...msg.sender_info },
        poEdit: changes || [],
        date: msg.created_at,
      };
    }

    case chatMsgType.PO: {
      const attachedPo = attachments && attachments.filter((att) => att.message_id === msg.message_id);

      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        isBuyer,
        senderInfo: { ...msg.sender_info },
        attachedPo,
        date: msg.created_at,
      };
    }

    case chatMsgType.REJ_PO:
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        isBuyer,
        senderInfo: { ...msg.sender_info },
        date: msg.created_at,
      };

    case chatMsgType.SHIP: {
      const AWB = msg.shipping.ship_notice_shipments
        && msg.shipping.ship_notice_shipments.map((v) => ['AWB:', v.bill_of_lading]);
      const shippingData = msg.shipping && [
        ['Qty:', msg.shipping.shipment_quantity],
        ...AWB,
        ['Shipping Date:', msg.shipping.shipped_date && dateFormat(msg.shipping.shipped_date, false)],
        ['Carrier Name:', msg.shipping.carrier],
        ['Remarks:', msg.shipping.remarks],
      ];
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        isBuyer,
        shipping: shippingData || [],
        senderInfo: { ...msg.sender_info },
        date: msg.created_at,
      };
    }

    case chatMsgType.PR_REQUEST:
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        isBuyer,
        senderInfo: { ...msg.sender_info },
        date: msg.created_at,
      };

    case chatMsgType.PR_MSG: {
      const tagDoc = attachments?.filter((att) => (
        att.message_id === msg.message_id
        && att.rfq_role === constants.chatAtchRfqRole.TAG
        && att.deleted_at === null
      ));
      const isTagDocRemoved = !!attachments?.find((att) => (
        att.message_id === msg.message_id
        && att.rfq_role === constants.chatAtchRfqRole.TAG
        && att.deleted_at
      )) && (!tagDoc || tagDoc.length === 0);

      if (msg.payment_request && msg.payment_request.is_hidden && !isQuoted) return null;
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: msg.creator_type === creatorTypes.BOT ? creatorTypes.EPLANE : msg.creator_type,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs: null,
        tagDoc,
        removedDocs: null,
        date: msg.created_at,

        status: msg.payment_request && msg.payment_request.status,
        ...processPaymentRequest(msg.payment_request, isRepair, isTagDocRemoved),

        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.PAYMENT_MSG: {
      const status = msg && msg.payment && msg.payment.status;
      const method = msg && msg.payment && msg.payment.method;
      if (status === paymentStatus.CREATED
        || status === paymentStatus.REJECTED
        || (status === paymentStatus.FAILED && method === paymentMethods.CARD.id)) {
        return null;
      }

      const attachedDocs = attachments && attachments.filter((att) => att.message_id === msg.message_id);

      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs,
        removedDocs: null,
        date: msg.created_at,

        incrementId,
        payment: msg.payment,
        status,
        method,
        buyerCompanyName: order.buyer.company_name ?? order.buyer.contact_name ?? 'Buyer',
        sellerCompanyName: order.seller.company_name ?? order.seller.contact_name ?? 'Seller',
        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages, true),
      };
    }

    case chatMsgType.PR_EXPIRED: {
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: msg.creator_type,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs: null,
        removedDocs: null,
        date: msg.created_at,

        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.EPLANE_PAYMENT_REQUEST: {
      const attachedDocs = attachments?.filter((att) => att.message_id === msg.message_id);
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs,
        removedDocs: null,
        date: msg.created_at,

        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.EPLANE_PO_REQUEST: {
      const attachedDocs = attachments?.filter((att) => att.message_id === msg.message_id);
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs,
        removedDocs: null,
        date: msg.created_at,

        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.EPLANE_BOUGHT_ITEM: {
      const attachedDocs = attachments?.filter((att) => att.message_id === msg.message_id);
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs,
        removedDocs: null,
        date: msg.created_at,

        sellerCompanyName: order.seller.company_name ?? '',
        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.EPLANE_ORDER_SHIPPED: {
      const attachedDocs = attachments?.filter((att) => att.message_id === msg.message_id);
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs,
        removedDocs: null,
        date: msg.created_at,

        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.EPLANE_REVIEW: {
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs: null,
        removedDocs: null,
        date: msg.created_at,

        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.EPLANE_PAYMENT_COMPLETE: {
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs: null,
        removedDocs: null,
        date: msg.created_at,

        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
        isWiretransfer: msg.extra_info === WIRETRANSFER_COMPLETE,
        buyerCompanyName: order.buyer.company_name ?? order.buyer.contact_name ?? 'Buyer',
      };
    }

    case chatMsgType.EPLANE_PAYMENT_FAILED: {
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs: null,
        removedDocs: null,
        date: msg.created_at,

        buyerCompanyName: order.buyer.company_name ?? order.buyer.contact_name ?? 'Buyer',
        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.EPLANE_PAYMENT_PROCESSING: {
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs: null,
        removedDocs: null,
        date: msg.created_at,

        buyerCompanyName: order.buyer.company_name ?? order.buyer.contact_name ?? 'Buyer',
        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.EPLANE_WT_CONFIRM: {
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs: null,
        removedDocs: null,
        date: msg.created_at,

        buyerCompanyName: order.buyer.company_name ?? order.buyer.contact_name ?? 'Buyer',
        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.PAYMENT_COMPLETE: {
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs: null,
        removedDocs: null,
        date: msg.created_at,

        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.PAYMENT_FAILED: {
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs: null,
        removedDocs: null,
        date: msg.created_at,

        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.PAYMENT_PROCESSING: {
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs: null,
        removedDocs: null,
        date: msg.created_at,

        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    case chatMsgType.EPP_REPORT: {
      return {
        messageId: msg.message_id,
        messageType: msg.message_type,
        creatorType: creatorTypes.EPLANE,
        isBuyer,
        text: msg.text,
        senderInfo: { ...msg.sender_info },
        attachedDocs: null,
        removedDocs: null,
        date: msg.created_at,

        quotedMessage: m && mapMessages(m, order, attachments, isRepair, messages),
      };
    }

    default: console.error(`Unknown message type: ${msg.message_type}!`); return null; // eslint-disable-line no-console
  }
};

/**
 * Creates tags map of the order.
 * If order deal type is repair, function returns array of tags, otherwise returns Object.
 * Keys of that Object are tag type from constants, values are array of tag entity.
 * @param {Object} order Order entity
 * @param {Object} constants server constants
 * @returns {Object|number[]}
 */
const createTagMapOfOrder = (order) => {
  if (!order) return null;
  let result;

  if (order.deal_type_list[0] === orderDealTypes.REPAIR.id) {
    const { product } = order.items[0];

    result = product && product.tags && product.tags.map((tag) => getConstNameById(tag, constants, 'tagType'));
  } else {
    result = order.items && order.items.reduce((acc, item) => {
      const documents = (item.part && item.part.documents) || [];

      documents.forEach((doc) => {
        const tagName = getConstNameById(doc.tag_type, constants, 'tagType');
        const tag = createTag(doc);
        acc[tagName] = (acc[tagName] && acc[tagName].concat(tag)) || [tag];
      });

      return acc;
    }, {});
  }
  return result;
};

const createTag = (tag) => ({
  url: tag.url,
  name: tag.label,
  created_at: tag.created_at,
  attachment_id: tag.document_id,
});
