import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FileLink } from '../../StyledComponents';
import dateFormat from '../../../utils/dateFormat';
import { color } from '../../../constants';

const Date = styled.time`
  font-size: 12px;
  color: ${color.grey};
  font-weight: 300;
  letter-spacing: 0.3px;
  margin-top: -1px;
  display: block;
  min-height: 15px;
`;

const Document = ({ url, name, created_at: date }) => (
  <>
    <FileLink to={url} target="_blank">{name}</FileLink>
    <Date>{dateFormat(date)}</Date>
  </>
);

Document.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  created_at: PropTypes.string,
};

export default Document;
