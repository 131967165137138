export default function getSelectedText() {
  let selectedText = '';

  if (window.getSelection) { // eslint-disable-line no-undef
    selectedText = window.getSelection(); // eslint-disable-line no-undef
  } else if (document.getSelection) { // eslint-disable-line no-undef
    selectedText = document.getSelection(); // eslint-disable-line no-undef
  } else if (document.selection) { // eslint-disable-line no-undef
    selectedText = document.selection.createRange().text; // eslint-disable-line no-undef
  }

  return selectedText;
}
