import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getVendorCategoriesStore } from '../../../selectors';
import * as VendorCategoriesActions from '../../../actions/settings/vendorCategories';
import Loader from '../../Loader';
import ModalLoader from '../../ModalLoader';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import { TabContainer } from '../../StyledComponents';
import CategoriesForm from './Categories';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import FormActionButtons from '../../FormComponents/ActionButtons';
import validators from './validators';
import { color } from '../../../constants';

class DemandCategories extends React.PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    categories: this.props.categories,
    prevPropsEditMode: this.props.editMode,
    validationState: [],
    confirmationModal: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.editMode !== state.prevPropsEditMode) {
      if (props.editMode && !state.prevPropsEditMode) {
        return {
          categories: props.categories.map((v) => ({ ...v })),
          prevPropsEditMode: props.editMode,
          validationState: [],
        };
      }
      return { prevPropsEditMode: props.editMode, validationState: [] };
    }

    return null;
  }

  componentDidMount() {
    const { fetchCategories } = this.props;
    fetchCategories();
  }

  componentDidUpdate(prevProps) {
    const { isSaving, fetchCategories } = this.props;
    if (!isSaving && prevProps.isSaving) {
      fetchCategories();
    }
  }

  onAddCategory = () => {
    const { categories, defaultCategories } = this.state;
    if (categories.length !== 0 && !categories[categories.length - 1].category_name
      && !categories[categories.length - 1].category_name) {
      return;
    }
    this.setState((prevState) => {
      const newValidationState = [...prevState.validationState];
      newValidationState[categories.length] = {
        category: validators.category('', categories, defaultCategories),
        category_name: validators.category_name('', categories, defaultCategories),
      };
      return {
        categories: [...prevState.categories, { category: '', category_name: '', is_enabled: true }],
        validationState: newValidationState,
      };
    });
  }

  onCheckChange = (i, value) => {
    this.setState((prevState) => {
      const newData = prevState.categories.map((v) => ({ ...v }));
      newData[i].is_enabled = value;
      if (newData.every((v) => !v.is_enabled)) {
        return { preSavedData: newData, confirmationModal: true };
      }
      return { categories: newData };
    });
  }

  valid = (categories) => {
    const { categories: categoriesProps } = this.props;
    const newVal = categories.map((v) => ({
      category: validators.category(v.category, categories),
      category_name: validators.category_name(v.category_name, categories),
    }));
    const isNotValid = !categories || newVal.some((v) => v && Object.values(v).some((el) => el));
    if (isNotValid) {
      this.setState({ validationState: newVal });
      return false;
    }

    const changesValidation = validators.validateIdentifiers(categories, categoriesProps);
    const isNotValidId = changesValidation.some((v) => v && Object.values(v).some((el) => el));
    if (isNotValidId) {
      this.setState({ validationState: changesValidation });
      return false;
    }

    return true;
  }

  onSaveCategories = () => {
    const { saveCategories } = this.props;
    const { categories } = this.state;
    if (!this.valid(categories)) {
      return;
    }

    const saveData = {
      demand_categories: categories,
    };
    saveCategories(saveData);
  }

  onBlur = () => {
    const { categories } = this.state;
    this.valid(categories);
  }

  onCategoryChange = (i, name, value) => {
    this.setState((prevState) => {
      const newData = [...prevState.categories];
      newData[i][name] = (name === 'category') ? value.toUpperCase() : value;

      // Reset validation of category id modification (only one change for all list)
      const newValidationState = prevState.validationState.map((v) => (
        v?.category?.helpText.startsWith('Only one identifier') && name === 'category'
          ? { category: null, category_name: null }
          : v
      ));
      newValidationState[i] = {
        ...newValidationState[i],
        [name]: validators[name](value, prevState.categories),
      };

      return { categories: newData, validationState: newValidationState };
    });
  }

  onConfirm = () => {
    this.setState(((prevState) => ({
      categories: prevState.preSavedData,
      preSavedData: null,
      confirmationModal: false,
    })));
  }

  onHideConfirmationModal = () => this.setState({ preSavedData: null, confirmationModal: false });

  render() {
    const {
      categories: categoriesProps,
      isFetching,
      isSaving,
      editMode,
      toggleEditMode,
    } = this.props;
    const {
      categories: categoriesState,
      validationState,
      confirmationModal,
    } = this.state;

    const categories = editMode ? categoriesState : categoriesProps;
    const saveDisabled = !categories || validationState.some((v) => v && Object.values(v).some((el) => el));

    if (isFetching) return <Loader />;

    return (
      <>
        <FormTitleSection
          title="Demand Categories"
          EditButtonClick={toggleEditMode}
          showEditButton
        />
        <TabContainer>
          {categories.length !== 0 && (
            <p>
              If a category is deactivated, it will be automatically deleted from Vendor Categories list.
            </p>
          )}
          <br />
          <CategoriesForm
            categories={categories}
            showControls
            disabled={!editMode}
            onAddCategory={this.onAddCategory}
            onCheckChange={this.onCheckChange}
            onCategoryChange={this.onCategoryChange}
            onBlur={this.onBlur}
            validation={validationState}
          />
          {editMode && (
            <>
              <hr />
              <FormActionButtons
                saveProps={{ disabled: saveDisabled }}
                saveHandler={this.onSaveCategories}
                cancelHandler={toggleEditMode}
                width="80"
              />
            </>
          )}
          <br />

          <ConfirmationModal
            show={confirmationModal}
            confirm={this.onConfirm}
            onHide={this.onHideConfirmationModal}
            confirmBtnText="I'm sure"
            cancelBtnText="Cancel"
          >
            <>
              <FontAwesomeIcon icon="exclamation-circle" color={`${color.goldenTainoi}`} size="2x" />
              <h3>
                Are you sure you wish to disable all categories?
              </h3>
              <p>
                Disabling all categories can cause company categorization malfunction.
                Leaving at least `ac parts` enabled is better decision.
              </p>
            </>
          </ConfirmationModal>
          <ModalLoader show={isSaving} />
        </TabContainer>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { categories: vendorCategory, ...other } = getVendorCategoriesStore(state);
  const categories = vendorCategory || [];
  return {
    categories,
    ...other,
  };
}

DemandCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
  isSaving: PropTypes.bool,
  editMode: PropTypes.bool,
  toggleEditMode: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  saveCategories: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, VendorCategoriesActions)(DemandCategories);
