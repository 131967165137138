import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { color } from '../../constants';

const Text = styled.span`
  color: ${(props) => (props.color || '')};
  margin-left: 20px;
  font-size: 16px;
`;

export default function EmailStatusMark({ statusId, customerEmailStatusConstants, withDescription }) {
  if (!customerEmailStatusConstants || !statusId) return null;

  switch (statusId) {
    case customerEmailStatusConstants.INACTIVE:
      return (
        <Text color={color.textRed}>
          <FontAwesomeIcon icon="user-times" />
          {withDescription ? ' deactivated' : ''}
        </Text>
      );

    case customerEmailStatusConstants.INVITED_EXPIRED:
      return (
        <Text color={color.textRed}>
          <FontAwesomeIcon icon={['far', 'clock']} />
          {withDescription ? ' invited - Expired' : ''}
        </Text>
      );

    case customerEmailStatusConstants.INVITED_PENDING:
      return (
        <Text color={color.darkorange}>
          <FontAwesomeIcon icon={['far', 'clock']} />
          {withDescription ? ' invited - Pending' : ''}
        </Text>
      );

    case customerEmailStatusConstants.NOT_VERIFIED:
      return (
        <Text color={color.textRed}>
          <FontAwesomeIcon icon="times" />
          {withDescription ? ' unverified' : ''}
        </Text>
      );

    case customerEmailStatusConstants.VERIFIED:
      return (
        <Text color={color.textGreen}>
          <FontAwesomeIcon icon="check" />
          {withDescription ? ' verified' : ''}
        </Text>
      );

    case customerEmailStatusConstants.DELETED:
      return (
        <Text color={color.textRed}>
          <FontAwesomeIcon icon="trash-alt" />
          {withDescription ? ' DELETED' : ''}
        </Text>
      );

    default: console.error(`Unknown email status id: ${statusId};`); // eslint-disable-line no-console
  }
}

EmailStatusMark.propTypes = {
  statusId: PropTypes.number,
  customerEmailStatusConstants: PropTypes.objectOf(PropTypes.number),
  withDescription: PropTypes.bool,
};
