import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Table from '../../Table/Table';
import header from '../../Table/HeaderWithSort';
import TableStyles from '../../Table/TableStyles';
import CellWithTitle from '../../Table/Cell';

import editButtonImg from '../../../assets/btn-edit-sprite.png';
import emailButtonImg from '../../../assets/email.png';
import disabledEmailButtonImg from '../../../assets/email_disabled.png';
import spriteCommonElements from '../../../assets/sprite-common-elements.png';
import { customerStatus } from '../../../constants/index';
import { color } from '../../../constants';
import { tdClickableFormatter } from '../../UsersTable';

export const DismissButton = styled.div`
  cursor: pointer;
  color: ${color.birch};
  display: inline-block;
  width: 23px;
  height: 23px;

  &:hover {
    color: ${color.darkBirch};
  }
`;
export const EditButton = styled.div`
  cursor: pointer;
  display: inline-block;
  background: url(${editButtonImg}) no-repeat 0 0;
  width: 23px;
  height: 23px;

  &:hover {
    background-position: 0 -66px;
  }
`;
export const RemoveButton = styled.div`
  cursor: pointer;
  display: inline-block;
  background: url(${spriteCommonElements}) no-repeat;
  background-position: -355px 0;
  width: 10px;
  height: 10px;

  &:hover {
    background-position: -375px 0;
  }
`;

export const UndoRemoveButton = styled.div`
cursor: pointer;
color: ${color.birch};
display: inline-block;
width: 23px;
height: 23px;

&:hover {
  color: ${color.iconGreen};
}
`;
const CheckBoxRow = styled(Form.Check)`
  & input {
    width: 20px;
    height: 20px;
  }
`;
const EmailButton = styled.div`
  cursor: pointer;
  display: inline-block;
  background: url(${emailButtonImg}) no-repeat 0 0;
  width: 32px;
  height: 32px;
  text-align: center;
`;
const EmailButtonDisabled = styled.div`
  display: inline-block;
  background: url(${disabledEmailButtonImg}) no-repeat 0 0;
  width: 32px;
  height: 32px;
`;
const ButtonsControl = styled.div`
  text-align: right;
`;
const TableContainer = styled(TableStyles)`
  .table-header-cell {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: inset 0 0 #ddd, 0 -1px #ddd;
  }

  thead tr th {
    border: none;
  }
  tbody tr td {
    border: none;
  }
  max-height: ${(props) => props.maxHeight}px;
  overflow-y: auto;
  width: 100%;
`;

export default function UMTable({
  userId,
  data,
  keyField,
  removeText,
  checkedRows,
  onDismiss,
  onEdit,
  onRemove,
  onCheck,
  onUndoRemove,
  selectSite,
  isUserExists,
  undoRemoveText,

  showUndoRemove = false,
  showDismissColumn = false,
  showEditColumn = true,
  showRemoveColumn = true,
  showCheckColumn = false,
  showEntityColumn = false,
  btnControls,
  columns,
  editUUID: editUUId,
  pushRedirect,
  ...other
}) {
  const dismissFormatter = useCallback((row) => (
    <DismissButton onClick={() => onDismiss(row[keyField])} title="Dismiss">
      <FontAwesomeIcon icon="user-minus" size="2x" />
    </DismissButton>
  ), [keyField, onDismiss]);

  const editFormatter = useCallback((row) => (
    <EditButton onClick={() => onEdit(row[keyField])} title="Edit" />
  ), [keyField, onEdit]);

  const removeFormatter = useCallback((cell, row) => {
    // disable activate/deactivate for deleted users
    // eslint-disable-next-line camelcase
    if (row?.customer_status === customerStatus.DELETED.id) {
      return '';
    }

    const clickRemoveHandler = () => (onRemove && onRemove(row[keyField]));
    const clickUndoHandler = () => (onUndoRemove && onUndoRemove(row[keyField]));
    const undo = (showUndoRemove === true)
      ? (
        <UndoRemoveButton onClick={clickUndoHandler} title={undoRemoveText || 'Undo remove'}>
          <FontAwesomeIcon icon="undo" />
        </UndoRemoveButton>
      ) : '';
    return cell === false ? undo : <RemoveButton onClick={clickRemoveHandler} title={removeText || 'Remove'} />;
  }, [keyField, onRemove, onUndoRemove, removeText, showUndoRemove, undoRemoveText]);

  const checkFormatter = useCallback((cell, row) => {
    const checked = checkedRows.find((elem) => elem === row[keyField]) !== undefined;
    const clickHandler = () => (onCheck && onCheck(row[keyField]));
    return cell ? <CheckBoxRow checked={checked} onClick={clickHandler} /> : '';
  }, [checkedRows, keyField, onCheck]);

  const generateEplaneEmail = () => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    // eslint-disable-next-line
    return 'inventory+' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '@eplane.com';
  };

  const emailFormatter = useCallback(({ eplane_email: eplaneEmail, site_id: siteId }) => {
    const clickHandler = () => {
      selectSite({ eplane_email: generateEplaneEmail() }, siteId, userId);
    };
    return (
      <div style={{ textAlign: 'center' }}>
        {eplaneEmail || (isUserExists ? <EmailButton onClick={clickHandler} /> : <EmailButtonDisabled />)}
      </div>
    );
  }, [isUserExists, selectSite, userId]);

  const tableColumns = useMemo(
    () => [
      showEntityColumn && {
        Header: () => header('Customer id'),
        accessor: keyField,
        Cell: ({ value, row }) => tdClickableFormatter(value, row.original, pushRedirect),
        style: { width: '100px' },
      }, ...columns.map((el) => (
        {
          Header: () => header(el.name),
          accessor: el.dataField,
          Cell: el.dataFormat
            ? ({ value, row }) => el.dataFormat(value, row.original, pushRedirect)
            : ({ value }) => <CellWithTitle value={value} style={el.tdStyle} />,
          style: el.tdStyle,
          ...el,
        }
      )), editUUId && {
        Header: () => header('Site\'s ePlane email address'),
        id: 'email_column',
        Cell: ({ row }) => emailFormatter(row.original),
        style: { width: '40%', textAlign: 'center' },
      }, showDismissColumn && {
        Header: () => null,
        accessor: 'dismiss',
        Cell: ({ row }) => dismissFormatter(row.original),
        style: { width: '50px' },
      }, showEditColumn && {
        Header: () => null,
        accessor: 'edit',
        Cell: ({ row }) => editFormatter(row.original),
        style: { width: '50px' },
      }, showRemoveColumn && {
        Header: () => null,
        accessor: 'remove',
        Cell: ({ value, row }) => removeFormatter(value, row.original),
        style: { width: '50px' },
      }, showCheckColumn && {
        Header: () => null,
        id: 'check_column',
        Cell: ({ value, row }) => checkFormatter(value, row.originals),
        style: { width: '50px' },
      },
    ].filter((v) => !!v), [
      emailFormatter, checkFormatter, dismissFormatter, removeFormatter, editFormatter,
      editUUId, keyField, pushRedirect, columns,
      showCheckColumn, showDismissColumn, showEditColumn, showEntityColumn, showRemoveColumn,
    ],
  );

  if (!data) return null;

  return (
    <div>
      <ButtonsControl>{btnControls}</ButtonsControl>
      <TableContainer maxHeight={other.maxHeight}>
        <Table columns={tableColumns} data={data} />
      </TableContainer>
    </div>
  );
}

const columnProps = PropTypes.shape({
  name: PropTypes.string, // name of column, that appears in the header
  dataField: PropTypes.string, // specify field in data set (data props of BootstrapTable)
  width: PropTypes.string, // width of column in px or %
});

UMTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  userId: PropTypes.string,
  onSaveHandler: PropTypes.func,
  selectSite: PropTypes.func,
  editUUID: PropTypes.bool,
  isUserExists: PropTypes.bool,
  columns: PropTypes.arrayOf(columnProps).isRequired,
  showDismissColumn: PropTypes.bool,
  showEditColumn: PropTypes.bool,
  showRemoveColumn: PropTypes.bool,
  showCheckColumn: PropTypes.bool,
  showEntityColumn: PropTypes.bool,
  showUndoRemove: PropTypes.bool,
  btnControls: PropTypes.element, // content above of the table which is right centered
  onDismiss: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onUndoRemove: PropTypes.func,
  pushRedirect: PropTypes.func,
  removeText: PropTypes.string,
  undoRemoveText: PropTypes.string,
  onCheck: PropTypes.func,
  checkedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  keyField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // specify property in data set which serves as a key in list of rows
};
