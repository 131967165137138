import React from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { Text, Button } from './styledComponents';

const ProgressModal = ({
  onHide, onCancel, now, ...other
}) => (
  <Modal centered onHide={onHide} {...other}>
    <Modal.Body>
      <Text>
        Uploading Document...
      </Text>
      <ProgressBar now={now} />
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onCancel}>Cancel</Button>
    </Modal.Footer>
  </Modal>
);

ProgressModal.propTypes = {
  now: PropTypes.number,
  onHide: PropTypes.func,
  onCancel: PropTypes.func,
};
export default ProgressModal;
