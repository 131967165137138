import {
  TASK_IGNORE_LIST_GET,
  TASK_IGNORE_LIST_GET_SUCCESS,

  TASK_IGNORE_LIST_SAVE,
  TASK_IGNORE_LIST_SAVE_SUCCESS,

  TASK_IGNORE_LIST_DELETE,
  TASK_IGNORE_LIST_DELETE_SUCCESS,

  TASK_IGNORE_LIST_FAIL,
  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';
import API from '../../API';

const startFetchingIgnoreList = () => ({
  type: TASK_IGNORE_LIST_GET,
});
const fetchIgnoreListSuccess = (message) => ({
  type: TASK_IGNORE_LIST_GET_SUCCESS,
  payload: message,
});

const startSaveIgnoreList = () => ({
  type: TASK_IGNORE_LIST_SAVE,
});
const saveIgnoreListSuccess = (message) => ({
  type: TASK_IGNORE_LIST_SAVE_SUCCESS,
  payload: message,
});

const deleteIgnoreRule = () => ({
  type: TASK_IGNORE_LIST_DELETE,
});
const deleteIgnoreRuleSuccess = (message) => ({
  type: TASK_IGNORE_LIST_DELETE_SUCCESS,
  payload: message,
});

const taskIgnoreListFail = () => ({
  type: TASK_IGNORE_LIST_FAIL,
});

export const fetchTaskIgnoreRules = () => async (dispatch) => {
  dispatch(startFetchingIgnoreList());
  try {
    const response = await API.getIgnoreTaskList();

    if (response.data.code === ERRORS.SUCCESS) dispatch(fetchIgnoreListSuccess(response.data?.payload?.autoactions));
    else requestErrorHandler(response, dispatch, taskIgnoreListFail);
  } catch (e) {
    requestErrorHandler(e.response, dispatch, taskIgnoreListFail, e);
  }
};

export const saveTaskIgnoreRule = (params, autoActionId) => async (dispatch) => {
  dispatch(startSaveIgnoreList());
  try {
    let response = null;
    if (autoActionId) response = await API.updateIgnoreRule(params, autoActionId);
    else response = await API.addIgnoreRule(params);

    if (response.data.code === ERRORS.SUCCESS) dispatch(saveIgnoreListSuccess(response.data?.payload?.autoactions));
    else requestErrorHandler(response, dispatch, taskIgnoreListFail);
  } catch (e) {
    requestErrorHandler(e.response, dispatch, taskIgnoreListFail, e);
  }
};

export const deleteTaskIgnoreRule = (autoActionId) => async (dispatch) => {
  dispatch(deleteIgnoreRule());
  try {
    const response = await API.deleteIgnoreRule(autoActionId);

    if (response.data.code === ERRORS.SUCCESS) dispatch(deleteIgnoreRuleSuccess(response.data?.payload?.autoactions));
    else requestErrorHandler(response, dispatch, taskIgnoreListFail);
  } catch (e) {
    requestErrorHandler(e.response, dispatch, taskIgnoreListFail, e);
  }
};
