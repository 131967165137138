import React from 'react';
import styled from 'styled-components';
// import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SortHeader = styled.div`
  padding: 0;
  color: white;
  font-weight: bold;
  outline: none;
`;
const SortIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  margin-top: 5px;
`;

const Header = (title, showButton, sortOrder, id, onSortHandler) => {
  let sortButton = null;
  if (!showButton) {
    sortButton = <SortHeader title={title}>{title}</SortHeader>;
  } else if (sortOrder === 'asc') {
    sortButton = (
      <SortHeader
        title={title}
        onKeyPress={null}
        role="button"
        tabIndex="0"
        onClick={() => onSortHandler(id, 'desc')}
      >
        {title}
        <SortIcon icon="sort-up" />
      </SortHeader>
    );
  } else if (sortOrder === 'desc') {
    sortButton = (
      <SortHeader
        title={title}
        onKeyPress={null}
        role="button"
        tabIndex="0"
        onClick={() => onSortHandler(id, 'asc')}
      >
        {title}
        <SortIcon icon="sort-down" />
      </SortHeader>
    );
  } else {
    sortButton = (
      <SortHeader
        title={title}
        onKeyPress={null}
        role="button"
        tabIndex="0"
        onClick={() => onSortHandler(id, 'asc')}
      >
        {title}
        <SortIcon icon="sort" />
      </SortHeader>
    );
  }

  return (
    <>
      {sortButton}
    </>
  );
};

export default Header;
