import { combineReducers } from 'redux';

import inventoryList from './inventory';
import uploader from './uploader';
import companies from './companies';
import userRepository from './userRepository';
import companyCustomer from './companyCustomer';
import userSettings from './userSettings';
import pnList from './pnList';
import demandList from './demandList';
import sftpUser from './sftpUser';
import activeCompanyCustomers from './activeCompanyCustomers';
import legalEntities from './legalEntities';
import vendorCategorization from './vendorCategorization';
import companyWeight from './companyWeight';
import paymentProviders from './paymentProviders';
import customSellersLists from './customSellersLists';
import weights from './weights';
import spec2k from './spec2k';
import orderLimits from './orderLimits';
import buyersLists from './buyersLists';
import partsWatchlist from './partsWatchlist';

export default combineReducers({
  companies,
  companyCustomer,
  userRepository,
  inventoryList,
  uploader,
  orderLimits,
  userSettings,
  pnList,
  demandList,
  sftpUser,
  activeCompanyCustomers,
  legalEntities,
  vendorCategorization,
  companyWeight,
  paymentProviders,
  customSellersLists,
  buyersLists,
  partsWatchlist,
  weights,
  spec2k,
});
