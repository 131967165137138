import {
  SFTP_USER_GET,
  SFTP_USER_GET_SUCCESS,
  SFTP_USER_GET_FAIL,
  SFTP_USER_SAVE,
  SFTP_USER_SAVE_SUCCESS,
  SFTP_USER_SAVE_FAIL,
  SFTP_USER_ERROR_REMOVE,
} from '../../constants';

const initialState = {
  sftpUserData: null,
  isFetching: false,
  isSaving: false,
  sftpSavingError: null,
};

const SftpUserReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SFTP_USER_GET:
      return {
        ...state,
        isFetching: true,
      };
    case SFTP_USER_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        sftpUserData: payload,
      };
    case SFTP_USER_GET_FAIL:
      return {
        ...state,
        isFetching: false,
      };
    case SFTP_USER_SAVE:
      return {
        ...state,
        isSaving: true,
      };
    case SFTP_USER_SAVE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        sftpUserData: payload,
      };
    case SFTP_USER_SAVE_FAIL:
      return {
        ...state,
        isSaving: false,
        sftpSavingError: payload,
      };
    case SFTP_USER_ERROR_REMOVE:
      return {
        ...state,
        sftpSavingError: null,
      };

    default: return state;
  }
};

export default SftpUserReducer;
