import React from 'react';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { color } from '../../../constants';
import Document from './Document';

const Title = styled.h5`
  color: ${color.darkGrey};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.4px;
  line-height: 1;
  padding-left: 15px;
  margin: 0;
  min-height: 17px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};;
  margin-bottom: 7px;

  color: ${({ disabled }) => (disabled ? color.grey : 'inherit')};
`;
const ListContainer = styled.ul`
  margin: 0;
  list-style-type: none;
`;
const Glyph = styled.span`
  transform: rotate(${({ rotateEl }) => (rotateEl ? '90deg' : '0deg')});

  transition: transform 0.3s;
  display: inline-block;
`;
const TagSubfolderContainer = styled.div`
  display: flex;
`;
const TagContainer = styled.ul`
  list-style-type: none;
  padding: 0 0 0 3px;
`;
const TagName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;
const Tag = styled.div`
  display: flex;
`;

class DocFolder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  onTitleClick = () => this.setState((prevState) => ({ open: !prevState.open }));

  render() {
    const {
      title,
      documents = [],
      tagsData,
      singleTags,
    } = this.props;
    const { open } = this.state;

    const singleTagList = singleTags && singleTags.map((tagName) => <div key={tagName}>{tagName}</div>);
    const tagList = createTagList(tagsData);

    const documentList = documents.map(createDocElement);

    const tagsLength = Object.values(tagsData || {}).reduce((sum, arr) => sum + arr.length, 0);
    const singleTagsLength = (singleTags && singleTags.length) || 0;
    const documentsLength = (documents && documents.length) || 0;
    const numOfFiles = singleTagsLength + tagsLength + documentsLength;

    return (
      <>
        <Title disabled={!numOfFiles} onClick={numOfFiles ? this.onTitleClick : null}>
          <Glyph rotateEl={open}><FontAwesomeIcon icon="chevron-right" /></Glyph>
          &nbsp;
          {title}
          &nbsp;
          (
          {numOfFiles}
          )
        </Title>

        <Collapse in={open}>
          <ListContainer>
            {singleTagList}
            {tagList}
            {documentList}
          </ListContainer>
        </Collapse>
      </>
    );
  }
}

const documentsPT = PropTypes.arrayOf(PropTypes.object);
DocFolder.propTypes = {
  title: PropTypes.string,
  documents: documentsPT,
  tagsData: PropTypes.objectOf(PropTypes.array),
  singleTags: PropTypes.arrayOf(PropTypes.string),
};

const TagSubfolder = ({ tagName, documents }) => (
  <TagSubfolderContainer>
    <Tag>
      <TagName title={tagName}>
        {tagName}
      </TagName>
      <div>:</div>
    </Tag>
    <TagContainer>
      {documents.map(createDocElement)}
    </TagContainer>
  </TagSubfolderContainer>
);

TagSubfolder.propTypes = {
  tagName: PropTypes.string,
  documents: documentsPT,
};

const createTagList = (tagData) => (
  tagData && Object.keys(tagData).map((tagName) => (
    <li key={`TagDocs - ${tagName}`}><TagSubfolder tagName={tagName} documents={tagData[tagName]} /></li>
  ))
);

const createDocElement = (docData) => <li key={docData.attachment_id}><Document {...docData} /></li>;

export default DocFolder;
