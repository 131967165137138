import createErrorValidationObj from '../../../utils/createErrorValidationObj';

export const companyWeightValidator = (value) => {
  if (!value) return createErrorValidationObj('Please enter a weight');
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal) || !value.match(/\d+$/)) return createErrorValidationObj('Please enter only digits');
  if (numVal < 1) return createErrorValidationObj('Please enter a positive number');
  return null;
};

export const naturalNumberValidator = (value) => {
  if (!value) return createErrorValidationObj('Please enter quoting time');
  const numVal = +value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numVal)) return createErrorValidationObj('Please enter a number of minutes');
  if (numVal < 0) return createErrorValidationObj('Please enter a positive number');
  return null;
};
