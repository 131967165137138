import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import replaceDocImage from '../../../assets/ic-deny.png';
import { Text, Button } from '../styledComponents';

const WrongFileImage = styled.div`
  background: url(${replaceDocImage}) no-repeat 0 0;
  width: 174px;
  height: 165px;
  margin: 0 auto 0;
  position: relative;
  left: 16px;
`;

const HeaderText = styled.div`
  font-size: 36px;
  text-align: center;
  font-weight: bold;
`;

const WrongFormatModal = ({ text, onHide, ...other }) => (
  <Modal centered onHide={onHide} {...other}>
    <Modal.Header closeButton>
      <HeaderText>Wrong file format</HeaderText>
    </Modal.Header>
    <Modal.Body>
      <WrongFileImage />
      <Text>
        {text}
      </Text>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={onHide}>OK</Button>
    </Modal.Footer>
  </Modal>
);

WrongFormatModal.propTypes = {
  onHide: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default WrongFormatModal;
