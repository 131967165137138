import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { FormControl, Col, Row as RowNormal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { fetchRfqEmailSettings, saveRfqEmailSettings } from '../../../actions/settings/emails';
import { getEmailsSettingsStore, getErrorsStore } from '../../../selectors';
import FormActionButtons from '../../FormComponents/ActionButtons';
import FormTitleSection, { FormSubTitle } from '../../FormComponents/TitleSection/index';
import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import { TabContainer } from '../../StyledComponents';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';

const CheckBoxLabel = styled.span`
  font-size: 16px;
  margin-left: 5px;
`;
const CheckBox = styled(CheckboxStyled)`
  margin-bottom: 0;
`;
const FlexRow = styled(RowNormal)`
  display: flex;
  align-items: baseline;
`;
const ReminderCol = styled(Col)`
  margin-top: 15px;
  display: flex;
  align-items: baseline;
  .time-delimeter {
    font-weight: bold;
    padding: 0 5px;
  }
  .hours-select {
    width: 70px;
    margin-left: 10px;
  }
  .minutes-select {
    width: 70px;
    margin-right: 10px;
  }
  .accumulation-hours {
    margin-left: 10px;
    margin-right: 10px;
    max-width: 70px;
    text-align: right;
  }
`;

const hoursOptions = Array(12).fill(0).map((_, i) => ({ label: i.toString().padStart(2, '0'), value: i }));
const minutesOptions = Array(60).fill(0).map((_, i) => ({ label: i.toString().padStart(2, '0'), value: i }));

const DigestedEmailsPage = () => {
  const dispatch = useDispatch();
  const { settings, isFetching, isProcessing } = useSelector(getEmailsSettingsStore);
  const { errors } = useSelector(getErrorsStore);

  const [stateSettings, setStateSettings] = useState({
    hours: hoursOptions[9],
    minutes: minutesOptions[0],
    reminder_interval: '8',
    reminder_is_enabled: false,
  });
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    dispatch(fetchRfqEmailSettings());
  }, [dispatch]);

  useEffect(() => {
    const parseTime = settings && settings.reminder_time.split(':');
    setStateSettings({
      hours: settings && { label: parseTime[0], value: +parseTime[0] },
      minutes: settings && { label: parseTime[1], value: +parseTime[1] },
      reminder_interval: settings && settings.reminder_interval,
      reminder_is_enabled: settings && settings.reminder_is_enabled === '1',
    });
  }, [settings]);

  const onSaveHandler = () => {
    setEditMode(false);

    const data = {
      reminder_time: `${stateSettings.hours.label}:${stateSettings.minutes.label}`,
      reminder_interval: stateSettings.reminder_interval,
      reminder_is_enabled: stateSettings.reminder_is_enabled ? '1' : '0',
    };

    if (Object.keys(data).some((v) => data[v] !== settings[v])) {
      dispatch(saveRfqEmailSettings(data));
    }
  };

  const onCancelHandler = () => {
    const parseTime = settings && settings.reminder_time.split(':');
    setStateSettings({
      hours: settings && { label: parseTime[0], value: +parseTime[0] },
      minutes: settings && { label: parseTime[1], value: +parseTime[1] },
      reminder_interval: settings.reminder_interval,
      reminder_is_enabled: settings.reminder_is_enabled,
    });

    setEditMode(false);
  };

  const onInputChange = (e) => {
    if (e.target.value === '' || (/^\d+$/.test(e.target.value) && e.target.value > 0)) {
      setEditMode(true);
      setStateSettings({
        ...stateSettings,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onSelectChange = (selectedOption, action) => {
    setEditMode(true);
    setStateSettings({
      ...stateSettings,
      [action.name]: selectedOption,
    });
  };

  const onReminderEnable = (e) => {
    setEditMode(true);
    setStateSettings({
      ...stateSettings,
      reminder_is_enabled: e.target.checked,
    });
  };

  if ((!settings) && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <div>
      <FormTitleSection title="Emails" showEditButton={false} />
      <TabContainer>

        <FormSubTitle title="RFQ Reminders" showEditButton={false} />
        <CheckBox
          id="checkbox-enable-reminder"
          label={(<CheckBoxLabel>Send list of all unanswered RFQs</CheckBoxLabel>)}
          checked={stateSettings.reminder_is_enabled || false}
          name="enable-reminder"
          onChange={onReminderEnable}
        />

        <FlexRow>
          <Col sm={3}><p>Will be sent at</p></Col>
          <ReminderCol sm={4}>
            <Select
              value={stateSettings.hours}
              name="hours"
              onChange={onSelectChange}
              options={hoursOptions}
              className="hours-select"
              isDisabled={!stateSettings.reminder_is_enabled}
            />
            <span className="time-delimeter">:</span>
            <Select
              value={stateSettings.minutes}
              name="minutes"
              onChange={onSelectChange}
              options={minutesOptions}
              className="minutes-select"
              isDisabled={!stateSettings.reminder_is_enabled}
            />
            <div>
              {` GMT / ${stateSettings.hours && (+stateSettings.hours.value + 12)}
              : ${stateSettings.minutes && stateSettings.minutes.label} GMT`}
            </div>
          </ReminderCol>
        </FlexRow>

        <FlexRow>
          <Col sm={3}>
            <p>That was created within last</p>
          </Col>
          <ReminderCol sm={3}>
            <FormControl
              name="reminder_interval"
              value={stateSettings.reminder_interval || ''}
              onChange={onInputChange}
              maxLength={3}
              className="accumulation-hours"
              disabled={!stateSettings.reminder_is_enabled}
            />
            <div>hours</div>
          </ReminderCol>
        </FlexRow>

        {editMode && (
          <>
            <hr />
            <FormActionButtons
              saveProps={{ disabled: (stateSettings.reminder_interval === '') }}
              saveHandler={onSaveHandler}
              cancelHandler={onCancelHandler}
            />
          </>
        )}

        <ModalLoader show={isProcessing || isFetching} />
      </TabContainer>
    </div>
  );
};

export default DigestedEmailsPage;
