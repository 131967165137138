import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { fetchRequestData } from '../../actions/requestDataActions';
import { getRequestDataStore, getErrorsStore } from '../../selectors';
import { getRequestData } from '../../reducers/requests/requestData';
import RequestDetails from '../RequestDetails/index';
import NotFoundPage from '../NotFoundPage';
import Container from '../Container';
import dateFormat from '../../utils/dateFormat';
import HistoryTable from '../HistoryTable';
import { TableRow as TableRowNormal } from '../StyledComponents';
import Loader from '../Loader';
import { BASE_DECLINE_COMMENT, ERRORS } from '../../constants';
import * as constants from '../../constants/index';

const CommentContainer = styled.div`
  margin: 30px 0px;
  font-size: 1.2em;
  & > div {
    padding: 5px;
  }
`;
const Italic = styled.span`
  font-style: italic;
  padding-right: 4px;
`;
const TableRow = styled(TableRowNormal)`
  margin-top: 30px;
`;

export default function ClosedDetailsPage() {
  const dispatch = useDispatch();

  const { id: reqId } = useParams();

  const requestDataStore = useSelector(getRequestDataStore);
  const requestData = getRequestData(requestDataStore, constants);
  const { isFetching: requestDataIsFetching } = requestDataStore;
  const { errors } = useSelector(getErrorsStore);

  useEffect(() => {
    dispatch(fetchRequestData(reqId));
  }, [dispatch, reqId]);

  let content;

  if (requestDataIsFetching) {
    content = <Loader />;
  } else if (errors.some((item) => item.error === ERRORS.ERR_ENTITY_NOT_FOUND)) {
    content = <NotFoundPage />;
  } else if (requestData) {
    const hqReq = requestData.headquarters;
    const headQuarters = [];
    const regionName = hqReq.state || hqReq.region;

    if (hqReq.street) headQuarters.push(hqReq.street);
    if (hqReq.city) headQuarters.push(hqReq.city);
    if (regionName) headQuarters.push(regionName);
    if (hqReq.zipCode) headQuarters.push(hqReq.zipCode);
    if (hqReq.countryName) headQuarters.push(hqReq.countryName);

    const comment = requestData.comment && requestData.comment.replace(BASE_DECLINE_COMMENT, '');
    const commentAuthor = requestData.boUser || 'Backoffice user';

    const timeOfDecline = dateFormat(requestData.updatedAt);
    const commentContent = (
      comment && (
        <div>
          <Italic>{timeOfDecline}</Italic>
          &nbsp;&#124;
          <span>
            &nbsp;
            {comment}
          </span>
        </div>
      )
    );

    content = (
      <div>
        <RequestDetails
          user_id={requestData.companyUserId}
          reqNo={requestData.entityId}
          userName={requestData.userName}
          userEmail={requestData.userEmail}
          userFiles={requestData.documents}
          companyName={requestData.companyName}
          companyType={requestData.companyType}
          companyContact={requestData.companyContact}
          companyEmail={requestData.companyEmail}
          headquarters={headQuarters.join(', ')}
          phoneNumber={requestData.companyPhone}
          contactPhone={requestData.contactPhone}
          emailIsVerified={requestData.userEmailIsVerified}
          companyWebsite={requestData.companyWebsite}
          vatTaxNumber={requestData.vatTax}
          reqResult={requestData.result}
          customers={requestData.customers}
          constants={constants}
        />
        {
          commentContent && (
            <CommentContainer>
              <div>{`${commentAuthor} Comments:`}</div>
              {commentContent}
            </CommentContainer>
          )
        }

        <TableRow>
          <HistoryTable userID={requestData.userId} excludedIds={[requestData.entityId]} />
        </TableRow>
      </div>
    );
  }

  return (
    <Container links={[{ 'Incoming requests': 'incoming' }, { 'Closed Request': null }]}>
      {content}
    </Container>
  );
}
