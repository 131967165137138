export function match(text, query) {
  if (!query) return [];
  const trimmedQuery = query.trim().toLowerCase();
  const textLower = text.toLowerCase();
  const queryLength = trimmedQuery.length;
  const indexOf = textLower.indexOf(trimmedQuery);
  return [[indexOf, indexOf + queryLength]];
}

/*
  Modified logic of match function from 'moroshko/autosuggest-highlight'
  Changed for highlighting anywhere in the text
*/

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
const specialCharsRegex = /[.*+?^${}()|[\]\\]/g;

const whitespacesRegex = /\s+/;

function escapeRegexCharacters(str) {
  return str.replace(specialCharsRegex, '\\$&');
}

export function matchGlobal(text, query) {
  // eslint-disable-next-line no-unused-vars
  let textT = text;
  return (
    query
      .trim()
      .split(whitespacesRegex)
      // If query is blank, we'll get empty string here, so let's filter it out.
      .filter((word) => word.length > 0)
      .reduce((result, word) => {
        const wordLen = word.length;
        const index = text.toLowerCase().search(escapeRegexCharacters(word).toLowerCase());

        if (index > -1) {
          result.push([index, index + wordLen]);

          // Replace what we just found with spaces so we don't find it again.
          textT = text.slice(0, index)
          + new Array(wordLen + 1).join(' ')
          + text.slice(index + wordLen);
        }

        return result;
      }, [])
      .sort((match1, match2) => match1[0] - match2[0])
  );
}

/*
  Original parse function from 'moroshko/autosuggest-highlight'
  Changed style to satisfy to the eslint rules
*/
export function parse(text, matches) {
  const result = [];

  if (matches.length === 0) {
    result.push({
      text,
      highlight: false,
    });
  } else if (matches[0][0] > 0) {
    result.push({
      text: text.slice(0, matches[0][0]),
      highlight: false,
    });
  }

  matches.forEach((m, i) => {
    const startIndex = m[0];
    const endIndex = m[1];

    result.push({
      text: text.slice(startIndex, endIndex),
      highlight: true,
    });

    if (i === matches.length - 1) {
      if (endIndex < text.length) {
        result.push({
          text: text.slice(endIndex, text.length),
          highlight: false,
        });
      }
    } else if (endIndex < matches[i + 1][0]) {
      result.push({
        text: text.slice(endIndex, matches[i + 1][0]),
        highlight: false,
      });
    }
  });

  return result;
}
