import axios from 'axios';

import { getBoUserRole } from '../../selectors/index';
import roles from '../../constants/backofficeRoles';
import { authorizationFail } from '../authActions';
import {
  USER_GET,
  USER_GET_SUCCESS,
  USER_GET_FAIL,
  USER_ERROR_REMOVE,
  USER_SAVE,
  USER_SAVE_SUCCESS,
  USER_SAVE_FAIL,
  USER_LOGO_UPLOAD,
  USER_LOGO_UPLOAD_SUCCESS,
  USER_LOGO_UPLOAD_FAIL,
  USER_LOGO_UPLOADED_REMOVE,
  USER_CLEAR,
  USER_CHECKING_CUSTOMER_START,
  USER_CHECKING_CUSTOMER_SUCCESS,

  ERRORS,
  maxLogoFileSizeUM,
  fileLogoFormatsUM,
  USER_ERROR_SET,
} from '../../constants';
import errorHandler, { addError } from '../requestErrorHandler';
import { updateComments } from '../chatActions';

export const fetchUserFail = (errCode) => ({
  type: USER_GET_FAIL,
  payload: errCode,
});

export const fetchUserSuccess = (USER) => ({
  type: USER_GET_SUCCESS,
  payload: USER,
});

export const startFetchingUser = () => ({
  type: USER_GET,
});

export const saveUserFail = (errCode) => ({
  type: USER_SAVE_FAIL,
  payload: errCode,
});

export const saveUserSuccess = (user) => ({
  type: USER_SAVE_SUCCESS,
  payload: user,
});

export const startSaveUser = () => ({
  type: USER_SAVE,
});

export const uploadUserLogoFail = (errCode) => ({
  type: USER_LOGO_UPLOAD_FAIL,
  payload: errCode,
});

export const uploadUserLogoSuccess = (files) => ({
  type: USER_LOGO_UPLOAD_SUCCESS,
  payload: files,
});

export const startUploadUserLogo = () => ({
  type: USER_LOGO_UPLOAD,
});

export const removeUploadedLogo = () => ({
  type: USER_LOGO_UPLOADED_REMOVE,
});

export const setUserError = (errorCode) => ({
  type: USER_ERROR_SET,
  payload: errorCode,
});

export const removeUserErrorCode = () => ({
  type: USER_ERROR_REMOVE,
});

export const clearUserStore = () => ({
  type: USER_CLEAR,
});

export const startCheckingCustomer = () => ({
  type: USER_CHECKING_CUSTOMER_START,
});

export const checkingCustomerSuccess = (customer) => ({
  type: USER_CHECKING_CUSTOMER_SUCCESS,
  payload: customer,
});

export function fetchUser(userId) {
  return (dispatch) => {
    dispatch(startFetchingUser());

    axios(`/rest/default/V1/eplane/backoffice/user/${userId}`).then((response) => {
      dispatch(fetchUserSuccess(response.data));
    }).catch((e) => errorHandler(e.response, dispatch, fetchUserFail, e));
  };
}

export const saveUser = (userId, saveData) => (dispatch, getState) => {
  if (getBoUserRole(getState()) === roles.VIEWER) {
    dispatch(addError(ERRORS.ERR_PERMISSION_DENIED));
    return;
  }

  dispatch(startSaveUser());

  axios.post(`/rest/default/V1/eplane/backoffice/user/${userId}`, { req: saveData }).then((response) => {
    dispatch(saveUserSuccess(response.data));
    dispatch(updateComments());
  }).catch((e) => errorHandler(e.response, dispatch, saveUserFail, e));
};

export function uploadUserLogo(userId, logoFile) {
  if (!userId || !logoFile) {
    console.error('userId and logoFile wasn\'t specified!'); // eslint-disable-line no-console
    return;
  }
  return (dispatch) => { // eslint-disable-line consistent-return
    if (!fileLogoFormatsUM.some((mimeType) => mimeType === logoFile.type)) {
      return dispatch(setUserError(ERRORS.ERR_WRONG_FORMAT));
    }
    if (logoFile.size > maxLogoFileSizeUM) {
      return dispatch(setUserError(ERRORS.ERR_FILE_IS_TOO_LARGE));
    }

    dispatch(startUploadUserLogo());

    const formData = new FormData(); // eslint-disable-line no-undef
    formData.append('user_id', userId);
    formData.append('files', logoFile);

    axios.post('/backoffice/users/uploadLogo', formData, { headers: { 'content-type': 'multipart/form-data' } }).then((response) => {
      const uploadedLogoFile = response.data.files[0];
      dispatch(uploadUserLogoSuccess(uploadedLogoFile));
    }).catch((e) => {
      if (e.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { status } = e.response;
        if (status === 403) dispatch(authorizationFail());
        else dispatch(uploadUserLogoFail(e.response.data));
      } else if (e.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser
        dispatch(uploadUserLogoFail('Network issue. Or server has not replied.'));
      } else {
        // Something happened in setting up the request that triggered an Error
        dispatch(uploadUserLogoFail(e && e.message ? e.message : 'Something went wrong...'));
      }
    });
  };
}

export const checkCustomer = (userId, customerId) => async (dispatch) => {
  dispatch(startCheckingCustomer());
  try {
    const response = await axios(`/rest/default/V1/eplane/backoffice/customer/${customerId}`);
    if (response.data && response.data.user_id === userId) {
      dispatch(checkingCustomerSuccess(response.data));
    } else {
      dispatch(setUserError(ERRORS.ERR_ENTITY_NOT_FOUND));
    }
  } catch (e) {
    errorHandler(e.response, dispatch, setUserError, e);
  }
};
