import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { ButtonContainer, Button } from '../styledComponents';

const Text = styled.div`
  font-size: 20px;
  text-align: center;
  padding: 40px 20px;
  width: 100%;
`;

const ConfirmationModal = ({
  onHide,
  confirm,
  confirmBtnText,
  cancelBtnText,
  children,
  ...other
}) => (
  <Modal centered onHide={onHide} {...other}>
    <Modal.Header closeButton>
      <Text>{children}</Text>
    </Modal.Header>
    <Modal.Footer>
      <ButtonContainer btnWidth="120px" space="10px">
        <Button variant="primary" onClick={confirm}>{confirmBtnText}</Button>
        <Button variant="outline-secondary" onClick={onHide}>{cancelBtnText}</Button>
      </ButtonContainer>
    </Modal.Footer>
  </Modal>
);

ConfirmationModal.defaultProps = {
  confirmBtnText: 'YES',
  cancelBtnText: 'CANCEL',
};

ConfirmationModal.propTypes = {
  onHide: PropTypes.func,
  confirm: PropTypes.func,
  children: PropTypes.node,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
};

export default ConfirmationModal;
