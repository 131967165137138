import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { getLastQuoteLBI } from '../../../selectors/rfq';
import LowBidIndicator from './LowBidIndicator';
import { DocSectionContainer, Section, Title } from './DocSection';
import { color } from '../../../constants';
import { priceTypes } from '../../../constants/index';

const LowBidSectionContainer = styled(DocSectionContainer)`
  margin-bottom: 1.1rem;
`;
const Price = styled.div`
  font-size: 1.2rem;
  color: ${color.darkGrey};
  font-weight: bold;
  margin-bottom: 5px;
`;

export default function LowBidSection() {
  const lbiData = useSelector(getLastQuoteLBI);

  if (!lbiData) return null;

  let text = null;
  if (lbiData.price_type === priceTypes.PER_UNIT) {
    text = `Price (per unit): ${lbiData.price} ${lbiData.currency}`;
  } else if (lbiData.price_type === priceTypes.FEE) {
    text = `Fee (per unit): ${lbiData.price} ${lbiData.currency}`;
  } else if (lbiData.price_type === priceTypes.EVALUATION) {
    text = `Evaluation: ${lbiData.price} ${lbiData.currency}`;
  } else if (lbiData.price_type === priceTypes.REPAIR) {
    text = `Repair: ${lbiData.price} ${lbiData.currency}`;
  } else if (lbiData.price_type === priceTypes.TEST) {
    text = `Test: ${lbiData.price} ${lbiData.currency}`;
  } else if (lbiData.price_type === priceTypes.OVERHAUL) {
    text = `Overhaul: ${lbiData.price} ${lbiData.currency}`;
  }

  return (
    <LowBidSectionContainer>
      <Title>LATEST QUOTE INFO</Title>
      <Section>
        <Price>{text}</Price>
        {lbiData.is_display && (
          <LowBidIndicator
            value={lbiData.p}
            multipleGroups={lbiData.multiple}
            isLive={lbiData.is_live}
          />
        )}
      </Section>
    </LowBidSectionContainer>
  );
}
