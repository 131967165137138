import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import trashCartImage from '../../../assets/trash-cart-127x175.png';
import { Text, ButtonsWrapper, Button } from '../styledComponents';

const TrashCanImg = styled.div`
  background: url(${trashCartImage}) no-repeat 0 0;
  width: 127px;
  height: 175px;
  margin: 40px auto 0;
  position: relative;
  left: -7px;
`;

/*
  Be careful during refactoring:
  attached file cannot be removed in first try, but in second try it's removed without problems
*/

const RemoveDocumentModal = (props) => {
  const preservedProps = useRef(null);
  const { show } = props;

  useEffect(() => {
    if (preservedProps?.current?.show && !show) {
      preservedProps.current = { ...props };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onExited = () => {
    const { onExited: onExitedProps } = props;
    if (onExitedProps) onExitedProps();
    preservedProps.current = null;
  };

  const {
    onHide,
    isRemoving,
    onRemove,
    text,
    textRemoving = 'Removing...',
    textRemove = 'Remove',
    ...other
  } = preservedProps.current || props;
  return (
    <Modal centered onHide={onHide} {...other} show={show} onExited={onExited}>
      <Modal.Body>
        <TrashCanImg />
        <Text>
          {text}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsWrapper>
          <Button
            variant="primary"
            onClick={onRemove}
            disabled={isRemoving}
          >
            {isRemoving ? textRemoving : textRemove}
          </Button>
          <Button onClick={onHide} variant="outline-secondary">Cancel</Button>
        </ButtonsWrapper>
      </Modal.Footer>
    </Modal>
  );
};

RemoveDocumentModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onRemove: PropTypes.func,
  onExited: PropTypes.func,
  isRemoving: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default RemoveDocumentModal;
