import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

import FormTitleSection, { FormSubTitle } from '../../FormComponents/TitleSection';
import { TabContainer } from '../../StyledComponents';
import FormActionButtons from '../../FormComponents/ActionButtons';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import Loader from '../../Loader';
import { fetchUserSettings } from '../../../actions/companiesManagement/userSettingsActions';
import { fetchCompanyCustomerList } from '../../../actions/companiesManagement/companyCustomerActions';
import { fetchCompanyList } from '../../../actions/companyListActions';
import {
  fetchCustomWeights, saveCustomWeights, attachDetachList, fetchAttachedLists,
} from '../../../actions/companiesManagement/weightsActions';
import { fetchWeightGroups } from '../../../actions/settings/weightGroups';

import {
  getUserStore,
  getCompanyListStore,
  getWeightGroupsStore,
  getWeightsStore,
} from '../../../selectors';
import ModalLoader from '../../ModalLoader';
import { companyWeightValidator } from './validators';
import CompanyWeightForm from './CompanyWeightForm';
import UploadCloneForm from './UploadCloneForm';
import DetachForm from './DetachForm';
import textTruncate from '../../../utils/textTruncate';
import { PUBLIC_URL, color } from '../../../constants';

import SelectWithButton from './SelectWithButton';
import A from '../../A';
import { userType } from '../../../constants/index';

const createValidationObj = (state, helpText) => ({
  validationState: state,
  helpText,
});
const LinkMessage = styled.p`
  & a {
    color: ${color.darkBirch};
  }
  & a:hover {
    text-decoration: underline;
    color: ${color.birch};
  }
`;

const cache = new Map();

const WeightManagementPage = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();

  const {
    weightGroups,
    isFetching: isGroupsFetching,
    isProcessing: isGroupsWeights,
  } = useSelector(getWeightGroupsStore);
  const {
    customWeights: customWeightsStore,
    attachedLists,
    isFetching: isFetchingCustomWeights,
    isProcessing: isProcessingWeights,
  } = useSelector(getWeightsStore);
  const { companies } = useSelector(getCompanyListStore);
  const { user } = useSelector(getUserStore);

  const [customWeights, setCustomWeights] = useState([]);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [attach, setAttach] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const isCompanyUser = user && (user.user_type === userType.COMPANY.id);

  const isFetching = isFetchingCustomWeights || isGroupsFetching;
  const isProcessing = isGroupsWeights || isProcessingWeights;

  const attachOptions = useMemo(
    () => weightGroups && weightGroups.filter((v) => attachedLists.every((l) => l.id !== v.id))
      .map((v) => ({ label: v.list_name, value: v.id })),
    [attachedLists, weightGroups],
  );
  const detachOptions = useMemo(
    () => attachedLists && attachedLists.map((v) => ({ label: v.list_name, value: v.id })),
    [attachedLists],
  );

  useEffect(() => {
    dispatch(fetchCompanyCustomerList(userId));
    dispatch(fetchUserSettings(userId));

    dispatch(fetchAttachedLists(userId));
    dispatch(fetchCustomWeights(userId));
    dispatch(fetchWeightGroups());
    dispatch(fetchCompanyList());
  }, [dispatch, userId]);

  useEffect(() => {
    setCustomWeights(customWeightsStore);
  }, [customWeightsStore]);

  const onAddCompanyBtnClick = () => {
    const lastItem = customWeights[customWeights.length - 1];
    if (customWeights.length !== 0 && !lastItem.name && !lastItem.seller_weight) return;

    setCustomWeights([
      ...customWeights,
      { seller_id: null, seller_weight: null },
    ]);
  };

  const onDeleteCompanyBtbClick = (id) => () => {
    if (customWeights.length < 1) return;

    const newData = customWeights.map((v) => {
      // clear error validation, warning validation are left
      if (v.validation && v.validation.validationState === 'error') {
        return { ...v, validation: null };
      }
      return { ...v };
    });

    newData.splice(id, 1);

    setCustomWeights(newData);
  };

  const onDeleteAllClick = () => setCustomWeights([]);

  const onCompanyFocusout = (name, value, index) => {
    const searchValue = companies.find((v) => v.company_name === value);

    if (searchValue) {
      const companyId = searchValue.company_id;
      if (cache.has(companyId)) {
        const acceptRFQ = cache.get(companyId);
        setValidationState(index, searchValue, acceptRFQ);
      } else {
        axios(`/rest/default/V1/eplane/backoffice/user/${companyId}/settings`).then((response) => {
          const showWarning = !response.data.receive_all_demands_enabled;
          cache.set(companyId, showWarning);
          setValidationState(index, searchValue, showWarning);
        });
      }
    }
  };

  const onSave = () => {
    onHideConfirmationModal();

    let changes = customWeights;
    let validationFlag = false;

    if (!dataIsValid()) return;

    changes = changes.map((elem, index) => {
      const item = companies.find((obj) => obj.company_name === elem.name);

      const finalChanges = {
        seller_id: item && item.company_id,
        seller_weight: elem.seller_weight,
      };

      let errorText = null;
      if (!elem.name) errorText = 'Please choose a company';
      else if (!item) {
        // save text in company input
        finalChanges.name = elem.name;
        errorText = 'Company doesn\'t exist';
      } else if (changes.findIndex((v, i) => v.name === item.company_name && index !== i) >= 0) {
        errorText = 'You have already added this company';
      } else if (userId === finalChanges.seller_id) {
        errorText = 'You cannot add yourself';
      }

      if (errorText) {
        validationFlag = true;
        finalChanges.validation = createValidationObj('error', errorText);
      }

      return finalChanges;
    });

    if (validationFlag) {
      // copy warnings from state in case there isn't error validation object
      changes = changes.map((v) => {
        if (!v.validation) {
          const item = customWeights.find((obj) => obj.seller_id === v.seller_id);
          return { ...v, validation: item && item.validation };
        }
        return v;
      });

      setCustomWeights(changes);
      return;
    }

    setEditMode(false);
    dispatch(saveCustomWeights({
      user_id: userId,
      sellers_weights: changes,
    }));

    setEditMode(false);
  };

  const onCompanyWeightChange = (name, value, index) => {
    const newData = customWeights.map((v) => {
      // clear error validation, warning validation are left
      if (v.validation && v.validation.validationState === 'error') {
        return { ...v, validation: null };
      }
      return { ...v };
    });
    newData[index][name] = value;

    setCustomWeights(newData);
  };

  const setValidationState = (index, company, showWarning) => {
    if (!customWeights.length) {
      setCustomWeights([]);
    }

    const indexCheck = index < customWeights.length;
    const hasValidObj = customWeights[index].validation && customWeights[index].validation.validationState !== 'warning';
    const newData = customWeights.map((v) => ({ ...v }));

    // if company has validation obj do not replace with new warning validation obj
    if (indexCheck && !hasValidObj && newData[index].name === company.company_name) {
      newData[index].seller_id = company.company_id;
      newData[index].validation = showWarning
        ? createValidationObj('warning', `Warning: ${textTruncate(company.company_name)} doesn't accept Tier3 RFQs`)
        : createValidationObj('success');
    }

    setCustomWeights(newData);
  };

  const dataIsValid = () => !customWeights.some((v) => companyWeightValidator(v.seller_weight) !== null);

  const onHideConfirmationModal = () => setConfirmationModal(false);

  const attachHandler = () => {
    if (!attach) return;

    setAttach(null);
    dispatch(attachDetachList({
      user_id: userId,
      list_ids: attach.map((v) => v.value),
    }));
  };

  const detachHandler = (listId) => {
    const data = {
      user_id: userId,
      list_ids: [listId],
    };
    dispatch(attachDetachList(data, true));
  };

  const toggleEditMode = () => {
    setCustomWeights(customWeightsStore);
    setEditMode(!editMode);
  };

  if (isFetching) return <Loader />;

  const title = isCompanyUser ? 'Tier 3 lists management' : "Warning! This user doesn't belong to any approved Company";
  const saveBtnProps = { disabled: !editMode || !dataIsValid() };

  return (
    <div>
      <FormTitleSection title={title} showEditButton={false} />

      <TabContainer marginTop="10px">
        <FormSubTitle title="TIER 3 lists" showEditButton={false} />
        <br />
        <LinkMessage>
          To handle Tier 3 lists press&nbsp;
          <A rel="noopener noreferrer" to={`${PUBLIC_URL}/eplaneSettings/companyGroup`}>here</A>
          .
        </LinkMessage>
        <SelectWithButton
          placeholder="Select lists..."
          selectValue={attach}
          selectOptions={attachOptions}
          onSelectChange={(selectedOption) => setAttach(selectedOption)}
          onButtonClick={attachHandler}
          buttonText="Attach"
          disabled={false}
          name="attach"
        />

        <br />
        <DetachForm
          data={detachOptions}
          onDetachHandler={detachHandler}
          disabled={false}
        />

        <FormSubTitle
          title="Custom weights"
          EditButtonClick={toggleEditMode}
          showEditButton={!editMode}
        />
        <UploadCloneForm
          userId={userId}
          disabled={!editMode}
          blockCloneButton={!customWeightsStore || customWeightsStore.length === 0}
        />
        <br />

        <CompanyWeightForm
          companies={customWeights}
          suggestion={companies}
          disabled={!editMode}
          onChange={onCompanyWeightChange}
          onAddCompanyBtnClick={onAddCompanyBtnClick}
          onDeleteCompanyBtbClick={onDeleteCompanyBtbClick}
          onDeleteAllClick={onDeleteAllClick}
          onCompanyFocusout={onCompanyFocusout}
        />

        {editMode && (
          <>
            <hr />
            <FormActionButtons
              saveProps={saveBtnProps}
              saveHandler={() => setConfirmationModal(true)}
              cancelHandler={toggleEditMode}
              width="80"
            />
          </>
        )}

        <ConfirmationModal
          show={showConfirmationModal}
          confirm={onSave}
          onHide={onHideConfirmationModal}
        >
          Are you sure you want to apply the changes?
        </ConfirmationModal>

        <ModalLoader show={isProcessing} />
      </TabContainer>
    </div>
  );
};

export default WeightManagementPage;
