import axios from 'axios';

import {
  API_PERMISSIONS_GET,
  API_PERMISSIONS_GET_SUCCESS,
  API_PERMISSIONS_GET_SAVE,
  API_PERMISSIONS_SAVE_SUCCESS,

  INTEGRATION_GET,
  INTEGRATION_GET_SUCCESS,
  INTEGRATION_SAVE,
  INTEGRATION_SAVE_SUCCESS,

  GENERATE_START,
  GENERATE_SUCCESS,
  REVOKE_START,
  REVOKE_SUCCESS,

  SUPPLIER_CODES_GET,
  SUPPLIER_CODES_GET_SUCCESS,
  SUPPLIER_CODE_ADD,
  SUPPLIER_CODE_ADD_SUCCESS,
  SUPPLIER_CODE_EDIT,
  SUPPLIER_CODE_EDIT_SUCCESS,
  SUPPLIER_CODE_DELETE,
  SUPPLIER_CODE_DELETE_SUCCESS,

  CLEAR_SPEC2K,
  SPEC2K_FAIL,
  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startFetchApiPermissions = () => ({ type: API_PERMISSIONS_GET });
const fetchApiPermissionsSuccess = (settings) => ({
  type: API_PERMISSIONS_GET_SUCCESS,
  payload: settings,
});

const startSaveApiPermissions = () => ({ type: API_PERMISSIONS_GET_SAVE });
const saveApiPermissionsSuccess = (settings) => ({
  type: API_PERMISSIONS_SAVE_SUCCESS,
  payload: settings,
});

const startFetchIntegration = () => ({ type: INTEGRATION_GET });
const fetchIntegrationSuccess = (settings) => ({
  type: INTEGRATION_GET_SUCCESS,
  payload: settings,
});

const startSaveIntegration = () => ({ type: INTEGRATION_SAVE });
const saveIntegrationSuccess = (settings) => ({
  type: INTEGRATION_SAVE_SUCCESS,
  payload: settings,
});

const startGenerate = () => ({ type: GENERATE_START });
const generateSuccess = (settings) => ({
  type: GENERATE_SUCCESS,
  payload: settings,
});

const startRevoke = () => ({ type: REVOKE_START });
const revokeSuccess = (settings) => ({
  type: REVOKE_SUCCESS,
  payload: settings,
});

const startFetchingSupplierCodes = () => ({ type: SUPPLIER_CODES_GET });
const fetchSupplierCodesSuccess = (codes) => ({
  type: SUPPLIER_CODES_GET_SUCCESS,
  payload: codes,
});

const startSaveSupplierCodes = () => ({ type: SUPPLIER_CODE_EDIT });
const saveSupplierCodesSuccess = (codes) => ({
  type: SUPPLIER_CODE_EDIT_SUCCESS,
  payload: codes,
});

const startAddCode = () => ({ type: SUPPLIER_CODE_ADD });
const addCodeSuccess = (data) => ({
  type: SUPPLIER_CODE_ADD_SUCCESS,
  payload: data,
});

const startDeleteCode = () => ({ type: SUPPLIER_CODE_DELETE });
const deleteCodeSuccess = (deletedId) => ({
  type: SUPPLIER_CODE_DELETE_SUCCESS,
  payload: deletedId,
});

const spec2kFail = () => ({ type: SPEC2K_FAIL });

export const clearSpec2kStore = () => ({ type: CLEAR_SPEC2K });

export const fetchApiPermissions = (userId) => async (dispatch) => {
  dispatch(startFetchApiPermissions());
  try {
    const response = await axios(`/rest/V2/eplane/backoffice/user/${userId}/api_permissions`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchApiPermissionsSuccess(response.data.payload.api_permissions));
    } else {
      requestErrorHandler(response, dispatch, spec2kFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, spec2kFail, e);
  }
};

export const saveApiPermissions = (data) => async (dispatch) => {
  dispatch(startSaveApiPermissions());
  try {
    const response = await axios.post('/rest/V2/eplane/backoffice/api_permissions', data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveApiPermissionsSuccess(response.data && response.data.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, spec2kFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, spec2kFail, e);
  }
};

export const fetchIntegration = (userId) => async (dispatch) => {
  dispatch(startFetchIntegration());
  try {
    const response = await axios(`/rest/V2/eplane/backoffice/user/${userId}/integration_settings`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchIntegrationSuccess(response.data && response.data.payload.integration_settings));
    } else {
      requestErrorHandler(response, dispatch, spec2kFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, spec2kFail, e);
  }
};

export const addIntegration = (userId, data) => async (dispatch) => {
  dispatch(startSaveIntegration());
  try {
    const response = await axios.post(`/rest/V2/eplane/backoffice/user/${userId}/integration_settings`, data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveIntegrationSuccess(response.data && response.data.payload.integration_settings));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, spec2kFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, spec2kFail, e);
  }
};

export const editIntegration = (settingsId, data) => async (dispatch) => {
  dispatch(startSaveIntegration());
  try {
    const response = await axios.post(`/rest/V2/eplane/backoffice/integration_settings/${settingsId}`, data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveIntegrationSuccess(response.data && response.data.payload.integration_settings));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, spec2kFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, spec2kFail, e);
  }
};

export const generate = (userId) => async (dispatch) => {
  dispatch(startGenerate());
  try {
    const response = await axios.get(`/rest/V2/eplane/backoffice/user/${userId}/api_permissions/generate`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(generateSuccess(response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, spec2kFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, spec2kFail, e);
  }
};

export const revoke = (userId, permissionId) => async (dispatch) => {
  dispatch(startRevoke());
  try {
    const response = await axios.get(`/rest/V2/eplane/backoffice/user/${userId}/api_permissions/${permissionId}/revoke`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(revokeSuccess(response.data.payload && response.data.payload.api_permissions));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, spec2kFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, spec2kFail, e);
  }
};

export const fetchSupplierCodes = (userId) => async (dispatch) => {
  dispatch(startFetchingSupplierCodes());
  try {
    const response = await axios(`/rest/V2/eplane/backoffice/user/${userId}/supplier_codes`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchSupplierCodesSuccess(response.data?.payload));
    } else {
      requestErrorHandler(response, dispatch, spec2kFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, spec2kFail, e);
  }
};

export const addSupplierCode = (data) => async (dispatch) => {
  dispatch(startSaveSupplierCodes());
  try {
    const response = await axios.post('/rest/V2/eplane/backoffice/supplier_codes', data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveSupplierCodesSuccess(response.data?.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, spec2kFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, spec2kFail, e);
  }
};

export const editSupplierCode = (codeId, data) => async (dispatch) => {
  dispatch(startAddCode());
  try {
    const response = await axios.post(`/rest/V2/eplane/backoffice/supplier_codes/${codeId}`, data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(addCodeSuccess(response.data?.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, spec2kFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, spec2kFail, e);
  }
};

export const deleteSupplierCode = (userId, codeId) => async (dispatch) => {
  dispatch(startDeleteCode());
  try {
    const response = await axios.delete(`/rest/V2/eplane/backoffice/user/${userId}/supplier_codes/${codeId}`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(deleteCodeSuccess(response.data?.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, spec2kFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, spec2kFail, e);
  }
};
