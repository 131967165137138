import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Modal, Row, Col, Container, FormLabel,
} from 'react-bootstrap';

import { ButtonsWrapper, Button, Text } from '../styledComponents';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import { emailValidator, notEmptyValidatorCreator } from './validators';

const StyledText = styled.p`
  text-align: center;
`;
const StyledLabel = styled(FormLabel)`
  font-weight: bold;
  text-align: right;
`;

const AddSellerModal = ({
  unregisteredSeller, onChange, onHide, onConfirm, ...other
}) => (
  <Modal centered onHide={onHide} {...other}>
    <Modal.Body>
      <Text>Add seller</Text>
      <br />
      <Container>
        <Row>
          <StyledLabel column sm={5}>
            Seller:
          </StyledLabel>
          <Col sm={7}>
            <BaseFormGroup
              value={unregisteredSeller.name}
              name="name"
              type="email"
              placeholder="Enter seller's name"
              onChange={onChange}
              {...notEmptyValidatorCreator(unregisteredSeller.name)}
            />
          </Col>
        </Row>

        <Row>
          <StyledLabel column sm={5}>
            Contact person&apos;s email:
          </StyledLabel>
          <Col sm={7}>
            <BaseFormGroup
              value={unregisteredSeller.email}
              type="email"
              name="email"
              placeholder="Enter email address"
              onChange={onChange}
              {...emailValidator(unregisteredSeller.email)}
            />
          </Col>
        </Row>
      </Container>
      <StyledText>We will notify the seller to let him know that he has been added to your custom list.</StyledText>
    </Modal.Body>
    <Modal.Footer>
      <ButtonsWrapper>
        <Button
          variant="primary"
          onClick={onConfirm}
          disabled={notEmptyValidatorCreator(unregisteredSeller.name) || emailValidator(unregisteredSeller.email)}
        >
          Add
        </Button>
        <Button variant="outline-secondary" onClick={onHide}>Cancel</Button>
      </ButtonsWrapper>
    </Modal.Footer>
  </Modal>
);

AddSellerModal.propTypes = {
  unregisteredSeller: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AddSellerModal;
