import axios from 'axios';

import {
  DOCUMENT_LIST_FETCH,
  DOCUMENT_LIST_IS_FETCHED,
  DOCUMENT_UPLOADING_START,
  DOCUMENT_UPLOADING_FINISH,
  DOCUMENT_REMOVE,
  DOCUMENT_LIST_ERROR_OCCURRED,
  DOCUMENT_LIST_ERROR_REMOVE,
  DOCUMENT_UPLOADING_PROGRESS_SET,
  DOCUMENT_UPLOADING_TERMINATE,

  ERRORS,
  AXIOS_CANCELLED_MESSAGE,
  fileFormatsUM,
  maxFileSizeUM,
} from '../../constants';
import errorHandler from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startFetching = () => ({
  type: DOCUMENT_LIST_FETCH,
});
const documentListIsFetched = (documentList) => ({
  type: DOCUMENT_LIST_IS_FETCHED,
  payload: documentList,
});

const removeDocument = (docId) => ({
  type: DOCUMENT_REMOVE,
  payload: docId,
});

const startUploadingDocument = (source) => ({
  type: DOCUMENT_UPLOADING_START,
  payload: source,
});
const finishUploadingDocument = (document) => ({
  type: DOCUMENT_UPLOADING_FINISH,
  payload: document,
});

const setUploadingProgress = (percentOfUpload) => ({
  type: DOCUMENT_UPLOADING_PROGRESS_SET,
  payload: percentOfUpload,
});

export const terminateUploadingProgress = () => ({
  type: DOCUMENT_UPLOADING_TERMINATE,
});

export const setErrorCode = (errorCode) => ({
  type: DOCUMENT_LIST_ERROR_OCCURRED,
  payload: errorCode,
});

export const removeErrorCode = () => ({
  type: DOCUMENT_LIST_ERROR_REMOVE,
});

export const fetchDocuments = (userId) => (dispatch) => {
  dispatch(startFetching());
  axios(`/rest/default/V1/eplane/backoffice/user/${userId}/document`).then((response) => (
    dispatch(documentListIsFetched(response.data))
  )).catch((e) => errorHandler(e.response, dispatch, setErrorCode, e));
};

export const deleteDocument = (userId, docId) => (dispatch) => {
  axios.delete(`/rest/default/V1/eplane/backoffice/user/${userId}/document/${docId}`).then(() => {
    dispatch(removeDocument(docId));
    dispatch(updateComments());
  }).catch((e) => deleteErrorHandler(e, dispatch));
};

const deleteErrorHandler = (e, dispatch) => {
  if (e.message === AXIOS_CANCELLED_MESSAGE) {
    console.warn(AXIOS_CANCELLED_MESSAGE); // eslint-disable-line no-console
    return;
  }
  errorHandler(e.response, dispatch, setErrorCode, e);
};

const onUploadProgress = (progressEvent, dispatch) => {
  const { loaded, total } = progressEvent;
  dispatch(setUploadingProgress((loaded * 100) / total));
};

// Add progress
/**
 *
 * @param {File} file
 * @param {string|number} userID
 * @param {number} docType
 */
export const uploadDocumentToUM = (file, userId, docType) => (dispatch) => {
  if (fileFormatsUM.every((mimeType) => mimeType !== file.type)) {
    dispatch(setErrorCode(ERRORS.ERR_WRONG_FORMAT));
    return;
  }
  if (file.size >= maxFileSizeUM) {
    dispatch(setErrorCode(ERRORS.ERR_FILE_IS_TOO_LARGE));
    return;
  }

  const source = axios.CancelToken.source();
  dispatch(startUploadingDocument(source));

  const fd = new FormData(); // eslint-disable-line no-undef
  fd.append('document', file);
  fd.append('doc_type', docType);
  fd.append('user_id', userId);
  const options = {
    onUploadProgress: (e) => onUploadProgress(e, dispatch),
    cancelToken: source.token,
  };

  axios.post('/backoffice/users/uploadDocument', fd, options).then((response) => {
    dispatch(finishUploadingDocument(response.data));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, setErrorCode, e);
  });
};
