import dateIntervalToString from '../../utils/dateIntervalToString';

export default function mapRequests(reqListFromServer) {
  if (!reqListFromServer) return [];
  return reqListFromServer?.map((req) => {
    const { customer, user } = req;
    return {
      // common data
      entityId: req.entity_id,
      type: 'Register Company',
      createdAt: req.created_at,
      updatedAt: req.updated_at,
      openFor: dateIntervalToString(req.created_at),
      userName: customer && (`${customer.firstname} ${customer.lastname}`),
      userEmail: customer && (customer.email),
      userEmailIsVerified: customer && (!customer.need_confirmation),
      companyName: req.company_name,

      // additional fields for closed table
      userId: req.user_id,
      customerId: req.customer_id,
      companyUserId: req.user_id,
      userCompany: (user && user.company_name), // ?
      resultId: req.result,
      result: req.result,
      boUser: req.admin_customer_email,
      comment: req.comment,
    };
  });
}
