import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledLinkButton as LinkButton } from '../../StyledComponents';
import dateFormat from '../../../utils/dateFormat';
import { HorizontalLine } from '../StyleComponents';
import { color, API_URL } from '../../../constants';

const Link = styled.span`
  color: ${color.birch};
`;

export default function lastPnInfo(data, userId) {
  return (
    <div>
      <p>
        Last uploaded PN list is&nbsp;
        {/* TODO: resolve ESLINT issue */}
        { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
        <Link>{(data && data.file_name.split('/').pop()) || 'unnamed file'}</Link>
        , created at&nbsp;
        <strong>
          {(data.created_at && dateFormat(data.created_at)) || 'not specified'}
          &nbsp;
        </strong>
        with status&nbsp;
        <strong>{data.status || 'not specified'}</strong>
        .
      </p>
      <LinkButton variant="link" href={`${API_URL}/backoffice/users/downloadPnList?user_id=${userId}`}>
        <FontAwesomeIcon icon="download" />
        &nbsp;
        Download
      </LinkButton>
      <HorizontalLine />
    </div>
  );
}
