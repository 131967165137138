import axios from 'axios';

import {
  NEWS_LIST_FETCH,
  NEWS_LIST_IS_FETCHED,
  NEWS_UPLOADING_START,
  NEWS_UPLOADING_FINISH,
  NEWS_DELETING_START,
  NEWS_DELETING_FINISH,
  NEWS_REORDER,
  NEWS_REORDER_SUCCESS,
  NEWS_ERROR,

  FEATURED_IN_LIST_FETCH,
  FEATURED_IN_LIST_IS_FETCHED,
  FEATURED_IN_UPLOADING_START,
  FEATURED_IN_UPLOADING_FINISH,
  FEATURED_IN_DELETING_START,
  FEATURED_IN_DELETING_FINISH,
  FEATURED_IN_REORDER,
  FEATURED_IN_REORDER_SUCCESS,

  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';

const startFetchingNewsFeed = () => ({
  type: NEWS_LIST_FETCH,
});
const finishFetchingNewsList = (newsList) => ({
  type: NEWS_LIST_IS_FETCHED,
  payload: newsList,
});

const startUploadingNews = () => ({
  type: NEWS_UPLOADING_START,
});
const finishUploadingNews = (newsData) => ({
  type: NEWS_UPLOADING_FINISH,
  payload: newsData,
});

const startDeletingNews = () => ({
  type: NEWS_DELETING_START,
});
const finishDeletingNews = (data) => ({
  type: NEWS_DELETING_FINISH,
  payload: data,
});

const startReorderingNews = () => ({
  type: NEWS_REORDER,
});
const finishReorderingNews = (newsList) => ({
  type: NEWS_REORDER_SUCCESS,
  payload: newsList,
});

const startFetchingFeaturedIn = () => ({
  type: FEATURED_IN_LIST_FETCH,
});
const finishFetchingFeaturedIn = (list) => ({
  type: FEATURED_IN_LIST_IS_FETCHED,
  payload: list,
});

const startUploadingFeaturedIn = () => ({
  type: FEATURED_IN_UPLOADING_START,
});
const finishUploadingFeaturedIn = (newsData) => ({
  type: FEATURED_IN_UPLOADING_FINISH,
  payload: newsData,
});

const startDeletingFeaturedIn = () => ({
  type: FEATURED_IN_DELETING_START,
});
const finishDeletingFeaturedIn = (data) => ({
  type: FEATURED_IN_DELETING_FINISH,
  payload: data,
});

const startReorderingFeaturedIn = () => ({
  type: FEATURED_IN_REORDER,
});
const finishReorderingFeaturedIn = (list) => ({
  type: FEATURED_IN_REORDER_SUCCESS,
  payload: list,
});

const handleError = () => ({ type: NEWS_ERROR });

export const fetchNewsList = (p = 1) => async (dispatch) => {
  dispatch(startFetchingNewsFeed());
  const reqParams = {
    perPage: 50,
    page: p,
  };
  try {
    const response = await axios('rest/V2/eplane/backoffice/newsfeed', { params: reqParams });
    if (response.data.code === ERRORS.SUCCESS && response?.data?.payload?.news) {
      dispatch(finishFetchingNewsList({
        newsList: response.data.payload.news,
        page: response.data.payload.page,
        total: response.data.payload.total,
      }));
    } else {
      requestErrorHandler(response, dispatch, handleError);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, handleError, e);
  }
};

export const uploadNewsFeed = (newsData, update) => async (dispatch) => {
  dispatch(startUploadingNews());
  try {
    let response = null;
    if (update) {
      response = await axios.post('rest/V2/eplane/backoffice/newsfeed', newsData);
    } else {
      response = await axios.put('rest/V2/eplane/backoffice/newsfeed', newsData);
    }

    if (response.data.code === ERRORS.SUCCESS && response?.data?.payload) {
      dispatch(finishUploadingNews({
        newsList: response.data.payload.news,
        page: response.data.payload.page,
        total: response.data.payload.total,
      }));
    } else {
      requestErrorHandler(response, dispatch, handleError);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, handleError, e);
  }
};

export const deleteSelectedNewsFeed = (newsId) => async (dispatch) => {
  dispatch(startDeletingNews(newsId));
  try {
    const response = await axios.delete(`rest/V2/eplane/backoffice/newsfeed/${newsId}`);
    if (response) {
      dispatch(finishDeletingNews(newsId));
    } else {
      requestErrorHandler(response, dispatch, handleError);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, handleError, e);
  }
};

export const reorderNewsList = (newsData) => async (dispatch) => {
  dispatch(startReorderingNews());

  try {
    const response = await axios.post('rest/V2/eplane/backoffice/newsfeed/reorder', newsData);
    if (response.data.code === ERRORS.SUCCESS && response?.data?.payload?.news) {
      dispatch(finishReorderingNews({
        newsList: response.data.payload.news,
        page: response.data.payload.page,
        total: response.data.payload.total,
      }));
    } else {
      requestErrorHandler(response, dispatch, handleError);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, handleError, e);
  }
};

export const fetchFeaturedInList = (p = 1) => async (dispatch) => {
  dispatch(startFetchingFeaturedIn());
  const reqParams = {
    perPage: 50,
    page: p,
  };
  try {
    const response = await axios('rest/V2/eplane/backoffice/featured-in', { params: reqParams });
    if (response.data.code === ERRORS.SUCCESS && response?.data?.payload?.items) {
      dispatch(finishFetchingFeaturedIn({
        featuredIn: response.data.payload.items,
        page: response.data.payload.page,
        total: response.data.payload.total,
      }));
    } else {
      requestErrorHandler(response, dispatch, handleError);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, handleError, e);
  }
};

export const uploadFeaturedIn = (newsData, update) => async (dispatch) => {
  dispatch(startUploadingFeaturedIn());
  try {
    let response = null;
    if (update) {
      response = await axios.post('rest/V2/eplane/backoffice/featured-in/', newsData);
    } else {
      response = await axios.put('rest/V2/eplane/backoffice/featured-in/', newsData);
    }

    if (response.data.code === ERRORS.SUCCESS && response?.data?.payload) {
      dispatch(finishUploadingFeaturedIn({
        featuredIn: response.data.payload.items,
        page: response.data.payload.page,
        total: response.data.payload.total,
      }));
    } else {
      requestErrorHandler(response, dispatch, handleError);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, handleError, e);
  }
};

export const deleteSelectedFeaturedIn = (itemId) => async (dispatch) => {
  dispatch(startDeletingFeaturedIn(itemId));
  try {
    const response = await axios.delete(`rest/V2/eplane/backoffice/featured-in/${itemId}`);
    if (response) {
      dispatch(finishDeletingFeaturedIn(itemId));
    } else {
      requestErrorHandler(response, dispatch, handleError);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, handleError, e);
  }
};

export const reorderFeaturedIn = (featuredInList) => async (dispatch) => {
  dispatch(startReorderingFeaturedIn());

  try {
    const response = await axios.post('rest/V2/eplane/backoffice/featured-in/reorder', featuredInList);
    if (response.data.code === ERRORS.SUCCESS && response?.data?.payload.items) {
      dispatch(finishReorderingFeaturedIn({
        featuredIn: response.data.payload.items,
        page: response.data.payload.page,
        total: response.data.payload.total,
      }));
    } else {
      requestErrorHandler(response, dispatch, handleError);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, handleError, e);
  }
};
