import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { fetchSearchingOrders } from '../../actions/OrderManagement/SearchOrdersActions';
import { fetchCompanyList } from '../../actions/companyListActions';
import { getSearchOrdersStore, getCompanyListStore } from '../../selectors';
import Container from '../../components/Container';
import SearchOrderBar from '../../components/OrderManagement/SearchOrders/SearchOrderBar';
import OrderTable from '../../components/OrderManagement/SearchOrders/OrderTable';
import SearchInfo from '../../components/OrderManagement/SearchOrders/SearchInfo';
import Pagination from '../../components/OrderManagement/SearchOrders/PaginationTable';
import ModalLoader from '../../components/ModalLoader';
import { PUBLIC_URL } from '../../constants';
import { orderStatus as orderStatusConstants } from '../../constants/index';

export default function SearchOrders() {
  const prevSearch = useRef(null);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const {
    currItems: data,
    totalRecords, lastPage,
    isFetching,
    isSearching,
  } = useSelector(getSearchOrdersStore);
  const { companies } = useSelector(getCompanyListStore);

  useEffect(() => {
    dispatch(fetchCompanyList());
    if (location.search) {
      const query = queryString.parse(location.search);
      dispatch(fetchSearchingOrders(getSearchParamsFromQuery(query)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const nextQuery = queryString.parse(location.search);
    const currQuery = queryString.parse(prevSearch.current);
    if (nextQuery.orderID !== currQuery.orderID
      || nextQuery.purchaseOrder !== currQuery.purchaseOrder
      || nextQuery.company !== currQuery.company
      || nextQuery.email !== currQuery.email
      || nextQuery.partNumber !== currQuery.partNumber
      || nextQuery.status !== currQuery.status
      || nextQuery.from !== currQuery.from
      || nextQuery.to !== currQuery.to
      || nextQuery.page !== currQuery.page) {
      dispatch(fetchSearchingOrders(getSearchParamsFromQuery(nextQuery)));
    }

    prevSearch.current = location.search;
  }, [dispatch, location.search]);

  const onRowClick = (rowData) => {
    window.open(`${PUBLIC_URL}/orders/${rowData.order_id}`, '_blank'); // eslint-disable-line no-undef
  };

  const onSearchHandler = (searchParams) => {
    const { fields } = searchParams;
    const company = (fields.company === 'company') ? '' : fields.company;
    const status = (fields.status === 'status') ? '' : fields.status;
    const newSearchParams = { ...searchParams };
    newSearchParams.fields.company = company;
    newSearchParams.fields.status = status;
    newSearchParams.fields.page = 1;
    history.push({
      pathname: location.pathname,
      search: getQueryFromSearchParams(newSearchParams),
    });
  };

  const getSearchParamsFromQuery = (query) => {
    const newSearchParams = { fields: { ...query } };
    if (newSearchParams.fields.to || newSearchParams.fields.from) {
      newSearchParams.fields.from = moment.utc(newSearchParams.fields.from);
      newSearchParams.fields.to = moment.utc(newSearchParams.fields.to);
    }
    return newSearchParams;
  };

  const getQueryFromSearchParams = (newSearchParams) => {
    const querySearchParams = { ...newSearchParams.fields };
    if (querySearchParams.to || querySearchParams.from) {
      querySearchParams.from = querySearchParams.from && querySearchParams.from.format('YYYY-MM-DD');
      querySearchParams.to = querySearchParams.to ? querySearchParams.to.format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD');
    }
    return queryString.stringify(querySearchParams);
  };

  const showAll = () => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({ showAll: 1, page: 1 }),
    });
  };

  const pageChanged = (pg) => {
    const query = queryString.parse(location.search);
    query.page = pg;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(query),
    });
  };

  const query = queryString.parse(location.search);
  const currentPage = query && query.page && +query.page;
  // Prevent error when render occurs before the lastPage in redux store is updated
  const totalPages = lastPage > currentPage ? lastPage : currentPage;

  let dataContent = null;

  let companyList = [['company', 'Company']];
  let statusList = [['status', 'Status']];

  if (companies) {
    const companiesOptions = companies.map((el) => ([el.company_id, el.company_name]));
    companyList = companyList.concat(companiesOptions);
  }

  const statusOptions = Object.values(orderStatusConstants).map((s) => ([s.id, s.name]));
  statusList = statusList.concat(statusOptions);

  if (isSearching) {
    const { length } = data;
    const statusNamePerId = {};

    const procData = data.map((order) => {
      if (!statusNamePerId[order.seller_status]) {
        const statusEl = Object.values(orderStatusConstants).find((s) => s.id === order.seller_status);
        statusNamePerId[order.seller_status] = statusEl?.name;
      }
      return { ...order, seller_status: statusNamePerId[order.seller_status] };
    });

    dataContent = (
      <div>
        <SearchInfo lengthOfItems={totalRecords} />
        {length !== 0 && <OrderTable data={procData} onRowClick={onRowClick} />}
        {length < totalRecords && <Pagination onChange={pageChanged} currentPage={currentPage} totalPages={totalPages} />}
      </div>
    );
  }

  return (
    <Container links={[{ 'Order Management': null }]}>
      <SearchOrderBar
        onShowAll={showAll}
        isShowAll={query.showAll}
        onSearch={onSearchHandler}
        companyList={companyList}
        statusList={statusList}
        query={query}
      />
      {currentPage ? dataContent : null}
      <ModalLoader show={isFetching} />
    </Container>
  );
}
