/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

import { GAPI_CLIENT_KEY } from '../../constants';
import { getErrorsStore } from '../../selectors';
import { errorsRemove } from '../../actions/requestErrorHandler';

import Loader from '../../components/Loader';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ErrorModal from '../../components/ErrorModal';
import Toasts from '../../components/Toasts';
import InternalErrorContainer from '../../components/InternalErrorContainer';
import { checkAuth } from '../../sagas/loginFlow';

import appRoutes from './appRouting';

const PageWrapper = styled.div`
  width: 100%;
`;
const AppWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100%;
`;

export default function App() {
  const dispatch = useDispatch();
  const hasUIError = false;

  const location = useLocation();

  const { tokenIsChecked, checkingTokenInProgress } = useSelector((state) => state.authorization);
  const { errors } = useSelector(getErrorsStore);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  let content;

  if (checkingTokenInProgress) content = <Loader />;
  else if (!tokenIsChecked && location.pathname !== '/login') content = null;
  else content = appRoutes;

  if (hasUIError) {
    return (
      <Container>
        <InternalErrorContainer>
          Oops, something went wrong...
          <br />
          Please, try to&nbsp;
          <a href=".">refresh</a>
          &nbsp;this page.
        </InternalErrorContainer>
      </Container>
    );
  }

  return (
    <PageWrapper>
      <AppWrapper>
        <Helmet
          titleTemplate="%s - ePlane Back Office"
          defaultTitle="ePlane Back Office"
          meta={[
            { name: 'description', content: 'ePlane Back Office' },
          ]}
          script={[
            {
              src: `https://maps.googleapis.com/maps/api/js?key=${GAPI_CLIENT_KEY}&libraries=places&language=en`,
              type: 'text/javascript',
            },
          ]}
        />
        <Header isAuthenticated={!!tokenIsChecked} />
        {content}

        <ErrorModal error={errors} onHide={() => dispatch(errorsRemove())} />
        <Toasts />
      </AppWrapper>
      <Footer />
    </PageWrapper>
  );
}
