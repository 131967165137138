import axios from 'axios';

import {
  CHAT_COMMENT_ADD,
  CHAT_COMMENT_ADD_SUCCESS,
  CHAT_UPDATE_REQUIRED,
  CHAT_FAIL,

  ERRORS,
} from '../constants';
import { requestErrorHandler } from './requestErrorHandler';

const startAddComment = () => ({
  type: CHAT_COMMENT_ADD,
});
const addCommentSuccess = (logsData) => ({
  type: CHAT_COMMENT_ADD_SUCCESS,
  payload: logsData,
});
const addCommentFail = () => ({ type: CHAT_FAIL });

export const updateComments = () => ({ type: CHAT_UPDATE_REQUIRED });

export const addComment = (comment, id, isCustomer) => async (dispatch) => {
  dispatch(startAddComment());
  try {
    const url = isCustomer
      ? `/rest/V2/eplane/backoffice/customer/${id}/comment`
      : `/rest/V2/eplane/backoffice/user/${id}/comment`;
    const response = await axios.post(url, comment);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(addCommentSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, addCommentFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, addCommentFail, e);
  }
};
