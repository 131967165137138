import {
  SERVER_CONSTANTS_GET,
  SERVER_CONSTANTS_GET_SUCCESS,
  SERVER_CONSTANTS_GET_FAIL,
  SERVER_CONSTANTS_ERROR_REMOVE,
} from '../constants';

const initialState = {
  isFetching: true,
  constants: null,
  countries: null,
  errorMessage: null,
};

export default function serverConstants(state = initialState, action) {
  const { payload } = action;
  let sortedCountries;
  switch (action.type) {
    case SERVER_CONSTANTS_GET:
      return { ...state, isFetching: true };

    case SERVER_CONSTANTS_GET_SUCCESS:
      sortedCountries = payload.countries.slice().sort((a, b) => (
        a.full_name_locale.localeCompare(b.full_name_locale)
      ));
      return {
        constants: payload.constants,
        countries: sortedCountries,
        isFetching: false,
        errorMessage: null,
      };

    case SERVER_CONSTANTS_GET_FAIL:
      return { errorMessage: payload };
    case SERVER_CONSTANTS_ERROR_REMOVE:
      return { ...state, errorMessage: null };

    default: return state;
  }
}
