import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProPrice, saveProPrice, resetProPrice } from '../../../actions/settings/proPrice';
import { getProPriceStore, getErrorsStore } from '../../../selectors';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import { TabContainer } from '../../StyledComponents';
import LimitSetting from '../OrderLimits/LimitSetting';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';
import LimitModal from '../OrderLimits/LimitModal';
import createErrorValidationObj from '../../../utils/createErrorValidationObj';

const regExp = /^(([1-9]\d*\.?\d{0,2})|(0?\.\d{0,2}))$/;

const proPrices = [{
  name: 'full_price/monthly',
  title: 'Price per month',
  label: <>Full monthly subscription price for ePlane users.</>,
  modalTitle: 'Price per month',
}, {
  name: 'price/monthly',
  title: null,
  label: <>Special monthly subscription price for ePlane users.</>,
  modalTitle: 'Price per month ',
}, {
  name: 'price/enterprise',
  title: null,
  label: <>Monthly subscription price for an Enterprise plan.</>,
  modalTitle: 'Price per month',
}, {
  name: 'price/yearly',
  title: 'Price per year',
  label: <>Special yearly subscription price for ePlane users.</>,
  modalTitle: 'Price per year',
}, {
  name: 'full_price/yearly',
  title: null,
  label: <>Full yearly subscription price for ePlane users (special monthly price ✕ 12).</>,
  modalTitle: 'Price per year',
  isDisabled: true,
  isEditHidden: true,
}];

const ProPricePage = () => {
  const dispatch = useDispatch();

  const [proPrice, setProPrice] = useState(null);

  const { price, isFetching, isProcessing } = useSelector(getProPriceStore);
  const { errors } = useSelector(getErrorsStore);

  const saveProPrices = () => {
    dispatch(saveProPrice({
      settings: [{
        setting: 'full_price/monthly',
        value: proPrice.setting === 'full_price/monthly' ? proPrice.value : price.find((v) => v.setting === 'full_price/monthly')?.value,
      }, {
        setting: 'price/monthly',
        value: proPrice.setting === 'price/monthly' ? proPrice.value : price.find((v) => v.setting === 'price/monthly')?.value,
      }, {
        setting: 'full_price/yearly',
        value: proPrice.setting === 'price/monthly' ? (proPrice.value * 12) : price.find((v) => v.setting === 'full_price/yearly')?.value,
      }, {
        setting: 'price/yearly',
        // auto calculate special year price
        value: proPrice.setting === 'price/yearly' ? (proPrice.value) : price.find((v) => v.setting === 'price/yearly')?.value,
      }, {
        setting: 'price/enterprise',
        value: proPrice.setting === 'price/enterprise' ? proPrice.value : price.find((v) => v.setting === 'price/enterprise')?.value,
      }],
    }));
    setProPrice(null);
  };

  useEffect(() => {
    dispatch(fetchProPrice());
    return () => dispatch(resetProPrice());
  }, [dispatch]);

  const onLimitChange = (name) => {
    const item = price.find((v) => v.setting === name);
    setProPrice({ setting: name, value: item?.value ?? '' });
  };

  const onPriceChange = (e) => {
    const { value } = e.target;
    setProPrice((prev) => ({ ...prev, value }));
  };

  const subPriceValidation = (value) => {
    if (!value) return createErrorValidationObj('Please enter a price');
    const numVal = +value;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(numVal) || !regExp.test(value)) return createErrorValidationObj('Please enter a correct price');
    if (numVal <= 0) return createErrorValidationObj('Please enter a positive number');
    if (proPrice.setting === 'price/monthly'
      && +value > +price.find((v) => v.setting === 'full_price/monthly')?.value) {
      return createErrorValidationObj('The price cannot be greater than a full price');
    }
    if (proPrice.setting === 'price/yearly'
      && +value > +price.find((v) => v.setting === 'full_price/yearly')?.value) {
      return createErrorValidationObj('The price cannot be greater than a full price');
    }

    return null;
  };

  if ((!price || isFetching) && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <>
      <FormTitleSection title="Pro Price Management" showEditButton={false} />
      <TabContainer>
        <p>
          Monthly and yearly price calculation.&nbsp;
          <br />
          The values described here are general values, meaning,&nbsp;
          they are relevant for all the vetted companies and private users registered on ePlane.
        </p>
        <br />

        {proPrices.map((v) => (
          <LimitSetting
            key={v.name}
            name={v.name}
            title={v.title}
            labelText={v.label}
            amount={price?.find((item) => (item.setting === v.name))?.value ?? ''}
            currency="USD"
            isDisabled={v.isDisabled}
            isEditHidden={v.isEditHidden}
            onEditClick={() => onLimitChange(v.name)}
          />
        ))}
      </TabContainer>

      <LimitModal
        title={proPrice && proPrices.find((v) => v.name === proPrice.setting).modalTitle}
        text="Please make sure you supply the correct value:"
        amount={proPrice?.value ?? ''}
        currency="USD"
        validation={subPriceValidation}
        onConfirm={saveProPrices}
        onLimitChange={onPriceChange}
        show={!!proPrice}
        onHide={() => setProPrice(null)}
      />
      <ModalLoader show={isProcessing} />
    </>
  );
};

export default ProPricePage;
