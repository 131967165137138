import {
  DEMAND_LIST_UPLOADING_START,
  DEMAND_LIST_UPLOADING_FINISH,
  DEMAND_LIST_UPLOADING_PROGRESS_SET,
  DEMAND_LIST_UPLOADING_PROGRESS_RESET,
  DEMAND_LIST_UPLOADING_TERMINATE,
  DEMAND_LIST_ERROR_REMOVE,
  DEMAND_LIST_UPLOADING_ERROR_OCCURRED,

  AXIOS_CANCELLED_MESSAGE,
} from '../../constants';

const initialState = {
  uploadingProgress: null,
  source: null,
  isFetching: false,
  isUploading: false,
  fetchingErrorCode: null,
  errorCode: null,
};

export default function demandListReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case DEMAND_LIST_UPLOADING_START:
      return {
        ...state,
        isUploading: true,
        uploadingProgress: null,
        source: payload,
      };

    case DEMAND_LIST_UPLOADING_FINISH:
      return {
        ...state,
        isUploading: false,
        source: false,
      };

    case DEMAND_LIST_UPLOADING_PROGRESS_SET:
      return { ...state, uploadingProgress: payload };
    case DEMAND_LIST_UPLOADING_PROGRESS_RESET:
      return { ...state, uploadingProgress: null };
    case DEMAND_LIST_UPLOADING_TERMINATE:
      if (state.source) {
        state.source.cancel(AXIOS_CANCELLED_MESSAGE);
      }
      return {
        ...state,
        uploadingProgress: null,
        isUploading: false,
        source: null,
      };
    case DEMAND_LIST_UPLOADING_ERROR_OCCURRED:
      return {
        ...state,
        errorCode: payload,
        isUploading: false,
        uploadingProgress: null,
      };
    case DEMAND_LIST_ERROR_REMOVE:
      return { ...state, errorCode: null };

    default: return state;
  }
}
