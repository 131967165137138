import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert } from 'react-bootstrap';

const Title = styled.h4`
  font-weight: 800;
`;

export default function FormAlert(props) {
  const {
    onHide,
    title,
    message,
    show,
    type,
    ...other
  } = props;
  return (
    (show
    && (
      <Alert dismissible onClose={onHide} variant={type} {...other}>
        { title !== null ? <Title>{title}</Title> : ''}
        <p>{message}</p>
      </Alert>
    ))
    || null
  );
}

FormAlert.propTypes = {
  onHide: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  show: PropTypes.bool,
  type: PropTypes.string,
};

FormAlert.defaultProps = {
  show: true,
  type: 'danger',
  title: null,
};
