import styled from 'styled-components';
import { Button as ButtonBS } from 'react-bootstrap';

export const Text = styled.div`
  margin-top: 22px;
  font-size: 26px;
  font-weight: 400;
  text-align: center;
  line-height: 1.35;
`;

export const ButtonsWrapper = styled.div`
  & {
  text-align: center;
  }

  &&&  button:not(:first-child) {
    margin-left: 20px;
    
  }
`;

export const Button = styled(ButtonBS)`
  && {
    font-size: 16px;
    padding: 6px 20px;
  }
`;
