/**
 * A link to a certain page, an anchor tag
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as consts from '../../constants';

export const StyledLink = styled(Link)`
  color: ${consts.color.blue};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${consts.color.darkBlue};
    text-decoration: underline;
  }

  &:active, &:focus {
    color: ${consts.color.blue};
    text-decoration: none;
  }
`;

// Test URL type, in case external URL render <a/ >, otherwise use Router <Link /> component
const A = ({ to, children, ...props }) => (
  /^https?:\/\//.test(to)
    ? <a href={to} {...props}>{children}</a>
    : <StyledLink to={to} {...props}>{children}</StyledLink>
);

A.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

export default A;
