import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { StyledLinkButton as LinkButtonNormal } from '../../StyledComponents';
import Img from '../../Img';
import EmptyListImg from '../../../assets/empty-list.png';
import { color } from '../../../constants';

const customSellerMessage = 'This seller is not registered on ePlane and we will invite him to ePlane on your behalf.'
  + ' Adding him to a custom list will allow you to send him RFQs right away.';
const approvedBlockedSellerText = (approvedList) => (
  approvedList
    ? 'This seller is not listed in your approved sellers. Adding it to a custom list will also add it to your approved sellers.'
    : 'This seller is listed in your blocked sellers. Adding it to a custom list will remove it from blocked sellers.'
);
const Counter = styled.span`
  color: ${color.silverGrey};
`;
const Coffee = styled(Img)`
  width: 90px;
  height: 90px;
`;
const Container = styled.div`
  margin-top: 35px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
const Text = styled.p`
  font-size: 16px;
  margin-bottom: 0;
  color: ${color.grey};
`;
const Wrapper = styled.div`
  .select-container {
    ${(props) => (props.isError && 'border: 1px solid #dc3545;')}
    border-radius: 5px;
  }
  .validation-text {
    margin-top: 3px;
    ${(props) => (props.isError && 'color: #dc3545;;')}
  }
`;
const SelectedItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 70px;
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: thin;
  ::-webkit-scrollbar-track
  {
    border-radius: 10px;
    background-color: ${color.lightGrey};
  }

  ::-webkit-scrollbar
  {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    background-color: #808080;
  }
`;
const Block = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
  margin: 2px 3px;
  background-color: ${color.lightGrey};
  border-radius: 15px;

  white-space: nowrap;
  overflow: hidden;

  button {
    padding: 0;
  }
  .warning-icon {
    margin-right: 5px;
    margin-left: 3px;
    font-size: 18px;
  }
  .close-icon {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .block-text {
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const RemoveButton = styled(FontAwesomeIcon)`
  color: ${color.silverGrey};
  margin: 2px 3px;
  &:hover {
    color: ${color.grey};
  }
`;
const LinkButton = styled(LinkButtonNormal)`
  font-size: 16px;
`;
const tooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

export const SortButton = styled(Button)`
  padding: 0 0 0 5px;
  color: ${color.grey};
  svg { pointer-events: none; }
`;

export const EmptyListsContent = ({ text }) => (
  <Container>
    <Coffee src={EmptyListImg} alt="Empty List" />
    <br />
    <Text>{text}</Text>
  </Container>
);

EmptyListsContent.propTypes = {
  text: PropTypes.string,
};

export const getSellerCount = (list, externalList) => {
  if (!list || list.length < 2) return null;
  let length = list.length || 0;
  length += ((externalList && externalList.length) || 0);

  return <Counter>{`(${length} Sellers)`}</Counter>;
};

export const CustomOption = ({ children, ...props }) => (
  <components.Option {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.data && props.data.isBlocked && (
      <FontAwesomeIcon
        icon="exclamation-circle"
        color={`${color.goldenTainoi}`}
        className="warning-icon"
        /* eslint-disable-next-line react/destructuring-assignment */
        title={approvedBlockedSellerText(props.data.approvedList)}
      />
    )}
    &nbsp;
    {children}
  </components.Option>
);

CustomOption.propTypes = {
  children: PropTypes.string,
  data: PropTypes.shape({
    isBlocked: PropTypes.bool,
    approvedList: PropTypes.bool,
  }),
};

export const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.data && props.data.isBlocked && (
      <>
        <FontAwesomeIcon
          icon="exclamation-circle"
          color={`${color.goldenTainoi}`}
          className="warning-icon"
          /* eslint-disable-next-line react/destructuring-assignment */
          title={approvedBlockedSellerText(props.data.approvedList)}
        />
        &nbsp;
      </>
    )}
    {children}
  </components.SingleValue>
);

SingleValue.propTypes = {
  children: PropTypes.string,
  data: PropTypes.shape({
    isBlocked: PropTypes.bool,
    approvedList: PropTypes.bool,
  }),
};

export const SelectValidationWrapper = ({ children, isError, errorText }) => (
  <Wrapper isError={isError}>
    <div className="select-container">{children}</div>
    {isError && <div className="validation-text">{errorText}</div>}
  </Wrapper>
);

SelectValidationWrapper.propTypes = {
  children: PropTypes.shape({}),
  isError: PropTypes.bool,
  errorText: PropTypes.string,
};

const OverlayWrapper = ({ children, text }) => (
  <>
    {text
      ? (
        <OverlayTrigger
          placement="right"
          overlay={tooltip(text)}
        >
          {children}
        </OverlayTrigger>
      )
      : <>{children}</>}
  </>
);

OverlayWrapper.propTypes = {
  children: PropTypes.shape({}),
  text: PropTypes.string,
};

export const SelectedItemContainer = ({ list, onRemove }) => (
  <SelectedItems>
    {list.map((v) => (
      <OverlayWrapper text={v.email && customSellerMessage} key={v.value}>
        <Block>
          {v.isBlocked && (
            <FontAwesomeIcon
              icon="exclamation-circle"
              color={`${color.goldenTainoi}`}
              className="warning-icon"
              title={approvedBlockedSellerText(v.approvedList)}
            />
          )}
          <span className="block-text" title={v.email ? `${v.label} <${v.email}>` : `${v.label}`}>
            {v.email ? `${v.label} <${v.email}>` : `${v.label}`}
          </span>
          <LinkButton variant="link" onClick={() => onRemove(v.value)}>
            <RemoveButton icon="times" className="close-icon" />
          </LinkButton>
        </Block>
      </OverlayWrapper>
    ))}
  </SelectedItems>
);

SelectedItemContainer.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  onRemove: PropTypes.func.isRequired,
};

export const BubbleItemContainer = ({ list, onRemove }) => (
  <SelectedItems>
    {list.map((v) => (
      <OverlayWrapper text={v.title} key={v.code}>
        <Block>
          <span className="block-text" title={v.title}>
            {v.title}
          </span>
          <LinkButton variant="link" onClick={() => onRemove(v.code)}>
            <RemoveButton icon="times" className="close-icon" />
          </LinkButton>
        </Block>
      </OverlayWrapper>
    ))}
  </SelectedItems>
);

BubbleItemContainer.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  onRemove: PropTypes.func.isRequired,
};

export const ApprovedBlockedSellersOption = ({ children, ...props }) => (
  <components.Option {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.data && props.data.warning && (
      <FontAwesomeIcon
        icon="exclamation-circle"
        color={`${color.goldenTainoi}`}
        className="warning-icon"
        title="This seller is listed in your custom lists. Blocking this seller will also remove it from custom lists."
      />
    )}
    &nbsp;
    {children}
  </components.Option>
);

ApprovedBlockedSellersOption.propTypes = {
  children: PropTypes.string,
  data: PropTypes.shape({
    warning: PropTypes.bool,
  }),
};

export const ApprovedBlockedSellersSingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.data && props.data.warning && (
      <>
        <FontAwesomeIcon
          icon="exclamation-circle"
          color={`${color.goldenTainoi}`}
          className="warning-icon"
          title="This seller is listed in your custom lists. Blocking this seller will also remove it from custom lists."
        />
        &nbsp;
      </>
    )}
    {children}
  </components.SingleValue>
);

ApprovedBlockedSellersSingleValue.propTypes = {
  children: PropTypes.string,
  data: PropTypes.shape({
    warning: PropTypes.bool,
  }),
};

export const getSortIcon = (order) => {
  if (order === 'asc') return <FontAwesomeIcon icon="sort-up" />;
  if (order === 'desc') return <FontAwesomeIcon icon="sort-down" />;

  return <FontAwesomeIcon icon="sort" />;
};
