import { ERRORS } from '../constants';

export default (error) => {
  switch (error) {
    case ERRORS.ERR_PARSE_INPUT:
      return 'Could not parse input.';
    case ERRORS.ERR_RENDER_OUTPUT:
      return 'Could not render response.';
    case ERRORS.ERR_INVALID_INPUT:
      return 'User input error: general.';
    case ERRORS.ERR_INVALID_KEY:
      return 'User input error: invalid or unexpected input key.';
    case ERRORS.ERR_INVALID_VALUE:
      return 'User input error: invalid or unexpected input value.';
    case ERRORS.ERR_REQUIRED_FIELD:
      return 'User input error: required key.';
    case ERRORS.ERR_REQUIRED_VALUE:
      return 'User input error: required key value.';
    case ERRORS.ERR_DENIED:
      return 'Access denied.';
    case ERRORS.ERR_ENTITY_NOT_FOUND:
      return 'Entity or resource was not found.';
    case ERRORS.ERR_ENTITY_ALREADY_EXIST:
      return 'Entity could not be added because it is already exist.';
    case ERRORS.ERR_ALREADY_DONE:
      return 'Requested action already done.';
    default:
      return 'Please, try again or check with IT.';
  }
};
