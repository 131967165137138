import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { color, DELETED_STATUS_ID } from '../../../constants';
import InventoryStatistics from './InventoryStats';
import { catalogItemStatus } from '../../../constants/index';

const UnpublishButton = styled(Button)`
  &&& {
    color: ${color.birch};
    font-size: 16px;
    padding-left: 10px;
    text-decoration: none;
  }
  &&&:hover {
    color: ${color.darkBirch};
  }
`;

class InventoryStatisticsManagement extends React.PureComponent {
  getCurrentItemStatusStats = (stats, statusId) => {
    const itemStatusStatisticArray = statusId && stats && stats.find((elem) => +elem[0] === statusId);
    return itemStatusStatisticArray ? itemStatusStatisticArray[1] : '0';
  };

  render() {
    const {
      inventoryStatistics,
      userRepositoryUnpublish,
    } = this.props;

    const published = this.getCurrentItemStatusStats(inventoryStatistics, catalogItemStatus.PUBLISHED.id);
    const unpublished = this.getCurrentItemStatusStats(inventoryStatistics, catalogItemStatus.NOT_PUBLISHED.id);
    const deleted = this.getCurrentItemStatusStats(inventoryStatistics, DELETED_STATUS_ID);

    return (
      <div>
        <InventoryStatistics published={published} deleted={deleted} unpublished={unpublished} />
        <h4>Manage  Inventory and  Repair Capabilities</h4>
        <p>
          There are
          <strong>
            &nbsp;
            {published}
          </strong>
          &nbsp;published,
          <strong>
            &nbsp;
            {unpublished}
          </strong>
          &nbsp;unpublished and
          <strong>
            &nbsp;
            {deleted}
          </strong>
          &nbsp;deleted, inventories and repair capabilities
        </p>
        <UnpublishButton disabled={published === '0'} variant="link" onClick={userRepositoryUnpublish}>
          <FontAwesomeIcon icon="trash-alt" />
          &nbsp;
          Unpublish all
        </UnpublishButton>
      </div>
    );
  }
}

InventoryStatisticsManagement.propTypes = {
  inventoryStatistics: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  userRepositoryUnpublish: PropTypes.func.isRequired,
};

export default InventoryStatisticsManagement;
