import {
  LEGAL_ENTITIES_GET,
  LEGAL_ENTITIES_GET_SUCCESS,
  LEGAL_ENTITIES_GET_FAIL,
  LEGAL_ENTITIES_ERROR_REMOVE,
} from '../../constants';

const initialState = {
  legalEntities: [],
  isFetching: false,
  errorCode: null,
};

const legalEntitiesReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case LEGAL_ENTITIES_GET:
      return {
        ...state,
        isFetching: true,
        errorCode: null,
      };
    case LEGAL_ENTITIES_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        legalEntities: payload,
      };
    case LEGAL_ENTITIES_GET_FAIL:
      return {
        ...state,
        isFetching: false,
        errorCode: payload,
      };
    case LEGAL_ENTITIES_ERROR_REMOVE:
      return {
        ...state,
        isFetching: false,
        errorCode: null,
      };

    default: return state;
  }
};

export default legalEntitiesReducer;
