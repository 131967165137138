import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ConfirmationModal from '../../Modals/ConfirmationModal';
import { color } from '../../../constants';

const RemoveModalText = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
`;

export default function RemoveConfirmationModal({ data, ...props }) {
  let message = null;
  if (data?.length > 1) {
    message = (
      // eslint-disable-next-line react/jsx-one-expression-per-line
      <>Are you sure you wish to remove <strong>{data.length}</strong> PNs from your Watchlist?</>
    );
  } else if (data?.length === 1) {
    message = (
      <>Are you sure you wish to remove <strong>{data[0]}</strong>?</> // eslint-disable-line react/jsx-one-expression-per-line
    );
  } else if (!Array.isArray(data)) {
    message = (
      // eslint-disable-next-line react/jsx-one-expression-per-line
      <>Are you sure you wish to remove <strong>{data}</strong> PNs from your Watchlist?</>
    );
  }

  return (
    <ConfirmationModal
      confirmBtnText="Remove"
      cancelBtnText="Cancel"
      {...props}
    >
      <>
        <FontAwesomeIcon icon="exclamation-circle" color={`${color.tomatoRed}`} size="2x" />
        <RemoveModalText>Remove Parts</RemoveModalText>
        <p>{message}</p>
      </>
    </ConfirmationModal>
  );
}

RemoveConfirmationModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
};
