import axios from 'axios';

import { getBoUserRole } from '../../selectors/index';
import roles from '../../constants/backofficeRoles';
import {
  USER_SETTINGS_FETCH,
  USER_SETTINGS_IS_FETCHED,
  USER_SETTINGS_FATAL_ERROR_OCCURRED,

  USER_SETTINGS_SAVE,
  USER_SETTINGS_IS_SAVED,
  USER_SETTINGS_SAVE_ERROR,

  USER_SETTINGS_ERROR_REMOVE,
  USER_SETTINGS_EDIT_MODE_TOGGLE,

  ERRORS,
} from '../../constants';
import errorHandler, { addError } from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startFetching = () => ({
  type: USER_SETTINGS_FETCH,
});
const userSettingsIsFetched = (data) => ({
  type: USER_SETTINGS_IS_FETCHED,
  payload: data,
});
const setFatalError = (errorCode) => ({
  type: USER_SETTINGS_FATAL_ERROR_OCCURRED,
  payload: errorCode,
});

const startSaving = () => ({
  type: USER_SETTINGS_SAVE,
});
const userSettingsIsSaved = (data) => ({
  type: USER_SETTINGS_IS_SAVED,
  payload: data,
});
const setSavingError = (errorCode) => ({
  type: USER_SETTINGS_SAVE_ERROR,
  payload: errorCode,
});

export const toggleEditMode = () => ({
  type: USER_SETTINGS_EDIT_MODE_TOGGLE,
});

export const removeError = () => ({
  type: USER_SETTINGS_ERROR_REMOVE,
});

export const fetchUserSettings = (userId) => async (dispatch) => {
  dispatch(startFetching());

  try {
    const response = await axios(`/rest/default/V1/eplane/backoffice/user/${userId}/settings`);
    dispatch(userSettingsIsFetched(response.data));
  } catch (e) {
    errorHandler(e.response, dispatch, setFatalError, e);
  }
};

export const saveUserSettings = (userId, data) => async (dispatch, getState) => {
  if (getBoUserRole(getState()) === roles.VIEWER) {
    dispatch(addError(ERRORS.ERR_PERMISSION_DENIED));
    return;
  }

  dispatch(startSaving());

  const body = { req: data };
  try {
    const response = await axios.post(`/rest/default/V1/eplane/backoffice/user/${userId}/settings`, body);
    dispatch(userSettingsIsSaved(response.data));
    dispatch(updateComments());
  } catch (e) {
    errorHandler(e.response, dispatch, setSavingError, e);
  }
};
