import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Form, Button, Row, Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormActionButtons from '../../FormComponents/ActionButtons';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import { WeightListTitle } from '../StyleComponents';
import { TabContainer } from '../../StyledComponents';
import { fetchVendorsCategory, saveVendorsCategory } from '../../../actions/companiesManagement/vendorCategorizationActions';
import { fetchCategories as fetchCategoriesSettings } from '../../../actions/settings/vendorCategories';
import { fetchUserSettings } from '../../../actions/companiesManagement/userSettingsActions';
import {
  getVendorCategoriesStore, getVendorCategorizationStore, getUserSettingsStore,
} from '../../../selectors';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import A from '../../A';
import { color, PUBLIC_URL } from '../../../constants';
import { materialClass } from '../../../constants/index';

const NoContent = styled.div`
  margin: 15px 0;
  text-align: center;
  font-size: 18px;
  color: ${color.grey};
`;
const IndexColumn = styled.div`
  padding-top: 6px;
`;
const Container = styled.div`
  margin: 15px 0 0 0;
`;
const WarningMessage = styled.p`
  a {
    color: ${color.googleBlue};
    font-weight: bold;
  }

  & a:hover {
    color: ${color.inputBorder};
  }
  & a:active {
    color: ${color.inputBorder};
    opacity: 70%;
  }
  & a:focus {
    color: ${color.inputBorder};
  }
`;

const VendorCategorizationPage = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();

  const [companyCategoryList, setCompanyCategoryList] = useState([{ category: {}, class: {} }]);
  const [editMode, setEditMode] = useState(false);
  const [showConfirmationModal, setModal] = useState(false);

  const {
    categories,
    isFetching: isCategoriesSettingFetching,
  } = useSelector(getVendorCategoriesStore);
  const {
    vendorCategorizationList,
    isFetching,
    isSaving,
  } = useSelector(getVendorCategorizationStore);
  const { data } = useSelector(getUserSettingsStore);

  const tier3DemandsEnabled = data && data.receive_all_demands_enabled;

  const categoryOptions = useMemo(
    () => categories?.filter((a) => a.is_enabled).sort((a, b) => a.category_name.toString().localeCompare(b.category_name)).map(
      (s) => ({ value: s.category, label: s.category_name }),
    ),
    [categories],
  );

  const materialClassOptions = useMemo(
    () => Object.values(materialClass).map((s) => ({ value: s.id, label: s.name })),
    [],
  );

  useEffect(() => {
    dispatch(fetchCategoriesSettings());
    dispatch(fetchVendorsCategory(userId));
    dispatch(fetchUserSettings(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (vendorCategorizationList && categoryOptions) {
      setCompanyCategoryList(
        vendorCategorizationList.map((v) => ({
          category: categoryOptions.find((v1) => v.category === v1.value),
          class: materialClassOptions.find((v1) => v.material_class === v1.value),
        })),
      );
    }
  }, [categoryOptions, materialClassOptions, vendorCategorizationList]);

  const onAddVendorCatClick = () => {
    const filteredCategoryOptions = categoryOptions?.filter((v) => companyCategoryList.every((v1) => v1?.category?.value !== v.value));
    const newList = [
      ...companyCategoryList,
      {
        category: filteredCategoryOptions[0],
        class: materialClassOptions[3],
      },
    ];
    setCompanyCategoryList(newList);
  };

  const onDeleteVendorClick = (id) => () => {
    const newList = [...companyCategoryList];
    newList.splice(id, 1);
    setCompanyCategoryList(newList);
  };

  const onChange = (selectedOption, action, idx) => {
    const newData = [...companyCategoryList];
    newData[idx][action.name] = selectedOption;
    setCompanyCategoryList(newData);
  };

  const onSaveBtnClick = () => {
    if (!showConfirmationModal && vendorCategorizationList.length !== 0 && companyCategoryList.length === 0) {
      setModal(true);
      return;
    }

    const categoryList = companyCategoryList.map((v) => ({
      category: v.category.value,
      material_class: v.class.value,
    }));

    dispatch(saveVendorsCategory(categoryList, userId));

    if (showConfirmationModal) hideModal();
    setEditMode(false);
  };

  const onCancelHandler = () => {
    setCompanyCategoryList(
      vendorCategorizationList.map((v) => ({
        category: categoryOptions.find((v1) => v.category === v1.value),
        class: materialClassOptions.find((v1) => v.material_class === v1.value),
      })),
    );
    setEditMode(!editMode);
  };

  const hideModal = () => setModal(false);

  if (isCategoriesSettingFetching || isFetching) return <Loader />;

  const filteredCategoryOptions = categoryOptions?.filter((v) => companyCategoryList.every((v1) => v1?.category?.value !== v.value));

  const content = getVendorCategorizationList(
    companyCategoryList, filteredCategoryOptions, materialClassOptions, onChange, onDeleteVendorClick, !editMode,
  );

  return (
    <>
      <FormTitleSection
        title="Vendor Categorization"
        EditButtonClick={() => setEditMode(!editMode)}
        showEditButton={tier3DemandsEnabled}
      />

      <TabContainer>
        {!tier3DemandsEnabled && !data?.length && (
          <WarningMessage>
            No category has been assigned to the company by default since it does not accept TIER 3 RFQs.
            Go to the&nbsp;
            <A target="_blank" rel="noopener noreferrer" to={`${PUBLIC_URL}/companies/${userId}/userSettings`}>
              Company Settings
            </A>
            &nbsp;to enable TIER 3 flow.
          </WarningMessage>
        )}
        <br />
        <Form>
          <Row>
            <Col sm={1}><WeightListTitle>#</WeightListTitle></Col>
            <Col sm={4}><WeightListTitle>Category</WeightListTitle></Col>
            <Col sm={2}><WeightListTitle>Material class</WeightListTitle></Col>
          </Row>

          {content}
          {companyCategoryList?.length === 0 && (
            <Row>
              <Col sm={7}><NoContent>Empty List</NoContent></Col>
            </Row>
          )}
        </Form>
        <br />
        {filteredCategoryOptions?.length !== 0 && (
          <Button variant="success" onClick={onAddVendorCatClick} disabled={!editMode} data-testid="add_category">
            <FontAwesomeIcon icon="plus" />
            &nbsp;Add
          </Button>
        )}

        {editMode && (
          <>
            <hr />
            <FormActionButtons saveHandler={onSaveBtnClick} cancelHandler={onCancelHandler} />
          </>
        )}

        <ModalLoader show={isSaving} />

        <ConfirmationModal
          show={showConfirmationModal}
          confirmBtnText="Save"
          cancelBtnText="Cancel"
          confirm={onSaveBtnClick}
          onHide={hideModal}
        >
          Are you sure you want to remove all categories?
        </ConfirmationModal>
      </TabContainer>
    </>
  );
};

const getVendorCategorizationList = (vendorList, categoryOptions, materialClassOptions, handleChange, onDeleteVendorClick, disabled) => (
  vendorList.map((vendor, idx) => (
    <Container key={idx.toString()}>
      <Row>
        <Col sm={1}><IndexColumn>{(idx + 1).toString()}</IndexColumn></Col>
        <Col sm={4}>
          <Select
            value={vendor.category}
            name="category"
            placeholder="Choose category..."
            onChange={(selectedOption, action) => handleChange(selectedOption, action, idx)}
            options={categoryOptions}
            isDisabled={disabled}
          />
        </Col>
        <Col sm={2}>
          <Select
            value={vendor.class}
            name="class"
            placeholder="Choose material class..."
            onChange={(selectedOption, action) => handleChange(selectedOption, action, idx)}
            options={materialClassOptions}
            isDisabled
          />
        </Col>
        <Col sm={2}>
          <Button onClick={onDeleteVendorClick(idx)} disabled={disabled}>Remove</Button>
        </Col>
      </Row>
    </Container>
  ))
);

export default VendorCategorizationPage;
