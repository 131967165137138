import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';

import Container from '../../components/Container';
import SearchBar from '../../components/SearchBar';
import UsersTable from '../../components/UsersTable';
import fetchCustomersList from '../../actions/userManagement/customersListActions';
import { pushRedirect } from '../../actions/redirectActions';
import Loader from '../../components/Loader';
import ModalLoader from '../../components/ModalLoader';
import { getCustomersListStore } from '../../selectors';
import Pagination from '../../components/OrderManagement/SearchOrders/PaginationTable';
import * as serverConstants from '../../constants/index';

const NO_RESULTS_TEXT = 'No results were found, try refining your search';

const SearchCustomerPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [range, setRange] = useState({
    startDate: null,
    endDate: null,
  });

  const { customersList, isFetching } = useSelector(getCustomersListStore);

  useEffect(() => {
    const query = queryString.parse(location.search);
    // if searching there is 100 items per page, else only ten.
    const params = {
      q: query.q || '',
      f: query.f || '',
      t: query.t || '',
      p: query.p || 1,
      c: (query.q || query.f) ? 100 : 10,
      o: 'CREATED_AT',
    };
    dispatch(fetchCustomersList(params));
  }, [dispatch, location]);

  const onPaginationSelect = (eventKey) => {
    const search = queryString.stringify({
      ...queryString.parse(location.search),
      p: eventKey,
    });

    history.push({
      pathname: location.pathname,
      search,
    });
  };

  const onSearchHandler = (searchString) => {
    const search = queryString.stringify({
      ...queryString.parse(location.search),
      q: searchString,
    });

    history.push({
      pathname: location.pathname,
      search,
    });
  };

  const onCalendarChange = ({ startDate, endDate }, searchValue) => {
    if (!startDate && !endDate) {
      onCalendarReset();
      return;
    }
    if ((startDate && !endDate) || (!startDate && endDate)) {
      setRange({ startDate, endDate });
      return;
    }
    const search = queryString.stringify({
      ...queryString.parse(location.search),
      f: startDate.format('YYYY-MM-DD'),
      t: endDate.format('YYYY-MM-DD'),
      q: searchValue,
    });

    history.push({
      pathname: location.pathname,
      search,
    });
  };

  const onCalendarReset = () => {
    const { f, t, ...query } = queryString.parse(location.search);

    setRange({ startDate: null, endDate: null });
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(query),
    });
  };

  const {
    q,
    f,
    t,
  } = queryString.parse(location.search);
  const isSearching = f || q;
  let content = null;
  const {
    page, lastPage, totalRecords,
  } = customersList || {};

  const items = customersList?.items?.map((customer) => {
    const newCustomer = { ...customer };
    newCustomer.fullName = newCustomer.is_external
      ? '[external user]'
      : `${newCustomer.firstname || ''} ${newCustomer.lastname || ''}`;
    return newCustomer;
  });

  if (isFetching && !customersList) {
    content = <Loader />;
  } else if (!isSearching) {
    content = (
      <UsersTable data={items} pushRedirect={(path) => dispatch(pushRedirect(path))} consts={serverConstants} />
    );
  } else if (totalRecords) {
    const tableTitle = (totalRecords === 1)
      ? 'There is 1 result that match your search'
      : `There are ${totalRecords} results that match your search`;
    content = (
      <div>
        <ModalLoader show={isFetching} />
        <UsersTable data={items} pushRedirect={(path) => dispatch(pushRedirect(path))} title={tableTitle} consts={serverConstants} />
        { totalRecords > 100 && (
          <Pagination
            currentPage={page}
            totalPages={lastPage}
            onChange={onPaginationSelect}
          />
        )}
      </div>
    );
  } else content = NO_RESULTS_TEXT;

  return (
    <Container links={[{ 'User Management': null }]}>
      <SearchBar
        onSearchHandler={onSearchHandler}
        placeholder="Customer ID / Email / Domain / User name / Company"
        defaultInputValue={q || ''}
        searchOnTheFly={false}
        showCalendar
        startDate={range.startDate || (f && moment.utc(f)) || null}
        endDate={range.endDate || (t && moment.utc(t)) || null}
        onCalendarChange={onCalendarChange}
      />
      {content}
    </Container>
  );
};

export default SearchCustomerPage;
