import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text, Button, Body } from '../styledComponents';
import { color } from '../../../constants';

export default function TooBigFileModal({ maxSize, onHide, ...other }) {
  return (
    <Modal centered onHide={onHide} {...other}>
      <Modal.Body>
        <Text>
          <FontAwesomeIcon icon="exclamation-circle" color={color.tomatoRed} size="2x" />
        </Text>
        <Text><strong>{`File exceeds ${maxSize}`}</strong></Text>
        <Body>
          <p>
            Please try again with a smaller file
            <br />
            or contact IT for assistance.
          </p>
        </Body>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

TooBigFileModal.propTypes = {
  onHide: PropTypes.func,
  maxSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  headerText: PropTypes.string,
};
