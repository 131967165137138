import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, FormGroup, Container, Row, FormControl, Form,
} from 'react-bootstrap';
import styled from 'styled-components';

const HeaderModal = styled.h1`
  text-align: center;
  letter-spacing: -0.2px;
  display: block;
`;

const PasswordControl = ({ onSend, onReset, ...other }) => {
  const sendButtonRef = useRef(null);

  const [email, setEmail] = useState({
    value: '',
    resetEmail: true,
    validState: null,
  });

  useEffect(() => {
    if (sendButtonRef.current) sendButtonRef.current.focus();
  }, [other.show]);

  const onChange = (e) => {
    const { value } = e.target;
    const validState = value ? 'success' : 'error';
    setEmail((prevState) => ({ ...prevState, value, validState }));
  };

  const onSubmit = () => {
    const { value, resetEmail } = email;

    if (resetEmail) onSend();
    else {
      if (!value) {
        setEmail((prevState) => ({ ...prevState, validState: 'error' }));
        return;
      }

      onReset(value);
      setEmail({ value: '', resetEmail: true, validState: null });
    }
  };

  const onRadioChange = () => {
    setEmail((prevState) => ({
      resetEmail: !prevState.resetEmail,
      value: '',
      validState: null,
    }));
  };

  const okButtonText = email.resetEmail ? 'Send' : 'Reset';

  return (
    <Modal centered {...other}>
      <Modal.Header>
        <HeaderModal>Change Password</HeaderModal>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <FormGroup as={Row} controlId="changePasswordForm">
            <Form.Check
              id="radio-send-email"
              label="Send the user an email with a reset password URL"
              type="radio"
              name="chPassRadioGroup"
              onChange={onRadioChange}
              checked={email.resetEmail}
            />
            <Form.Check
              id="radio-manual"
              label="Manually set the password"
              type="radio"
              name="chPassRadioGroup"
              onChange={onRadioChange}
              checked={!email.resetEmail}
            />
          </FormGroup>
          <FormGroup as={Row} controlId="formPasswordValidation">
            <FormControl
              type="text"
              value={email.value}
              placeholder="Enter new password"
              onChange={onChange}
              disabled={email.resetEmail}
              isValid={email.validState === 'success'}
              isInvalid={email.validState === 'error'}
            />
            <FormControl.Feedback />
          </FormGroup>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmit} type="submit" ref={sendButtonRef}>
          &nbsp;
          {okButtonText}
        </Button>
        <Button variant="outline-secondary" onClick={other.onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};

PasswordControl.propTypes = {
  onSend: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default PasswordControl;
