import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Row, FormControl, FormGroup, Container,
} from 'react-bootstrap';
import styled from 'styled-components';

import { ButtonContainer, Button } from '../styledComponents';

const Text = styled.div`
  font-size: 16px;
  padding: 20px 0;
`;

class PromptModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      comment: props.defaultText,
      prevShow: props.show,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.show && !state.prevShow) {
      return {
        prevShow: props.show,
        comment: '',
      };
    }
    if (props.show !== state.prevShow) {
      return {
        prevShow: props.show,
        comment: state.comment,
      };
    }
    return null;
  }

  onCommentChange = (e) => {
    this.setState({
      comment: e.target.value,
    });
  }

  onConfirmHandler = () => {
    const { confirm } = this.props;
    const { comment } = this.state;
    confirm(comment);
  }

  render() {
    const {
      onHide,
      confirm,
      confirmBtnText,
      cancelBtnText,
      placeholder,
      defaultText,
      validationState,
      isRequired,
      children,
      ...other
    } = this.props;
    const { comment } = this.state;
    return (
      <Modal centered onHide={onHide} {...other}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Container>
            <Text>{children}</Text>
            <FormGroup as={Row}>
              <FormControl
                name="comment"
                value={comment}
                onChange={this.onCommentChange}
                rows={3}
                className="PopUp-Comment"
                as="textarea"
                placeholder={placeholder}
                isValid={validationState === 'success'}
                isInvalid={validationState === 'error'}
              />
            </FormGroup>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <ButtonContainer btnWidth="120px" space="10px">
            <Button variant="primary" onClick={this.onConfirmHandler} disabled={isRequired && !comment}>
              {confirmBtnText}
            </Button>
            <Button variant="outline-secondary" onClick={onHide}>
              {cancelBtnText}
            </Button>
          </ButtonContainer>
        </Modal.Footer>
      </Modal>
    );
  }
}

PromptModal.defaultProps = {
  confirmBtnText: 'YES',
  cancelBtnText: 'CANCEL',
};

PromptModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  confirm: PropTypes.func,
  children: PropTypes.node,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  placeholder: PropTypes.string,
  defaultText: PropTypes.string,
  isRequired: PropTypes.bool,
  validationState: PropTypes.oneOf(['success', 'warning', 'error', null]),
};

export default PromptModal;
