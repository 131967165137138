import moment from 'moment';

import {
  TASKS_LIST_GET,
  TASKS_LIST_GET_SUCCESS,

  TASK_START,
  TASK_START_SUCCESS,

  TASK_CLOSE,
  TASK_CLOSE_SUCCESS,

  TASK_REOPEN,
  TASK_REOPEN_SUCCESS,

  TASK_STEP_FORWARD,
  TASK_STEP_FORWARD_SUCCESS,

  TASK_DATA_FETCH,
  TASK_DATA_FETCH_SUCCESS,

  NOC_SECTION_FAIL,

  TASK_SET_VIEWED,
  SET_TASK_FETCHING_PARAMS,
} from '../../constants';

const initialState = {
  selectedTask: null,
  tasksList: null,
  taskHistory: null,
  isTasksFetching: false,
  isTaskInfoFetching: false,
  isProcessing: false,
  isClosingStepForward: false,
  tasksPerPage: null,
  totalTaskPages: null,
  taskListFetchingParams: {
    startDate: null,
    endDate: null,
    taskStatus: null,
    searchQuery: '',
    clearFiltersState: false,
  },
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case TASKS_LIST_GET:
      return {
        ...state,
        isTasksFetching: true,
      };
    case TASKS_LIST_GET_SUCCESS: {
      const {
        items: itemList,
        per_page: perPage,
        total_pages: totalPages,
        total_items: totalItems,
      } = payload;
      return {
        ...state,
        isTasksFetching: false,
        tasksList: itemList,
        tasksPerPage: perPage,
        totalTaskPages: totalPages,
        totalTasksAmount: totalItems,
      };
    }
    case SET_TASK_FETCHING_PARAMS: {
      const {
        startDate: newStartDate,
        endDate: newEndDate,
        taskStatus: newTaskStatus,
        searchQuery: newSearchQuery,
        clearFiltersState: clearFiltersStateA,
      } = payload;
      const {
        taskListFetchingParams: {
          startDate: currentStartDate,
          endDate: currentEndDate,
          taskStatus: currentTaskStatus,
          searchQuery: currentSearchQuery,
        },
      } = state;
      const taskListFetchingParams = {
        startDate: newStartDate === undefined
          ? currentStartDate
          : newStartDate,
        endDate: newEndDate === undefined
          ? currentEndDate
          : newEndDate,
        taskStatus: newTaskStatus === undefined
          ? currentTaskStatus
          : newTaskStatus,
        searchQuery: newSearchQuery === undefined
          ? currentSearchQuery
          : newSearchQuery,
        clearFiltersState: !!clearFiltersStateA,
      };
      return {
        ...state,
        taskListFetchingParams: { ...taskListFetchingParams },
      };
    }
    case TASK_START:
      return {
        ...state,
        isProcessing: true,
      };
    case TASK_START_SUCCESS: {
      const { updated_task: task, task_history: history } = payload;
      const itemIndex = state.tasksList?.findIndex((v) => v.task_id === task.task_id);
      return {
        ...state,
        taskHistory: sortHistory(history),
        tasksList: state.tasksList?.map((item, index) => (index === itemIndex ? task : item)),
        selectedTask: task,
        isProcessing: false,
      };
    }

    case TASK_CLOSE:
      return {
        ...state,
        isProcessing: true,
      };
    case TASK_CLOSE_SUCCESS: {
      const { updated_task: task, task_history: history } = payload;
      const itemIndex = state.tasksList?.findIndex((v) => v.task_id === task.task_id);
      return {
        ...state,
        taskHistory: sortHistory(history),
        tasksList: state.tasksList?.map((item, index) => (index === itemIndex ? task : item)),
        selectedTask: task,
        isProcessing: false,
      };
    }

    case TASK_STEP_FORWARD:
      return {
        ...state,
        isProcessing: true,
        isClosingStepForward: true,
      };
    case TASK_STEP_FORWARD_SUCCESS: {
      const { updated_task: task, task_history: history } = payload;
      const itemIndex = state.tasksList?.findIndex((v) => v.task_id === task.task_id);
      return {
        ...state,
        taskHistory: sortHistory(history),
        tasksList: state.tasksList?.map((item, index) => (index === itemIndex ? task : item)),
        selectedTask: task,
        isProcessing: false,
        isClosingStepForward: false,
      };
    }

    case TASK_REOPEN:
      return {
        ...state,
        isProcessing: true,
      };
    case TASK_REOPEN_SUCCESS: {
      const { updated_task: task, task_history: history } = payload;
      const itemIndex = state.tasksList?.findIndex((v) => v.task_id === task.task_id);
      return {
        ...state,
        taskHistory: sortHistory(history),
        tasksList: state.tasksList?.map((item, index) => (index === itemIndex ? task : item)),
        selectedTask: task,
        isProcessing: false,
      };
    }
    case NOC_SECTION_FAIL:
      return {
        ...state,
        isTaskInfoFetching: false,
        isTasksFetching: false,
        isProcessing: false,
      };

    case TASK_DATA_FETCH:
      return { ...state, isTaskInfoFetching: true };
    case TASK_DATA_FETCH_SUCCESS:
      return {
        ...state,
        taskHistory: sortHistory(payload.task_history),
        selectedTask: payload.task,
        isTaskInfoFetching: false,
      };

    case TASK_SET_VIEWED: {
      const itemIndex = state.tasksList && state.tasksList?.findIndex((v) => v.task_id === payload);
      return {
        ...state,
        tasksList: state.tasksList
          && state.tasksList?.map((item, index) => (index === itemIndex ? { ...item, customer_view_status: true } : item)),
      };
    }

    default: return state;
  }
};

function sortHistory(history) {
  const groupedHistory = [];
  if (!history) return groupedHistory;
  history.forEach((e) => {
    const formatedDate = moment.utc(e.created_at).calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday]',
      lastWeek: '[Last] dddd',
      sameElse: 'MMM D, YYYY',
    });
    const index = groupedHistory.findIndex((v) => v.title === formatedDate);
    if (index < 0) {
      groupedHistory.push({ title: formatedDate, groupedHistory: [e] });
    } else {
      groupedHistory[index].groupedHistory.push(e);
    }
  });
  return groupedHistory;
}
