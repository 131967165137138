import React, {
  useEffect, useState, useMemo, useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Form, Button, FormLabel, FormGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

import { fetchIntegration, addIntegration } from '../../../actions/companiesManagement/spec2k';
import { getSpec2kStore, getErrorsStore } from '../../../selectors';
import { TabContainer, ButtonContainer, StyledLinkButton } from '../../StyledComponents';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import RemoveModal from '../../Modals/RemoveModal';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';
import UniversalFormControl from '../../FormComponents/BaseFormGroup';
import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import {
  integrationTypeList, requestMethodsOptions, contentTypeOptions, authTypeOptions, MessageType,
} from '../../../constants/spec2k';
import { sitaAddressValidator, forwardEmailValidator } from './validators';
import { AXIOS_CANCELLED_MESSAGE } from '../../../constants';

const createNewOption = { value: '-1', label: 'Create new integration setting' };
const defaultIntegration = {
  is_active: true,
  forward_unknown_orders: false,
  settings_id: null,
  request_method: requestMethodsOptions[0],
  content_type: contentTypeOptions[0],
  auth_type: authTypeOptions[0],
  integration_type: { value: integrationTypeList.INTEGRATION_ROTABULL, label: integrationTypeList.INTEGRATION_ROTABULL },
  supported_messages: ['ATA_SparesQuoteRequest'],
  email: '',
};

const Spec2000Page = () => {
  const source = useRef(null);

  const { userId } = useParams();
  const dispatch = useDispatch();

  const [integrationData, setIntegrationData] = useState({
    ...defaultIntegration,
    supported_messages: [],
  });
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [generationState, setGenerationState] = useState(false);

  const { integrationSettings, isFetching, isProcessing } = useSelector(getSpec2kStore);
  const { errors } = useSelector(getErrorsStore);

  const settingsOptions = useMemo(
    () => integrationSettings && [
      ...Object.values(integrationSettings).map((v) => ({ value: v.settings_id, label: v.name })),
      createNewOption,
    ],
    [integrationSettings],
  );

  const integrationTypeOptions = useMemo(
    () => Object.values(integrationTypeList).map((v) => ({ value: v, label: v })),
    [],
  );

  useEffect(() => {
    dispatch(fetchIntegration(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (integrationSettings && integrationSettings.length !== 0) {
      const item = integrationSettings[0];
      setIntegrationData({
        ...item,
        settings_id: settingsOptions.find((v) => v.value === item.settings_id),
        request_method: requestMethodsOptions.find((v) => v.value === item.request_method) ?? requestMethodsOptions[0],
        content_type: contentTypeOptions.find((v) => v.value === item.content_type) ?? contentTypeOptions[0],
        auth_type: authTypeOptions.find((v) => v.value === item.auth_type) ?? authTypeOptions[0],
        integration_type: item.sita_address !== null
          ? { value: integrationTypeList.INTEGRATION_SITA, label: integrationTypeList.INTEGRATION_SITA }
          : { value: integrationTypeList.INTEGRATION_CUSTOM, label: integrationTypeList.INTEGRATION_CUSTOM },
        supported_messages: item.supported_messages ?? [],
      });
    } else if (integrationSettings) {
      setIntegrationData({ ...defaultIntegration, supported_messages: [] });
    }
  }, [dispatch, integrationSettings, integrationTypeOptions, settingsOptions]);

  const onSaveHandler = () => {
    let data = {
      is_active: integrationData.is_active,
      integration_type: integrationData.integration_type?.value,
      supported_messages: integrationData.supported_messages,
      forward_unknown_orders: integrationData.forward_unknown_orders,
    };
    if (data.integration_type === integrationTypeList.INTEGRATION_CUSTOM) {
      data = {
        ...data,
        request_method: integrationData.request_method?.value,
        content_type: integrationData.content_type?.value,
        auth_type: integrationData.auth_type?.value,
        sita_address: null,
        request_url: integrationData.request_url ?? null,
        auth_data: integrationData.auth_data,
      };
    } else if (data.integration_type === integrationTypeList.INTEGRATION_SITA) {
      data = {
        ...data,
        sita_address: integrationData.sita_address,
      };
    } else if (data.integration_type === integrationTypeList.INTEGRATION_SPEC2MAIL) {
      data = {
        ...data,
        email: integrationData.email,
      };
    }
    dispatch(addIntegration(userId, data));
  };

  const onChange = (e) => setIntegrationData({ ...integrationData, [e.target.name]: e.target.value });

  const onResetAllHandler = () => {
    dispatch(addIntegration(userId, { integration_type: 'none' }));
    setConfirmationModal(false);
  };

  const onSettingChosen = (selectedOption) => {
    if (selectedOption.value !== createNewOption.value) {
      const item = integrationSettings.find((v) => v.settings_id === selectedOption.value);
      setIntegrationData({
        ...item,
        settings_id: settingsOptions.find((v) => v.value === item.settings_id),
        request_method: requestMethodsOptions.find((v) => v.value === item.request_method) ?? requestMethodsOptions[0],
        content_type: contentTypeOptions.find((v) => v.value === item.content_type) ?? contentTypeOptions[0],
        auth_type: authTypeOptions.find((v) => v.value === item.auth_type) ?? authTypeOptions[0],
        integration_type: item.sita_address !== null
          ? { value: integrationTypeList.INTEGRATION_SITA, label: integrationTypeList.INTEGRATION_SITA }
          : { value: integrationTypeList.INTEGRATION_CUSTOM, label: integrationTypeList.INTEGRATION_CUSTOM },
        supported_messages: item.supported_messages ?? [],
      });
    } else {
      setIntegrationData({ ...defaultIntegration, settings_id: createNewOption });
    }
  };

  const onSupportedMessageChecked = (e) => {
    const newList = e.target.checked
      ? [...integrationData.supported_messages, e.target.name]
      : integrationData.supported_messages.filter((v) => v !== e.target.name);
    setIntegrationData({ ...integrationData, supported_messages: newList });
  };

  const generateRequestBin = async () => {
    setGenerationState(true);
    if (source.current) source.current.cancel(AXIOS_CANCELLED_MESSAGE);
    source.current = axios.CancelToken.source();
    try {
      const response = await axios('/rest/V2/eplane/backoffice/request_bin/create', { cancelToken: source.current.token });
      setIntegrationData({ ...integrationData, request_url: response?.data?.payload?.url ?? '' });
      source.current = null;
    } catch (error) {
      if (error.message === AXIOS_CANCELLED_MESSAGE) ;
      else console.warn('Can\'t generate request bin'); // eslint-disable-line
    }
    setGenerationState(false);
  };

  if ((!integrationSettings || isFetching) && (errors === null || errors.length === 0)) return <Loader />;

  let disableSave = false;
  let sita = null;
  let spec2mail = null;
  let custom = null;

  if (integrationData.integration_type?.value === integrationTypeList.INTEGRATION_SITA) {
    disableSave = !!sitaAddressValidator(integrationData.sita_address);
    sita = (
      <>
        <FormGroup>
          <FormLabel>
            SITA TypeB address (IATA code)
          </FormLabel>
          <Row>
            <Col sm={5}>
              <UniversalFormControl
                labelColumnWidth={0}
                controlColumnWidth={12}
                value={integrationData.sita_address}
                name="sita_address"
                onChange={onChange}
                disabled={false}
                maxLength="7"
                {...sitaAddressValidator(integrationData.sita_address)}
              />
            </Col>
          </Row>
        </FormGroup>
      </>
    );
  }
  if (integrationData.integration_type?.value === integrationTypeList.INTEGRATION_SPEC2MAIL
    && integrationData.settings_id?.value === createNewOption.value) {
    spec2mail = (
      <>
        <FormGroup>
          <FormLabel>
            Forward eMail
          </FormLabel>
          <Row>
            <Col sm={5}>
              <UniversalFormControl
                labelColumnWidth={0}
                controlColumnWidth={12}
                value={integrationData.email}
                name="email"
                onChange={onChange}
                disabled={false}
                {...forwardEmailValidator(integrationData.email)}
              />
            </Col>
          </Row>
        </FormGroup>
      </>
    );
  }
  if (integrationData.integration_type?.value === integrationTypeList.INTEGRATION_CUSTOM
    || (integrationData.integration_type?.value === integrationTypeList.INTEGRATION_SPEC2MAIL
        && integrationData.settings_id?.value !== createNewOption.value)) {
    custom = (
      <>
        <FormGroup>
          <FormLabel>
            Request URL
          </FormLabel>
          <Row>
            <Col sm={5}>
              <UniversalFormControl
                labelColumnWidth={0}
                controlColumnWidth={12}
                value={integrationData.request_url}
                name="request_url"
                onChange={onChange}
                disabled={!integrationData.is_active}
              />
            </Col>
            <Col sm={2}>
              <StyledLinkButton variant="link" title="Generate request bin" onClick={generateRequestBin}>
                <FontAwesomeIcon icon="sync" spin={generationState} />
                &nbsp;Generate
              </StyledLinkButton>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <FormLabel>
            Request method
          </FormLabel>
          <Row>
            <Col sm={5}>
              <Select
                value={integrationData.request_method}
                name="request_method"
                onChange={(selectedOption, action) => setIntegrationData({ ...integrationData, [action.name]: selectedOption })}
                options={requestMethodsOptions}
                isDisabled={!integrationData.is_active}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <FormLabel>
            Content type
          </FormLabel>
          <Row>
            <Col sm={5}>
              <Select
                value={integrationData.content_type}
                name="content_type"
                onChange={(selectedOption, action) => setIntegrationData({ ...integrationData, [action.name]: selectedOption })}
                options={contentTypeOptions}
                isDisabled={!integrationData.is_active}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <FormLabel>
            Auth type
          </FormLabel>
          <Row>
            <Col sm={5}>
              <Select
                value={integrationData.auth_type}
                name="auth_type"
                onChange={(selectedOption, action) => setIntegrationData({ ...integrationData, [action.name]: selectedOption })}
                options={authTypeOptions}
                isDisabled={!integrationData.is_active}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <FormLabel>
            Auth data
          </FormLabel>
          <Row>
            <Col sm={5}>
              <UniversalFormControl
                labelColumnWidth={0}
                controlColumnWidth={12}
                value={integrationData.auth_data}
                name="auth_data"
                onChange={onChange}
                disabled={!integrationData.is_active}
              />
            </Col>
          </Row>
        </FormGroup>
      </>
    );
  }
  const messages = integrationData.settings_id !== null && (
    <FormGroup>
      <FormLabel>Supported messages</FormLabel>
      {Object.values(MessageType).map((v) => (
        <Form.Group>
          <CheckboxStyled
            id={`${v}_checkbox`}
            label={v}
            key={v}
            checked={integrationData.supported_messages?.find((el) => (el === v)) ?? false}
            name={`${v}`}
            onChange={onSupportedMessageChecked}
            disabled={!integrationData.is_active}
          />
        </Form.Group>
      ))}
    </FormGroup>
  );

  return (
    <>
      <FormTitleSection title="Outbound integration" showEditButton={false} />
      <TabContainer>
        {integrationSettings?.length === 0 && (
          <>
            <p>Integration settings list is empty now.</p>
            <br />
          </>
        )}

        <Form>
          <FormGroup>
            <FormLabel>
              Selected Integration Setting
            </FormLabel>
            <Row>
              <Col sm={5}>
                <Select
                  value={integrationData.settings_id}
                  name="settings_id"
                  onChange={onSettingChosen}
                  options={settingsOptions}
                />
              </Col>
              {integrationData.settings_id !== null && (
              <Col sm={3}>
                <ButtonContainer>
                  <Button
                    variant="primary"
                    title="Save Changes"
                    disabled={disableSave}
                    onClick={onSaveHandler}
                  >
                    <FontAwesomeIcon icon="save" />
                  </Button>
                  <Button
                    variant="danger"
                    title="Delete all settings"
                    onClick={() => setConfirmationModal(true)}
                  >
                    <FontAwesomeIcon icon="trash-alt" />
                  </Button>
                </ButtonContainer>
              </Col>
              )}
            </Row>
          </FormGroup>

          <hr />
          {integrationData.settings_id !== null && (
            <>
              <Form.Group>
                <CheckboxStyled
                  id="is_active_checkbox"
                  label="Enabled"
                  checked={integrationData.is_active}
                  name="is_active"
                  onChange={(e) => setIntegrationData({ ...integrationData, is_active: e.target.checked })}
                />
              </Form.Group>
              <Form.Group>
                <CheckboxStyled
                  id="forward_unknown_orders_checkbox"
                  label="Forward unknown orders"
                  checked={integrationData.forward_unknown_orders}
                  name="forward_unknown_orders"
                  onChange={(e) => setIntegrationData({ ...integrationData, forward_unknown_orders: e.target.checked })}
                />
              </Form.Group>
            </>
          )}

          {integrationData.settings_id?.value === createNewOption.value && (
            <FormGroup>
              <FormLabel>
                Integration
              </FormLabel>
              <Row>
                <Col sm={5}>
                  <Select
                    value={integrationData.integration_type}
                    name="integration_type"
                    onChange={(selectedOption, action) => setIntegrationData({ ...integrationData, [action.name]: selectedOption })}
                    options={integrationTypeOptions}
                    isDisabled={!integrationData.is_active}
                  />
                </Col>
              </Row>
            </FormGroup>
          )}

          {sita}
          {spec2mail}
          {custom}
          {messages}

        </Form>
      </TabContainer>

      <ModalLoader show={isProcessing} />
      <RemoveModal
        show={confirmationModal}
        onRemove={onResetAllHandler}
        onHide={() => setConfirmationModal(false)}
        text="Are you sure you wish to delete ALL integration settings?"
      />
    </>
  );
};

export default Spec2000Page;
