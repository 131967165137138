import moment from 'moment';

import { getConstNameById } from '../../utils/constantsHandlers';
import * as constants from '../../constants/index';
import {
  formatTime, getTime, formatUnits, getSiteTitle, getTagName, getTraceTo,
} from './helpers';

export default (r, isRepair, isTagDocRemoved) => {
  const pickupSite = r.pickup_site && r.pickup_site?.location;
  let quoteRaw = null;

  if (r.type && r.type === constants.paymentRequestType.EXCHANGE) {
    quoteRaw = {
      part_number: r.is_alt_pn ? `Alternative PN: ${r.part_number}` : null,
      description: r.description && `Description: ${r.description}`,
      condition: `Condition: ${r.condition ? getConstNameById(r.condition, constants, 'condition') : '-'}`,
      warranty: `Warranty: ${formatTime(r.warranty, 'month')}`,
      lead_time: `Lead Time: ${getTime(r.lead_time, r.lead_time_units)}`,
      qty: `Qty: ${formatUnits(r.qty, r.qty_units)}`,
      pickup_site: getSiteTitle(false, pickupSite),
      exchange_fee: `Exchange Fee (per unit): ${formatUnits(r.fee, r.currency)}`,
      exchange_period: `Exchange Period: ${formatTime(r.period, 'day')}`,
      outright_price: `Outright Price: ${formatUnits(r.outright_price, r.currency)}`,
      tag_type: getTagName(r.tag_type, r.tag_name, isTagDocRemoved),
      taxes: `Taxes: ${formatUnits(r.taxes, r.currency)}`,
      tagged_by: `Tagged By: ${r.tagged_by ?? '-'}`,
      total_price: `Total Price: ${formatUnits(r.price_total, r.currency)}`,
      tag_date: `Tag Date: ${r.tag_date ? `${moment.utc(r.tag_date).format('MMM D, YYYY')}` : '-'}`,
      minimum_order_value: `Minimum Order Value: ${formatUnits(r.minimum_order_value, r.currency)}`,
      trace_to: `Trace To: ${getTraceTo(r.trace_to)}`,
      valid_for_value: `Request valid for: ${getTime(r.valid_for_value, r.valid_for_units)}`,
    };
  } else if (r.type && r.type === constants.paymentRequestType.LOAN) {
    quoteRaw = {
      part_number: r.is_alt_pn ? `Alternative PN: ${r.part_number}` : null,
      description: r.description && `Description: ${r.description}`,
      condition: `Condition: ${r.condition ? getConstNameById(r.condition, constants, 'condition') : '-'}`,
      warranty: `Warranty: ${formatTime(r.warranty, 'month')}`,
      lead_time: `Lead Time: ${getTime(r.lead_time, r.lead_time_units)}`,
      qty: `Qty: ${formatUnits(r.qty, r.qty_units)}`,
      pickup_site: getSiteTitle(false, pickupSite),
      loan_fee: `Loan Fee: ${formatUnits(r.fee, r.currency)}`,
      loan_period: `Loan Period: ${formatTime(r.period, 'day')}`,
      outright_price: `Outright Price: ${formatUnits(r.outright_price, r.currency)}`,
      tag_type: getTagName(r.tag_type, r.tag_name, isTagDocRemoved),
      taxes: `Taxes: ${formatUnits(r.taxes, r.currency)}`,
      tagged_by: `Tagged By: ${r.tagged_by ?? '-'}`,
      total_price: `Total Price: ${formatUnits(r.price_total, r.currency)}`,
      tag_date: `Tag Date: ${r.tag_date ? `${moment.utc(r.tag_date).format('MMM D, YYYY')}` : '-'}`,
      minimum_order_value: `Minimum Order Value: ${formatUnits(r.minimum_order_value, r.currency)}`,
      trace_to: `Trace To: ${getTraceTo(r.trace_to)}`,
      valid_for_value: `Request valid for: ${getTime(r.valid_for_value, r.valid_for_units)}`,
    };
  } else if (r.type && r.type === constants.paymentRequestType.REPAIR) {
    quoteRaw = {
      pickup_site: getSiteTitle(true, pickupSite),
      valid_for_value: `Request valid for: ${getTime(r.valid_for_value, r.valid_for_units)}`,
      warranty: `Warranty: ${formatTime(r.warranty, 'month')}`,
      qty: `Qty: ${formatUnits(r.qty, r.qty_units)}`,
      evaluation_fee: `Evaluation Fee (per unit): ${formatUnits(r.repair_price_evaluation, r.currency)}`,
      evaluation_time: `Evaluation Lead Time: ${getTime(r.repair_lead_time_evaluation, r.lead_time_units)}`,
      test_price: `Test Price (per unit): ${formatUnits(r.repair_price_test, r.currency)}`,
      test_time: `Test Lead Time: ${getTime(r.repair_lead_time_test, r.lead_time_units)}`,
      repair_price: `Repair Price (per unit): ${formatUnits(r.repair_price_repair, r.currency)}`,
      repair_time: `Repair Lead Time: ${getTime(r.repair_lead_time_repair, r.lead_time_units)}`,
      overhaul_price: `Overhaul Price (per unit): ${formatUnits(r.repair_price_overhaul, r.currency)}`,
      overhaul_time: `Overhaul Lead Time: ${getTime(r.repair_lead_time_overhaul, r.lead_time_units)}`,
      taxes: `Taxes: ${formatUnits(r.taxes, r.currency)}`,
      total_price: `Total Price: ${formatUnits(r.price_total, r.currency)}`,
    };
  } else {
    quoteRaw = {
      part_number: r.is_alt_pn ? `Alternative PN: ${r.part_number}` : null,
      description: r.description && `Description: ${r.description}`,
      condition: `Condition: ${r.condition ? getConstNameById(r.condition, constants, 'condition') : '-'}`,
      warranty: `Warranty: ${formatTime(r.warranty, 'month')}`,
      lead_time: `Lead Time: ${getTime(r.lead_time, r.lead_time_units)}`,
      qty: `Qty: ${formatUnits(r.qty, r.qty_units)}`,
      pickup_site: getSiteTitle(false, pickupSite),
      price_per_unit: `Price (per unit): ${formatUnits(r.price_per_unit, r.currency)}`,
      tag_type: getTagName(r.tag_type, r.tag_name, isTagDocRemoved),
      taxes: `Taxes: ${formatUnits(r.taxes, r.currency)}`,
      tagged_by: `Tagged By: ${r.tagged_by ?? '-'}`,
      total_price: `Total Price: ${formatUnits(r.price_total, r.currency)}`,
      tag_date: `Tag Date: ${r.tag_date ? `${moment.utc(r.tag_date).format('MMM D, YYYY')}` : '-'}`,
      minimum_order_value: `Minimum Order Value: ${formatUnits(r.minimum_order_value, r.currency)}`,
      trace_to: `Trace To: ${getTraceTo(r.trace_to)}`,
      valid_for_value: `Request valid for: ${getTime(r.valid_for_value, r.valid_for_units)}`,
    };
  }
  return {
    type: r.type,
    quoteRaw,
  };
};
