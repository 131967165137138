import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';

import { getLogsManagementStore } from '../../../selectors';
import { fetchLogsData } from '../../../actions/dev/logsActions';

import FormTitleSection from '../../FormComponents/TitleSection/index';
import { TabContainer } from '../../StyledComponents';
import Loader from '../../Loader';
import PaginationTable from '../../OrderManagement/SearchOrders/PaginationTable';
import DevLogsTable from '../DewLogsTable';
import DevLogsFilters from '../DevLogsFilters';
import { color } from '../../../constants';
import { logObjects } from '../../../constants/index';

const NoLogsContainer = styled.div`
  margin-top: 180px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 16px;
    color: ${color.grey};
  }
`;
const NoLogsBlock = (
  <NoLogsContainer>
    <p>There are no results that match your search.</p>
  </NoLogsContainer>
);

export default function DevLogs() {
  const refSearch = useRef(null);

  const { search, pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedObjectType, setSelectedObjectType] = useState(null);
  const [selectedPageSize, setSelectedPageSize] = useState({ value: '50', label: '50' });
  const [selectedActionsType, setSelectedActionsType] = useState(null);
  const [needChangeState, setNeedChangeState] = useState(true);

  const {
    actions,
    isFetching,
    page,
    total,
    pageSize,
  } = useSelector(getLogsManagementStore);

  useEffect(() => {
    refSearch.current = search;
    // default sort logs by date (!crutch!)
    const searchParams = queryString.stringify({
      ...queryString.parse(search),
      o: 'entry_ts',
      s: 'desc',
    });

    history.push({
      pathname,
      search: searchParams,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const nextQuery = queryString.parse(search);
    const currQuery = queryString.parse(refSearch.current);

    if ((search !== '' && needChangeState) && !isFetching) {
      setNeedChangeState(false);
      setSelectedObjectType(nextQuery.objectType
        ? mapObjectTypeFromQuery(parseInt(nextQuery.objectType, 10))
        : null);
      setSelectedPageSize(nextQuery.pageSize
        ? { value: nextQuery.pageSize, label: nextQuery.pageSize }
        : { value: '50', label: '50' });
      setSelectedActionsType(nextQuery.actionId && nextQuery.objectType
        ? mapActionObjFromQuery(parseInt(nextQuery.objectType, 10), parseInt(nextQuery.actionId, 10))
        : null);

      dispatch(fetchLogsData(nextQuery));
    }

    if ((nextQuery.page !== currQuery.page
      || nextQuery.pageSize !== currQuery.pageSize
      || nextQuery.o !== currQuery.o
      || nextQuery.s !== currQuery.s
      || nextQuery.objectType !== currQuery.objectType
      || nextQuery.actionId !== currQuery.actionId
    )) {
      dispatch(fetchLogsData(nextQuery));
    }

    refSearch.current = search;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search]);

  const onChangePageSize = (selectedPgSize) => {
    setSelectedPageSize(selectedPgSize || { value: '50', label: '50' });
    setNeedChangeState(false);

    const searchParams = queryString.stringify({
      ...queryString.parse(search),
      pageSize: selectedPgSize ? selectedPgSize.value : null,
      page: 1,
    });
    history.push({
      pathname,
      search: searchParams,
    });
  };

  const onChangeSelectedAction = (value) => {
    setSelectedActionsType(value || null);
    setNeedChangeState(false);

    const searchParams = queryString.stringify({
      ...queryString.parse(search),
      actionId: value ? value.value : null,
      page: 1,
    });
    history.push({
      pathname,
      search: searchParams,
    });
  };

  const onChangeSelectedObject = (value) => {
    setSelectedObjectType(value || null);
    setSelectedActionsType(null);
    setNeedChangeState(false);

    const searchParams = queryString.stringify({
      ...queryString.parse(search),
      objectType: value ? value.value : null,
      actionId: null,
      page: 1,
    });
    history.push({
      pathname,
      search: searchParams,
    });
  };

  const onSortChange = (sortName, sortOrder) => {
    const currQuery = queryString.parse(search);
    const { o } = currQuery;
    const sortFieldMapArray = { display_text: 'action', created_at: 'entry_ts' };
    const searchParams = queryString.stringify({
      ...queryString.parse(search),
      o: sortFieldMapArray[sortName],
      s: o !== sortFieldMapArray[sortName] && sortOrder === 'desc' ? 'asc' : sortOrder,
    });

    setNeedChangeState(false);
    history.push({
      pathname,
      search: searchParams,
    });
  };

  const onPaginationSelect = (eventKey) => {
    const searchParams = queryString.stringify({
      ...queryString.parse(search),
      page: eventKey,
    });

    setNeedChangeState(false);
    history.push({
      pathname,
      search: searchParams,
    });
  };

  const mapConstantsToSelectOptions = (constsData) => Object.values(
    Object.keys(constsData).map((k) => constsData[k]).map(
      (value) => [formatObjectToSelectProps(value)][0],
    ),
  );

  const defineActionTypesList = (selectedObjectTypeId) => {
    const selectedObjType = Object.values(logObjects).find((x) => x.id === selectedObjectTypeId);

    return selectedObjType ? selectedObjType.actions : null;
  };

  const formatObjectToSelectProps = (object) => ({
    label: object.name, value: object.id,
  });

  const mapObjectTypeFromQuery = (objectTypeId) => {
    const selectedObjType = Object.values(logObjects).find((x) => x.id === objectTypeId);
    return selectedObjType ? formatObjectToSelectProps(selectedObjType) : null;
  };

  const mapActionObjFromQuery = (objectTypeId, actionId) => {
    const selectedAction = Object.values(defineActionTypesList(objectTypeId)).find((x) => x.id === actionId);
    return selectedAction ? formatObjectToSelectProps(selectedAction) : null;
  };

  const currQuery = queryString.parse(search);
  const sortFieldMapArray = { action: 'display_text', entry_ts: 'created_at' };

  const logsTableOptions = {
    sortName: sortFieldMapArray[currQuery.o],
    sortOrder: currQuery.s,
    onSortChange,
  };

  const pagesCount = Math.ceil(total / pageSize);
  const paginator = (total > pageSize) && (
    <PaginationTable currentPage={page} totalPages={pagesCount} onChange={onPaginationSelect} />
  );

  const loader = isFetching ? <Loader /> : null;

  if (isFetching) return loader;

  const tableData = Object.keys(actions).map((k) => actions[k]);
  const mainContent = (total > 0) ? (
    <DevLogsTable tableData={tableData} logsTableOptions={logsTableOptions} />
  ) : NoLogsBlock;

  return (
    <div>
      <FormTitleSection title="Logs" showEditButton={false} />
      <TabContainer>
        <DevLogsFilters
          selectedObjectType={selectedObjectType}
          selectedPageSize={selectedPageSize}
          selectedActionsType={selectedActionsType}
          mapConstantsToSelectOptions={mapConstantsToSelectOptions}
          defineActionTypesList={defineActionTypesList}
          onChangeSelectedObject={onChangeSelectedObject}
          onChangePageSize={onChangePageSize}
          onChangeSelectedAction={onChangeSelectedAction}
        />
        {mainContent}
        {paginator}
      </TabContainer>
    </div>
  );
}
