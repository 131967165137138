import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import A from '../A';
import Img from './Img';
import Logo from './ePlaneAi.svg';
import NavBar from './Navbar';
import { color, font, PUBLIC_URL } from '../../constants';

const SiteLogo = styled(Img)`
  @media (max-width: 576px) {
    width: 60px;
  }
  @media (max-width: 1200px) {
    width: 100px;
  }
`;
const HeaderWrapper = styled.div`
  background-color: ${color.white};
  border-bottom: 1px solid ${color.lightGrey};
  padding: 0 16px;
`;
const Title = styled.div`
  color: ${color.darkGrey};
  font-family: ${font.bold};
  font-size: 24px;
  font-weight: 700;
  padding: 0 0 0 60px;
  display: inline-block;
  @media (max-width: 1100px) {
    font-size: 18px;
    padding-left: 30px;
  }
`;
const IconContainer = styled.span`
  color: ${color.birch};
  margin-right: 5px;
  padding-left: 10px;

  &::before {
    background: ${color.altoGrey};
    content: "";
    display: block;
    height: 34px;
    left: 0;
    margin-top: -17px;
    position: absolute;
    top: 50%;
    width: 1px;
  }
`;

function renderIcon() {
  return (
    <IconContainer>
      <FontAwesomeIcon icon="user-circle" size="2x" />
    </IconContainer>
  );
}

const Header = ({ isAuthenticated }) => (
  <HeaderWrapper>
    <Navbar collapseOnSelect expand="xl">
      <A to={PUBLIC_URL || '/'}>
        <Navbar.Brand>
          <SiteLogo src={Logo} alt="ePlane Logo" className="d-inline-block align-top" />
          <Title>Back Office</Title>
        </Navbar.Brand>
      </A>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      {isAuthenticated && (
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav>
          <NavBar />
        </Nav>
        <Nav>
          <NavDropdown alignRight title={renderIcon()} id="collasible-nav-dropdown">
            <NavDropdown.Item as={A} to={`${PUBLIC_URL}/profile`}>Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={A} to={`${PUBLIC_URL}/logout`}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      )}
    </Navbar>
  </HeaderWrapper>
);
Header.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default Header;
