import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { color } from '../../../constants';

const Wrapper = styled.div`
  color: ${(props) => (props.isSuccess ? color.textGreen : color.textRed)};
  margin: 0 0 20px 0;
  font-size: 16px;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')}
`;

const StatusIndicator = ({ isSuccess, isVisible, text }) => (
  <Wrapper isSuccess={isSuccess} isVisible={isVisible}>
    <FontAwesomeIcon icon={isSuccess ? 'check' : 'exclamation-triangle'} />
    {' '}
    {text}
  </Wrapper>
);

StatusIndicator.propTypes = {
  isVisible: PropTypes.bool,
  isSuccess: PropTypes.bool,
  text: PropTypes.string,
};

export default StatusIndicator;
