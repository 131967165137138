import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone';

import {
  TitleSection, Title, ButtonContainer, HorizontalLine, FixedWidthContainer,
} from '../StyleComponents';
import { dropezoneContent, filledDropezoneContent } from './dropezoneContent';
import ProgressModal from '../../Modals/ProgressModal';
import SuccessModal from './SuccessModal';
import WrongFormatModal from './WrongFormatModal';
import WrongNumOfFilesModal from './WrongNumOfFilesModal';
import ErrorModalsForInventorySection from './ErrorModalsForInventorySection';
import ModalLoader from '../../ModalLoader';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import FormRadioGroup from './RadioGroup';
import * as inventoryActions from '../../../actions/inventoryDocsActions';
import { fetchUserSites } from '../../../actions/userCompanyManagement/sitesActions';
import { getUploaderStore, getCompanyListStore, getUserSitesStore } from '../../../selectors';
import {
  fetchCompanyList,
  errorRemove as removeCompanyListError,
} from '../../../actions/companyListActions';
import { DEFAULT_SELECT_KEY, color } from '../../../constants';

const DropezoneContainer = styled.div`
  display: flex;
  justify-content: start;
  padding: 10px 0 20px 0;
`;

const DialogContainer = styled.div`
  padding: 0px 30px 10px 30px;
`;

const FooterButtonContainer = styled(ButtonContainer)`
  display: flex;
  justify-content: space-between;
`;

class InventoryUploadPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inventoryFiles: [],
      archiveFiles: [],
      userId: DEFAULT_SELECT_KEY,
      siteId: DEFAULT_SELECT_KEY,
      jobType: 'parts',

      showWrongFormatDialog: false,
      showWrongNumOfFilesDialog: false,
      rejectedFileName: null,
    };
  }

  componentDidMount() {
    const { fetchCompanyList: fetchCompanyListA } = this.props;
    fetchCompanyListA();
  }

  onChange = (e) => {
    const { fetchUserSites: fetchUserSitesA } = this.props;
    const { name, value } = e.target;
    if (name === 'userId') {
      fetchUserSitesA(value);
    }
    this.setState((prevState) => {
      const newData = { ...prevState };
      newData[name] = value;
      newData.siteId = name === 'userId' ? null : newData.siteId;

      return newData;
    });
  }

  onInventoryDrop = (files) => {
    this.setState({
      inventoryFiles: [...files],
    });
  }

  onArchiveDrop = (files) => {
    this.setState({
      archiveFiles: [...files],
    });
  }

  onUpload = () => {
    const { uploadDocumentToIM, userId: userIdPr } = this.props;
    const {
      inventoryFiles,
      archiveFiles,
      siteId,
      userId,
      jobType,
    } = this.state;
    uploadDocumentToIM(inventoryFiles[0], archiveFiles[0], userIdPr || userId, siteId, jobType);
  }

  onInventoryRemove = () => {
    this.setState({ inventoryFiles: [] });
  }

  onArchiveRemove = () => {
    this.setState({ archiveFiles: [] });
  }

  onSuccessHide = () => {
    const { onHide, resetUploadingProgress } = this.props;
    onHide();
    resetUploadingProgress();
  }

  onDropRejected = (file) => {
    if (file.length > 1) {
      this.setState({ showWrongNumOfFilesDialog: true });
    } else {
      this.setState({ showWrongFormatDialog: true, rejectedFileName: file[0].name });
    }
  }

  onWrongFormatDialogHide = () => {
    this.setState({ showWrongFormatDialog: false, rejectedFileName: null });
  }

  onWrongFileFormatHide = () => this.setState({ showWrongNumOfFilesDialog: false });

  getCustomerListForSelect = () => {
    const { companies } = this.props;
    if (!companies) return [];

    const optionList = companies.map((c) => [c.company_id, c.company_name]);
    optionList.splice(0, 0, [DEFAULT_SELECT_KEY, 'not selected']);

    return optionList;
  }

  getSiteListForSelect = () => {
    const { sites: { sites } } = this.props;

    if (!sites) return [[DEFAULT_SELECT_KEY, 'not selected']];
    const optionList = sites.filter((v) => v.site_type === 2).map((c) => [c.site_id, `${c.name} - ${c.location}`]);

    if (optionList.length === 0) return [[DEFAULT_SELECT_KEY, 'no sites']];
    optionList.splice(0, 0, [DEFAULT_SELECT_KEY, 'not selected']);

    return optionList;
  }

  render() {
    const {
      uploader: {
        isUploading,
        errorCode,
        uploadingProgress,
      },
      removeErrorCode,
      companyListErrorCode,
      isCompanyListFetching,
      removeCompanyListError: removeCompanyListErrorA,
      userId: userIdPr,
      companies,
    } = this.props;
    const {
      userId,
      siteId,
      jobType,
      inventoryFiles,
      archiveFiles,
      showWrongFormatDialog,
      showWrongNumOfFilesDialog,
      rejectedFileName,
    } = this.state;
    const siteOptions = this.getSiteListForSelect();
    const locationDisabled = (userId === DEFAULT_SELECT_KEY || siteOptions.length < 2) && !userIdPr;
    const showSuccessModal = !isUploading && uploadingProgress === 100;
    const dropzoneDisabled = !siteId || siteId === DEFAULT_SELECT_KEY;
    const archiveDisabled = inventoryFiles.length === 0;
    const uploadDisabled = dropzoneDisabled || inventoryFiles.length === 0;

    const searchCompany = companies.find((v) => v.company_id === userIdPr);
    const uploadCompanyName = searchCompany && searchCompany.company_name;

    let inventoryContent = null;
    let archiveContent = null;

    if (inventoryFiles.length > 0) {
      inventoryContent = filledDropezoneContent(inventoryFiles[0], this.onInventoryRemove);
    } else {
      inventoryContent = dropezoneContent('Drag file here', '(.xls / .xlsx / .csv)', dropzoneDisabled);
    }

    if (archiveFiles.length > 0) {
      archiveContent = filledDropezoneContent(archiveFiles[0], this.onArchiveRemove);
    } else {
      archiveContent = dropezoneContent('Drag zip file here', '(contains .doc, .pdf, .jpg, .png, .gif, .tif, .tiff)', archiveDisabled);
    }

    return (
      <DialogContainer>
        <TitleSection>
          <Title>Upload Inventory</Title>
        </TitleSection>
        <form>
          <FixedWidthContainer maxWidth="380px">
            { userIdPr
              ? (
                <p>
                  Upload inventory/capabilities for&nbsp;
                  <strong>{uploadCompanyName}</strong>
                  .
                </p>
              )
              : (
                <BaseFormGroup
                  name="userId"
                  labelText="Choose a company"
                  as="select"
                  options={this.getCustomerListForSelect()}
                  value={userIdPr || userId}
                  onChange={this.onChange}
                />
              )}
            <BaseFormGroup
              name="siteId"
              labelText="To which location would you like to upload your inventory?"
              as="select"
              value={siteId || ''}
              options={siteOptions}
              disabled={locationDisabled}
              onChange={this.onChange}
            />
          </FixedWidthContainer>
          <HorizontalLine />
          <FixedWidthContainer maxWidth="380px">
            <FormRadioGroup
              checkedValue={jobType || 'parts'}
              radioList={[{ value: 'parts', labelText: 'Inventory' }, { value: 'repair', labelText: 'Capabilities' }]}
              disabled={dropzoneDisabled}
              name="jobType"
              onChange={this.onChange}
              inline
            />
          </FixedWidthContainer>
          <strong>Upload your inventory (inventory file &amp; a zip file)</strong>
          <DropezoneContainer>
            <Dropzone
              onDrop={this.onInventoryDrop}
              disabled={dropzoneDisabled}
              accept=".xls,.xlsx,.csv"
              onDropRejected={this.onDropRejected}
              multiple={false}
              disableClick
            >
              {inventoryContent}
            </Dropzone>
            <Dropzone
              onDrop={this.onArchiveDrop}
              disabled={archiveDisabled}
              accept=".zip"
              onDropRejected={this.onDropRejected}
              multiple={false}
              disableClick
            >
              {archiveContent}
            </Dropzone>
          </DropezoneContainer>
          <HorizontalLine color={color.darkBlue} />
          <FooterButtonContainer btnWidth="120px">
            <Button variant="outline-secondary" onClick={this.onSuccessHide}>Cancel</Button>
            <Button variant="primary" onClick={this.onUpload} disabled={uploadDisabled}>Upload</Button>
          </FooterButtonContainer>
        </form>

        <ErrorModalsForInventorySection
          errorCode={errorCode}
          companyListErrorCode={companyListErrorCode}
          onHide={removeErrorCode}
          onHideCompanies={removeCompanyListErrorA}
        />
        <ModalLoader show={isCompanyListFetching} />
        <ProgressModal show={isUploading} now={uploadingProgress} />
        <SuccessModal show={showSuccessModal} text="We are currently processing your inventory." onHide={this.onSuccessHide} />
        <WrongFormatModal show={showWrongFormatDialog} fileName={rejectedFileName} onHide={this.onWrongFormatDialogHide} />
        <WrongNumOfFilesModal show={showWrongNumOfFilesDialog} onHide={this.onWrongFileFormatHide} />
      </DialogContainer>
    );
  }
}

function mapStateToProp(state) {
  const uploader = getUploaderStore(state);
  const {
    companies,
    errorCode: companyListErrorCode,
    isFetching: isCompanyListFetching,
  } = getCompanyListStore(state);
  const sites = getUserSitesStore(state);

  return {
    uploader,

    companies,
    companyListErrorCode,
    isCompanyListFetching,

    sites,
  };
}

const actions = {
  ...inventoryActions,
  fetchUserSites,
  fetchCompanyList,
  removeCompanyListError,
};

export default connect(mapStateToProp, actions)(InventoryUploadPage);

InventoryUploadPage.propTypes = {
  uploader: PropTypes.shape({
    isUploading: PropTypes.bool,
    errorCode: PropTypes.number,
    uploadingProgress: PropTypes.number,
  }),
  userId: PropTypes.string,
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  companyListErrorCode: PropTypes.number,
  isCompanyListFetching: PropTypes.bool,
  sites: PropTypes.shape({
    sites: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  removeErrorCode: PropTypes.func,
  uploadDocumentToIM: PropTypes.func,
  fetchCompanyList: PropTypes.func,
  removeCompanyListError: PropTypes.func,
  fetchUserSites: PropTypes.func,
  resetUploadingProgress: PropTypes.func,
  onHide: PropTypes.func,
};
