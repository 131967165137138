import axios from 'axios';
import createErrorValidatorObj from '../../../utils/createErrorValidationObj';
import emailValidator from '../../../utils/emailValidator';

// const createNotEmptyValidator = errorText => value => (value ? null : createErrorValidatorObj(errorText));

const AccountSettingsValidators = {
  firstname: (value) => {
    if (!value) {
      return createErrorValidatorObj('This field is required.');
    }
    return null;
  },
  lastname: (value) => {
    if (!value) {
      return createErrorValidatorObj('This field is required.');
    }
    return null;
  },
  company: () => null,
  pnone: () => null,
  mobile: () => null,
  wechat: () => null,
  email: async (errCB, email) => {
    let result = null;
    if (!emailValidator(email)) {
      result = createErrorValidatorObj('Please, enter valid email.');
    }
    if (!email) {
      result = createErrorValidatorObj('Please, enter your email.');
    }
    if (result) return result;
    return checkEmailAvailability(errCB, email);
  },
};

async function checkEmailAvailability(errCB, customerEmail) {
  try {
    const response = await axios.get('/rest/default/V1/eplane/checkEmail', { params: { email: customerEmail } });
    if (!response.data) return null;
    return createErrorValidatorObj('This email address already exists in the system. Please use a different email address.');
  } catch (e) {
    errCB(e);
    return null;
  }
}

export default AccountSettingsValidators;
