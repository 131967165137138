import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Select from 'react-select';

import { taskStatus as taskStatusConst } from '../../../../constants/index';
import DatesPicker from '../../../DatesPicker';
import {
  ApplyButton, DatesContainer, ClearAllButton, LabelText, LinkButton, Icon,
} from './StyledComponents';

class FiltersDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      taskStatus: null,
      isApplyDisabled: true,
      isClearAllDisabled: true,
      showDropDown: false,
    };
  }

  componentDidMount() {
    const { taskListFetchingParams } = this.props;
    this.setState({
      startDate: taskListFetchingParams.startDate,
      endDate: taskListFetchingParams.endDate,
      taskStatus: taskListFetchingParams.taskStatus,
      isClearAllDisabled: !taskListFetchingParams.startDate && !taskListFetchingParams.endDate && !taskListFetchingParams.taskStatus,
    });
  }

  componentDidUpdate() {
    const { taskListFetchingParams, setTaskFetchingParams } = this.props;
    if (taskListFetchingParams.clearFiltersState) {
      setTaskFetchingParams({
        clearFiltersState: false,
      });
      // eslint-disable-next-line
      this.setState({
        startDate: null,
        endDate: null,
        taskStatus: null,
        isClearAllDisabled: true,
      });
    }
    return null;
  }

  onApply = () => {
    const { taskListFetchingParams, fetchTasksListWithParams, changeTaskListFetchingParams } = this.props;
    const { startDate: selectedStartDate, endDate: selectedEndDate, taskStatus: selectedStatus } = this.state;
    changeTaskListFetchingParams({
      ...taskListFetchingParams,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      taskStatus: selectedStatus,
    });
    fetchTasksListWithParams({
      ...taskListFetchingParams,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      taskStatus: selectedStatus,
    });
    this.setState({ isApplyDisabled: true, showDropDown: false });
  };

  onClearAll = () => {
    const {
      queryParams: {
        f: startDateFromQuery,
        t: endDateFromQuery,
        s: taskStatusFromQuery,
      },
    } = this.props;
    this.setState({
      startDate: null,
      endDate: null,
      taskStatus: null,
      isApplyDisabled: !taskStatusFromQuery && !endDateFromQuery && !startDateFromQuery,
      isClearAllDisabled: true,
    });
  };

  onCalendarChange = ({ startDate: selectedStartDate, endDate: selectedEndDate }) => {
    const {
      queryParams: {
        f: startDateFromQuery,
        t: endDateFromQuery,
        s: taskStatusFromQuery,
      },
    } = this.props;
    const { taskStatus: selectedStatus } = this.state;
    this.setState({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      isClearAllDisabled: false,
      isApplyDisabled: (
        (!endDateFromQuery && !selectedEndDate)
        || endDateFromQuery === selectedEndDate
        || (selectedEndDate && selectedEndDate.format('YYYY-MM-DD') === endDateFromQuery))
        && ((!startDateFromQuery && !selectedStartDate)
          || startDateFromQuery === selectedStartDate
          || (selectedStartDate && selectedStartDate.format('YYYY-MM-DD') === startDateFromQuery))
      && (taskStatusFromQuery === selectedStatus || (selectedStatus && selectedStatus.value === taskStatusFromQuery)),
    });
  }

  onStatusChange = (selectedStatus) => {
    const {
      queryParams: {
        f: startDateFromQuery,
        t: endDateFromQuery,
        s: taskStatusFromQuery,
      },
    } = this.props;
    const { startDate: selectedStartDate, endDate: selectedEndDate } = this.state;
    this.setState({
      taskStatus: selectedStatus,
      isClearAllDisabled: false,
      isApplyDisabled: (
        (!endDateFromQuery && !selectedEndDate)
        || endDateFromQuery === selectedEndDate
        || (selectedEndDate && selectedEndDate.format('YYYY-MM-DD') === endDateFromQuery))
        && ((!startDateFromQuery && !selectedStartDate)
          || startDateFromQuery === selectedStartDate
          || (selectedStartDate && selectedStartDate.format('YYYY-MM-DD') === startDateFromQuery))
        && (taskStatusFromQuery === selectedStatus || (selectedStatus && selectedStatus.value === taskStatusFromQuery)),
    });
  };

  displayFormat = () => moment.localeData().longDateFormat('L');

  render() {
    const FilterOptions = [{
      label: 'Open tasks', value: taskStatusConst.OPEN,
    }, {
      label: 'Closed tasks', value: taskStatusConst.CLOSED,
    }, {
      label: 'In progress', value: taskStatusConst.IN_PROGRESS,
    }, {
      label: 'On Hold', value: taskStatusConst.ON_HOLD,
    }];

    const {
      startDate,
      endDate,
      taskStatus,
      isClearAllDisabled,
      isApplyDisabled,
      showDropDown,
    } = this.state;
    const displayFormat = 'YYYY-MM-DD';

    return (
      <>
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="bottom"
          show={showDropDown}
          onToggle={() => this.setState({ showDropDown: false })}
          overlay={(
            <Popover id="popover-basic">
              <Popover.Content>
                <ClearAllButton
                  variant="link"
                  disabled={isClearAllDisabled}
                  onClick={this.onClearAll}
                >
                  Clear all
                </ClearAllButton>
                <LabelText>Task status</LabelText>
                <Select
                  value={taskStatus}
                  onChange={this.onStatusChange}
                  options={FilterOptions}
                  placeholder="Choose category..."
                  isSearchable={false}
                />
                <LabelText>Date</LabelText>
                <DatesContainer>
                  <DatesPicker
                    displayFormat={displayFormat}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={this.onCalendarChange}
                    minimumNights={0}
                  />
                </DatesContainer>
                <ApplyButton variant="outline-secondary" disabled={isApplyDisabled} onClick={this.onApply}>Apply</ApplyButton>
              </Popover.Content>
            </Popover>
          )}
        >
          <LinkButton variant="link" title="Filters" onClick={() => this.setState({ showDropDown: true })}>
            <Icon icon="filter" />
          </LinkButton>
        </OverlayTrigger>
      </>
    );
  }
}

FiltersDropDown.propTypes = {
  setTaskFetchingParams: PropTypes.func,
  taskListFetchingParams: PropTypes.shape({
    startDate: PropTypes.instanceOf(moment),
    endDate: PropTypes.instanceOf(moment),
    taskStatus: PropTypes.shape({
      value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
    clearFiltersState: PropTypes.bool,
  }),
  queryParams: PropTypes.shape({
    f: PropTypes.string,
    t: PropTypes.string,
    s: PropTypes.string,
  }),
  fetchTasksListWithParams: PropTypes.func.isRequired,
  changeTaskListFetchingParams: PropTypes.func.isRequired,
};

export default FiltersDropDown;
