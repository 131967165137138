import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button, Form,
} from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';

import AutosuggestControl from '../../FormComponents/AutosuggestControl';
import FormCheckboxGroup from '../../CheckboxGroupBasic';
import { BubbleItemContainer } from '../SellersLists/Helpers';
import { companyType, buyerFilterTypes } from '../../../constants/index';

const InlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
const filterCompanyTypes = {
  ...companyType,
  PRIVATE: {
    id: -1,
    name: 'Standalone user (unverified company)',
  },
};

export default function BlockForm({
  category, countries, initialCompanyTypes, onAddHandler, checkUnique,
}) {
  const lastSuggestionRef = useRef([]);

  const [search, setSearch] = useState({ title: '', code: null });
  const [selectedItems, setSelectedItems] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  let formContent = null;

  useEffect(() => {
    setSelectedItems([]);
  }, [category]);

  const companyTypeList = Object.values(filterCompanyTypes).map(({ id, name }) => ({
    value: id,
    labelText: name,
  }));

  const onBlur = () => {};

  const onSuggestionsFetchRequested = ({ value }) => {
    if (value.length < 3) return;

    let data = [];
    if (category === buyerFilterTypes.buyer_country_filters) {
      const searchValue = value.toLowerCase();

      data = countries.filter((country) => (
        country.full_name_english.toLowerCase().includes(searchValue)
          && !selectedItems.some((selected) => selected.code === country.id)
          && checkUnique(country.id, buyerFilterTypes.buyer_country_filters)))
        .map((country) => ({ code: country.id, title: country.full_name_english }))
        .slice(0, 10);

      setSuggestions(data);
    } else if (category === buyerFilterTypes.buyer_company_filters) {
      axios(`/suggest/buyer-list/company?q=${value}&user_type=2`).then((response) => {
        data = response.data.payload?.items;
        lastSuggestionRef.current = data;

        data = data.filter((buyer) => (
          !selectedItems.some((selected) => selected.code === buyer.code)
            && checkUnique(buyer.code, buyerFilterTypes.buyer_company_filters)))
          .slice(0, 10);

        setSuggestions(data);
      }).catch((e) => console.error(e)); // eslint-disable-line no-console
    } else if (category === buyerFilterTypes.buyer_private_user_filters) {
      axios(`/suggest/buyer-list/company?q=${value}&user_type=1`).then((response) => {
        data = response.data.payload?.items;
        lastSuggestionRef.current = data;
        data = data.filter((buyer) => (
          !selectedItems.some((selected) => selected.code === buyer.code)
            && checkUnique(buyer.code, buyerFilterTypes.buyer_private_user_filters)))
          .slice(0, 10);

        setSuggestions(data);
      }).catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const getSuggestionValue = (suggestion) => suggestion.title;

  const renderSuggestion = (suggestion) => <span>{suggestion.title}</span>;

  const onAddFilterItemHandler = () => {
    let data = null;
    if (category === buyerFilterTypes.buyer_type_filters) {
      data = {
        company_types: selectedItems.filter((v) => initialCompanyTypes.every((t) => t !== v)),
      };
    } else if (category === buyerFilterTypes.buyer_country_filters) {
      data = { country_codes: selectedItems.map((country) => country.code) };
    } else if (category === buyerFilterTypes.buyer_company_filters) {
      data = { user_ids: selectedItems.map((user) => user.code) };
    } else if (category === buyerFilterTypes.buyer_private_user_filters) {
      data = { user_ids: selectedItems.map((user) => user.code) };
    }

    onAddHandler(data);
    setSelectedItems([]);
    setSearch({ title: '', code: null });
  };

  const onSuggestionSelected = (e, { suggestion }) => {
    setSelectedItems((prev) => ([...prev, suggestion]));
    setSearch({ title: '', code: null });
  };

  const onRemoveFilter = (code) => {
    setSelectedItems((prev) => prev.filter((item) => item.code !== code));
  };

  const onCheckboxChange = (e) => {
    const { checked, value } = e.target;

    setSelectedItems((prev) => {
      let newFilters = null;
      if (!checked) {
        newFilters = prev.filter((v) => v !== +value);
      } else {
        newFilters = prev.concat(+value);
      }
      return newFilters;
    });
  };

  if (category === buyerFilterTypes.buyer_type_filters) {
    formContent = (
      <>
        <p>Select company types to block:</p>
        <InlineContainer>
          <FormCheckboxGroup
            checkList={companyTypeList || []}
            checkedValues={[...selectedItems, ...initialCompanyTypes]}
            disabledValues={initialCompanyTypes}
            name="company_type"
            onChange={onCheckboxChange}
            inline
          />
          <Button
            variant="primary"
            disabled={selectedItems.length === 0}
            onClick={onAddFilterItemHandler}
          >
            Add company types
          </Button>
        </InlineContainer>
      </>
    );
  } else if (category === buyerFilterTypes.buyer_country_filters) {
    formContent = (
      <>
        <Row>
          <Col sm={4}>
            <AutosuggestControl
              labelColumnWidth={0}
              controlColumnWidth={12}
              value={search.title}
              suggestions={suggestions}
              placeholder="Search by county"
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              onSuggestionSelected={onSuggestionSelected}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              onChange={(e) => setSearch({ title: e.target.value, code: null })}
              onClear={() => setSearch({ title: '', code: null })}
              onBlur={onBlur}
            />
          </Col>
          <Col sm={4}>
            <Button
              variant="primary"
              disabled={selectedItems.length === 0}
              onClick={onAddFilterItemHandler}
            >
              Add countries
            </Button>
          </Col>
        </Row>
        <Row noGutters>
          <Col sm={8}>
            <Row noGutters>
              <Col sm={9}>
                <BubbleItemContainer list={selectedItems} onRemove={onRemoveFilter} />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  } else if (category === buyerFilterTypes.buyer_company_filters) {
    formContent = (
      <>
        <Row>
          <Col sm={4}>
            <AutosuggestControl
              labelColumnWidth={0}
              controlColumnWidth={12}
              value={search.title}
              suggestions={suggestions}
              placeholder="Search by company"
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              onSuggestionSelected={onSuggestionSelected}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              onChange={(e) => setSearch({ title: e.target.value, code: null })}
              onClear={() => setSearch({ title: '', code: null })}
              onBlur={onBlur}
            />
          </Col>
          <Col sm={4}>
            <Button
              variant="primary"
              disabled={selectedItems.length === 0}
              onClick={onAddFilterItemHandler}
            >
              Add buyers
            </Button>
          </Col>
        </Row>
        <Row noGutters>
          <Col sm={8}>
            <Row noGutters>
              <Col sm={9}>
                <BubbleItemContainer list={selectedItems} onRemove={onRemoveFilter} />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  } else if (category === buyerFilterTypes.buyer_private_user_filters) {
    formContent = (
      <>
        <Row>
          <Col sm={4}>
            <AutosuggestControl
              labelColumnWidth={0}
              controlColumnWidth={12}
              value={search.title}
              suggestions={suggestions}
              placeholder="Search by buyer's name"
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              onSuggestionSelected={onSuggestionSelected}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              onChange={(e) => setSearch({ title: e.target.value, code: null })}
              onClear={() => setSearch({ title: '', code: null })}
              onBlur={onBlur}
            />
          </Col>
          <Col sm={4}>
            <Button
              variant="primary"
              disabled={selectedItems.length === 0}
              onClick={onAddFilterItemHandler}
            >
              Add buyers
            </Button>
          </Col>
        </Row>
        <Row noGutters>
          <Col sm={8}>
            <Row noGutters>
              <Col sm={9}>
                <BubbleItemContainer list={selectedItems} onRemove={onRemoveFilter} />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Form>{formContent}</Form>
  );
}

BlockForm.propTypes = {
  category: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.object),
  initialCompanyTypes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onAddHandler: PropTypes.func.isRequired,
  checkUnique: PropTypes.func.isRequired,
};
