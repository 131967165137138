import axios from 'axios';

import {
  PAYMENT_PROVIDERS_GET,
  PAYMENT_PROVIDERS_GET_SUCCESS,
  PAYMENT_PROVIDERS_SAVE,
  PAYMENT_PROVIDERS_SAVE_SUCCESS,
  PAYMENT_PROVIDERS_FAIL,
  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';

const startFetchingPaymentProviders = () => ({
  type: PAYMENT_PROVIDERS_GET,
});
const fetchPaymentProvidersSuccess = (providersList) => ({
  type: PAYMENT_PROVIDERS_GET_SUCCESS,
  payload: providersList,
});
const startSavingPaymentProviders = () => ({
  type: PAYMENT_PROVIDERS_SAVE,
});
const savePaymentProvidersSuccess = (providersList) => ({
  type: PAYMENT_PROVIDERS_SAVE_SUCCESS,
  payload: providersList,
});
const paymentProvidersFail = () => ({ type: PAYMENT_PROVIDERS_FAIL });

export const fetchProviders = () => async (dispatch) => {
  dispatch(startFetchingPaymentProviders());
  try {
    const response = await axios('rest/V2/eplane/backoffice/payment/providers');
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchPaymentProvidersSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, paymentProvidersFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, paymentProvidersFail, e);
  }
};

export const saveProviders = (data) => async (dispatch) => {
  dispatch(startSavingPaymentProviders());
  try {
    const response = await axios.post('rest/V2/eplane/backoffice/payment/change-provider-fee', data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(savePaymentProvidersSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, paymentProvidersFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, paymentProvidersFail, e);
  }
};
