import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button, OverlayTrigger, Tooltip, Row, Col, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledLinkButton as LinkButton } from '../../StyledComponents';
import TwoInputForms from './TwoInputForm';
import { WeightListTitle } from '../StyleComponents';

const CompanyWeightLabel = styled.p`
  margin-bottom: 10px;
  margin-top: 20px;
`;
const StyledLinkButton = styled(LinkButton)`
  padding-left: 0;
`;
const tooltip = (text) => (
  <Tooltip id="tooltip">
    {text}
  </Tooltip>
);

const CompanyWeightForm = (props) => {
  const {
    companies,
    suggestion,
    disabled,
    onAddCompanyBtnClick,
    onDeleteAllClick,
    ...other
  } = props;
  let content;

  if (!companies || !suggestion || companies.length === 0) {
    content = (
      <>
        <OverlayTrigger placement="right" overlay={tooltip('Define the weight for the company')}>
          <Button disabled={disabled} onClick={onAddCompanyBtnClick} variant="success" size="sm">
            <FontAwesomeIcon icon="plus" />
            &nbsp;Add
          </Button>
        </OverlayTrigger>
      </>
    );
  } else {
    content = (
      <Form>
        <Row>
          <Col sm={1}><WeightListTitle>#</WeightListTitle></Col>
          <Col sm={4}><WeightListTitle>Company name</WeightListTitle></Col>
          <Col sm={2}><WeightListTitle>Weight</WeightListTitle></Col>
        </Row>
        <CompanyWeightList
          companies={companies}
          suggestion={suggestion}
          disabled={disabled}
          {...other}
        />
        <Row>
          <Col sm={2}>
            <Button disabled={disabled} onClick={onAddCompanyBtnClick} variant="success" size="sm">
              <FontAwesomeIcon icon="plus" />
              &nbsp;Add
            </Button>
          </Col>
          <Col sm={{ span: 2, offset: 5 }}>
            <StyledLinkButton variant="link" onClick={onDeleteAllClick} disabled={disabled}>
              <FontAwesomeIcon icon="trash-alt" />
              &nbsp;
              Remove All
            </StyledLinkButton>
          </Col>
        </Row>
      </Form>
    );
  }

  return (
    <div>
      <CompanyWeightLabel>Weights for different companies for TIER 3:</CompanyWeightLabel>
      {content}
    </div>
  );
};

const CompanyWeightList = ({
  companies,
  suggestion,
  disabled,
  onChange,
  onDeleteCompanyBtbClick,
  onCompanyFocusout,
}) => {
  if (!companies || !suggestion) return null;

  return companies.map((company, idx) => (
    <TwoInputForms
      value={company}
      key={idx.toString()}
      disabled={disabled}
      handleDeleteBtn={onDeleteCompanyBtbClick(idx)}
      onChange={onChange}
      onCompanyFocusout={onCompanyFocusout}
      companies={suggestion}
      counter={idx.toString()}
    />
  ));
};

CompanyWeightForm.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  suggestion: PropTypes.arrayOf(PropTypes.object),
  companies: PropTypes.arrayOf(PropTypes.object),
  onAddCompanyBtnClick: PropTypes.func.isRequired,
  onDeleteCompanyBtbClick: PropTypes.func.isRequired,
  onDeleteAllClick: PropTypes.func.isRequired,
  onCompanyFocusout: PropTypes.func.isRequired,
};

export default CompanyWeightForm;
