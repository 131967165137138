import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getChatStore } from '../../selectors';
import { fetchChatData, clearChatData } from '../../actions/OrderManagement/chatDataActions';
import InternalErrorContainer from '../../components/InternalErrorContainer';
import Loader from '../../components/Loader';
import Container from '../../components/Container';
import ChatPage from '../../components/OrderManagement/ChatPage/ChatPage';

const OrderViewWrapper = styled.div`
  font-size: 16px;
  letter-spacing: 0.425px;
  max-width: 1050px;
  margin: 30px auto;
`;

const ChatPageWrapper = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const { order, isFetching: isChatFetching } = useSelector(getChatStore);

  useEffect(() => {
    dispatch(fetchChatData(orderId));
    return () => dispatch(clearChatData());
  }, [dispatch, orderId]);

  if (!order && isChatFetching) return <Loader />;

  if (!order) {
    return (
      <InternalErrorContainer>
        Oops, something went wrong...
        <br />
      </InternalErrorContainer>
    );
  }

  return (
    <Container links={[{ 'Order Management': 'orders' }, { [orderId]: null }]}>
      <OrderViewWrapper>
        <ChatPage orderId={orderId} showActionsDropdown />
      </OrderViewWrapper>
    </Container>
  );
};

export default ChatPageWrapper;
