import axios from 'axios';

import {
  BOUNCE_EMAILS_GET,
  BOUNCE_EMAILS_GET_SUCCESS,
  BOUNCE_EMAILS_FAIL,

  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';

const startFetchingBounceEmails = () => ({
  type: BOUNCE_EMAILS_GET,
});
const fetchBounceEmailsSuccess = (logsData) => ({
  type: BOUNCE_EMAILS_GET_SUCCESS,
  payload: logsData,
});
const bounceEmailsFecthingFail = (error) => ({
  type: BOUNCE_EMAILS_FAIL,
  payload: error,
});

export default (queryParams) => async (dispatch) => {
  dispatch(startFetchingBounceEmails());
  try {
    const response = await axios('rest/default/V2/eplane/backoffice/bounce-emails', { params: queryParams });
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchBounceEmailsSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, bounceEmailsFecthingFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, bounceEmailsFecthingFail, e);
  }
};
