import {
  UPLOADING_START,
  UPLOADING_FINISH,
  UPLOADING_PROGRESS_SET,

  UPLOADING_PROGRESS_RESET,
  UPLOADING_TERMINATE,
  UPLOADING_ERROR_OCCURRED,
  UPLOADING_RESET,

  AXIOS_CANCELLED_MESSAGE,
} from '../constants';
import errorHandler from './requestErrorHandler';

export const startUploadingFile = (source) => ({
  type: UPLOADING_START,
  payload: source,
});
export const finishUploadingFile = (document) => ({
  type: UPLOADING_FINISH,
  payload: document,
});

const setUploadingFileProgress = (percentOfUpload) => ({
  type: UPLOADING_PROGRESS_SET,
  payload: percentOfUpload,
});

export const resetUploadingFileProgress = () => ({ type: UPLOADING_PROGRESS_RESET });

export const terminateUploadingFileProgress = () => ({ type: UPLOADING_TERMINATE });

export const setUploadingErrorCode = (errorCode) => ({
  type: UPLOADING_ERROR_OCCURRED,
  payload: errorCode,
});

export const resetUploadState = () => ({ type: UPLOADING_RESET });

export const uploadingFileErrorHandler = (e, dispatch) => {
  if (e.message === AXIOS_CANCELLED_MESSAGE) {
    console.warn(AXIOS_CANCELLED_MESSAGE); // eslint-disable-line no-console
    return;
  }
  errorHandler(e.response, dispatch, setUploadingErrorCode, e);
};

export const onUploadFileProgress = (progressEvent, dispatch) => {
  const { loaded, total } = progressEvent;
  dispatch(setUploadingFileProgress((loaded * 100) / total));
};
