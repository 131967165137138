/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';

import A from '../A/index';
import * as consts from '../../constants';

const Main = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;
const Wrapper = styled(Col)`
  text-align: center;
`;

export default function NotFound() {
  return (
    <Main>
      <Wrapper>
        <h1>404</h1>
        <br />
        <A to={`${consts.PUBLIC_URL}/`}>Return to Home Page</A>
      </Wrapper>
    </Main>
  );
}
