import React, { useState, useEffect, useRef } from 'react';
import {
  Col, Row, Form, ButtonToolbar, Button,
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import FormWrapperNormal from '../../FormComponents/Wrapper';
import UniversalFormControl from '../../FormComponents/UniversalControl';
import FormCheckboxGroup from '../../FormComponents/CheckboxGroup';
import FormTitleSection from '../../FormComponents/TitleSection';
import FormActionButtons from '../../FormComponents/ActionButtons';
import FormRadioGroup from '../../FormComponents/RadioGroup';
import FormImage from '../../FormComponents/ImageLoader/index';
import ConfirmLeaveModal from '../../Modals/ConfirmLeaveModal';
import ModalLoader from '../../ModalLoader';
import getCountryRegionLists from '../../../utils/getCountryRegionLists';
import { TabContainer } from '../../StyledComponents';
import {
  getUserStore, getCountries, getRequestDataStore, getCustomerStore,
} from '../../../selectors';
import {
  saveUser, uploadUserLogo, removeUserErrorCode, removeUploadedLogo,
} from '../../../actions/userManagement/userActions';
import { fetchRequestData } from '../../../actions/requestDataActions';
import ErrorModalsCompanyDetails from './ErrorModalsCompanyDetails';
import Loader from '../../Loader';
import {
  USER_TYPE_PRIVATE,
  USER_TYPE_COMPANY,
  fileLogoFormatsUM,

  DEFAULT_COUNTRY_VALUE_SELECT,
  DEFAULT_REGION_VALUE_SELECT,

  color,
} from '../../../constants';
import validators from './validators';
import { customerEmailStatus } from '../../../constants/index';
import * as constants from '../../../constants/index';

const FormWrapper = styled(FormWrapperNormal)`
  &&& {
    margin-top: 0;
  }
`;
const Hint = styled.div`
  max-width: 200px;
  text-align: center;
  font-weight: 300;
  font-size: 12px;
  color: ${color.grey};
  letter-spacing: 0.325px;
  margin-top: 6px;
`;

export default function CompanyDetailsPage() {
  const refUploadLogo = useRef(null);

  const dispatch = useDispatch();

  const { userId } = useParams();
  const location = useLocation();

  const [editMode, setEditMode] = useState(false);
  const [showConfirmModal, setConfirmationModal] = useState(false);
  const [isChanged, setChanged] = useState(false);
  const [stateUser, setUser] = useState(null);
  const [validationState, setValidationState] = useState({});

  const {
    user: propsUser,
    uploadedLogo: propsUploadedLogo,
    errorCode,
    isSaving,
  } = useSelector(getUserStore);
  const countries = useSelector(getCountries);
  const requestData = useSelector(getRequestDataStore);
  const { customer } = useSelector(getCustomerStore);

  const companyTypeList = Object.values(constants.companyType).map(constObjToCheckProps);
  const companyCertificationList = Object.values(constants.companyCertification).map(constObjToCheckProps);
  const isRegisterButtonDisabled = customer && customer.email_status_indication !== customerEmailStatus.VERIFIED;

  const {
    regionList: newRegionList,
    countriesList: newCountryList,
  } = getCountryRegionLists(countries || []);

  const regionList = processRegionList(newRegionList);
  const countriesList = newCountryList.slice();
  countriesList.unshift([DEFAULT_COUNTRY_VALUE_SELECT, DEFAULT_COUNTRY_VALUE_SELECT]);

  useEffect(() => {
    const reqId = queryString.parse(location.search).request;

    if (reqId) {
      dispatch(fetchRequestData(reqId));
    }
  }, [dispatch, location.search]);

  useEffect(() => {
    if (!stateUser && propsUser && propsUser.user_type === USER_TYPE_PRIVATE) {
      if (!queryString.parse(location.search).request) { // came from users page
        setUser(createUserFromProps(propsUser));
        setEditMode(true);
      } else if (requestData.data && requestData.data.company_name) { // came from incoming request and valid data is loaded
        setUser(createUserFromRequest(requestData.data, propsUser));
        setEditMode(true);
      }
    } else if (!refUploadLogo.current && propsUploadedLogo) {
      setUser((prev) => {
        const newUser = { ...prev };
        newUser.company_logo = {
          action: 'add',
          file: propsUploadedLogo,
        };

        return newUser;
      });
    }

    refUploadLogo.current = propsUploadedLogo;
  }, [propsUser, requestData.data, propsUploadedLogo, stateUser, location.search]);

  const onChangeEditMode = () => {
    if (editMode) {
      setEditMode(false);
      setChanged(false);
      setValidationState({});
    } else {
      setEditMode(true);
      setUser(createUserFromProps(propsUser));
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;

    setUser((prev) => {
      const user = { ...prev };

      if (name === 'country_id') {
        user.region_id = regionList[value] ? DEFAULT_REGION_VALUE_SELECT : null;
      }
      user[name] = value;
      return user;
    });

    if (validators[name]) {
      setValidationState((prev) => {
        const newValidationState = { ...prev };
        newValidationState[name] = validators[name](value);

        return newValidationState;
      });
    }

    setChanged(true);
  };

  const onCheckboxChange = (e) => {
    const { checked, name, value } = e.target;

    setUser((prev) => {
      const newUser = { ...prev };
      const oldArr = newUser[name] || [];

      if (!checked) {
        // remove value from data container
        newUser[name] = oldArr.filter((v) => v !== +value);
      } else {
        // add value
        newUser[name] = oldArr.concat(+value);
      }
      return newUser;
    });

    setChanged(true);
  };

  const onHideConfirmModal = () => setConfirmationModal(false);

  const onSaveHandler = () => {
    const newValidationState = computeValidationStateObj();
    setValidationState(newValidationState);

    if (Object.values(newValidationState).some((val) => !!val)) return;
    updateCompanyDetails();
  };

  const computeValidationStateObj = () => (
    Object.keys(validators).reduce((acc, fieldName) => {
      acc[fieldName] = validators[fieldName](stateUser[fieldName]);
      return acc;
    }, {})
  );

  const updateCompanyDetails = () => {
    const userChanges = {};
    const companyLogo = stateUser.company_logo;

    Object.keys(stateUser).forEach((item) => {
      if (item === 'company_tax_number' || item === 'company_logo' || item === 'contact_email' || item === 'contact_phone') {
        return;
      }
      if (stateUser[item] !== propsUser[item]) {
        userChanges[item] = stateUser[item];
      }
    });

    if (stateUser.user_type === USER_TYPE_PRIVATE) { // Register as Company
      userChanges.user_type = USER_TYPE_COMPANY;
    }

    if (userChanges.region_id) {
      userChanges.country_id = stateUser.country_id;
    } else if (userChanges.country_id) {
      userChanges.region_id = regionList[userChanges.country_id] ? stateUser.region_id : null;
    }

    if (companyLogo?.action) {
      const { file } = companyLogo;

      userChanges.company_logo = {
        action: companyLogo.action,
        uploadId: file && file.uploadId,
      };
    }

    dispatch(saveUser(stateUser.user_id, userChanges));
    onChangeEditMode();
  };

  const addCompanyLogo = (e) => {
    if (!isChanged) setChanged(true);

    addCompanyLogoHandler(e);
  };

  const removeCompanyLogo = () => {
    if (!isChanged) setChanged(true);

    removeCompanyLogoHandler();
  };

  const addCompanyLogoHandler = (e) => {
    const logoFile = e && e.target.files ? e.target.files[0] : [];

    dispatch(uploadUserLogo(userId, logoFile));
  };

  const removeCompanyLogoHandler = () => {
    setUser((prev) => {
      const user = { ...prev };
      user.company_logo = { action: 'delete' };

      return user;
    });

    if (propsUploadedLogo) {
      dispatch(removeUploadedLogo());
    }
  };

  if (!propsUser) return <Loader />;

  let user;
  let companyLogo;

  if (editMode || isSaving) {
    user = stateUser;
    companyLogo = stateUser && stateUser.company_logo && stateUser.company_logo.file;
  } else {
    user = propsUser;
    companyLogo = propsUser && propsUser.company_logo;
  }

  const {
    company_name: companyName,
    street,
    city,
    state,
    country_id: countryId,
    region_id: regionId,
    zip_code: zipCode,
    phone,
    company_type: companyType,
    company_certification: companyCertification,
    company_fax: companyFax,
    company_website: companyWebsite,
    company_tax_number: companyTaxNumber,
    company_internal_order_number: companyInternalOrderNumber,
    contact_name: contactName,
    contact_phone: contactPhone,
    contact_email: contactEmail,
    company_description: companyDescription,
    user_type: userType,
  } = user || {};

  const pageTitle = userType === USER_TYPE_COMPANY ? 'Company Details' : 'Register as Company';

  let regionContent;
  // Displays select control with regions if user's country support them
  if (regionList[countryId]) {
    regionContent = (
      <UniversalFormControl
        labelText="Headquarters / State/Province (optional)"
        value={(regionId && regionId.toString()) || DEFAULT_REGION_VALUE_SELECT}
        name="region_id"
        as="select"
        optionsForSelect={regionList[countryId]}
        onChange={onChange}
        disabled={!editMode}
        feedBack={false}
        {...validationState.region_id}
      />
    );
  } else {
    // Otherwise displays simple text input with state value
    regionContent = (
      <UniversalFormControl
        labelText="Headquarters / State/Province (optional)"
        value={state || ''}
        name="state"
        onChange={onChange}
        disabled={!editMode}
      />
    );
  }

  return (
    <div>
      <ModalLoader show={isSaving} />
      <FormWrapper>
        <FormTitleSection
          title={pageTitle}
          EditButtonClick={userType === USER_TYPE_COMPANY ? onChangeEditMode : null}
          showEditButton={!editMode}
        />

        <TabContainer>
          <Form>
            <Row>
              <Col sm={6}>
                <UniversalFormControl
                  labelText="Company name"
                  value={companyName || ''}
                  name="company_name"
                  onChange={onChange}
                  disabled={!editMode}
                  {...validationState.company_name}
                />

                <UniversalFormControl
                  labelText="Headquarters / Street"
                  value={street || ''}
                  name="street"
                  onChange={onChange}
                  disabled={!editMode}
                />

                <UniversalFormControl
                  labelText="Headquarters / City"
                  value={city || ''}
                  name="city"
                  onChange={onChange}
                  disabled={!editMode}
                />

                <UniversalFormControl
                  labelText="Headquarters / Country"
                  value={countryId || DEFAULT_COUNTRY_VALUE_SELECT}
                  name="country_id"
                  onChange={onChange}
                  disabled={!editMode}
                  optionsForSelect={countriesList}
                  as="select"
                  feedBack={false}
                  {...validationState.country_id}
                />

                {regionContent}

                <UniversalFormControl
                  labelText="Headquarters / Zip Code"
                  value={zipCode || ''}
                  name="zip_code"
                  onChange={onChange}
                  disabled={!editMode}
                />

                <UniversalFormControl
                  labelText="Website"
                  value={companyWebsite || ''}
                  name="company_website"
                  onChange={onChange}
                  disabled={!editMode}
                  {...validationState.company_website}
                />

                <UniversalFormControl
                  labelText="Phone number"
                  value={phone || ''}
                  name="phone"
                  onChange={onChange}
                  disabled={!editMode}
                  {...validationState.phone}
                />

                <UniversalFormControl
                  labelText="Fax number"
                  value={companyFax || ''}
                  name="company_fax"
                  onChange={onChange}
                  disabled
                />
              </Col>
              <Col sm={6}>
                <FormCheckboxGroup
                  labelText="Company Type"
                  checkList={companyTypeList || []}
                  checkedValues={companyType || []}
                  name="company_type"
                  disabled={!editMode}
                  onChange={onCheckboxChange}
                  inline
                  feedBack={false}
                />

                <FormCheckboxGroup
                  labelText="ISO certificates"
                  checkList={companyCertificationList || []}
                  checkedValues={companyCertification || []}
                  name="company_certification"
                  onChange={onCheckboxChange}
                  disabled={!editMode}
                  inline
                />

                <FormRadioGroup
                  labelText="Internal order no."
                  checkedValue={+companyInternalOrderNumber}
                  // values={['1', '0']}
                  // titles={['Mandatory', 'Not Mandatory']}
                  radioList={[{ value: 1, labelText: 'Mandatory' }, { value: 0, labelText: 'Not Mandatory' }]}
                  disabled={!editMode}
                  onChange={onChange}
                  name="company_internal_order_number"
                />

                <UniversalFormControl
                  labelText="Contact person"
                  value={contactName || ''}
                  name="contact_name"
                  onChange={onChange}
                  disabled={!editMode}
                  {...validationState.contact_name}
                />

                <UniversalFormControl
                  labelText="Company description"
                  value={companyDescription || ''}
                  name="company_description"
                  onChange={onChange}
                  disabled={!editMode}
                  as="textarea"
                  defaultInputLength={null}
                />

                <UniversalFormControl
                  labelText="VAT/TAX ID"
                  value={companyTaxNumber || ''}
                  name="company_tax_number"
                  onChange={onChange}
                  disabled
                />

                <UniversalFormControl
                  labelText="Phone"
                  value={contactPhone || ''}
                  name="contact_phone"
                  onChange={onChange}
                  disabled
                />

                <UniversalFormControl
                  labelText="Email"
                  value={contactEmail || ''}
                  name="contact_email"
                  onChange={onChange}
                  disabled
                />

                <FormImage
                  labelText="Company logo"
                  name="company_logo"
                  file={companyLogo}
                  editMode={editMode}
                  loadFileHandler={addCompanyLogo}
                  removeHandler={removeCompanyLogo}
                  accept={fileLogoFormatsUM.join()}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={{ span: 6, offset: 4 }}>
                {
                  editMode && userType === USER_TYPE_COMPANY
                  && <FormActionButtons saveHandler={onSaveHandler} cancelHandler={onChangeEditMode} />
                }
                <ButtonToolbar>
                  {
                    editMode && userType === USER_TYPE_PRIVATE && (
                      <Button
                        size="lg"
                        variant="primary"
                        disabled={isRegisterButtonDisabled}
                        onClick={onSaveHandler}
                      >
                        Register as Company
                      </Button>
                    )
                  }
                </ButtonToolbar>
                {editMode && userType === USER_TYPE_PRIVATE && isRegisterButtonDisabled && (
                  <Hint>Customer is not active or email is not verified</Hint>
                )}
              </Col>
            </Row>
          </Form>
        </TabContainer>
      </FormWrapper>

      <ErrorModalsCompanyDetails errorCode={errorCode} onHide={() => dispatch(removeUserErrorCode)} />
      <ConfirmLeaveModal
        show={showConfirmModal}
        onLeave={() => {
          onChangeEditMode();
          onHideConfirmModal();
        }}
        onCancel={onHideConfirmModal}
      />
    </div>
  );
}

const checkElPropsPT = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelText: PropTypes.string,
});

CompanyDetailsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }),
  regionList: PropTypes.shape({}),
  countriesList: PropTypes.arrayOf(PropTypes.array),
  isRegisterButtonDisabled: PropTypes.bool,
  userData: PropTypes.shape({
    user: PropTypes.shape({
      company_name: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      country_id: PropTypes.string,
      region: PropTypes.string,
      zip_code: PropTypes.string,
      phone: PropTypes.string,
      company_fax: PropTypes.string,
      company_website: PropTypes.string,
      company_tax_number: PropTypes.string,
      company_type: PropTypes.arrayOf(PropTypes.string),
      company_certification: PropTypes.arrayOf(PropTypes.string),
      company_logo: PropTypes.shape({
        action: PropTypes.string,
        name: PropTypes.string,
        thumbnail_url: PropTypes.string,
        url: PropTypes.string,
      }),
      company_internal_order_number: PropTypes.bool,
      contact_name: PropTypes.string,
      contact_phone: PropTypes.string,
      contact_email: PropTypes.string,
      company_description: PropTypes.string,
      user_type: PropTypes.number,
    }),
    isSaving: PropTypes.bool,
    uploadedLogo: PropTypes.shape({
      name: PropTypes.string,
      origin_name: PropTypes.string,
      thumbnail_url: PropTypes.string,
      url: PropTypes.string,
    }),
    errorCode: PropTypes.number,
  }),
  requestData: PropTypes.shape({
    data: PropTypes.shape({
    }),
  }),

  companyTypeList: PropTypes.arrayOf(checkElPropsPT),
  companyCertificationList: PropTypes.arrayOf(checkElPropsPT),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

const constObjToCheckProps = ({ id, name }) => ({
  value: id,
  labelText: name,
});

const createUserFromProps = (propsUser) => ({
  company_name: propsUser.company_name,
  street: propsUser.street,
  city: propsUser.city,
  state: propsUser.state,
  country_id: propsUser.country_id || DEFAULT_COUNTRY_VALUE_SELECT,
  region_id: propsUser.region_id,
  zip_code: propsUser.zip_code,
  phone: propsUser.phone,
  company_fax: propsUser.company_fax,
  company_website: propsUser.company_website,
  company_tax_number: propsUser.company_tax_number,
  company_type: propsUser.company_type,
  company_certification: propsUser.company_certification,
  company_internal_order_number: propsUser.company_internal_order_number,
  contact_name: propsUser.contact_name,
  contact_phone: propsUser.contact_phone,
  contact_email: propsUser.contact_email,
  company_description: propsUser.company_description,
  company_logo: {
    action: null,
    file: propsUser.company_logo,
  },
  user_id: propsUser.user_id,
  user_type: propsUser.user_type,
});

const createUserFromRequest = (req, propsUser) => ({
  company_name: req.company_name,
  street: req.street,
  city: req.city,
  state: req.state,
  country_id: req.country_id,
  region_id: req.region_id,
  zip_code: req.zip_code,
  phone: req.phone,
  company_fax: propsUser.company_fax, // not in request
  company_website: req.company_website,
  company_tax_number: req.company_tax_number,
  company_type: req.company_type,
  company_certification: propsUser.company_certification, // not in request
  company_internal_order_number: propsUser.company_internal_order_number, // not in request
  contact_name: (propsUser && propsUser.contact_name), // not in request
  contact_phone: req.contact_phone,
  contact_email: req.contact_email,
  company_description: propsUser.company_description, // not in request
  company_logo: {
    action: null,
    file: propsUser.company_logo, // not in request
  },
  user_id: propsUser.user_id, // not in request
  user_type: propsUser.user_type, // not in request
});

const processRegionList = (regionList) => {
  const newRegionList = {};

  Object.keys(regionList).forEach((countryId) => {
    newRegionList[countryId] = [[DEFAULT_REGION_VALUE_SELECT, DEFAULT_REGION_VALUE_SELECT]];
    newRegionList[countryId] = newRegionList[countryId].concat(regionList[countryId]);
  });

  return newRegionList;
};
