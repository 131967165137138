import axios from 'axios';

import {
  RFQ_EMAIL_SETTINGS_GET,
  RFQ_EMAIL_SETTINGS_SUCCESS,
  RFQ_EMAIL_SETTINGS_SAVE,
  RFQ_EMAIL_SETTINGS_SAVE_SUCCESS,

  EMAILS_FAIL,
  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';

const startFetchDigestedEmailSetting = () => ({ type: RFQ_EMAIL_SETTINGS_GET });
const fetchDigestedEmailSettingSuccess = (settings) => ({
  type: RFQ_EMAIL_SETTINGS_SUCCESS,
  payload: settings,
});

const startSaveDigestedEmailSetting = () => ({ type: RFQ_EMAIL_SETTINGS_SAVE });
const saveDigestedEmailSettingSuccess = (settings) => ({
  type: RFQ_EMAIL_SETTINGS_SAVE_SUCCESS,
  payload: settings,
});

const mailSettingsFail = () => ({ type: EMAILS_FAIL });

export const fetchRfqEmailSettings = () => async (dispatch) => {
  dispatch(startFetchDigestedEmailSetting());
  try {
    const response = await axios('/rest/V2/eplane/backoffice/get-aggregated-rfq-settings');
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchDigestedEmailSettingSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, mailSettingsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, mailSettingsFail, e);
  }
};

export const saveRfqEmailSettings = (data) => async (dispatch) => {
  dispatch(startSaveDigestedEmailSetting());
  try {
    const response = await axios.post('/rest/V2/eplane/backoffice/update-aggregated-rfq-settings', data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveDigestedEmailSettingSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, mailSettingsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, mailSettingsFail, e);
  }
};
