import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Col, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { SortButton, getSortIcon } from '../SellersLists/Helpers';
import IndeterminateCheckbox from '../../FormComponents/IndeterminateCheckbox';
import { StyledLinkButton as LinkButton } from '../../StyledComponents';
import { color } from '../../../constants';

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  thead {
    border-bottom: 2px solid ${color.grey};
  }
  tbody tr {
    border-bottom: 2px solid ${color.lightGrey};
  }
  th, td {
    padding: 10px 0;
    padding-right: 10px;
    text-align: left;
    vertical-align: top;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  tr:first-child td {
    padding-top: 10px;
  }
  th:first-child, td:first-child {
    padding-left: 10px;
    vertical-align: top;
  }
  th:last-child, td:last-child {
    text-align: right;
    padding-right: 5px;
    vertical-align: top;
  }
  td.no-content-data {
    border-bottom: none;
    text-align: center; 
  }
  .select-column-header {
    width: 40px;
  }
  .description-column-header {
    width: 30%;
  }
`;
const RemoveButton = styled(LinkButton)`
  padding: 0;
  margin-right: 20px;
`;
const removeFormatter = (id, onRemoveHandler) => (
  <RemoveButton onClick={() => onRemoveHandler([id])} variant="link" title="Remove">
    <FontAwesomeIcon icon="times" />
  </RemoveButton>
);

const PartsTable = React.memo(({
  data,
  tableSelectionState,
  selectedItems,
  onSelectionCheckboxClick,
  sortOrder,
  options,
  onRemoveHandler,
  onSort,
}) => {
  let tableBody = null;
  if (data.length === 0) {
    tableBody = (
      <tr className="no-content-data">
        <td colSpan="6">{options.noDataContent}</td>
      </tr>
    );
  } else {
    tableBody = (
      <>
        {data.map((v) => (
          <tr key={v.list_id}>
            <td title={v.pn}>
              <input
                checked={selectedItems.some((pn) => pn === v.pn)}
                type="checkbox"
                onChange={(e) => onSelectionCheckboxClick(e, v.pn)}
              />
            </td>
            <td title={v.pn}>{v.pn}</td>
            <td title={v.description}>{v.description}</td>
            <td>{`${moment.utc(v.created_at).format('MMM D, YYYY, HH:mm')}`}</td>
            <td title={v.customer_name}>{v.customer_name}</td>
            <td className="remove-column">
              {removeFormatter(v.pn, onRemoveHandler)}
            </td>
          </tr>
        ))}
      </>
    );
  }

  return (
    <Row>
      <Col sm={12}>
        <Table>
          <thead>
            <tr>
              <th className="select-column-header">
                <IndeterminateCheckbox
                  value={tableSelectionState}
                  name="table_multicheckbox"
                  onChange={onSelectionCheckboxClick}
                />
              </th>
              <th>
                Part Number
                <SortButton variant="link" name="p_n" onClick={onSort}>
                  {getSortIcon(sortOrder?.columnName === 'p_n' && sortOrder.order)}
                </SortButton>
              </th>
              <th className="description-column-header">
                Description
                <SortButton variant="link" name="description" onClick={onSort}>
                  {getSortIcon(sortOrder?.columnName === 'description' && sortOrder.order)}
                </SortButton>
              </th>
              <th>
                Watched Since
                <SortButton variant="link" name="created_at" onClick={onSort}>
                  {getSortIcon(sortOrder?.columnName === 'created_at' && sortOrder.order)}
                </SortButton>
              </th>
              <th>
                Added By
                <SortButton variant="link" name="owner" onClick={onSort}>
                  {getSortIcon(sortOrder?.columnName === 'owner' && sortOrder.order)}
                </SortButton>
              </th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {tableBody}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
});

PartsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  tableSelectionState: PropTypes.number,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  sortOrder: PropTypes.shape({
    columnName: PropTypes.string,
    order: PropTypes.string,
  }),
  options: PropTypes.shape({
    noDataContent: PropTypes.element,
  }),
  onSelectionCheckboxClick: PropTypes.func.isRequired,
  onRemoveHandler: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default PartsTable;
