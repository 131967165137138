import axios from 'axios';

import {
  REQUEST_DATA_FETCH,
  REQUEST_DATA_ARE_FETCHED,
  REQUEST_DATA_CUSTOMERS_ARE_FETCHED,
  REQUEST_DATA_PROCESS_DECISION,
  REQUEST_DATA_DECISION_IS_PROCESSED,

  REQUEST_DATA_ERROR_OCCURRED,
} from '../constants';
import { requestErrorHandler } from './requestErrorHandler';

const startFetchingRequestData = () => ({
  type: REQUEST_DATA_FETCH,
});
const requestDataAreFetched = (data) => ({
  type: REQUEST_DATA_ARE_FETCHED,
  payload: data,
});

const requestCustomersAreFetched = (data) => ({
  type: REQUEST_DATA_CUSTOMERS_ARE_FETCHED,
  payload: data,
});

const startProcessingDecision = () => ({
  type: REQUEST_DATA_PROCESS_DECISION,
});
const finishProcessingDecision = (data) => ({
  type: REQUEST_DATA_DECISION_IS_PROCESSED,
  payload: data,
});

export const requestDataFail = () => ({
  type: REQUEST_DATA_ERROR_OCCURRED,
});

export const fetchRequestData = (id) => (dispatch) => {
  dispatch(startFetchingRequestData());

  axios(`/rest/default/V1/eplane/backoffice/companyRegistrationRequests/${id}`)
    .then((response) => {
      const requestData = response.data;
      dispatch(requestDataAreFetched(requestData));
      return axios(`/rest/default/V1/eplane/backoffice/user/${requestData.user_id}/companyCustomers`);
    })
    .then((response) => (dispatch(requestCustomersAreFetched(response.data.customers))))
    .catch((e) => requestErrorHandler(e.response, dispatch, requestDataFail, e));
};

export const processDecision = (reqId, decision, comment) => (dispatch) => {
  dispatch(startProcessingDecision());

  return axios.post('/rest/default/V1/eplane/backoffice/companyRegistrationRequests/consider', {
    id: reqId,
    decision,
    comment,
  }).then((response) => {
    dispatch(finishProcessingDecision(response.data));
    return response.data;
  }).catch((e) => requestErrorHandler(e.response, dispatch, requestDataFail, e));
};
