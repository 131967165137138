import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Row,
  // HelpBlock as HelpBlockNormal,
  Form,
  FormControl,
  Col,
} from 'react-bootstrap';

import emailValidator from '../../../utils/emailValidator';
import { formOptions } from '../../../constants';
import renderValidation from '../renderHelpBlock';

const FormControlStyled = styled(FormControl)`
  &&:disabled {
    cursor: default;
    background-color: inherit;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
`;
const StyledTextArea = styled.textarea`
  &&:disabled {
    cursor: default;
    background-color: inherit;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
`;

/* const HelpBlock = styled(HelpBlockNormal)`
  && {
    margin: 0;
    min-height: 20px;
  }
`; */

/**
 * This component renders universal control: input text, select or textarea.
 * @param props {Object}
 * @return {XML}
 */
export default class UniversalFormControl extends React.PureComponent {
  static errorValObj = (helpText) => ({ validationState: 'error', helpText });

  static clearValObj = () => ({ validationState: null, helpText: null });

  constructor(props) {
    super(props);
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      validationState: null,
      helpText: null,
    };

    this.validators = {
      required: (val) => {
        const { disabled } = this.props;
        const { helpText, validationState } = this.state;
        if (!disabled && !val) {
          const errorObj = UniversalFormControl.errorValObj('This field is required.');
          this.setState(errorObj);
          return errorObj;
        }
        if (validationState || helpText) {
          this.setState(UniversalFormControl.clearValObj());
        }
        return null;
      },
      email: (email) => {
        const { disabled } = this.props;
        const { helpText, validationState } = this.state;
        let result = null;
        if (!disabled) {
          if (!email) {
            result = UniversalFormControl.errorValObj('Please, enter your email.');
          }
          // eslint-disable-next-line no-useless-escape
          if (!emailValidator(email)) {
            result = UniversalFormControl.errorValObj('Please, enter valid email.');
          }
        }
        if (result) {
          this.setState(result);
        } else if (validationState || helpText) {
          this.setState(UniversalFormControl.clearValObj());
        }
        return result;
      },
      default: () => {
        const { disabled } = this.props;
        return !disabled || null;
      },
    };
  }

  validationHandler = (e) => {
    const { validators } = this;
    const {
      validationType,
      onValidationStateChanged,
      onChange,
    } = this.props;
    const { value: inputValue, name: inputName } = e.target;
    // firing original event

    onChange && onChange(e); // eslint-disable-line no-unused-expressions

    if (typeof validationType === 'function') {
      // TODO: custom validation function
    } else if (typeof onValidationStateChanged === 'function') {
      // running required validator, then firing callback
      const result = this.validators[validationType] ? validators[validationType](inputValue) : validators.default();
      onValidationStateChanged({ name: inputName, state: result ? result.validationState : null });
    }
  };

  render() {
    const {
      id,
      labelText,
      feedBack,
      labelColumnWidth,
      controlColumnWidth,
      defaultInputLength,
      optionsForSelect: options,
      value,
      disabled,
      validationState: validationStateSt,
      helpText: helpTextProps,

      // The properties listed below were deliberately taken off so that they did not go lower in the hierarchy
      validationType,
      onValidationStateChanged,
      ...other
    } = this.props;
    const { as } = this.props;
    const { helpText: helpTextState, validationState: validationStateState } = this.state;
    const testId = id || other.name || '';

    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    let optionsList = (as === 'select') ? <option /> : null;

    if (options && Array.isArray(options)) {
      if (typeof options[0] === 'string') {
        optionsList = options.map((el) => (

          <option value={el} key={el}>{el}</option>

        ));
      } else {
        optionsList = options.map((option) => {
          const key = option[0];
          const text = option[1];
          return <option value={key} key={key}>{text}</option>;
        });
      }
    } else if (options) {
      optionsList = Object.keys(options).map((key) => (
        <option value={key} key={key}>{options[key]}</option>
      ));
    }
    const helpText = helpTextProps || helpTextState;
    const validationState = validationStateSt || validationStateState;

    let innerControl = null;
    if (as === 'textarea') {
      innerControl = (
        <>
          <Form.Control
            {...other}
            as={StyledTextArea}
            onChange={this.validationHandler}
            value={value}
            disabled={disabled}
            maxLength={defaultInputLength}
            data-testid={testId}
            isValid={validationState === 'success'}
            isInvalid={validationState === 'error'}
            aria-describedby="helpBlock"
          />
          {renderValidation(helpText, validationState)}
        </>
      );
    } else if (as === 'select') {
      innerControl = (
        <>
          <Form.Control
            {...other}
            as="select"
            onChange={this.validationHandler}
            value={value}
            disabled={disabled}
            plaintext={disabled}
            custom={!disabled}
            maxLength={defaultInputLength}
            data-testid={testId}
            isValid={validationState === 'success'}
            isInvalid={validationState === 'error'}
            aria-describedby="helpBlock"
          >
            {optionsList}
          </Form.Control>
          {renderValidation(helpText, validationState)}
        </>
      );
    } else {
      innerControl = (
        <>
          <FormControlStyled
            {...other}
            onChange={this.validationHandler}
            value={value}
            disabled={disabled}
            maxLength={defaultInputLength}
            data-testid={testId}
            isValid={validationState === 'success'}
            isInvalid={validationState === 'error'}
            aria-describedby="helpBlock"
          />
          {renderValidation(helpText, validationState)}
        </>
      );
    }

    return (
      <Form.Group as={Row} controlId={id}>
        <Form.Label column={labelColumnWidth !== 0} sm={labelColumnWidth}>
          {labelText}
        </Form.Label>
        <Col sm={controlColumnWidth}>
          {innerControl}
        </Col>
      </Form.Group>
    );
  }
}

UniversalFormControl.propTypes = {
  id: PropTypes.string,
  validationState: PropTypes.oneOf(['success', 'warning', 'error', null]),
  labelText: PropTypes.string,
  helpText: PropTypes.string,
  feedBack: PropTypes.bool,
  labelColumnWidth: PropTypes.number,
  controlColumnWidth: PropTypes.number,
  defaultInputLength: PropTypes.number,
  optionsForSelect: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  ]),
  onChange: PropTypes.func,
  onValidationStateChanged: PropTypes.func,
  validationType: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  as: PropTypes.string,
  disabled: PropTypes.bool,
};

UniversalFormControl.defaultProps = {
  feedBack: true,
  labelColumnWidth: formOptions.labelColumnWidth,
  controlColumnWidth: formOptions.controlColumnWidth,
  defaultInputLength: formOptions.defaultInputLength,
  placeholder: '',
  validationType: 'default',
};
