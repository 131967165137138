import React, { useState, useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button, FormGroup, FormControl, Row, Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  fetchFiltersList, removeFilter, saveFilter, notificationToggle, removeFilterError,
} from '../../../actions/userManagement/filterActions';
import { fetchCompanyCustomers, removeCompanyCustomersError } from '../../../actions/companiesManagement/activeCompanyCustomersActions';
import { fetchLegalEntities, removeLegalEntitiesError } from '../../../actions/legalEntitiesActions';
import { fetchUserSettings, removeError as removeUserSettingsError } from '../../../actions/companiesManagement/userSettingsActions';
import { fetchCompanyList, errorRemove as removeCompanyListError } from '../../../actions/companyListActions';
import {
  getFiltersStore,
  getActiveCompanyCustomersStore,
  getCompanyListStore,
  getLegalEntitiesStore,
  getUserStore,
  getUserSettingsStore,
} from '../../../selectors';
import { getProActiveState } from '../../../selectors/pro';
import { isFiltersPageFetching, getFilters } from '../../../selectors/filters';
import { getFilterSelectOptions } from '../../../reducers/userManagement/filterSelector';
import EmailAggregationForm from './EmailAggregationForm';
import RfqFilterTable from './RfqFilterTable';
import { TabContainer, StyledLinkButton as LinkButtonNormal } from '../../StyledComponents';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import FilterModal from './FilterModal';
import Loader from '../../Loader';
import ModalLoader from '../../ModalLoader';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import ErrorModal from '../../ErrorModal';
import { color } from '../../../constants';
import { userType, rfqFilterType } from '../../../constants/index';

const LinkButton = styled(LinkButtonNormal)`
font-size: 16px;
`;
const StyledFormGroup = styled(FormGroup)`
  && {
    margin-bottom: 0;
  }
`;
const ButtonsControl = styled.div`
  text-align: right;
  margin-top: 12px;
`;

const spanStyle = {
  pointerEvents: 'all',
  marginTop: '2px',
  outline: 'none',
  zIndex: '3',
  color: color.grey,
};

export default function RfqFilterPage() {
  const dispatch = useDispatch();
  const { userId, customerId } = useParams();

  const [modals, setModals] = useState({
    showFilterModal: false,
    showConfirmationModal: false,
  });
  const [editMode, setEditMode] = useState(false);
  const [clickedFilterId, setClickedFilterId] = useState(null);
  const [removeFilterId, setRemoveFilterId] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [searchFilters, setSearchFilters] = useState([]);

  const { data } = useSelector(getUserSettingsStore);
  const filtersStore = useSelector(getFiltersStore);
  const activeCompanyCustomers = useSelector(getActiveCompanyCustomersStore);
  const {
    companies, errorCode: companiesErrorCode,
  } = useSelector(getCompanyListStore);
  const {
    legalEntities, errorCode: legalEntitiesErrorCode,
  } = useSelector(getLegalEntitiesStore);
  const { user } = useSelector(getUserStore);
  const isProActive = useSelector(getProActiveState);

  const filters = useSelector(getFilters);
  const filterSelectOptions = useMemo(() => (
    companies && activeCompanyCustomers && legalEntities
      ? getFilterSelectOptions(companies, activeCompanyCustomers.customers, legalEntities)
      : null
  ), [activeCompanyCustomers, companies, legalEntities]);

  const blockFilters = !isProActive && !data?.pro_rfq_filters;
  const blockEmails = !isProActive && !data?.aggregation_emails_editable;
  const isCompany = user?.user_type === userType.COMPANY.id;
  const isFetching = useSelector(isFiltersPageFetching);
  const errorCode = companiesErrorCode || legalEntitiesErrorCode || filtersStore.errorCode || activeCompanyCustomers.errorCode;
  const isAutopilotEnabled = data?.autopilot_enabled;

  useEffect(() => {
    batch(() => {
      dispatch(fetchFiltersList(customerId));
      dispatch(fetchUserSettings(userId));
      dispatch(fetchCompanyCustomers(userId));
      dispatch(fetchCompanyList(userId));
      dispatch(fetchLegalEntities());
    });
  }, [customerId, dispatch, userId]);

  const onAddFilterHandler = () => setModals((prev) => ({ ...prev, showFilterModal: true }));

  const hideFilterModal = () => {
    setModals((prev) => ({ ...prev, showFilterModal: false }));
    setEditMode(false);
    setClickedFilterId(null);
  };

  const onEditHandler = (filterId) => {
    setModals((prev) => ({ ...prev, showFilterModal: true }));
    setEditMode(true);
    setClickedFilterId(filterId);
  };

  const onRemoveHandler = (filterId) => {
    setModals((prev) => ({ ...prev, showConfirmationModal: true }));
    setRemoveFilterId(filterId);
  };

  const hideModal = () => {
    setModals((prev) => ({ ...prev, showConfirmationModal: false }));
    setRemoveFilterId(null);
  };

  const saveFilterHandler = (filterData, editModeValue) => {
    const changes = {
      order_increment_id: filterData.order_increment_id,
      filter_name:
        editModeValue && filterData.filter_id
          ? filterData.filter_name
          : generateUniqueFilterName(filterData.filter_name, filters),
      filter_status: filterData.filter_status,
      filter_type: filterData.filter_type,
      ata_chapter: filterData.ata_chapter,
      date_from: filterData.date_from,
      date_to: filterData.date_to,
      replies: filterData.replies && filterData.replies.value,

      agent: getSendData(filterData.agent, filterSelectOptions.agent),
      deal_type:
        filterData.filter_type !== rfqFilterType.AUTOPILOT
          ? getSendData(filterData.deal_type, filterSelectOptions.dealTypes)
          : null,
      priority: getSendData(filterData.priority, filterSelectOptions.demandPriority),
      buyer_id:
        filterData.filter_type === rfqFilterType.SALES
          ? getSendData(filterData.buyer_id, filterSelectOptions.companies)
          : null,
      buyer_company_type:
        filterData.filter_type === rfqFilterType.SALES
          ? getSendData(filterData.buyer_company_type, filterSelectOptions.companyType)
          : null,
      seller_id:
        filterData.filter_type === rfqFilterType.PURCHASES
          ? getSendData(filterData.seller_id, filterSelectOptions.companies)
          : null,
      seller_company_type:
        filterData.filter_type === rfqFilterType.PURCHASES
          ? getSendData(filterData.seller_company_type, filterSelectOptions.companyType)
          : null,
      quote_position: filterData.filter_type === rfqFilterType.SALES
        ? getSendData(filterData.quote_position, filterSelectOptions.quotePositions)
        : null,
    };
    if (filterData.filter_type === rfqFilterType.SALES) {
      changes.order_status = getSendData(filterData.order_status, filterSelectOptions.salesStatus);
    } else if (filterData.filter_type === rfqFilterType.PURCHASES) {
      changes.order_status = getSendData(filterData.order_status, filterSelectOptions.purchasesStatus);
    } else if (filterData.filter_type === rfqFilterType.AUTOPILOT) {
      changes.order_status = getSendData(filterData.order_status, filterSelectOptions.autopilotStatus);
    }

    const finalData = {
      filter: changes,
      enabled: filterData.disableToggle ? false : filterData.enabled,
    };

    if (editMode) dispatch(saveFilter(finalData, customerId, filterData.filter_id));
    else dispatch(saveFilter(finalData, customerId));

    hideFilterModal();
  };

  const onChange = (e) => {
    const { value } = e.target;
    setSearchString(value);
    setSearchFilters(filters.filter((v) => v.filter_name.search(new RegExp(value, 'i')) > -1));
  };

  const removeConfirm = () => {
    dispatch(removeFilter(customerId, removeFilterId));
    hideModal();
  };

  const removeErrors = () => {
    batch(() => {
      dispatch(removeCompanyCustomersError());
      dispatch(removeFilterError());
      dispatch(removeCompanyListError());
      dispatch(removeLegalEntitiesError());
      dispatch(removeUserSettingsError());
    });
  };

  const onResetHandler = () => setSearchString('');

  if (isFetching) return <Loader />;

  const currFilters = searchString !== '' ? searchFilters : filters;
  const editData = clickedFilterId && currFilters.find((v) => v.filter_id === clickedFilterId);
  const removeFilterName = removeFilterId && currFilters.find((v) => v.filter_id === removeFilterId).filter_name;

  return (
    <>
      <FormTitleSection title="Notification Preferences" showEditButton={false} />
      <TabContainer>
        {blockEmails ? (
          <p>Aggregated emails management is available for users with enabled feature or activated Pro Plan</p>
        ) : (
          <EmailAggregationForm />
        )}
        <br />

        <h4>RFQ Filters</h4>
        <p>Add, edit, and remove RFQ filters on behalf of the user.</p>
        <>
          <Row>
            <Col sm={4}>
              <StyledFormGroup>
                <FormControl
                  name="search_name"
                  type="text"
                  placeholder="Search by filter name"
                  value={searchString}
                  onChange={onChange}
                />
                { !!searchString && (
                  <FormControl.Feedback>
                    <Button
                      onClick={onResetHandler}
                      style={spanStyle}
                      variant="link"
                      tabIndex={-1}
                    >
                      <FontAwesomeIcon icon="times" />
                    </Button>
                  </FormControl.Feedback>
                )}
              </StyledFormGroup>
            </Col>
            <Col sm={8}>
              <ButtonsControl>
                <LinkButton variant="link" onClick={onAddFilterHandler} disabled={blockFilters}>
                  <FontAwesomeIcon icon="plus" />
                  &nbsp;Add New Filter
                </LinkButton>
              </ButtonsControl>
            </Col>
          </Row>
          <hr />
          <RfqFilterTable
            customerId={customerId}
            data={currFilters}
            onEdit={onEditHandler}
            notificationToggle={(...props) => dispatch(notificationToggle(...props))}
            onRemove={onRemoveHandler}
            agents={filterSelectOptions.agent}
            disabled={blockFilters}
          />
        </>
        <FilterModal
          editData={editData}
          filterSelectOptions={filterSelectOptions}
          isCompany={isCompany}
          isAutopilotEnabled={isAutopilotEnabled}
          show={modals.showFilterModal}
          onHide={hideFilterModal}
          editMode={editMode}
          saveFilter={saveFilterHandler}
        />
        <ModalLoader show={filtersStore.isSaving} />
        <ConfirmationModal
          show={modals.showConfirmationModal}
          confirmBtnText="Delete Filter"
          cancelBtnText="Cancel"
          confirm={removeConfirm}
          onHide={hideModal}
        >
          Are you sure you wish to delete&nbsp;
          <strong>{removeFilterName}</strong>
          ?
        </ConfirmationModal>
        <ErrorModal
          error={errorCode}
          onHide={removeErrors}
        />
      </TabContainer>
    </>
  );
}

function getSendData(array, consts) {
  if (array && array.length !== 0 && array.length !== consts.length) {
    return array.map((item) => item.value).join(',');
  }
  return '';
}

function generateUniqueFilterName(name, filters) {
  let i = 1;
  let newName = name;
  // eslint-disable-next-line no-loop-func
  while (filters.some((v) => v.filter_name === newName)) {
    newName = `${name} (${i})`;
    i += 1;
  }
  return newName;
}
