import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Table from '../../Table/Table';
import header from '../../Table/HeaderWithSort';
import TableStyles from '../../Table/TableStyles';
import CellWithTitle from '../../Table/Cell';

import dateFormat from '../../../utils/dateFormat';

const TableContainer = styled(TableStyles)`
  tbody tr td {
    cursor: pointer;
  }
`;

const DevLogsTable = ({ tableData, logsTableOptions }) => {
  const { sortName, sortOrder, onSortChange } = logsTableOptions;

  const columns = useMemo(
    () => [
      {
        Header: () => header('User name'),
        id: 'admin_customer_full_name',
        accessor: 'admin_customer_full_name',
      }, {
        Header: () => header('User email'),
        accessor: 'admin_customer_email',
      }, {
        Header: ({ column }) => header('Action', true, (column.id === sortName && sortOrder), column.id, onSortChange),
        accessor: 'display_text',
        Cell: CellWithTitle,
      }, {
        Header: ({ column }) => header('Date', true, (column.id === sortName && sortOrder), column.id, onSortChange),
        accessor: 'created_at',
        Cell: ({ value }) => dateFormat(value),
        style: {
          width: '180px',
        },
      },
    ], [onSortChange, sortName, sortOrder],
  );

  return (
    <TableContainer>
      <Table columns={columns} data={tableData} options={logsTableOptions} />
    </TableContainer>
  );
};

DevLogsTable.propTypes = {
  logsTableOptions: PropTypes.shape({
    onSortChange: PropTypes.func,
    sortName: PropTypes.string,
    sortOrder: PropTypes.string,
  }),
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
};
export default DevLogsTable;
