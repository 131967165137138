import React from 'react';

import FormTitleSection from '../../FormComponents/TitleSection/index';
import { TabContainer } from '../../StyledComponents';

export default () => (
  <>
    <FormTitleSection title="Pro Plan Information" showEditButton={false} />
    <TabContainer>
      <p>Feature is available only for verified not external users.</p>
    </TabContainer>
  </>
);
