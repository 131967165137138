import React, { useState, useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { fetchProSub } from '../../../actions/userCompanyManagement/proManagement';
import {
  fetchUserSettings, saveUserSettings, removeError, toggleEditMode,
} from '../../../actions/companiesManagement/userSettingsActions';
import { getUserSettingsStore, getProManagementStore } from '../../../selectors';
import { getProActiveState } from '../../../selectors/pro';
import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import FormTitleSection from '../../FormComponents/TitleSection';
import { TabContainer } from '../../StyledComponents';
import FormActionButtons from '../../FormComponents/ActionButtons';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import ErrorModal from '../../ErrorModal';
import InternalErrorContent from '../../InternalErrorContent';
import Loader from '../../Loader';
import ModalLoader from '../../ModalLoader';
import changesMapper from '../../../utils/diffMapper';

export default function ProFeaturesPage() {
  const refChanges = useRef(null);

  const dispatch = useDispatch();
  const { userId } = useParams();

  const [data, setData] = useState(null);
  const [modals, setModals] = useState({ showConfirmationModal: false });

  const {
    data: propsData,
    fatalErrorCode,
    editMode,
    isFetching,
    isSaving,
    savingErrorCode,
  } = useSelector(getUserSettingsStore);
  const { isFetching: isProFetching } = useSelector(getProManagementStore);
  const isProActive = useSelector(getProActiveState);

  useEffect(() => {
    batch(() => {
      dispatch(fetchUserSettings(userId));
      dispatch(fetchProSub(userId));
    });
  }, [dispatch, userId]);

  useEffect(() => {
    if (editMode) setData({ ...propsData });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const onChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setData((prevState) => {
      const newData = { ...prevState };
      newData[target.name] = value;
      return newData;
    });
  };

  const onSaveBtnClick = () => {
    const changes = changesMapper(data, propsData);

    if (Object.keys(changes).length) {
      refChanges.current = changes;
      setModals((prev) => ({ ...prev, showConfirmationModal: true }));
    } else {
      dispatch(toggleEditMode());
    }
  };

  const onHideConfirmationModal = () => {
    setModals((prev) => ({ ...prev, showConfirmationModal: false }));
  };

  const save = () => {
    onHideConfirmationModal();
    const changes = refChanges.current;

    dispatch(saveUserSettings(userId, changes));
  };

  if (fatalErrorCode) return <InternalErrorContent />;
  if (isFetching || isProFetching) return <Loader />;

  const settings = (editMode ? data : propsData) || {};
  const saveBtnProps = { disabled: !editMode };

  return (
    <>
      <ModalLoader show={isSaving} />
      <FormTitleSection
        title="Pro Features"
        EditButtonClick={() => dispatch(toggleEditMode())}
        showEditButton={!editMode && !isProActive}
      />

      <TabContainer marginTop="20px">
        <Form>
          <Form.Group>
            <CheckboxStyled
              id="aggregation_emails_editable"
              checked={settings.aggregation_emails_editable || isProActive}
              label="Aggregated emails (for buyers and sellers)"
              disabled={!editMode}
              name="aggregation_emails_editable"
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <CheckboxStyled
              id="pro_buyer_filters"
              checked={settings.pro_buyer_filters || isProActive}
              label="Blocked buyer lists"
              disabled={!editMode}
              name="pro_buyer_filters"
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <CheckboxStyled
              id="pro_demand_export"
              checked={settings.pro_demand_export || isProActive}
              label="Download as excel (demands)"
              disabled={!editMode}
              name="pro_demand_export"
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <CheckboxStyled
              id="pro_rfq_export"
              checked={settings.pro_rfq_export || isProActive}
              label="Save to pdf (chat)"
              disabled={!editMode}
              name="pro_rfq_export"
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <CheckboxStyled
              id="pro_rfq_filters"
              checked={settings.pro_rfq_filters || isProActive}
              label="Dashboard filters management"
              disabled={!editMode}
              name="pro_rfq_filters"
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <CheckboxStyled
              id="sellers_lists_enabled"
              checked={settings.sellers_lists_enabled || isProActive}
              label="Seller lists management"
              disabled={!editMode}
              name="sellers_lists_enabled"
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <CheckboxStyled
              id="market_price_indicator_enabled"
              checked={settings.market_price_indicator_enabled || isProActive}
              label="Market price indicator"
              disabled={!editMode}
              name="market_price_indicator_enabled"
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <CheckboxStyled
              id="pro_parts_watchlist"
              checked={settings.pro_parts_watchlist || isProActive}
              label="Parts watchlist"
              disabled={!editMode}
              name="pro_parts_watchlist"
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group>
            <CheckboxStyled
              id="low_bid_indicator_enabled"
              checked={settings.low_bid_indicator_enabled || isProActive}
              label="Low bid indicator"
              disabled={!editMode}
              name="low_bid_indicator_enabled"
              onChange={onChange}
            />
          </Form.Group>

          {
            editMode && (
              <FormActionButtons
                saveProps={saveBtnProps}
                saveHandler={onSaveBtnClick}
                cancelHandler={() => dispatch(toggleEditMode())}
              />
            )
          }
        </Form>
      </TabContainer>

      <ConfirmationModal
        show={modals.showConfirmationModal}
        confirm={save}
        onHide={onHideConfirmationModal}
      >
        Are you sure you want to apply the changes?
      </ConfirmationModal>
      <ErrorModal show={savingErrorCode} onHide={() => dispatch(removeError())} />
    </>
  );
}
