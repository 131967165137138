import {
  REQUEST_DATA_FETCH,
  REQUEST_DATA_ARE_FETCHED,
  REQUEST_DATA_CUSTOMERS_ARE_FETCHED,
  REQUEST_DATA_PROCESS_DECISION,
  REQUEST_DATA_DECISION_IS_PROCESSED,
  REQUEST_DATA_ERROR_OCCURRED,
} from '../../constants';
import { getCompanyTypeById } from '../../utils/constantsHandlers';
import dateIntervalToString from '../../utils/dateIntervalToString';

const initialState = {
  data: null,
  isFetching: false,
  decisionIsBeingProcessed: false,
};

export default function requestDataReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_DATA_FETCH:
      return {
        ...state,
        isFetching: true,
        data: null,
      };
    case REQUEST_DATA_ARE_FETCHED:
      return { ...state, data: payload };
    case REQUEST_DATA_CUSTOMERS_ARE_FETCHED:
      return {
        ...state,
        isFetching: false,
        data: { ...state.data, customers: payload },
      };
    case REQUEST_DATA_PROCESS_DECISION:
      return { ...state, decisionIsBeingProcessed: true };

    case REQUEST_DATA_DECISION_IS_PROCESSED: {
      // Only changed properties is updated in data
      const newData = Object.keys(payload).reduce(createDataReduce(payload), { ...state.data });
      return {
        ...state,
        data: newData,
        decisionIsBeingProcessed: false,
      };
    }

    case REQUEST_DATA_ERROR_OCCURRED:
      return {
        ...state,
        isFetching: false,
        decisionIsBeingProcessed: false,
      };

    default: return state;
  }
}

export const getRequestData = (requestDataState, constants) => {
  const req = requestDataState.data;
  if (!req) return null;
  const { customer, user } = req;
  const companyType = req.company_type && req.company_type.map((typeId) => getCompanyTypeById(typeId, constants)).join(', ');
  return {
    entityId: req.entity_id,
    type: 'Register Company',
    updatedAt: req.updated_at,
    openFor: dateIntervalToString(req.updated_at),
    userName: customer && (`${customer.firstname} ${customer.lastname}`),
    userEmail: customer && (customer.email),
    userEmailIsVerified: customer && (!customer.need_confirmation),
    companyName: req.company_name,
    userEntity: user,
    // additional fields for closed table
    customerId: req.customer_id,
    companyUserId: req.user_id,
    userId: req.user_id,
    userCompany: (user && user.company_name),
    resultId: req.result,
    result: req.result,
    boUser: req.admin_customer_email,
    comment: req.comment,

    // additional for request details page
    companyEmail: req.contact_email,
    companyType,
    companyContact: (user && user.contact_name),
    companyWebsite: (req.company_website),
    headquarters: {
      street: req.street,
      city: req.city,
      countryName: req.country,
      countryId: req.country_id,
      state: req.state,
      region: req.region,
      regionId: req.region_id,
      zipCode: req.zip_code,
    },
    documents: user && user.documents,
    isPro: user && user.is_pro,
    companyPhone: req.phone,
    contactPhone: req.contact_phone,
    vatTax: req.company_tax_number,
    customers: req.customers,
  };
};

function createDataReduce(payload) {
  return (acc, key) => {
    acc[key] = payload[key];
    return acc;
  };
}
