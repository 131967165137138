import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from 'styled-components';

import { color } from '../../constants';

const Icon = styled(FontAwesomeIcon)`
  color: ${color.darkBirch};
  &:hover {
    color: ${color.birch};
  }
`;
const IconContainer = styled.div`
  text-align: center;
`;

export default function CellExpandable({ row }) {
  return (
    <IconContainer {...row.getToggleRowExpandedProps()}>
      {
        row.isExpanded
          ? <Icon icon="times" title="collapse" />
          : <Icon icon="plus" title="expand" />
      }
    </IconContainer>
  );
}

CellExpandable.propTypes = {
  row: PropTypes.shape({
    isExpanded: PropTypes.bool,
    getToggleRowExpandedProps: PropTypes.func,
  }),
};
