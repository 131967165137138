import axios from 'axios';

import {
  LOGS_DATA_FETCH,
  LOGS_DATA_FETCH_SUCCESS,
  LOGS_DATA_FETCH_FAIL, ERRORS,
} from '../../constants';
import errorHandler from '../requestErrorHandler';

const startFetchingLogs = () => ({
  type: LOGS_DATA_FETCH,
});
const logsFetchingSuccess = (logsData) => ({
  type: LOGS_DATA_FETCH_SUCCESS,
  payload: logsData,
});

const logsFetchingFail = () => ({
  type: LOGS_DATA_FETCH_FAIL,
});

// eslint-disable-next-line import/prefer-default-export
export const fetchLogsData = (queryParams) => async (dispatch) => {
  dispatch(startFetchingLogs());
  try {
    const response = await axios('rest/default/V2/eplane/backoffice/log', { params: queryParams });
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(logsFetchingSuccess(response.data?.payload));
    } else {
      errorHandler(response, dispatch, logsFetchingFail);
    }
  } catch (e) {
    errorHandler(e.response, dispatch, logsFetchingFail, e);
  }
};
