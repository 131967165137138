import React from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { Text } from '../styledComponents';

const ProgressModal = ({ onHide, now, ...other }) => (
  <Modal centered onHide={onHide} {...other}>
    <Modal.Body>
      <Text>Uploading Document...</Text>
      <br />
      <ProgressBar animated now={now} />
    </Modal.Body>
  </Modal>
);

ProgressModal.propTypes = {
  now: PropTypes.number,
  onHide: PropTypes.func,
};

export default ProgressModal;
