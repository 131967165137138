import {
  BUYERS_LISTS_GET,
  BUYERS_LISTS_SUCCESS,

  BUYERS_LISTS_SAVE,
  BUYERS_LISTS_SAVE_SUCCESS,

  BUYERS_LISTS_DELETE,
  BUYERS_LISTS_DELETE_SUCCESS,

  BUYERS_LISTS_FAIL,
} from '../../constants';

const initialState = {
  items: null,
  isFetching: false,
  isProcessing: false,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case BUYERS_LISTS_GET:
      return {
        ...state,
        isFetching: true,
      };
    case BUYERS_LISTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: payload,
      };

    case BUYERS_LISTS_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case BUYERS_LISTS_SAVE_SUCCESS:
      return {
        ...state,
        items: payload,
        isProcessing: false,
      };

    case BUYERS_LISTS_DELETE:
      return {
        ...state,
        isProcessing: true,
      };
    case BUYERS_LISTS_DELETE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        items: payload,
      };
    case BUYERS_LISTS_FAIL:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
      };
    default:
      return state;
  }
};
