import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import { color } from '../../../constants';

const GroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 500px;
  .form-check {
    margin-top: 10px;
  }
`;

const ValidationText = styled.div`
  color: ${(props) => props.textColor || color.birch};
  margin-top: 5px;
  margin-bottom: 10px;
`;

const TierCheckBoxGroup = ({
  values, disabled, onChange, ...props
}) => {
  const [validation, setValidationText] = useState({ validationType: null, text: null });
  const messageColor = validation.validationType === 'warning' ? color.goldenTainoi : null;
  const onCheckChange = (e) => {
    if (!e.target.checked && values.filter((v) => v.value).length === 1) {
      setValidationText({ validationType: 'warning', text: 'At least one TIER is required when Autopilot enabled' });
    } else {
      onChange(e);
    }
  };

  useEffect(() => {
    const disabledTiers = [];
    values.forEach((v, i) => {
      if (!v.value) disabledTiers.push(`TIER ${i + 1}`);
    });
    if (disabled || disabledTiers.length === 0) setValidationText({ validationType: null, text: null });
    else {
      setValidationText({
        validationType: 'info',
        text: `This will skip ${disabledTiers.join(' & ')} and will not send an RFQ for the Autopilot demand`,
      });
    }
  }, [values, disabled]);

  return (
    <>
      <b>RFQ will be sent to:</b>
      <GroupContainer>
        {
          values.map((v, i) => (
            <CheckboxStyled
              id={`tier-${i + 1}-checkbox`}
              key={i.toString()}
              checked={v.value}
              label={`TIER ${i + 1}`}
              name={v.name}
              onChange={onCheckChange}
              disabled={disabled}
              {...props}
            />
          ))
        }
      </GroupContainer>
      <ValidationText textColor={messageColor}>{validation.text}</ValidationText>
    </>
  );
};

TierCheckBoxGroup.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default TierCheckBoxGroup;
