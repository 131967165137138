import axios from 'axios';

import {
  CUSTOMER_GET,
  CUSTOMER_GET_SUCCESS,
  CUSTOMER_GET_FAIL,
  CUSTOMER_ERROR_REMOVE,
  CUSTOMER_SAVE,
  CUSTOMER_SAVE_SUCCESS,
  CUSTOMER_SAVE_FAIL,
  CUSTOMER_PASSWORD_SAVE,
  CUSTOMER_PASSWORD_SAVE_SUCCESS,
  CUSTOMER_PASSWORD_SAVE_FAIL,
  CUSTOMER_PASSWORD_RESET,
  CUSTOMER_PASSWORD_RESET_SUCCESS,
  CUSTOMER_PASSWORD_RESET_FAIL,
  CUSTOMER_EMAIL_RESEND,
  CUSTOMER_EMAIL_RESEND_SUCCESS,
  CUSTOMER_EMAIL_RESEND_FAIL,
  CUSTOMER_ACCOUNT_CONFIRMATION,
  CUSTOMER_ACCOUNT_CONFIRMATION_SUCCESS,
  CUSTOMER_ACCOUNT_CONFIRMATION_FAIL,
  CUSTOMER_ERROR_CLEAR,
  CUSTOMER_STATE_CLEAR,
  ERRORS,
} from '../../constants';
import errorHandler from '../requestErrorHandler';
import { updateComments } from '../chatActions';

export const fetchCustomerFail = (errCode) => ({
  type: CUSTOMER_GET_FAIL,
  payload: errCode,
});

const startFetchingCustomer = () => ({
  type: CUSTOMER_GET,
});
const fetchCustomerSuccess = (customer) => ({
  type: CUSTOMER_GET_SUCCESS,
  payload: customer,
});
export const removeCustomerErrorCode = () => ({
  type: CUSTOMER_ERROR_REMOVE,
});

const startSaveCustomer = () => ({
  type: CUSTOMER_SAVE,
});
const saveCustomerSuccess = (customer) => ({
  type: CUSTOMER_SAVE_SUCCESS,
  payload: customer,
});
const saveCustomerFail = (errCode) => ({
  type: CUSTOMER_SAVE_FAIL,
  payload: errCode,
});

const startSaveCustomerPassword = () => ({
  type: CUSTOMER_PASSWORD_SAVE,
});
const saveCustomerPasswordSuccess = (customer) => ({
  type: CUSTOMER_PASSWORD_SAVE_SUCCESS,
  payload: customer,
});
const saveCustomerPasswordFail = (errCode) => ({
  type: CUSTOMER_PASSWORD_SAVE_FAIL,
  payload: errCode,
});

export const clearCustomerState = () => ({
  type: CUSTOMER_STATE_CLEAR,
});

const startSendEmailResetPassword = () => ({
  type: CUSTOMER_PASSWORD_RESET,
});
const sendEmailResetPasswordSuccess = (result) => ({
  type: CUSTOMER_PASSWORD_RESET_SUCCESS,
  payload: result,
});
const sendEmailResetPasswordFail = (errCode) => ({
  type: CUSTOMER_PASSWORD_RESET_FAIL,
  payload: errCode,
});

const startEmailResend = () => ({
  type: CUSTOMER_EMAIL_RESEND,
});
const emailResendSuccess = (result) => ({
  type: CUSTOMER_EMAIL_RESEND_SUCCESS,
  payload: result,
});
const emailResendFail = (errCode) => ({
  type: CUSTOMER_EMAIL_RESEND_FAIL,
  payload: errCode,
});

const startAccountConfirmation = () => ({
  type: CUSTOMER_ACCOUNT_CONFIRMATION,
});
const accountConfirmationSuccess = (result) => ({
  type: CUSTOMER_ACCOUNT_CONFIRMATION_SUCCESS,
  payload: result,
});
const accountConfirmationFail = (errCode) => ({
  type: CUSTOMER_ACCOUNT_CONFIRMATION_FAIL,
  payload: errCode,
});

export const clearCustomerError = () => ({
  type: CUSTOMER_ERROR_CLEAR,
});

export const fetchCustomer = (customerId) => (dispatch) => {
  dispatch(startFetchingCustomer());

  axios(`/rest/default/V1/eplane/backoffice/customer/${customerId}`).then((response) => {
    dispatch(fetchCustomerSuccess(response.data));
  }).catch((e) => {
    errorHandler(e.response, dispatch, fetchCustomerFail, e);
  });
};

export const saveCustomer = (customerId, saveData) => (dispatch) => {
  dispatch(startSaveCustomer());

  axios.post(`/rest/default/V1/eplane/backoffice/customer/${customerId}`, { req: saveData }).then((response) => {
    dispatch(saveCustomerSuccess(response.data));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, saveCustomerFail, e);
  });
};

export const saveCustomerPassword = (customerId, saveData) => (dispatch) => {
  dispatch(startSaveCustomerPassword());

  axios.post(`/rest/default/V1/eplane/backoffice/customer/${customerId}/setpassw`, { password: saveData }).then((response) => {
    dispatch(saveCustomerPasswordSuccess(response.data));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, saveCustomerPasswordFail, e);
  });
};

export const sendEmailResetPassword = (customerId) => (dispatch) => {
  dispatch(startSendEmailResetPassword());

  axios.post(`/rest/default/V1/eplane/backoffice/customer/${customerId}/resetPassword`).then((response) => {
    dispatch(sendEmailResetPasswordSuccess(response.data));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, sendEmailResetPasswordFail, e);
  });
};

export const resendEmailConfirmation = (customerId) => (dispatch) => {
  dispatch(startEmailResend());

  axios.post(`/rest/default/V1/eplane/backoffice/customer/${customerId}/resendConfirmation`).then((response) => {
    dispatch(emailResendSuccess(response.data));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, emailResendFail, e);
  });
};

export const customerAccountConfirmation = (customerId) => async (dispatch) => {
  dispatch(startAccountConfirmation());
  try {
    const response = await axios.post(`rest/V2/eplane/backoffice/customer/${customerId}/confirm`);
    if (response && response.data.code === ERRORS.SUCCESS) {
      dispatch(accountConfirmationSuccess(response.data));
      dispatch(fetchCustomer(customerId));
      dispatch(updateComments());
    } else {
      accountConfirmationFail(response && response.data.code);
    }
  } catch (e) {
    errorHandler(e.response, dispatch, accountConfirmationFail, e);
  }
};
