import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '../StyleComponents';

import replaceDocImage from '../../../assets/ic-deny.png';
import { color } from '../../../constants';

const WrongFileImage = styled.div`
  background: url(${replaceDocImage}) no-repeat 0 0;
  width: 174px;
  height: 165px;
  margin: 0 auto 0;
  position: relative;
  left: 16px;
`;

const MessageText = styled.p`
  font-size: 22px;
  font-weight: 300;
  color: ${color.darkGrey};
  line-height: 1.25;
  text-align: center;
`;

const WrongFormatModal = ({ fileName, onHide, ...other }) => (
  <Modal centered onHide={onHide} {...other}>
    <Modal.Header closeButton>
      <Modal.Title><Text><strong>Wrong file format</strong></Text></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <WrongFileImage />
      <br />
      <MessageText>
        The file you are trying to upload&nbsp;
        <strong>{fileName}</strong>
        &nbsp;is not in a supported format.
      </MessageText>
      <MessageText>Please try again using one of the accepted formats.</MessageText>
    </Modal.Body>
  </Modal>
);

WrongFormatModal.propTypes = {
  now: PropTypes.number,
  onHide: PropTypes.func,
  fileName: PropTypes.string,
  onCancel: PropTypes.func,
};

export default WrongFormatModal;
