import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import { FormGroup, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { fetchUserSettings } from '../../../actions/companiesManagement/userSettingsActions';
import { fetchBuyersLists, saveApprovedBlockedList, removeSellersList } from '../../../actions/companiesManagement/buyersListsActions';
import { fetchCompanyList } from '../../../actions/companyListActions';
import {
  getBuyersListsStore, getCountries, getUserSettingsStore, getErrorsStore,
} from '../../../selectors';
import { getProActiveState } from '../../../selectors/pro';

import BlockForm from './BlockForm';
import BuyersListsTable from './BuyersListsTable';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import { TabContainer } from '../../StyledComponents';
import Loader from '../../Loader';
import ModalLoader from '../../ModalLoader';
import ConfirmationModal from '../../Modals/ConfirmationModal';

import * as constants from '../../../constants/index';
import { color } from '../../../constants';
import { getCompanyTypeById } from '../../../utils/constantsHandlers';

const Paragraph = styled.p`
  margin-bottom: 30px;
`;
const RemoveModalText = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const categories = [
  { id: constants.buyerFilterTypes.buyer_type_filters, value: 'type', label: 'Buyer\'s company type' },
  { id: constants.buyerFilterTypes.buyer_country_filters, value: 'country', label: 'Buyer\'s country' },
  { id: constants.buyerFilterTypes.buyer_company_filters, value: 'company', label: 'Buyer\'s company' },
  { id: constants.buyerFilterTypes.buyer_private_user_filters, value: 'private', label: 'Standalone buyer (unverified company)' },
];

export function process(sectionsData) {
  return [{
    id: constants.buyerFilterTypes.buyer_type_filters,
    title: 'Blocked company types',
    data: sectionsData.buyer_type_filters.map((v) => ({
      ...v,
      name: getCompanyTypeById(v.company_type, constants) ?? 'Standalone user (unverified company)',
    })),
  }, {
    id: constants.buyerFilterTypes.buyer_country_filters,
    title: 'Blocked countries',
    data: sectionsData.buyer_country_filters.map((v) => ({
      ...v,
      name: v.country_name,
    })),
  }, {
    id: constants.buyerFilterTypes.buyer_company_filters,
    title: 'Blocked companies',
    data: sectionsData.buyer_company_filters.map((v) => ({
      ...v,
      name: v.buyer_company_name,
    })),
  }, {
    id: constants.buyerFilterTypes.buyer_private_user_filters,
    title: 'Blocked standalone buyers (unverified companies)',
    data: sectionsData.buyer_private_user_filters.map((v) => ({
      ...v,
      name: v.buyer_company_name,
    })),
  },
  ];
}

export default function BuyersListPage() {
  const sortOrder = useRef(null);

  const { userId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  const [category, setCategory] = useState(null);
  const {
    items,
    isFetching: isFetchingLists,
    isProcessing: isProcessingLists,
  } = useSelector(getBuyersListsStore);
  const { data: userData, isFetching: isUserSettingsFetching } = useSelector(getUserSettingsStore);
  const isProActive = useSelector(getProActiveState);
  const countries = useSelector(getCountries);
  const [removeConfirmationModals, setRemoveConfirmationModal] = useState({
    show: false,
    message: '',
    filterId: null,
  });
  const { errors } = useSelector(getErrorsStore);

  useEffect(() => {
    dispatch(fetchCompanyList());
    dispatch(fetchUserSettings(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (location.search === '') {
      dispatch(fetchBuyersLists(userId, { sortBy: '-updated_on' }));
    } else {
      const currQuery = queryString.parse(location.search);
      sortOrder.current = {
        columnName: currQuery?.sortBy.replace(/-/g, ''),
        order: currQuery?.sortBy.startsWith('-') ? 'asc' : 'desc',
      };
      dispatch(fetchBuyersLists(userId, currQuery));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.search]);

  const buyersFiltersTable = useMemo(
    () => items && process(items),
    [items],
  );

  const initialCompanyTypes = useMemo(
    () => (items && items.buyer_type_filters.map((v) => v.company_type)) ?? [],
    [items],
  );

  const onCategoryChange = (selectedOption) => {
    setCategory(selectedOption);
  };

  const onAddFilter = (payload) => {
    const currQuery = queryString.parse(location.search);
    const searchParam = location.search ? { sort: currQuery.sortBy } : {};
    dispatch(saveApprovedBlockedList({ ...payload, ...searchParam }, userId, category.id));
    setCategory(null);
  };

  const onRemoveFilter = () => {
    const currQuery = queryString.parse(location.search);
    const searchParam = location.search ? { sort: currQuery.sortBy } : {};
    dispatch(removeSellersList(removeConfirmationModals.filterId, userId, searchParam));
    setRemoveConfirmationModal({ show: false, message: '', filterId: null });
  };

  const showConfirmationModal = (filterId, sectionId) => {
    let text = null;
    if (sectionId === constants.buyerFilterTypes.buyer_type_filters) {
      text = 'Are you sure you wish to remove this company type from the blocked list?';
    } else if (sectionId === constants.buyerFilterTypes.buyer_country_filters) {
      text = 'Are you sure you wish to remove this country from the blocked list?';
    } else {
      text = 'Are you sure you wish to remove this buyer from the blocked list?';
    }
    setRemoveConfirmationModal({ show: true, filterId, text });
  };

  const onSortOrderChange = (e) => {
    const { sortBy } = queryString.parse(location.search);
    sortOrder.current = {
      columnName: e.target.name,
      order: (sortBy && sortBy.startsWith('-')) ? 'asc' : 'desc',
    };

    history.push({
      pathname: location.pathname,
      search: `sortBy=${sortOrder.current.order === 'desc' ? '-' : ''}${sortOrder.current.columnName}`,
    });
  };

  const checkUnique = (id, filterCategory) => {
    let hasItem = false;

    if (filterCategory === constants.buyerFilterTypes.buyer_country_filters) {
      hasItem = items[constants.buyerFilterTypes.buyer_country_filters].some((countryFilter) => countryFilter.country_code === id);
    } else if (filterCategory === constants.buyerFilterTypes.buyer_company_filters) {
      hasItem = items[constants.buyerFilterTypes.buyer_company_filters].some((buyerFilter) => buyerFilter.buyer_id === id);
    } else if (filterCategory === constants.buyerFilterTypes.buyer_private_user_filters) {
      hasItem = items[constants.buyerFilterTypes.buyer_private_user_filters].some((buyerFilter) => buyerFilter.buyer_id === id);
    }

    return !hasItem;
  };

  if ((!countries || !items) && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <>
      <FormTitleSection title="Buyer Lists Management" showEditButton={false} />
      <TabContainer>
        <p><strong>Blocked Buyers</strong></p>
        {userData && !(userData.pro_buyer_filters || isProActive) ? (
          <p>Feature is available for companies with enabled buyer lists feature or activated Pro Plan.</p>
        ) : (
          <>
            <Paragraph>
              Blocked buyers cannot view your company&apos;s inventory and send it RFQs.
              <br />
              Limit buyers access to your inventory by company name, location and company type.
            </Paragraph>

            <Paragraph>Select a category and add buyers to your blocked list:</Paragraph>
            <FormGroup as={Row}>
              <Col sm={4}>
                <Select
                  value={category}
                  name="buyer_list_category"
                  placeholder="Select a category..."
                  onChange={onCategoryChange}
                  options={categories}
                  isSearchable={false}
                />
              </Col>
            </FormGroup>

            <BlockForm
              category={category?.id}
              initialCompanyTypes={initialCompanyTypes}
              countries={countries}
              onAddHandler={onAddFilter}
              checkUnique={checkUnique}
            />
            <BuyersListsTable
              data={buyersFiltersTable}
              sortOrder={sortOrder.current}
              onRemoveHandler={showConfirmationModal}
              onSortOrderChange={onSortOrderChange}
            />
          </>
        )}
      </TabContainer>
      <ModalLoader show={isFetchingLists || isProcessingLists || isUserSettingsFetching} />
      <ConfirmationModal
        show={removeConfirmationModals.show}
        confirm={onRemoveFilter}
        onHide={() => setRemoveConfirmationModal({ show: false, message: '', filterId: null })}
        confirmBtnText="I'm sure"
        cancelBtnText="Cancel"
      >
        <>
          <FontAwesomeIcon icon="exclamation-circle" color={`${color.goldenTainoi}`} size="2x" />
          <RemoveModalText>{removeConfirmationModals.text}</RemoveModalText>
        </>
      </ConfirmationModal>
    </>
  );
}
