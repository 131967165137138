import {
  CLOSED_REQUESTS_FETCH,
  CLOSED_REQUESTS_ARE_FETCHED,
  CLOSED_REQUESTS_SEARCHING_START,
  CLOSED_REQUESTS_SEARCHING_FINISHED,
  CLOSED_REQUEST_TABLE_OPTIONS_SET,
  CLOSED_REQUEST_RESET_DATA,

  CLOSED_REQUEST_ERROR_OCCURRED,
} from '../../constants';
import mapRequests from './mapRequests';

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_COUNT_PER_PAGE = 25;

const initialState = {
  items: [],
  tableOptions: {
    countPerPage: DEFAULT_COUNT_PER_PAGE,
    page: DEFAULT_PAGE_NUMBER,
  },
  isFetching: false,
  isSearching: false,
  isSearchingMode: false,
};

export default function closedRequestReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case CLOSED_REQUESTS_FETCH:
      return {
        ...state,
        isFetching: true,
        isSearchingMode: false,
      };
    case CLOSED_REQUESTS_ARE_FETCHED:
      return {
        ...state,
        items: payload,
        isFetching: false,
      };

    // in this case of starting searching payload is search string
    case CLOSED_REQUESTS_SEARCHING_START:
      return {
        ...state,
        isSearching: true,
        tableOptions: {
          countPerPage: 100,
          page: 1,
        },
        isSearchingMode: !!payload, /* search string */
      };
    case CLOSED_REQUESTS_SEARCHING_FINISHED:
      return {
        ...state,
        items: payload,
        isSearching: false,
      };
    case CLOSED_REQUEST_TABLE_OPTIONS_SET:
      return {
        ...state,
        tableOptions: {
          page: payload.page || state.tableOptions.page,
          countPerPage: payload.countPerPage || state.tableOptions.countPerPage,
        },
      };
    case CLOSED_REQUEST_RESET_DATA:
      return {
        ...state,
        items: [],
        tableOptions: {
          page: DEFAULT_PAGE_NUMBER,
          countPerPage: DEFAULT_COUNT_PER_PAGE,
        },
        isSearchingMode: false,
      };

    case CLOSED_REQUEST_ERROR_OCCURRED:
      return {
        ...state,
        isFetching: false,
        isSearching: false,
      };

    default: return state;
  }
}

export function getClosedRequestById(id, closedRequestsState) {
  return closedRequestsState.items.find((req) => req.entity_id === id);
}

export function getClosedReqList(state) {
  return mapRequests(state.items);
}

/**
 * function returns closed requests of a user by userId
 *
 * @param {String} userId
 * @param {Object} closedRequestsState
 * @param {String[]} excludedIds array of request's ids that will be excluded from returned results
 * @returns {Object[]}
 */
export function getClosedRequestsOfUser(userId, closedRequestsState, excludedIds) {
  const requests = getClosedReqList(closedRequestsState);

  if (excludedIds) {
    return requests.filter((req) => (
      (req.userId === userId) && !excludedIds.some((id) => id === req.entityId)
    ));
  }
  return requests.filter((req) => req.userId === userId);
}
