import axios from 'axios';

import {
  USER_ORDER_LIMITS_GET,
  USER_ORDER_LIMITS_GET_SUCCESS,
  USER_ORDER_LIMITS_SAVE,
  USER_ORDER_LIMITS_SAVE_SUCCESS,
  USER_ORDER_LIMITS_FAIL,
  USER_ORDER_LIMITS_RESET,
  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';

const startFetchingOrderLimits = () => ({ type: USER_ORDER_LIMITS_GET });
const fetchOrderLimitsSuccess = (providersList) => ({
  type: USER_ORDER_LIMITS_GET_SUCCESS,
  payload: providersList,
});

const startSavingOrderLimits = () => ({ type: USER_ORDER_LIMITS_SAVE });
const saveOrderLimitsSuccess = (providersList) => ({
  type: USER_ORDER_LIMITS_SAVE_SUCCESS,
  payload: providersList,
});

const orderLimitsFail = () => ({ type: USER_ORDER_LIMITS_FAIL });
export const resetUserOrderLimits = () => ({ type: USER_ORDER_LIMITS_RESET });

export const fetchUserOrderLimits = (userId) => async (dispatch) => {
  dispatch(startFetchingOrderLimits());
  try {
    const response = await axios(`/rest/V2/eplane/backoffice/users/${userId}/buy-through-eplane-limits`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchOrderLimitsSuccess(response.data?.payload.data.items));
    } else {
      requestErrorHandler(response, dispatch, orderLimitsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, orderLimitsFail, e);
  }
};

export const saveUserOrderLimits = (data, userId) => async (dispatch) => {
  dispatch(startSavingOrderLimits());
  try {
    const response = await axios.post(`/rest/V2/eplane/backoffice/users/${userId}/buy-through-eplane-limits`, data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveOrderLimitsSuccess(response.data?.payload.data.items));
    } else {
      requestErrorHandler(response, dispatch, orderLimitsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, orderLimitsFail, e);
  }
};
