import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import UploadInventory from '../UploadInventory';

const SuccessModal = ({ onHide, userId, ...other }) => (
  <Modal onHide={onHide} size="lg" centered {...other}>
    <Modal.Body>
      <UploadInventory userId={userId} onHide={onHide} />
    </Modal.Body>
  </Modal>
);

SuccessModal.propTypes = {
  userId: PropTypes.string,
  now: PropTypes.number,
  onHide: PropTypes.func,
};

export default SuccessModal;
