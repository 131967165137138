import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';

import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import { color } from '../../constants';

// Fixes ussue with data input width
export const DateRangePickerWrapper = styled.div`
  .DateInput__small {
    width: 160px;
  }
  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: ${color.darkBirch};
    border: 1px double ${color.blue};
    color: #fff;
  }
  .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
    background: ${color.birch};
    border: 1px double ${color.birch};
    color: #fff;
  }
  .CalendarDay__selected_span {
    background: #6DB6C9;
    border: 1px double ${color.birch};
    color: #fff;
  }
  .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
    background: #6DB6C9;
    border: 1px double ${color.birch};
    color: #fff;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid ${color.darkBirch};
  }
  .DateInput_input__focused {
    border-bottom: 2px solid ${color.darkBirch};
  }
`;
const RangesContainer = styled.div`
  padding: 10px 10px;
`;
const MonthYearContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const Select = styled.select`
  padding: 5px 0;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid ${color.darkBirch};
  display: block;
  cursor: pointer;
`;
const RangeButton = styled.button`
  color: ${(props) => (props.isSelected ? 'white' : `${color.darkBirch}`)};
  cursor: pointer;
  border: 2px solid ${color.darkBirch};
  text-align: center;
  padding: 4px 12px;
  margin-right: 8px;
  background: ${(props) => (props.isSelected ? `${color.darkBirch}` : 'none')};
`;

const presets = [{
  text: 'Yesterday',
  start: moment.utc().subtract(1, 'days'),
  end: moment.utc().subtract(1, 'days'),
}, {
  text: 'Last 7 Days',
  start: moment.utc().subtract(6, 'days'),
  end: moment.utc(),
}, {
  text: 'Last 30 Days',
  start: moment.utc().subtract(29, 'days'),
  end: moment.utc(),
}, {
  text: 'This Month',
  start: moment.utc().startOf('month'),
  end: moment.utc().endOf('month'),
}, {
  text: 'Today',
  start: moment.utc(),
  end: moment.utc(),
}];

const DatesPicker = ({
  startDate,
  endDate,
  onChange,
  onGo,
  displayFormat,
  minimumNights,
  numberOfMonths,
  isShownDatePresets,
  ...otherProps
}) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const initialVisibleMonth = () => (!numberOfMonths || numberOfMonths === 1 ? moment.utc() : moment.utc().subtract(1, 'months'));
  const renderDatePresets = () => (
    <RangesContainer>
      {presets.map(({ text, start, end }) => {
        const isSelected = moment(start).isSame(startDate, 'day') && moment(end).isSame(endDate, 'day');
        return (
          <RangeButton
            key={text}
            type="button"
            onClick={() => { onChange({ startDate: start, endDate: end }); }}
            isSelected={isSelected}
          >
            {text}
          </RangeButton>
        );
      })}
      {onGo && (
      <RangeButton
        key="Go"
        type="button"
        onClick={() => onClickGo()}
      >
        Go
      </RangeButton>
      )}
    </RangesContainer>
  );

  const onClickGo = () => {
    setFocusedInput(null);
    onGo();
  };

  const getYearsArray = () => {
    const yearsArray = [moment.utc().year()];
    while (yearsArray[yearsArray.length - 1] > 2016) yearsArray.push(yearsArray[yearsArray.length - 1] - 1);
    return yearsArray;
  };
  // eslint-disable-next-line react/prop-types
  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <MonthYearContainer>
      <div>
        {/* eslint-disable-next-line react/prop-types */}
        <Select value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
          {moment.months().map((label, value) => (
            <option key={label} value={value}>{label}</option>
          ))}
        </Select>
      </div>
      <div style={{ marginLeft: '10px' }}>
        {/* eslint-disable-next-line react/prop-types */}
        <Select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {getYearsArray().map((v) => <option value={v} key={v}>{v}</option>)}
        </Select>
      </div>
    </MonthYearContainer>
  );

  return (
    <DateRangePickerWrapper>
      <DateRangePicker
        startDate={startDate}
        startDateId="filter_start_date"
        endDate={endDate}
        endDateId="filter_end_date"
        renderCalendarInfo={isShownDatePresets ? renderDatePresets : null}
        onDatesChange={onChange}
        focusedInput={focusedInput}
        onFocusChange={(focusInput) => setFocusedInput(focusInput)}
        numberOfMonths={numberOfMonths || 1}
        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
        startDatePlaceholderText="From"
        endDatePlaceholderText="To"
        displayFormat={displayFormat || 'LL'}
        minimumNights={minimumNights}
        initialVisibleMonth={initialVisibleMonth}
        renderMonthElement={renderMonthElement}
        keepOpenOnDateSelect={!!onGo}
        showDefaultInputIcon
        showClearDates
        small
        block
        {...otherProps}
      />
    </DateRangePickerWrapper>
  );
};

DatesPicker.propTypes = {
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  numberOfMonths: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onGo: PropTypes.func,
  displayFormat: PropTypes.string,
  minimumNights: PropTypes.number,
  isShownDatePresets: PropTypes.bool,
};

export default DatesPicker;
