import React from 'react';
import {
  Row, Col, FormGroup, FormLabel,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as consts from '../../../constants';
import ImageLoader from '../../FilesView/ImageLoader';
import A from '../../A';
import Img from '../../Img';

const FileName = styled.div`
  padding-top: 7px;
  margin-bottom: 0;
`;
const Preview = styled(Img)`
  width: 127px;
`;

export default function FormImage(props) {
  const {
    id,
    validationState,
    labelText,
    labelColumnWidth,
    controlColumnWidth,
    editMode,
    loadFileHandler,
    removeHandler,
    file,
    ...others
  } = props;

  const fileNamePreview = (
    <FileName>
      {file ? <A to={file.url} target="_blank"><Preview src={file.thumbnail_url} alt="ePlane Animation" /></A> : 'No image'}
    </FileName>
  );
  const fileProps = (file && file.name) ? file : null;
  const title = fileProps
    ? (fileProps.name)
    : (
      <span>
        <FontAwesomeIcon icon="plus" />
        &nbsp;Add logo
      </span>
    );

  const fileLoaderPops = {
    onChangeHandler: loadFileHandler,
    onRemoveHandler: removeHandler,
    title,
    file: fileProps,
    ...others,
  };
  return (
    <FormGroup as={Row} validationState={validationState} controlId={id}>
      <Col as={FormLabel} sm={labelColumnWidth}>
        {labelText}
      </Col>
      <Col sm={controlColumnWidth}>
        {editMode ? <ImageLoader {...fileLoaderPops} /> : fileNamePreview}
      </Col>
    </FormGroup>
  );
}

FormImage.propTypes = {
  id: PropTypes.string,
  validationState: PropTypes.oneOf(['success', 'warning', 'error', null]),
  labelColumnWidth: PropTypes.number,
  controlColumnWidth: PropTypes.number,
  labelText: PropTypes.string,
  loadFileHandler: PropTypes.func,
  removeHandler: PropTypes.func,
  editMode: PropTypes.bool,
  file: PropTypes.shape({
    name: PropTypes.string,
    thumbnail_url: PropTypes.string,
    url: PropTypes.string,
  }),
};

/* TODO: resolve ESLINT issue */
/* eslint-disable react/default-props-match-prop-types */
FormImage.defaultProps = {
  feedBack: true,
  labelColumnWidth: consts.formOptions.labelColumnWidth,
  controlColumnWidth: consts.formOptions.controlColumnWidth,
  defaultInputLength: consts.formOptions.defaultInputLength,
};
/* eslint-enabable react/default-props-match-prop-types */
