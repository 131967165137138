import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { color } from '../../../constants';

const Wrapper = styled.div`
  background-color: ${color.lightGrey};
  padding: 17px 20px 14px;

  & > h3 {
    color: ${color.blue};
    letter-spacing: 0.4px;
    font-weight: bold;
    margin: 0;
    font-size: 16px;
  }
`;
const Title = styled.span`
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-weight: bold;
`;
const TableWrapper = styled.table`;
  width: 100%;
  table-layout: fixed;

  & td {
    padding: 7px 10px;
    line-height: 1;
  };

  & td:first-child {
    padding-left: 0;
  };

  & td:last-child {
    padding-right: 0;
  };

  & tr:first-child td {
    padding-top: 0;
    padding-bottom: 15px;
  }
  & tr:last-child td {
    padding-bottom: 0;
  }
`;
const TableItem = styled.td`
  word-break: break-all;
  width: ${(props) => (props.maxWidth ? props.maxWidth : 'none')};
`;

const PartDetailsBlock = ({ title, priority, content }) => {
  const colSize = (content && content[0].length === 2) ? 1 : 2;
  const contentProcessed = content && content.map((row, rowInd) => {
    const tdMaxWidth = `${100 / row.length}%`;

    return (
      // eslint-disable-next-line react/no-array-index-key
      <tr key={rowInd}>
        {row && row.map((data, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableItem key={i} maxWidth={tdMaxWidth}>
            <strong>
              {data[0]}
              :&nbsp;
            </strong>
            {data[1]}
          </TableItem>
        ))}
      </tr>
    );
  });

  return (
    <Wrapper>
      <TableWrapper>
        <tbody>
          <tr>
            <td colSpan={colSize}><Title>{title}</Title></td>
            <td>{priority}</td>
          </tr>
          {contentProcessed}
        </tbody>
      </TableWrapper>
    </Wrapper>
  );
};

PartDetailsBlock.propTypes = {
  title: PropTypes.string,
  priority: PropTypes.element,
  content: PropTypes.arrayOf(PropTypes.array),
};

export default PartDetailsBlock;
