import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Col, Row, Form, Button, ButtonGroup, Tooltip, OverlayTrigger,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UniversalFormControl from '../../FormComponents/UniversalControl';
import FormActionButtons from '../../FormComponents/ActionButtons';
import { FormSubTitle } from '../../FormComponents/TitleSection';
import { TabContainer, StyledLinkButton as LinkButton } from '../../StyledComponents';
import ModalLoader from '../../ModalLoader';
import AddKeyModal from './AddKeyModal';
import sshUserManagementValidators from './validators';
import { color, MAIL_READER_KEY, MAIL_ROTABULL_KEY } from '../../../constants';
import createErrorValidationObj from '../../../utils/createErrorValidationObj';
import CheckboxStyled from '../../FormComponents/CheckboxStyled';

const Text = styled.span`
  color: ${(props) => (props.color || '')};
  font-size: 16px;
`;
const HorizontalLine = styled.hr`
  margin-right: 70px;
`;
const TextAreaContainer = styled.div`
  textarea {
    resize: none;
    padding-bottom: 0px;
  }
  && textarea:disabled {
    border: 1px solid #ccc;
    background-color: #eee;
  }
  && .has-error .form-control {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  }
  &&& .glyphicon-remove {
    right: 25px;
  }
`;

const tooltip = (text) => (
  <Tooltip id="tooltip">
    {text}
  </Tooltip>
);

function* getDemandMaskAdvice() {
  yield '*.csv';
  yield '*.xlsx';
  return '';
}

export default class SFTPManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAddKeyModal: false,
      editMode: false,
      createUser: false,
      isChanged: false,
      pubdrUser: null,
      validationState: { keysList: [], whitelistedIP: [] },
    };
    this.advice = getDemandMaskAdvice();
  }

  onAddKeyBtnClick = () => {
    this.setState({ showAddKeyModal: true });
  };

  onAddDemandPatternClick = () => {
    this.setState((prevState) => {
      const newData = { ...prevState.pubdrUser };
      const validationState = { ...prevState.validationState };

      const advice = this?.advice?.next();
      newData.demands_patterns.push(advice.done ? '*.*' : advice.value);

      return {
        isChanged: true,
        pubdrUser: newData,
        validationState,
      };
    });
  };

  onAddWhitelistedIpClick = () => {
    const { pubdrUser } = this.state;

    // disallow to add new IP in case previous is empty
    if (pubdrUser?.ip_allow_list?.length && !pubdrUser.ip_allow_list[pubdrUser.ip_allow_list.length - 1]) return;

    this.setState((prevState) => {
      const newData = { ...prevState.pubdrUser };
      const validationState = { ...prevState.validationState };

      validationState.whitelistedIP = [];
      if (newData.ip_allow_list === null) {
        newData.ip_allow_list = [''];
      } else {
        newData.ip_allow_list.push('');
      }

      return {
        isChanged: true,
        pubdrUser: newData,
        validationState,
      };
    });
  };

  onAddValidKey = (key) => {
    this.setState((prevState) => {
      const newData = { ...prevState.pubdrUser };
      const validationState = { ...prevState.validationState };

      if (newData.keysList.length === 1 && newData.keysList[0].length === 0) {
        newData.keysList.shift();
        validationState.keysList = [];
      }
      newData.keysList = [...newData.keysList];
      newData.keysList.push(key);

      return {
        isChanged: true,
        pubdrUser: newData,
        validationState,
        showAddKeyModal: false,
      };
    });
  };

  onDeleteKeyBtbClick = (id) => () => {
    this.setState((prevState) => {
      const newData = { ...prevState.pubdrUser };
      const validationState = { ...prevState.validationState };

      newData.keysList = [...newData.keysList];
      newData.keysList.splice(id, 1);
      validationState.keysList = [];

      return { isChanged: true, pubdrUser: newData, validationState };
    });
  };

  onDeleteDemandBtbClick = (id) => () => {
    this.setState((prevState) => {
      const newData = { ...prevState.pubdrUser };
      const validationState = { ...prevState.validationState };

      newData.demands_patterns = [...newData.demands_patterns];
      newData.demands_patterns.splice(id, 1);

      return { isChanged: true, pubdrUser: newData, validationState };
    });
  };

  onDeleteWhitelistedIp = (id) => () => {
    this.setState((prevState) => {
      const newData = { ...prevState.pubdrUser };
      const validationState = { ...prevState.validationState };

      newData.ip_allow_list = [...newData.ip_allow_list];
      newData.ip_allow_list.splice(id, 1);

      return { isChanged: true, pubdrUser: newData, validationState };
    });
  };

  onChange = (e) => {
    const { isChanged } = this.state;
    const { value, name } = e.target;
    if (!isChanged) {
      this.setState({ isChanged: true });
    }

    this.setState((prevState) => {
      const user = { ...prevState.pubdrUser };
      const validationState = { ...prevState.validationState };
      validationState.keysList = validationState.keysList.slice(0);

      if (name.startsWith('ip_')) {
        const index = name.split('_')[1];
        user.ip_allow_list = [...user.ip_allow_list];
        user.ip_allow_list[index] = value;
        validationState.whitelistedIP = [];
      } else if (name.startsWith('pattern_')) {
        const index = name.split('_')[1];
        user.demands_patterns = [...user.demands_patterns];
        user.demands_patterns[index] = value;
      } else if (name.startsWith('key_')) {
        const index = name.split('_')[1];
        user.keysList = [...user.keysList];
        user.keysList[index] = value;
      } else {
        user[name] = value.toLowerCase();
        validationState[name] = sshUserManagementValidators[name](value);
      }

      return { pubdrUser: user, validationState };
    });
  };

  allowProcessingInventory = (e) => {
    const { target } = e;
    const value = target.checked;
    this.setState((prevState) => {
      const newData = { ...prevState.pubdrUser };
      newData.enabled = value;
      return { pubdrUser: newData, isChanged: true };
    });
  };

  allowProcessingDemands = (e) => {
    const { target } = e;
    const value = target.checked;
    this.setState((prevState) => {
      const newData = { ...prevState.pubdrUser };
      newData.demands_enabled = value;
      return { pubdrUser: newData, isChanged: true };
    });
  };

  onCreateClick = () => {
    const {
      sftpUser: { sftpUserData: { username_reference: userNameRef } },
    } = this.props;
    this.setState({
      createUser: true,
      pubdrUser: {
        user_name: userNameRef, keysList: [], enabled: true, demands_enabled: false, demands_patterns: [], ip_allow_list: [],
      },
      editMode: true,
      validationState: { keysList: [] },
    });
  };

  onSaveBttnHandler = () => {
    const { isChanged } = this.state;
    if (isChanged) this.save();
    else this.onChangeEditMode();
  };

  save = () => {
    const { sftpUser: { sftpUserData: { pubdrive_user: pubdrUserPr } }, saveSftpUser } = this.props;
    const { pubdrUser: pubdrUserSt, validationState } = this.state;

    const duplicateValidation = pubdrUserSt.keysList.map((v, i) => {
      const duplicate = pubdrUserSt.keysList.findIndex((v1, i1) => v === v1 && i !== i1);
      if (duplicate >= 0) {
        return createErrorValidationObj('You have already added this key');
      }
      return null;
    });

    if (duplicateValidation.some((value) => !!value)) {
      this.setState({ validationState: { keysList: duplicateValidation, whitelistedIP: [] } });
      return;
    }

    const whitelistValidation = pubdrUserSt.ip_allow_list.map((v) => (
      sshUserManagementValidators.whitelistedIP(v)
    ));

    if (whitelistValidation.some((value) => !!value)) {
      this.setState({ validationState: { keysList: [], whitelistedIP: whitelistValidation } });
      return;
    }

    if (!validationState.user_name && !validationState.keysList.some((value) => !!value)) {
      const sftpUserChanges = {};
      if (pubdrUserSt.user_name !== pubdrUserPr.user_name) {
        sftpUserChanges.user_name = pubdrUserSt.user_name;
      }
      const sshKeys = pubdrUserSt.keysList.join('\n');
      if (pubdrUserPr.ssh_keys !== sshKeys) {
        sftpUserChanges.ssh_keys = sshKeys;
      }
      if (pubdrUserSt.enabled !== pubdrUserPr.enabled) {
        sftpUserChanges.enabled = pubdrUserSt.enabled;
      }
      if (pubdrUserSt.demands_enabled !== pubdrUserPr.demands_enabled) {
        sftpUserChanges.demands_enabled = pubdrUserSt.demands_enabled;
      }
      if (sftpUserChanges.demands_enabled === false) {
        sftpUserChanges.demands_patterns = [];
      } else {
        sftpUserChanges.demands_patterns = pubdrUserSt.demands_patterns;
      }
      if (pubdrUserPr.ip_allow_list !== pubdrUserSt.ip_allow_list) {
        sftpUserChanges.ip_allow_list = pubdrUserSt.ip_allow_list;
      }

      if (Object.keys(sftpUserChanges).length) {
        saveSftpUser(pubdrUserPr.user_id, sftpUserChanges);
      }
      this.onChangeEditMode();
    }
  };

  onChangeEditMode = () => {
    this.setState((prevState) => {
      if (prevState.editMode) {
        return {
          editMode: false,
          isChanged: false,
          createUser: false,
          validationState: { keysList: [] },
        };
      }

      const { sftpUser: { sftpUserData } } = this.props;
      const sshKeys = parseKeys(sftpUserData.pubdrive_user.ssh_keys) || [];
      const validation = sshKeys && sshKeys.map((v) => sshUserManagementValidators.sshKeyValidator(v));
      return {
        editMode: true,
        pubdrUser: {
          ...sftpUserData.pubdrive_user,
          keysList: sshKeys,
        },
        validationState: { keysList: validation || [] },
      };
    });
  };

  showAddKeyModal = () => {
    this.setState({ showAddKeyModal: false });
  };

  addPatternsCtrl(patternList, editMode, addDisabled) {
    return (
      <Form.Group as={Row}>
        <Col sm={{ span: 6, offset: 2 }}>
          {editMode && patternList.length === 0 && (
            <p>There is no demand patterns. </p>)}
          <OverlayTrigger placement="right" overlay={tooltip('Add')}>
            <Button
              variant="outline-info"
              onClick={this.onAddDemandPatternClick}
              disabled={addDisabled}
              size="sm"
            >
              <b>+ Demand pattern</b>
            </Button>
          </OverlayTrigger>
        </Col>
      </Form.Group>
    );
  }

  addWhitelistedIpCtrl(ipList, editMode) {
    return (
      <Form.Group as={Row}>
        <Col sm={{ span: 6, offset: 2 }}>
          {editMode && ipList.length === 0 && (
            <p>There are no whitelisted IP addresses. </p>)}
          <OverlayTrigger placement="right" overlay={tooltip('Add')}>
            <Button
              variant="outline-info"
              onClick={this.onAddWhitelistedIpClick}
              disabled={!editMode}
              size="sm"
            >
              <b>+ whitelisted IP</b>
            </Button>
          </OverlayTrigger>
        </Col>
      </Form.Group>
    );
  }

  render() {
    const {
      sftpUser: {
        sftpUserData,
        isSaving,
      },
    } = this.props;
    const {
      editMode,
      createUser,
      validationState: {
        user_name: userNameValidation,
        keysList: keysValidation,
        whitelistedIP: whitelistedIPValidation,
      },
      showAddKeyModal,
    } = this.state;
    let pubdrUser;
    const isUserExist = sftpUserData && sftpUserData.is_user_exist;
    if (editMode) ({ pubdrUser } = this.state);
    else pubdrUser = sftpUserData && sftpUserData.pubdrive_user;
    const sftpUserName = pubdrUser && pubdrUser.user_name;
    const isProcessingEnabled = pubdrUser && pubdrUser.enabled;
    const isProcessingDemandsEnabled = pubdrUser && pubdrUser.demands_enabled;
    const keyList = editMode
      ? pubdrUser.keysList
      : (pubdrUser && pubdrUser.ssh_keys && parseKeys(pubdrUser.ssh_keys)) || [];
    const patternList = (pubdrUser && pubdrUser.demands_patterns) || [];
    const ipWhitelist = (pubdrUser && pubdrUser.ip_allow_list) || [];
    const showEditButton = isUserExist || createUser;
    const saveProps = {
      disabled: (keyList.length === 0 || keyList[0] === '') || (isProcessingDemandsEnabled && patternList.length === 0),
    };
    let content;

    if (isUserExist || createUser) {
      content = (
        <Form>
          <Form.Group as={Row}>
            <Col sm={10} lg={6}>
              <UniversalFormControl
                labelText="User"
                name="user_name"
                value={sftpUserName || ''}
                disabled={!editMode}
                onChange={this.onChange}
                labelColumnWidth={3}
                controlColumnWidth={9}
                {...userNameValidation}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col sm={6} lg={3}>
              <CheckboxStyled
                id="checkbox-processing-inventory"
                label="Enable processing inventory"
                checked={isProcessingEnabled}
                name="enabled"
                onChange={this.allowProcessingInventory}
                disabled={!editMode}
              />
            </Col>
            <Col sm={6} lg={3}>
              {
                // eslint-disable-next-line react/jsx-one-expression-per-line
                messageRender(<>SFTP Hostname: <i>invupload.eplane.com</i></>)
              }
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col sm={6} lg={3}>
              <CheckboxStyled
                id="checkbox-processing-demands"
                label="Enable processing demands"
                checked={isProcessingDemandsEnabled}
                name="demandsEnabled"
                onChange={this.allowProcessingDemands}
                disabled={!editMode}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col sm={12}>
              {
                isProcessingDemandsEnabled
                && getDemandPatterns(patternList, !editMode, this.onChange, this.onDeleteDemandBtbClick)
              }
              {
                isProcessingDemandsEnabled && this.addPatternsCtrl(patternList, editMode, !editMode || !isProcessingDemandsEnabled)
              }
            </Col>
          </Form.Group>
          <HorizontalLine />

          <Form.Group as={Row}>
            <Col sm={12}>
              {
                ipWhitelist
                && getIpWhiteList(ipWhitelist, !editMode, this.onChange, this.onDeleteWhitelistedIp, whitelistedIPValidation)
              }
              {this.addWhitelistedIpCtrl(ipWhitelist, editMode)}
            </Col>
          </Form.Group>
          <HorizontalLine />

          <div>
            {
              keyList
              && getKeyList(keyList, keysValidation, !editMode, this.onChange, this.onDeleteKeyBtbClick)
            }
          </div>

          <Form.Group as={Row}>
            <Col sm={{ span: 6, offset: 2 }}>
              { editMode && keyList.length === 0 && (
                <p>There is no key for the SSH user. </p>
              )}
              <ButtonGroup size="sm" className="mb-2">
                <OverlayTrigger placement="left" overlay={tooltip('Add')}>
                  <Button
                    variant="outline-info"
                    onClick={this.onAddKeyBtnClick}
                    disabled={!editMode}
                  >
                    <b>+ Custom key</b>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={tooltip('Add')}>
                  <Button
                    variant="outline-info"
                    onClick={() => this.onAddValidKey(MAIL_READER_KEY)}
                    disabled={!editMode}
                  >
                    <b>+ Mail Reader</b>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="right" overlay={tooltip('Add')}>
                  <Button
                    variant="outline-info"
                    onClick={() => this.onAddValidKey(MAIL_ROTABULL_KEY)}
                    disabled={!editMode}
                  >
                    <b>+ Rotabull</b>
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col sm={{ span: 6, offset: 1 }}>
              {editMode && (
                <FormActionButtons
                  saveProps={saveProps}
                  saveHandler={this.onSaveBttnHandler}
                  cancelHandler={this.onChangeEditMode}
                />
              )}
            </Col>
          </Form.Group>
        </Form>
      );
    } else {
      content = (
        <div>
          <p>SFTP user do not exist.</p>
          <LinkButton variant="link" onClick={this.onCreateClick}>
            <FontAwesomeIcon icon="user-plus" />
            &nbsp;
            Create
          </LinkButton>
        </div>
      );
    }

    return (
      <>
        <ModalLoader show={isSaving} />
        <FormSubTitle
          title="SFTP Credentials"
          EditButtonClick={this.onChangeEditMode}
          showEditButton={showEditButton}
        />
        <TabContainer>{content}</TabContainer>
        <AddKeyModal show={showAddKeyModal} saveClickHandler={this.onAddValidKey} onHide={this.showAddKeyModal} />
      </>
    );
  }
}

SFTPManagement.propTypes = {
  sftpUser: PropTypes.shape({
    sftpUserData: PropTypes.shape({
      username_reference: PropTypes.string,
      pubdrive_user: PropTypes.shape({
        ssh_keys: PropTypes.arrayOf(PropTypes.string),
        demands_patterns: PropTypes.arrayOf(PropTypes.string),
      }),
      is_user_exist: PropTypes.bool,
    }),
    userData: PropTypes.shape({}),
    isSaving: PropTypes.bool,
  }),
  saveSftpUser: PropTypes.func.isRequired,
};

const getKeyList = (keysList, validation, disabled, onChange, handleDeleteBtn) => (
  keysList.map((key, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Row key={index}>
      <Col sm={10} xl={6}>
        <TextAreaContainer>
          <UniversalFormControl
            labelText={`Public Key ${index + 1}`}
            value={key || ''}
            name={`key_${index}`}
            onChange={onChange}
            disabled
            defaultInputLength={500}
            as="textarea"
            rows="3"
            labelColumnWidth={3}
            controlColumnWidth={9}
            {...validation[index]}
          />
        </TextAreaContainer>
      </Col>
      <Form.Group as={Col} sm={1}>
        <ButtonGroup>
          <OverlayTrigger placement="top" overlay={tooltip('Remove')}>
            <Button
              disabled={disabled}
              onClick={handleDeleteBtn(index)}
              variant="light"
            >
              <FontAwesomeIcon icon="trash-alt" color={color.darkBirch} size="lg" />
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </Form.Group>
    </Row>
  ))
);

const getDemandPatterns = (patternList, disabled, onChange, handleDeleteBtn) => (
  patternList.map((key, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Row key={index}>
      <Col sm={10} xl={6}>
        <UniversalFormControl
          labelText={`Demand pattern ${index + 1}`}
          value={key || ''}
          name={`pattern_${index}`}
          disabled={disabled}
          onChange={onChange}
          labelColumnWidth={3}
          controlColumnWidth={9}
        />
      </Col>
      <Form.Group as={Col} sm={1}>
        <ButtonGroup>
          <OverlayTrigger placement="top" overlay={tooltip('Remove')}>
            <Button
              disabled={disabled}
              variant="light"
              onClick={handleDeleteBtn(index)}
            >
              <FontAwesomeIcon icon="trash-alt" color={color.darkBirch} size="lg" />
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </Form.Group>
    </Row>
  ))
);

const getIpWhiteList = (ipList, disabled, onChange, handleDeleteBtn, validation = []) => (
  ipList.map((key, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Row key={index}>
      <Form.Group as={Col} sm={10} xl={6}>
        <TextAreaContainer>
          <UniversalFormControl
            labelText={`Whitelist IP ${index + 1}`}
            value={key || ''}
            name={`ip_${index}`}
            disabled={disabled}
            onChange={onChange}
            labelColumnWidth={3}
            controlColumnWidth={9}
            {...validation[index]}
          />
        </TextAreaContainer>
      </Form.Group>
      <Form.Group as={Col} sm={1}>
        <ButtonGroup>
          <OverlayTrigger placement="top" overlay={tooltip('Remove')}>
            <Button
              disabled={disabled}
              onClick={handleDeleteBtn(index)}
              variant="light"
            >
              <FontAwesomeIcon icon="trash-alt" color={color.darkBirch} size="lg" />
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </Form.Group>
    </Row>
  ))
);

function parseKeys(str) {
  return str && str.split('\n');
}

function messageRender(text) {
  return (
    <>
      <Text color={color.darkGrey}><FontAwesomeIcon icon="exclamation-circle" /></Text>
      &nbsp;
      {text}
    </>
  );
}
