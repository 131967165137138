import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { authorizationFail } from '../../../actions/authActions';
import { fetchCustomer } from '../../../actions/userManagement/customerActions';
import { fetchCompanyCustomerList, companyCustomerDismiss } from '../../../actions/companiesManagement/companyCustomerActions';
import AutosuggestControl from '../../FormComponents/AutosuggestControl';
import {
  getCompanyListStore,
  getUserStore,
  getCompanyCustomer,
  getCustomerStore,
} from '../../../selectors';
import ModalLoader from '../../ModalLoader';
import { fetchCompanyList } from '../../../actions/companyListActions';
import createRegExpForString from '../../../utils/createRegExpForString';
import FormTitleSection from '../../FormComponents/TitleSection';
import { TabContainer } from '../../StyledComponents';
import InfoContent from './InfoContent';
import InternalErrorContent from '../../InternalErrorContent';
import ConfirmationLastUserModal from '../../Modals/ConfirmationLastUserModal';
import StatusIndicator from './StatusIndicator';
import { color } from '../../../constants';
import * as constants from '../../../constants/index';

const renderSuggestion = (value) => <span>{value.company_name}</span>;

export default function AssociateUserPage() {
  const { userId, customerId } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const [modals, setModals] = useState({
    showWarnModal: false,
    showConfirmationModal: false,
  });
  const [suggestedCompanies, setSuggestedCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [associateData, setAssociateData] = useState({
    company_name: '',
    associatingError: null,
    successText: null,
    isAssociating: false,
  });

  const {
    companies, isFetching: isFetchingCompanies, errorCode: errorCodeCompanyList,
  } = useSelector(getCompanyListStore);

  const {
    customers: companyCustomers,
    isFetching: companyCustomersIsFetching,
    isDismissing,
    fetchingErrorCode: companyCustomersError,
    dismissingErrorCode,
  } = useSelector(getCompanyCustomer);

  const {
    customer, isFetching: customerIsFetching,
  } = useSelector(getCustomerStore);

  const {
    user, isFetching: userIsFetching, isCustomerChecked,
  } = useSelector(getUserStore);

  let isLoadingInfoData;
  const isCompanyUser = !!(user?.user_type === constants.userType.COMPANY.id);
  const isUnconfirmedCustomer = !!(customer?.email_status_indication === constants.customerEmailStatus.NOT_VERIFIED);
  let currentUserMail;
  let currentEmailStatus;
  let currentCompanyName;
  let currentUsers;
  let hasInventory = false;
  let hasPro = false;

  if (!user || !customer || companyCustomersIsFetching || userIsFetching || isDismissing) {
    isLoadingInfoData = true;
  } else {
    currentUserMail = customer.email;
    currentEmailStatus = customer.email_status_indication;
    currentCompanyName = user.company_name;
    currentUsers = isCompanyUser && companyCustomers;
    hasInventory = user.has_published_inventory;
    hasPro = user.is_pro;
  }

  const showDisassociateButton = isLoadingInfoData || isDismissing || !(user.user_type === constants.userType.COMPANY.id);
  const companyCustomersErrorCode = companyCustomersError || dismissingErrorCode;

  useEffect(() => {
    if (!customer && isCustomerChecked) {
      dispatch(fetchCustomer(customerId));
    }
    if (isCompanyUser) {
      dispatch(fetchCompanyCustomerList(userId));
    }
    dispatch(fetchCompanyList());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (isCompanyUser) {
      dispatch(fetchCompanyCustomerList(userId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isCompanyUser]);

  useEffect(() => {
    if (!customer && isCustomerChecked && !customerIsFetching) {
      dispatch(fetchCustomer(customerId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customer, customerIsFetching, isCustomerChecked]);

  useEffect(() => {
    dispatch(fetchCompanyCustomerList(userId));
    dispatch(fetchCustomer(customerId));
  }, [dispatch, userId, customerId]);

  const onSuggestionCalculate = ({ value }) => {
    const re = createRegExpForString(value, 'i');
    const suggested = companies.filter((val) => re.test(val.company_name));
    setSuggestedCompanies(suggested);
  };

  const onClearSuggestions = () => setSuggestedCompanies([]);

  const onChangeInput = (e) => {
    setAssociateData((prev) => ({
      ...prev,
      company_name: e.target.value,
    }));
  };

  const onSuggestionSelected = (e, { suggestion }) => setSelectedCompany(suggestion);

  const onHandleSubmitAssociation = (e) => {
    e.preventDefault();
    const {
      isAssociating,
      company_name: companyName,
    } = associateData;

    const companyNameInput = companyName && companyName.trim();
    const companyList = companies;
    if (!companyList.length || isLoadingInfoData || isAssociating) return;

    if (!companyNameInput) {
      associationFail('Please enter Company name.');
      return;
    }

    if (isCompanyUser) {
      const activeCustomers = currentUsers.filter((elem) => elem.customer_status === constants.customerStatus.ACTIVE.id);
      if (customer.customer_status === constants.customerStatus.ACTIVE.id && activeCustomers.length === 1) {
        showWarnModal();
        return;
      }
    }

    const company = companyList.find((val) => val.company_name === companyNameInput);
    if (selectedCompany?.company_name === companyNameInput) {
      associateUser(selectedCompany.company_id);
    } else if (company) {
      associateUser(company.company_id);
    } else {
      associationFail(`Company with name '${companyNameInput}' is not found!`);
    }
  };

  const associateUser = (newUserId) => {
    startAssociating();

    const url = `/rest/default/V1/eplane/backoffice/user/${userId}/companyCustomers/${customerId}/associate`;
    const data = { newUserId };
    axios.post(url, data).then(() => {
      associationIsFinished(newUserId);
    }).catch((e) => {
      const status = e && e.response && e.response.status;
      if (status === 403) {
        dispatch(authorizationFail());
      } else if (status === 400) {
        const { message } = e.response.data;
        associationFail(message || 'Something went wrong!');
      } else {
        associationFail('Something went wrong!');
      }
    });
  };

  const startAssociating = () => setAssociateData((prev) => ({
    ...prev,
    associatingError: null,
    isAssociating: true,
    successText: null,
  }));

  const associationIsFinished = (newUserId) => {
    setAssociateData((prev) => ({
      ...prev,
      successText: 'Success!',
      isAssociating: false,
    }));

    history.replace(`/userManagement/${newUserId}/${customerId}/associateUser`);
  };

  const associationFail = (errorText) => setAssociateData((prev) => ({
    ...prev,
    isAssociating: false,
    associatingError: errorText,
    successText: null,
  }));

  const associateWarnModalClick = () => {
    closeWarnModal();

    const { company_name: companyName } = associateData;
    const companyNameInput = companyName.trim();
    if (!companies) return;
    let newUserId;

    const company = companies.find((val) => val.company_name === companyNameInput);
    if (company) {
      newUserId = company.company_id;
    } else {
      associationFail(`Company with name '${companyNameInput}' is not found!`);
      return;
    }
    associateUser(newUserId);
  };

  const showWarnModal = () => setModals((prev) => ({ ...prev, showWarnModal: true }));

  const closeWarnModal = () => setModals((prev) => ({ ...prev, showWarnModal: false }));

  const disassociate = () => {
    dispatch(companyCustomerDismiss(customerId, userId, true));
    hideConfirmationModal();
  };

  const onDisassociateClick = () => {
    if (isCompanyUser) {
      const activeCustomers = currentUsers.filter((elem) => elem.customer_status === constants.customerStatus.ACTIVE.id);
      if (customer.customer_status === constants.customerStatus.ACTIVE.id && activeCustomers.length === 1) {
        setModals((prev) => ({ ...prev, showConfirmationModal: true }));
      } else {
        disassociate();
      }
    }
  };

  const hideConfirmationModal = () => setModals((prev) => ({ ...prev, showConfirmationModal: false }));

  const {
    company_name: companyName,
    associatingError,
    successText,
    isAssociating,
  } = associateData;

  if (companyCustomersErrorCode || errorCodeCompanyList) return <InternalErrorContent />;

  return (
    <div>
      <ModalLoader show={isFetchingCompanies} />

      <FormTitleSection title="Associate with Company" showEditButton={false} />
      <TabContainer>
        <form onSubmit={onHandleSubmitAssociation}>
          <AutosuggestControl
            suggestions={suggestedCompanies.slice(0, 30)}
            onSuggestionsFetchRequested={onSuggestionCalculate}
            onSuggestionsClearRequested={onClearSuggestions}
            getSuggestionValue={(value) => value.company_name}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={onSuggestionSelected}
            placeholder="Company name..."
            name="company_id"
            value={companyName}
            onChange={onChangeInput}
          />

          <Button type="submit" disabled={isUnconfirmedCustomer || isAssociating || isLoadingInfoData} variant="primary">
            {isAssociating ? 'Associating...' : 'Associate'}
          </Button>
        </form>
        <hr />

        {!!(successText || associatingError) && (
          <StatusIndicator
            isVisible={!!(successText || associatingError)}
            text={successText || associatingError}
            isSuccess={!!successText}
          />
        )}
        {customer && customer.on_behalf_role && customer.on_behalf_role.length > 0 && onBefalfWarning()}
        {customer && customer.referenced_in && customer.referenced_in.length > 0 && referencedWarning(customer.referenced_in)}

        <InfoContent
          isLoading={isLoadingInfoData}
          isCompanyUser={isCompanyUser}
          currentUserMail={currentUserMail}
          currentEmailStatus={currentEmailStatus}
          currentCompanyName={currentCompanyName}
          currentUsers={currentUsers}
        />
        <br />
        <Button disabled={showDisassociateButton} variant="primary" onClick={onDisassociateClick}>
          {isDismissing ? 'Disassociating...' : 'Disassociate'}
        </Button>
      </TabContainer>

      <ConfirmationLastUserModal
        show={modals.showConfirmationModal}
        confirmBtnText="Disassociate"
        confirm={disassociate}
        onHide={hideConfirmationModal}
        hasInventory={hasInventory}
        hasPro={hasPro}
        userId={userId}
      />
      <ConfirmationLastUserModal
        show={modals.showWarnModal}
        confirmBtnText="Associate"
        confirm={associateWarnModalClick}
        onHide={closeWarnModal}
        hasInventory={hasInventory}
        hasPro={hasPro}
        userId={userId}
      />
    </div>
  );
}

const onBefalfWarning = () => (
  <>
    <p>
      <FontAwesomeIcon icon="exclamation-triangle" style={{ color: color.darkorange }} />
      &nbsp;
      Current user already belongs to a company and on his behalf automatic quotes and/or RFQs are sent.
      Re-associating the user will reset the&nbsp;&#39;on behalf&#39; setting.
    </p>
    <hr />
  </>
);

const referencedWarning = () => (
  <>
    <p>
      <FontAwesomeIcon icon="exclamation-triangle" style={{ color: color.darkorange }} />
      &nbsp;
      Current user has already created RFQs and/or uploaded demands. Ask the development team for assistance&nbsp;
      if they also need to be transferred.
    </p>
    <hr />
  </>
);
