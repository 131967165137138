import styled from 'styled-components';
import { Form } from 'react-bootstrap';

const CheckboxStyled = styled(Form.Check)`
  margin-left: 10px;
  & input {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  & input[type=checkbox]::-ms-check {
    display: none;
  }
  &&& input {
    cursor: pointer;
    outline: 0;
    outline-offset: 0;
    margin-left: -20px;
  }
  
  input:checked::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 17px;
    top: -8px;
    left: -2px;
    font-size: 16px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #26a69a;
    border-bottom: 2px solid #26a69a;
    transform: rotate(40deg);
  }
  
  input::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    top: -8px;
    left: -6px;
    border: 2px solid #5a5a5a;
    border-radius: 1px;
    margin-top: 3px;
    -webkit-transition: .2s;
    transition: .2s;
  }
  
  input:disabled::after {
    border-color: #5a5a5a;
    background: #ddd;
    color: gray;
    opacity: 0.8;
  }
  
  input:checked:disabled::after {
    content: "";
    width: 8px;
    height: 17px;
    background: #ffffff;
    position: absolute;
    top: -8px;
    left: -2px;
    font-size: 16px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #26a69a;
    border-bottom: 2px solid #26a69a;
  }  
`;

export default CheckboxStyled;
