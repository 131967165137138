import { combineReducers } from 'redux';

import quote from './quote';
import tasks from './tasks';
import payments from './payments';
import formManagement from './formManagement';
import taskIgnoreList from './taskIgnoreList';

export default combineReducers({
  quote,
  tasks,
  payments,
  formManagement,
  taskIgnoreList,
});
