import styled from 'styled-components';
import { Button as ButtonBS } from 'react-bootstrap';

import { color } from '../../constants';
import H2Normal from '../H2/index';

export const Title = styled(H2Normal)`
  font-weight: 700;
  color: ${color.blue};
  letter-spacing: 0.55px;
  font-size: 22px;
  float: left;
  display: block;
  margin-top: 0;
`;

export const TitleSection = styled.div`
  margin: 20px 0 20px 0;
  border-bottom: 4px solid ${color.lightGrey};
  overflow: hidden;
`;

export const Text = styled.div`
  margin-top: 22px;
  font-size: 26px;
  font-weight: 400;
  text-align: center;
  line-height: 1.35;
`;

export const ButtonsWrapper = styled.div`
  & {
  text-align: center;
  }

  &&&  button:not(:first-child) {
    margin-left: 20px;
  }
`;

export const Button = styled(ButtonBS)`
  && {
    font-size: 16px;
    padding: 6px 20px;
  }
`;

export const ButtonContainer = styled.div`
  &>button {
    min-width: ${(props) => (props.btnWidth || 'auto')}
  }
  &&&>button:not(:first-child) {
    margin-left: ${(props) => (props.space || '5px')}
  }
`;

export const LinkButton = styled.span`
  color: ${color.birch};
  cursor: pointer;
`;

export const HorizontalLine = styled.hr`
  border-top: 2px solid ${(props) => props.color || color.lightGrey};
`;

export const FixedWidthContainer = styled.div`
  max-width: ${(props) => (props.maxWidth || 'none')};
`;

export const WeightListTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 5px;
  font-size: 16px;
  border-bottom: 2px solid rgba(21, 58, 84, 0.6);
`;
