import {
  DOCUMENT_LIST_FETCH,
  DOCUMENT_LIST_IS_FETCHED,
  DOCUMENT_UPLOADING_START,
  DOCUMENT_UPLOADING_FINISH,
  DOCUMENT_UPLOADING_PROGRESS_SET,
  DOCUMENT_UPLOADING_TERMINATE,
  DOCUMENT_REMOVE,
  DOCUMENT_LIST_ERROR_OCCURRED,
  DOCUMENT_LIST_ERROR_REMOVE,
  AXIOS_CANCELLED_MESSAGE,
} from '../../constants';

const initialState = {
  documentList: [],
  uploadingProgress: null,
  source: null, // is used to cancel uploading document
  isFetching: false,
  isUploading: false,
  errorCode: null,
};

export default function documentsReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case DOCUMENT_LIST_FETCH: return { ...state, isFetching: true, errorCode: null };
    case DOCUMENT_LIST_IS_FETCHED: return {
      ...state,
      isFetching: false,
      documentList: payload && payload.documents && Object.values(payload.documents),
    };
    case DOCUMENT_UPLOADING_START:
      return {
        ...state,
        isUploading: true,
        uploadingProgress: null,
        source: payload,
      };
    case DOCUMENT_UPLOADING_FINISH:
      return {
        ...state,
        isUploading: false,
        documentList: state.documentList.concat(payload && payload.documents && payload.documents[0]),
        source: false,
      };
    case DOCUMENT_REMOVE:
      return {
        ...state,
        documentList: state.documentList.filter((doc) => doc.document_id !== payload),
      };
    case DOCUMENT_UPLOADING_PROGRESS_SET: return { ...state, uploadingProgress: payload };
    case DOCUMENT_UPLOADING_TERMINATE:
      if (state.source) {
        state.source.cancel(AXIOS_CANCELLED_MESSAGE);
      }
      return {
        ...state,
        uploadingProgress: null,
        isUploading: false,
        source: null,
      };

    case DOCUMENT_LIST_ERROR_OCCURRED: return { ...state, errorCode: payload, isFetched: false };
    case DOCUMENT_LIST_ERROR_REMOVE: return { ...state, errorCode: null };

    default: return state;
  }
}

export const getDocumentList = (state) => state.documentList;

export const getDocumentMap = (documentList, constants) => {
  if (!documentList || !constants) return null;
  const documentMap = {
    exchangeAgreement: null,
    genericDocument: [],
    isoCertificate: [],
    loanAgreement: null,
    purchaseAgreement: null,
    repairAgreement: null,
    repairCertificate: [],
    salesAgreement: null,
  };
  const { documentType } = constants;
  documentList.forEach((rawDoc) => {
    /* eslint-disable no-multi-spaces */
    const doc = processDocument(rawDoc);
    switch (doc.docType) {
      case documentType.EXCHANGE_AGREEMENT.id: documentMap.exchangeAgreement = doc; break;
      case documentType.GENERIC_DOCUMENT.id:   documentMap.genericDocument.push(doc); break;
      case documentType.ISO_CERTIFICATE.id:    documentMap.isoCertificate.push(doc); break;
      case documentType.LOAN_AGREEMENT.id:     documentMap.loanAgreement = doc; break;
      case documentType.PURCHASE_AGREEMENT.id: documentMap.purchaseAgreement = doc; break;
      case documentType.REPAIR_AGREEMENT.id:   documentMap.repairAgreement = doc; break;
      case documentType.REPAIR_CERTIFICATE.id: documentMap.repairCertificate.push(doc); break;
      case documentType.SALES_AGREEMENT.id:    documentMap.salesAgreement = doc; break;
      /* eslint-enable no-multi-spaces */
      default: console.error('Unknown doc type: ', doc.docType); // eslint-disable-line no-console
    }
  });

  return documentMap;
};
const processDocument = (rawDoc) => ({
  name: rawDoc.file_name,
  id: rawDoc.document_id,
  url: rawDoc.url,
  docType: rawDoc.document_type,
});
