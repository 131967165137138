import moment from 'moment';

import {
  rfqFilterSalesOrderStatus, rfqFilterPurchasesOrderStatus, demandStatus, companyType, rfqFilterStatus, dealTypes, demandPriority,
  quotePositions,
} from '../../constants/index';

export const getFilters = (filterStore) => {
  const { filters } = filterStore;

  const filtersData = filters.map((v) => {
    const agent = getOptionFromString(v.agent);
    const buyerCompanyType = getOptionFromString(v.buyer_company_type);
    const sellerCompanyType = getOptionFromString(v.seller_company_type);
    const dealType = getOptionFromString(v.deal_type);
    const priority = getOptionFromString(v.priority);
    const orderStatus = getOptionFromString(v.order_status);
    const buyerId = getOptionFromString(v.buyer_id);
    const sellerId = getOptionFromString(v.seller_id);
    const qPositions = getOptionFromString(v.quote_position);
    const replies = (v.replies && { value: v.replies, label: `More than ${v.replies}` }) || null;
    return {
      ...v,
      agent,
      priority,
      deal_type: dealType,
      order_status: orderStatus,
      buyer_id: buyerId,
      buyer_company_type: buyerCompanyType,
      seller_id: sellerId,
      seller_company_type: sellerCompanyType,
      quote_position: qPositions,
      date_to: v.date_to && moment.utc(v.date_to),
      date_from: v.date_from && moment.utc(v.date_from),
      replies,
    };
  });
  return filtersData;
};

const getOptionFromString = (string) => {
  if (!string) return null;
  return string.split(',').map((v) => ({ value: (+v) || v }));
};

export const getFilterSelectOptions = (companies, activeCompanyCustomers, legalEntities) => ({
  agent: getAgentOptions(activeCompanyCustomers),
  companies: getCompaniesOptions(companies),
  legalEntities: getLegalEntities(legalEntities),
  salesStatus: getOptions(rfqFilterSalesOrderStatus),
  purchasesStatus: getOptions(rfqFilterPurchasesOrderStatus),
  autopilotStatus: getOptions(demandStatus),
  companyType: getOptions(companyType),
  rfqFilterStatus: getOptions(rfqFilterStatus),
  dealTypes: getOptions(dealTypes),
  demandPriority: getOptions(demandPriority),
  replies: getRepliesOptions(),
  quotePositions: getOptions(quotePositions),
});

function getOptions(consts) {
  return Object.values(consts).map((s) => ({ value: s.id, label: s.name }));
}

function getRepliesOptions() {
  return Array(5).fill(0).map((_, i) => ({ value: `${i + 2}`, label: `More than ${i + 1}` }));
}

export function getAgentOptions(customers) {
  if (!customers) return [];
  return Object.keys(customers).map((s) => (
    { value: +s, label: `${customers[s].firstname} ${customers[s].lastname}` }
  ));
}

function getLegalEntities(legalEntities) {
  return legalEntities.map((s) => ({ value: +s.company_id, label: s.company_name || `User Id: ${s.company_id}` }));
}

function getCompaniesOptions(consts) {
  return Object.values(consts).map((s) => ({ value: +s.company_id, label: s.company_name }));
}
