import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.p`
  margin: 30px 20px 5px;
`;

export default function SearchInfo({ lengthOfItems }) {
  if (lengthOfItems > 1) {
    return <Container>{`There are ${lengthOfItems} results that match your search:`}</Container>;
  }
  if (lengthOfItems === 1) {
    return <Container>There is 1 result that match your search:</Container>;
  }
  return <Container>There are no results that match your search.</Container>;
}

SearchInfo.propTypes = {
  lengthOfItems: PropTypes.number.isRequired,
};
