import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { resetUploadState } from '../../../actions/fileUploaderActions';
import { uploadTagFile } from '../../../actions/networkOperationsActions/builtInQuoteActions';
import { getFileUploaderStore } from '../../../selectors';
import { getQuoteOptions, getChatPartNumber } from '../../../selectors/rfq';
import { paymentRequestAdd } from '../../../actions/networkOperationsActions/paymentsActions';
import { hideForm } from '../../../actions/networkOperationsActions/formManagementActions';

import RequestFormContent from './RequestFormContent';
import FormActionButtons from '../../FormComponents/ActionButtons';
import FormRadioGroup from '../../FormComponents/RadioGroup';
import { Title, SectionText, FormButtonContainer } from '../StyledComponents';
import NotificationsGroup from './NotificationGroup';
import { WarningMessage } from '../ProvideQuote/BuiltInQuoteForm';

import PaymentRequestValidators from './PaymentRequestValidators';
import { paymentRequestTypes, tagType as tagTypes, chatAtchRfqRole } from '../../../constants/index';
import { OTHER_SELECT_PICKUP_KEY } from '../../../constants';
import { getReqTotalPrice, getReqPricePerUnit } from '../utils';

const dealTypesOptions = [
  { value: paymentRequestTypes.BUY, labelText: 'Buy' },
  { value: paymentRequestTypes.EXCHANGE, labelText: 'Exchange' },
  { value: paymentRequestTypes.LOAN, labelText: 'Loan' },
  { value: paymentRequestTypes.REPAIR, labelText: 'Repair' },
];
const notification = {
  sellerNotification: {
    checked: false,
    disabled: true,
  },
  buyerNotification: {
    checked: true,
    disabled: false,
  },
};

export default function PaymentRequestForm({ orderId }) {
  const dispatch = useDispatch();

  const chatPartNumber = useSelector(getChatPartNumber);
  const selectOptions = useSelector(getQuoteOptions);
  const { files, isUploading } = useSelector(getFileUploaderStore);

  const [validationState, setValidationState] = useState({});
  const [dealType, setDealType] = useState(paymentRequestTypes.BUY);
  const [formData, setFormData] = useState({
    qty: '1',
    qty_units: selectOptions.unitsOptions[0],
    currency: selectOptions.supportedCurrenciesOptions[0],
    price_per_unit: '',
    price_total: '',
    taxes: '',
    condition: null,
    lead_time: '',
    lead_time_units: selectOptions.timeOptions[1],
    valid_for_value: '2',
    valid_for_units: selectOptions.timeOptions[1],
    pickup_site: null,
    place: '',
    warranty: '',

    part_number: chatPartNumber ?? '',
    is_alt_pn: false,
    description: '',
    tag_type: null,
    tagged_by: '',
    tag_date: '',
    trace_to: null,
    minimum_order_value: '',
  });

  useEffect(() => () => {
    dispatch(resetUploadState());
    return () => dispatch(resetUploadState());
  }, [dispatch]);

  const onTypeChange = (e) => setDealType(e.target.value);

  const onSelectGooglePlace = (selectedPlace) => {
    setValidationState({ ...validationState, place: PaymentRequestValidators.place(selectedPlace) });
    setFormData({ ...formData, place: selectedPlace, isChanged: true });
  };

  const onBlurAutoSuggest = (selectedPlace) => {
    setValidationState({ ...validationState, place: PaymentRequestValidators.place(selectedPlace) });
    setFormData({ ...formData, place: selectedPlace, isChanged: true });
  };

  const onInputChange = (e) => {
    const { value, name, checked } = e.target;

    setFormData((prevState) => {
      const validation = { ...validationState };
      let calcTotalPrice = formData.price_total;
      let calcPricePerUnit = formData.price_per_unit;

      if (name === 'is_alt_pn') {
        validation.part_number = PaymentRequestValidators.part_number(checked ? '' : chatPartNumber);
        setValidationState(validation);

        return {
          ...prevState,
          part_number: checked ? '' : chatPartNumber,
          [name]: checked,
        };
      }

      if (name === 'price_per_unit') {
        calcTotalPrice = getReqTotalPrice(value, formData.qty, formData.taxes);
        validation.price_total = PaymentRequestValidators.price_total(calcTotalPrice);
      } else if (name === 'taxes') {
        calcTotalPrice = getReqTotalPrice(formData.price_per_unit, formData.qty, value);
        validation.price_total = PaymentRequestValidators.price_total(calcTotalPrice);
      } else if (name === 'price_total') {
        calcPricePerUnit = getReqPricePerUnit(value, formData.qty, formData.taxes);
        validation.price_per_unit = PaymentRequestValidators.price_per_unit(calcPricePerUnit);
      }

      if (name === 'qty') {
        calcTotalPrice = getReqTotalPrice(formData.price_per_unit, value, formData.taxes);
        validation.price_total = PaymentRequestValidators.price_total(calcTotalPrice);
        validation[name] = PaymentRequestValidators[name](value, formData.qty_units.value);
      } else {
        validation[name] = PaymentRequestValidators[name](value);
      }

      setValidationState(validation);
      return {
        ...prevState,
        price_total: calcTotalPrice,
        price_per_unit: calcPricePerUnit,
        [name]: value,
      };
    });
  };

  const onSelectChange = (selectedOption, action) => {
    if (selectedOption !== OTHER_SELECT_PICKUP_KEY && action.name === 'pickup_site') {
      setValidationState({ ...validationState, place: PaymentRequestValidators.place('') });
      setFormData({
        ...formData,
        [action.name]: selectedOption,
        place: '',
        isChanged: true,
      });
    } else if (action.name === 'qty_units') {
      setValidationState({ ...validationState, qty: PaymentRequestValidators.qty(formData.qty, selectedOption.value) });

      setFormData({
        ...formData,
        [action.name]: selectedOption,
        place: '',
        isChanged: true,
      });
    } else {
      setFormData({ ...formData, [action.name]: selectedOption, isChanged: true });
    }
  };

  const onPaymentRequestSend = () => {
    const newValidation = {};
    // eslint-disable-next-line no-return-assign
    Object.keys(PaymentRequestValidators).forEach((v) => newValidation[v] = PaymentRequestValidators[v](formData[v]));
    newValidation.qty = PaymentRequestValidators.qty(formData.qty, formData.qty_units.value);
    if (formData.pickup_site && formData.pickup_site.value === OTHER_SELECT_PICKUP_KEY && !formData.place) {
      newValidation.place = PaymentRequestValidators.place(null);
    }

    if (Object.values(newValidation).some((v) => !!v)) {
      setValidationState(newValidation);
      return;
    }

    let tagType = null;
    if (formData.tag_type && selectOptions.tagOptions.every((t) => t.value !== formData.tag_type.value)) {
      tagType = tagTypes.CUSTOM.id;
    } else if (formData.tag_type) {
      tagType = formData.tag_type.value;
    }
    const data = {
      req: {
        message: {
          payment_request: {
            part_number: formData.part_number || null,
            is_alt_pn: formData.is_alt_pn || false,
            tag_type: tagType,
            tag_name: tagType === tagTypes.CUSTOM.id ? formData.tag_type.value : null,
            tagged_by: formData.tagged_by || null,
            tag_date: formData.tag_date?.toString() || null,
            trace_to: formData.trace_to?.value || null,
            description: formData.description || null,
            minimum_order_value: formData.minimum_order_value || null,

            type: dealType,
            qty: formData.qty,
            qty_units: formData.qty_units.value,
            currency: formData.currency.value,
            price_per_unit: formData.price_per_unit,
            price_total: formData.price_total,
            taxes: formData.taxes || null,
            condition: formData.condition?.value || null,
            lead_time: formData.lead_time || null,
            lead_time_units: (formData.lead_time && formData.lead_time_units?.value) || null,
            valid_for_value: formData.valid_for_value,
            valid_for_units: formData.valid_for_units?.value,
            pickup_site_id: (formData.pickup_site && formData.pickup_site.value !== OTHER_SELECT_PICKUP_KEY && !formData.place)
              ? formData.pickup_site.value
              : null,
            place_id: (formData.place && formData.place.place_id) || null,
            warranty: formData.warranty || null,
          },
          text: formData.comment?.toString() || null,
        },
        attachments: files?.length > 0
          ? [{
            upload_id: files[0].upload_id,
            name: files[0].name,
            comment: null,
            rfq_role: chatAtchRfqRole.TAG,
            tag_type: tagType,
            tag_name: tagType === tagTypes.CUSTOM.id ? formData.tag_type.value : null,
          }]
          : null,
      },
    };

    dispatch(paymentRequestAdd(data, orderId));
    dispatch(hideForm());
  };

  const onFileAdd = (e) => dispatch(uploadTagFile(e.target.files[0]));

  const onFileRemove = () => dispatch(resetUploadState());

  const onTagDateChange = (date) => setFormData((prev) => ({ ...prev, tag_date: date }));

  return (
    <>
      <Title>Add payment request to ePlane</Title>
      <SectionText>
        Select the deal type you wish to request a payment for, fill it&apos;s relevant details and send the request to the buyer:
      </SectionText>
      <FormRadioGroup
        checkedValue={dealType}
        radioList={dealTypesOptions}
        onChange={onTypeChange}
        name="deal_type"
        disabled
        labelColumnWidth={0}
        controlColumnWidth={12}
        inline
      />

      <Form>
        <RequestFormContent
          tagFile={files && files[0]}
          type={dealType}
          data={formData}
          validation={validationState}
          selectOptions={selectOptions}
          onChange={onInputChange}
          onFileAdd={onFileAdd}
          onFileRemove={onFileRemove}
          onTagDateChange={onTagDateChange}
          onSelectGooglePlace={onSelectGooglePlace}
          onBlurAutoSuggest={onBlurAutoSuggest}
          onSelectChange={onSelectChange}
        />
        <p><i>ePlane&apos;s commission will be added upon selection of payment method</i></p>
      </Form>

      <NotificationsGroup state={notification} />
      {(files?.length > 0 || files?.length > 0) && !formData.tag_type && <WarningMessage>Select or type a tag</WarningMessage>}
      <hr />
      <FormButtonContainer>
        <FormActionButtons
          saveHandler={onPaymentRequestSend}
          cancelHandler={() => dispatch(hideForm())}
          confirmButtonText="Send"
          saveProps={{
            disabled: !formData.price_total
            || Object.values(validationState).some((v) => !!v)
            || isUploading
            || (files?.length > 0 && !formData.tag_type),
          }}
          width="100"
          showLoader={isUploading}
        />
      </FormButtonContainer>
    </>
  );
}

PaymentRequestForm.propTypes = {
  orderId: PropTypes.string,
};
