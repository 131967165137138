import axios from 'axios';

import {
  BLOCK_APPROVED_SELLERS_GET,
  BLOCK_APPROVED_SELLERS_GET_SUCCESS,

  CUSTOM_SELLERS_LISTS_GET,
  CUSTOM_SELLERS_LISTS_SUCCESS,

  CUSTOM_SELLERS_LISTS_SAVE,
  CUSTOM_SELLERS_LISTS_SAVE_SUCCESS,

  CUSTOM_SELLERS_LISTS_DELETE,
  CUSTOM_SELLERS_LISTS_DELETE_SUCCESS,

  CUSTOM_SELLERS_LISTS_FAIL,
  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startFetchBlockedApprovedSellers = () => ({ type: BLOCK_APPROVED_SELLERS_GET });
const fetchBlockedApprovedSellersSuccess = (lists) => ({
  type: BLOCK_APPROVED_SELLERS_GET_SUCCESS,
  payload: lists,
});

const startFetchCustomSellersLists = () => ({ type: CUSTOM_SELLERS_LISTS_GET });
const fetchCustomSellersListsSuccess = (list) => ({
  type: CUSTOM_SELLERS_LISTS_SUCCESS,
  payload: list,
});

const startSaveCustomSellersList = () => ({ type: CUSTOM_SELLERS_LISTS_SAVE });
const saveCustomSellersListSuccess = (list) => ({
  type: CUSTOM_SELLERS_LISTS_SAVE_SUCCESS,
  payload: list,
});

const startRemoveCustomSellersList = () => ({ type: CUSTOM_SELLERS_LISTS_DELETE });
const removeSellersListSuccess = (list) => ({
  type: CUSTOM_SELLERS_LISTS_DELETE_SUCCESS,
  payload: list,
});

const customSellersListsFail = () => ({ type: CUSTOM_SELLERS_LISTS_FAIL });

export const fetchCustomSellersLists = (userId, sortParam) => async (dispatch) => {
  dispatch(startFetchCustomSellersLists());
  try {
    const response = await axios(`/rest/V2/eplane/backoffice/seller-lists/custom/${userId}`, { params: sortParam });
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchCustomSellersListsSuccess(response.data && response.data.payload.lists));
    } else {
      requestErrorHandler(response, dispatch, customSellersListsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, customSellersListsFail, e);
  }
};

export const fetchBlockedApprovedSellers = (userId) => async (dispatch) => {
  dispatch(startFetchBlockedApprovedSellers());
  try {
    const response = await axios(`/rest/V2/eplane/backoffice/seller-lists/approved-blocked/${userId}`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchBlockedApprovedSellersSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, customSellersListsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, customSellersListsFail, e);
  }
};

export const saveApprovedBlockedList = (data, isEdit) => async (dispatch) => {
  dispatch(startSaveCustomSellersList());
  let response = null;
  try {
    if (isEdit) {
      response = await axios.post('/rest/V2/eplane/backoffice/seller-lists/update', data);
    } else {
      response = await axios.post('/rest/V2/eplane/backoffice/seller-lists/add', data);
    }
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveCustomSellersListSuccess(response.data && response.data.payload));
      dispatch(fetchBlockedApprovedSellers(data.user_id));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, customSellersListsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, customSellersListsFail, e);
  }
};

export const saveSellersList = (data, isEdit) => async (dispatch) => {
  dispatch(startSaveCustomSellersList());
  let response = null;
  try {
    if (isEdit) {
      response = await axios.post('/rest/V2/eplane/backoffice/seller-lists/update', data);
    } else {
      response = await axios.post('/rest/V2/eplane/backoffice/seller-lists/add', data);
    }
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveCustomSellersListSuccess(response.data && response.data.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, customSellersListsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, customSellersListsFail, e);
  }
};

export const removeSellersList = (listId, userId) => async (dispatch) => {
  dispatch(startRemoveCustomSellersList());
  try {
    const response = await axios.delete(`/rest/V2/eplane/backoffice/seller-lists/${userId}/${listId}`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(removeSellersListSuccess(listId));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, customSellersListsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, customSellersListsFail, e);
  }
};
