import { createSelector } from 'reselect';

import {
  getFiltersStore, getActiveCompanyCustomersStore, getCompanyListStore,
  getLegalEntitiesStore, getUserSettingsStore,
} from './index';
import { getFilters as processFilters, getFilterSelectOptions as processOptions } from '../reducers/userManagement/filterSelector';

export const isFiltersPageFetching = createSelector(
  [
    getFiltersStore,
    getActiveCompanyCustomersStore,
    getCompanyListStore,
    getLegalEntitiesStore,
    getUserSettingsStore,
  ],
  (filtersStore, activeCompanyCustomers, companies, legalEntities, userSettings) => filtersStore.isFetching
  || activeCompanyCustomers.isFetching || companies.isFetching || legalEntities.isFetching || userSettings.isFetching,
);

export const getFilters = createSelector(
  [getFiltersStore],
  (filtersStore) => processFilters(filtersStore),
);

export const getFilterSelectOptions = createSelector(
  [
    getActiveCompanyCustomersStore,
    getCompanyListStore,
    getLegalEntitiesStore,
    getUserSettingsStore,
  ],
  (activeCompanyCustomers, companies, legalEntities) => (
    companies && activeCompanyCustomers && legalEntities
      ? processOptions(companies, activeCompanyCustomers.customers, legalEntities)
      : null
  ),
);
