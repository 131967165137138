import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import FormTitleSection from '../../components/FormComponents/TitleSection';
import Container from '../../components/Container';
import AccountSettingsForm from '../../components/UserManagement/AccountSettingsPage/AccountSettingsForm';
import { getCustomerStore, getAdminCustomerId } from '../../selectors';
import { fetchCustomer, clearCustomerState } from '../../actions/userManagement/customerActions';
import InternalErrorContent from '../../components/InternalErrorContent';
import Loader from '../../components/Loader';

const RowContent = styled(Row)`
  margin-top: 30px;
`;
const Range = styled(Form.Control)`
  margin-top: 2px;
`;

export default function AccountSettingsPage() {
  const dispatch = useDispatch();

  const customerId = useSelector(getAdminCustomerId);
  const { customer, errorCode } = useSelector(getCustomerStore);

  const [taskNotification, setTaskNotification] = useState({
    enabled: +localStorage.getItem('task_notification_enabled'), // eslint-disable-line no-undef
    range: +localStorage.getItem('task_notification_range') || 300000, // eslint-disable-line no-undef
  });

  useEffect(() => {
    dispatch(fetchCustomer(customerId));

    return () => dispatch(clearCustomerState());
  }, [dispatch, customerId]);

  const onNotificationChange = (e) => {
    const { name, checked, value } = e.target;
    if (name === 'task_notification_enabled') {
      localStorage.setItem(name, checked ? 1 : 0); // eslint-disable-line
      setTaskNotification((prevState) => ({ ...prevState, enabled: checked ? 1 : 0 }));
    } else if (name === 'task_notification_range') {
      localStorage.setItem(name, value); // eslint-disable-line
      setTaskNotification((prevState) => ({ ...prevState, range: value }));
    }
  };

  if (errorCode) return <InternalErrorContent />;
  if (!customer) return <Loader />;

  return (
    <Container links={[{ Profile: 'profile' }]}>
      <RowContent>
        <Col xl={{ span: 6, offset: 3 }}>
          <AccountSettingsForm />
        </Col>
      </RowContent>

      <Row>
        <Col xl={{ span: 6, offset: 3 }}>
          <FormTitleSection title="Notifications" showEditButton={false} />
          <Form>
            <br />
            <Form.Group controlId="taskNotificationRange" as={Row}>
              <Col sm={3}>
                <Form.Check
                  checked={taskNotification.enabled === 1}
                  type="switch"
                  name="task_notification_enabled"
                  id="task-notification-switch"
                  label="Tasks notifications"
                  onChange={onNotificationChange}
                />
              </Col>
              <Col sm={6}>
                <Range
                  value={+taskNotification.range}
                  type="range"
                  name="task_notification_range"
                  min={60000}
                  max={3600000}
                  step={60000}
                  onChange={onNotificationChange}
                  disabled={taskNotification.enabled !== 1}
                />
              </Col>
              <Col sm={3}>{`Every ${+taskNotification.range / 60000} minute(s)`}</Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
