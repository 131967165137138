import {
  COMPANY_CUSTOMER_GET_LIST,
  COMPANY_CUSTOMER_GET_LIST_SUCCESS,
  COMPANY_CUSTOMER_GET_LIST_FAIL,

  COMPANY_CUSTOMER_INVITE,
  COMPANY_CUSTOMER_INVITE_SUCCESS,
  COMPANY_CUSTOMER_INVITE_FAIL,

  COMPANY_CUSTOMER_REINVITE,
  COMPANY_CUSTOMER_REINVITE_SUCCESS,
  COMPANY_CUSTOMER_REINVITE_FAIL,

  COMPANY_CUSTOMER_UPDATE,
  COMPANY_CUSTOMER_UPDATE_SUCCESS,
  COMPANY_CUSTOMER_UPDATE_FAIL,

  COMPANY_CUSTOMER_DEACTIVATE,
  COMPANY_CUSTOMER_DEACTIVATE_SUCCESS,
  COMPANY_CUSTOMER_DEACTIVATE_FAIL,

  COMPANY_CUSTOMER_DISMISS,
  COMPANY_CUSTOMER_DISMISS_SUCCESS,
  COMPANY_CUSTOMER_DISMISS_FAIL,

  COMPANY_CUSTOMER_REMOVE,
  COMPANY_CUSTOMER_REMOVE_SUCCESS,
  COMPANY_CUSTOMER_REMOVE_FAIL,

  COMPANY_CUSTOMER_ERROR_REMOVE,
} from '../../constants';

const initialState = {
  customers: null,

  isFetching: false,
  isInviting: false,
  isReinviting: false,
  isUpdating: false,
  isDeactivating: false,
  isRemoving: false,
  isDismissing: false,

  fetchingErrorCode: null,
  inviteErrorCode: null,
  reinviteErrorCode: null,
  updateErrorCode: null,
  deactivatingErrorCode: null,
  removingErrorCode: null,
  dismissingErrorCode: null,
};

const CompanyCustomersReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case COMPANY_CUSTOMER_GET_LIST: return {
      ...state,
      isFetching: true,
      fetchingErrorCode: null,
    };
    case COMPANY_CUSTOMER_GET_LIST_SUCCESS: return {
      ...state,
      isFetching: false,
      customers: payload,
    };
    case COMPANY_CUSTOMER_GET_LIST_FAIL: return {
      ...state,
      isFetching: false,
      fetchingErrorCode: payload,
    };

    case COMPANY_CUSTOMER_INVITE: return {
      ...state,
      isInviting: true,
      inviteErrorCode: null,
    };
    case COMPANY_CUSTOMER_INVITE_SUCCESS: return {
      ...state,
      isInviting: false,
      customers: payload,
    };
    case COMPANY_CUSTOMER_INVITE_FAIL: return {
      ...state,
      isInviting: false,
      inviteErrorCode: payload,
    };

    case COMPANY_CUSTOMER_REINVITE: return {
      ...state,
      isReinviting: true,
      reinviteErrorCode: null,
    };
    case COMPANY_CUSTOMER_REINVITE_SUCCESS: return {
      ...state,
      isReinviting: false,
      customers: payload,
    };
    case COMPANY_CUSTOMER_REINVITE_FAIL: return {
      ...state,
      isReinviting: false,
      reinviteErrorCode: payload,
    };

    case COMPANY_CUSTOMER_UPDATE: return {
      ...state,
      isUpdating: true,
      updateErrorCode: null,
    };
    case COMPANY_CUSTOMER_UPDATE_SUCCESS: return {
      ...state,
      isUpdating: false,
      customers: payload,
    };
    case COMPANY_CUSTOMER_UPDATE_FAIL: return {
      ...state,
      isUpdating: false,
      updateErrorCode: payload,
    };

    case COMPANY_CUSTOMER_DEACTIVATE: return {
      ...state,
      isDeactivating: true,
      deactivatingErrorCode: null,
    };
    case COMPANY_CUSTOMER_DEACTIVATE_SUCCESS: return {
      ...state,
      isDeactivating: false,
      customers: payload,
    };
    case COMPANY_CUSTOMER_DEACTIVATE_FAIL: return {
      ...state,
      isDeactivating: false,
      deactivatingErrorCode: payload,
    };

    case COMPANY_CUSTOMER_DISMISS: return {
      ...state,
      isDismissing: true,
      dismissingErrorCode: null,
    };
    case COMPANY_CUSTOMER_DISMISS_SUCCESS: return {
      ...state,
      isDismissing: false,
      customers: state.customers.filter((v) => v.entity_id !== payload),
    };
    case COMPANY_CUSTOMER_DISMISS_FAIL: return {
      ...state,
      isDismissing: false,
      dismissingErrorCode: payload,
    };

    case COMPANY_CUSTOMER_REMOVE: return {
      ...state,
      isRemoving: true,
      removingErrorCode: null,
    };

    case COMPANY_CUSTOMER_REMOVE_SUCCESS: return {
      ...state,
      isRemoving: false,
      customers: payload,
    };

    case COMPANY_CUSTOMER_REMOVE_FAIL: return {
      ...state,
      isRemoving: false,
      removingErrorCode: payload,
    };

    case COMPANY_CUSTOMER_ERROR_REMOVE:
      return {
        ...state,
        fetchingErrorCode: null,
        inviteErrorCode: null,
        reinviteErrorCode: null,
        updateErrorCode: null,
        deactivatingErrorCode: null,
        removingErrorCode: null,
        dismissingErrorCode: null,
      };

    default: return state;
  }
};

export default CompanyCustomersReducer;
