import {
  API_PERMISSIONS_GET,
  API_PERMISSIONS_GET_SUCCESS,
  API_PERMISSIONS_GET_SAVE,
  API_PERMISSIONS_SAVE_SUCCESS,

  INTEGRATION_GET,
  INTEGRATION_GET_SUCCESS,
  INTEGRATION_SAVE,
  INTEGRATION_SAVE_SUCCESS,

  GENERATE_START,
  GENERATE_SUCCESS,
  REVOKE_START,
  REVOKE_SUCCESS,

  SUPPLIER_CODES_GET,
  SUPPLIER_CODES_GET_SUCCESS,
  SUPPLIER_CODE_ADD,
  SUPPLIER_CODE_ADD_SUCCESS,
  SUPPLIER_CODE_EDIT,
  SUPPLIER_CODE_EDIT_SUCCESS,
  SUPPLIER_CODE_DELETE,
  SUPPLIER_CODE_DELETE_SUCCESS,

  SPEC2K_FAIL,
  CLEAR_SPEC2K,
} from '../../constants';

const initialState = {
  apiPermissions: null,
  generatedRequestUrl: null,
  integrationSettings: null,

  companyCodes: null,
  partnerCodes: null,
  defaultCodes: null,

  isFetching: false,
  isProcessing: false,
  isGenerating: false,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case API_PERMISSIONS_GET:
      return {
        ...state,
        isFetching: true,
      };
    case API_PERMISSIONS_GET_SUCCESS:
      return {
        ...state,
        apiPermissions: payload,
        isFetching: false,
      };
    case API_PERMISSIONS_GET_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case API_PERMISSIONS_SAVE_SUCCESS:
      return {
        ...state,
        customWeights: payload,
        isProcessing: false,
      };

    case INTEGRATION_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case INTEGRATION_SAVE_SUCCESS:
      return {
        ...state,
        integrationSettings: payload,
        isProcessing: false,
      };

    case INTEGRATION_GET:
      return {
        ...state,
        isFetching: true,
      };
    case INTEGRATION_GET_SUCCESS:
      return {
        ...state,
        integrationSettings: payload,
        isFetching: false,
      };

    case GENERATE_START:
      return {
        ...state,
        isGenerating: true,
      };
    case GENERATE_SUCCESS:
      return {
        ...state,
        apiPermissions: payload.api_permissions,
        generatedRequestUrl: payload.generated_request_url,
        isGenerating: false,
      };

    case REVOKE_START:
      return {
        ...state,
        isProcessing: true,
      };
    case REVOKE_SUCCESS:
      return {
        ...state,
        apiPermissions: payload,
        isProcessing: false,
      };

    case SUPPLIER_CODES_GET:
      return {
        ...state,
        isFetching: true,
      };
    case SUPPLIER_CODES_GET_SUCCESS:
      return {
        ...state,
        companyCodes: payload.company_codes,
        partnerCodes: payload.partner_codes,
        defaultCodes: payload.default_codes,
        isFetching: false,
      };
    case SUPPLIER_CODE_ADD:
      return {
        ...state,
        isProcessing: true,
      };
    case SUPPLIER_CODE_ADD_SUCCESS:
      return {
        ...state,
        companyCodes: payload.company_codes,
        partnerCodes: payload.partner_codes,
        defaultCodes: payload.default_codes,
        isProcessing: false,
      };
    case SUPPLIER_CODE_EDIT:
      return {
        ...state,
        isProcessing: true,
      };
    case SUPPLIER_CODE_EDIT_SUCCESS:
      return {
        ...state,
        companyCodes: payload.company_codes,
        partnerCodes: payload.partner_codes,
        defaultCodes: payload.default_codes,
        isProcessing: false,
      };
    case SUPPLIER_CODE_DELETE:
      return {
        ...state,
        isProcessing: true,
      };
    case SUPPLIER_CODE_DELETE_SUCCESS:
      return {
        ...state,
        companyCodes: payload.company_codes,
        partnerCodes: payload.partner_codes,
        defaultCodes: payload.default_codes,
        isProcessing: false,
      };

    case SPEC2K_FAIL:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
        isGenerating: false,
      };
    case CLEAR_SPEC2K:
      return {
        ...initialState,
      };
    default: return state;
  }
};
