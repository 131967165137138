import React from 'react';
import PropTypes from 'prop-types';

import { ERRORS, maxFileSizeIM, maxFileSizeImArc } from '../../../constants';
import ErrorModal from '../../ErrorModal';
import TooBigFileModal from '../../Modals/TooBigFileModal';
import WrongFormatModal from '../../Modals/WrongFileFormatModal';

const ErrorModalsForInventorySection = ({
  errorCode, companyListErrorCode, onHide, onHideCompanies, ...props
}) => {
  const fileSize = ((errorCode === ERRORS.ERR_FILE_IS_TOO_LARGE) ? maxFileSizeIM : maxFileSizeImArc) / 1024 / 1024;
  let text;

  if (errorCode === ERRORS.ERR_FILE_IS_TOO_LARGE || errorCode === ERRORS.ERR_SECOND_FILE_IS_TOO_LARGE) {
    text = (
      <div>
        <p>
          The file you are trying to upload exceeds the&nbsp;
          {fileSize}
          MB attachment limit.
        </p>
        <p>Please try again with a smaller file or contact us for assistance.</p>
      </div>
    );
    return (
      <TooBigFileModal
        show
        onHide={onHide}
        headerText={`File Exceeds ${fileSize}MB`}
        text={text}
        {...props}
      />
    );
  }
  if (errorCode === ERRORS.ERR_FILE_CONTENT_NOT_SUPPORTED || errorCode === ERRORS.ERR_WRONG_FORMAT) {
    text = (
      <div>
        <p>
          The file you are trying to upload is not in a supported format
        </p>
        <p>
          Please try again using one of the accepted formats.
        </p>
      </div>
    );
    return (
      <WrongFormatModal
        show
        onHide={onHide}
        text={text}
        {...props}
      />
    );
  }
  if (errorCode) return <ErrorModal show onHide={onHide} />;
  if (companyListErrorCode) return <ErrorModal show onHide={onHideCompanies} />;
  return null;
};

ErrorModalsForInventorySection.propTypes = {
  errorCode: PropTypes.number,
  companyListErrorCode: PropTypes.number,
  onHide: PropTypes.func,
  onHideCompanies: PropTypes.func,
};

export default ErrorModalsForInventorySection;
