import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import ChatHeader from './ChatHeader';
import ChatWindow from './ChatWindow';
import DocSection from './DocSection';
import LowBidSection from './LowBidSection';

const ChatPage = ({ ...props }) => (
  <>
    <ChatHeader />
    <Row>
      <Col xl={{ span: 4, order: 'last' }} xs={12}>
        <LowBidSection />
        <DocSection />
      </Col>
      <Col xl={{ span: 8, order: 'first' }} xs={12}>
        <ChatWindow {...props} />
      </Col>
    </Row>
  </>
);

ChatPage.propTypes = {
  orderId: PropTypes.string,
  taskId: PropTypes.string,
  showQuoteHandler: PropTypes.func,
};

export default ChatPage;
