import axios from 'axios';

import { getUserSitesStore } from '../../selectors';
import {
  USER_SITES_GET,
  USER_SITES_GET_SUCCESS,
  USER_SITES_GET_FAIL,

  USER_SITE_SAVE,
  USER_SITE_SAVE_SUCCESS,
  USER_SITE_SAVE_FAIL,

  USER_SITE_DELETING_START,
  USER_SITE_DELETE_SUCCESS,
  USER_SITE_DELETE_FAIL,

  USER_SITE_MODAL_CREATE_OPEN,
  USER_SITE_MODAL_EDIT_OPEN,
  USER_SITE_MODAL_CLOSE,

  USER_SITE_MODAL_REMOVE_OPEN,
  USER_SITE_MODAL_REMOVE_CLOSE,

  USER_SITES_ERRORS_REMOVE,
  USER_SITE_SELECT,
} from '../../constants';
import errorHandler from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const starFetchUserSites = () => ({
  type: USER_SITES_GET,
});
const fetchUserSitesSuccess = (sites) => ({
  type: USER_SITES_GET_SUCCESS,
  payload: sites,
});
const fetchUserSitesFail = (errCode) => ({
  type: USER_SITES_GET_FAIL,
  payload: errCode,
});

export const removeErrorsUserSites = () => ({
  type: USER_SITES_ERRORS_REMOVE,
});

const startSavingSite = () => ({
  type: USER_SITE_SAVE,
});
const finishSavingSiteSuccess = (newSite) => ({
  type: USER_SITE_SAVE_SUCCESS,
  payload: newSite,
});
const setSavingError = (errCode) => ({
  type: USER_SITE_SAVE_FAIL,
  payload: errCode,
});

const startDeletingSite = () => ({
  type: USER_SITE_DELETING_START,
});
const removeSiteFromStore = (siteId) => ({
  type: USER_SITE_DELETE_SUCCESS,
  payload: siteId,
});
const setDeletingError = (errCode) => ({
  type: USER_SITE_DELETE_FAIL,
  payload: errCode,
});

export const updateUserSite = (siteId, userId, siteData) => (dispatch) => {
  dispatch(startSavingSite());

  const data = {
    req: siteData,
  };
  axios.post(`/rest/default/V1/eplane/backoffice/users/${userId}/site/${siteId}`, data).then((response) => {
    dispatch(finishSavingSiteSuccess(response.data));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, setSavingError, e);
  });
};

export const createNewUserSite = (userId, siteType, siteData) => (dispatch) => {
  dispatch(startSavingSite());

  const data = {
    req: {
      ...siteData,
      site_type: siteType,
    },
  };
  axios.post(`/rest/default/V1/eplane/backoffice/users/${userId}/site`, data).then((response) => {
    dispatch(finishSavingSiteSuccess(response.data));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, setSavingError, e);
  });
};

export const fetchUserSites = (userId) => (dispatch) => {
  dispatch(starFetchUserSites());

  axios(`/rest/default/V1/eplane/backoffice/users/${userId}/site`).then((response) => {
    dispatch(fetchUserSitesSuccess(response.data));
  }).catch((e) => {
    errorHandler(e.response, dispatch, fetchUserSitesFail, e);
  });
};

export const deleteUserSite = (userId) => (dispatch, getState) => {
  dispatch(startDeletingSite());
  const siteId = getUserSitesStore(getState()).currSiteId;

  if (!siteId) {
    console.error('Couldn\'t get a site id to delete'); // eslint-disable-line no-console
    return;
  }

  axios.delete(`/rest/default/V1/eplane/backoffice/users/${userId}/site/${siteId}`).then(() => {
    dispatch(removeSiteFromStore(siteId));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, setDeletingError, e);
  });
};

// modal actions
export const openSiteModalToCreate = (siteType) => ({
  type: USER_SITE_MODAL_CREATE_OPEN,
  payload: siteType,
});

export const openSiteModalToEdit = (siteId) => ({
  type: USER_SITE_MODAL_EDIT_OPEN,
  payload: siteId,
});

export const closeSiteModal = () => ({
  type: USER_SITE_MODAL_CLOSE,
});

export const openRemoveSiteModel = (id) => ({
  type: USER_SITE_MODAL_REMOVE_OPEN,
  payload: id,
});

export const closeRemoveSiteModal = () => ({
  type: USER_SITE_MODAL_REMOVE_CLOSE,
});

export const siteSelect = (siteId) => ({
  type: USER_SITE_SELECT,
  payload: siteId,
});

export const updateSiteEmail = (siteData, siteId, userId) => (dispatch) => {
  const data = {
    req: siteData,
  };
  axios.post(`/rest/default/V1/eplane/backoffice/users/${userId}/site/${siteId}`, data).then((response) => {
    dispatch(finishSavingSiteSuccess(response.data));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, setSavingError, e);
  });
};
