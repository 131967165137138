import React from 'react';
import styled from 'styled-components';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import dateFormat from '../../../utils/dateFormat';
import PartDetailsBlock from './PartDetailsBlock';
import ChatMessage from './ChatMessage';
import { getChatMessages } from '../../../reducers/orderManagement/chatSelectors';
import {
  getChatStore, getBuiltInQuoteStore, getUserSitesStore, getNocFormStore, getErrorsStore,
} from '../../../selectors';
import { fetchUserSites } from '../../../actions/userCompanyManagement/sitesActions';
import {
  removeBuiltInQuote, sendNoQuote as addBotNoQuote, quoteValidate,
} from '../../../actions/networkOperationsActions/builtInQuoteActions';
import * as formManagementActions from '../../../actions/networkOperationsActions/formManagementActions';
import { getConstNameById, getConditionNamesById } from '../../../utils/constantsHandlers';
import { fetchChatData } from '../../../actions/OrderManagement/chatDataActions';
import { errorsRemove } from '../../../actions/requestErrorHandler';
import { fetchTaskData } from '../../../actions/networkOperationsActions/tasksActions';
import { color, BOT_SERVICE_MSG } from '../../../constants';
import { NOC_FORMS } from '../../../constants/operationCenter';
import BuiltInQuoteModal from '../../NetworkOperationsCenter/BuiltInQuoteModal';
import ErrorModal from '../../ErrorModal';
import LoaderPlane from '../../Loader';
import Loader from '../../AnimatedBackgroundLoader';
import * as serverConstants from '../../../constants/index';

const MIN_HEIGHT = 250;
const OTHER_CONTENT_HEIGHT = 277;

const WindowContainer = styled.div`
  min-height: ${({ heightEl }) => (heightEl)}px;
`;

const ChatRoom = styled.div`
  border-top: 20px solid rgba(0,0,0,0);
  padding: 0 6px;
`;
const AOGIcon = styled.span`
  background-color: rgb(255,98,80);
  color: white;
  padding: 2px 5px;
  font-weight: 400;
`;
const StatusIndicator = styled.div`
  display: inline-block;
  box-sizing: border-box;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: relative;
  top: -1px;
  background-color: ${(props) => props.color};;
`;

class ChatWindow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: getHeight(),
    };
  }

  componentDidMount() {
    const {
      sellerId,
      fetchUserSites: fetchUserSitesData,
    } = this.props;
    global.addEventListener('resize', this.setHeight);
    fetchUserSitesData(sellerId);
  }

  componentDidUpdate(prevProps) {
    const {
      orderIncId,
      taskId,
      builtInQuote,
      fetchChatData: fetchChat,
      fetchTaskData: fetchTask,
    } = this.props;
    const { isSending, isRemoving } = prevProps.builtInQuote;

    if ((!builtInQuote.isSending && isSending) || (!builtInQuote.isRemoving && isRemoving)) {
      if (taskId) fetchTask(taskId);
      else fetchChat(orderIncId);
    }
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.setHeight);
  }

  setHeight = () => {
    this.setState({ height: getHeight() });
  };

  showQuoteModal = (messageId) => {
    const { showForm } = this.props;
    showForm({ type: NOC_FORMS.BUILT_IN_QUOTE, messageId });
  };

  hideQuoteModal = () => {
    const { hideForm } = this.props;
    hideForm();
  };

  removeQuote = (messageId) => {
    const { removeBuiltInQuote: removeBuiltInQuoteMessage, orderId } = this.props;
    removeBuiltInQuoteMessage(orderId, messageId);
  };

  addNoQuote = (messageId) => {
    const { addBotNoQuote: addNoQuoteMessage, orderId } = this.props;
    addNoQuoteMessage(orderId, (messageId === BOT_SERVICE_MSG) ? null : messageId);
  }

  addToIgnoreList = (messageId) => {
    const { showForm } = this.props;
    showForm({ type: NOC_FORMS.IGNORE_TASK, messageId });
  };

  quoteValidate = (state, messageId) => {
    const { quoteValidate: validate, orderId } = this.props;
    validate(orderId, messageId, state);
  }

  removeErrors = () => {
    const {
      errorsRemove: removeErrors,
    } = this.props;
    removeErrors();
  }

  render() {
    const {
      messages: messagesProps,
      partData,
      orderId,
      taskId,
      scrolledMessageId: quoteMessageId,
      builtInQuote,

      blockQuotesDropdown,
      showActionsDropdown,
      errors,

      isSitesFetching,
      blockMessageProcessing,
      currentForm,
    } = this.props;

    const { height } = this.state;

    if (isSitesFetching) return <LoaderPlane />;

    const intoViewMessageId = builtInQuote.modifiedMessageId || quoteMessageId;
    const messageList = messagesProps && messagesProps.map((msg) => (
      <ChatMessage
        key={msg.messageId}
        msg={msg}
        buyerSellerInfo={getBuyerSellerInfo(messagesProps)}
        showQuoteModal={this.showQuoteModal}
        removeBotQuote={this.removeQuote}
        addBotNoQuote={this.addNoQuote}
        addToIgnoreList={this.addToIgnoreList}
        quoteValidate={this.quoteValidate}
        blockQuotesDropdown={blockQuotesDropdown || !showActionsDropdown}
        isOpenedTask={msg.messageId === quoteMessageId}
        isIntoView={msg.messageId === intoViewMessageId}
        isHighlighted={msg.messageId === currentForm?.messageId}
      />
    ));
    const hidePartDetailsBlock = messagesProps && messagesProps[0]
      && (messagesProps[0].pnDetailsAvailable || messagesProps[0].attachedPo
        || messagesProps[0].quoteOriginal === serverConstants.quoteOriginal.QUOTE_TIER1);

    return (
      <WindowContainer heightEl={height}>
        {hidePartDetailsBlock ? null : <PartDetailsBlock {...partData} /> }
        <ChatRoom>
          {messageList}
        </ChatRoom>
        {!taskId && (
          <BuiltInQuoteModal
            key={currentForm?.messageId}
            orderId={orderId}
            messageId={currentForm?.messageId}
            show={!blockMessageProcessing && currentForm !== null}
            cancelHandler={this.hideQuoteModal}
          />
        )}
        <ErrorModal
          error={errors}
          onHide={this.removeErrors}
        />
        { blockQuotesDropdown && <Loader /> }
      </WindowContainer>
    );
  }
}

function getBuyerSellerInfo(messages) {
  const buyer = messages.find((v) => v.isBuyer);
  const seller = messages.find((v) => !v.isBuyer && v.creatorType === serverConstants.creatorTypes.CUSTOMER);
  return {
    buyer: buyer?.senderInfo ?? {},
    seller: seller?.senderInfo ?? {},
  };
}

const msgPT = PropTypes.shape({
  sellerId: PropTypes.string,
  messageId: PropTypes.string.isRequired,
  isBuyer: PropTypes.bool.isRequired,
  text: PropTypes.string,
  senderInfo: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    company_name: PropTypes.string,
  }),
  attachedDocs: PropTypes.arrayOf(PropTypes.object),
  removedDocNames: PropTypes.arrayOf(PropTypes.string),
});

ChatWindow.propTypes = {
  sellerId: PropTypes.string,
  orderIncId: PropTypes.string,
  orderId: PropTypes.string,
  taskId: PropTypes.string,
  scrolledMessageId: PropTypes.string,
  builtInQuote: PropTypes.shape({
    modifiedMessageId: PropTypes.string,
    isSending: PropTypes.bool,
    isRemoving: PropTypes.bool,
  }),
  blockQuotesDropdown: PropTypes.bool,
  showActionsDropdown: PropTypes.bool,
  messages: PropTypes.arrayOf(msgPT),
  partData: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.array),
  }),
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  ),
  isSitesFetching: PropTypes.bool,

  blockMessageProcessing: PropTypes.bool,
  currentForm: PropTypes.shape({
    messageId: PropTypes.string,
  }),

  removeBuiltInQuote: PropTypes.func.isRequired,
  addBotNoQuote: PropTypes.func.isRequired,
  quoteValidate: PropTypes.func.isRequired,
  fetchChatData: PropTypes.func.isRequired,
  fetchUserSites: PropTypes.func.isRequired,
  fetchTaskData: PropTypes.func.isRequired,
  errorsRemove: PropTypes.func.isRequired,
  showQuoteHandler: PropTypes.func,
  showForm: PropTypes.func.isRequired,
  hideForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { errors } = getErrorsStore(state);
  const { currentForm } = getNocFormStore(state);
  const {
    order,
    attachments,
    messages,
    providers,
    platformPay,
    isFetching: isChatFetching,
    errorCode,
  } = getChatStore(state);
  const sellerId = order && order.seller && order.seller.user_id;
  const builtInQuote = getBuiltInQuoteStore(state);
  const blockQuotesDropdown = isChatFetching || builtInQuote.isRemoving || builtInQuote.isSending;
  const { isFetching: isSitesFetching } = getUserSitesStore(state);
  const messagesProp = getChatMessages(order, attachments, messages);
  const lastMessage = messagesProp && messagesProp[messagesProp.length - 1];
  const sellerHasActiveStripe = platformPay || providers?.find((v) => v.code === serverConstants.paymentProviders.STRIPE).connected;
  if (lastMessage && lastMessage.messageId !== BOT_SERVICE_MSG) {
    const serviceMsg = {
      messageId: BOT_SERVICE_MSG,
      creatorType: 'bot',
      isBuyer: false,
      messageType: 'text',
      senderInfo: {},
      extraInfo: { sellerHasActiveStripe },
    };
    messagesProp.push(serviceMsg);
  }

  return {
    currentForm,
    sellerId,
    messages: messagesProp,
    partData: createPartDetailsContent(order, serverConstants),
    orderId: order && order.order_id,
    orderIncId: order && order.increment_id,
    builtInQuote,
    blockQuotesDropdown,
    errorCode: errorCode || builtInQuote.errorCode,
    isSitesFetching,
    errors,
  };
};

const actions = {
  ...formManagementActions,
  removeBuiltInQuote,
  addBotNoQuote,
  quoteValidate,
  fetchChatData,
  fetchUserSites,
  fetchTaskData,
  errorsRemove,
};

export default connect(mapStateToProps, actions)(ChatWindow);

const getHeight = () => {
  const height = global.innerHeight - OTHER_CONTENT_HEIGHT;

  return height < MIN_HEIGHT ? MIN_HEIGHT : height;
};

/**
 *
 * @param {Object} order order data
 * @param {Object} constants server constants
 */
const createPartDetailsContent = (order, constants) => {
  if (!order || !constants) return null;
  const item = (order.items && order.items[0]) || {};
  const orderDealTypeList = order.deal_type_list;
  const pickupSite = order.pickup_site_actual;
  const location = pickupSite && pickupSite.location;

  let pn;
  let content;
  let title;
  let priority = null;

  const dealTypesConsts = constants.dealTypes;
  const itemTypeConst = constants.itemType;

  if (orderDealTypeList.includes(dealTypesConsts.REPAIR.id)) {
    pn = item.product && item.product.p_n;
    title = 'Inquiry for the following repair service:';
    priority = getPriority(order.priority, constants);

    content = [
      [['PN', pn], ['Repair Shop', location]],
    ];
  } else if (order.demand_id) {
    const { demand } = order;
    const qty = +demand.qty;
    const targDate = demand && demand.target_date;
    const conditionId = demand && demand.condition;

    const conditionNames = conditionId && getConditionNamesById(constants.condition, conditionId);
    const targDateText = targDate ? dateFormat(targDate, false) : '';

    title = 'Required details:';
    priority = getPriority(demand && demand.priority, constants);

    content = [
      [['Condition', <Conditions condArray={conditionNames} />], ['Qty', qty || '']],
      [['UM', demand.units], ['Target Date', targDateText]],
    ];
  } else {
    const { part } = item;
    const qty = +item.qty;
    const isSerial = part && part.item_type === itemTypeConst.SERIAL.id;
    const snText = isSerial ? 'Serial#' : 'Batch#';
    const leadTime = item.product && item.product.lead_time;
    const units = part && part.units;
    // eslint-disable-next-line camelcase
    pn = part?.p_n || item?.p_n;

    const conditionName = getConstNameById(part && part.condition, constants, 'condition');
    let bottomContent;
    const qtyText = `${qty}${(isSerial && ` ${units}`) || ''}`;
    priority = getPriority(order.priority, constants);

    if (leadTime) {
      bottomContent = [[snText, part && part.s_n], ['Lead Time', processLeadTime(leadTime)], ['Pickup Site', location]];
    } else {
      bottomContent = [[snText, part && part.s_n], ['Pickup Site', location]];
    }

    title = 'Inquiry for the following part:';
    content = [
      [['PN', pn], ['Condition', conditionName], ['Requested Qty', qtyText]],
      bottomContent,
    ];
  }

  return {
    title,
    priority,
    content,
  };
};

const processLeadTime = (leadTime) => {
  if (!leadTime) return null;
  return `${leadTime / 24} day${(leadTime / 24) > 1 ? 's' : ''}`;
};

const getPriority = (priority, constants) => {
  const priorityText = getConstNameById(priority, constants, 'demandPriority');
  const { demandPriority } = constants;
  let statusColor = '';

  switch (priorityText) {
    case demandPriority.ROUTINE.name:
      statusColor = color.birch;
      break;
    case demandPriority.EXPEDITE.name:
      statusColor = color.goldenTainoi;
      break;
    case demandPriority.CRITICAL.name:
      statusColor = color.darkorange;
      break;
    default:
      statusColor = '';
  }
  let content = null;
  if (priorityText === demandPriority.AOG.name) {
    content = (
      <>
        <b>Priority:</b>
        &nbsp;
        <AOGIcon>AOG</AOGIcon>
      </>
    );
  } else {
    content = (
      <>
        <b>Priority:</b>
        &nbsp;
        <span>
          <StatusIndicator color={statusColor} />
          &nbsp;
          <b>{priorityText}</b>
        </span>
      </>
    );
  }
  return (
    <>
      {content}
    </>
  );
};

const tooltip = (text) => (
  <Tooltip id="tooltip">
    {text}
  </Tooltip>
);

const Conditions = ({ condArray }) => {
  const fullList = condArray && condArray.join(', ');
  let content;
  if (condArray && condArray.length > 5) {
    const shortList = condArray.slice(0, 5).join(', ').concat('...');
    content = (
      <OverlayTrigger placement="top" overlay={tooltip(fullList)} delayShow={500} delayHide={500}>
        <span>{shortList}</span>
      </OverlayTrigger>
    );
  } else {
    content = (
      <span>{fullList}</span>
    );
  }

  return (
    <span>{content}</span>
  );
};

Conditions.propTypes = {
  condArray: PropTypes.arrayOf(PropTypes.string),
};
