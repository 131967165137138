import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TableWithPagination from '../Table/TableWithPagination';
import Table from '../Table/Table';
import header from '../Table/HeaderWithSort';
import TableStyles from '../Table/TableStyles';
import CellWithTitle from '../Table/Cell';

import dateFormat from '../../utils/dateFormat';
import { PUBLIC_URL } from '../../constants';
import statusFormat from './statusFormat';
import { crrResult } from '../../constants/index';

const TableContainer = styled(TableStyles)`
  tbody tr td {
    cursor: pointer;
  }
`;

export default function ClosedReqTable({
  data, goTo, title, noDataContent, options, ...other
}) {
  const onRowClick = useCallback(
    (row) => {
      goTo(`${PUBLIC_URL}/incoming/closed/${row.entityId}`);
    },
    [goTo],
  );

  const columns = useMemo(
    () => [
      {
        Header: () => header('Request No.'),
        id: 'entityId',
        accessor: 'entityId',
        style: {
          width: '100px',
        },
      }, {
        Header: () => header('User id'),
        accessor: 'customerId',
        style: {
          width: '100px',
        },
      }, {
        Header: () => header('User\'s name'),
        accessor: 'userName',
        Cell: CellWithTitle,
      }, {
        Header: () => header('User\'s email'),
        accessor: 'userEmail',
        Cell: CellWithTitle,
      }, {
        Header: () => header('User\'s company'),
        accessor: 'companyName',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Approved / Decline'),
        accessor: 'result',
        Cell: ({ value }) => statusFormat(value, crrResult),
        style: {
          width: '150px',
        },
      }, {
        Header: () => header('Handled by'),
        accessor: 'boUser',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Approval/decline date'),
        accessor: 'updatedAt',
        Cell: ({ value }) => dateFormat(value),
        style: {
          width: '180px',
        },
      },
    ], [],
  );

  if (!data.length && noDataContent) return noDataContent;

  return (
    <div>
      {title}
      <TableContainer>
        {
          options?.pagination
            ? <TableWithPagination columns={columns} data={data} options={options} onRowClick={onRowClick} {...other} />
            : <Table columns={columns} data={data} onRowClick={onRowClick} {...other} />
        }
      </TableContainer>
    </div>
  );
}

const requestPT = PropTypes.shape({
  userId: PropTypes.string,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  userFiles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  companyName: PropTypes.string,
  companyType: PropTypes.string,
  companyEmail: PropTypes.string,
  headquarters: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    countryName: PropTypes.string,
  }),
  phoneNumber: PropTypes.string,
  faxNumber: PropTypes.string,
  emailIsVerified: PropTypes.bool,
  vatTaxNumber: PropTypes.string,
  reqResult: PropTypes.number,
  comment: PropTypes.string,
});

ClosedReqTable.propTypes = {
  data: PropTypes.arrayOf(requestPT),
  goTo: PropTypes.func,
  title: PropTypes.string,
  noDataContent: PropTypes.element,
  options: PropTypes.shape({
    page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sizePerPage: PropTypes.string,
    pagination: PropTypes.bool,
  }),
};
