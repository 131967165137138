import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Fade } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledLinkButton as LinkButton } from '../../StyledComponents';
import { color } from '../../../constants';

const SettingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid ${color.lightGrey};
  margin-bottom: 25px;
`;
const Label = styled.p`
`;
const EditButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  min-width: 250px;
`;
const ControlButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  button {
    text-align: start;
  }
`;
const LimitContainer = styled.div`
  margin-bottom: 10px;
`;

const LimitSetting = ({
  title, labelText, amount, currency, isDisabled, isEditHidden, onEditClick, onRemoveClick,
}) => (
  <>
    <p><strong>{title}</strong></p>
    <SettingContainer>
      <Label>
        {labelText}
      </Label>
      <EditButtonContainer>
        {amount
          ? (
            <>
              <LimitContainer>{`${amount} ${currency}`}</LimitContainer>
              <ControlButtonContainer>
                <Fade in={!isEditHidden}>
                  <LinkButton variant="link" disabled={isDisabled} onClick={onEditClick}>
                    Edit&nbsp;
                    <FontAwesomeIcon icon="pencil-alt" />
                  </LinkButton>
                </Fade>
                {!!onRemoveClick && (
                  <LinkButton variant="link" disabled={isDisabled} onClick={onRemoveClick}>
                    Remove&nbsp;
                    <FontAwesomeIcon icon="trash-alt" />
                  </LinkButton>
                )}
              </ControlButtonContainer>
            </>
          ) : (
            <LinkButton variant="link" disabled={isDisabled} onClick={onEditClick}>Set</LinkButton>
          )}
      </EditButtonContainer>
    </SettingContainer>
  </>
);

LimitSetting.propTypes = {
  title: PropTypes.string,
  labelText: PropTypes.element,
  amount: PropTypes.string,
  currency: PropTypes.string,
  isDisabled: PropTypes.bool,
  isEditHidden: PropTypes.bool,
  onEditClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func,
};

export default LimitSetting;
