import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

import CloneWeightReport from './CloneWeightReport';
import SelectWithButton from './SelectWithButton';
import { fetchCompanyList, errorRemove } from '../../../actions/companyListActions';
import {
  getConstants, getCompanyListStore, getCompanyWeightStore,
} from '../../../selectors';
import { StyledLinkButton as LinkButton } from '../../StyledComponents';
import { dropezoneContent, filledDropezoneContentWithUpload } from '../UploadInventory/dropezoneContent';
import * as weightActions from '../../../actions/companiesManagement/companyWeight';
import { fetchCustomWeights } from '../../../actions/companiesManagement/weightsActions';
import ErrorModalsForDemandSection from '../UploadDemandPage/ErrorModalsForDemandSection';
import ProgressModal from '../../Modals/ProgressModal';
import SuccessModal from '../UploadInventory/SuccessModal';
import ModalLoader from '../../ModalLoader';
import getMultiSelectPlaceholder from '../../../utils/getMultiSelectPlaceholder';
import { ERRORS, API_URL } from '../../../constants';

const ButtonContainer = styled(Row)`
  margin: 15px 15px 15px 0;
`;
const ReportContainer = styled(Row)`
  margin: 0 0 0 15px;
`;
const DropzoneContainer = styled(Row)`
  transition: opacity 2s ease-in-out;
`;

class WeightForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      clone_companies: null,
      showDropzone: false,
    };
  }

  componentDidMount() {
    const { fetchCompanyList: fetchCompanyListData } = this.props;
    fetchCompanyListData();
  }

  onUploadClick = () => {
    const { userId, uploadWeightList: uploadWeightListData } = this.props;
    const { files } = this.state;
    uploadWeightListData(userId, files[0]);
  }

  uploadingSuccess = () => {
    const { userId, resetUploadingProgress, fetchCustomWeights: fetchCustomWeightsData } = this.props;

    resetUploadingProgress();
    fetchCustomWeightsData(userId);
    this.setState({ files: [], showDropzone: false });
  }

  onDocumentDrop = (files) => this.setState({ files: [...files] })

  onDocumentRemove = () => this.setState({ files: [] })

  onCompanyChange = (selectedOption, action) => this.setState({ [action.name]: selectedOption })

  onCloneHandler = () => {
    const { userId, cloneWeightList: cloneWeightListData } = this.props;
    const { clone_companies: cloneCompaniesIds } = this.state;
    const companiesIds = cloneCompaniesIds.map((v) => v.value);
    cloneWeightListData(userId, companiesIds);
    this.setState({ clone_companies: null });
  }

  removeErrors = () => {
    const { removeErrorCode, removeCompanyListError } = this.props;
    removeErrorCode();
    removeCompanyListError();
  }

  onDropRejected = () => {
    const { setUploadingErrorCode } = this.props;
    setUploadingErrorCode(ERRORS.ERR_WRONG_FORMAT);
  }

  onUpload = () => this.setState((prevState) => ({ showDropzone: !prevState.showDropzone }))

  render() {
    const {
      userId,
      companiesOptions,
      errorCode,
      uploadingProgress,
      isUploading,
      cloneResults,
      isCloning,
      disabled,
      blockCloneButton,
    } = this.props;
    const { files, clone_companies: companies, showDropzone } = this.state;
    const showSuccessModal = !isUploading && uploadingProgress === 100;

    let pnContent = null;
    if (files.length > 0) {
      pnContent = filledDropezoneContentWithUpload(files[0], this.onDocumentRemove, this.onUploadClick);
    } else {
      pnContent = dropezoneContent('Drag file here', '(.xls / .xlsx / .csv)', false);
    }
    return (
      <>
        {showDropzone && (
          <DropzoneContainer>
            <Col sm={8}>
              <br />
              <Dropzone
                onDrop={this.onDocumentDrop}
                disabled={false}
                accept=".xls,.xlsx,.csv"
                onDropRejected={this.onDropRejected}
                multiple={false}
                disableClick
              >
                {pnContent}
              </Dropzone>
            </Col>
          </DropzoneContainer>
        )}

        <ButtonContainer>
          <LinkButton variant="link" onClick={this.onUpload} disabled={disabled}>
            <FontAwesomeIcon icon="upload" />
            &nbsp;
            Upload List
          </LinkButton>
          <LinkButton variant="link" href={`${API_URL}/backoffice/users/DownloadSellersWeightsList?user_id=${userId}`} disabled={disabled}>
            <FontAwesomeIcon icon="download" />
            &nbsp;
            Download
          </LinkButton>
        </ButtonContainer>

        <SelectWithButton
          placeholder={getMultiSelectPlaceholder('Companies', companies, companiesOptions)}
          selectValue={companies}
          selectOptions={companiesOptions}
          onSelectChange={this.onCompanyChange}
          onButtonClick={this.onCloneHandler}
          buttonText="Clone"
          disabled={disabled || blockCloneButton}
          name="clone_companies"
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
        />

        <ReportContainer>
          <CloneWeightReport cloneResult={cloneResults} />
        </ReportContainer>

        <ProgressModal show={isUploading} now={uploadingProgress} />
        <SuccessModal show={showSuccessModal} text="We are currently processing your company weight list." onHide={this.uploadingSuccess} />
        <ModalLoader show={isCloning} />
        <ErrorModalsForDemandSection
          errorCode={errorCode}
          onHide={this.removeErrors}
        />
      </>
    );
  }
}

WeightForm.propTypes = {
  userId: PropTypes.string,
  cloneResults: PropTypes.arrayOf(PropTypes.object),
  companiesOptions: PropTypes.arrayOf(PropTypes.object),
  errorCode: PropTypes.number,
  uploadingProgress: PropTypes.number,
  isUploading: PropTypes.bool,
  isCloning: PropTypes.bool,
  disabled: PropTypes.bool,
  blockCloneButton: PropTypes.bool,
  fetchCompanyList: PropTypes.func.isRequired,
  uploadWeightList: PropTypes.func.isRequired,
  cloneWeightList: PropTypes.func.isRequired,
  removeErrorCode: PropTypes.func.isRequired,
  removeCompanyListError: PropTypes.func.isRequired,
  resetUploadingProgress: PropTypes.func.isRequired,
  setUploadingErrorCode: PropTypes.func.isRequired,
  fetchCustomWeights: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const constants = getConstants(state);
  const { companies } = getCompanyListStore(state);
  const companiesOptions = companies && Object.values(companies.filter((v) => v.company_id !== props.userId))
    .map((v) => ({ value: v.company_id, label: v.company_name }));
  const {
    cloneResults, uploadingProgress, isCloning, isUploading, errorCode,
  } = getCompanyWeightStore(state);

  return {
    companiesOptions,
    constants,
    cloneResults,
    uploadingProgress,
    isCloning,
    isUploading,
    errorCode,
  };
};

const actions = {
  ...weightActions,
  fetchCompanyList,
  fetchCustomWeights,
  removeCompanyListError: errorRemove,
};

export default connect(mapStateToProps, actions)(WeightForm);
