import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { mailchimpConditionStatus } from '../../../constants/mailchimp';
import { color } from '../../../constants';

const Text = styled.span`
  color: ${(props) => (props.color || '')};
  font-size: 16px;
`;

export default (cell, title) => {
  let icon = null;
  switch (cell) {
    case mailchimpConditionStatus.ARCHIVED.id:
      icon = <Text color={color.blue}><FontAwesomeIcon icon="save" /></Text>;
      break;
    case mailchimpConditionStatus.IN_PROGRESS.id:
      icon = <Text color={color.inputBorder}><FontAwesomeIcon icon={['far', 'clock']} /></Text>;
      break;
    case mailchimpConditionStatus.SYNCHRONIZED.id:
      icon = <Text color={color.textGreen}><FontAwesomeIcon icon="check" /></Text>;
      break;
    case mailchimpConditionStatus.SYNCHRONIZE_ERROR.id:
      icon = <Text color={color.textRed}><FontAwesomeIcon icon="exclamation-triangle" /></Text>;
      break;
    default: console.error(`Unknown report status id: ${cell};`); // eslint-disable-line no-console
  }
  return <div title={title} style={{ textAlign: 'center' }}>{icon}</div>;
};
