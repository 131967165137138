import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck, faTimes, faPlus, faPencilAlt, faTrashAlt, faUserPlus, faFile, faDownload, faUpload, faChevronLeft,
  faChevronRight, faTh, faChevronDown, faChevronUp, faExclamationTriangle, faShare, faUserCheck, faRetweet,
  faExclamationCircle, faSpinner, faPauseCircle, faHourglassEnd, faCogs, faSync, faSyncAlt,
  faFolderOpen, faGlobe, faCog, faUserTimes, faCaretDown, faEdit, faUser, faUserMinus, faUndoAlt, faSearch,
  faSave, faUnlink, faCheckCircle, faComments, faPaperPlane, faHistory, faListOl, faBan, faSort, faSortUp, faSortDown,
  faFileImport, faClipboardCheck, faCopy, faBell, faFileExport, faHandHoldingUsd, faPaperclip, faStepForward, faWrench, faCircle,
  faEllipsisH, faFilter, faCommentDollar, faUndo, faTimesCircle, faPlusCircle, faCrown, faUserCircle, faArrowCircleUp, faMapMarker,
} from '@fortawesome/free-solid-svg-icons';
import { faBlackTie, faStripe } from '@fortawesome/free-brands-svg-icons';
import { faClock, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

library.add(
  faCircle,

  faTimes, faTimesCircle,
  faPlus, faPlusCircle,
  faPencilAlt,
  faTrashAlt,
  faFile, faFileImport,
  faDownload, faUpload,
  faChevronLeft, faChevronRight, faChevronDown, faChevronUp,
  faTh,
  faExclamationTriangle,
  faExclamationCircle,
  faShare,
  faRetweet,
  faBlackTie,
  faSpinner,
  faPauseCircle,
  faClock,
  faHourglassEnd,
  faFolderOpen,
  faGlobe,
  faCog,
  faUserTimes,
  faEdit,
  faCaretDown,
  faUser, faUserPlus, faUserMinus, faUserCheck, faUserCircle,
  faComments,
  faPaperPlane,
  faUndoAlt,
  faSearch,
  faCogs,
  faSync, faSyncAlt,
  faSave,
  faUnlink,
  faCheckCircle, faCheck,
  faHistory,
  faListOl,
  faBan,
  faSort, faSortUp, faSortDown,
  faClipboardCheck, faCopy,
  faBell,
  faFileExport,
  faHandHoldingUsd,
  faStripe,
  faPaperclip,
  faStepForward,
  faWrench,
  faEllipsisH,
  faFilter,
  faCommentDollar,
  faUndo,
  faCrown,
  faArrowCircleUp,
  faMapMarker,
  faEyeSlash,
);
