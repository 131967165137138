import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import CheckboxStyled from '../FormComponents/CheckboxStyled';

const CheckboxGroupContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
`;

export default function CheckboxGroup({
  checkList,
  checkedValues,
  disabledValues,
  name,
  customstyle,
  ...other
}) {
  const list = checkList.map(({ value, labelText }) => {
    if (customstyle) {
      return (
        <FlexContainer key={value}>
          <CheckboxStyled
            id={`checkbox-${labelText}`}
            checked={checkedValues && checkedValues.includes(value)}
            disabled={disabledValues && disabledValues.includes(value)}
            value={value}
            name={name}
            type="checkbox"
            label={labelText}
            {...other}
          />
        </FlexContainer>
      );
    }
    return (
      <FlexContainer key={value}>
        <Form.Check
          id={`checkbox-${labelText}`}
          checked={checkedValues && checkedValues.includes(value)}
          disabled={disabledValues && disabledValues.includes(value)}
          value={value}
          name={name}
          type="checkbox"
          label={labelText}
          {...other}
        />
      </FlexContainer>
    );
  });
  return (
    <CheckboxGroupContainer>
      {list}
    </CheckboxGroupContainer>
  );
}
const valuePT = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
CheckboxGroup.propTypes = {
  checkList: PropTypes.arrayOf(PropTypes.shape({
    value: valuePT,
    labelText: PropTypes.string,
  })),
  checkedValues: PropTypes.arrayOf(valuePT),
  disabledValues: PropTypes.arrayOf(valuePT),
  name: PropTypes.string.isRequired,
  customstyle: PropTypes.bool,
};
