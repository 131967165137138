import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import Loader from '../../components/Loader';
import ModalLoader from '../../components/ModalLoader';
import SearchBar from '../../components/SearchBar';
import Container from '../../components/Container';
import CompaniesTable from '../../components/CompaniesManagement/CompaniesPage/CompaniesTable';
import PaginationTable from '../../components/OrderManagement/SearchOrders/PaginationTable';
import { pushRedirect } from '../../actions/redirectActions';
import { fetchCompanies, searchForCompanies } from '../../actions/companiesManagement/companiesActions';
import { getCompaniesStore } from '../../selectors';
import ErrorModal from '../../components/ErrorModal';

const CompaniesPage = () => {
  const sortNameRef = useRef('updated_at');
  const sortOrderRef = useRef('desc');

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    currItems,
    isFetching,
    totalRecords,
    page,
    lastPage,
    isSearching,
    isSearchingMode,
    isError,

    removeCompaniesSearchError,
  } = useSelector(getCompaniesStore);

  useEffect(() => {
    const nextQuery = queryString.parse(location.search);

    if (Object.keys(nextQuery).length) {
      dispatch(searchForCompanies(nextQuery));
    } else {
      dispatch(fetchCompanies());
    }
  }, [dispatch, location]);

  const onSearchHandler = (searchString) => {
    const query = queryString.parse(location.search);

    if (searchString !== query.q) {
      query.q = searchString;
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(query),
      });
    }
  };

  const onSort = useCallback((sortName, sortOrder) => {
    if (sortNameRef.current !== sortName && sortOrder === 'desc') {
      // revert default desc soft order to asc due to bug EP-5771
      // feature out-from-the-box enabled only in react-bootstrap-table2
      sortOrderRef.current = 'asc';
    } else {
      sortOrderRef.current = sortOrder;
    }
    sortNameRef.current = sortName;

    const query = queryString.parse(location.search);
    query.c = 100;
    query.p = 1;
    query.o = sortNameRef.current;
    query.s = sortOrderRef.current;

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(query),
    });
  }, [history, location.pathname, location.search]);

  const pageChanged = (pg) => {
    const query = queryString.parse(location.search);
    query.p = pg;

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(query),
    });
  };

  const searchValue = location && queryString.parse(location.search).q;
  let tableTitle = null;

  const loader = isFetching ? <Loader /> : null;

  if (isSearchingMode) {
    tableTitle = (totalRecords === 1)
      ? 'There is 1 result that match your search:'
      : `There are ${totalRecords} results that match your search:`;
  }

  return (
    <Container links={[{ Companies: null }]}>
      {
        loader || (
          <div>
            <SearchBar
              onSearchHandler={onSearchHandler}
              placeholder="User ID / Company name / Company type / Contact name / Email / Supplier code"
              defaultInputValue={searchValue || ''}
              searchOnTheFly={false}
            />
            <CompaniesTable
              data={currItems}
              title={tableTitle}
              goTo={(...params) => dispatch(pushRedirect(...params))}
              onSort={onSort}
              sName={sortNameRef.current}
              sOrder={sortOrderRef.current}
            />
            {currItems && currItems.length < totalRecords
              && <PaginationTable onChange={pageChanged} currentPage={page} totalPages={lastPage} />}
            <ErrorModal show={isError} onHide={removeCompaniesSearchError} />
            <ModalLoader show={isSearching} />
          </div>
        )
      }
    </Container>
  );
};

export default CompaniesPage;
