import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import dateFormat from '../../utils/dateFormat';
import { color } from '../../constants';

const Date = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: ${color.grey};
  letter-spacing: 0.325px;
  margin-top: 6px;
  text-align: ${(props) => props.textAlign};
`;
const CommentIcon = styled(FontAwesomeIcon)`
  font-size: 32px;
  margin-right: 10px;
`;
const WidgetComment = styled.div`
  margin: 10px;
  display: flex;
  word-wrap: break-word;
  ${(props) => (props.alignRight && 'justify-content: flex-end;')}
`;
const TextContainer = styled.div`
  background-color: ${(props) => (props.alignRight ? color.stripedGrey : color.lightBirch)};
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
  ${(props) => (props.alignRight && 'margin-right: 15px;')};
`;
const Email = styled.div`
   font-size: 12px;
   font-weight: normal;
   color: ${color.grey};
`;

const startComment = 'commented about company: ';
const startCommentCustomer = 'commented about customer: ';

const Comments = React.memo(({ comments, adminCustomerId }) => (
  comments && comments.map((comment, i) => (
    <WidgetComment key={i.toString()} alignRight={comment.admin_customer_id === adminCustomerId}>
      {comment.admin_customer_id !== adminCustomerId && <CommentIcon icon="user" color={color.oracle} />}
      <TextContainer alignRight={comment.admin_customer_id === adminCustomerId}>
        <b>{comment.admin_customer_full_name}</b>
        <Email>{comment.admin_customer_email}</Email>
        <div className="widget-comment-text">
          <p>
            {
               Array.isArray(comment.display_text)
                 ? comment.display_text.map((v) => {
                   if (v.startsWith(startComment)) {
                     return v.split(startComment)[1];
                   }
                   if (v.startsWith(startCommentCustomer)) {
                     return v.split(startCommentCustomer)[1];
                   }
                   return v;
                 }).join('\n')
                 : comment.display_text
            }
          </p>
        </div>
        <Date textAlign="right">
          {dateFormat(comment.created_at)}
        </Date>
      </TextContainer>
      {comment.admin_customer_id === adminCustomerId && <CommentIcon icon="user" color={color.blue} />}
    </WidgetComment>
  ))
));

Comments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  adminCustomerId: PropTypes.string,
};

export default Comments;
