import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StatsCol = styled.div`
  width: 33.33%;
`;
const StatsCell = styled.div`
  text-align: center;
`;
const StatsContainer = styled.div`
  margin-top: 20px;
  height: 85px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  margin-bottom: 20px;
`;
const Divider = styled.div`
  height: 50px;
  width: 2px;
  position: relative;
  top: 7px;
  background: #408494;
`;

const InventoryStatistics = ({ deleted, published, unpublished }) => (
  <StatsContainer>
    <StatsCol>
      <StatsCell>
        <h3>Unpublished</h3>
        <p>{unpublished}</p>
      </StatsCell>
    </StatsCol>
    <Divider />
    <StatsCol>
      <StatsCell>
        <h3>Published</h3>
        <p>{published}</p>
      </StatsCell>
    </StatsCol>
    <Divider />
    <StatsCol>
      <StatsCell>
        <h3>Deleted</h3>
        <p>{deleted}</p>
      </StatsCell>
    </StatsCol>
  </StatsContainer>
);

InventoryStatistics.propTypes = {
  published: PropTypes.string.isRequired,
  unpublished: PropTypes.string.isRequired,
  deleted: PropTypes.string.isRequired,
};

export default InventoryStatistics;
