import {
  WEIGHT_GROUP_LIST_GET,
  WEIGHT_GROUP_LIST_GET_SUCCESS,

  WEIGHT_GROUPS_GET,
  WEIGHT_GROUPS_GET_SUCCESS,

  WEIGHT_GROUP_REMOVE,
  WEIGHT_GROUP_REMOVE_SUCCESS,

  WEIGHT_GROUP_LIST_SAVE,
  WEIGHT_GROUP_LIST_SAVE_SUCCESS,
  WEIGHT_GROUP_LIST_UPDATE_SUCCESS,

  WEIGHT_GROUPS_ERROR_REMOVE,
} from '../../constants';

const initialState = {
  selectedItem: null,
  weightGroups: [],
  changedListId: null,

  isFetching: false,
  isProcessing: false,
  isListFetching: false,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case WEIGHT_GROUPS_GET:
      return {
        ...state,
        weightGroups: null,
        isFetching: true,
      };
    case WEIGHT_GROUPS_GET_SUCCESS:
      return {
        ...state,
        weightGroups: payload,
        isFetching: false,
      };

    case WEIGHT_GROUP_LIST_GET:
      return {
        ...state,
        selectedItem: null,
        isListFetching: true,
      };
    case WEIGHT_GROUP_LIST_GET_SUCCESS:
      return {
        ...state,
        selectedItem: payload,
        isListFetching: false,
      };

    case WEIGHT_GROUP_LIST_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case WEIGHT_GROUP_LIST_SAVE_SUCCESS:
      return {
        ...state,
        selectedItem: null,
        weightGroups: payload ? [...state.weightGroups, payload] : state.weightGroups,
        changedListId: payload.id,
        isProcessing: false,
      };
    case WEIGHT_GROUP_LIST_UPDATE_SUCCESS: {
      const index = payload.list && state.weightGroups.findIndex((v) => v.id === payload.list.id);
      return {
        ...state,
        selectedItem: payload.sellers || null,
        weightGroups: state.weightGroups.map((v, i) => (index === i ? payload.list : v)),
        changedListId: payload.id,
        isProcessing: false,
      };
    }

    case WEIGHT_GROUP_REMOVE:
      return {
        ...state,
        isProcessing: true,
      };
    case WEIGHT_GROUP_REMOVE_SUCCESS:
      return {
        ...state,
        weightGroups: state.weightGroups.filter((v) => v.id !== payload.id),
        isProcessing: false,
      };

    case WEIGHT_GROUPS_ERROR_REMOVE:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
        isListFetching: false,
      };
    default: return state;
  }
};
