import {
  USER_NOTIFICATIONS_GET,
  USER_NOTIFICATIONS_SUCCESS,
  USER_NOTIFICATIONS_FAIL,
  USER_NOTIFICATIONS_SAVE,
  USER_NOTIFICATIONS_SAVE_SUCCESS,
  USER_NOTIFICATIONS_SAVE_FAIL,
  USER_NOTIFICATIONS_ERROR_REMOVE,
} from '../../constants';

const initialState = {
  notificationsData: null,
  isFetching: false,
  isSaving: false,
  errorCode: null,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case USER_NOTIFICATIONS_GET:
      return {
        ...state,
        isFetching: true,
      };
    case USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        notificationsData: payload,
      };
    case USER_NOTIFICATIONS_FAIL:
      return {
        ...state,
        isFetching: false,
        errorCode: payload,
      };
    case USER_NOTIFICATIONS_SAVE:
      return {
        ...state,
        isSaving: true,
      };
    case USER_NOTIFICATIONS_SAVE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        notificationsData: payload,
      };
    case USER_NOTIFICATIONS_SAVE_FAIL:
      return {
        ...state,
        isSaving: false,
        errorCode: payload,
      };
    case USER_NOTIFICATIONS_ERROR_REMOVE:
      return {
        ...state,
        errorCode: null,
      };

    default: return state;
  }
};
