/**
 * Functions takes raw countries array that is received from server and returns countriesList and regionList for select form control
 * @param {Array} data - array of countries data, that is received from server
 * @returns {{ countriesList: [], regionList: {} }}
 */
const getCountryRegionLists = (rawData) => {
  if (!rawData) return { countriesList: [], regionList: {} };

  const regionList = {};
  const countriesList = rawData.map((el) => {
    if (el.available_regions) regionList[el.id] = (getRegionList(el.available_regions));
    return [el.id, el.full_name_locale];
  });
  return { countriesList, regionList };
};

const getRegionList = (regionsRaw) => regionsRaw.map((r) => ([r.id, r.name]));

export default getCountryRegionLists;
