import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import NavA from '../NavA';
import NavNormal from '../Nav';
import NestedNavList from '../NestedNavList';
import { color } from '../../constants';

const Nav = styled(NavNormal)`
  .activeNavLink {
    background-color: ${color.blue};
    color: ${color.white};
    text-decoration: none;
    font-weight: bold;
  }
  .activeTopNavLink {
    p {
      font-weight: bold;
    }
    background-color: ${color.whiteSmoke};
    color: ${color.darkGrey};
    text-decoration: none;
  }
`;

export default function SideNavigation({ routes, urlPrefix, currentPath }) {
  return (
    // the replace prop gives an ability to not store history of transmissions inside the section
    <Nav>
      {
      Object.keys(routes).map((el) => {
        if (routes[el].tabHeader) {
          const isActive = Object.keys(routes[el].mapRoutes).some((e) => currentPath === urlPrefix + e);
          return <NestedNavList key={el + 1} listKey={el} linksObj={routes[el]} urlPrefix={urlPrefix} isActive={isActive} />;
        }
        return <NavA replace key={el} to={urlPrefix + el}>{routes[el]}</NavA>;
      })
      }
    </Nav>
  );
}

SideNavigation.propTypes = {
  routes: PropTypes.shape({
  }),
  urlPrefix: PropTypes.string,
  currentPath: PropTypes.string,
};
