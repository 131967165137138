import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { color } from '../../constants';

const A = styled(NavLink)`
  color: ${color.blue};
  text-decoration: none;
  padding: 0.5em;
  display: block;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: ${color.darkBlue};
    text-decoration: underline;
    background-color: ${color.lightGrey};
  }

  &:active, &:focus {
    color: ${color.blue};
    text-decoration: none;
  }
`;

const NavA = (props) => {
  const { children } = props;
  return (
    <A activeClassName="activeNavLink" {...props}>{children}</A>
  );
};

NavA.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default NavA;
