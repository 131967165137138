import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, FormGroup, Dropdown, Col, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchCompanyList } from '../../../actions/companyListActions';
import {
  clearSpec2kStore, fetchSupplierCodes, addSupplierCode, editSupplierCode, deleteSupplierCode,
} from '../../../actions/companiesManagement/spec2k';
import { getSpec2kStore, getCompanyListStore, getErrorsStore } from '../../../selectors';
import { TabContainer, ButtonContainer } from '../../StyledComponents';
import FormTitleSection, { FormSubTitle } from '../../FormComponents/TitleSection/index';
import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import RemoveModal from '../../Modals/RemoveModal';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';
import CodesManagementTable from './CodesManagementTable';
import SupplierCodeModal from './SupplierCodeModal';
import { API_URL } from '../../../constants';
import { pushRedirect } from '../../../actions/redirectActions';
import { defautCodeValidator } from './validators';

const exportTypes = {
  EXPORT_TYPE_CSV: 'csv',
  EXPORT_TYPE_EXCEL: 'xlsx',
};
const ControlsContainer = styled(ButtonContainer)`
  display: flex;
  &&&>div:not(:first-child) {
    margin-left: ${(props) => (props.space || '5px')}
  }
`;
const Labeled = styled.div`
  padding-top: calc(.375rem + 1px);
  padding-bottom: .5rem;
`;

const CodesManagementPage = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();

  const {
    companyCodes, partnerCodes, defaultCodes,
    isFetching, isProcessing,
  } = useSelector(getSpec2kStore);
  const { companies } = useSelector(getCompanyListStore);
  const { errors } = useSelector(getErrorsStore);

  const [defaultCodeSetting, setDefaultCodeSetting] = useState({
    id: null,
    isActive: false,
    code: '',
  });
  const [codeItem, setCodeItem] = useState(null);
  const [modals, setModals] = useState({
    removeConfirmationModal: false,
    supplierCodeModal: false,
    removeDefaultCodeConfirmation: false,
  });

  const companiesOptions = useMemo(
    () => partnerCodes && companies?.filter((v) => v.company_id !== userId)
      .map((v) => ({ value: v.company_id, label: v.company_name })),
    [companies, partnerCodes, userId],
  );

  useEffect(() => {
    dispatch(fetchCompanyList());
    dispatch(fetchSupplierCodes(userId));
    return () => dispatch(clearSpec2kStore());
  }, [dispatch, userId]);

  useEffect(() => {
    if (defaultCodes) {
      const item = defaultCodes.find((v) => v.partner_id === userId) ?? { code: '' };
      setDefaultCodeSetting({
        ...item,
        isActive: !!item?.code,
      });
    }
  }, [defaultCodes, userId]);

  const onEditClickHandler = (id) => {
    const item = partnerCodes.find((v) => v.id === id);
    setCodeItem({ ...item, company_name: ({ value: item.partner_id, label: item.company_name }) });
    setModals({ ...modals, supplierCodeModal: true });
  };

  const onRemoveClickHandler = (id) => setModals({ ...modals, removeConfirmationModal: id });

  const onCompanySelected = (selectedOption, action) => setCodeItem({ ...codeItem, [action.name]: selectedOption });

  const onCodeChange = (e) => setCodeItem({ ...codeItem, [e.target.name]: e.target.value });

  const onSaveHandler = () => {
    const formedData = {
      user_id: userId,
      code: codeItem.code,
      partner_id: codeItem.company_name.value,
    };
    if (codeItem.id) {
      dispatch(editSupplierCode(codeItem.id, formedData));
    } else {
      dispatch(addSupplierCode(formedData));
    }
    setModals({ ...modals, supplierCodeModal: false });
    setCodeItem(null);
  };

  const onSaveDefaultCodeSetting = () => {
    const formedData = {
      user_id: null,
      code: defaultCodeSetting.code,
      partner_id: userId,
    };
    if (defaultCodeSetting.id) {
      dispatch(editSupplierCode(defaultCodeSetting.id, formedData));
    } else {
      dispatch(addSupplierCode(formedData));
    }
    setModals({ ...modals, removeDefaultCodeConfirmation: false });
  };

  const onDefaultCodeCheckChange = (e) => {
    if (!e.target.checked && defaultCodeSetting?.code !== '') setModals({ ...modals, removeDefaultCodeConfirmation: true });
    else setDefaultCodeSetting({ ...defaultCodeSetting, isActive: e.target.checked });
  };

  const onDefaultCodeRemove = () => {
    dispatch(deleteSupplierCode(userId, defaultCodeSetting.id));
    setModals({ ...modals, removeDefaultCodeConfirmation: false });
  };

  const onHideSupplierCodeModal = () => {
    setModals({ ...modals, supplierCodeModal: false });
    setCodeItem(null);
  };

  const onRemoveConfirm = () => {
    dispatch(deleteSupplierCode(userId, modals.removeConfirmationModal));
    setModals({ ...modals, removeConfirmationModal: false });
  };

  const onGo = (to) => {
    dispatch(pushRedirect(to));
  };

  if ((!companyCodes || !companies || isFetching) && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <>
      <FormTitleSection title="CIC & SPL codes" showEditButton={false} />
      <TabContainer>
        <FormGroup as={Row}>
          <Col sm={5}>
            <Labeled>
              <CheckboxStyled
                id="checkbox-system-wide-code"
                label="This company has system-wide default code"
                checked={defaultCodeSetting.isActive}
                name="is_active"
                onChange={onDefaultCodeCheckChange}
              />
            </Labeled>
          </Col>
          <Col sm={2}>
            <BaseFormGroup
              value={defaultCodeSetting.code}
              name="code"
              disabled={!defaultCodeSetting.isActive}
              maxLength="32"
              onChange={(e) => setDefaultCodeSetting({ ...defaultCodeSetting, code: e.target.value })}
              {...defautCodeValidator(defaultCodeSetting)}
            />
          </Col>
          <Col sm={3}>
            <Button
              variant="success"
              onClick={onSaveDefaultCodeSetting}
              title="Save"
              disabled={!defaultCodeSetting.isActive}
            >
              <FontAwesomeIcon icon="save" />
            </Button>
          </Col>
        </FormGroup>
        <FormSubTitle title="Partners' codes" showEditButton={false} />
        <Labeled>
          Codes of partner used by this company:
        </Labeled>
        <FormGroup>
          <ControlsContainer>
            <Button
              variant="success"
              size="sm"
              onClick={() => setModals({ ...modals, supplierCodeModal: true })}
              title="Add new code"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              Add
            </Button>

            {renderExportButton(userId)}
          </ControlsContainer>
        </FormGroup>
        <CodesManagementTable
          data={partnerCodes}
          companyColumnTitle="Partner company name"
          showControls
          onEditCodeClick={onEditClickHandler}
          onRemoveCodeClick={onRemoveClickHandler}
          onGo={onGo}
        />

        <FormSubTitle title="Company codes" showEditButton={false} />
        <Labeled>
          This company is mapped as partner by another companies with following codes:
        </Labeled>
        <CodesManagementTable data={companyCodes} usePartnerId={false} onGo={onGo} />

        <FormSubTitle title="System-wide default codes" showEditButton={false} />
        <Labeled>
          The partners can be identified by this company using their default codes:
        </Labeled>
        <CodesManagementTable data={defaultCodes} companyColumnTitle="Partner company name" onGo={onGo} />
      </TabContainer>

      <SupplierCodeModal
        show={modals.supplierCodeModal}
        data={codeItem}
        companiesOptions={companiesOptions}
        onCompanyChange={onCompanySelected}
        onCodeChange={onCodeChange}
        onConfirm={onSaveHandler}
        onHide={onHideSupplierCodeModal}
      />
      <RemoveModal
        show={!!modals.removeConfirmationModal}
        onRemove={onRemoveConfirm}
        onHide={() => setModals({ ...modals, removeConfirmationModal: false })}
        text="Are you sure you wish to delete this code?"
      />
      <ConfirmationModal
        show={modals.removeDefaultCodeConfirmation}
        confirm={onDefaultCodeRemove}
        onHide={() => setModals({ ...modals, removeDefaultCodeConfirmation: false })}
      >
        Are you sure you want to remove system-wide default code for this company?
      </ConfirmationModal>
      <ModalLoader show={isProcessing} />
    </>
  );
};

const renderExportButton = (userId) => (
  <Dropdown id="export_dropdown">
    <Dropdown.Toggle variant="primary" size="sm">
      <FontAwesomeIcon icon="file-export" />
      &nbsp;
      Export
    </Dropdown.Toggle>
    <Dropdown.Menu className="super-colors">
      <Dropdown.Item
        eventKey={exportTypes.EXPORT_TYPE_CSV}
        href={`${API_URL}/backoffice/users/downloadsuppliercodes?user_id=${userId}&export_type=${exportTypes.EXPORT_TYPE_CSV}`}
      >
        CSV
      </Dropdown.Item>
      <Dropdown.Item
        eventKey={exportTypes.EXPORT_TYPE_EXCEL}
        href={`${API_URL}/backoffice/users/downloadsuppliercodes?user_id=${userId}&export_type=${exportTypes.EXPORT_TYPE_EXCEL}`}
      >
        EXCEL
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

export default CodesManagementPage;
