import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Form, Row, Col, Button,
} from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchUserSettings } from '../../../actions/companiesManagement/userSettingsActions';
import { fetchTimeZones } from '../../../actions/userManagement/filterActions';
import { fetchNotifications, saveNotifications } from '../../../actions/userManagement/notifications';
import {
  getNotificationsStore, getFiltersStore, getUserStore, getUserSettingsStore, getErrorsStore,
} from '../../../selectors';
import { getProActiveState } from '../../../selectors/pro';

import ToggleForm from './ToggleForm';
import { StyledLinkButton as LinkButtonNormal, TabContainer } from '../../StyledComponents';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import Loader from '../../Loader';
import ModalLoader from '../../ModalLoader';
import NotificationModal from './NotificationModal';
import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import { subscriptionState, userType } from '../../../constants/index';

const Label = styled.div`
  font-size: 16px;
`;
const ScheduleButton = styled(LinkButtonNormal)`
  padding-top: 0;
`;
const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
const getEplaneFormData = (notificationSettings) => [{
  name: 'mailchimp_product',
  type: 'Product Announcements',
  description: 'New features, tips, statistics, and version updates.',
  enabled: notificationSettings.mailchimp_product === subscriptionState.SUBSCRIBED,
  locked: notificationSettings.mailchimp_product === subscriptionState.MAILCHIMP_UNSUBSCRIBE,
}, {
  name: 'mailchimp_news_event',
  type: 'ePlane News & Events',
  description: 'Our latest blog posts, news, and events.',
  enabled: notificationSettings.mailchimp_news_event === subscriptionState.SUBSCRIBED,
  locked: notificationSettings.mailchimp_news_event === subscriptionState.MAILCHIMP_UNSUBSCRIBE,
}];

const getRFQFormData = (notificationSettings) => [{
  name: 'account_rfq_reminder_subscription',
  type: 'RFQ Reminders',
  description: 'Email report about unanswered RFQs.',
  enabled: notificationSettings.account_rfq_reminder_subscription === subscriptionState.SUBSCRIBED,
  locked: false,
}];

const getAccountFormData = (notificationSettings, isCompany, quoteActivityDisabled) => {
  const quoteActivity = !quoteActivityDisabled
    ? [{
      name: 'quote_activity_subscription',
      type: 'Quote Activity',
      description: 'Updates on your quotes activities as a seller vs. other quotes.',
      enabled: notificationSettings.quote_activity_subscription === subscriptionState.SUBSCRIBED,
      locked: false,
    }]
    : [];

  const companyNotifications = isCompany ? [
    ...quoteActivity,
    {
      name: 'account_demand_upload',
      type: 'Demand Upload Report',
      description: 'A timely report on your latest demand uploads.',
      enabled: notificationSettings.account_demand_upload === subscriptionState.SUBSCRIBED,
      locked: false,
    }, {
      name: 'account_inventory_repair',
      type: 'Repair Upload Reports',
      description: 'A timely report on your latest repair upload.',
      enabled: notificationSettings.account_inventory_repair === subscriptionState.SUBSCRIBED,
      locked: false,
    }] : [];

  return [{
    name: 'account_general',
    type: 'General',
    description: 'Account updates, user invitations, and automatic emails.',
    enabled: notificationSettings.account_general === subscriptionState.SUBSCRIBED,
    locked: false,
  }, /* {
    name: 'account_purchase',
    type: 'Purchase Report',
    description: 'Weekly reports on your company\'s purchases & activities on ePlane.',
    enabled: notificationSettings.account_purchase === 1,
  }, {
    name: 'account_sales',
    type: 'Sales Report',
    description: 'Monthly reports on your company\'s sales & activities on ePlane.',
    enabled: notificationSettings.account_sales === 1,
  }, */
  ...companyNotifications,
  ];
};

const NotificationsPage = () => {
  const dispatch = useDispatch();
  const { customerId, userId } = useParams();

  const [notificationModal, setNotificationModal] = useState(false);
  const {
    notificationsData: defaultNotificationsData, isFetching, isSaving,
  } = useSelector(getNotificationsStore);
  const { timeZones, isTimeZonesFetching } = useSelector(getFiltersStore);
  const { user } = useSelector(getUserStore);
  const { data: userData, isFetching: isUserSettingsFetching } = useSelector(getUserSettingsStore);
  const isProActive = useSelector(getProActiveState);
  const { errors } = useSelector(getErrorsStore);

  const isCompany = user.user_type === userType.COMPANY.id;
  const isPartsWatchListDisabled = userData && !(userData.pro_parts_watchlist || isProActive);
  const quoteActivityDisabled = userData && !(userData.low_bid_indicator_enabled || isProActive);

  const [notificationsData, setNotificationData] = useState({});
  const [dataChanged, setDataChange] = useState(false);

  const partWatchlistSchedule = {
    pwl_notification_time: defaultNotificationsData?.pwl_notification_time,
    pwl_notification_time_zone: defaultNotificationsData?.pwl_notification_time_zone,
  };

  useEffect(() => {
    dispatch(fetchNotifications(customerId));
    dispatch(fetchUserSettings(userId));
    dispatch(fetchTimeZones());
  }, [customerId, userId, dispatch]);

  useEffect(() => {
    if (isSaving) setDataChange(false);
  }, [isSaving]);

  const saveNotificationsHandler = () => {
    const changes = {};
    Object.keys(notificationsData).forEach((item) => {
      if (notificationsData[item] !== defaultNotificationsData[item]) {
        changes[item] = notificationsData[item];
      }
    });
    if (Object.keys(changes).length) {
      dispatch(saveNotifications(customerId, changes));
    }
  };

  const savePartsWatchlistDigestHandler = (data) => {
    dispatch(saveNotifications(customerId, data));
    setNotificationModal(false);
  };

  const onPartsWatchlistDigestChange = () => {
    const data = { pwl_notifications: !defaultNotificationsData.pwl_notifications };
    dispatch(saveNotifications(customerId, data));
  };

  const onChange = (e) => {
    const { name, checked } = e.target;
    let notifications = null;

    if (dataChanged) {
      notifications = { ...notificationsData, [name]: checked ? 1 : 0 };
      const checkDifference = Object.keys(defaultNotificationsData).some((v) => defaultNotificationsData[v] !== notifications[v]);
      setNotificationData(notifications);
      setDataChange(checkDifference);
    } else {
      notifications = { ...defaultNotificationsData, [name]: checked ? 1 : 0 };
      setNotificationData(notifications);
      setDataChange(true);
    }
  };

  if ((!defaultNotificationsData || isFetching || isTimeZonesFetching) && (errors === null || errors.length === 0)) return <Loader />;

  const eplaneFormData = (dataChanged || isSaving)
    ? getEplaneFormData(notificationsData)
    : getEplaneFormData(defaultNotificationsData);

  const accountForm = (dataChanged || isSaving)
    ? getAccountFormData(notificationsData, isCompany, quoteActivityDisabled)
    : getAccountFormData(defaultNotificationsData, isCompany, quoteActivityDisabled);

  const rfqForm = (dataChanged || isSaving)
    ? getRFQFormData(notificationsData)
    : getRFQFormData(defaultNotificationsData);

  return (
    <>
      <FormTitleSection title="Notification Preferences" showEditButton={false} />
      <TabContainer>
        <p>Select which notifications the user will be getting:</p>
        <br />
        <ToggleForm
          data={eplaneFormData}
          title="ePlane Updates"
          onChange={onChange}
        />
        <br />
        <ToggleForm
          data={accountForm}
          title="Account Updates"
          onChange={onChange}
        />
        <br />

        {isPartsWatchListDisabled ? (
          <p>Parts watchlist is available for users with enabled feature or activated Pro Plan.</p>
        ) : (
          <Form.Group as={Row} controlId="partsWatchlistNotifications">
            <Col sm={5}>
              <CheckboxStyled
                id="checkbox-parts-watchlist-schedule"
                // eslint-disable-next-line react/jsx-one-expression-per-line
                label={<Label>Notify the user of <strong>parts watchlist updates</strong> in email digest.</Label>}
                checked={defaultNotificationsData.pwl_notifications ?? false}
                disabled={isPartsWatchListDisabled}
                name="partsWatchlistNotifications"
                onChange={onPartsWatchlistDigestChange}
              />
            </Col>
            <Col sm={2}>
              <ScheduleButton
                variant="link"
                disabled={!defaultNotificationsData.pwl_notifications || isPartsWatchListDisabled}
                onClick={() => setNotificationModal(true)}
              >
                <FontAwesomeIcon icon="bell" />
                &nbsp;Schedule email
              </ScheduleButton>
            </Col>
          </Form.Group>
        )}

        {isCompany && (
          <ToggleForm data={rfqForm} title="RFQ" onChange={onChange} />
        )}
        <br />

        {dataChanged && (
          <ButtonContainer>
            <Button variant="primary" onClick={saveNotificationsHandler}>Save Changes</Button>
          </ButtonContainer>
        )}
        <br />

        <ModalLoader show={isSaving || isUserSettingsFetching} />

        <NotificationModal
          aggregationData={partWatchlistSchedule}
          timeZones={timeZones}
          isTimeZonesFetching={isTimeZonesFetching}
          modalName="parts watchlist updates"
          title="Schedule email digest"
          show={notificationModal}
          onSaveHandler={savePartsWatchlistDigestHandler}
          onHide={() => setNotificationModal(false)}
        />
      </TabContainer>
    </>
  );
};

export default NotificationsPage;
