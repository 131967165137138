import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar as ButtonToolbarNormal } from 'react-bootstrap';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getRequestDataStore, getErrorsStore, getCountries } from '../../selectors';
import { getRequestData } from '../../reducers/requests/requestData';
import { fetchRequestData, processDecision } from '../../actions/requestDataActions';
import { pushRedirect } from '../../actions/redirectActions';

import Container from '../Container';
import RequestDetails from '../RequestDetails';
import { TableRow } from '../StyledComponents';
import NotFoundPage from '../NotFoundPage';
import Loader from '../Loader';
import HistoryTable from '../HistoryTable';
import UpdateDetailsModal from './UpdateDetailsModal';
import ApproveModal from './ApproveModal';
import DeclineModal from './DeclineModal';
import PromptModal from '../Modals/PromptModal';
import ConfirmationModal from '../Modals/ConfirmationModal';
import getCountryRegionLists from '../../utils/getCountryRegionLists';
import { ERRORS, PUBLIC_URL } from '../../constants';
import * as constants from '../../constants/index';

import './ManageRequest.css';

const ButtonToolbar = styled(ButtonToolbarNormal)`
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 600px;
`;

export default function ManageRequest() {
  const dispatch = useDispatch();

  const { id: reqId } = useParams();

  const [modals, setModals] = useState({
    showUpdateDetailsModal: false,
    showApproveModal: false,
    showDeclineModal: false,
    showInProgressModal: false,
    showOnHoldModal: false,
  });

  const countries = useSelector(getCountries);
  const requestDataStore = useSelector(getRequestDataStore);
  const { errors } = useSelector(getErrorsStore);

  const { requestDataIsFetching, decisionIsBeingProcessed } = requestDataStore;
  const { regionList } = getCountryRegionLists(countries || []);
  const requestData = getRequestData(requestDataStore, constants);

  useEffect(() => {
    dispatch(fetchRequestData(reqId));
  }, [dispatch, reqId]);

  const onApproveButtonHandler = () => {
    const user = requestData?.userEntity;

    if (
      !user.company_website
      || !user.company_certification
      || !user.company_internal_order_number
      || !user.contact_name
      || !user.contact_email
      || !user.contact_phone
      || !user.company_logo) {
      setModals((prev) => ({ ...prev, showUpdateDetailsModal: true }));
    } else {
      setModals((prev) => ({ ...prev, showApproveModal: true }));
    }
  };

  const onDeclineButtonHandler = () => {
    setModals((prev) => ({ ...prev, showDeclineModal: true }));
  };

  const onInProgressButtonHandler = () => {
    setModals((prev) => ({ ...prev, showInProgressModal: true }));
  };

  const onOnHoldButtonHandler = () => {
    setModals((prev) => ({ ...prev, showOnHoldModal: true }));
  };

  const getToolButtons = (result) => {
    if (!result) return null;
    return (
      <TableRow>
        <ButtonToolbar>
          <Button variant="success" onClick={onApproveButtonHandler}>
            APPROVE
          </Button>

          <Button variant="danger" onClick={onDeclineButtonHandler}>
            DECLINE
          </Button>

          <Button
            variant="primary"
            onClick={onInProgressButtonHandler}
            disabled={result === constants.crrResult.INPROGRESS.id}
          >
            IN PROGRESS
          </Button>

          <Button
            variant="primary"
            onClick={onOnHoldButtonHandler}
            disabled={result === constants.crrResult.ONHOLD.id}
          >
            ON HOLD
          </Button>
        </ButtonToolbar>
      </TableRow>
    );
  };

  const approve = (approveComment) => {
    hideModals();

    dispatch(processDecision(reqId, constants.crrDecision.APPROVE, approveComment))
      .then((reqData) => {
        if (reqData) {
          // the request was successfully sent
          dispatch(pushRedirect(`${PUBLIC_URL}/incoming`));
        }
      });
  };

  const decline = (declineComment) => {
    hideModals();

    dispatch(processDecision(reqId, constants.crrDecision.DECLINE, declineComment))
      .then((reqData) => {
        if (reqData) {
          // the request was successfully sent
          dispatch(pushRedirect(`${PUBLIC_URL}/incoming`));
        }
      });
  };

  const hold = (comment) => {
    const { crrDecision } = constants;
    hideModals();
    dispatch(processDecision(reqId, crrDecision.HOLD, comment));
  };

  const start = () => {
    hideModals();

    dispatch(processDecision(reqId, constants.crrDecision.START, 'Started Progress'));
  };

  const hideModals = () => {
    setModals({
      showUpdateDetailsModal: false,
      showApproveModal: false,
      showDeclineModal: false,
      showInProgressModal: false,
      showOnHoldModal: false,
    });
  };

  const forceApprove = () => approve('');

  // TODO: UpdateDetails modal shouldn't has "APPROVE" button
  let content;
  if (requestDataIsFetching) {
    content = <Loader />;
  } else if (errors.some((item) => item.error === ERRORS.ERR_ENTITY_NOT_FOUND)) {
    content = <NotFoundPage />;
  } else if (requestData) {
    const hqReq = requestData.headquarters;
    const headQuarters = [];
    const regionName = hqReq.state || hqReq.region;
    const approveDisabled = !(hqReq.countryId && (!regionList[hqReq.countryId] || (regionList[hqReq.countryId] && hqReq.regionId)));
    if (hqReq.street) headQuarters.push(hqReq.street);
    if (hqReq.city) headQuarters.push(hqReq.city);
    if (regionName) headQuarters.push(regionName);
    if (hqReq.zipCode) headQuarters.push(hqReq.zipCode);
    if (hqReq.countryName) headQuarters.push(hqReq.countryName);

    content = (
      <div>
        <RequestDetails
          user_id={requestData.companyUserId}
          isPro={requestData.isPro}
          reqNo={requestData.entityId}
          userName={requestData.userName}
          userEmail={requestData.userEmail}
          userFiles={requestData.documents}
          companyName={requestData.companyName}
          companyType={requestData.companyType}
          companyContact={requestData.companyContact}
          companyEmail={requestData.companyEmail}
          headquarters={headQuarters.join(', ')}
          phoneNumber={requestData.companyPhone}
          contactPhone={requestData.contactPhone}
          emailIsVerified={requestData.userEmailIsVerified}
          companyWebsite={requestData.companyWebsite}
          vatTaxNumber={requestData.vatTax}
          statusResult={requestData.result}
          decisionIsBeingProcessed={decisionIsBeingProcessed}
          boUserComment={requestData.comment}
          boAdminEmail={requestData.boUser}
          constants={constants}
        />

        {getToolButtons(requestData.result)}

        <TableRow>
          <HistoryTable userID={requestData.userId} />
        </TableRow>

        <UpdateDetailsModal
          show={modals.showUpdateDetailsModal}
          onHide={hideModals}
          approve={forceApprove}
          reqId={reqId}
          requestData={requestData}
          approveDisabled={approveDisabled}
        />

        <ApproveModal
          show={modals.showApproveModal}
          onHide={hideModals}
          approve={approve}
        />

        <DeclineModal
          show={modals.showDeclineModal}
          onHide={hideModals}
          decline={decline}
        />

        <ConfirmationModal
          show={modals.showInProgressModal}
          confirm={start}
          onHide={hideModals}
        >
          { /* eslint-disable-next-line react/jsx-one-expression-per-line */ }
          Are you sure you want to update this request&apos;s status to be <strong>IN PROGRESS</strong>?
        </ConfirmationModal>

        <PromptModal
          show={modals.showOnHoldModal}
          confirm={hold}
          onHide={hideModals}
          placeholder="Comment..."
          isRequired
        >
          { /* eslint-disable-next-line react/jsx-one-expression-per-line */ }
          Are you sure you want to update this request&apos;s status to be <strong>ON HOLD</strong>?
        </PromptModal>
      </div>
    );
  }

  return (
    <Container links={[{ 'Incoming request': 'incoming' }, { 'Manage request': null }]}>
      {content}
    </Container>
  );
}
