import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import SideNavigation from '../../components/SideNavigation';
import Container from '../../components/Container';
import TabsDev from './TabsDev';
import { color, PUBLIC_URL } from '../../constants';

const RowContent = styled(Row)`
  margin-top: 30px;
`;
const MenuWrapper = styled(Col)`
  border-right: 2px solid ${color.lightGrey};
  margin-bottom: 10px;
  padding-bottom: 10px;
`;
const Content = styled(Col)`
  && {
    padding-left: 45px;
    clear: right;
    display: flex;
    flex-direction: column;
  }
`;
const mapRoutesToTitles = {
  emails: 'Emails',
  logs: 'Logs',
  bounceEmails: 'Bounce Emails',
};

export default () => {
  const urlPrefix = `${PUBLIC_URL}/dev/`;

  return (
    <Container links={[{ Dev: 'dev' }]}>
      <RowContent>
        <MenuWrapper sm={3}>
          <SideNavigation
            routes={mapRoutesToTitles}
            urlPrefix={urlPrefix}
          />
        </MenuWrapper>
        <Content sm={9}>
          <div>
            <TabsDev />
          </div>
        </Content>
      </RowContent>
    </Container>
  );
};
