import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Img from '../../Img';
import { StyledLinkButton as LinkButton } from '../../StyledComponents';
import { color } from '../../../constants';

const Date = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 2px;
  color: ${color.silverGrey};
`;
const A = styled.a`
  cursor: pointer;
  && {
    text-decoration: none;
    align-items: center;
    background-color: ${color.white};
    display: flex;
    padding: 27px 10px 29px 0;
    text-decoration: none;
    width: 436px;
  }
`;
const NewsBlock = styled.div`
  margin: 10px 10px;
  padding: 10px 10px;
  border: 1px solid ${(props) => (props.isVisible ? color.lightCyan : color.altoGrey)};
  border-radius: 3px;
  width: 100%;
  background-color: ${(props) => (props.isVisible ? color.lightBirch : color.whiteSmoke)};
  border-left: 3px solid ${(props) => (props.isVisible ? color.oracle : color.grey)};
`;
const BlogPostContainer = styled.div`
  box-sizing: content-box;
  display: block;
  min-height: 100px;
  position: relative;
`;
const Image = styled(Img)`

`;
const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 130px;
`;
const BlogPostContent = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  width: 435px;
`;
const Index = styled.div`
  margin-top: 20px;
  font-weight: bold;
  min-width: 20px;
`;
const NewsItem = styled.div`
  display: flex;
  max-width: 700px;
  padding-left: 15px;
  ${(props) => (props.isDragging && 'background-color: #FFCA8A;')}
  &&&&&:hover {
    .edit-news-button {
      visibility: visible;
    }
  }
`;
const Title = styled.p`
  color: ${color.darkGrey};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.45;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const EditButton = styled(LinkButton)`
  visibility: hidden;
`;
const HiddenBadge = styled.div`
  font-size: 14px;
  color: ${color.grey};
`;
const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const FeaturedInList = ({ items, onRowClickHandler }) => (
  (items?.map((v, i) => (
    <Draggable draggableId={v.item_id} index={i} key={v.item_id}>
      {(provided, draggableSnapshot) => (
        <NewsItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={draggableSnapshot.isDragging}
        >
          <Index>{`${i + 1}.`}</Index>
          <NewsBlock isVisible={v.is_visible}>
            <BlogPostContainer>
              <A target={v.target_type} rel="noopener noreferrer" to={`${v.link}`}>
                <BlogPostContent>
                  <ImageContainer>
                    <Image src={v.image_url} alt={v.image_alt_text} />
                  </ImageContainer>
                  <div>
                    <Date>{v.date}</Date>
                    <TitleBlock>
                      <Title>{v.title}</Title>
                      {!v.is_visible && <HiddenBadge>Hidden</HiddenBadge>}
                    </TitleBlock>
                  </div>
                </BlogPostContent>
              </A>
            </BlogPostContainer>
          </NewsBlock>
          <EditButton className="edit-news-button" variant="link" onClick={() => onRowClickHandler(v.item_id)}>
            <FontAwesomeIcon icon="edit" size="lg" />
          </EditButton>
        </NewsItem>
      )}
    </Draggable>
  ))) || null
);

export default FeaturedInList;
