import axios from 'axios';

import {
  INCOMING_REQUESTS_FETCH,
  INCOMING_REQUESTS_ARE_FETCHED,

  INCOMING_REQUESTS_SEARCHING_START,
  INCOMING_REQUESTS_SEARCHING_FINISHED,

  INCOMING_REQUEST_ERROR_OCCURRED,
} from '../constants';
import { requestErrorHandler } from './requestErrorHandler';

const startFetching = () => ({
  type: INCOMING_REQUESTS_FETCH,
});
const requestsAreFetched = (incomingRequests) => ({
  type: INCOMING_REQUESTS_ARE_FETCHED,
  payload: incomingRequests,
});

const startSearching = (searchString) => ({
  type: INCOMING_REQUESTS_SEARCHING_START,
  payload: searchString,
});
const finishSearching = (incomingRequests) => ({
  type: INCOMING_REQUESTS_SEARCHING_FINISHED,
  payload: incomingRequests,
});

export const incomingRequestsFail = () => ({
  type: INCOMING_REQUEST_ERROR_OCCURRED,
});

export const fetchIncomingRequests = () => (dispatch) => {
  dispatch(startFetching());
  const params = {
    completeStatus: 'open',
  };
  axios('/rest/default/V1/eplane/backoffice/companyRegistrationRequests', { params }).then((response) => {
    dispatch(requestsAreFetched(response.data));
  }).catch((e) => {
    requestErrorHandler(e.response, dispatch, incomingRequestsFail, e);
  });
};

export const searchForIncomingRequests = (searchString, status = 'open') => (dispatch) => {
  dispatch(startSearching(searchString));
  const params = {
    completeStatus: status,
    q: searchString,
  };
  axios('/rest/default/V1/eplane/backoffice/companyRegistrationRequests', { params }).then((response) => {
    dispatch(finishSearching(response.data));
  }).catch((e) => {
    requestErrorHandler(e.response, dispatch, incomingRequestsFail, e);
  });
};
