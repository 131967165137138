/* eslint-disable camelcase */
import axios from 'axios';

import {
  BUILTIN_QUOTE_SEND,
  BUILTIN_QUOTE_SEND_SUCCESS,

  BUILTIN_VALIDATE,
  BUILTIN_VALIDATE_SUCCESS,

  BUILTIN_NOQUOTE_SEND,
  BUILTIN_NOQUOTE_SEND_SUCCESS,

  BUILTIN_QUOTE_EDIT,
  BUILTIN_QUOTE_EDIT_SUCCESS,

  TAG_FILE_REMOVE,
  TAG_FILE_REMOVE_SUCCESS,

  BUILTIN_QUOTE_REMOVE,
  BUILTIN_QUOTE_REMOVE_SUCCESS,

  BUILTIN_QUOTE_MARK_PO,
  BUILTIN_QUOTE_MARK_PO_SUCCESS,

  TASKS_OPERATION_FAIL,
  BUILTIN_QUOTE_SCROLLED,

  ERRORS,
  fileFormatsAny,
  maxFileSizeSendMsg,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';
import {
  startUploadingFile,
  finishUploadingFile,
  onUploadFileProgress,
  setUploadingErrorCode,
  uploadingFileErrorHandler,
} from '../fileUploaderActions';
import api from '../../API';

const startSendingQuote = () => ({
  type: BUILTIN_QUOTE_SEND,
});
const sendQuoteSuccess = (message) => ({
  type: BUILTIN_QUOTE_SEND_SUCCESS,
  payload: message,
});

const startSendingNoQuote = () => ({
  type: BUILTIN_NOQUOTE_SEND,
});
const sendNoQuoteSuccess = (message) => ({
  type: BUILTIN_NOQUOTE_SEND_SUCCESS,
  payload: message,
});

const startMarkingInvalid = () => ({
  type: BUILTIN_VALIDATE,
});
const markingInvalidSuccess = (message) => ({
  type: BUILTIN_VALIDATE_SUCCESS,
  payload: message,
});

const startEditingQuote = () => ({
  type: BUILTIN_QUOTE_EDIT,
});
const editQuoteSuccess = (message) => ({
  type: BUILTIN_QUOTE_EDIT_SUCCESS,
  payload: message,
});

const startRemovingQuote = () => ({
  type: BUILTIN_QUOTE_REMOVE,
});
const removeQuoteSuccess = (message) => ({
  type: BUILTIN_QUOTE_REMOVE_SUCCESS,
  payload: message,
});

const startRemovingTagFile = () => ({ type: TAG_FILE_REMOVE });
const removeTagFileSuccess = (message) => ({
  type: TAG_FILE_REMOVE_SUCCESS,
  payload: message,
});

const startMarkingAsPo = () => ({
  type: BUILTIN_QUOTE_MARK_PO,
});
const markingAsPoSuccess = (message) => ({
  type: BUILTIN_QUOTE_MARK_PO_SUCCESS,
  payload: message,
});

const removeQuoteFail = () => ({
  type: TASKS_OPERATION_FAIL,
});

export const clearScrollToMessage = () => ({
  type: BUILTIN_QUOTE_SCROLLED,
});

export const saveBuiltInQuote = (message, attachments, orderId) => async (dispatch) => {
  dispatch(startSendingQuote());

  const body = { req: { message, attachments } };
  const path = `/rest/default/V1/eplane/backoffice/rfq/${orderId}/message/quote`;

  try {
    const response = await axios.post(path, body);
    if (response.data.code === ERRORS.SUCCESS) {
      const msg = response.data.messages && Object.values(response.data.messages)[0];
      dispatch(sendQuoteSuccess(msg && msg.message_id));
    } else {
      requestErrorHandler(response, dispatch, removeQuoteFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, removeQuoteFail, e);
  }
};

export const editBuiltInQuote = (message, attachments, orderId, messageId) => async (dispatch) => {
  dispatch(startEditingQuote());

  const body = { req: { message, attachments } };
  const path = `/rest/default/V1/eplane/backoffice/rfq/${orderId}/message/${messageId}/quote`;

  try {
    const response = await axios.put(path, body);
    if (response.data.code === ERRORS.SUCCESS) {
      const msg = response.data.messages && Object.values(response.data.messages)[0];
      dispatch(editQuoteSuccess(msg && msg.message_id));
    } else {
      requestErrorHandler(response, dispatch, removeQuoteFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, removeQuoteFail, e);
  }
};

export const removeBuiltInQuote = (orderId, messageId) => async (dispatch) => {
  dispatch(startRemovingQuote());
  try {
    const response = await axios.delete(`/rest/default/V1/eplane/backoffice/rfq/${orderId}/message/${messageId}/quote`);
    if (response.data.code === ERRORS.SUCCESS) dispatch(removeQuoteSuccess(response.data));
    else {
      requestErrorHandler(response, dispatch, removeQuoteFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, removeQuoteFail, e);
  }
};

export const removeTagFile = (orderId, attachmentId) => async (dispatch) => {
  dispatch(startRemovingTagFile());
  try {
    const response = await api.deleteRfqAttachment(orderId, attachmentId);
    dispatch(removeTagFileSuccess(response.data));
  } catch (e) {
    requestErrorHandler(e.response, dispatch, removeQuoteFail, e);
  }
};

export const sendNoQuote = (orderId, messageId) => async (dispatch) => {
  dispatch(startSendingNoQuote());

  const quote = { no_quote: true };
  const message = { quote, cited_message_id: messageId, text: 'No quote.' };
  const body = { req: { message } };
  const path = `/rest/default/V1/eplane/backoffice/rfq/${orderId}/message/quote`;

  try {
    const response = await axios.post(path, body);
    if (response.data.code === ERRORS.SUCCESS) {
      const msg = response.data.messages && Object.values(response.data.messages)[0];
      dispatch(sendNoQuoteSuccess(msg && msg.message_id));
    } else {
      requestErrorHandler(response, dispatch, removeQuoteFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, removeQuoteFail, e);
  }
};

export const markAsPo = (orderId, attachementId) => async (dispatch) => {
  dispatch(startMarkingAsPo());
  try {
    const response = await axios.put(`rest/V1/eplane/backoffice/rfq/${orderId}/attachment/${attachementId}/markPO`);
    if (response.data.code === ERRORS.SUCCESS) dispatch(markingAsPoSuccess(response.data));
    else {
      requestErrorHandler(response, dispatch, removeQuoteFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, removeQuoteFail, e);
  }
};

export const quoteValidate = (orderId, messageId, state) => async (dispatch) => {
  dispatch(startMarkingInvalid());
  try {
    const response = await axios.post(
      `rest/V1/eplane/backoffice/rfq/${orderId}/message/${messageId}/quote/change-validation`,
      { is_valid: state },
    );
    if (response.data.code === ERRORS.SUCCESS) {
      const msg = response.data.messages && Object.values(response.data.messages)[0];
      dispatch(markingInvalidSuccess(msg?.message_id));
    } else {
      requestErrorHandler(response, dispatch, removeQuoteFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, removeQuoteFail, e);
  }
};

export const uploadTagFile = (file) => (dispatch) => {
  if (file && fileFormatsAny.every((mimeType) => mimeType !== file.type)) {
    dispatch(setUploadingErrorCode(ERRORS.ERR_WRONG_FORMAT));
    return;
  }
  if (file.size > maxFileSizeSendMsg) {
    const maxSizeFormat = `${maxFileSizeSendMsg / 1024 / 1024} MB`;
    dispatch(setUploadingErrorCode(ERRORS.ERR_FILE_IS_TOO_LARGE, { maxFileSize: maxSizeFormat }));
    return;
  }

  const source = axios.CancelToken.source();
  dispatch(startUploadingFile(source));

  const fd = new FormData(); // eslint-disable-line no-undef
  fd.append('files', file || null);
  const options = {
    onUploadProgress: (e) => onUploadFileProgress(e, dispatch),
    cancelToken: source.token,
  };

  axios.post('/backoffice/rfq/sendmessage_attach', fd, options).then((response) => {
    dispatch(finishUploadingFile(response.data?.files));
  }).catch((e) => {
    uploadingFileErrorHandler(e, dispatch);
  });
};
