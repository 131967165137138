export function getCompanyTypeById(id, constants)  {// eslint-disable-line
  if (!id || !(constants && constants.companyType)) return null;
  const typeObj = Object.values(constants.companyType).find((t) => t.id === id);
  return typeObj && typeObj.name;
}

export function getConstNameById(id, constants, constType) {
  if (!id || !(constants && constants[constType])) return null;
  const typeObj = Object.values(constants[constType]).find((t) => t.id === id);
  return typeObj && typeObj.name;
}
export function getNameById(id, constants) {
  if (!id || !constants) return null;
  const typeObj = Object.values(constants).find((t) => t.id.toString() === id.toString());
  return (typeObj && typeObj.name) || '';
}

export function getIdByConstName(name, constants, constType) {
  if (!name || !(constants && constants[constType])) return null;
  const typeObj = Object.values(constants[constType]).find((t) => t.name === name);
  return typeObj && typeObj.id;
}

export function getArrayConstNamesByArraysOfIds(arrayIds, constants, constType) {
  if (!arrayIds || !(constants && constants[constType])) return [];
  return arrayIds.map((id) => getConstNameById(id, constants, constType));
}

export function getArrayConstNameByConstType(constants, constType) {
  if (!(constants && constants[constType])) return [];
  return Object.values(constants[constType]).map((elem) => elem.name);
}

export function getConditionNamesById(conditionsConst, condIdArray) {
  return condIdArray.map((val) => {
    if (!conditionsConst || !val) return null;
    const conditionObj = Object.values(conditionsConst).find((el) => el.id === val);
    return conditionObj && conditionObj.name;
  });
}
