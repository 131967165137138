import * as consts from '../constants';

export function pushRedirect(path) {
  return {
    type: consts.ROUTING,
    payload: {
      method: 'push',
      nextUrl: path,
    },
  };
}

export function replaceRedirect(path) {
  return {
    type: consts.ROUTING,
    payload: {
      method: 'replace',
      nextUrl: path,
    },
  };
}
