import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getNocFormStore } from '../../selectors';

import BuildInQuoteForm from './ProvideQuote/BuiltInQuoteForm';
import PaymentRequestForm from './PaymentsTasks/PaymentRequestForm';
import SendMessageForm from './PaymentsTasks/SendMessageForm';
import WireTransferForm from './PaymentsTasks/WireTransferForm';
import OrderStatusForm from './PaymentsTasks/OrderStatusForm';
import ReportPaymentForm from './PaymentsTasks/ReportPaymentForm';
import IgnoreTaskForm from './ProvideQuote/TasksIgnoreForm';
import { NOC_FORMS } from '../../constants/operationCenter';

const BuiltInQuoteModal = ({
  orderId, messageId, cancelHandler, ...other
}) => {
  const { currentForm } = useSelector(getNocFormStore);

  let modalContent = null;
  if (currentForm?.type === NOC_FORMS.BUILT_IN_QUOTE) {
    modalContent = (
      <BuildInQuoteForm key={currentForm.messageId} orderId={orderId} messageId={messageId} cancelHandler={cancelHandler} />
    );
  } else if (currentForm?.type === NOC_FORMS.ORDER_STATUS) {
    modalContent = (
      <OrderStatusForm orderId={orderId} />
    );
  } else if (currentForm?.type === NOC_FORMS.SEND_MESSAGE) {
    modalContent = (
      <SendMessageForm orderId={orderId} />
    );
  } else if (currentForm?.type === NOC_FORMS.PAYMENT_REQUEST) {
    modalContent = (
      <PaymentRequestForm orderId={orderId} />
    );
  } else if (currentForm?.type === NOC_FORMS.REPORT_PAYMENT) {
    modalContent = (
      <ReportPaymentForm orderId={orderId} />
    );
  } else if (currentForm?.type === NOC_FORMS.WIRE_TRANSFER) {
    modalContent = (
      <WireTransferForm orderId={orderId} />
    );
  } else if (currentForm?.type === NOC_FORMS.IGNORE_TASK) {
    modalContent = (
      <IgnoreTaskForm messageId={currentForm.messageId} />
    );
  }

  return (
    <Modal centered onHide={cancelHandler} dialogClassName="built-in-quote-modal" {...other} backdrop="static">
      <Modal.Body>
        {modalContent}
      </Modal.Body>
    </Modal>
  );
};

BuiltInQuoteModal.propTypes = {
  orderId: PropTypes.string,
  messageId: PropTypes.string,
  cancelHandler: PropTypes.func.isRequired,
};

export default BuiltInQuoteModal;
