import React from 'react';
import styled from 'styled-components';
import ComingSoonManGif from './coming-soon-man.gif';

const Title = styled.div`
  font-size: 35px;
  font-family: 'Lato',sans-serif;
  font-weight: 300;
  color: #333333;
  margin-bottom: 20px;

`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ComingSoonManElement = styled.div`
  width: 300px;
  height: 282px;

  background-image: url(${ComingSoonManGif});
`;

export default function ComingSoonPage() {
  return (
    <Main>
      <Title>Coming Soon</Title>
      <ComingSoonManElement />
    </Main>
  );
}
