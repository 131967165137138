import React from 'react';
import styled from 'styled-components';
import { Button, FormGroup, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Container = styled(FormGroup)`
  text-align: left;
  
  & Button {
    margin: 10px 30px;
    @media (max-width: 1824px) { 
      margin: 10px 10px;
    }
    width: ${(props) => `${props.width}px` || '145px'};
  }
`;

const FormActionButtons = ({
  saveProps,
  saveHandler,
  cancelProps,
  cancelHandler,
  confirmButtonText = 'SAVE',
  cancelButtonText = 'CANCEL',
  width,
  showLoader,
}) => (
  <Container width={width}>
    <Button variant="primary" onClick={saveHandler} {...saveProps}>
      {showLoader && (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      {confirmButtonText}
    </Button>
    <Button variant="outline-secondary" onClick={cancelHandler} {...cancelProps}>{cancelButtonText}</Button>
  </Container>
);

FormActionButtons.propTypes = {
  saveHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  showLoader: PropTypes.bool,
  saveProps: PropTypes.shape({}),
  cancelProps: PropTypes.arrayOf(PropTypes.object),
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  width: PropTypes.string,
};

export default FormActionButtons;
