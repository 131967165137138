import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ButtonsWrapper, Body, Button, Text as Title,
} from '../../Modals/styledComponents';
import { color } from '../../../constants';

// Not used fully used because uploading error can not be get without web socket's integration
export default function WatchListFullModal({ onHide, ...other }) {
  return (
    <Modal centered onHide={onHide} {...other}>
      <Modal.Body>
        <Title>
          <FontAwesomeIcon icon="exclamation-circle" color={color.tomatoRed} size="2x" />
        </Title>
        <Title><strong>Parts Watchlist is Full</strong></Title>
        <Body>
          <p>
            Parts Watchlist is limited to 4000 parts.
            <br />
            Remove some parts from your list and try again.
          </p>
        </Body>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsWrapper>
          <Button variant="primary" onClick={onHide}>
            OK
          </Button>
        </ButtonsWrapper>
      </Modal.Footer>
    </Modal>
  );
}

WatchListFullModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};
