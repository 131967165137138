/**
 * function creates regular expression matching str
 * @param {string} str
 * @param {string} [flags] flags will apply to returning regexp
 * @return {RegExp} regular expression t
 */
export default function createRegExpForString(str, flags = '') {
  // eslint-disable-next-line no-useless-escape
  const re = /([\\\^\$\.\?\*\|\+\(\)\[\{\}\]])/g;
  const newStr = str.replace(re, '\\$&');

  return new RegExp(newStr, flags);
}
