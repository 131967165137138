import {
  TASK_IGNORE_LIST_GET,
  TASK_IGNORE_LIST_GET_SUCCESS,

  TASK_IGNORE_LIST_SAVE,
  TASK_IGNORE_LIST_SAVE_SUCCESS,

  TASK_IGNORE_LIST_DELETE,
  TASK_IGNORE_LIST_DELETE_SUCCESS,

  TASK_IGNORE_LIST_FAIL,
} from '../../constants';

const initialState = {
  ignoreRules: null,
  isFetching: false,
  isProcessing: false,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case TASK_IGNORE_LIST_GET:
      return {
        ...state,
        isFetching: true,
      };
    case TASK_IGNORE_LIST_GET_SUCCESS: {
      return {
        ...state,
        ignoreRules: payload,
        isFetching: false,
      };
    }

    case TASK_IGNORE_LIST_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case TASK_IGNORE_LIST_SAVE_SUCCESS: {
      return {
        ...state,
        ignoreRules: payload,
        isProcessing: false,
      };
    }

    case TASK_IGNORE_LIST_DELETE:
      return {
        ...state,
        isProcessing: true,
      };
    case TASK_IGNORE_LIST_DELETE_SUCCESS: {
      return {
        ...state,
        ignoreRules: payload,
        isProcessing: false,
      };
    }

    case TASK_IGNORE_LIST_FAIL:
      return {
        isFetching: false,
        isProcessing: false,
      };

    default: return state;
  }
};
