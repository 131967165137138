import {
  VENDOR_CATEGORIZATION_LIST_GET,
  VENDOR_CATEGORIZATION_LIST_GET_SUCCESS,

  VENDOR_CATEGORIZATION_SAVE,
  VENDOR_CATEGORIZATION_SAVE_SUCCESS,

  VENDOR_CATEGORIZATION_FAIL,
} from '../../constants';

const initialState = {
  vendorCategorizationList: [],
  isFetching: false,
  isSaving: false,
};

const vendorCategorizationReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case VENDOR_CATEGORIZATION_LIST_GET:
      return {
        ...state,
        isFetching: true,
      };
    case VENDOR_CATEGORIZATION_LIST_GET_SUCCESS:
      return {
        ...state,
        vendorCategorizationList: payload,
        isFetching: false,
      };

    case VENDOR_CATEGORIZATION_SAVE:
      return {
        ...state,
        isSaving: true,
      };
    case VENDOR_CATEGORIZATION_SAVE_SUCCESS:
      return {
        ...state,
        vendorCategorizationList: payload,
        isSaving: false,
      };

    case VENDOR_CATEGORIZATION_FAIL:
      return {
        ...state,
        isFetching: false,
        isSaving: false,
      };

    default: return state;
  }
};

export default vendorCategorizationReducer;

export const getVendorsCategoryList = (rawData) => (
  rawData.map((v) => ({ value: v.id, label: v.name }))
);
