import {
  USER_PAYMENT_PROVIDERS_GET,
  USER_PAYMENT_PROVIDERS_GET_SUCCESS,
  USER_PAYMENT_PROVIDERS_SAVE,
  USER_PAYMENT_PROVIDERS_SAVE_SUCCESS,
  USER_PAYMENT_PROVIDER_DETACH,
  USER_PAYMENT_PROVIDER_DETACH_SUCCESS,
  USER_PAYMENT_PROVIDER_COMMISSION_REMOVE,
  USER_PAYMENT_PROVIDER_COMMISSION_REMOVE_SUCCESS,
  USER_PAYMENT_PROVIDER_METHODS_SAVE,
  USER_PAYMENT_PROVIDER_METHODS_SAVE_SUCCESS,

  USER_PAYMENT_PROVIDERS_FAIL,
} from '../../constants';
import { paymentProviders } from '../../constants/index';

const initialState = {
  stripe: null,
  providers: null,
  isFetching: false,
  isProcessing: false,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case USER_PAYMENT_PROVIDERS_GET:
      return {
        ...state,
        isFetching: true,
      };
    case USER_PAYMENT_PROVIDERS_GET_SUCCESS:
      return {
        ...state,
        stripe: payload.items.find((v) => v.code === paymentProviders.STRIPE) || null,
        providers: payload.items,
        isFetching: false,
      };
    case USER_PAYMENT_PROVIDERS_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case USER_PAYMENT_PROVIDERS_SAVE_SUCCESS:
      return {
        ...state,
        stripe: payload.items.find((v) => v.code === paymentProviders.STRIPE) || null,
        providers: payload.items,
        isProcessing: false,
      };
    case USER_PAYMENT_PROVIDER_DETACH:
      return {
        ...state,
        isProcessing: true,
      };
    case USER_PAYMENT_PROVIDER_DETACH_SUCCESS:
      return {
        ...state,
        isProcessing: false,
      };
    case USER_PAYMENT_PROVIDER_COMMISSION_REMOVE:
      return {
        ...state,
        isProcessing: true,
      };
    case USER_PAYMENT_PROVIDER_COMMISSION_REMOVE_SUCCESS: {
      const updProviders = state.providers.map((v) => (
        v.code === payload.items[0].provider_code
          ? { ...v, fee: null }
          : v
      ));
      return {
        ...state,
        stripe: updProviders.find((v) => v.code === paymentProviders.STRIPE) || null,
        providers: updProviders,
        isProcessing: false,
      };
    }
    case USER_PAYMENT_PROVIDER_METHODS_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case USER_PAYMENT_PROVIDER_METHODS_SAVE_SUCCESS:
      return {
        ...state,
        stripe: payload.items.find((v) => v.code === paymentProviders.STRIPE) || null,
        providers: payload.items,
        isProcessing: false,
      };
    case USER_PAYMENT_PROVIDERS_FAIL:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
      };
    default: return state;
  }
};
