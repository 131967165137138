import axios from 'axios';

import {
  DEMAND_LIST_UPLOADING_START,
  DEMAND_LIST_UPLOADING_FINISH,
  DEMAND_LIST_UPLOADING_PROGRESS_SET,
  DEMAND_LIST_UPLOADING_PROGRESS_RESET,
  DEMAND_LIST_UPLOADING_TERMINATE,
  DEMAND_LIST_ERROR_REMOVE,
  DEMAND_LIST_UPLOADING_ERROR_OCCURRED,

  AXIOS_CANCELLED_MESSAGE,
  ERRORS,
  maxFileSizeDemand,
} from '../../constants';
import errorHandler from '../requestErrorHandler';
import { updateComments } from '../chatActions';

export const removeErrorCode = () => ({
  type: DEMAND_LIST_ERROR_REMOVE,
});

const startUploadingDemandList = (source) => ({
  type: DEMAND_LIST_UPLOADING_START,
  payload: source,
});
const finishUploadingDemandList = (document) => ({
  type: DEMAND_LIST_UPLOADING_FINISH,
  payload: document,
});
const setUploadingProgress = (percentOfUpload) => ({
  type: DEMAND_LIST_UPLOADING_PROGRESS_SET,
  payload: percentOfUpload,
});

export const resetUploadingProgress = () => ({
  type: DEMAND_LIST_UPLOADING_PROGRESS_RESET,
});

export const terminateUploadingProgress = () => ({
  type: DEMAND_LIST_UPLOADING_TERMINATE,
});

export const setUploadingErrorCode = (errorCode) => ({
  type: DEMAND_LIST_UPLOADING_ERROR_OCCURRED,
  payload: errorCode,
});

export const uploadDemandList = (demandFile, userId, customerId, comment) => (dispatch) => {
  const source = axios.CancelToken.source();
  dispatch(startUploadingDemandList(source));

  if (demandFile && demandFile.size > maxFileSizeDemand) {
    dispatch(setUploadingErrorCode(ERRORS.ERR_FILE_IS_TOO_LARGE));
    return;
  }

  const fd = new FormData(); // eslint-disable-line no-undef
  fd.append('user_id', userId);
  fd.append('customer_id', customerId);
  fd.append('files', demandFile);
  fd.append('eplane_comment', comment);

  const options = {
    onUploadProgress: (e) => onUploadProgress(e, dispatch),
    cancelToken: source.token,
  };
  axios.post('/backoffice/demand/upload', fd, options).then((response) => {
    dispatch(finishUploadingDemandList(response.data));
    dispatch(updateComments());
  }).catch((e) => uploadingErrorHandler(e, dispatch));
};

const onUploadProgress = (progressEvent, dispatch) => {
  const { loaded, total } = progressEvent;
  dispatch(setUploadingProgress((loaded * 100) / total));
};

const uploadingErrorHandler = (e, dispatch) => {
  if (e.message === AXIOS_CANCELLED_MESSAGE) {
    console.warn(AXIOS_CANCELLED_MESSAGE); // eslint-disable-line no-console
    return;
  }
  errorHandler(e.response, dispatch, setUploadingErrorCode, e);
};
