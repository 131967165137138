import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import {
  ButtonsWrapper, Button, Text, Body,
} from '../../Modals/styledComponents';
import UniversalFormControl from '../../FormComponents/BaseFormGroup';

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;

  input[name=fee] {
    height: 38px;
  }
  span {
    margin-left: 8px;
    font-size: 16px;
  }
`;

const LimitModal = ({
  amount, onHide, title, text, currency, onLimitChange, onConfirm, validation, ...other
}) => (
  <Modal centered onHide={onHide} {...other}>
    <Body>
      <Text>{title}</Text>
      <br />
      <p>{text}</p>
      <InputContainer>
        <UniversalFormControl
          placeholder=""
          name="fee"
          value={(amount) || ''}
          onChange={onLimitChange}
          maxLength={10}
          {...validation(amount)}
        />
        <span>{currency}</span>
      </InputContainer>
    </Body>
    <Modal.Footer>
      <ButtonsWrapper>
        <Button variant="primary" onClick={onConfirm} disabled={!!validation(amount)}>Save</Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
      </ButtonsWrapper>
    </Modal.Footer>
  </Modal>
);

LimitModal.propTypes = {
  amount: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  currency: PropTypes.string,

  validation: PropTypes.func,
  onHide: PropTypes.func,
  onLimitChange: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default LimitModal;
