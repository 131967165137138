import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Img from '../Img';
import loadingAnimation from '../../assets/loading-animation-v2.gif';

const loader = {
  width: 251,
  height: 61,
};

const LoaderContainer = styled.div`
  position: fixed;
  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  background-color: rgba(255,255,255,0.7);
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 100;
`;

const LoaderAnimation = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  
  img: {
    display: block;
    width: ${loader.width}px;
    height: ${loader.height}px;
  }
`;

const ModalLoader = (props) => {
  const { show } = props;
  if (show) {
    return (
      <LoaderContainer>
        <LoaderAnimation>
          <Img src={loadingAnimation} alt="ePlane Animation" />
        </LoaderAnimation>
      </LoaderContainer>
    );
  }
  return null;
};

ModalLoader.propTypes = {
  show: PropTypes.bool,
};

export default ModalLoader;
