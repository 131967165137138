import styled from 'styled-components';

import { color } from '../../constants';

const TableStyles = styled.div`
  table {
    table-layout: fixed;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;

    border-spacing: 0;
    border: 1px solid black;
    background-color: ${color.stripedGrey};
    border: 1px solid #ddd;

    th, td {
      margin: 0;
      padding: 0.50rem;
      border: 1px solid #ddd;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      cursor: auto;
      vertical-align: middle;
    }
  
    tbody tr:nth-child(odd) {
      background-color: ${color.backgroundGrey};
    }

    tbody tr:hover {
      background-color: ${color.altoGrey};
    }

    thead tr th {
      background-color: ${color.blue};
      color: ${color.white};
      border-bottom-width: 2px
    }
  }
`;

export const ExpandableTableStyles = styled.div`
  .expandable-table {
    table-layout: fixed;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;

    border-spacing: 0;
    border: 1px solid black;
    background-color: ${color.stripedGrey};
    border: 1px solid #ddd;

    .table-header-cell, .table-cell {
      margin: 0;
      padding: 0.50rem;
      border: 1px solid #ddd;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .table-cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      cursor: auto;
      vertical-align: middle;
    }
  
    .table-body-row:nth-child(odd) {
      background-color: ${color.backgroundGrey};
    }

    .table-body-row:hover {
      background-color: ${color.altoGrey};
    }

    .table-header-cell {
      background-color: ${color.blue};
      color: ${color.white};
      border-bottom-width: 2px
    }
  }
`;

export default TableStyles;
