import axios from 'axios';

import {
  CLOSED_REQUESTS_FETCH,
  CLOSED_REQUESTS_ARE_FETCHED,

  CLOSED_REQUESTS_SEARCHING_START,
  CLOSED_REQUESTS_SEARCHING_FINISHED,
  CLOSED_REQUEST_ERROR_OCCURRED,

  CLOSED_REQUEST_TABLE_OPTIONS_SET,
  CLOSED_REQUEST_RESET_DATA,
} from '../constants';
import { requestErrorHandler } from './requestErrorHandler';

const startFetching = () => ({
  type: CLOSED_REQUESTS_FETCH,
});
const requestsAreFetched = (closedRequests) => ({
  type: CLOSED_REQUESTS_ARE_FETCHED,
  payload: closedRequests,
});

const startSearching = (searchString) => ({
  type: CLOSED_REQUESTS_SEARCHING_START,
  payload: searchString,
});
const finishSearching = (closedRequests) => ({
  type: CLOSED_REQUESTS_SEARCHING_FINISHED,
  payload: closedRequests,
});

export const closedRequestsFail = () => ({
  type: CLOSED_REQUEST_ERROR_OCCURRED,
});

export const fetchClosedRequests = (count) => (dispatch) => {
  dispatch(startFetching());
  const params = {
    completeStatus: 'closed',
    count,
  };
  axios('/rest/default/V1/eplane/backoffice/companyRegistrationRequests', { params }).then((response) => {
    dispatch(requestsAreFetched(response.data));
  }).catch((e) => {
    requestErrorHandler(e.response, dispatch, closedRequestsFail, e);
  });
};

export const searchForClosedRequests = (searchString, count) => (dispatch) => {
  dispatch(startSearching(searchString));
  const params = {
    completeStatus: 'closed',
    q: searchString,
    count,
  };
  axios('/rest/default/V1/eplane/backoffice/companyRegistrationRequests', { params }).then((response) => {
    dispatch(finishSearching(response.data));
  }).catch((e) => {
    requestErrorHandler(e.response, dispatch, closedRequestsFail, e);
  });
};

/**
 *
 * @param {Object} options
 * @param {Number} options.countPerPage
 * @param {Number} options.page
 */
export const setClosedRequestTableOptions = (options) => ({
  type: CLOSED_REQUEST_TABLE_OPTIONS_SET,
  payload: options,
});

export const resetDataOfClosedRequests = () => ({
  type: CLOSED_REQUEST_RESET_DATA,
});
