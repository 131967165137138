import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import SideNavigation from '../../components/SideNavigation';
import SettingsTabs from './settingsTabs';
import Container from '../../components/Container';
import { color, PUBLIC_URL } from '../../constants';

const MenuWrapper = styled(Col)`
  border-right: 2px solid ${color.lightGrey};
  margin-top: 30px;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;
const Content = styled(Col)`
  && {
    padding-top: 20px;
    padding-left: 45px;
    clear: right;
    display: flex;
    flex-direction: column;
  }
`;

const mapRoutesToTitles = {
  inventory: 'Inventory',
  companyGroup: 'Tier 3 Groups',
  demandCategories: 'Demand Categories',
  commission: 'General Commissions',
  bankAccount: 'Bank Account Details',
  ordersLimits: 'Order Limited Values',
  proPrice: 'Pro Price',
  emails: 'Emails',
  tasks: 'Tasks Management',
  mailchimp: 'Mailchimp',
  spec2k: {
    tabHeader: 'CMS',
    mapRoutes: {
      blogPosts: 'Latest Blog Posts',
      featuredIn: 'Featured In',
    },
  },
};

export default () => {
  const location = useLocation();

  const urlPrefix = `${PUBLIC_URL}/eplaneSettings/`;

  return (
    <Container links={[{ 'Eplane Settings': 'eplaneSettings' }]}>
      <Row>
        <MenuWrapper sm={3}>
          <SideNavigation
            routes={mapRoutesToTitles}
            urlPrefix={urlPrefix}
            currentPath={location.pathname}
          />
        </MenuWrapper>
        <Content sm={9}>
          <div>
            <SettingsTabs />
          </div>
        </Content>
      </Row>
    </Container>
  );
};
