import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import replaceDocImage from '../../../assets/ic-deny.png';
import { Text, Button } from '../../Modals/styledComponents';

const WrongFileImage = styled.div`
  background: url(${replaceDocImage}) no-repeat 0 0;
  width: 174px;
  height: 165px;
  margin: 0 auto 0;
  position: relative;
  left: 16px;
`;

const WrongFormatModal = ({ onHide, ...other }) => (
  <Modal centered onHide={onHide} {...other}>
    <Modal.Body>
      <WrongFileImage />
      <Text>
        You can add only one file
      </Text>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-secondary" onClick={onHide}>OK</Button>
    </Modal.Footer>
  </Modal>
);

WrongFormatModal.propTypes = {
  onHide: PropTypes.func,
};

export default WrongFormatModal;
