import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import trashCartImage from '../../../assets/ic-trash-warning.png';
import { Text, Button } from '../styledComponents';

const TrashWarnImg = styled.div`
  background: url(${trashCartImage}) no-repeat 0 0;
  width: 164px;
  height: 182px;
  margin: 0 auto;
  position: relative;
  left: -14px;
`;

const RemoveDocumentModal = ({ onHide, text, ...other }) => (
  <Modal onHide={onHide} {...other}>
    <Modal.Body>
      <TrashWarnImg />
      <Text>
        {text}
      </Text>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={onHide}>OK</Button>
    </Modal.Footer>
  </Modal>
);

RemoveDocumentModal.propTypes = {
  onHide: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default RemoveDocumentModal;
