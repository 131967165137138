import axios from 'axios';

import {
  PARTS_WATCHLIST_GET,
  PARTS_WATCHLIST_GET_SUCCESS,
  PARTS_WATCHLIST_ALL_GET,
  PARTS_WATCHLIST_ALL_GET_SUCCESS,
  PARTS_WATCHLIST_ADD,
  PARTS_WATCHLIST_ADD_SUCCESS,
  PARTS_WATCHLIST_DELETE,
  PARTS_WATCHLIST_DELETE_SUCCESS,
  PARTS_WATCHLIST_FAIL,

  ERRORS,
  fileFormatsInventoryIM,
  maxFileSizePWL,
  MAX_PARTS_WATCHLIST_ITEMS,
} from '../../constants';

import { requestErrorHandler } from '../requestErrorHandler';
import { updateComments } from '../chatActions';
import {
  startUploadingFile,
  finishUploadingFile,
  onUploadFileProgress,
  setUploadingErrorCode,
  uploadingFileErrorHandler,
} from '../fileUploaderActions';

const startFetchingPartsWatchlist = () => ({
  type: PARTS_WATCHLIST_GET,
});
const fetchPartsWatchlistSuccess = (list) => ({
  type: PARTS_WATCHLIST_GET_SUCCESS,
  payload: list,
});

const startFetchingAllParts = () => ({
  type: PARTS_WATCHLIST_ALL_GET,
});
const fetchAllPartsSuccess = (parts) => ({
  type: PARTS_WATCHLIST_ALL_GET_SUCCESS,
  payload: parts,
});

const startAddingPartsWatchlist = () => ({
  type: PARTS_WATCHLIST_ADD,
});
const addPartWatchlistSuccess = (list) => ({
  type: PARTS_WATCHLIST_ADD_SUCCESS,
  payload: list,
});

const startDeletingPartsWatchlist = () => ({
  type: PARTS_WATCHLIST_DELETE,
});
const deletingPartWatchlistSuccess = (list) => ({
  type: PARTS_WATCHLIST_DELETE_SUCCESS,
  payload: list,
});

const partsWatchlistFail = () => ({ type: PARTS_WATCHLIST_FAIL });

export const fetchPartWatchlist = (userId, data) => async (dispatch) => {
  dispatch(startFetchingPartsWatchlist());
  try {
    const response = await axios.post(`rest/V2/eplane/backoffice/users/${userId}/pwl`, data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchPartsWatchlistSuccess(response.data?.payload));
    } else {
      requestErrorHandler(response, dispatch, partsWatchlistFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, partsWatchlistFail, e);
  }
};

export const fetchAllPNs = (userId) => async (dispatch) => {
  dispatch(startFetchingAllParts());
  try {
    const response = await axios.post(`rest/V2/eplane/backoffice/users/${userId}/pwl`, { per_page: MAX_PARTS_WATCHLIST_ITEMS });
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchAllPartsSuccess(response.data?.payload?.watchlist_items.map((item) => item.pn)));
    } else {
      requestErrorHandler(response, dispatch, partsWatchlistFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, partsWatchlistFail, e);
  }
};

export const addPartsWatchlist = (data, userId) => async (dispatch) => {
  dispatch(startAddingPartsWatchlist());
  try {
    const response = await axios.post(`rest/V2/eplane/backoffice/users/${userId}/pwl/add`, data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(addPartWatchlistSuccess(response.data?.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, partsWatchlistFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, partsWatchlistFail, e);
  }
};

export const deletePartsWatchlist = (data, userId) => async (dispatch) => {
  dispatch(startDeletingPartsWatchlist());
  try {
    const response = await axios.post(`rest/V2/eplane/backoffice/users/${userId}/pwl/remove`, data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(deletingPartWatchlistSuccess(response.data?.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, partsWatchlistFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, partsWatchlistFail, e);
  }
};

export const uploadPartWatchlist = (file, userId) => (dispatch) => {
  if (file && fileFormatsInventoryIM.every((mimeType) => mimeType !== file.type)) {
    dispatch(setUploadingErrorCode(ERRORS.ERR_WRONG_FORMAT));
    return;
  }
  if (file && file.size > maxFileSizePWL) {
    dispatch(setUploadingErrorCode(ERRORS.ERR_FILE_IS_TOO_LARGE));
    return;
  }

  const source = axios.CancelToken.source();
  dispatch(startUploadingFile(source));

  const fd = new FormData(); // eslint-disable-line no-undef
  fd.append('user_id', userId);
  fd.append('files', file || null);

  const options = {
    onUploadProgress: (e) => onUploadFileProgress(e, dispatch),
    cancelToken: source.token,
  };

  axios.post('/backoffice/users/uploadPartsWatchList', fd, options).then((response) => {
    dispatch(finishUploadingFile(response.data));
    setTimeout(() => dispatch(fetchPartWatchlist(userId)), 1000);
  }).catch((e) => {
    uploadingFileErrorHandler(e, dispatch);
  });
};
