import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const checkboxStates = {
  CHECKED: 1,
  UNCHECKED: 0,
  INDETERMINATE: -1,
};

const IndeterminateCheckbox = ({ value, ...otherProps }) => {
  const checkRef = useRef();

  useEffect(() => {
    checkRef.current.checked = value === checkboxStates.CHECKED;
    checkRef.current.indeterminate = value === checkboxStates.INDETERMINATE;
  }, [value]);

  return (
    <input
      type="checkbox"
      ref={checkRef}
      {...otherProps}
    />
  );
};

IndeterminateCheckbox.propTypes = {
  value: PropTypes.oneOf([-1, 0, 1]),
};

export default IndeterminateCheckbox;
