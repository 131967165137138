import {
  COMPANIES_GET,
  COMPANIES_GET_SUCCESS,
  ORDERS_SEARCH,
  ORDERS_SEARCH_SUCCESS,
  ORDERS_SEARCH_SHOW_ALL_SUCCESS,

  REQUEST_FOR_DATA_FAIL,
} from '../../constants';

const initialState = {
  lastSearchParams: null,
  totalRecords: 0,
  currItems: [],
  page: 0,
  lastPage: 0,
  isFetching: false,
  isSearching: false,

  companies: null,
};

export default function searchOrders(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case COMPANIES_GET:
      return { ...state, isFetching: true };

    case COMPANIES_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        companies: payload,
      };

    case ORDERS_SEARCH:
      if (payload) {
        return {
          ...state,
          isFetching: true,
          lastSearchParams: payload,
        };
      }
      return { ...state, isFetching: true };

    case ORDERS_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        totalRecords: payload.totalRecords,
        currItems: payload.items,
        page: payload.page,
        isSearching: true,
        lastPage: payload.lastPage,
      };

    case ORDERS_SEARCH_SHOW_ALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        totalRecords: payload.totalRecords,
        isSearching: true,
        currItems: payload.items,
        page: payload.page,
        lastPage: payload.lastPage,
        lastSearchParams: null,
      };

    case REQUEST_FOR_DATA_FAIL:
      return { ...state, isFetching: false };

    default:
      return state;
  }
}
