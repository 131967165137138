import createErrorValidationObj from '../../../utils/createErrorValidationObj';
import emailVal from '../../../utils/emailValidator';

export const emailValidator = (value) => {
  const newValue = value && value.trim();
  if (!emailVal(newValue)) return createErrorValidationObj('Please enter a valid email address');
  return null;
};

export const notEmptyValidatorCreator = (value) => {
  if (!value.trim()) return createErrorValidationObj('Please enter a company name');
  return null;
};
