import {
  USER_CUSTOM_WEIGHTS_GET,
  USER_CUSTOM_WEIGHTS_GET_SUCCESS,

  USER_CUSTOM_WEIGHTS_SAVE,
  USER_CUSTOM_WEIGHTS_SAVE_SUCCESS,

  USER_ATTACHED_LISTS_GET,
  USER_ATTACHED_LISTS_GET_SUCCESS,

  WEIGHT_GROUP_ATTACH_DETACH,
  WEIGHT_GROUP_ATTACH_SUCCESS,
  WEIGHT_GROUP_DETACH_SUCCESS,

  USER_CUSTOM_WEIGHTS_FAIL,
} from '../../constants';

const initialState = {
  customWeights: [],
  attachedLists: [],
  isFetching: false,
  isProcessing: false,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case USER_CUSTOM_WEIGHTS_GET:
      return {
        ...state,
        isFetching: true,
      };
    case USER_CUSTOM_WEIGHTS_GET_SUCCESS:
      return {
        ...state,
        customWeights: payload,
        isFetching: false,
      };
    case USER_CUSTOM_WEIGHTS_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case USER_CUSTOM_WEIGHTS_SAVE_SUCCESS:
      return {
        ...state,
        customWeights: payload,
        isProcessing: false,
      };
    case USER_CUSTOM_WEIGHTS_FAIL:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
      };

    case WEIGHT_GROUP_ATTACH_DETACH:
      return {
        ...state,
        isProcessing: true,
      };
    case WEIGHT_GROUP_ATTACH_SUCCESS:
      return {
        ...state,
        attachedLists: [...payload],
        isProcessing: false,
      };
    case WEIGHT_GROUP_DETACH_SUCCESS:
      return {
        ...state,
        attachedLists: [...payload],
        isProcessing: false,
      };

    case USER_ATTACHED_LISTS_GET:
      return {
        ...state,
        isFetching: true,
      };
    case USER_ATTACHED_LISTS_GET_SUCCESS:
      return {
        ...state,
        attachedLists: payload,
        isFetching: false,
      };

    default: return state;
  }
};
