import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { color } from '../../../constants';
import { syncStatus } from '../../../constants/mailchimp';
import dateFormat from '../../../utils/dateFormat';

const Container = styled.div`
  margin-right: 30px;
`;
const DisabledText = styled.span`
  font-size: 16px;
  color: ${color.borderLightGrey};
`;

const LastSyncStatus = ({ status, date, errorMessage }) => (
  <Container>
    <div>
      <DisabledText>Last sync status:</DisabledText>
      &nbsp;
      <DisabledText>{getSyncStatus(status)}</DisabledText>
      &nbsp;
      <DisabledText>{status === syncStatus.STATUS_ERROR ? errorMessage : status}</DisabledText>
    </div>
    <div>
      <DisabledText>
        {date !== 'never' ? `Sync on: ${dateFormat(date)}` : 'Sync on: never'}
      </DisabledText>
    </div>
  </Container>
);

const getSyncStatus = (status) => {
  switch (status) {
    case syncStatus.STATUS_DISABLED:
      return <FontAwesomeIcon icon="ban" color={color.inputBorder} />;

    case syncStatus.STATUS_PROGRESS:
      return <FontAwesomeIcon icon={['far', 'clock']} color={color.inputBorder} />;

    case syncStatus.STATUS_OK:
      return <FontAwesomeIcon icon="check" color={color.textGreen} />;

    case syncStatus.STATUS_ERROR:
      return <FontAwesomeIcon icon="exclamation-triangle" color={color.textRed} />;

    default: return null;
  }
};

LastSyncStatus.propTypes = {
  date: PropTypes.string,
  status: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default LastSyncStatus;
