import {
  USER_SETTINGS_FETCH,
  USER_SETTINGS_IS_FETCHED,
  USER_SETTINGS_FATAL_ERROR_OCCURRED,
  USER_SETTINGS_SAVE,
  USER_SETTINGS_IS_SAVED,
  USER_SETTINGS_SAVE_ERROR,
  USER_SETTINGS_ERROR_REMOVE,
  USER_SETTINGS_EDIT_MODE_TOGGLE,
} from '../../constants';

// TODO: initial state is temporary, data should be replaced by null
const initialState = {
  data: null,
  isFetching: false,
  isSaving: false,
  savingErrorCode: null,
  editMode: false,
  // fatalErrorCode field indicates that smth dangerous has happened
  // In this case the system should prevent the user from doing any action until he reload the whole page
  fatalErrorCode: null,
};

const userSettingsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_SETTINGS_FETCH: return {
      ...state,
      isFetching: true,
    };

    case USER_SETTINGS_IS_FETCHED: return {
      ...state,
      isFetching: false,
      data: payload,
      editMode: false,
    };

    case USER_SETTINGS_SAVE: return {
      ...state,
      isSaving: true,
    };

    case USER_SETTINGS_IS_SAVED: return {
      ...state,
      isSaving: false,
      editMode: false,
      data: payload,
    };

    case USER_SETTINGS_SAVE_ERROR: return {
      ...state,
      savingErrorCode: payload,
      isSaving: false,
    };

    case USER_SETTINGS_ERROR_REMOVE: return {
      ...state,
      savingErrorCode: null,
    };

    case USER_SETTINGS_FATAL_ERROR_OCCURRED: return {
      ...state,
      fatalErrorCode: payload,
    };

    case USER_SETTINGS_EDIT_MODE_TOGGLE: return {
      ...state,
      editMode: !state.editMode,
    };

    default: return state;
  }
};

export default userSettingsReducer;
