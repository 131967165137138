import axios from 'axios';

import {
  TASKS_LIST_GET,
  TASKS_LIST_GET_SUCCESS,

  TASK_START,
  TASK_START_SUCCESS,

  TASK_CLOSE,
  TASK_CLOSE_SUCCESS,

  TASK_REOPEN,
  TASK_REOPEN_SUCCESS,

  TASK_STEP_FORWARD,
  TASK_STEP_FORWARD_SUCCESS,

  TASK_DATA_FETCH,
  TASK_DATA_FETCH_SUCCESS,

  NOC_SECTION_FAIL,

  TASK_SET_VIEWED,
  SET_TASK_FETCHING_PARAMS,
  DEFAULT_TASK_AMOUNT_PER_PAGE,

  ERRORS,
} from '../../constants';
import { startFetchingData, finishFetchingData } from '../OrderManagement/chatDataActions';
import { requestErrorHandler } from '../requestErrorHandler';

const startFetchTasks = () => ({ type: TASKS_LIST_GET });
const fetchTasksSuccess = (LIST) => ({
  type: TASKS_LIST_GET_SUCCESS,
  payload: LIST,
});

const startingTask = () => ({ type: TASK_START });
const startTaskSuccess = (updatedTask) => ({
  type: TASK_START_SUCCESS,
  payload: updatedTask,
});

const startCloseTask = () => ({ type: TASK_CLOSE });
const closeTaskSuccess = (updatedTask) => ({
  type: TASK_CLOSE_SUCCESS,
  payload: updatedTask,
});

const startStepForward = () => ({ type: TASK_STEP_FORWARD });
const stepForwardSuccess = (updatedTask) => ({
  type: TASK_STEP_FORWARD_SUCCESS,
  payload: updatedTask,
});

const startReopenTask = () => ({ type: TASK_REOPEN });
const reopenTaskSuccess = (updatedTask) => ({
  type: TASK_REOPEN_SUCCESS,
  payload: updatedTask,
});

const startFetchTaskData = () => ({ type: TASK_DATA_FETCH });
const fetchTaskDataSuccess = (ORDER) => ({
  type: TASK_DATA_FETCH_SUCCESS,
  payload: ORDER,
});

const nocSectionFail = () => ({ type: NOC_SECTION_FAIL });

const setTaskViewed = (taskId) => ({
  type: TASK_SET_VIEWED,
  payload: taskId,
});

const changeTaskFetchingparams = (params) => ({
  type: SET_TASK_FETCHING_PARAMS,
  payload: params,
});

export const setTaskFetchingPrams = (params) => (dispatch) => {
  dispatch(changeTaskFetchingparams(params));
};

export const fetchTasksList = (params) => async (dispatch) => {
  dispatch(startFetchTasks());
  const reqParams = params || { c: DEFAULT_TASK_AMOUNT_PER_PAGE };
  if (!params || !params.c) {
    reqParams.c = DEFAULT_TASK_AMOUNT_PER_PAGE;
  }
  try {
    const response = await axios('/rest/default/V2/eplane/backoffice/tasks', { params: reqParams });
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchTasksSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, nocSectionFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, nocSectionFail, e);
  }
};

export const fetchPaymentsTasksList = (params) => async (dispatch) => {
  dispatch(startFetchTasks());
  const reqParams = params || { c: DEFAULT_TASK_AMOUNT_PER_PAGE };
  if (!params || !params.c) {
    reqParams.c = DEFAULT_TASK_AMOUNT_PER_PAGE;
  }
  try {
    const response = await axios('/rest/V2/eplane/backoffice/payment_tasks', { params: reqParams });
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchTasksSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, nocSectionFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, nocSectionFail, e);
  }
};

export const startTask = (taskId) => async (dispatch) => {
  dispatch(startingTask());
  try {
    const response = await axios.post(`/rest/default/V2/eplane/backoffice/task/${taskId}/start`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(startTaskSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, nocSectionFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, nocSectionFail, e);
  }
};

export const closeTask = (taskId) => async (dispatch) => {
  dispatch(startCloseTask());
  try {
    const response = await axios.post(`/rest/default/V2/eplane/backoffice/task/${taskId}/close`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(closeTaskSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, nocSectionFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, nocSectionFail, e);
  }
};

export const reopenTask = (taskId) => async (dispatch) => {
  dispatch(startReopenTask());
  try {
    const response = await axios.post(`/rest/default/V2/eplane/backoffice/task/${taskId}/reopen`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(reopenTaskSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, nocSectionFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, nocSectionFail, e);
  }
};

export const fetchTaskData = (taskId) => async (dispatch) => {
  dispatch(startFetchTaskData());
  dispatch(startFetchingData());
  try {
    const response = await axios(`/rest/default/V2/eplane/backoffice/task/${taskId}/info`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(finishFetchingData(response.data && response.data.payload.rfq));
      dispatch(fetchTaskDataSuccess(response.data && response.data.payload));
      dispatch(setTaskViewed(taskId));
    } else {
      requestErrorHandler(response, dispatch, nocSectionFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, nocSectionFail, e);
  }
};

export const stepForwardTask = (taskId) => async (dispatch) => {
  dispatch(startStepForward());
  try {
    const response = await axios.post(`/rest/default/V2/eplane/backoffice/task/${taskId}/close`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(stepForwardSuccess(response.data?.payload));
    } else {
      requestErrorHandler(response, dispatch, nocSectionFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, nocSectionFail, e);
  }
};
