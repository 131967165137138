import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ButtonsWrapper, Button, Text, Body,
} from '../styledComponents';

const Icon = styled.div`
  text-align: center;
`;

const DisconnectModal = ({
  companyName, onHide, onConfirm, ...other
}) => (
  <Modal centered onHide={onHide} {...other}>
    <Body>
      <br />
      <Icon><FontAwesomeIcon icon="unlink" size="2x" /></Icon>
      <br />
      <Text>Disconnect Stripe account</Text>
      <br />
      <p>
        Are you sure you wish to disconnect&nbsp;
        {companyName}
        &apos;s Stripe account?
      </p>
    </Body>
    <Modal.Footer>
      <ButtonsWrapper>
        <Button variant="primary" onClick={onConfirm}>Disconnect</Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
      </ButtonsWrapper>
    </Modal.Footer>
  </Modal>
);

DisconnectModal.propTypes = {
  companyName: PropTypes.string,
  onHide: PropTypes.func,
  onCommissionChange: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default DisconnectModal;
