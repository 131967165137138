import React from 'react';
import { Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { getToastsStore } from '../../selectors/index';
import { deleteToast } from '../../actions/toastsActions';

import { color, TOASTS_MAX_COUNT } from '../../constants';

const Container = styled.div`
  position: fixed;
  top: 70px;
  right: 20px;
  z-index: 9999;
`;
const StyledToast = styled(Toast)`
  font-size: 1.1em;
`;

export default function ToastsContainer() {
  const dispatch = useDispatch();

  const { toasts: toastsData } = useSelector(getToastsStore);

  return (
    <Container>
      {toastsData.map((toast, index) => (
        index < TOASTS_MAX_COUNT && (
          <StyledToast
            show
            key={toast.id}
            delay={toast.delay}
            autohide={toast.delay > 0}
            onClose={() => dispatch(deleteToast(toast.id))}
          >
            <Toast.Header>
              <FontAwesomeIcon icon="bell" color={color.birch} className="mr-2" />
              <strong className="mr-2">{toast.title}</strong>
              <small>{toast.date}</small>
            </Toast.Header>
            <Toast.Body>{toast.message}</Toast.Body>
          </StyledToast>
        )
      ))}
    </Container>
  );
}
