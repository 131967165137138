import { combineReducers } from 'redux';

import documents from './documents';
import sites from './sites';
import proManagement from './proManagement';

export default combineReducers({
  documents,
  sites,
  proManagement,
});
