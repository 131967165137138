import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { color } from '../../constants';
import './RequestDetails.css';
import A from '../A';
import VerificationMark from './VerificationMark';
import SimpleLoaderNormal from '../SimpleLoader';

const SimpleLoader = styled(SimpleLoaderNormal)`
  display: inline-block;
  margin-bottom: -12px;
  margin-left: 20px;
`;

const spanStyle = {
  marginLeft: '100px',
  color: '#0bb9e2',
  fontSize: '80%',
  textDecoration: 'underline',
  fontWeight: '600',
  cursor: 'pointer',
};

const RequestStatus = styled.span`
  color: ${color.grey};
  padding-left: 5px;
  font-style: italic;
`;

export default class RequestDetails extends React.PureComponent {
  mapFileObjectToLink = (fileObj) => (
    <span key={fileObj.url}>
      <A target="_blank" rel="noopener noreferrer" to={fileObj.url}>
        {fileObj.file_name}
      </A>
      {' '}
    </span>
  );

  simulateClick = () => {
    this.span.click();
  }

  render() {
    const {
      user_id: userId,
      isPro,
      reqNo,
      userName,
      userEmail,
      customers: customersV,
      companyName,
      companyEmail,
      userFiles,
      headquarters,
      companyType,
      companyContact,
      phoneNumber,
      contactPhone,
      vatTaxNumber,
      reqResult,
      emailIsVerified,
      companyWebsite,
      statusResult,
      boUserComment,
      boAdminEmail,
      decisionIsBeingProcessed,
      constants,
    } = this.props;
    const customers = customersV || [];
    const isCompanyCustomers = !!customersV;
    let result;
    let status;
    let files;
    let websiteContent;
    let contactPhoneContent;
    let proContent;

    if (!userFiles) files = null;
    else if (Array.isArray(userFiles)) {
      files = userFiles.map(this.mapFileObjectToLink);
    } else {
      const fileList = Object.values(userFiles);
      files = fileList && fileList.map(this.mapFileObjectToLink);
    }

    if (constants) {
      const crrResultArr = Object.values(constants.crrResult);
      const userReqResultObj = crrResultArr.find((res) => res.id === reqResult);
      result = (
        <tr className="Result">
          <td>
            {userReqResultObj && userReqResultObj.name}
          </td>
        </tr>
      );
      status = getStatusContent(statusResult, constants.crrResult, decisionIsBeingProcessed);
    }

    if (companyWebsite) {
      websiteContent = (
        <tr>
          <td>Company website</td>
          <td>{companyWebsite}</td>
        </tr>
      );
    }

    if (contactPhone) {
      contactPhoneContent = (
        <tr>
          <td>Phone number:</td>
          <td>{contactPhone}</td>
        </tr>
      );
    }

    if (isPro) {
      proContent = (
        <tr>
          <td>Pro Plan Info:</td>
          <td>The user has a PRO subscription</td>
        </tr>
      );
    }

    const listCustomers = customers.map((elem) => (
      <p key={elem.email}>
        <a target="_blank" rel="noopener noreferrer" href={`/userManagement/${userId}/${elem.entity_id}`}>{elem.email}</a>
      </p>
    ));
    const tooltip = (
      <Tooltip id="tooltip" bsPrefix="customers-company-links-tooltip tooltip">
        <div>
          {/* TODO: resolve ESLINT issues */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid */}
          <a onClick={this.simulateClick} className="close-x" role="button" tabIndex="-1">
            <FontAwesomeIcon icon="times" />
          </a>
          {listCustomers}
        </div>
      </Tooltip>
    );

    return (
      <div className="Requests-details">
        <table className="Requests-details">
          <tbody>
            <tr>
              <th>
                {`REQ-${reqNo}`}
                &nbsp;
                {status}
              </th>
            </tr>
            {getCommentContent(boAdminEmail, boUserComment)}
            {result}
            <tr>
              <td>User:</td>
              <td>{userName}</td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>
                {userEmail}
                &nbsp;
                <VerificationMark isVerified={emailIsVerified} />
              </td>
            </tr>
            {proContent}
            {contactPhoneContent}
            <tr className="Files">
              <td>Files:</td>
              <td>{files}</td>
            </tr>
            <tr className="Company-data">
              <td>Company:</td>
              <td>
                {companyName}
                {isCompanyCustomers && (
                <OverlayTrigger trigger={['click']} placement="right" overlay={tooltip} rootClose>
                  <span style={spanStyle} ref={(span) => { this.span = span; }}>View company users</span>
                </OverlayTrigger>
                )}
              </td>
            </tr>
            <tr>
              <td>Company type:</td>
              <td>{companyType}</td>
            </tr>
            <tr>
              <td>Company contact:</td>
              <td>{companyContact}</td>
            </tr>
            <tr>
              <td>Company email:</td>
              <td>{companyEmail}</td>
            </tr>
            <tr>
              <td>Headquarters:</td>
              <td>{headquarters}</td>
            </tr>
            <tr>
              <td>Company phone:</td>
              <td>{phoneNumber}</td>
            </tr>
            {websiteContent}
            <tr>
              <td>VAT/TAX no.:</td>
              <td>{vatTaxNumber}</td>
            </tr>
          </tbody>
        </table>

      </div>
    );
  }
}

RequestDetails.propTypes = {
  user_id: PropTypes.string,
  isPro: PropTypes.bool,
  reqNo: PropTypes.string,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  userFiles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  companyName: PropTypes.string,
  companyType: PropTypes.string,
  companyContact: PropTypes.string,
  companyEmail: PropTypes.string,
  headquarters: PropTypes.string,
  phoneNumber: PropTypes.string,
  contactPhone: PropTypes.string,
  vatTaxNumber: PropTypes.string,
  reqResult: PropTypes.number,
  emailIsVerified: PropTypes.bool,
  companyWebsite: PropTypes.string,
  statusResult: PropTypes.number,
  boUserComment: PropTypes.string,
  boAdminEmail: PropTypes.string,
  customers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  decisionIsBeingProcessed: PropTypes.bool,
  constants: PropTypes.shape({
    crrResult: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

RequestDetails.defaultProps = {
  boAdminEmail: 'Backoffice admin',
};

const getCommentContent = (email, comment) => {
  if (comment) {
    return (
      <tr>
        <td colSpan="2">
          <strong>{`${email} comment:`}</strong>
          &nbsp;
          {comment}
        </td>
      </tr>
    );
  }
  return null;
};

const getStatusContent = (statusId, statusConsts, isLoading) => {
  if (isLoading) return <SimpleLoader />;
  switch (statusId) {
    case statusConsts.INPROGRESS.id: return <RequestStatus>IN PROGRESS</RequestStatus>;
    case statusConsts.ONHOLD.id: return <RequestStatus>ON HOLD</RequestStatus>;
    case statusConsts.PENDING.id: return <RequestStatus>OPEN</RequestStatus>;
    default: return null;
  }
};
