import React from 'react';
import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';

import DevEmails from '../../components/Dev/DevEmailsPage';
import DevLogs from '../../components/Dev/DevLogs';
import BounceEmailsPage from '../../components/Dev/BounceEmail';

export default () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Redirect exact from={match.path} to={match.url[match.url.length - 1] === '/' ? `${match.url}emails` : `${match.url}/emails`} />
      <Route path={`${match.path}/emails`}><DevEmails /></Route>
      <Route path={`${match.path}/logs`}><DevLogs /></Route>
      <Route path={`${match.path}/bounceEmails`}><BounceEmailsPage /></Route>
    </Switch>
  );
};
