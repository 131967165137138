import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Container } from 'react-bootstrap';

import { ButtonsWrapper, Button, Text as Title } from '../../Modals/styledComponents';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import newPartNumberValidator from './newPartNumberValidator';

const Text = styled.p`
  text-align: center;
`;

export default function AddPartNumberModal({
  userId, onHide, onConfirm, ...other
}) {
  const [partNumber, setPartNumber] = useState('');
  const [validation, setValidation] = useState(null);

  useEffect(() => {
    if (!other.show) {
      setPartNumber('');
      setValidation(null);
    }
  }, [other.show]);

  const onConfirmHandler = () => {
    onConfirm(partNumber);
    setPartNumber('');
    setValidation(null);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setPartNumber(value.trim());
    newPartNumberValidator(value.trim(), userId).then((errObj) => setValidation(errObj));
  };

  return (
    <Modal centered onHide={onHide} {...other}>
      <Modal.Body>
        <Title>Add Part Number</Title>
        <br />
        <Text>
          The part number will be added to your Parts Watchlist.
          You will be notified by email when new matching items are added to ePlane&apos;s inventory.
        </Text>
        <Container>
          <BaseFormGroup
            value={partNumber}
            name="part_number"
            type="text"
            placeholder="Enter PN"
            onChange={onChange}
            maxLength={35}
            {...validation}
          />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsWrapper>
          <Button
            variant="primary"
            onClick={onConfirmHandler}
            disabled={!!validation || !partNumber}
          >
            Add to Watchlist
          </Button>
          <Button variant="outline-secondary" onClick={onHide}>Cancel</Button>
        </ButtonsWrapper>
      </Modal.Footer>
    </Modal>
  );
}

AddPartNumberModal.propTypes = {
  userId: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
