import createErrorValidationObj from '../../../utils/createErrorValidationObj';

// eslint-disable-next-line import/prefer-default-export
export const listNameValidator = (value, listNames) => {
  if (!value.trim()) return createErrorValidationObj('List name required');
  if (listNames.some((v) => value.toLowerCase().localeCompare(v.toLowerCase()) === 0)) {
    return createErrorValidationObj(`${value} already exists`);
  }
  return null;
};

export const editListNameValidator = (value, currentListName, listNames) => {
  if (!value.trim()) return createErrorValidationObj('List name required');
  if (value.toLowerCase().localeCompare(currentListName.toLowerCase()) !== 0
    && listNames.some((v) => value.toLowerCase().localeCompare(v.toLowerCase()) === 0)) {
    return createErrorValidationObj(`${value} already exists`);
  }
  return null;
};
