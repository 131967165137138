import moment from 'moment';

/**
 * function return a string of time period in day/hour format
 * @param dateLine
 * @param currDate - current date in unixtime
 * @return {{}}
 */
export default function dateIntervalToString(dateLine = null, currDate = new Date().getTime()) {
  return moment.utc(dateLine).from(currDate, true);
}
