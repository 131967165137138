import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import AutosuggestControl from '../../../FormComponents/AutosuggestControl';
import createErrorValidationObj from '../../../../utils/createErrorValidationObj';

export default class IATACodeInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
    };
    this.lastSuggestionList = [];
  }

  onBlur = (e) => {
    const { onValidationStateChange } = this.props;
    const { value } = e.target;
    const { lastSuggestionList } = this;

    if (!value) {
      onValidationStateChange(null);
    } else if (value.length < 3) onValidationStateChange(createErrorValidationObj('Unrecognized airport, please check and re-enter'));
    else {
      const isValid = lastSuggestionList.some((suggest) => suggest.title === value);

      if (isValid) onValidationStateChange(null);
      else onValidationStateChange(createErrorValidationObj('Unrecognized airport, please check and re-enter'));
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    axios(`/suggest/iata/?q=${value}&max_results=10`).then((response) => {
      const { data } = response;

      this.lastSuggestionList = data?.payload?.items;
      this.setState({
        suggestions: data?.payload?.items.slice(0, 10),
      });
    }).catch((e) => console.error(e)); // eslint-disable-line no-console
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  }

  getSuggestionValue = (suggestion) => suggestion.title;

  renderSuggestion = (suggestion) => <span>{suggestion.title}</span>;

  render() {
    const { suggestions } = this.state;
    const {
      onValidationStateChange,
      ...other
    } = this.props;

    return (
      <AutosuggestControl
        horizontal
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        maxLength={3}
        {...other}
        onBlur={this.onBlur}
      />
    );
  }
}

IATACodeInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  labelText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  validationState: PropTypes.string,
  onValidationStateChange: PropTypes.func.isRequired,
};
