import {
  INVENTORY_LIST_FETCH,
  INVENTORY_LIST_IS_FETCHED,
  INVENTORY_LIST_FAIL,
} from '../../constants';

const initialState = {
  inventoryList: {},
  isFetching: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case INVENTORY_LIST_FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case INVENTORY_LIST_IS_FETCHED:
      return {
        ...state,
        inventoryList: payload,
        isFetching: false,
      };

    case INVENTORY_LIST_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    default: return state;
  }
};
