import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import {
  getUserStore,
  getIsSavingUMStore,
  getIsFetchingUMStore,
  getCustomerStore,
} from '../../../selectors';
import { fetchUser, clearUserStore, checkCustomer } from '../../../actions/userManagement/userActions';
import { fetchProSub, clearProSub } from '../../../actions/userCompanyManagement/proManagement';
import { clearCustomerState } from '../../../actions/userManagement/customerActions';

import SideNavigation from '../../SideNavigation';
import Container from '../../Container';
import {
  color, USER_TYPE_COMPANY, PUBLIC_URL, ERRORS,
} from '../../../constants';
import Loader from '../../Loader';
import ModalLoader from '../../ModalLoader';
import NotFoundPage from '../../NotFoundPage';
import TabsUM from './TabsUM';
import dateFormat from '../../../utils/dateFormat';
import CommentsWidget from '../../CommentsWidget';
import * as serverConstants from '../../../constants/index';

const MenuWrapper = styled(Col)`
  border-right: 2px solid ${color.lightGrey};
  margin-bottom: 10px;
  padding-bottom: 10px;
  `;

const RowContent = styled(Row)`
  margin-top: 30px;
`;
const Red = styled.div`
  color: ${color.textRed};
  font-weight: bold;
  font-size: 19px;
  word-break: break-all;
`;
const Content = styled(Col)`
  && {
  padding-left: 45px;
  clear: right;
  display: flex;
  flex-direction: column;
  }
`;

const mapRoutesToTitles = {
  company: {
    accountSettings: 'Account Settings',
    associateUser: 'Associate with Company',
    notificationPreferences: {
      tabHeader: 'Notification Preferences ',
      mapRoutes: {
        rfqFilters: 'RFQ Filters',
        notifications: 'Updates & reports',
      },
    },
  },
  private: {
    accountSettings: 'Account Settings',
    companyDetails: 'Register as Company',
    associateUser: 'Associate with Company',
    commercialAgreement: 'Commercial agreements',
    notificationPreferences: {
      tabHeader: 'Notification Preferences ',
      mapRoutes: {
        rfqFilters: 'RFQ Filters',
        notifications: 'Updates & reports',
      },
    },
    payments: 'Payments Settings',
    partsWatchlist: 'Parts Watchlist',
    pro: {
      tabHeader: 'Pro',
      mapRoutes: {
        proManagement: 'Pro Management',
        proFeatures: 'Pro Features',
      },
    },
  },
};

const EditUserPage = () => {
  const dispatch = useDispatch();
  const { userId, customerId } = useParams();
  const location = useLocation();

  useEffect(() => {
    batch(() => {
      dispatch(fetchUser(userId));
      dispatch(fetchProSub(userId));
      dispatch(checkCustomer(userId, customerId));
    });
    return () => {
      batch(() => {
        dispatch(clearUserStore());
        dispatch(clearProSub());
        dispatch(clearCustomerState());
      });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchUser(userId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname]);

  const { customer } = useSelector(getCustomerStore);
  const {
    user,
    isCheckingCustomer,
    isCustomerChecked,
    errorCode: userErrorCode,
  } = useSelector(getUserStore);
  const isSaving = useSelector(getIsSavingUMStore);
  const isFetchingUM = useSelector(getIsFetchingUMStore);

  const contactEmail = customer?.email;
  const contactName = customer && `${customer.firstname || ''} ${customer.lastname || ''}`;
  const companyName = user && user.company_name;

  const isCompany = user && user.user_type === USER_TYPE_COMPANY;
  const linksObj = isCompany ? mapRoutesToTitles.company : mapRoutesToTitles.private;
  const isVerified = customer?.email_status_indication === serverConstants.customerEmailStatus.VERIFIED;
  const isExternal = user?.is_external;
  const urlPrefix = `${PUBLIC_URL}/userManagement/${userId}/${customerId}/`;

  if (!isCustomerChecked && !isCheckingCustomer && !userErrorCode) {
    // To prevent rendering children before verification of the URL starts
    return null;
  }

  if (isCheckingCustomer) return <Loader />;
  if (userErrorCode === ERRORS.ERR_ENTITY_NOT_FOUND) {
    return <NotFoundPage />;
  }

  const navLinks = [
    { 'User Management': 'userManagement' },
    companyName && { [companyName]: `companies/${userId}` },
    contactEmail && { [contactEmail]: null },
    contactName && { [contactName]: null },
  ].filter((v) => v);

  return (
    <Container links={navLinks}>
      {/* TODO: move loader to tabs */}
      <ModalLoader show={isSaving} />

      <RowContent>
        <MenuWrapper sm={3}>
          <SideNavigation
            routes={linksObj}
            urlPrefix={urlPrefix}
            currentPath={location.pathname}
          />
        </MenuWrapper>
        <Content sm={9}>
          <div>
            <InactiveInfo customer={customer} constants={serverConstants} />
            {!!user && <TabsUM isCompany={isCompany} isVerified={isVerified} isExternal={isExternal} />}
          </div>
          {isFetchingUM && <Loader />}
        </Content>
      </RowContent>
      <CommentsWidget />
    </Container>
  );
};

export default EditUserPage;

const InactiveInfo = (props) => {
  const { customer, constants } = props;
  if (!customer || !constants) return null;
  const { customerStatus } = constants;

  if (customer.customer_status === customerStatus.DELETED.id) {
    const [deleteDetails] = customer.delete_details;
    const adminMail = customer.admin_customer_email;

    return (
      <div>
        <Red>
          Deleted on&nbsp;
          {dateFormat(customer.deleted_at)}
          &nbsp;by&nbsp;
          <a href={`mailto:${adminMail}`}>
            {adminMail}
          </a>
        </Red>
        <Red>
          Reason:&nbsp;
          {deleteDetails && deleteDetails.comment}
        </Red>
      </div>
    );
  } return null;
};

InactiveInfo.propTypes = {
  customer: PropTypes.shape({
    customer_status: PropTypes.number,
    deleted_at: PropTypes.string,
    admin_customer_email: PropTypes.string,
    delete_details: PropTypes.arrayOf(PropTypes.object),
  }),
  constants: PropTypes.shape({
    customerStatus: PropTypes.shape({
      DELETED: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  }),
};
