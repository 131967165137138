import React from 'react';
import {
  Row, Col, Form, FormGroup,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import renderValidation from '../renderHelpBlock';
import RadioGroup from '../../RadioGroupBasic/index';
import * as consts from '../../../constants';

export default function FormRadioGroup({
  id,
  validationState,
  labelText,
  helpText,
  feedBack,
  labelColumnWidth,
  controlColumnWidth,
  ...other
}) {
  return (
    <FormGroup as={Row} controlId={id}>
      <Form.Label column sm={labelColumnWidth}>
        {labelText}
      </Form.Label>
      <Col sm={controlColumnWidth}>
        <RadioGroup validationState={validationState} {...other} />
        {renderValidation(helpText, validationState)}
      </Col>
    </FormGroup>
  );
}

const valuePT = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

FormRadioGroup.propTypes = {
  id: PropTypes.string,
  validationState: PropTypes.oneOf(['success', 'warning', 'error', null]),
  value: PropTypes.string,
  helpText: PropTypes.string,
  feedBack: PropTypes.bool,
  labelColumnWidth: PropTypes.number,
  controlColumnWidth: PropTypes.number,
  labelText: PropTypes.string,

  // radio boxes props
  radioList: PropTypes.arrayOf(PropTypes.shape({
    value: valuePT,
    label: PropTypes.string,
  })),
  checkedValue: valuePT,
  name: PropTypes.string,
};

FormRadioGroup.defaultProps = {
  feedBack: true,
  labelColumnWidth: consts.formOptions.labelColumnWidth,
  controlColumnWidth: consts.formOptions.controlColumnWidth,
};
