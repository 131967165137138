import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, FormGroup } from 'react-bootstrap';
import Select from 'react-select';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import FormTitleSection from '../../FormComponents/TitleSection/index';
import { TabContainer } from '../../StyledComponents';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import ModalLoader from '../../ModalLoader';
import { getBounceEmailsStore } from '../../../selectors';
import fetchBounceEmails from '../../../actions/dev/bounceEmailActions';
import PaginationTable from '../../OrderManagement/SearchOrders/PaginationTable';
import BounceEmailsTable from './BounceEmailsTable';
import DatesPicker from '../../DatesPicker';
import { color } from '../../../constants';

const Container = styled.div`
  max-width: 500px;
  margin: 20px auto;
  padding: 10px;
  border-radius: 5px;
  background-color: ${color.altoGrey};
`;
const FormGroupCentered = styled(FormGroup)`
  display: flex;
  justify-content: center;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
const DropdownContainer = styled.div`
  margin-bottom: 15px;
`;

const BounceEmailsPage = () => {
  const [search, setSearch] = useState('');
  const [range, setRange] = useState({ startDate: null, endDate: null });
  const [chosenFilters, setFilters] = useState({ reason: null, severity: null });
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {
    items, filters, page, totalPages, isFetching,
  } = useSelector(getBounceEmailsStore);

  const severityOptions = useMemo(
    () => filters && filters.severity.map((v) => ({ label: v, value: v })),
    [filters],
  );
  const reasonOptions = useMemo(
    () => filters && filters.reason.map((v) => ({ label: v, value: v })),
    [filters],
  );

  useEffect(() => {
    const currQuery = queryString.parse(location.search);
    dispatch(fetchBounceEmails(currQuery));
  }, [location, dispatch]);

  const onChange = useCallback((e) => setSearch(e.target.value), []);

  const onPageChanged = (nextPage) => {
    const query = queryString.parse(location.search);
    query.page = nextPage;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(query),
    });
  };

  const onSearchClick = () => {
    const query = queryString.parse(location.search);
    query.query = search;
    query.from = range.startDate && range.startDate.format('YYYY-MM-DD');
    query.to = range.endDate && moment(range.endDate).add(1, 'days').format('YYYY-MM-DD');
    query.reason = chosenFilters.reason && chosenFilters.reason.value;
    query.severity = chosenFilters.severity && chosenFilters.severity.value;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(query),
    });
  };

  const onCalendarChange = ({ startDate, endDate }) => {
    setRange({ startDate, endDate });
  };

  const onFiltersChange = (selectedOption, action) => {
    setFilters({ ...chosenFilters, [action.name]: selectedOption });
  };

  const onReset = () => {
    setSearch('');
    setRange({ startDate: null, endDate: null });
    setFilters({ reason: null, severity: null });
    history.push({
      pathname: location.pathname,
      search: '',
    });
  };

  return (
    <>
      <FormTitleSection title="Bounce Emails" showEditButton={false} />
      <TabContainer>
        <Container>
          <BaseFormGroup
            placeholder="Search..."
            name="search"
            value={search}
            onChange={onChange}
          />
          <DropdownContainer>
            <Select
              value={chosenFilters.severity}
              name="severity"
              placeholder="Choose severity..."
              onChange={onFiltersChange}
              options={severityOptions}
              isClearable
            />
          </DropdownContainer>
          <DropdownContainer>
            <Select
              value={chosenFilters.reason}
              name="reason"
              placeholder="Choose reason..."
              onChange={onFiltersChange}
              options={reasonOptions}
              isClearable
            />
          </DropdownContainer>
          <FormGroupCentered>
            <DatesPicker
              startDate={range.startDate}
              endDate={range.endDate}
              onChange={onCalendarChange}
              minimumNights={0}
              numberOfMonths={2}
              readOnly
              isShownDatePresets
            />
          </FormGroupCentered>
          <ButtonsContainer>
            <Button onClick={onSearchClick} variant="primary">
              <FontAwesomeIcon name="search" icon="search" />
              &nbsp;Search
            </Button>
            <Button onClick={onReset}>
              <FontAwesomeIcon name="reset" icon="undo-alt" />
            </Button>
          </ButtonsContainer>
        </Container>
        <br />
        <BounceEmailsTable data={items} />
        {totalPages > 1 && <PaginationTable onChange={onPageChanged} currentPage={page} totalPages={totalPages} />}

        <ModalLoader show={isFetching} />
      </TabContainer>
    </>
  );
};

export default BounceEmailsPage;
