import { combineReducers } from 'redux';
import incomingRequests from './incomingRequests';
import closedRequests from './closedRequests';
import requestData from './requestData';

export default combineReducers({
  incomingRequests,
  closedRequests,
  requestData,
});
