import axios from 'axios';

import {
  USER_REPOSITORY_STATS_GET,
  USER_REPOSITORY_STATS_GET_FAIL,
  USER_REPOSITORY_STATS_GET_SUCCESS,

  USER_REPOSITORY_DAILY_STATUS_GET,
  USER_REPOSITORY_DAILY_STATUS_FAIL,
  USER_REPOSITORY_DAILY_STATUS_SUCCESS,

  USER_REPOSITORY_UNPUBLISH,
  USER_REPOSITORY_UNPUBLISH_SUCCESS,
  USER_REPOSITORY_UNPUBLISH_FAIL,

  USER_REPOSITORY_ERROR_REMOVE,
} from '../../constants';
import errorHandler from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startFetchUserRepository = () => ({
  type: USER_REPOSITORY_STATS_GET,
});
const fetchUserRepositorySuccess = (list) => ({
  type: USER_REPOSITORY_STATS_GET_SUCCESS,
  payload: list,
});
const fetchUserRepositoryFail = (errCode) => ({
  type: USER_REPOSITORY_STATS_GET_FAIL,
  payload: errCode,
});

const startUserRepositoryUnpublish = () => ({
  type: USER_REPOSITORY_UNPUBLISH,
});
const userRepositoryUnpublishSuccess = (list) => ({
  type: USER_REPOSITORY_UNPUBLISH_SUCCESS,
  payload: list,
});
const userRepositoryUnpublishFail = (errCode) => ({
  type: USER_REPOSITORY_UNPUBLISH_FAIL,
  payload: errCode,
});

const startFetchUserDailyStatus = () => ({
  type: USER_REPOSITORY_DAILY_STATUS_GET,
});
const fetchUserDailyStatusSuccess = (list) => ({
  type: USER_REPOSITORY_DAILY_STATUS_SUCCESS,
  payload: list,
});
const fetchDailyStatusFail = (errCode) => ({
  type: USER_REPOSITORY_DAILY_STATUS_FAIL,
  payload: errCode,
});

export const removeErrorsCompanyCustomer = () => ({
  type: USER_REPOSITORY_ERROR_REMOVE,
});

export const fetchUserDailyStatus = (userID) => (dispatch) => {
  const params = {
    userId: userID,
  };
  dispatch(startFetchUserDailyStatus(params));
  axios('/rest/default/V1/eplane/backoffice/inventory/getUserRepositoryStatus', { params }).then((response) => {
    dispatch(fetchUserDailyStatusSuccess(response.data));
  }).catch((e) => {
    errorHandler(e.response, dispatch, fetchDailyStatusFail, e);
  });
};

export const fetchUserRepositoryStats = (userID) => (dispatch) => {
  const params = {
    userId: userID,
  };
  dispatch(startFetchUserRepository(params));
  axios('/rest/default/V1/eplane/backoffice/inventory/getUserRepositoryStats', { params }).then((response) => {
    dispatch(fetchUserRepositorySuccess(response.data));
  }).catch((e) => {
    errorHandler(e.response, dispatch, fetchUserRepositoryFail, e);
  });
};

export const userRepositoryUnpublish = (userID) => (dispatch) => {
  dispatch(startUserRepositoryUnpublish());
  axios.post('/rest/default/V1/eplane/backoffice/inventory/unpublishRepository', { userId: userID }).then((response) => {
    dispatch(userRepositoryUnpublishSuccess(response.data));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, userRepositoryUnpublishFail, e);
  });
};
