import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import { ModalTitle, ButtonContainer } from '../../StyledComponents';

const WeightGroupModal = ({
  list,
  title,
  onHide,
  onSaveHandler,
  validation,
  ...other
}) => {
  const [name, setName] = useState((list && list.label) || '');
  const nameValidation = validation(name);

  useEffect(() => {
    setName((list && list.label) || '');
  }, [list]);

  const onChange = (e) => setName(e.target.value);

  const onSave = () => {
    const data = {
      list_name: name,
    };
    if (list && list.value) data.id = list.value;
    onSaveHandler(data, !!list);
    setName('');
    onHide();
  };

  return (
    <Modal centered onHide={onHide} {...other}>
      <Modal.Header>
        <ModalTitle>{title}</ModalTitle>
      </Modal.Header>
      <Modal.Body>
        <BaseFormGroup
          labelText="List name:"
          value={name}
          name="group_name"
          onChange={onChange}
          {...nameValidation}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonContainer btnWidth="120px" space="10px">
          <Button onClick={onSave} disabled={!!nameValidation} variant="primary">Save</Button>
          <Button onClick={onHide} variant="secondary">Cancel</Button>
        </ButtonContainer>
      </Modal.Footer>
    </Modal>
  );
};

WeightGroupModal.propTypes = {
  list: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  title: PropTypes.string,
  show: PropTypes.bool,
  onSaveHandler: PropTypes.func.isRequired,
  validation: PropTypes.func.isRequired,
  onHide: PropTypes.func,
};

export default WeightGroupModal;
