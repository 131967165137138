import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { fetchTaskIgnoreRules, saveTaskIgnoreRule } from '../../../actions/networkOperationsActions/ignoreListActions';
import { getIgnoreTaskRulesStore, getChatStore } from '../../../selectors';
import { hideForm } from '../../../actions/networkOperationsActions/formManagementActions';

import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import FormActionButtons from '../../FormComponents/ActionButtons';
import { Title, FormButtonContainer, TextAreaContainer } from '../StyledComponents';
import getSelectedText from '../../../utils/getSelectedText';
import { taskTriggerType } from '../../../constants/index';

import createErrorValidationObj from '../../../utils/createErrorValidationObj';

function textIgnoreValidator(value, rules) {
  if (!value || !value.trim()) return createErrorValidationObj('Please enter an ignored phrase');
  if (value.trim().length < 3) {
    return createErrorValidationObj('At least 3 characters required');
  }
  if (rules?.some((rule) => rule?.trigger?.toLowerCase() === value.trim().toLowerCase())) {
    return createErrorValidationObj('This trigger text is already added');
  }
  return null;
}

export default function TasksIgnoreForm({ messageId }) {
  const dispatch = useDispatch();

  const { messages } = useSelector(getChatStore);
  const { ignoreRules, isFetching } = useSelector(getIgnoreTaskRulesStore);

  const [ignoreRule, setIgnoreRule] = useState({
    trigger: getSelectedText().toString() || messages?.find((msg) => msg.message_id === messageId)?.text,
    sendNoQuote: false,
  });

  useEffect(() => {
    if (!ignoreRules) dispatch(fetchTaskIgnoreRules());
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSendHandler = () => {
    dispatch(saveTaskIgnoreRule({
      trigger: ignoreRule.trigger,
      action: ignoreRule.sendNoQuote ? taskTriggerType.NOQUOTE : taskTriggerType.IGNORE,
    }));
    dispatch(hideForm());
  };

  const onNoQuoteChecked = (e) => {
    const { checked } = e.target;
    setIgnoreRule((prev) => ({ ...prev, sendNoQuote: checked }));
  };

  const onTriggerChange = (e) => {
    const { value } = e.target;
    setIgnoreRule((prev) => ({ ...prev, trigger: value }));
  };

  return (
    <Form>
      <Title>Add to Ignore List</Title>
      <p>Text that will be ignored:</p>
      <TextAreaContainer>
        <BaseFormGroup
          value={ignoreRule.trigger}
          as="textarea"
          placeholder="Type your text here..."
          name="comment"
          onChange={onTriggerChange}
          rows="3"
          {...textIgnoreValidator(ignoreRule.trigger, ignoreRules)}
        />
        <CheckboxStyled
          id="buyerNotification"
          label="Send 'No quote' message to the chat"
          checked={ignoreRule.sendNoQuote}
          name="no_quote"
          onChange={onNoQuoteChecked}
        />
      </TextAreaContainer>

      <hr />
      <FormButtonContainer>
        <FormActionButtons
          saveHandler={onSendHandler}
          cancelHandler={() => dispatch(hideForm())}
          confirmButtonText="Add"
          cancelButtonText="Cancel"
          saveProps={{ disabled: !ignoreRule.trigger || isFetching || !!textIgnoreValidator(ignoreRule.trigger, ignoreRules) }}
          width="100"
        />
      </FormButtonContainer>
    </Form>
  );
}

TasksIgnoreForm.propTypes = {
  messageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
