import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getUserStore, getCountries } from '../../../selectors';
import { LinkButton as LinkButtonNormal, TabContainer } from '../../StyledComponents';
import changesMapper from '../../../utils/diffMapper';
import * as siteActions from '../../../actions/userCompanyManagement/sitesActions';
import { FormSubTitle } from '../../FormComponents/TitleSection';
import UMTable from '../../UserCompanyManagement/Table';
import PickupSiteModal from './PickupSiteModal';
import RemoveModal from '../../Modals/RemoveModal';
import RemovingErrorModal from '../../Modals/RemovingErrorModal';
import ErrorModal from '../../ErrorModal';
import getCountryRegionLists from '../../../utils/getCountryRegionLists';
import * as constants from '../../../constants/index';

const LinkButton = styled(LinkButtonNormal)`
  font-size: 16px;
`;

const siteColumns = [{
  name: 'Name',
  dataField: 'name',
  width: '20%',
}, {
  name: 'Location',
  dataField: 'location',
  width: '20%',
}];

class PickupSitesTable extends React.PureComponent {
  openPickupSiteModal = (id) => {
    const { openSiteModalToEdit, openSiteModalToCreate } = this.props;
    if (id) {
      openSiteModalToEdit(id);
    } else {
      openSiteModalToCreate(constants.siteType.PICKUP_SITE);
    }
  };

  hideErrorModal = () => {
    const { removeErrorsUserSites } = this.props;
    removeErrorsUserSites();
  };

  onSaveSiteData = (savingData) => {
    const {
      sitesData: {
        newSiteType,
      },
      currSiteData,
      userId,
      updateUserSite,
      createNewUserSite,
    } = this.props;

    if (currSiteData) {
      const diffObject = changesMapper(savingData, currSiteData);

      if (diffObject.country_id && !diffObject.region_id) {
        diffObject.region_id = null;
      } else if (!diffObject.country_id && diffObject.region_id) {
        diffObject.country_id = currSiteData.country_id;
      }
      updateUserSite(currSiteData.site_id, userId, diffObject);
    } else if (newSiteType) {
      createNewUserSite(userId, newSiteType, savingData);
    }
  };

  render() {
    const {
      userData,

      countriesList,
      regionList,
      userId,
      sitesData: {
        showSiteModal,
        showRemoveSiteModal,

        isSaving: siteIsSaving,
        isDeleting: siteIsDeleting,

        fetchingErrorCode: siteFetchingErrorCode,
        savingErrorCode: siteSavingErrorCode,
        deletingErrorCode: siteDeletingErrorCode,
      },

      pickupSites,
      listOfSiteNames,
      currSiteData,

      deleteUserSite,
      closeRemoveSiteModal,
      openRemoveSiteModel,
      removeErrorsUserSites,
      updateSiteEmail,
      isUserExists,

      closeSiteModal,
    } = this.props;

    let showSiteErrorRemovingModal = false;
    let showErrorModal = false;

    let isCompany = false;

    showSiteErrorRemovingModal = constants.errorCodes.ERROR_SITE_IS_NOT_EMPTY === siteDeletingErrorCode;
    showErrorModal = !!(
      !showSiteErrorRemovingModal
      && (siteDeletingErrorCode || siteFetchingErrorCode)
    );
    isCompany = userData && userData.user_type === constants.userType.COMPANY.id;

    let noPickupSitesContent = null;
    let addPickupSite = null;
    const editUUID = true;

    if (isCompany) {
      if (!pickupSites.length) {
        noPickupSitesContent = (
          <p>
            No sites on file.
            <LinkButton onClick={() => this.openPickupSiteModal()}> Add a new site.</LinkButton>
          </p>
        );
      } else {
        addPickupSite = (
          <LinkButton onClick={() => this.openPickupSiteModal()}>
            <FontAwesomeIcon icon="plus" />
            &nbsp;Add a new site.
          </LinkButton>
        );
      }
    }

    return (
      <div>
        {
          isCompany
          && (
            <div>
              <FormSubTitle title="Pickup Sites &amp; Repair Shops" showEditButton={false} />
              <TabContainer>
                {
                  noPickupSitesContent
                  || (
                    <div>
                      <p>The parts the user sells and services user provides will be in these locations:</p>
                      <UMTable
                        userId={userId}
                        editUUID={editUUID}
                        isUserExists={isUserExists}
                        selectSite={updateSiteEmail}
                        maxHeight={268}
                        columns={siteColumns}
                        data={pickupSites}
                        keyField="site_id"
                        onSaveHandler={this.onSaveSiteData}
                        btnControls={addPickupSite}
                        onEdit={this.openPickupSiteModal}
                        onRemove={openRemoveSiteModel}
                      />
                    </div>
                  )
                }
              </TabContainer>
            </div>
          )
        }

        {/* Site modals */}
        <PickupSiteModal
          key={currSiteData && currSiteData.site_id}
          show={showSiteModal}
          siteData={currSiteData}
          onHide={closeSiteModal}
          countriesList={countriesList}
          isUserExists={isUserExists}
          regionList={regionList}
          listOfSiteNames={listOfSiteNames}
          onSaveHandler={this.onSaveSiteData}
          errorCode={siteSavingErrorCode}
          isSaving={siteIsSaving}
        />

        <RemoveModal
          show={showRemoveSiteModal}
          onRemove={() => deleteUserSite(userId)}
          onHide={closeRemoveSiteModal}
          isRemoving={siteIsDeleting}
          text="Are you sure you want to remove this site? In case there are items associated with it, they will be removed."
        />

        <RemovingErrorModal
          show={showSiteErrorRemovingModal}
          text="This site cannot be removed as there are published items associated with it."
          onHide={removeErrorsUserSites}
        />

        <ErrorModal show={showErrorModal} onHide={this.hideErrorModal} />
      </div>
    );
  }
}

PickupSitesTable.propTypes = {
  userData: PropTypes.shape({
    user_type: PropTypes.number,
  }),

  userId: PropTypes.string,
  // site actions
  updateUserSite: PropTypes.func.isRequired,
  createNewUserSite: PropTypes.func.isRequired,
  openSiteModalToEdit: PropTypes.func.isRequired,
  openSiteModalToCreate: PropTypes.func.isRequired,
  closeSiteModal: PropTypes.func.isRequired,
  deleteUserSite: PropTypes.func.isRequired,
  closeRemoveSiteModal: PropTypes.func.isRequired,
  openRemoveSiteModel: PropTypes.func.isRequired,
  removeErrorsUserSites: PropTypes.func,
  updateSiteEmail: PropTypes.func,

  sitesData: PropTypes.shape({
    sites: PropTypes.arrayOf(PropTypes.object),
    newSiteType: PropTypes.number,
    isFetching: PropTypes.bool,
    isDeleting: PropTypes.bool,
    showRemoveSiteModal: PropTypes.bool,
    showSiteModal: PropTypes.bool,
    isSaving: PropTypes.bool,
    currSiteId: PropTypes.string,
    fetchingErrorCode: PropTypes.number,
    savingErrorCode: PropTypes.number,
    deletingErrorCode: PropTypes.number,
  }),

  currSiteData: PropTypes.shape({
    country_id: PropTypes.string,
    site_id: PropTypes.string,
  }),
  countriesList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  regionList: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.array)),
  pickupSites: PropTypes.arrayOf(PropTypes.object),
  listOfSiteNames: PropTypes.arrayOf(PropTypes.string),
  isUserExists: PropTypes.bool,
};

function mapStateToProps(state) {
  const rawCountriesData = getCountries(state);
  const { countriesList, regionList } = getCountryRegionLists(rawCountriesData);

  return {
    userData: getUserStore(state).user,
    countriesList,
    regionList,
  };
}

export default connect(mapStateToProps, { ...siteActions })(PickupSitesTable);
