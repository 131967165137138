import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Img from '../../Img';
import ProviderStatus from './ProviderStatus';
import Disconnect from './Disconnect';
import { TabContainer, StyledLinkButton as LinkButton } from '../../StyledComponents';
import {
  fetchProviders, saveProviders, disconnectProvider, removeCommission, saveMethods,
} from '../../../actions/companiesManagement/paymentProviders';
import { fetchUserSettings, saveUserSettings } from '../../../actions/companiesManagement/userSettingsActions';
import {
  getUserPaymentProviders,
  getErrorsStore,
  getAdminCustomerId,
  getUserStore,
  getUserSettingsStore,
} from '../../../selectors';
import FormTitleSection, { FormSubTitleItem } from '../../FormComponents/TitleSection/index';
import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import FormActionButtons from '../../FormComponents/ActionButtons';
import DisconnectModal from '../../Modals/PaymentModals/DisconnectModal';
import CommissionModal from '../../Modals/PaymentModals/CommissionModal';
import CustomCommissionForm from '../../UserManagement/Payments/CustomCommissionForm';
import RemoveModal from '../../Modals/PaymentModals/RemoveModal';
import SupportedMethodsModal from '../../Modals/PaymentModals/SupportedMethodsModal';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';

import StripeLogoImg from '../../../assets/stripe-logo.svg';
import { color } from '../../../constants';

const StripeLogo = styled(Img)`
  height: 25px;
  margin: 15px 0;
  margin-left: 10px;
`;
const PaymentProvidersTable = styled.table`
  width: 100%;
  margin-top: 20px;
  tr {
    border-bottom: 2px solid ${color.lightGrey};
    margin-bottom: 5px;
  }
  td {
    min-width: 90px;
    text-align: left;
    vertical-align: middle;
  }
`;
const SettingsForm = styled.div`
  ${({ isDisabled }) => (isDisabled ? 'opacity: 0.5;' : '')}
`;

const PaymentProvidersPage = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();

  const adminCustomerId = useSelector(getAdminCustomerId);
  const {
    stripe, providers, isFetching, isProcessing,
  } = useSelector(getUserPaymentProviders);
  const { user, isFetching: isUserFetching } = useSelector(getUserStore);
  const {
    data, isFetching: isSettingsFetching, isSaving: isSettingsSaving,
  } = useSelector(getUserSettingsStore);
  const { errors } = useSelector(getErrorsStore);

  const [fee, setFee] = useState({});
  const [config, setConfig] = useState({ platform_pay: false });

  const [showModal, setModalState] = useState({
    showCommissionModal: false,
    showDisconnectModal: false,
    showRemoveModal: false,
    showMethodsModal: false,
  });

  const commissionModalTitle = stripe && stripe.fee ? 'Edit commission' : 'Set custom commission';
  const defaultStripeMethods = stripe && stripe.methods;
  const feeAmount = stripe && ((stripe.fee && stripe.fee.amount) || stripe.default_fee.amount);

  const saveStripeCommission = () => {
    setModalState({
      showCommissionModal: false,
      showDisconnectModal: false,
      showRemoveModal: false,
      showMethodsModal: false,
    });
    dispatch(saveProviders({
      user_id: userId,
      fee: fee.amount,
      provider_code: fee.code,
      currency: fee.currency,
      is_percent: fee.is_percent,
    }));
  };

  const disconnectPaymentProvider = () => {
    setModalState({
      showCommissionModal: false,
      showDisconnectModal: false,
      showRemoveModal: false,
      showMethodsModal: false,
    });
    dispatch(disconnectProvider({
      provider_code: fee.code,
      user_id: userId,
      customer_id: adminCustomerId,
    }));
  };

  const removePaymentCommission = () => {
    setModalState({
      showCommissionModal: false,
      showDisconnectModal: false,
      showRemoveModal: false,
      showMethodsModal: false,
    });
    dispatch(removeCommission({
      provider_code: fee.code,
      user_id: userId,
    }));
  };

  const savePaymentMethods = (m) => {
    setModalState({
      showCommissionModal: false,
      showDisconnectModal: false,
      showRemoveModal: false,
      showMethodsModal: false,
    });

    dispatch(saveMethods({
      user_id: userId,
      provider_code: fee.code,
      methods: m.filter((v, i) => v.enabled !== defaultStripeMethods[i].enabled),
    }));
  };

  const hideCommissionModal = () => {
    setModalState({ ...showModal, showCommissionModal: false });
    setFee({ ...fee, amount: feeAmount });
  };

  const onChange = (e) => {
    setConfig({ platform_pay: e.target.checked });
  };

  const onSaveHandler = () => {
    dispatch(saveUserSettings(userId, { platform_pay: config.platform_pay }));
  };

  const onCancelHandler = () => {
    setConfig({ platform_pay: data.platform_pay });
  };

  const saveCustomCommission = (commission) => {
    dispatch(saveProviders({ user_id: userId, ...commission }));
  };

  const removeCustomCommission = (providerCode) => {
    dispatch(removeCommission({
      provider_code: providerCode,
      user_id: userId,
    }));
  };

  useEffect(() => {
    dispatch(fetchProviders(userId));
    dispatch(fetchUserSettings(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    setFee({
      code: stripe && stripe.code,
      ...(stripe && (stripe.fee || stripe.default_fee)),
    });
  }, [stripe]);

  useEffect(() => {
    setConfig({ platform_pay: data && data.platform_pay });
  }, [data]);

  if ((!providers || isFetching || isUserFetching || isSettingsFetching) && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <div>
      <FormTitleSection title="Payment settings" showEditButton={false} />
      <TabContainer>
        <SettingsForm isDisabled={config.platform_pay}>
          <CustomCommissionForm
            title="Buyer's custom commissions when purchasing directly from ePlane"
            providers={providers}
            saveCustomCommission={saveCustomCommission}
            removeCustomCommission={removeCustomCommission}
            disabled={config.platform_pay}
          />
          <br />

          <FormSubTitleItem title="Sellers general commissions charged according to each payment provider transaction" />
          <p>The commission ePlane charges sellers for transactions they get paid for through their company&apos;s payment providers:</p>
          <p>
            {`Connect payment providers to accept online payments on behalf of ${user && user.company_name}:`}
          </p>

          <PaymentProvidersTable>
            <tbody>
              <tr>
                <td>
                  <StripeLogo src={StripeLogoImg} alt="Stripe Logo" />
                </td>
                <td>
                  <ProviderStatus
                    date={stripe && stripe.last_modify}
                    isConnected={stripe && stripe.connected}
                  />
                </td>
                <td>
                  <Disconnect
                    date={stripe && stripe.last_modify}
                    isConnected={stripe && stripe.connected}
                    wasConnected={stripe && stripe.methods && stripe.methods.length !== 0}
                    platformPay={config.platform_pay || (data && data.platform_pay)}
                    onDisconnectClick={() => setModalState({ ...showModal, showDisconnectModal: true })}
                    onMethodsConfClick={() => setModalState({ ...showModal, showMethodsModal: true })}
                  />
                </td>
                <td>
                  {stripe && stripe.fee ? (
                    ` ePlane custom commission: ${feeAmount}%`
                  ) : (
                    <>
                      &nbsp;ePlane custom commission:
                      <LinkButton
                        variant="link"
                        onClick={() => setModalState({ ...showModal, showCommissionModal: true })}
                        disabled={config.platform_pay}
                      >
                        Set&nbsp;
                      </LinkButton>
                    </>
                  )}
                </td>
                <td>
                  {stripe && stripe.fee && (
                    <>
                      <LinkButton
                        variant="link"
                        onClick={() => setModalState({ ...showModal, showCommissionModal: true })}
                        disabled={config.platform_pay}
                      >
                        Edit&nbsp;
                        <FontAwesomeIcon icon="pencil-alt" />
                      </LinkButton>
                      <br />
                      <LinkButton
                        variant="link"
                        onClick={() => setModalState({ ...showModal, showRemoveModal: true })}
                        disabled={config.platform_pay}
                      >
                        Remove&nbsp;
                        <FontAwesomeIcon icon="times" />
                      </LinkButton>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </PaymentProvidersTable>
        </SettingsForm>

        <br />
        <CheckboxStyled
          id="checkbox-platform-pay"
          label="Purchasing from this company will be paid directly to ePlane's Stripe account"
          checked={config.platform_pay}
          name="platform_pay"
          onChange={onChange}
        />

        {data && data.platform_pay !== config.platform_pay && (
          <>
            <hr />
            <FormActionButtons saveHandler={onSaveHandler} cancelHandler={onCancelHandler} />
          </>
        )}

        <SupportedMethodsModal
          companyName={user && user.company_name}
          data={defaultStripeMethods}
          show={showModal.showMethodsModal}
          onHide={() => setModalState({ ...showModal, showMethodsModal: false })}
          saveMethods={savePaymentMethods}
        />
        <DisconnectModal
          companyName={user && user.company_name}
          show={showModal.showDisconnectModal}
          onHide={() => setModalState({ ...showModal, showDisconnectModal: false })}
          onConfirm={disconnectPaymentProvider}
        />
        <CommissionModal
          title={commissionModalTitle}
          fee={fee}
          companyName={user && user.company_name}
          show={showModal.showCommissionModal}
          onConfirm={saveStripeCommission}
          onCommissionChange={(e) => setFee({ ...fee, amount: e.target.value })}
          onHide={hideCommissionModal}
        />
        <RemoveModal
          companyName={user && user.company_name}
          show={showModal.showRemoveModal}
          onHide={() => setModalState({ ...showModal, showRemoveModal: false })}
          onConfirm={removePaymentCommission}
        />
      </TabContainer>

      <ModalLoader show={isProcessing || isSettingsSaving} />
    </div>
  );
};

export default PaymentProvidersPage;
