import {
  CUSTOMERS_LIST_GET,
  CUSTOMERS_LIST_GET_SUCCESS,
  CUSTOMERS_LIST_FAIL,

} from '../../constants';

const initialState = {
  customersList: null,
  isFetching: false,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case CUSTOMERS_LIST_GET:
      return {
        ...state,
        isFetching: true,
      };
    case CUSTOMERS_LIST_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customersList: payload,
      };
    case CUSTOMERS_LIST_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    default: return state;
  }
};
