import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Row, Col, Form, Button, FormGroup, FormLabel,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  fetchApiPermissions, revoke, generate, clearSpec2kStore,
} from '../../../actions/companiesManagement/spec2k';
import { getSpec2kStore, getErrorsStore } from '../../../selectors';
import { ButtonContainer, TabContainer, StyledLinkButton as LinkButton } from '../../StyledComponents';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import { color } from '../../../constants';
import { WeightListTitle } from '../StyleComponents';

const IndexColumn = styled.div`
  padding-top: 6px;
`;
const CopiedButton = styled(LinkButton)`
  border-radius: 5px;
  background: ${color.magicMint};
  :hover {
    background: ${color.magicMint};
  }
`;
const UrlInput = styled(BaseFormGroup)`
  &&& {
    border: none;
    background: transparent;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid ${color.borderLightGrey};
  }
`;

const Spec2000Page = () => {
  const apiTextFileds = useRef([]);
  const apiUrl = useRef(null);

  const { userId } = useParams();
  const dispatch = useDispatch();

  const [copyIndex, setCopyState] = useState(null);
  const [copyApiUrl, setCopyApiUrl] = useState(false);

  const {
    apiPermissions, generatedRequestUrl,
    isFetching, isProcessing, isGenerating,
  } = useSelector(getSpec2kStore);
  const { errors } = useSelector(getErrorsStore);

  useEffect(() => {
    dispatch(fetchApiPermissions(userId));
    return () => dispatch(clearSpec2kStore());
  }, [dispatch, userId]);

  const onCopyToClipboardHandler = (e, index) => {
    apiTextFileds.current[index].select();
    // eslint-disable-next-line no-undef
    document.execCommand('copy');
    e.target.focus();
    setCopyState(index);
  };

  const onCopyUrlToClipboardHandler = (e) => {
    apiUrl.current.select();
    // eslint-disable-next-line no-undef
    document.execCommand('copy');
    e.target.focus();
    setCopyApiUrl(true);
  };

  const onRevokeHandler = (permissionId) => {
    dispatch(revoke(userId, permissionId));
    setCopyState(null);
  };

  if ((!apiPermissions || isFetching) && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <>
      <FormTitleSection title="Incoming SPEC2000 API requests" showEditButton={false} />
      <TabContainer>
        <Form>
          <FormGroup>
            <Row>
              <Col as={FormLabel} sm={1}>
                <IndexColumn>URL</IndexColumn>
              </Col>
              <Col sm={8}>
                <UrlInput
                  value={generatedRequestUrl}
                  placeholder="Generate request url..."
                  inputRef={apiUrl}
                  name="resource"
                  readOnly
                />
              </Col>
              <Col sm={3}>
                <ButtonContainer>
                  {renderCopyButton(copyApiUrl, !generatedRequestUrl, onCopyUrlToClipboardHandler)}
                  <LinkButton variant="link" title="Generate" onClick={() => dispatch(generate(userId))}>
                    <FontAwesomeIcon icon="sync" spin={isGenerating} />
                    &nbsp;Generate
                  </LinkButton>
                </ButtonContainer>
              </Col>
            </Row>
          </FormGroup>
          <hr />

          {apiPermissions.length !== 0 && (
            <Row>
              <Col sm={1}><WeightListTitle>#</WeightListTitle></Col>
              <Col sm={8}><WeightListTitle>Spec2K API URL</WeightListTitle></Col>
            </Row>
          )}

          {apiPermissions.map((v, i) => (
            <FormGroup key={v.permission_id}>
              <Row>
                <Col sm={1}><IndexColumn>{i + 1}</IndexColumn></Col>
                <Col sm={8}>
                  <BaseFormGroup
                    value={v.request_url}
                    placeholder="SPEC2000 API URL"
                    name="request_url"
                    // eslint-disable-next-line no-return-assign
                    inputRef={(el) => apiTextFileds.current[i] = el}
                    readOnly
                  />
                </Col>
                <Col sm={2}>
                  <ButtonContainer>
                    {renderCopyButton(copyIndex === i, false, (e) => onCopyToClipboardHandler(e, i))}
                    <Button variant="primary" onClick={() => onRevokeHandler(v.permission_id)} title="Revoke">
                      <FontAwesomeIcon icon="trash-alt" />
                    </Button>
                  </ButtonContainer>
                </Col>
              </Row>
            </FormGroup>
          ))}
        </Form>
      </TabContainer>

      <ModalLoader show={isProcessing} />
    </>
  );
};

const renderCopyButton = (isCopied, disabled, onClickHandler) => (
  isCopied
    ? (
      <CopiedButton variant="link" disabled={disabled} onClick={null} title="Copied">
        <FontAwesomeIcon icon="clipboard-check" />
      </CopiedButton>
    ) : (
      <LinkButton variant="link" disabled={disabled} onClick={onClickHandler} title="Copy API URL">
        <FontAwesomeIcon icon="copy" />
      </LinkButton>
    )
);

export default Spec2000Page;
