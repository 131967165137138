import styled from 'styled-components';
import { Button, Row } from 'react-bootstrap';

import { color } from '../constants';
import A from './A';

export const TableRow = styled(Row)`
  margin-bottom: 30px;
`;

export const LinkButton = styled.span`
  color: ${color.birch};
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  &>button {
    min-width: ${(props) => (props.btnWidth || 'auto')}
  }
  &&&>button:not(:first-child), &&&>a:not(:first-child) {
    margin-left: ${(props) => (props.space || '5px')}
  }
`;

export const FileLink = styled(A)`
  color: ${color.birch};
  word-break: break-word;

  &:hover {
    color: ${color.darkBirch};
  }
`;

export const SelectContainer = styled.div`
  max-width: ${(props) => (props.maxWidth || 'none')};
`;

export const TabContainer = styled.div`
  margin-top: ${(props) => (props.marginTop || '26px')};
  padding-bottom: 57px;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalTitle = styled.h3`
  color: ${color.blue};
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ModalErrorText = styled.div`
  margin-left: 20px;
  color: ${color.textRed};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  font-size: 18px;
`;

export const FormModalColumn = styled.div`
    width: 300px;
    flex-grow: 1;
`;

export const DocumentUploader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 11px;
  flex-wrap: wrap;
`;

export const StyledLinkButton = styled(Button)`
  &&& {
    color: ${color.birch};
    font-size: 16px;
    text-decoration: none;
  }
  &&&:hover {
    color: ${color.darkBirch};
  }
`;
export const DangerLinkButton = styled(Button)`
  &&& {
    color: #d24c4c;
    font-size: 16px;
    text-decoration: none;
  }
  &&&:hover {
    color: ${color.textRed};
  }
`;
export const ScrollableContainer = styled.div`
  max-height: ${({ maxHeight }) => (maxHeight)}px;
  min-height: ${({ maxHeight }) => (maxHeight)}px;
  overflow: auto;
  scrollbar-width: thin;
  ::-webkit-scrollbar-track
  {
    border-radius: 10px;
    background-color: transparent
  }

  ::-webkit-scrollbar
  {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    background-color: #808080;
  }
`;
