import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon as FormAwesomeIconNorm } from '@fortawesome/react-fontawesome';

import { getSelectedTaskStatus } from '../../../selectors';
import { StyledLinkButton as LinkButton } from '../../StyledComponents';
import { markAsPo } from '../../../actions/networkOperationsActions/builtInQuoteActions';
import A from '../../A';
import BankAccountDetail from './BankAccountDetails';
import { getPaymentStatus, getPaymentRequestStatus, getPaymentToEplaneStatus } from './paymentStatusFormatters';
import { color, BOT_SERVICE_MSG } from '../../../constants';
import {
  quoteOriginal, quoteTypes, chatMsgType,
  paymentRequestType, taskStatus, paymentStatus, paymentProviders, creatorTypes, quoteValidationStatuses,
} from '../../../constants/index';
import LowBidIndicator from './LowBidIndicator';

const InvalidQuoteIcon = styled.div`
  text-align: right;
  font-weight: bold;
  margin-top: 5px;
  color: ${(props) => (props.valid ? `${color.textGreen}` : `${color.darkorange}`)};
`;
const FontAwesomeIcon = styled(FormAwesomeIconNorm)`
  &&& {
    margin-right: 0.5em;
  }
`;
const DownloadIcon = styled(FormAwesomeIconNorm)`
  margin-left: 5px;
  margin-right: 5px;
`;
const StyledLinkButton = styled(LinkButton)`
  padding-left: 5px;
`;
const PreviewImg = styled.img`
  min-height: 200px;
  max-width: 100%;
  height: 500px;
  width: 261px;
  object-fit: contain;
`;
const AttachmentBody = styled.div`
  margin-bottom: 1px;
  padding: 3px 20px 3px;
`;
const DocumentMsg = styled.a`
  && {
    ${({ isDocRemoved }) => (!isDocRemoved && `color: ${color.birch}`)};
    font-weight: ${({ isDocRemoved }) => (isDocRemoved ? '400' : 'bold')};
    text-decoration: ${({ isDocRemoved }) => (isDocRemoved ? 'line-through' : 'auto')};
    opacity: ${({ isDocRemoved }) => (isDocRemoved ? '0.7' : '1')};
    pointer-events: ${({ isDocRemoved }) => (isDocRemoved ? 'none' : 'auto')};;
    word-break: break-word;
  }
`;
const Text = styled.div`
  font-weight: ${(props) => props.fontWeight || '300'};
  word-wrap: break-word;
  white-space: pre-wrap;

  padding: ${(props) => props.padding};
  && {
    color: ${(props) => (props.color || '')};
  }
`;
const QuoteContainer = styled(Text)`
  font-size: 14px;
  && > h3 {
    font-size: 16px;
    margin: 0 0 3px 0;
  }

  && > p {
    margin: 15px 0 2px;
  }

  && > hr {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 25%;
  }

  && > table {
    width: auto;
  }

  && td {
    padding: 0 12px;
    vertical-align: text-top;
    min-width: 120px;
  }

  && td:first-child {
    padding-left: 0;
    min-width: 160px;
  }

  && td:last-child {
    padding-right: 0;
    min-width: 160px;
  }
`;
const MessageContainer = styled.div`
  font-size: 16px;
  border-radius: 10px;
  overflow: hidden;

  &&& > div:last-child {
    padding-bottom: 10px;
  }

  & > div {
    background-color: ${(props) => getMainColor(props.isBuyer)};
    color: ${(props) => getTextColor(props.isBuyer)};
  }

  & a {
    color: ${(props) => getLinkColor(props.isBuyer)};
  }

  & a:hover, & a:focus {
    color: ${(props) => getLinkColor(props.isBuyer)};
  }

  & hr {
    border-color: ${(props) => getTextColor(props.isBuyer)};
  }
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.4px;
  word-break: break-all;
  padding: 12px 20px 6px;
`;
const Container = styled.div`
  display: flex;
`;
const ColumnContainer = styled.div`
  flex: 50%;
`;
const Column = styled.div`
  flex: 50%;

  && td {
    white-space: nowrap;
  }

  && td:first-child {
    padding-right: 0;
    min-width: 0;
  }
`;
const InfoContainer = styled.div`
  font-size: 16px;
  & p {
    margin-bottom: 0;
  }
  letter-spacing: 0.4px;
`;
const QuotedMessage = styled.div`
  padding: 10px 20px;
  & > div {
    border-radius: 0;
    border-left: 5px solid ${color.blue};
  }
  & > div > div {
    background-color: ${color.white};
  }
`;
const MarginTop = styled.div`
  margin-top: 5px;
`;
const PrStatus = styled.div`
  margin-top: 5px;
  font-size: 13px;
  text-align: right;
`;
const QuoteTable = styled.table`
  table-layout: fixed;
  width: 100%;
  td {
    word-break: break-word;
  }
`;
const chatMessageContent = (msg, buyerSellerInfo, isQuoted) => {
  const {
    isBuyer,
    senderInfo,
    text,
  } = msg;
  let titleText = '';
  const { firstname, lastname, company_name: companyName } = senderInfo;
  const buyerCompanyName = buyerSellerInfo.buyer.company_name;
  const sellerCompanyName = buyerSellerInfo.seller.company_name;

  if (msg.creatorType === creatorTypes.BOT) {
    if (msg.messageId === BOT_SERVICE_MSG) {
      titleText = 'ePlane Bot Actions';
    } else {
      titleText = 'ePlane Bot';
    }
  } else if (msg.creatorType === creatorTypes.EPLANE) {
    titleText = 'ePlane';
  } else {
    titleText = getTitleText(senderInfo, isBuyer);
  }

  switch (msg.messageType) {
    case chatMsgType.ATCH_ADD:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {text && <Text padding="0px 20px 6px">{text}</Text>}
          <AttachedList files={msg.attachedDocs} />
        </MessageContainer>
      );

    case chatMsgType.ATCH_DEL:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {createRemovedList(msg.removedDocNames)}
        </MessageContainer>
      );

    case chatMsgType.TEXT:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {text && <Text padding="0px 20px 6px">{text}</Text>}
        </MessageContainer>
      );

    case chatMsgType.QUOTE:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && !isQuoted && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo)}
            </QuotedMessage>
          )}
          {quoteTextContent(text, msg)}

        </MessageContainer>
      );

    case chatMsgType.NO_QUOTE:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo)}
            </QuotedMessage>
          )}
          {text && <Text padding="0px 20px 6px">{text}</Text>}
        </MessageContainer>
      );

    case chatMsgType.PO:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          <Text padding="0px 20px 6px">
            <p><A target="_blank" rel="noopener noreferrer" to={`${msg.attachedPo[0].url}`}>{`PO ${msg.attachedPo[0].name}`}</A></p>
            <PreviewImg src={msg.attachedPo[0].preview_url} alt="po preview" />
          </Text>
        </MessageContainer>
      );

    case chatMsgType.EDIT_PO:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          <Text padding="0px 20px 6px">
            <p>{`PO was edited by ${companyName}`}</p>
            <br />
            <InfoContainer>
              <p>Changes:</p>
              {msg.poEdit.filter((t) => t[1] !== null).map((v) => (
                <p key={v[0]}>
                  {v[0]}
                  &nbsp;
                  {v[1]}
                </p>
              ))}
            </InfoContainer>
          </Text>
        </MessageContainer>
      );

    case chatMsgType.ACK_PO:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          <Text padding="0px 20px 6px">
            <p>{`${companyName} has acknowledged ${isBuyer ? sellerCompanyName : buyerCompanyName}'s PO`}</p>
          </Text>
        </MessageContainer>
      );

    case chatMsgType.REJ_PO:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          <Text padding="0px 20px 6px">
            <p>{`${companyName} has rejected ${isBuyer ? sellerCompanyName : buyerCompanyName}'s PO`}</p>
          </Text>
        </MessageContainer>
      );

    case chatMsgType.SHIP:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          <Text padding="0px 20px 6px">
            <p>{`Shipping details were provided by ${firstname} ${lastname}:`}</p>
            <br />
            <InfoContainer>
              {msg.shipping.filter((t) => t[1] !== null).map((v) => (
                <p key={v[1]}>
                  {v[0]}
                  &nbsp;
                  {v[1]}
                </p>
              ))}
            </InfoContainer>
          </Text>
        </MessageContainer>
      );

    case chatMsgType.PR_REQUEST:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          <Text padding="0px 20px 6px">
            <p>Please send me a payment request to complete this purchase online.</p>
          </Text>
        </MessageContainer>
      );

    case chatMsgType.PR_MSG:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}

          <QuoteContainer padding="5px 20px 6px">
            <b>{getMessageHeaderText(msg.type, true)}</b>
            {paymentRequestQuoteContent(text, msg)}
            {text && <p>{text}</p>}
            {msg.creatorType === creatorTypes.BOT && (
              <p>ePlane&apos;s commission will be added upon selection of payment method</p>
            )}
            {!msg.quotedMessage && !isQuoted && (
              <PrStatus>{getPaymentRequestStatus(msg.status)}</PrStatus>
            )}
          </QuoteContainer>
        </MessageContainer>
      );

    case chatMsgType.PAYMENT_MSG: {
      let mainContent = null;
      if (msg.payment && !msg.payment.to_eplane) {
        mainContent = (
          <Text padding="10px 20px 6px">{getPaymentStatus(msg.status, msg.method)}</Text>
        );
      } else if (msg.payment?.provider !== paymentProviders.WIRETRANSFER_BROKER && msg.status === paymentStatus.COMPLETED) {
        mainContent = (
          <>
            <Text padding="10px 20px 6px">{getPaymentToEplaneStatus(msg.status, msg.method, msg.buyerCompanyName)}</Text>
            <Text padding="10px 20px 6px">
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              ePlane will now purchase the item from {msg.sellerCompanyName}.<br />
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              Once the purchase is complete, {msg.buyerCompanyName} will be notified.
            </Text>
          </>
        );
      } else if (msg.payment?.provider !== paymentProviders.WIRETRANSFER_BROKER) {
        mainContent = (
          <Text padding="10px 20px 6px">{getPaymentToEplaneStatus(msg.status, msg.method, msg.buyerCompanyName)}</Text>
        );
      } else {
        mainContent = (
          <Text padding="10px 20px 6px">
            <p><strong>{`We've noticed ${msg.buyerCompanyName} is interested in paying ePlane by wire transfer.`}</strong></p>
            <p>Please use the following bank account details to make the transfer:</p>
            <BankAccountDetail amount={`${msg.payment.price_total} ${msg.payment.currency}`} />

            <p>
              When making the transfer, please add a note with&nbsp;
              <strong>{`EP order # ${msg.incrementId}`}</strong>
              {`. It will help us to track your transfer and continue the process with ${msg.sellerCompanyName}`}
              .
            </p>
          </Text>
        );
      }

      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          {text && <Text padding="0px 20px 15px">{text}</Text>}
          <AttachedList files={(msg.attachedDocs)} />

          {mainContent}
        </MessageContainer>
      );
    }

    case chatMsgType.PR_EXPIRED:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          <Text padding="0px 20px 6px">
            This payment request has expired.
          </Text>
          {text && <p>{text}</p>}
        </MessageContainer>
      );

    case chatMsgType.EPLANE_PAYMENT_REQUEST:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && !isQuoted && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          <Text padding="0px 20px 6px" color={color.textGreen}>
            <FontAwesomeIcon icon="check-circle" />
            ePlane sent payment request to the buyer.
          </Text>
          {text && <Text padding="0px 20px 6px">{text}</Text>}
        </MessageContainer>
      );

    case chatMsgType.EPLANE_PO_REQUEST:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          <Text padding="0px 20px 6px" color={color.grey}>
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon="circle" />
              <FontAwesomeIcon icon="ellipsis-h" inverse transform="shrink-6" />
            </span>
            <span>Please send PO to eplane.</span>
          </Text>
          {text && <Text padding="0px 20px 6px">{text}</Text>}
          <AttachedList files={msg.attachedDocs} />
        </MessageContainer>
      );

    case chatMsgType.EPLANE_BOUGHT_ITEM:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          <Text padding="0px 20px 6px" color={color.textGreen}>
            <FontAwesomeIcon icon="check-circle" />
            {`ePlane purchased the item from ${msg.sellerCompanyName}, the payment has been completed successfully.`}
          </Text>
          {text && <Text padding="0px 20px 6px">{text}</Text>}
          <AttachedList files={msg.attachedDocs} />
        </MessageContainer>
      );

    case chatMsgType.EPLANE_ORDER_SHIPPED:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          <Text padding="0px 20px 6px" color={color.textGreen}>
            <FontAwesomeIcon icon="check-circle" />
            <span>Order shipped</span>
          </Text>
          {text && <Text padding="0px 20px 6px">{text}</Text>}
          <AttachedList files={msg.attachedDocs} />
        </MessageContainer>
      );

    case chatMsgType.EPLANE_REVIEW:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          <Text padding="0px 20px 6px" color={color.grey}>
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon="circle" />
              <FontAwesomeIcon icon="ellipsis-h" inverse transform="shrink-6" />
            </span>
            <span>Under ePlane&apos;s review</span>
          </Text>
          {text && <Text padding="0px 20px 6px">{text}</Text>}

        </MessageContainer>
      );

    case chatMsgType.EPLANE_PAYMENT_COMPLETE:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          <Text padding="0px 20px 6px" color={color.textGreen}>
            <FontAwesomeIcon icon="check-circle" />
            {
              msg.isWiretransfer
                ? `${msg.buyerCompanyName}'s wire transfer payment to ePlane has been completed successfully`
                : `${msg.buyerCompanyName}'s payment to ePlane has been completed successfully.`
            }
          </Text>
          {text && <Text padding="0px 20px 6px">{text}</Text>}
        </MessageContainer>
      );

    case chatMsgType.EPLANE_PAYMENT_FAILED:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          <Text padding="0px 20px 6px" color={color.textRed}>
            <FontAwesomeIcon icon="exclamation-circle" />
            {`${msg.buyerCompanyName}'s payment to ePlane has failed.`}
          </Text>
          {text && <Text padding="0px 20px 6px">{text}</Text>}
        </MessageContainer>
      );
    case chatMsgType.EPLANE_PAYMENT_PROCESSING:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          <Text padding="0px 20px 6px" color={color.grey}>
            <FontAwesomeIcon icon={['far', 'clock']} />
            {`${msg.buyerCompanyName}'s payment to ePlane is being processed.`}
          </Text>
          {text && <Text padding="0px 20px 6px">{text}</Text>}
        </MessageContainer>
      );

    case chatMsgType.EPLANE_WT_CONFIRM:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          {text && (
            <Text padding="0px 20px 6px">
              {text}
            </Text>
          )}
        </MessageContainer>
      );

    case chatMsgType.PAYMENT_COMPLETE:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          <Text padding="0px 20px 6px">
            {getPaymentToEplaneStatus(paymentStatus.COMPLETED)}
          </Text>
          {text && <Text padding="0px 20px 6px">{text}</Text>}
        </MessageContainer>
      );

    case chatMsgType.PAYMENT_FAILED:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          <Text padding="0px 20px 6px">
            {getPaymentStatus(paymentStatus.FAILED)}
          </Text>
          {text && <Text padding="0px 20px 6px">{text}</Text>}
        </MessageContainer>
      );

    case chatMsgType.PAYMENT_PROCESSING:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          <Text padding="0px 20px 6px">
            {getPaymentStatus(paymentStatus.PENDING)}
          </Text>
          {text && <Text padding="0px 20px 6px">{text}</Text>}
        </MessageContainer>
      );

    case chatMsgType.EPP_REPORT:
      return (
        <MessageContainer isBuyer={isBuyer}>
          <Title>{titleText}</Title>
          {msg.quotedMessage && (
            <QuotedMessage>
              {chatMessageContent(msg.quotedMessage, buyerSellerInfo, true)}
            </QuotedMessage>
          )}
          {text && (
            <Text padding="0px 20px 6px">
              {text}
            </Text>
          )}
        </MessageContainer>
      );

    default:
      return null;
  }
};

const quoteTextContent = (text, msg) => {
  const {
    quoteType,
    quote,
    quoteRaw,
    quotePrice,
    quoteFinal,
    quoteOriginal: quoteOrigin,
    quoteExtraData,
    leadTime,
    pnDetailsAvailable,
    isPaymentRequest,
    lowBidIndicator,
    quoteValidationStatus,
  } = msg;

  let content = null;

  if (quoteFinal) {
    content = (
      <>
        <ColumnContainer>
          <strong>Quote details:</strong>
          {getTableContentFromQuote(quote, 1)}
        </ColumnContainer>
        <br />
        {
          quotePrice && quotePrice.length > 0
            ? (
              <ColumnContainer>
                <strong>PriceBreaks:</strong>
                {getTableContentFromPrices(quotePrice)}
              </ColumnContainer>
            )
            : null
        }
        {getTableContentFromQuote([
          lowBidIndicator?.is_display && (
            <LowBidIndicator
              value={lowBidIndicator.p}
              multipleGroups={lowBidIndicator.multiple}
              isLive={lowBidIndicator.is_live}
            />
          ),
        ], 2)}
        <br />
        {quoteFinal.map((v, i) => <div key={i.toString()}>{v}</div>)}
        {text && <div>{text}</div>}
        {getQuoteValidState(quoteValidationStatus)}
      </>
    );
  } else if (quoteOrigin === quoteOriginal.QUOTE_TIER1) {
    content = (
      <>
        <h3>Quote details:</h3>
        {getTableContentFromQuote(quote, 2)}
        {quoteExtraData ? <MarginTop>{getTableContentFromQuote(quoteExtraData, 1)}</MarginTop> : null}
        {getTableContentFromQuote([
          '',
          lowBidIndicator?.is_display && (
            <LowBidIndicator
              value={lowBidIndicator.p}
              multipleGroups={lowBidIndicator.multiple}
              isLive={lowBidIndicator.is_live}
            />
          ),
        ], 2)}
        {getQuoteValidState(quoteValidationStatus)}
      </>
    );
  } else if (pnDetailsAvailable) {
    content = (
      <>
        <h3>Quote details:</h3>
        {getTableContentFromQuote(quote, 2)}
        {getTableContentFromQuote([
          '',
          lowBidIndicator?.is_display && (
            <LowBidIndicator
              value={lowBidIndicator.p}
              multipleGroups={lowBidIndicator.multiple}
              isLive={lowBidIndicator.is_live}
            />
          ),
        ], 2)}
        {quotePrice ? <hr /> : null}
        {quotePrice ? getTableContentFromPrices(quotePrice) : null}
        {quoteExtraData ? <MarginTop>{getTableContentFromQuote(quoteExtraData, 1)}</MarginTop> : null}
        {leadTime ? <MarginTop>{leadTime}</MarginTop> : null}
      </>
    );
  } else {
    const messageHeaderText = getMessageHeaderText(quoteType, isPaymentRequest);
    if (quoteType === quoteTypes.QUOTE_REPAIR) {
      content = (
        <>
          <b>{messageHeaderText}</b>
          <QuoteTable>
            <tbody>
              <tr><td>{quoteRaw.pickup_site}</td></tr>
              <tr><td>{quoteRaw.warranty}</td></tr>
              <tr><td>{quoteRaw.qty}</td></tr>
              <tr>
                <td>{quoteRaw.evaluation_fee}</td>
                <td>{quoteRaw.evaluation_time}</td>
              </tr>
              <tr>
                <td>{quoteRaw.test_price}</td>
                <td>{quoteRaw.test_time}</td>
              </tr>
              <tr>
                <td>{quoteRaw.repair_price}</td>
                <td>{quoteRaw.repair_time}</td>
              </tr>
              <tr>
                <td>{quoteRaw.overhaul_price}</td>
                <td>{quoteRaw.overhaul_time}</td>
              </tr>
              <tr>
                <td />
                <td>
                  {lowBidIndicator?.is_display && (
                  <LowBidIndicator
                    value={lowBidIndicator.p}
                    multipleGroups={lowBidIndicator.multiple}
                    isLive={lowBidIndicator.is_live}
                  />
                )}
                </td>
              </tr>
            </tbody>
          </QuoteTable>
          {text && <p>{text}</p>}
        </>
      );
    } else if (quoteType === quoteTypes.QUOTE_LOAN || quoteType === quoteTypes.QUOTE_EXCHANGE) {
      content = (
        <>
          <b>{messageHeaderText}</b>
          <QuoteTable>
            <tbody>
              {quoteRaw.part_number && <tr><td colSpan={2}>{quoteRaw.part_number}</td></tr>}
              {quoteRaw.description && <tr><td colSpan={2}>{quoteRaw.description}</td></tr>}
              <tr>
                <td>{quoteRaw.condition}</td>
                <td>{quoteRaw.warranty}</td>
              </tr>
              <tr>
                <td>{quoteRaw.lead_time}</td>
                <td>{quoteRaw.qty}</td>
              </tr>
              <tr>
                <td>{quoteRaw.pickup_site}</td>
                <td>{quoteType === quoteTypes.QUOTE_LOAN ? quoteRaw.loan_fee : quoteRaw.exchange_fee}</td>
              </tr>
              <tr>
                <td>
                  {msg.tagDoc[0]?.url
                    ? (
                      <>
                        Tag:&nbsp;
                        <A target="_blank" rel="noopener noreferrer" to={`${msg.tagDoc[0]?.url}`}>{quoteRaw.tag_type}</A>
                      </>
                    )
                    : `Tag: ${quoteRaw.tag_type}`}
                </td>
                <td>{quoteType === quoteTypes.QUOTE_LOAN ? quoteRaw.loan_period : quoteRaw.exchange_period}</td>
              </tr>
              <tr>
                <td>{quoteRaw.tagged_by}</td>
                <td>{quoteRaw.outright_price}</td>
              </tr>
              <tr>
                <td>{quoteRaw.tag_date}</td>
                <td>{quoteRaw.minimum_order_value}</td>
              </tr>
              <tr>
                <td>{quoteRaw.trace_to}</td>
              </tr>
              <tr>
                <td />
                <td>
                  {lowBidIndicator?.is_display && (
                  <LowBidIndicator
                    value={lowBidIndicator.p}
                    multipleGroups={lowBidIndicator.multiple}
                    isLive={lowBidIndicator.is_live}
                  />
                )}
                </td>
              </tr>
            </tbody>
          </QuoteTable>

          {text && <p>{text}</p>}
        </>
      );
    } else if (quoteType === quoteTypes.QUOTE_BUY) {
      content = (
        <>
          <b>{messageHeaderText}</b>
          <QuoteTable>
            <tbody>
              {quoteRaw.part_number && <tr><td colSpan={2}>{quoteRaw.part_number}</td></tr>}
              {quoteRaw.description && <tr><td colSpan={2}>{quoteRaw.description}</td></tr>}
              <tr>
                <td>{quoteRaw.condition}</td>
                <td>{quoteRaw.warranty}</td>
              </tr>
              <tr>
                <td>{quoteRaw.lead_time}</td>
                <td>{quoteRaw.qty}</td>
              </tr>
              <tr>
                <td>{quoteRaw.pickup_site}</td>
                <td>{quoteRaw.price}</td>
              </tr>
              <tr>
                <td>
                  {msg.tagDoc[0]?.url
                    ? (
                      <>
                        Tag:&nbsp;
                        <A target="_blank" rel="noopener noreferrer" to={`${msg.tagDoc[0]?.url}`}>{quoteRaw.tag_type}</A>
                      </>
                    )
                    : `Tag: ${quoteRaw.tag_type}`}
                </td>
                <td>{quoteRaw.taxes}</td>
              </tr>
              <tr>
                <td>{quoteRaw.tagged_by}</td>
                <td><strong>{quoteRaw.total_price}</strong></td>
              </tr>
              <tr>
                <td>{quoteRaw.tag_date}</td>
                <td>{quoteRaw.minimum_order_value}</td>
              </tr>
              <tr>
                <td>{quoteRaw.trace_to}</td>
              </tr>
              <tr>
                <td />
                <td>
                  {lowBidIndicator?.is_display && (
                  <LowBidIndicator
                    value={lowBidIndicator.p}
                    multipleGroups={lowBidIndicator.multiple}
                    isLive={lowBidIndicator.is_live}
                  />
                )}
                </td>
              </tr>
            </tbody>
          </QuoteTable>
          {text && <p>{text}</p>}
          {!quoteRaw.is_alt_pn && getQuoteValidState(quoteValidationStatus)}
        </>
      );
    }
  }

  return (
    <QuoteContainer padding="5px 20px 6px">
      {content}
    </QuoteContainer>
  );
};

const paymentRequestQuoteContent = (text, msg) => {
  const { type, quoteRaw } = msg;
  let content = null;

  if (type === paymentRequestType.REPAIR) {
    content = (
      <tbody>
        <tr><td>{quoteRaw.pickup_site}</td></tr>
        <tr><td>{quoteRaw.warranty}</td></tr>
        <tr><td>{quoteRaw.valid_for_value}</td></tr>
        <tr><td>{quoteRaw.qty}</td></tr>
        <tr>
          <td>{quoteRaw.evaluation_fee}</td>
          <td>{quoteRaw.evaluation_time}</td>
        </tr>
        <tr>
          <td>{quoteRaw.test_price}</td>
          <td>{quoteRaw.test_time}</td>
        </tr>
        <tr>
          <td>{quoteRaw.repair_price}</td>
          <td>{quoteRaw.repair_time}</td>
        </tr>
        <tr>
          <td>{quoteRaw.overhaul_price}</td>
          <td>{quoteRaw.overhaul_time}</td>
        </tr>
        <tr>
          <td>{quoteRaw.taxes}</td>
          <td>{quoteRaw.total_price}</td>
        </tr>
      </tbody>
    );
  } else if (type === paymentRequestType.LOAN || type === paymentRequestType.EXCHANGE) {
    content = (
      <tbody>
        {quoteRaw.part_number && <tr><td colSpan={2}>{quoteRaw.part_number}</td></tr>}
        {quoteRaw.description && <tr><td colSpan={2}>{quoteRaw.description}</td></tr>}
        <tr>
          <td>{quoteRaw.condition}</td>
          <td>{quoteRaw.warranty}</td>
        </tr>
        <tr>
          <td>{quoteRaw.lead_time}</td>
          <td>{quoteRaw.qty}</td>
        </tr>
        <tr>
          <td>{quoteRaw.pickup_site}</td>
          <td>{type === paymentRequestType.LOAN ? quoteRaw.loan_fee : quoteRaw.exchange_fee}</td>
        </tr>
        <tr>
          <td>
            {msg.tagDoc[0]?.url
              ? (
                <>
                  Tag:&nbsp;
                  <A target="_blank" rel="noopener noreferrer" to={`${msg.tagDoc[0]?.url}`}>{quoteRaw.tag_type}</A>
                </>
              )
              : `Tag: ${quoteRaw.tag_type}`}
          </td>
          <td>{type === paymentRequestType.LOAN ? quoteRaw.loan_period : quoteRaw.exchange_period}</td>
        </tr>
        <tr>
          <td>{quoteRaw.tagged_by}</td>
          <td>{quoteRaw.outright_price}</td>
        </tr>
        <tr>
          <td>{quoteRaw.tag_date}</td>
          <td>{quoteRaw.taxes}</td>
        </tr>
        <tr>
          <td>{quoteRaw.trace_to}</td>
          <td><strong>{quoteRaw.total_price}</strong></td>
        </tr>
        <tr>
          <td />
          <td>{quoteRaw.valid_for_value}</td>
        </tr>
      </tbody>
    );
  } else if (type === paymentRequestType.BUY) {
    content = (
      <tbody>
        {quoteRaw.part_number && <tr><td colSpan={2}>{quoteRaw.part_number}</td></tr>}
        {quoteRaw.description && <tr><td colSpan={2}>{quoteRaw.description}</td></tr>}
        <tr>
          <td>{quoteRaw.condition}</td>
          <td>{quoteRaw.warranty}</td>
        </tr>
        <tr>
          <td>{quoteRaw.lead_time}</td>
          <td>{quoteRaw.qty}</td>
        </tr>
        <tr>
          <td>{quoteRaw.pickup_site}</td>
          <td>{quoteRaw.price_per_unit}</td>
        </tr>
        <tr>
          <td>
            {msg.tagDoc[0]?.url
              ? (
                <>
                  Tag:&nbsp;
                  <A target="_blank" rel="noopener noreferrer" to={`${msg.tagDoc[0]?.url}`}>{quoteRaw.tag_type}</A>
                </>
              )
              : `Tag: ${quoteRaw.tag_type}`}
          </td>
          <td>{quoteRaw.taxes}</td>
        </tr>
        <tr>
          <td>{quoteRaw.tagged_by}</td>
          <td><strong>{quoteRaw.total_price}</strong></td>
        </tr>
        <tr>
          <td>{quoteRaw.tag_date}</td>
          <td>{quoteRaw.minimum_order_value}</td>
        </tr>
        <tr>
          <td>{quoteRaw.trace_to}</td>
          <td>{quoteRaw.valid_for_value}</td>
        </tr>
      </tbody>
    );
  }
  return <QuoteTable>{content}</QuoteTable>;
};

const getMessageHeaderText = (q) => {
  let text = '';
  if (q === paymentRequestType.EXCHANGE) {
    text = 'Payment request (for Exchange):';
  } else if (q === paymentRequestType.LOAN) {
    text = 'Payment request (for Loan):';
  } else if (q === paymentRequestType.REPAIR) {
    text = 'Payment request (for Repair):';
  } else if (q === paymentRequestType.BUY) {
    text = 'Payment request (for Buy):';
  } else if (q === quoteTypes.QUOTE_EXCHANGE) {
    text = 'Quote details (for Exchange):';
  } else if (q === quoteTypes.QUOTE_LOAN) {
    text = 'Quote details (for Loan):';
  } else if (q === quoteTypes.QUOTE_REPAIR) {
    text = 'Quote details (for Repair):';
  } else {
    text = 'Quote details (for Buy):';
  }

  return text;
};

const getTableContentFromQuote = (q, NUM_OF_COLUMNS = 3) => {
  // 'data' is a 2D array that is needed to populate the table with the quote information
  if (!q) return null;
  const data = q.filter((d) => (!!d || d === '')).reduce((acc, d, i) => {
    const rowInd = Math.floor(i / NUM_OF_COLUMNS);
    const colInd = i % NUM_OF_COLUMNS;
    if (!acc[rowInd]) acc[rowInd] = [d];
    else acc[rowInd][colInd] = d;
    return acc;
  }, []);

  return (
    /* eslint-disable react/no-array-index-key */
    <table>
      <tbody>
        {
          data.map((row, i) => (
            <tr key={i}>
              {row.map((d, _i) => <td key={_i}>{d}</td>)}
            </tr>
          ))
        }
      </tbody>
    </table>
    /* eslint-enable react/no-array-index-key */
  );
};

/* TODO: resolve ESLINT issue */
// eslint-disable-next-line object-curly-newline
const Attachment = ({ text, docName, fontWeight, isDocRemoved, url, showMarkPoButton, onMarkPoHandler }) => (
  <AttachmentBody>
    {text && <Text padding="0px 0px 3px" fontWeight={fontWeight}>{text}</Text>}
    {docName && (
      <>
        <span>Attached: </span>
        <DocumentMsg href={url} target="_blank" isDocRemoved={isDocRemoved}>
          {!isDocRemoved && <DownloadIcon icon="download" />}
          {docName.toString()}
        </DocumentMsg>
      </>
    )}
    {showMarkPoButton && (
      <StyledLinkButton variant="link" onClick={onMarkPoHandler} title="mark as PO">
        <FontAwesomeIcon icon="file-import" />
      </StyledLinkButton>
    )}
  </AttachmentBody>
);

Attachment.propTypes = {
  text: PropTypes.string,
  fontWeight: PropTypes.string,
  docName: PropTypes.string,
  isDocRemoved: PropTypes.bool,
  showMarkPoButton: PropTypes.bool,
  url: PropTypes.string,
  onMarkPoHandler: PropTypes.func.isRequired,
};

const AttachedList = ({ files }) => {
  const status = useSelector(getSelectedTaskStatus);
  const dispatch = useDispatch();

  return (
    files?.map((doc) => (
      <Attachment
        key={doc.attachment_id}
        text={doc.comment}
        isDocRemoved={!!doc.deleted_at}
        url={doc.url}
        docName={doc.name}
        showMarkPoButton={doc.canBeMarkedPo && (status === null || status === taskStatus.IN_PROGRESS)}
        onMarkPoHandler={() => dispatch(markAsPo(doc.orderId, doc.attachment_id))}
      />
    ))
  );
};

const createRemovedList = (removedDocNames) => (
  removedDocNames?.map((docName, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <AttachmentBody key={i}>
      <span>
        Removed:
        &nbsp;
        {docName}
      </span>
    </AttachmentBody>
  ))
);

const getTableContentFromPrices = (price) => (
  /* eslint-disable react/no-array-index-key */
  <Container>
    <Column>
      <table>
        <tbody>
          {
            price.map((row, i) => (
              <tr key={i}>
                {row.map((d, _i) => <td key={_i}>{d}</td>)}
              </tr>
            ))
          }
        </tbody>
      </table>
    </Column>
  </Container>
/* eslint-disable react/no-array-index-key */
);

const getTitleText = (senderInfo, isBuyer) => {
  if (!senderInfo) return '';
  const { firstname, lastname, company_name: companyName } = senderInfo;
  const userTypeText = isBuyer ? 'Buyer' : 'Seller';

  let companyNameText = '';
  if (companyName && firstname === null && lastname === null) {
    companyNameText = ` ${companyName}`;
  } else if (companyName) {
    companyNameText = ` (${companyName})`;
  }
  return `${firstname ?? ''} ${lastname ?? ''}${companyNameText} - ${userTypeText}`;
};
const getMainColor = (isBuyer) => (isBuyer ? color.lightCyan : color.lightGrey);
const getTextColor = (isBuyer) => (isBuyer ? color.darkGrey : color.darkGrey);
const getLinkColor = (isBuyer) => (isBuyer ? 'inherit' : color.birch);
const getQuoteValidState = (quoteValidStatus) => {
  let indicator = null;
  if (quoteValidStatus === quoteValidationStatuses.INVALID_BY_ALGO) {
    indicator = (
      <InvalidQuoteIcon valid={false} title="The price of the quote is marked as invalid by automation">
        <FontAwesomeIcon icon="ban" />
        by automation
      </InvalidQuoteIcon>
    );
  } else if (quoteValidStatus === quoteValidationStatuses.INVALID_BY_BO) {
    indicator = (
      <InvalidQuoteIcon valid={false} title="The price of the quote is manually marked as invalid">
        <FontAwesomeIcon icon="ban" />
        manually
      </InvalidQuoteIcon>
    );
  } else if (quoteValidStatus === quoteValidationStatuses.VALID_BY_BO) {
    indicator = (
      <InvalidQuoteIcon valid title="The price of the quote is manually marked as valid">
        <FontAwesomeIcon icon="check" />
        valid
      </InvalidQuoteIcon>
    );
  }

  return indicator;
};

export default chatMessageContent;
