import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TaskTopBarWrapper = styled.div`
  height: 30px;
  background-color: #808080;
  padding-left: 0;
  padding-right: 0;
  position: relative;  
  div {
    margin-left: 20px;
    font-size: 16px;
    font-family: 'Lato','Helvetica Neue',Helvetica,Arial,sans-serif;
    color: #fff;
    text-transform: uppercase;
    line-height: 30px;
  }  
`;

const TopBar = ({ renderHeaderText }) => (
  <TaskTopBarWrapper>
    <div>{renderHeaderText()}</div>
  </TaskTopBarWrapper>
);

TopBar.propTypes = {
  renderHeaderText: PropTypes.func.isRequired,
};

export default TopBar;
