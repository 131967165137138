import axios from 'axios';

import {
  BANK_ACCOUNT_GET,
  BANK_ACCOUNT_GET_SUCCESS,
  BANK_ACCOUNT_SAVE,
  BANK_ACCOUNT_SAVE_SUCCESS,

  BANK_ACCOUNT_FAIL,
  BANK_ACCOUNT_RESET,
  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';

const startFetchingBankAccount = () => ({ type: BANK_ACCOUNT_GET });
const fetchBankAccountSuccess = (accountData) => ({
  type: BANK_ACCOUNT_GET_SUCCESS,
  payload: accountData,
});

const startSavingBankAccount = () => ({ type: BANK_ACCOUNT_SAVE });
const saveBankAccountSuccess = (accountData) => ({
  type: BANK_ACCOUNT_SAVE_SUCCESS,
  payload: accountData,
});

const bankAccountFail = () => ({ type: BANK_ACCOUNT_FAIL });
export const resetBankAccount = () => ({ type: BANK_ACCOUNT_RESET });

export const fetchBankAccount = () => async (dispatch) => {
  dispatch(startFetchingBankAccount());
  try {
    const response = await axios('rest/V2/eplane/backoffice/wiretransfer/account');
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchBankAccountSuccess(response.data?.payload));
    } else {
      requestErrorHandler(response, dispatch, bankAccountFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, bankAccountFail, e);
  }
};

export const saveBankAccount = (data) => async (dispatch) => {
  dispatch(startSavingBankAccount());
  try {
    const response = await axios.post('rest/V2/eplane/backoffice/wiretransfer/account', data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveBankAccountSuccess(response.data?.payload));
    } else {
      requestErrorHandler(response, dispatch, bankAccountFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, bankAccountFail, e);
  }
};
