import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Button, FormLabel, FormControl, Form as FormNormal, Modal,
} from 'react-bootstrap';
import dompurify from 'dompurify';

import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import { targetTypes } from '../../../constants/index';
import { ButtonContainer, ModalTitle } from '../../StyledComponents';

const Form = styled(FormNormal)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
const DeleteButton = styled(Button)`
  visibility: ${(props) => (props.isVisible ? 'hidden' : 'visible')};
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const UploadNews = ({
  selectedNewsItem, isShown, onHide, onUpload, onDelete,
}) => {
  const [newsItem, setNewsItem] = useState({
    title: '',
    subtitle: '',
    link: '',
    imageUrl: '',
    imageAltText: '',
    targetType: '',
    isVisible: false,
    inputChanged: false,
  });

  const text = selectedNewsItem ? 'Edit news' : 'Upload news';
  const isSaveDisabled = !newsItem.title || !newsItem.link || !newsItem.subtitle
    || !newsItem.imageUrl || !newsItem.imageAltText || !newsItem.targetType;

  useEffect(() => {
    setNewsItem({
      title: selectedNewsItem ? selectedNewsItem.title : '',
      subtitle: selectedNewsItem ? selectedNewsItem.subtitle : '',
      link: selectedNewsItem ? selectedNewsItem.link : '',
      imageUrl: selectedNewsItem ? selectedNewsItem.image_url : '',
      imageAltText: selectedNewsItem ? selectedNewsItem.image_alt_text : '',
      targetType: selectedNewsItem ? selectedNewsItem.target_type : '',
      isVisible: selectedNewsItem ? selectedNewsItem.is_visible : false,
      inputChanged: false,
    });
  }, [selectedNewsItem]);

  const onChangeHandler = (event) => {
    const { name } = event.target;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setNewsItem({ ...newsItem, [name]: value, inputChanged: true });
  };

  const onSendHandler = () => {
    const reqData = {
      title: newsItem.title,
      subtitle: dompurify.sanitize(newsItem.subtitle),
      link: newsItem.link,
      target_type: newsItem.targetType,
      image_alt_text: newsItem.imageAltText,
      image_url: newsItem.imageUrl,
      is_visible: newsItem.isVisible,
    };

    if (selectedNewsItem) {
      const newReqData = {
        news_id: selectedNewsItem.news_id,
        ...reqData,
      };
      onUpload(newReqData, true);
    } else {
      onUpload(reqData);
    }
    onHide();
  };

  const onDeleteNews = () => {
    onDelete(selectedNewsItem && selectedNewsItem.news_id);
    onHide();
  };

  return (
    <div>
      <Modal centered show={isShown} onHide={onHide}>
        <Modal.Body>
          <ModalTitle>{text}</ModalTitle>
          <Form>
            <Form.Group>
              <Form.Label>Image url</Form.Label>
              <FormControl
                type="text"
                placeholder=""
                value={newsItem.imageUrl}
                onChange={onChangeHandler}
                name="imageUrl"
                isInvalid={getValidation.imageUrl(newsItem)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Image alt text</Form.Label>
              <FormControl
                type="text"
                placeholder=""
                value={newsItem.imageAltText}
                onChange={onChangeHandler}
                name="imageAltText"
                isInvalid={getValidation.imageAltText(newsItem)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Title</Form.Label>
              <FormControl
                type="text"
                placeholder=""
                value={newsItem.title}
                onChange={onChangeHandler}
                name="title"
                isInvalid={getValidation.title(newsItem)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Subtitle</Form.Label>
              <FormControl
                type="text"
                placeholder=""
                value={newsItem.subtitle}
                onChange={onChangeHandler}
                name="subtitle"
                isInvalid={getValidation.subtitle(newsItem)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Link</Form.Label>
              <FormControl
                type="text"
                placeholder=""
                value={newsItem.link}
                onChange={onChangeHandler}
                name="link"
                isInvalid={getValidation.link(newsItem)}
              />
            </Form.Group>
            <BaseFormGroup
              validationState={getValidation.target(newsItem)}
              labelText="Target:"
              value={newsItem.targetType}
              onChange={onChangeHandler}
              options={getTargetOptionList()}
              as="select"
              name="targetType"
            />
            <Form.Group>
              <CheckboxStyled
                id="checkbox-is-visible"
                label={(<FormLabel>is visible </FormLabel>)}
                checked={newsItem.isVisible}
                name="isVisible"
                onChange={onChangeHandler}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ButtonsContainer>
            <DeleteButton disabled={!selectedNewsItem} variant="danger" onClick={onDeleteNews}>
              Delete
            </DeleteButton>
            <ButtonContainer space="20px">
              <Button disabled={isSaveDisabled} variant="success" onClick={onSendHandler}>
                Upload
              </Button>
              <Button variant="outline-secondary" onClick={() => onHide()}>
                Cancel
              </Button>
            </ButtonContainer>
          </ButtonsContainer>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const getTargetOptionList = () => ([
  ['', 'Select...'],
  [targetTypes.BLANK, 'Blank'],
  [targetTypes.PARENT, 'Parent'],
  [targetTypes.SELF, 'Self'],
  [targetTypes.TOP, 'Top'],
]);

const getValidation = {
  title: (state) => (state && !state.title ? 'error' : null),

  subtitle: (state) => (state.inputChanged && !state.subtitle ? 'error' : null),

  link: (state) => (state.inputChanged && !state.link ? 'error' : null),

  imageUrl: (state) => (state.inputChanged && !state.imageUrl ? 'error' : null),

  imageAltText: (state) => (state.inputChanged && !state.imageAltText ? 'error' : null),

  target: (state) => (state.inputChanged && !state.targetType ? 'error' : null),
};

UploadNews.propTypes = {
  selectedNewsItem: PropTypes.shape({
    created_at: PropTypes.string,
    is_visible: PropTypes.bool,
    subtitle: PropTypes.string,
    news_id: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    image_url: PropTypes.string,
    image_alt_text: PropTypes.string,
    target_type: PropTypes.string,
  }),
  isShown: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default UploadNews;
