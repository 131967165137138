import axios from 'axios';

import {
  PRO_MANAGEMENT_GET,
  PRO_MANAGEMENT_GET_SUCCESS,
  PRO_MANAGEMENT_CANCEL_SUB,
  PRO_MANAGEMENT_CANCEL_SUB_SUCCESS,
  PRO_MANAGEMENT_STOP_SUB,
  PRO_MANAGEMENT_STOP_SUB_SUCCESS,
  PRO_MANAGEMENT_CONTINUE_SUB,
  PRO_MANAGEMENT_CONTINUE_SUB_SUCCESS,
  PRO_MANAGEMENT_MANUAL_SUB,
  PRO_MANAGEMENT_MANUAL_SUB_SUCCESS,

  PRO_MANAGEMENT_HISTORY_GET,
  PRO_MANAGEMENT_HISTORY_GET_SUCCESS,

  PRO_MANAGEMENT_CLEAR,
  PRO_MANAGEMENT_FAIL,
  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';

const startFetchingSub = () => ({
  type: PRO_MANAGEMENT_GET,
});
const subFetchingSuccess = (data) => ({
  type: PRO_MANAGEMENT_GET_SUCCESS,
  payload: data,
});

const startFetchingHistory = () => ({
  type: PRO_MANAGEMENT_HISTORY_GET,
});
const historyFetchingSuccess = (data) => ({
  type: PRO_MANAGEMENT_HISTORY_GET_SUCCESS,
  payload: data,
});

const startSubCancel = () => ({
  type: PRO_MANAGEMENT_CANCEL_SUB,
});
const subCancelSuccess = (data) => ({
  type: PRO_MANAGEMENT_CANCEL_SUB_SUCCESS,
  payload: data,
});

const startSubStop = () => ({
  type: PRO_MANAGEMENT_STOP_SUB,
});
const subStopSuccess = (data) => ({
  type: PRO_MANAGEMENT_STOP_SUB_SUCCESS,
  payload: data,
});

const startSubContinue = () => ({
  type: PRO_MANAGEMENT_CONTINUE_SUB,
});
const subContinueSuccess = (data) => ({
  type: PRO_MANAGEMENT_CONTINUE_SUB_SUCCESS,
  payload: data,
});

const startManualSub = () => ({
  type: PRO_MANAGEMENT_MANUAL_SUB,
});
const manualSubSuccess = (data) => ({
  type: PRO_MANAGEMENT_MANUAL_SUB_SUCCESS,
  payload: data,
});

const proManagementFail = () => ({ type: PRO_MANAGEMENT_FAIL });

export const clearProSub = () => ({ type: PRO_MANAGEMENT_CLEAR });

export const fetchProSub = (userId) => async (dispatch) => {
  dispatch(startFetchingSub());
  try {
    const response = await axios(`/rest/V2/eplane/backoffice/pro-subscription/${userId}/get`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(subFetchingSuccess(response.data.payload?.subscription_data));
    } else {
      requestErrorHandler(response, dispatch, proManagementFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, proManagementFail, e);
  }
};

export const fetchPaymentHistory = (userId) => async (dispatch) => {
  dispatch(startFetchingHistory());
  try {
    const response = await axios(`/rest/V2/eplane/backoffice/pro-subscription/${userId}/payments`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(historyFetchingSuccess(response.data.payload?.payments));
    } else {
      requestErrorHandler(response, dispatch, proManagementFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, proManagementFail, e);
  }
};

export const stopSub = (userId) => async (dispatch) => {
  dispatch(startSubStop());
  try {
    const response = await axios.post(`/rest/V2/eplane/backoffice/pro-subscription/${userId}/stop`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(subStopSuccess(response.data.payload?.subscription_data));
    } else {
      requestErrorHandler(response, dispatch, proManagementFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, proManagementFail, e);
  }
};

export const cancelSub = (userId) => async (dispatch) => {
  dispatch(startSubCancel());
  try {
    const response = await axios.post(`/rest/V2/eplane/backoffice/pro-subscription/${userId}/cancel`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(subCancelSuccess(response.data.payload?.subscription_data));
    } else {
      requestErrorHandler(response, dispatch, proManagementFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, proManagementFail, e);
  }
};

export const continueSub = (userId) => async (dispatch) => {
  dispatch(startSubContinue());
  try {
    const response = await axios.post(`/rest/V2/eplane/backoffice/pro-subscription/${userId}/setup-manual`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(subContinueSuccess(response.data.payload?.subscription_data));
    } else {
      requestErrorHandler(response, dispatch, proManagementFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, proManagementFail, e);
  }
};

export const manualSub = (userId, data) => async (dispatch) => {
  dispatch(startManualSub());
  try {
    const response = await axios.post(`/rest/V2/eplane/backoffice/pro-subscription/${userId}/setup-manual`, data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(manualSubSuccess(response.data.payload?.subscription_data));
    } else {
      requestErrorHandler(response, dispatch, proManagementFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, proManagementFail, e);
  }
};
