import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { StyledLinkButton as LinkButtonNormal } from '../../StyledComponents';
import { EmptyListsContent, SortButton, getSortIcon } from '../SellersLists/Helpers';
import { color } from '../../../constants';

const Table = styled.table`
  margin-top: 50px;
  width: 100%;
  table-layout: fixed;

  thead {
    border-bottom: 2px solid ${color.grey};
  }
  th, td {
    padding: 5px 0;
    padding-right: 10px;
    text-align: left;
    vertical-align: top;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  th:first-child, td:first-child {
    padding-left: 10px;
    vertical-align: top;
  }
  th:last-child, td:last-child {
    text-align: right;
    padding-right: 5px;
    vertical-align: top;
  }
  .no-content-data {
    border-bottom: none;
  }
  td:last-child.section-title {
    padding-top: 15px;
    padding-bottom: 9px;
    border-top: 1px solid ${color.lightGrey};
    text-align: left;
  }
  td.remove-column {
    padding-right: 20px;
  }
`;
const RemoveButton = styled(LinkButtonNormal)`
  padding: 0;
`;
const removeFormatter = (id, sectionId, onRemoveHandler) => (
  <RemoveButton onClick={() => onRemoveHandler(id, sectionId)} variant="link" title="Remove">
    <FontAwesomeIcon icon="times" />
  </RemoveButton>
);

export default function BuyersListsTable({
  data, sortOrder, onRemoveHandler, onSortOrderChange,
}) {
  let tableBody = null;
  if (!data || data.every((section) => (section.data.length === 0))) {
    tableBody = (
      <tr className="no-content-data">
        <td colSpan="4"><EmptyListsContent text="You have no blocked buyer lists" /></td>
      </tr>
    );
  } else {
    tableBody = (
      <>
        {data.map((section) => (
          section.data.length > 0 && (
            <React.Fragment key={section.id}>
              <tr>
                <td className="section-title" colSpan="4"><b>{section.title}</b></td>
              </tr>
              {section.data.map((v) => (
                <tr key={v.filter_id}>
                  <td title={v.name}>
                    {v.name}
                  </td>
                  <td title={v.customer_name}>{v.customer_name}</td>
                  <td>{`${moment.utc(v.created_at).format('MMM D, YYYY, HH:mm')}`}</td>
                  <td className="remove-column">
                    {removeFormatter(v.filter_id, section.id, onRemoveHandler)}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          )
        ))}
      </>
    );
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>
            Blocked Buyers
            <SortButton variant="link" name="names" onClick={onSortOrderChange}>
              {getSortIcon(sortOrder?.columnName === 'names' && sortOrder.order)}
            </SortButton>
          </th>
          <th>
            Added By
            <SortButton variant="link" name="owner" onClick={onSortOrderChange}>
              {getSortIcon(sortOrder?.columnName === 'owner' && sortOrder.order)}
            </SortButton>
          </th>
          <th>
            Updated On (GMT)
            <SortButton variant="link" name="date" onClick={onSortOrderChange}>
              {getSortIcon(sortOrder?.columnName === 'date' && sortOrder.order)}
            </SortButton>
          </th>
          <th>Remove</th>
        </tr>
      </thead>
      <tbody>
        {tableBody}
      </tbody>
    </Table>
  );
}

BuyersListsTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]),
  sortOrder: PropTypes.shape({
    columnName: PropTypes.string,
    order: PropTypes.string,
  }),
  onRemoveHandler: PropTypes.func.isRequired,
  onSortOrderChange: PropTypes.func.isRequired,
};
