import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { FormSubTitle } from '../../FormComponents/TitleSection';
import { color, PUBLIC_URL } from '../../../constants';
import A from '../../A';

const ChangeButton = styled(A)`
  &&& {
    width: 0%;
    color: ${color.birch};
    font-size: 16px;
    padding-left: 10px;
    text-decoration: none;
  }
  &&&:hover {
    color: ${color.darkBirch};
  }
`;

const tooltip = (text) => (
  <Tooltip id="tooltip">
    {text}
  </Tooltip>
);

class ERPSystem extends React.PureComponent {
  render() {
    const { data, userId } = this.props;
    const formSubTitle = (data.length) ? (
      <FormSubTitle
        title="Company ERP system"
        showEditButton={false}
      />
    ) : <div />;

    return (
      <div>
        {formSubTitle}
        <p>
          {data}
        </p>
        <OverlayTrigger placement="left" overlay={tooltip('It can be changed in company settings tab')}>
          <ChangeButton rel="noopener noreferrer" to={`${PUBLIC_URL}/companies/${userId}/userSettings`}>
            <FontAwesomeIcon icon="pencil-alt" />
            &nbsp;
            Change
          </ChangeButton>
        </OverlayTrigger>
      </div>
    );
  }
}

ERPSystem.propTypes = {
  data: PropTypes.string,
  userId: PropTypes.string,
};

export default ERPSystem;
