import React, { useEffect } from 'react';
import { googleLogout } from '@react-oauth/google';
import { useDispatch } from 'react-redux';

import { logout } from '../../sagas/loginFlow';

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    googleLogout();
    dispatch(logout);
  }, [dispatch]);

  return (
    <></>
  );
}
