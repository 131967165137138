import axios from 'axios';

import {
  USER_NOTIFICATIONS_GET,
  USER_NOTIFICATIONS_SUCCESS,
  USER_NOTIFICATIONS_SAVE,
  USER_NOTIFICATIONS_SAVE_SUCCESS,

  USER_NOTIFICATIONS_FAIL,
  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startNotifications = () => ({
  type: USER_NOTIFICATIONS_GET,
});
const fetchNotificationsSuccess = (USER) => ({
  type: USER_NOTIFICATIONS_SUCCESS,
  payload: USER,
});

const startSaveNotifications = () => ({
  type: USER_NOTIFICATIONS_SAVE,
});
const saveNotificationsSuccess = (user) => ({
  type: USER_NOTIFICATIONS_SAVE_SUCCESS,
  payload: user,
});

const notificationsFail = () => ({ type: USER_NOTIFICATIONS_FAIL });

export const fetchNotifications = (customerId) => async (dispatch) => {
  dispatch(startNotifications());
  try {
    const response = await axios(`rest/default/V2/eplane/backoffice/customers/${customerId}/subscriptions`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchNotificationsSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, notificationsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, notificationsFail, e);
  }
};

export const saveNotifications = (customerId, saveData) => async (dispatch) => {
  dispatch(startSaveNotifications());
  try {
    const response = await axios.post(`rest/default/V2/eplane/backoffice/customers/${customerId}/subscriptions`, saveData);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveNotificationsSuccess(response.data && response.data.payload));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, notificationsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, notificationsFail, e);
  }
};
