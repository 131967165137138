import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledLinkButton as LinkButtonNormal } from '../../StyledComponents';

const LinkButton = styled(LinkButtonNormal)`
  font-size: 16px;
`;
const ActionContainer = styled.div`
  button:nth-child(odd) {
    margin-right: 10px;
  }
`;

const Actions = ({
  editMode,
  saveDisabled,
  onSaveHandler,
  onCancelHandler,
  onEditHandler,
  onRemoveHandler,
}) => (
  editMode
    ? (
      <ActionContainer>
        <LinkButton disabled={saveDisabled} onClick={onSaveHandler} variant="link">
          <FontAwesomeIcon icon="save" />
          &nbsp;
          <b>Save</b>
        </LinkButton>
        &nbsp;
        <LinkButton onClick={onCancelHandler} variant="link">
          <b>Cancel</b>
        </LinkButton>
      </ActionContainer>
    )
    : (
      <ActionContainer>
        <LinkButton onClick={onEditHandler} variant="link">
          <FontAwesomeIcon icon="pencil-alt" />
        </LinkButton>
        &nbsp;
        <LinkButton onClick={onRemoveHandler} variant="link">
          <FontAwesomeIcon icon="times" />
        </LinkButton>
      </ActionContainer>
    )
);

Actions.propTypes = {
  saveDisabled: PropTypes.bool,
  editMode: PropTypes.bool,
  onSaveHandler: PropTypes.func,
  onCancelHandler: PropTypes.func,
  onEditHandler: PropTypes.func,
  onRemoveHandler: PropTypes.func,
};

export default Actions;
