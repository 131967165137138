export const orderLimits = {
  C_LIMIT_ORDER: 10000000,
  C_LIMIT_TOTAL: 30000000,
  INTERNAL_ORDER_POSITION_MAX: 9999999,
};
export const routes = {
  USER_UPLOAD_DOCUMENT: '/backoffice/users/uploadDocument',
  USER_UPLOAD_LOGO: '/backoffice/users/uploadLogo',
  PN_LIST_DOWNLOAD_FILE: '/backoffice/pnlistjob/download',
  SELLERS_WEIGHTS_LIST_DOWNLOAD_FILE: '/backoffice/sellersweightslistjob/download',
  HOME_PAGE: '/',
  USER_CATALOG: '/catalog',
  USER_CATALOG_PRODUCT: '/catalog/seller_inventory',
  USER_CATALOG_HOW_TO_SYNC: '/catalog/how-to-sync',
  USER_CATALOG_PART_ADD: '/catalog/seller_inventory/partAdd',
  USER_CATALOG_REPAIR_ADD: '/catalog/seller_inventory/repairAdd',
  USER_CATALOG_PART_EDIT: '/catalog/seller_inventory/partEdit',
  USER_CATALOG_REPAIR_EDIT: '/catalog/seller_inventory/repairEdit',
  USER_CATALOG_PART_IMPORT: '/catalog/seller_inventory/partImport',
  USER_CATALOG_REPAIR_IMPORT: '/catalog/seller_inventory/repairImport',
  USER_CATALOG_IMPORT_UPLOAD: '/catalog/seller_inventory/import_upload',
  PRODUCT_DOCUMENT_UPLOAD: '/catalog/seller_inventory/upload_document',
  PRODUCT_IMAGE_UPLOAD: '/catalog/seller_inventory/upload_image',
  USER_ACCOUNT_REGISTR_BY_INVITATION: '/users/account/registerByInvitation',
  SEARCH_SUGGEST: '/search/ajax/suggest',
  SEARCH_SUGGEST_IATA: '/search/suggest/iata',
  SEARCH_SUGGEST_ORDER: '/search/suggest/order',
  SEARCH_QUERY_UPLOAD: '/search/query/upload',
  CATALOG_SEARCH_RESULT: '/catalog-search',
  CATALOG_SEARCH_RESULT_NO_RESULTS: '/catalog-search/no-results',
  NOT_ALLOWED_INFO: '/content/customer/notAllowedInfo',
  LOGIN_PAGE: '/account-login',
  CREATE_ACCOUNT: '/create-account',
  CUSTOMER_LOGOUT: '/users/account/logout',
  CUSTOMER_FORGOT_PASSWORD: '/users/account/forgotPassword',
  CUSTOMER_CREATE_PASSWORD: '/users/account/createPassword',
  CUSTOMER_CONFIRM_PASSWORD: '/users/account/confirm',
  CONTACT_US: '/contactus',
  BADGES: '/badges',
  CUSTOMER_SUPPORT: '/customersupport',
  PRIVACY_POLICY: '/about/privacy',
  TERMS: '/about/terms',
  ABOUT_INDEX: '/about',
  ABOUT_BENEFITS: '/about/benefits',
  ABOUT_FAQ: '/about/faq',
  ABOUT_FEATURES: '/about/features',
  ABOUT_JOIN: '/about/join',
  ABOUT_JOIN_REQUEST: '/content/corporate/joinRequest',
  ABOUT_PLATFORM: '/about/platform',
  ABOUT_PARTNERS: '/about/customers',
  ABOUT_VISION: '/about/vision',
  PART_VIEW: '/catalog/part/index',
  REPAIR_VIEW: '/catalog/repair/index',
  CATALOG_TAG: '/catalog/tag',
  RFQ_URL: '/rfq',
  ORDER_COMMENTS_ATTACH: '/sales/order_comments/attach',
  RFQ_COMMENTS_ATTACH: '/sales/rfq_comments/attach',
  RFQ_PDF_DOWNLOAD: '/sales/rfq/pdf/download',
  RFQ_ATTACHMENT_DOWNLOAD_PREVIEW: '/sales/rfq/attachment_preview',
  RFQ_ATTACHMENT_DOWNLOAD: '/sales/rfq/attachment',
  RFQ_MAIL_REPLY: '/sales/rfq/reply',
  COMPARE: '/compare',
  COMPARE_SAVE_TO_PDF: '/content/compare/downloadFile',
  ERROR_404: '/404',
  PROFILE: '/users/profile/manage',
  PROFILE_ACCOUNT: '/users/profile/manage/account',
  PROFILE_COMPANY: '/users/profile/manage/company',
  PROFILE_COMMERCIAL: '/users/profile/manage/commercial',
  PROFILE_SHIPMENT: '/users/profile/manage/shipment',
  PROFILE_USER_MANAGEMENT: '/users/profile/manage/users',
  PROFILE_CUSTOMER_MANAGEMENT: '/users/profile/manage/customers',
  PROFILE_BLOCKING: '/users/profile/manage/blocking',
  PROFILE_PREFERENCES: '/users/profile/manage/preferences',
  PROFILE_REGISTER_COMPANY: '/users/profile/manage/register_company',
  UNSUBSCRIBE: '/unsubscribe',
  SUBSCRIBE: '/subscribe',
  IMPORT_REPORT: '/uploader/import/report',
  IMPORT_DOWNLOAD_FILE: '/uploader/import/download',
  UPLOAD_PROFILE_DOCUMENT: '/users/profile/upload_document',
  UPLOAD_PROFILE_LOGO: '/users/profile/upload_logo',
  CHAT: '/chat',
  SEO_CATALOG_PAGE: '/parts',
  SEO_CATALOG_PART: '/part',
  DASHBOARD: '/dashboard',
  DASHBOARD_SEARCH_HISTORY: '/dashboard/search_history',
  DASHBOARD_ORDERS: '/dashboard/orders',
  DASHBOARD_ORDERS_PURCHASES: '/dashboard/orders/purchases',
  DASHBOARD_ORDERS_PURCHASES_NEW: '/dashboard/orders/purchases/new',
  DASHBOARD_ORDERS_PURCHASES_ACTIVE: '/dashboard/orders/purchases/active',
  DASHBOARD_ORDERS_PURCHASES_INACTIVE: '/dashboard/orders/purchases/inactive',
  DASHBOARD_ORDERS_SALES: '/dashboard/orders/sales',
  DASHBOARD_ORDERS_SALES_NEW: '/dashboard/orders/sales/new',
  DASHBOARD_ORDERS_SALES_ACTIVE: '/dashboard/orders/sales/active',
  DASHBOARD_ORDERS_SALES_INACTIVE: '/dashboard/orders/sales/inactive',
  STORAGE: '/storage',
  DASHBOARD_AUTOPILOT: '/dashboard/autopilot',
  DASHBOARD_AUTOPILOT_DONE: '/dashboard/autopilot/done',
  DASHBOARD_AUTOPILOT_ACTIVE: '/dashboard/autopilot/active',
  DASHBOARD_AUTOPILOT_ARCHIVED: '/dashboard/autopilot/archived',
  DASHBOARD_STATISTICS: '/dashboard/statistics',
  DASHBOARD_STATISTICS_PURCHASES: '/dashboard/statistics/purchases',
  DASHBOARD_STATISTICS_SALES: '/dashboard/statistics/sales',
  DASHBOARD_STATISTICS_INVENTORY: '/dashboard/statistics/inventory',
  DASHBOARD_MANAGE_FILTERS: '/dashboard/manage-filters',
  AUTOPILOT: '/autopilot',
  AUTOPILOT_DEMAND_RESULT: '/autopilot/demand/result',
  AUTOPILOT_DEMAND_EXPORT: '/autopilot/demand/export',
  AUTOPILOT_DEMAND_EXPORT_BY_IDS: '/autopilot/demand/export_by/ids',
  AUTOPILOT_DEMAND_EXPORT_BY_STATUS: '/autopilot/demand/export_by/status',
  AUTOPILOT_DEMAND_EXPORT_DOWNLOAD: '/autopilot/demand_jobs/export_download',
  AUTOPILOT_DEMAND_JOBS_UPLOAD: '/autopilot/demand_jobs/upload',
  AUTOPILOT_DEMAND_JOBS_REPORT: '/autopilot/demand_jobs/report',
  AUTOPILOT_DEMAND_JOBS_DOWNLOAD: '/autopilot/demand_jobs/download',
  STATS_SSO: '/stats/sso',
  STATS_SSO_LOGOUT: '/stats/sso/logout',
  STATS_BUYER: '/stats/buyer',
  STATS_SELLER: '/stats/seller',
  STATS_SEARCH: '/stats/search',
  STATS_BUYER_DEMO: '/stats/buyer/demo',
  STATS_SELLER_DEMO: '/stats/seller/demo',
  STATS_SEARCH_DEMO: '/stats/search/demo',
  FOR_BUYERS: '/for-buyers',
  FOR_SELLERS: '/for-sellers',
  SOLUTIONS: '/solutions',
  SOLUTIONS_AUTOMATION: '/solutions/automation',
  SOLUTIONS_INTEGRATION: '/solutions/integration',
  SOLUTIONS_MARKETPLACE: '/solutions/marketplace',
  SOLUTIONS_STATS: '/solutions/stats',
  JOIN_TO_COMPANY_APPROVE: '/company-registration/join-request/{:id}/approve',
  JOIN_TO_COMPANY_DECLINE: '/company-registration/join-request/{:id}/decline',
  API_REFERENCE: '/api-reference',
  EXTERNAL_IMAGES: '/extimages',
};
export const origin = {
  OEM: {
    id: 1,
    name: 'OEM',
  },
  PMA: {
    id: 2,
    name: 'PMA',
  },
};
export const itemType = {
  SERIAL: {
    id: 1,
    name: 'Serial',
  },
  BATCH: {
    id: 2,
    name: 'Batch',
  },
};
export const condition = {
  FN: {
    id: 21,
    name: 'FN',
    descr: 'Factory New',
  },
  NE: {
    id: 3,
    name: 'NE',
    descr: 'New',
  },
  NS: {
    id: 4,
    name: 'NS',
    descr: 'New Surplus',
  },
  OH: {
    id: 5,
    name: 'OH',
    descr: 'Overhauled',
  },
  SV: {
    id: 7,
    name: 'SV',
    descr: 'Serviceable',
  },
  RP: {
    id: 6,
    name: 'RP',
    descr: 'Repaired',
  },
  AR: {
    id: 1,
    name: 'AR',
    descr: 'As Removed',
  },
  TEST: {
    id: 8,
    name: 'TEST',
    descr: 'Tested',
  },
  MD: {
    id: 9,
    name: 'MD',
    descr: 'Modified',
  },
  IN: {
    id: 2,
    name: 'IN',
    descr: 'Inspected',
  },
  NR: {
    id: 10,
    name: 'NR',
    descr: 'Not Repaired',
  },
  NU: {
    id: 22,
    name: 'NU',
    descr: 'Factory New',
  },
  US: {
    id: 23,
    name: 'US',
    descr: 'Used/Serviceable',
  },
  XX: {
    id: 24,
    name: 'XX',
    descr: 'Scrap',
  },
  ST: {
    id: 25,
    name: 'ST',
    descr: 'Stolen/Missing',
  },
  EX: {
    id: 26,
    name: 'EX',
    descr: 'Exchange/Lease',
  },
  CR: {
    id: 30,
    name: 'CR',
    descr: 'Condition provided on request',
  },
};
export const dealTypes = {
  BUY: {
    id: 1,
    name: 'Buy',
  },
  EXCHANGE: {
    id: 2,
    name: 'Exchange',
  },
  LOAN: {
    id: 3,
    name: 'Loan',
  },
  REPAIR: {
    id: 4,
    name: 'Repair',
  },
};
export const orderDealTypes = {
  RFQ: {
    id: 200,
    name: 'RFQ',
  },
  BUY: {
    id: 1,
    name: 'Buy',
  },
  EXCHANGE: {
    id: 2,
    name: 'Exchange',
  },
  LOAN: {
    id: 3,
    name: 'Loan',
  },
  REPAIR: {
    id: 4,
    name: 'Repair',
  },
};
export const tagType = {
  FAA: {
    id: 1,
    name: 'FAA',
  },
  EASA: {
    id: 2,
    name: 'EASA',
  },
  CAAC: {
    id: 3,
    name: 'CAAC',
  },
  COC: {
    id: 4,
    name: 'COC',
  },
  OTHERS: {
    id: 5,
    name: 'Others',
  },
  MATERIALS: {
    id: 6,
    name: 'Materials',
  },
  CUSTOM: {
    id: 7,
    name: 'Custom',
  },
  FAA_EASA: {
    id: 8,
    name: 'FAA/EASA Dual',
  },
};
export const units = {
  EACH: {
    id: 'EA',
    name: 'Each',
  },
  KILOGRAMS_PER_LITER: {
    id: '3F',
    name: 'Kilograms per Liter',
  },
  TWO_HUNDRED_FIFTY: {
    id: 'AA',
    name: 'Two Hundred Fifty',
  },
  AMPOULE: {
    id: 'AM',
    name: 'Ampoule',
  },
  ASSORTMENT: {
    id: 'AT',
    name: 'Assortment',
  },
  TWENTY: {
    id: 'AX',
    name: 'Twenty',
  },
  ASSEMBLY: {
    id: 'AY',
    name: 'Assembly',
  },
  BALE: {
    id: 'BA',
    name: 'Bale',
  },
  BOBBIN: {
    id: 'BB',
    name: 'Bobbin',
  },
  BLOCK: {
    id: 'BC',
    name: 'Block',
  },
  BOARD_FOOT: {
    id: 'BF',
    name: 'Board Foot',
  },
  BAG: {
    id: 'BG',
    name: 'Bag',
  },
  BOOK: {
    id: 'BK',
    name: 'Book',
  },
  BARREL: {
    id: 'BL',
    name: 'Barrel',
  },
  BOLT: {
    id: 'BO',
    name: 'Bolt',
  },
  BAR: {
    id: 'BR',
    name: 'Bar',
  },
  BUSHEL: {
    id: 'BU',
    name: 'Bushel',
  },
  BOX: {
    id: 'BX',
    name: 'Box',
  },
  CAN: {
    id: 'CA',
    name: 'Can',
  },
  CARBOY: {
    id: 'CB',
    name: 'Carboy',
  },
  CUBIC_CENTIMETER: {
    id: 'CC',
    name: 'Cubic Centimeter',
  },
  DEGREES_CELSIUS: {
    id: 'CE',
    name: 'Degrees Celsius',
  },
  CUBIC_FOOT: {
    id: 'CF',
    name: 'Cubic Foot',
  },
  CENTIGRAM: {
    id: 'CG',
    name: 'Centigram',
  },
  CUBIC_INCH: {
    id: 'CI',
    name: 'Cubic Inch',
  },
  CAKE: {
    id: 'CK',
    name: 'Cake',
  },
  CYLINDER: {
    id: 'CL',
    name: 'Cylinder',
  },
  CENTIMETERS: {
    id: 'CM',
    name: 'Centimeters',
  },
  CARTON: {
    id: 'CN',
    name: 'Carton',
  },
  CONTAINER: {
    id: 'CO',
    name: 'Container',
  },
  CAPSULE: {
    id: 'CP',
    name: 'Capsule',
  },
  CARD: {
    id: 'CR',
    name: 'Card',
  },
  CASE: {
    id: 'CS',
    name: 'Case',
  },
  HUNDRED_FEET: {
    id: 'CT',
    name: 'Hundred Feet',
  },
  HUNDRED_POUNDS: {
    id: 'CW',
    name: 'Hundred Pounds',
  },
  CUBIC_YARD: {
    id: 'CY',
    name: 'Cubic Yard',
  },
  CUBIC_DECIMETER: {
    id: 'DC',
    name: 'Cubic Decimeter',
  },
  DECILITER: {
    id: 'DL',
    name: 'Deciliter',
  },
  DECIMETER: {
    id: 'DM',
    name: 'Decimeter',
  },
  DRAM: {
    id: 'DR',
    name: 'Dram',
  },
  DOZEN: {
    id: 'DZ',
    name: 'Dozen',
  },
  DEGREES_FAHRENHEIT: {
    id: 'FA',
    name: 'Degrees Fahrenheit',
  },
  FATHOM: {
    id: 'FM',
    name: 'Fathom',
  },
  FLUIDOUNCE_US: {
    id: 'FO',
    name: 'Fluidounce US',
  },
  FOOT: {
    id: 'FT',
    name: 'Foot',
  },
  FIFTY: {
    id: 'FY',
    name: 'Fifty',
  },
  FLUIDOUNCE_IMPERIAL: {
    id: 'FZ',
    name: 'Fluidounce Imperial',
  },
  GILL_IMPERIAL: {
    id: 'GC',
    name: 'Gill Imperial',
  },
  POUNDS_PER_GALLON: {
    id: 'GE',
    name: 'Pounds per Gallon',
  },
  GALLON_IMPERIAL: {
    id: 'GI',
    name: 'Gallon Imperial',
  },
  GALLON_US: {
    id: 'GL',
    name: 'Gallon US',
  },
  GRAM: {
    id: 'GM',
    name: 'Gram',
  },
  GRAIN: {
    id: 'GN',
    name: 'Grain',
  },
  GROUP: {
    id: 'GP',
    name: 'Group',
  },
  GROSS: {
    id: 'GR',
    name: 'Gross',
  },
  HUNDRED_CUBIC_METERS: {
    id: 'HC',
    name: 'Hundred Cubic Meters',
  },
  HECTOGRAM: {
    id: 'HG',
    name: 'Hectogram',
  },
  HUNDRED_KILOGRAM_QUINTAL: {
    id: 'HK',
    name: 'Hundred Kilogram Quintal',
  },
  HUNDRED_LITERS_HECTOLITER: {
    id: 'HL',
    name: 'Hundred Liters Hectoliter',
  },
  HUNDRED_METERS_HECTOMETER: {
    id: 'HM',
    name: 'Hundred Meters Hectometer',
  },
  HALF_PINT_US: {
    id: 'HP',
    name: 'Half Pint US',
  },
  HUNDRED_SQUARE_FEET: {
    id: 'HS',
    name: 'Hundred Square Feet',
  },
  HUNDRED: {
    id: 'HU',
    name: 'Hundred',
  },
  HUNDRED_WEIGHT: {
    id: 'HW',
    name: 'Hundred Weight',
  },
  HUNDRED_YARDS: {
    id: 'HY',
    name: 'Hundred Yards',
  },
  INCH: {
    id: 'IN',
    name: 'Inch',
  },
  UNIT: {
    id: 'IU',
    name: 'Unit',
  },
  JAR: {
    id: 'JR',
    name: 'Jar',
  },
  KILOGRAMS_PER_CUBIC_METER: {
    id: 'KC',
    name: 'Kilograms per Cubic Meter',
  },
  KILOGRAM: {
    id: 'KG',
    name: 'Kilogram',
  },
  KILOMETER: {
    id: 'KM',
    name: 'Kilometer',
  },
  COP: {
    id: 'KP',
    name: 'Cop',
  },
  KITG: {
    id: 'KT',
    name: 'Kitg',
  },
  POUND: {
    id: 'LB',
    name: 'Pound',
  },
  LENGTH: {
    id: 'LG',
    name: 'Length',
  },
  LITER: {
    id: 'LI',
    name: 'Liter',
  },
  LINEAR_METER: {
    id: 'LM',
    name: 'Linear Meter',
  },
  LOT: {
    id: 'LT',
    name: 'Lot',
  },
  CUBIC_METER: {
    id: 'MC',
    name: 'Cubic Meter',
  },
  METER: {
    id: 'ME',
    name: 'Meter',
  },
  THOUSAND_BOARD_FEET: {
    id: 'MF',
    name: 'Thousand Board Feet',
  },
  MILLIGRAM: {
    id: 'MG',
    name: 'Milligram',
  },
  MILLILITER: {
    id: 'ML',
    name: 'Milliliter',
  },
  MILLIMETER: {
    id: 'MM',
    name: 'Millimeter',
  },
  SQUARE_MILLIMETER: {
    id: 'MN',
    name: 'Square Millimeter',
  },
  THOUSAND_FEET: {
    id: 'MT',
    name: 'Thousand Feet',
  },
  THOUSAND_CUBIC_FEET: {
    id: 'MX',
    name: 'Thousand Cubic Feet',
  },
  OUTFIT: {
    id: 'OT',
    name: 'Outfit',
  },
  OUNCE: {
    id: 'OZ',
    name: 'Ounce',
  },
  PINT_IMPERIAL: {
    id: 'PB',
    name: 'Pint Imperial',
  },
  PIECE: {
    id: 'PC',
    name: 'Piece',
  },
  PAD: {
    id: 'PD',
    name: 'Pad',
  },
  PECK: {
    id: 'PE',
    name: 'Peck',
  },
  BUNDLE: {
    id: 'PK',
    name: 'Bundle',
  },
  PLATE: {
    id: 'PM',
    name: 'Plate',
  },
  PAIR: {
    id: 'PR',
    name: 'Pair',
  },
  PINT_US: {
    id: 'PT',
    name: 'Pint US',
  },
  PACKET: {
    id: 'PZ',
    name: 'Packet',
  },
  QUART_IMPERIAL: {
    id: 'QI',
    name: 'Quart Imperial',
  },
  QUARTER_KILOGRAM: {
    id: 'QK',
    name: 'Quarter Kilogram',
  },
  QUIRE: {
    id: 'QR',
    name: 'Quire',
  },
  QUART_US: {
    id: 'QT',
    name: 'Quart US',
  },
  RATION: {
    id: 'RA',
    name: 'Ration',
  },
  ROLL: {
    id: 'RL',
    name: 'Roll',
  },
  REAM: {
    id: 'RM',
    name: 'Ream',
  },
  SQUARE_CENTIMETER: {
    id: 'SC',
    name: 'Square Centimeter',
  },
  SQUARE_DECIMETER: {
    id: 'SD',
    name: 'Square Decimeter',
  },
  SQUARE_FOOT: {
    id: 'SF',
    name: 'Square Foot',
  },
  SHEET: {
    id: 'SH',
    name: 'Sheet',
  },
  SQUARE_INCH: {
    id: 'SI',
    name: 'Square Inch',
  },
  STICK: {
    id: 'SK',
    name: 'Stick',
  },
  SQUARE_METER: {
    id: 'SM',
    name: 'Square Meter',
  },
  SKEIN: {
    id: 'SN',
    name: 'Skein',
  },
  SHOT: {
    id: 'SO',
    name: 'Shot',
  },
  STRIP: {
    id: 'SP',
    name: 'Strip',
  },
  SET: {
    id: 'ST',
    name: 'Set',
  },
  SQUARE_YARD: {
    id: 'SY',
    name: 'Square Yard',
  },
  TEN: {
    id: 'TE',
    name: 'Ten',
  },
  THOUSAND: {
    id: 'TH',
    name: 'Thousand',
  },
  TON_METRIC: {
    id: 'TK',
    name: 'Ton Metric',
  },
  THOUSAND_LITER: {
    id: 'TL',
    name: 'Thousand Liter',
  },
  TON_SHORT: {
    id: 'TN',
    name: 'Ton Short',
  },
  TROY_OUNCE: {
    id: 'TO',
    name: 'Troy Ounce',
  },
  TABLET: {
    id: 'TT',
    name: 'Tablet',
  },
  TUBE: {
    id: 'TU',
    name: 'Tube',
  },
  TON_LONG: {
    id: 'TX',
    name: 'Ton Long',
  },
  FIVE_HUNDRED: {
    id: 'VC',
    name: 'Five Hundred',
  },
  VIAL: {
    id: 'VI',
    name: 'Vial',
  },
  YARD: {
    id: 'YD',
    name: 'Yard',
  },
  SYPHON: {
    id: 'ZV',
    name: 'Syphon',
  },
};
export const userType = {
  PRIVATE: {
    id: 1,
    name: 'Private',
  },
  COMPANY: {
    id: 2,
    name: 'Company',
  },
};
export const companyType = {
  AIRLINE: {
    id: 1,
    name: 'Airline',
  },
  MRO: {
    id: 2,
    name: 'MRO',
  },
  PMA: {
    id: 3,
    name: 'PMA',
  },
  OEM: {
    id: 4,
    name: 'OEM',
  },
  BROKER: {
    id: 5,
    name: 'Broker',
  },
  DISTRIBUTOR: {
    id: 6,
    name: 'Distributor',
  },
  OTHER: {
    id: 7,
    name: 'Other',
  },
};
export const errorCodes = {
  SUCCESS: 1000,
  SUCCESS_DEMAND_MOVED_FROM_ARCHIVED: 1005,
  SUCCESS_DEMAND_EXPORT_QUEUED: 1010,
  SUCCESS_DEMAND_EXPORT_READY: 1011,
  ERR_API_BASE: 1,
  ERR_PARSE_INPUT: 2,
  ERR_RENDER_OUTPUT: 3,
  ERR_INVALID_INPUT: 4,
  ERR_INVALID_KEY: 5,
  ERR_INVALID_VALUE: 6,
  ERR_REQUIRED_FIELD: 7,
  ERR_REQUIRED_VALUE: 8,
  ERR_DENIED: 9,
  ERR_ENTITY_NOT_FOUND: 10,
  ERR_ENTITY_ALREADY_EXIST: 11,
  ERR_ALREADY_DONE: 12,
  ERR_LIMIT_EXCEEDED: 13,
  ERR_NETWORK_ERROR: 14,
  ERR_NOT_PERMITTED: 15,
  ERR_RESTV2_BASE: 9000,
  ERR_CATALOG_BASE: 5000,
  ERR_ORDER_BASE: 6000,
  ERR_SEARCH_BASE: 7000,
  ERR_CHAT_BASE: 8000,
  SUCCESS_ADDED_TO_CART: 6999,
  SUCCESS_REDIRECT: 6998,
  ERROR_ORDER_NOT_CREATED: 1400,
  ERROR_PRODUCTS_NOT_AVAILABLE: 1401,
  ERROR_NO_PRODUCT_IN_STOCK: 1404,
  ERROR_PRODUCT_NOT_PUBLISHED: 1403,
  ERROR_ITEMS_NOT_IN_CART: 1405,
  ERROR_INPUT_VALIDATION: 1406,
  ERROR_SITE_IS_NOT_EMPTY: 1407,
  ERROR_PRODUCT_NOT_FOUND: 1412,
  ERROR_PRODUCT_WAS_UPDATED: 1413,
  ERROR_PRODUCT_ORDERED_ALREADY: 1414,
  ERROR_CAN_NOT_CHANGE_ORDER: 1500,
  ERROR_UPLOAD_FILE: 1700,
  ERROR_WRONG_FILE_CONTENT: 1701,
  ERROR_UNSUPPORTED_FILE_TYPE: 1702,
  ERROR_EMPTY_FILE: 1703,
  ERROR_MULTIPLE_EMPTY_FILES: 1704,
  ERROR_CAN_NOT_EDIT: 1800,
  ERROR_USER_LOCKED: 1801,
  ERROR_LAST_ACTIVE_PAYMENT_METHOD: 1802,
  ERROR_LOGO_FILE_IS_TOO_BIG: 1806,
  ERROR_CAN_NOT_SEND_EMAIL: 1900,
  ERROR_CAN_NOT_ADD_FOR_SEND_EMAIL: 1901,
  ERROR_CAN_NOT_CREATE_PDF: 1950,
  ERROR_SELLER_INVENTORY_GENERAL: 1001,
  ERROR_SELLER_INVENTORY_VALIDATION: 1002,
  ERROR_SELLER_INVENTORY_CANT_UPDATE: 1004,
  ERROR_CHAT_NOT_PUBLISHED: 8001,
  ERROR_DEMAND_NOT_ADDED: 2001,
  ERROR_DEMAND_MISSING_FIELDS: 2002,
  ERROR_DEMAND_ALREADY_EXISTS: 2003,
  ERROR_DEMAND_PN_EXISTS: 2004,
  ERROR_DEMAND_NOT_CHANGED: 2005,
  ERROR_DEMAND_ADDED_PARTIAL: 2006,
  ERROR_EAPI_GENERAL: 3000,
  ERROR_EAPI_NOT_FETCHED: 3001,
  ERROR_EAPI_NOT_FOUND: 3002,
  ERROR_API_BAD_XML: 4001,
  ERROR_CAN_NOT_CREATE_QUOTE_MESSAGE: 5100,
  ERROR_CAN_NOT_EDIT_QUOTE_MESSAGE: 5101,
  ERROR_CAN_NOT_DELETE_QUOTE_MESSAGE: 5102,
  ERROR_RESTV2_GENERAL: 9001,
  ERROR_RESTV2_INVITATION_EXPIRED: 9002,
  ERROR_RESTV2_INVITATION_REGISTERED: 9003,
  ERROR_RESTV2_JOIN_COMPANY_DECIDED: 9004,
  ERROR_RESTV2_STATS_NO_DATA: 9005,
  ERROR_RESTV2_DEMAND_ALREADY_EXISTS_PN: 9006,
  ERROR_RESTV2_DEMAND_ALREADY_EXISTS_PN_ORDER: 9007,
  ERROR_RESTV2_DEMAND_ALREADY_EXISTS_PN_ORDER_POSITION: 9008,
};
export const siteType = {
  SHIPPING_SITE: 1,
  PICKUP_SITE: 2,
  BILLING_SITE: 3,
  QUOTE_PICKUP_SITE: 4,
};
export const orderStatus = {
  PENDING_RFQ: {
    id: 'pending_rfq',
    name: 'New inquiry/Pending seller\'s response',
  },
  PENDING_RFQ_PO_ATTACHED: {
    id: 'pending_rfq_po_attached',
    name: 'PO has been attached',
  },
  PENDING_RFQ_BUYER_RM_PO: {
    id: 'pending_rfq_buyer_rm_po',
    name: 'PO has been removed',
  },
  PENDING_RFQ_BUYER_RM_FILE: {
    id: 'pending_rfq_buyer_rm_file',
    name: 'File has been removed',
  },
  ACT_RFQ_SELLER_RESPONDED: {
    id: 'act_rfq_seller_responded',
    name: 'Active chat/New message from the seller',
  },
  ACT_RFQ_BUYER_RESPONDED: {
    id: 'act_rfq_buyer_responded',
    name: 'New message from the buyer/Active chat',
  },
  ACT_RFQ_INVOICE_ATTACHED: {
    id: 'act_rfq_invoice_attached',
    name: 'Invoice has been attached',
  },
  ACT_RFQ_QUOTE_ATTACHED: {
    id: 'act_rfq_quote_attached',
    name: 'Quote has been attached',
  },
  ACT_RFQ_NO_QUOTE: {
    id: 'act_rfq_no_quote',
    name: 'Active chat/Seller responded \'No quote\'',
  },
  ACT_RFQ_PO_ATTACHED: {
    id: 'act_rfq_po_attached',
    name: 'PO has been attached',
  },
  INACTIVE_RFQ: {
    id: 'inactive_rfq',
    name: 'Inactive inquiry',
  },
  ACT_RFQ_BUYER_RM_PO: {
    id: 'act_rfq_buyer_rm_po',
    name: 'PO has been removed',
  },
  ACT_RFQ_BUYER_RM_FILE: {
    id: 'act_rfq_buyer_rm_file',
    name: 'File has been removed',
  },
  ACT_RFQ_SELLER_RM_TAG: {
    id: 'act_rfq_seller_rm_tag',
    name: 'Tag has been removed',
  },
  ACT_RFQ_SELLER_RM_INVOICE: {
    id: 'act_rfq_seller_rm_invoice',
    name: 'Invoice has been removed',
  },
  ACT_RFQ_SELLER_RM_QUOTE: {
    id: 'act_rfq_seller_rm_quote',
    name: 'Quote has been removed',
  },
  ACT_RFQ_SELLER_RM_FILE: {
    id: 'act_rfq_seller_rm_file',
    name: 'File has been removed',
  },
  ACT_RFQ_SELLER_ACK_PO: {
    id: 'act_rfq_seller_ack_po',
    name: 'PO acknowledged',
  },
  ACT_RFQ_SELLER_REJ_PO: {
    id: 'act_rfq_seller_rej_po',
    name: 'PO rejected',
  },
  ACT_RFQ_SELLER_EDITED_PO: {
    id: 'act_rfq_seller_edited_po',
    name: 'PO edited',
  },
  ACT_RFQ_SELLER_SHIP_ADDED: {
    id: 'act_rfq_seller_ship_added',
    name: 'Shipment details have been added',
  },
  PAYMENT_NEW_REQUEST: {
    id: 'payment_new_request',
    name: 'Your payment request was sent to the buyer/The seller has sent a payment request for this order',
  },
  PAYMENT_NEW_REQUEST_TO_EPLANE: {
    id: 'payment_new_request_to_eplane',
    name: 'ePlane sent a payment request for this order/ePlane sent a payment request for this order',
  },
  PAYMENT_COMPLETED: {
    id: 'payment_completed',
    name: 'The order has been paid successfully/The order has been paid successfully',
  },
  PAYMENT_COMPLETED_TO_EPLANE: {
    id: 'payment_completed_to_eplane',
    name: 'The buyer paid successfully to ePlane. ePlane will contact your company soon to buy the item/The order has been paid'
      + ' successfully to ePlane. ePlane will contact the seller soon to buy the item',
  },
  PAYMENT_PROCESSING: {
    id: 'payment_processing',
    name: 'The payment for this order is being processed/Your payment for this order is being processed',
  },
  PAYMENT_PROCESSING_TO_EPLANE: {
    id: 'payment_processing_to_eplane',
    name: 'The buyer\'s payment to ePlane is being processed/Your payment to ePlane is being processed',
  },
  PAYMENT_FAILED: {
    id: 'payment_failed',
    name: 'The payment for this order failed/Your payment for this order failed',
  },
  PAYMENT_FAILED_TO_EPLANE: {
    id: 'payment_failed_to_eplane',
    name: 'The buyer\'s payment to ePlane failed/Your payment to ePlane failed',
  },
  PAYMENT_REQUEST_EXPIRED: {
    id: 'payment_request_expired',
    name: 'The payment request for this order has been expired/, send a new one if required',
  },
  PAYMENT_REQUEST_REQUESTED: {
    id: 'payment_request_requested',
    name: 'The buyer requested to pay for this order./Your request to pay for this order has been sent to the seller',
  },
  PAYMENT_AWAITING_PO: {
    id: 'payment_awaiting_po',
    name: '/Please upload PO to ePlane',
  },
  AWAITING_SHIPMENT: {
    id: 'awaiting_shipment',
    name: 'The order has been paid successfully by ePlane.'
    + ' Please make sure the order is shipped on time to the buyer\'s shipping address/The order has been paid successfully by ePlane.'
    + ' You will be notified once the shipment is on its way to you',
  },
  SHIPPED: {
    id: 'shipped',
    name: 'The order has been shipped to the buyer\'s shipping address/The order has been shipped to you',
  },
  EPLANE_REVIEW: {
    id: 'eplane_review',
    name: 'The order is currently being reviewed by ePlane/The order is currently being reviewed by ePlane',
  },
  EPLANE_COMMENT: {
    id: 'eplane_new_message',
  },
};
export const orderAction = {
  EXPIRE: 'expire',
  CREATE: 'create',
  UPDATE: 'update',
  COMMENT: 'comment',
  ATT_PO: 'attach_po',
  ATT_INV: 'attach_invoice',
  ATT_QUOTE: 'attach_quote',
  NO_QUOTE: 'no_quote',
  RM_PO: 'remove_po',
  RM_INV: 'remove_invoice',
  RM_QUOTE: 'remove_quote',
  RM_TAG: 'remove_tag',
  RM_FILE: 'remove_file',
  ACK_PO: 'acknowledge_po',
  REJ_PO: 'reject_po',
  EDIT_PO: 'edit_po',
  ADD_SHIP: 'add_shipment',
  BOT_CREATE_QUOTE: 'bot_create_quote',
  BOT_EDIT_QUOTE: 'bot_edit_quote',
  BOT_DELETE_QUOTE: 'bot_delete_quote',
  BOT_NO_QUOTE: 'bot_no_quote',
};
export const orderItemAction = {
  UPDATE: 'update',
  DELETE: 'delete',
};
export const documentType = {
  SALES_AGREEMENT: {
    id: 1,
    name: 'Sales Agreement',
  },
  PURCHASE_AGREEMENT: {
    id: 2,
    name: 'Purchase Agreement',
  },
  EXCHANGE_AGREEMENT: {
    id: 3,
    name: 'Exchange Agreement',
  },
  LOAN_AGREEMENT: {
    id: 4,
    name: 'Loan Agreement',
  },
  REPAIR_AGREEMENT: {
    id: 5,
    name: 'Repair Agreement',
  },
  GENERIC_DOCUMENT: {
    id: 6,
    name: 'Generic Agreement',
  },
  ISO_CERTIFICATE: {
    id: 7,
    name: 'ISO Certificate',
  },
  REPAIR_CERTIFICATE: {
    id: 8,
    name: 'Repair Shop Certificate',
  },
};
export const customerStatus = {
  PENDING: {
    id: 1,
    name: 'Pending',
  },
  ACTIVE: {
    id: 2,
    name: 'Active',
  },
  INACTIVE: {
    id: 3,
    name: 'Inactive',
  },
  DELETED: {
    id: 4,
    name: 'Deleted',
  },
};
export const companyCertification = {
  AS9100: {
    id: 1,
    name: 'AS 9100',
  },
  AS9110: {
    id: 2,
    name: 'AS 9110',
  },
  AS9120: {
    id: 3,
    name: 'AS 9120',
  },
};
export const orderType = {
  INCOMING_ORDERS: {
    id: 1,
    name: 'Incoming Orders',
  },
  OUTGOING_ORDERS: {
    id: 2,
    name: 'Outgoing Orders',
  },
};
export const orderNumberType = {
  EPLANE: 'eplane',
  INTERNAL: 'internal',
};
export const itemState = {
  AVAILABLE: 0,
  PRODUCT_VENDOR_OR_SITE_WAS_CHANGED: 1,
  PRODUCT_NOT_PUBLISHED: 5,
  PRODUCT_IS_DELETED: 6,
  PRODUCT_WAS_UPDATED: 7,
  ITEM_IS_DELETED: 9,
  UNAVAILABLE_GENERIC: 10,
};
export const importJobType = {
  PARTS: 'parts',
  REPAIR: 'repair',
};
export const pricePolicy = {
  FLAT: {
    id: 1,
    name: 'Flat rate pricing',
  },
  AVERAGE: {
    id: 2,
    name: 'Avg pricing',
  },
  EVALUATE: {
    id: 3,
    name: 'Price to be determined after evaluation',
  },
};
export const catalogItemStatus = {
  NOT_PUBLISHED: {
    id: 1,
    name: 'Not published',
  },
  PUBLISHED: {
    id: 2,
    name: 'Published',
  },
};
export const chatAtchRfqRole = {
  GENERAL: 'general',
  TAG: 'tag',
  INVOICE: 'invoice',
  QUOTE: 'quote',
  PO: 'po',
};
export const chatMsgType = {
  TEXT: 'text',
  ATCH_ADD: 'atch_add',
  ATCH_DEL: 'atch_del',
  QUOTE: 'quote',
  NO_QUOTE: 'no_quote',
  PO: 'po',
  ACK_PO: 'ack_po',
  REJ_PO: 'rej_po',
  EDIT_PO: 'edit_po',
  SHIP: 'ship',

  PR_REQUEST: 'pr_request',
  PR_MSG: 'pr_msg',
  PAYMENT_MSG: 'payment_msg',
  PR_EXPIRED: 'pr_expired',

  EPLANE_PAYMENT_REQUEST: 'ep_pr',
  EPLANE_PO_REQUEST: 'ep_po_request',
  EPLANE_BOUGHT_ITEM: 'ep_bought_item',
  EPLANE_ORDER_SHIPPED: 'ep_order_shipped',
  EPLANE_REVIEW: 'ep_review',
  EPLANE_PAYMENT_COMPLETE: 'ep_payment_complete',
  EPLANE_PAYMENT_FAILED: 'ep_payment_failed',
  EPLANE_PAYMENT_PROCESSING: 'ep_payment_processing',
  EPLANE_WT_CONFIRM: 'ep_wt_confirm',
  EPP_REPORT: 'epp_report',

  PAYMENT_COMPLETE: 'payment_complete',
  PAYMENT_FAILED: 'payment_failed',
  PAYMENT_PROCESSING: 'payment_processing',
};
export const channelMsgType = {
  RFQ_CHAT: 'rfq_chat',
  NOTIFICATION_NEW: 'notification_new',
  NOTIFICATION_CLOSE: 'notification_close',
  DEMAND_UPLOAD_JOB_UPDATE: 'demand_upload_job_update',
  CUSTOMER_UPDATED: 'customer_updated',
};
export const orderSubset = {
  NEW: 'new',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  RECENT: 'recent',
};
export const orderDirection = {
  SELL: 'sell',
  BUY: 'buy',
  BOTH: 'both',
};
export const chatDriverType = {
  DEVNULL: 'devnull',
  NCHAN: 'nchan',
  SOCKETIO: 'socketio',
};
export const demandUploadJobStatus = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  FAILED: 'failed',
  STUCK: 'stuck',
  ONHOLD: 'onhold',
};
export const demandSearchSortOrder = {
  PRIORITY_UPLOAD_DATE: {
    id: 'priority_upload_date',
    name: 'Priority + Upload Date',
  },
  UPLOAD_DATE: {
    id: 'upload_date',
    name: 'Upload Date',
  },
  NUMBER_OF_REPLIES: {
    id: 'number_replies',
    name: 'Number of Replies',
  },
  PRIORITY: {
    id: 'priority',
    name: 'Priority',
  },
};
export const demandQueryType = {
  PN: 'pn',
  INTERNAL: 'internal',
};
export const demandPriority = {
  AOG: {
    id: 300,
    name: 'AOG',
  },
  CRITICAL: {
    id: 200,
    name: 'Critical',
  },
  EXPEDITE: {
    id: 150,
    name: 'Expedite',
  },
  ROUTINE: {
    id: 100,
    name: 'Routine',
  },
};
export const demandStatus = {
  COLLECTING: {
    id: 'collecting',
    name: 'Collecting',
  },
  DONE: {
    id: 'done',
    name: 'Done',
  },
};
export const demandViewSortOrder = {
  DEFAULT_SORT: {
    id: 'default_sort',
    name: 'Default Sorting',
  },
  REPLIES_DESC: {
    id: 'replies_desc',
    name: 'Unread Replies',
  },
  PRICE_DESC: {
    id: 'price_desc',
    name: 'Price: Highest First',
  },
  LEAD_TIME: {
    id: 'lead_time',
    name: 'Lead Time',
  },
  COMPANY_ASC: {
    id: 'company_asc',
    name: 'Company A-Z',
  },
  COMPANY_DESC: {
    id: 'company_desc',
    name: 'Company Z-A',
  },
};
export const poStatus = {
  NO_PO: {
    id: 0,
    name: 'NO PO',
  },
  ACTIVE_PO: {
    id: 1,
    name: 'ACTIVE PO',
  },
  ACKNOWLEDGED_PO: {
    id: 2,
    name: 'ACKNOWLEDGED PO',
  },
  SHIPPING_PO: {
    id: 3,
    name: 'SHIPPING PO',
  },
};
export const poAction = {
  EDIT: 'edit',
  REJECT: 'reject',
  ACKNOWLEDGE: 'acknowledge',
};
export const poInterchangeabilityCode = {
  ONE_WAY: {
    id: 1,
    name: 'One‐Way Interchangeable',
  },
  TWO_WAY: {
    id: 2,
    name: 'Two‐Way Interchangeable',
  },
  NOT_INTERCHANGEABLE: {
    id: 3,
    name: 'Not Interchangeable',
  },
  AS_A_SET: {
    id: 4,
    name: 'Interchangeable As A Set',
  },
  QUALIFIED: {
    id: 5,
    name: 'Qualified Interchangeability',
  },
  PART_REIDENTIFICATION: {
    id: 6,
    name: 'Part Reidentification',
  },
};
export const mailSubscriptionType = {
  SUBSCRIBE_MC_PRODUCT: 'mc_product',
  SUBSCRIBE_MC_NEWS: 'mc_news',
  SUBSCRIBE_GENERAL: 'general',
  SUBSCRIBE_DAILY_DIGEST: 'daily_digest',
  SUBSCRIBE_RFQ_DIGEST: 'rfq_digest',
  SUBSCRIBE_PURCHASE_REPORT: 'purchase',
  SUBSCRIBE_SALES_REPORT: 'sales',
  SUBSCRIBE_INVENTORY_REPAIR: 'inventory_repair',
  SUBSCRIBE_DEMAND_UPLOAD: 'demand_upload',
};
export const attach = {
  forbiddenExtensions: [
    '0xe',
    '73k',
    '89k',
    '8ck',
    'a6p',
    'a7r',
    'acr',
    'actc',
    'action',
    'actm',
    'ahk',
    'app',
    'applescript',
    'arscript',
    'asb',
    'azw2',
    'ba_',
    'beam',
    'btm',
    'caction',
    'cel',
    'celx',
    'cgi',
    'cof',
    'coffee',
    'command',
    'cyw',
    'dld',
    'dmc',
    'dxl',
    'e_e',
    'ear',
    'ebm',
    'ebs',
    'ebs2',
    'ecf',
    'eham',
    'elf',
    'epk',
    'es',
    'esh',
    'ex4',
    'ex5',
    'ex_',
    'exe1',
    'exopc',
    'ezs',
    'ezt',
    'fas',
    'fky',
    'fpi',
    'frs',
    'gadget',
    'gpe',
    'gpu',
    'gs',
    'hms',
    'hpf',
    'icd',
    'iim',
    'ipf',
    'isu',
    'ita',
    'jse',
    'jsf',
    'kix',
    'ksh',
    'kx',
    'lo',
    'ls',
    'm3g',
    'mam',
    'mel',
    'mem',
    'mio',
    'mlx',
    'mpx',
    'mrp',
    'mxe',
    'n',
    'nexe',
    'nro',
    'ore',
    'osx',
    'otm',
    'out',
    'paf',
    'pex',
    'phar',
    'plsc',
    'prg',
    'ps1',
    'pyo',
    'qit',
    'qpx',
    'rbf',
    'rbx',
    'rfu',
    'rgs',
    'rox',
    'rpj',
    'run',
    'rxe',
    's2a',
    'sbs',
    'sca',
    'scar',
    'scb',
    'scpt',
    'scptd',
    'scr',
    'script',
    'server',
    'shb',
    'tcp',
    'thm',
    'tiapp',
    'tms',
    'u3p',
    'udf',
    'upx',
    'vbe',
    'vbscript',
    'vdo',
    'vexe',
    'vlx',
    'vxp',
    'widget',
    'workflow',
    'wpk',
    'wpm',
    'wsf',
    'wsh',
    'x86',
    'xqt',
    'xys',
    'zl9',
    'ac',
    'acc',
    'air',
    'apk',
    'bat',
    'bat',
    'bat',
    'cmd',
    'bat',
    'bin',
    'cmd',
    'com',
    'exe',
    'pif',
    'bat',
    'com',
    'exe',
    'bat',
    'com',
    'exe',
    'com',
    'com',
    'csh',
    'csh',
    'csh',
    'dek',
    'ds',
    'exe',
    'exe',
    'exe',
    'exe',
    'exe',
    'exe',
    'exe',
    'fxp',
    'ham',
    'hta',
    'ipa',
    'jar',
    'jar',
    'jar',
    'jar',
    'jar',
    'jar',
    'jar',
    'jar',
    'js',
    'js',
    'js',
    'jsx',
    'mac',
    'mac',
    'mac',
    'mac',
    'mac',
    'mac',
    'mac',
    'mcr',
    'mm',
    'mrc',
    'ms',
    'ms',
    'msl',
    'plx',
    'prc',
    'prc',
    'prc',
    'prc',
    'pvd',
    'pwc',
    'pyc',
    'pyc',
    'sct',
    'seed',
    'smm',
    'smm',
    'spr',
    'vbs',
    'vbs',
    'vbs',
    'vbs',
    'vpm',
    'wcm',
    'wiz',
    'ws',
    'ws',
    'ws',
    'xap',
    'xbap',
    'xlm',
    'xlm',
    'xlm',
    'xlm',
    'xlm',
    'xlm',
  ],
};
export const rfqFilterType = {
  PURCHASES: 'purchases',
  SALES: 'sales',
  AUTOPILOT: 'autopilot',
};
export const rfqFilterStatus = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  SYSTEM: 'system',
};
export const customerSubscriptionType = {
  MAILCHIMP_PRODUCT: 'mailchimp_product',
  MAILCHIMP_NEWS_EVENT: 'mailchimp_news_event',
  ACCOUNT_GENERAL: 'account_general',
  ACCOUNT_PURCHASE: 'account_purchase',
  ACCOUNT_SALES: 'account_sales',
  ACCOUNT_DEMAND_UPLOAD: 'account_demand_upload',
  ACCOUNT_INVENTORY_REPAIR: 'account_inventory_repair',
  ACCOUNT_RFQ_DIGEST_SUBSCRIPTION: 'account_rfq_digest_subscription',
  FILTER_PURCHASE: 'filter_purchase',
  FILTER_SALES_INVENTORY: 'filter_sales_inventory',
  FILTER_SALES_REPAIR: 'filter_sales_repair',
};
export const quoteTypes = {
  QUOTE_BUY: 'quote_buy',
  QUOTE_REPAIR: 'quote_repair',
  QUOTE_EXCHANGE: 'quote_exchange',
  QUOTE_LOAN: 'quote_loan',
};
export const paymentRequestTypes = {
  BUY: 'buy',
  REPAIR: 'repair',
  EXCHANGE: 'exchange',
  LOAN: 'loan',
};
export const paymentRequestType = {
  BUY: 'buy',
  EXCHANGE: 'exchange',
  LOAN: 'loan',
  REPAIR: 'repair',
};
export const creatorTypes = {
  CUSTOMER: 'customer',
  SYSTEM: 'system',
  BOT: 'bot',
  EPLANE: 'eplane',
};
export const subscriptionState = {
  UNSUBSCRIBED: 0,
  SUBSCRIBED: 1,
  MAILCHIMP_UNSUBSCRIBE: 2,
};
export const noResultActionsType = {
  SIGNIN: 'signin',
  SIGNUP: 'signup',
  REGISTER: 'register',
};
export const demandCategory = {
  AC_PART: {
    id: 'A',
    name: 'AC Parts',
  },
  METAL: {
    id: 'M',
    name: 'Metals',
  },
  CHEMICAL: {
    id: 'C',
    name: 'Chemicals',
  },
  GROUND_EQUIPMENT: {
    id: 'G',
    name: 'Ground equipment',
  },
  OTHER: {
    id: 'O',
    name: 'Others',
  },
};
export const quoteOriginal = {
  QUOTE_DEFAULT: 0,
  QUOTE_RTS: 1,
  QUOTE_TIER1: 2,
  QUOTE_SPEC2000: 3,
};
export const supportedExchangeCurrencies = {
  AED: 'AED',
  AFN: 'AFN',
  ALL: 'ALL',
  AMD: 'AMD',
  ANG: 'ANG',
  AOA: 'AOA',
  ARS: 'ARS',
  AUD: 'AUD',
  AWG: 'AWG',
  AZN: 'AZN',
  BAM: 'BAM',
  BBD: 'BBD',
  BDT: 'BDT',
  BGN: 'BGN',
  BHD: 'BHD',
  BIF: 'BIF',
  BMD: 'BMD',
  BND: 'BND',
  BOB: 'BOB',
  BRL: 'BRL',
  BSD: 'BSD',
  BTC: 'BTC',
  BTN: 'BTN',
  BWP: 'BWP',
  BYN: 'BYN',
  BYR: 'BYR',
  BZD: 'BZD',
  CAD: 'CAD',
  CDF: 'CDF',
  CHF: 'CHF',
  CLF: 'CLF',
  CLP: 'CLP',
  CNH: 'CNH',
  CNY: 'CNY',
  COP: 'COP',
  CRC: 'CRC',
  CUC: 'CUC',
  CUP: 'CUP',
  CVE: 'CVE',
  CZK: 'CZK',
  DJF: 'DJF',
  DKK: 'DKK',
  DOP: 'DOP',
  DZD: 'DZD',
  EEK: 'EEK',
  EGP: 'EGP',
  ERN: 'ERN',
  ETB: 'ETB',
  EUR: 'EUR',
  FJD: 'FJD',
  FKP: 'FKP',
  GBP: 'GBP',
  GEL: 'GEL',
  GGP: 'GGP',
  GHS: 'GHS',
  GIP: 'GIP',
  GMD: 'GMD',
  GNF: 'GNF',
  GTQ: 'GTQ',
  GYD: 'GYD',
  HKD: 'HKD',
  HNL: 'HNL',
  HRK: 'HRK',
  HTG: 'HTG',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  IMP: 'IMP',
  INR: 'INR',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'ISK',
  JEP: 'JEP',
  JMD: 'JMD',
  JOD: 'JOD',
  JPY: 'JPY',
  KES: 'KES',
  KGS: 'KGS',
  KHR: 'KHR',
  KMF: 'KMF',
  KPW: 'KPW',
  KRW: 'KRW',
  KWD: 'KWD',
  KYD: 'KYD',
  KZT: 'KZT',
  LAK: 'LAK',
  LBP: 'LBP',
  LKR: 'LKR',
  LRD: 'LRD',
  LSL: 'LSL',
  LYD: 'LYD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'MMK',
  MNT: 'MNT',
  MOP: 'MOP',
  MRO: 'MRO',
  MRU: 'MRU',
  MTL: 'MTL',
  MUR: 'MUR',
  MVR: 'MVR',
  MWK: 'MWK',
  MXN: 'MXN',
  MYR: 'MYR',
  MZN: 'MZN',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  OMR: 'OMR',
  PAB: 'PAB',
  PEN: 'PEN',
  PGK: 'PGK',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  RON: 'RON',
  RSD: 'RSD',
  RUB: 'RUB',
  RWF: 'RWF',
  SAR: 'SAR',
  SBD: 'SBD',
  SCR: 'SCR',
  SDG: 'SDG',
  SEK: 'SEK',
  SGD: 'SGD',
  SHP: 'SHP',
  SLL: 'SLL',
  SOS: 'SOS',
  SRD: 'SRD',
  SSP: 'SSP',
  STD: 'STD',
  STN: 'STN',
  SVC: 'SVC',
  SYP: 'SYP',
  SZL: 'SZL',
  THB: 'THB',
  TJS: 'TJS',
  TMT: 'TMT',
  TND: 'TND',
  TOP: 'TOP',
  TRY: 'TRY',
  TTD: 'TTD',
  TWD: 'TWD',
  TZS: 'TZS',
  UAH: 'UAH',
  UGX: 'UGX',
  USD: 'USD',
  UYU: 'UYU',
  UZS: 'UZS',
  VEF: 'VEF',
  VND: 'VND',
  VUV: 'VUV',
  WST: 'WST',
  XAF: 'XAF',
  XAG: 'XAG',
  XAU: 'XAU',
  XCD: 'XCD',
  XDR: 'XDR',
  XOF: 'XOF',
  XPD: 'XPD',
  XPF: 'XPF',
  XPT: 'XPT',
  YER: 'YER',
  ZAR: 'ZAR',
  ZMK: 'ZMK',
  ZMW: 'ZMW',
};
export const supportedCurrencies = {
  USD: 'USD',
  EUR: 'EUR',
  AED: 'AED',
  ARS: 'ARS',
  AUD: 'AUD',
  BRL: 'BRL',
  CHF: 'CHF',
  CNY: 'CNY',
  GBP: 'GBP',
  RUB: 'RUB',
};
export const rfqFilterSalesOrderStatus = {
  PAYMENT_REQUEST_REQUESTED: {
    id: 'Buyer requested to pay',
    name: 'Buyer requested to pay',
  },
  PAYMENT_COMPLETED_TO_EPLANE: {
    id: "Buyer's payment to ePlane completed",
    name: "Buyer's payment to ePlane completed",
  },
  PAYMENT_FAILED_TO_EPLANE: {
    id: "Buyer's payment to ePlane failed",
    name: "Buyer's payment to ePlane failed",
  },
  INACTIVE: {
    id: 'Inactive',
    name: 'Inactive',
  },
  NEW_INQUIRY: {
    id: 'New inquiry',
    name: 'New inquiry',
  },
  NEW_MESSAGE: {
    id: 'New message',
    name: 'New message',
  },
  EPLANE_COMMENT: {
    id: 'New message from ePlane',
    name: 'New message from ePlane',
  },
  PAYMENT_NEW_REQUEST_TO_EPLANE: {
    id: 'New payment request from ePlane',
    name: 'New payment request from ePlane',
  },
  SHIPPED: {
    id: 'Order shipped',
    name: 'Order shipped',
  },
  PO_ACKNOWLEDGED: {
    id: 'PO acknowledged',
    name: 'PO acknowledged',
  },
  PO_RECEIVED: {
    id: 'PO received',
    name: 'PO received',
  },
  PO_REJECTED: {
    id: 'PO rejected',
    name: 'PO rejected',
  },
  PAYMENT_COMPLETED: {
    id: 'Payment completed',
    name: 'Payment completed',
  },
  AWAITING_SHIPMENT: {
    id: 'Payment completed via ePlane',
    name: 'Payment completed via ePlane',
  },
  PAYMENT_FAILED: {
    id: 'Payment failed',
    name: 'Payment failed',
  },
  PAYMENT_REQUEST_EXPIRED: {
    id: 'Payment request expired',
    name: 'Payment request expired',
  },
  PAYMENT_PROCESSING_TO_EPLANE: {
    id: "Processing buyer's payment to ePlane",
    name: "Processing buyer's payment to ePlane",
  },
  PAYMENT_PROCESSING: {
    id: 'Processing payment',
    name: 'Processing payment',
  },
  REPLIED: {
    id: 'Replied',
    name: 'Replied',
  },
  NO_QUOTE_SENT: {
    id: "Sent 'No quote'",
    name: "Sent 'No quote'",
  },
  SENT_INVOICE: {
    id: 'Sent invoice',
    name: 'Sent invoice',
  },
  PAYMENT_NEW_REQUEST: {
    id: 'Sent payment request',
    name: 'Sent payment request',
  },
  SENT_QUOTE: {
    id: 'Sent quote',
    name: 'Sent quote',
  },
  EPLANE_REVIEW: {
    id: "Under ePlane's review",
    name: "Under ePlane's review",
  },
  UNUSED: {
    id: "Unused from seller's side",
  },
};
export const rfqFilterPurchasesOrderStatus = {
  AWAITING_QUOTE: {
    id: 'Awaiting quote',
    name: 'Awaiting quote',
  },
  AWAITING_SHIPMENT: {
    id: 'Awaiting shipment',
    name: 'Awaiting shipment',
  },
  PAYMENT_AWAITING_PO: {
    id: 'Awaiting your PO for ePlane',
    name: 'Awaiting your PO for ePlane',
  },
  INACTIVE: {
    id: 'Inactive',
    name: 'Inactive',
  },
  INVOICE_RECEIVED: {
    id: 'Invoice received',
    name: 'Invoice received',
  },
  NEW_MESSAGE: {
    id: 'New message',
    name: 'New message',
  },
  EPLANE_COMMENT: {
    id: 'New message from ePlane',
    name: 'New message from ePlane',
  },
  PAYMENT_NEW_REQUEST: {
    id: 'New payment request',
    name: 'New payment request',
  },
  PAYMENT_NEW_REQUEST_TO_EPLANE: {
    id: 'New payment request from ePlane',
    name: 'New payment request from ePlane',
  },
  NEW_QUOTE: {
    id: 'New quote',
    name: 'New quote',
  },
  NO_QUOTE: {
    id: 'No quote',
    name: 'No quote',
  },
  SHIPPED: {
    id: 'Order shipped',
    name: 'Order shipped',
  },
  PO_ACKNOWLEDGED: {
    id: 'PO acknowledged',
    name: 'PO acknowledged',
  },
  PO_REJECTED: {
    id: 'PO rejected',
    name: 'PO rejected',
  },
  PAYMENT_COMPLETED: {
    id: 'Payment completed',
    name: 'Payment completed',
  },
  PAYMENT_FAILED: {
    id: 'Payment failed',
    name: 'Payment failed',
  },
  PAYMENT_REQUEST_EXPIRED: {
    id: 'Payment request expired',
    name: 'Payment request expired',
  },
  PAYMENT_COMPLETED_TO_EPLANE: {
    id: 'Payment to ePlane completed',
    name: 'Payment to ePlane completed',
  },
  PAYMENT_FAILED_TO_EPLANE: {
    id: 'Payment to ePlane failed',
    name: 'Payment to ePlane failed',
  },
  PAYMENT_PROCESSING: {
    id: 'Processing payment',
    name: 'Processing payment',
  },
  PAYMENT_PROCESSING_TO_EPLANE: {
    id: 'Processing payment to ePlane',
    name: 'Processing payment to ePlane',
  },
  REPLIED: {
    id: 'Replied',
    name: 'Replied',
  },
  PO_ATTACHED: {
    id: 'Sent PO',
    name: 'Sent PO',
  },
  EPLANE_REVIEW: {
    id: "Under ePlane's review",
    name: "Under ePlane's review",
  },
  PAYMENT_REQUEST_REQUESTED: {
    id: "You've asked to pay",
    name: "You've asked to pay",
  },
};
export const customerEmailStatus = {
  VERIFIED: 1,
  NOT_VERIFIED: 2,
  INVITED_PENDING: 3,
  INVITED_EXPIRED: 4,
  INACTIVE: 5,
  DELETED: 6,
};
export const crrCompleteStatus = {
  OPEN: 'open',
  CLOSED: 'closed',
  PENDING: 'pending',
  INPROGRESS: 'inprogress',
  ONHOLD: 'onhold',
};
export const crrDecision = {
  APPROVE: 'approve',
  DECLINE: 'decline',
  START: 'start',
  HOLD: 'hold',
};
export const crrResult = {
  PENDING: {
    id: 1,
    name: 'Pending',
  },
  APPROVED: {
    id: 2,
    name: 'Approved',
  },
  DECLINED: {
    id: 3,
    name: 'Declined',
  },
  INPROGRESS: {
    id: 4,
    name: 'In progress',
  },
  ONHOLD: {
    id: 5,
    name: 'On hold',
  },
  UNCONFIRMED: {
    id: 6,
    name: 'Not confirmed',
  },
};
export const onBehalfRole = {
  AUTO_BUILT_IN_QUOTE: 1,
  RFQ_CREATOR: 2,
  SELLER_DEMANDS_AUTOSYNC: 3,
};
export const tier = {
  TIER_1: 'catalog',
  TIER_2: 'pn lists',
  TIER_3: 'weighted sellers',
  TIER_4: 'seller list',
  TIER_SPEC2000_PO: 'spec2000 PO',
};
export const targetTypes = {
  BLANK: '_blank',
  SELF: '_self',
  PARENT: '_parent',
  TOP: '_top',
};
export const taskStatus = {
  OPEN: 'open',
  ON_HOLD: 'on_hold',
  CLOSED: 'closed',
  IN_PROGRESS: 'in_progress',
};
export const vendorCategory = {
  AC_PART: {
    id: 'A',
    name: 'AC Parts',
  },
  METAL: {
    id: 'M',
    name: 'Metals',
  },
  CHEMICAL: {
    id: 'C',
    name: 'Chemicals',
  },
  GROUND_EQUIPMENT: {
    id: 'G',
    name: 'Ground equipment',
  },
  OTHER: {
    id: 'O',
    name: 'Others',
  },
};
export const materialClass = {
  CONSUMABLE: {
    id: 'C',
    name: 'Consumable',
  },
  EXPENDIBLE: {
    id: 'E',
    name: 'Extendible',
  },
  ROTABLE: {
    id: 'R',
    name: 'Rotable',
  },
  ALL: {
    id: 'A',
    name: 'All',
  },
  CONSUMABLE_EXPENDIBLE: {
    id: 'CE',
    name: 'Consumable and expendible',
  },
  CONSUMABLE_ROTABLE: {
    id: 'CR',
    name: 'Consumable and rotable',
  },
  EXTENDIBLE_ROTABLE: {
    id: 'ER',
    name: 'Expendible and rotable',
  },
};
export const vettedStatus = {
  NOT_VETTED: 1,
  VETTED: 2,
};
export const logObjects = {
  COMPANY_REGISTRATION_REQUEST: {
    id: 1,
    name: 'Company registration requests',
    actions: {
      APPROVE: {
        id: 1,
        name: 'Approve',
      },
      DECLINE: {
        id: 2,
        name: 'Decline',
      },
      START_PROGRESS: {
        id: 3,
        name: 'Start progress',
      },
      HOLD: {
        id: 4,
        name: 'Hold',
      },
    },
  },
  CUSTOMER: {
    id: 2,
    name: 'Customers operations',
    actions: {
      DELETE: {
        id: 1,
        name: 'Delete',
      },
      RESURRECT: {
        id: 2,
        name: 'Resurrect',
      },
      ASSOCIATE: {
        id: 3,
        name: 'Associate',
      },
      UPDATE: {
        id: 4,
        name: 'Update',
      },
      SET_PASSWD: {
        id: 5,
        name: 'Set password',
      },
      RESET_PASSWD: {
        id: 6,
        name: 'Reset password',
      },
      RESEND_CONFIRM: {
        id: 7,
        name: 'Resend confirmation',
      },
      SET_SUBSCRIPTIONS: {
        id: 8,
        name: 'Set subscriptions',
      },
      CONFIRM: {
        id: 9,
        name: 'Confirm',
      },
      COMMENT: {
        id: 10,
        name: 'Comment',
      },
      DISSOCIATE: {
        id: 11,
        name: 'Dissociate',
      },
    },
  },
  RFQ: {
    id: 3,
    name: 'RFQ operations',
    actions: {
      BIQ_ADD: {
        id: 1,
        name: 'Add built-in quote',
      },
      BIQ_EDIT: {
        id: 2,
        name: 'Edit built-in quote',
      },
      BIQ_REMOVE: {
        id: 3,
        name: 'Remove built-in quote',
      },
      NQ_ADD: {
        id: 4,
        name: 'Add no quote message',
      },
      NQ_REMOVE: {
        id: 5,
        name: 'Remove no quote message',
      },
    },
  },
  BACKOFFICE_TASK: {
    id: 4,
    name: 'Backoffice tasks',
    actions: {
      REOPEN: {
        id: 1,
        name: 'Reopen',
      },
      CLOSE: {
        id: 2,
        name: 'Close',
      },
      START: {
        id: 3,
        name: 'Start',
      },
    },
  },
  NEWSFEED: {
    id: 5,
    name: 'Newsfeed operations',
    actions: {
      CREATE: {
        id: 1,
        name: 'Create',
      },
      EDIT: {
        id: 2,
        name: 'Edit',
      },
      DELETE: {
        id: 3,
        name: 'Delete',
      },
      REORDER: {
        id: 4,
        name: 'Reorder',
      },
    },
  },
  USER: {
    id: 6,
    name: 'User operations',
    actions: {
      UPDATE: {
        id: 1,
        name: 'Update',
      },
      DELETE_DOC: {
        id: 2,
        name: 'Delete document',
      },
      UPDATE_SETTINGS: {
        id: 3,
        name: 'Update settings',
      },
      CLONE_SELLERS_WEIGHTS: {
        id: 4,
        name: 'Clone sellers weights',
      },
      UPDATE_CATEGORIES: {
        id: 5,
        name: 'Update categories',
      },
      UPDATE_PUBDRIVE: {
        id: 6,
        name: 'Update PubDrive settings',
      },
      INVITE_CUSTOMER: {
        id: 7,
        name: 'Invite customer',
      },
      REINVITE_CUSTOMER: {
        id: 8,
        name: 'Reinvite customer',
      },
      DEACTIVATE_CUSTOMER: {
        id: 9,
        name: 'Deactivate customer',
      },
      UPDATE_CUSTOMER: {
        id: 10,
        name: 'Update customer',
      },
      ADD_SITE: {
        id: 14,
        name: 'Add pickup site',
      },
      EDIT_SITE: {
        id: 15,
        name: 'Edit pickup site',
      },
      DELETE_SITE: {
        id: 16,
        name: 'Delete pickup site',
      },
      UNPUBLISH_INVENTORY: {
        id: 17,
        name: 'Unpublish inventory',
      },
      UPDATE_PRIVATE_BILLING: {
        id: 18,
        name: 'Edit private user billing info',
      },
      REPLACE_DOC: {
        id: 19,
        name: 'Replace document',
      },
      UPLOAD_DOC: {
        id: 20,
        name: 'Upload document',
      },
      REPLACE_PN_LIST: {
        id: 21,
        name: 'Replace TIER 2 PN list',
      },
      MERGE_PN_LIST: {
        id: 22,
        name: 'Merge TIER 2 PN list',
      },
      UPLOAD_SELLERS_WEIGHTS: {
        id: 23,
        name: 'Upload sellers weights',
      },
      UPLOAD_INVENTORY: {
        id: 24,
        name: 'Upload inventory',
      },
      UPLOAD_CAPABILITIES: {
        id: 25,
        name: 'Upload capabilities',
      },
      COMMENT: {
        id: 26,
        name: 'Comment',
      },
      UPDATE_CATEGORY_BY_BUYER: {
        id: 27,
        name: 'Update category by buyer',
      },
      ADD_TO_TIER3_LIST: {
        id: 28,
        name: 'Add to tier 3 group',
      },
      REMOVE_FROM_TIER3_LIST: {
        id: 29,
        name: 'Remove from tier 3 group',
      },
      ATTACH_TIER3_LIST: {
        id: 30,
        name: 'Attach tier 3 group',
      },
      DETACH_TIER3_LIST: {
        id: 31,
        name: 'Detach tier 3 group',
      },
      ADD_CUSTOM_TIER3_WEIGHT: {
        id: 32,
        name: 'Add custom tier 3 weight',
      },
      REMOVE_CUSTOM_TIER3_WEIGHT: {
        id: 33,
        name: 'Remove custom tier 3 weight',
      },
      UPDATE_CUSTOM_TIER3_WEIGHT: {
        id: 34,
        name: 'Update custom tier 3 weight',
      },
    },
  },
  RFQ_NOTIFICATION_FILTER: {
    id: 7,
    name: 'RFQ notification filters operations',
    actions: {
      CREATE: {
        id: 1,
        name: 'Create filter',
      },
      UPDATE: {
        id: 2,
        name: 'Update filter',
      },
      DELETE: {
        id: 3,
        name: 'Delete filter',
      },
      TOGGLE: {
        id: 4,
        name: 'Toggle filter',
      },
    },
  },
  DEMAND_CATEGORY: {
    id: 8,
    name: 'Demand categories changes',
    actions: {
      UPDATE: {
        id: 1,
        name: 'Update',
      },
    },
  },
  TIER3LIST: {
    id: 9,
    name: 'Tier 3 groups management',
    actions: {
      CREATE: {
        id: 1,
        name: 'Create',
      },
      DELETE: {
        id: 2,
        name: 'Delete',
      },
      UPDATE_NAME: {
        id: 3,
        name: 'Update name',
      },
      ADD_SELLER: {
        id: 4,
        name: 'Add seller',
      },
      REMOVE_SELLER: {
        id: 5,
        name: 'Remove seller',
      },
      REMOVE_ALL_SELLERS: {
        id: 6,
        name: 'Remove all seller',
      },
      UPDATE_SELLER: {
        id: 7,
        name: 'Update seller',
      },
      ATTACH_COMPANY: {
        id: 8,
        name: 'Attach company',
      },
      DETACH_COMPANY: {
        id: 9,
        name: 'Detach company',
      },
    },
  },
  SELLER_LIST: {
    id: 10,
    name: 'Seller lists management',
    actions: {
      ADD: {
        id: 1,
        name: 'Add seller list',
      },
      UPDATE: {
        id: 2,
        name: 'Update seller list',
      },
      DELETE: {
        id: 3,
        name: 'Delete seller list',
      },
    },
  },
};
export const leadTimeUnits = {
  HOURS: {
    id: 'h',
    name: 'Hours',
  },
  DAYS: {
    id: 'd',
    name: 'Days',
  },
};
export const paymentProviders = {
  STRIPE: 'stripe',
  WIRETRANSFER_BROKER: 'wiretransfer_broker',
  STRIPE_BROKER: 'stripe_broker',
};
export const paymentStatus = {
  CREATED: 'created',
  REJECTED: 'rejected',
  PENDING: 'pending',
  FAILED: 'failed',
  COMPLETED: 'completed',
};
export const paymentRequestStatus = {
  UNCONFIRMED: 'unconfirmed',
  PENDING: 'pending',
  PROCESSING: 'processing',
  PROCESSING_TO_EPLANE: 'processing_to_ep',
  EXPIRED: 'expired',
  REJECTED: 'rejected',
  FAILED: 'failed',
  FAILED_TO_EPLANE: 'failed_to_ep',
  COMPLETED: 'completed',
  COMPLETED_TO_EPLANE: 'completed_to_ep',
  COMPLETED_VIA_EPLANE: 'completed_via_ep',
};
export const paymentMethods = {
  CARD: {
    id: 'card',
    name: 'Credit card',
  },
  ACH: {
    id: 'ach',
    name: 'ACH',
  },
  ALIPAY: {
    id: 'alipay',
    name: 'AliPay',
  },
  WECHAT: {
    id: 'wechat',
    name: 'WeChatPay',
  },
  WIRETRANSFER: {
    id: 'wiretransfer',
    name: 'Wiretransfer',
  },
};
export const proPaymentMethods = {
  PAYMENT_METHOD_CARD: {
    id: 'card',
    name: 'Credit card',
  },
  PAYMENT_METHOD_WIRE_TRANSFER: {
    id: 'wire_transfer',
    name: 'Wire Transfer',
  },
  PAYMENT_METHOD_FREE: {
    id: 'free',
    name: 'Free',
  },
};
export const sellersListTypes = {
  CUSTOM: '0',
  BLOCKED: '1',
  APPROVED: '2',
};
export const tier2Status = {
  DISABLED: 1,
  AUTOPILOT_ONLY: 2,
  ENABLED: 3,
};
export const proSubscriptionStatus = {
  STATUS_PENDING: 'pending',
  STATUS_FAILED: 'failed',
  STATUS_ACTIVE: 'active',
  STATUS_DEBT: 'debt',
  STATUS_SUSPENDED: 'suspended',
  STATUS_CANCELED: 'canceled',
};
export const buyerFilterTypes = {
  buyer_type_filters: 'buyer_type_filters',
  buyer_country_filters: 'buyer_country_filters',
  buyer_company_filters: 'buyer_company_filters',
  buyer_private_user_filters: 'buyer_private_user_filters',
};
export const priceTypes = {
  FEE: 'fee',
  PER_UNIT: 'per_unit',
  EVALUATION: 'evaluation',
  REPAIR: 'repair',
  TEST: 'test',
  OVERHAUL: 'overhaul',
};
export const builtInQuoteOptions = [
  { value: quoteTypes.QUOTE_BUY, labelText: 'Buy' },
  { value: quoteTypes.QUOTE_EXCHANGE, labelText: 'Exchange' },
  { value: quoteTypes.QUOTE_LOAN, labelText: 'Loan' },
  { value: quoteTypes.QUOTE_REPAIR, labelText: 'Repair' },
];
export const traceTo = {
  AIRLINE121: {
    id: 'airline_121',
    name: 'Airline 121',
  },
  AIRLINE129: {
    id: 'airline_129',
    name: 'Airline 129',
  },
  MRO: {
    id: 'mro',
    name: 'MRO',
  },
  REPAIR_SHOP: {
    id: 'repair_shop',
    name: 'Repair Shop',
  },
  OEM: {
    id: 'oem',
    name: 'OEM',
  },
  COC: {
    id: 'coc',
    name: 'COC',
  },
};
export const quotePositions = {
  LOWEST: {
    id: 1,
    name: 'Lowest quotes',
  },
  MIDDLE: {
    id: 2,
    name: 'Mid-level quotes',
  },
  HIGHEST: {
    id: 3,
    name: 'Highest quotes',
  },
};
export const quoteValidationStatuses = {
  VALID_BY_ALGO: 'valid_by_algo',
  INVALID_BY_ALGO: 'invalid_by_algo',
  VALID_BY_BO: 'valid_by_bo',
  INVALID_BY_BO: 'invalid_by_bo',
};
export const supportEmail = 'support@eplane.com';
export const taskTriggerType = {
  IGNORE: 'ignore',
  NOQUOTE: 'noquote',
};
