import {
  PRO_MANAGEMENT_GET,
  PRO_MANAGEMENT_GET_SUCCESS,
  PRO_MANAGEMENT_CANCEL_SUB,
  PRO_MANAGEMENT_CANCEL_SUB_SUCCESS,
  PRO_MANAGEMENT_STOP_SUB,
  PRO_MANAGEMENT_STOP_SUB_SUCCESS,
  PRO_MANAGEMENT_CONTINUE_SUB,
  PRO_MANAGEMENT_CONTINUE_SUB_SUCCESS,
  PRO_MANAGEMENT_MANUAL_SUB,
  PRO_MANAGEMENT_MANUAL_SUB_SUCCESS,

  PRO_MANAGEMENT_HISTORY_GET,
  PRO_MANAGEMENT_HISTORY_GET_SUCCESS,

  PRO_MANAGEMENT_CLEAR,
  PRO_MANAGEMENT_FAIL,
} from '../../constants';

const initialState = {
  proPlan: null,
  paymentsHistory: null,
  isFetching: false,
  isProcessing: false,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case PRO_MANAGEMENT_GET:
      return {
        ...state,
        isFetching: true,
      };
    case PRO_MANAGEMENT_GET_SUCCESS:
      return {
        ...state,
        proPlan: payload,
        isFetching: false,
      };

    case PRO_MANAGEMENT_CANCEL_SUB:
      return {
        ...state,
        isProcessing: true,
      };
    case PRO_MANAGEMENT_CANCEL_SUB_SUCCESS:
      return {
        ...state,
        proPlan: payload,
        isProcessing: false,
      };

    case PRO_MANAGEMENT_STOP_SUB:
      return {
        ...state,
        isProcessing: true,
      };
    case PRO_MANAGEMENT_STOP_SUB_SUCCESS:
      return {
        ...state,
        proPlan: payload,
        isProcessing: false,
      };

    case PRO_MANAGEMENT_CONTINUE_SUB:
      return {
        ...state,
        isProcessing: true,
      };
    case PRO_MANAGEMENT_CONTINUE_SUB_SUCCESS:
      return {
        ...state,
        proPlan: payload,
        isProcessing: false,
      };

    case PRO_MANAGEMENT_MANUAL_SUB:
      return {
        ...state,
        isProcessing: true,
      };
    case PRO_MANAGEMENT_MANUAL_SUB_SUCCESS:
      return {
        ...state,
        proPlan: payload,
        isProcessing: false,
      };

    case PRO_MANAGEMENT_HISTORY_GET:
      return {
        ...state,
        isFetching: true,
      };
    case PRO_MANAGEMENT_HISTORY_GET_SUCCESS:
      return {
        ...state,
        paymentsHistory: payload,
        isFetching: false,
      };

    case PRO_MANAGEMENT_FAIL:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
      };

    case PRO_MANAGEMENT_CLEAR:
      return initialState;

    default: return state;
  }
};
