// TODO: Make more selectors, please :)
export const getCompanyListStore = (state) => state.companyList;

export const getServerConstants = (state) => state.serverConstants;

export const getCountries = (state) => state.serverConstants.countries;

export const getConstants = (state) => state.serverConstants.constants;

export const getAdminCustomerId = (state) => state.authorization.adminCustomerId;

export const getBoUserRole = (state) => state.authorization.role;

/** *************** Order Management *********************** */

export const getSearchOrdersStore = (state) => state.orderManagement.searchOrders;

export const getChatStore = (state) => state.orderManagement.chat;

/** *************** User Management  *********************** */

export const getCustomersListStore = (state) => state.userManagement.customersList;

export const getUserStore = (state) => state.userManagement.user;

export const getCustomerStore = (state) => state.userManagement.customer;

export const getIsSavingUMStore = (state) => state.userManagement.user.isSaving;

export const getFiltersStore = (state) => state.userManagement.filters;

export const getNotificationsStore = (state) => state.userManagement.notifications;

/** *************** User Company Management  *********************** */

export const getUserSitesStore = (state) => state.userCompanyManagement.sites;

export const getDocumentsStore = (state) => state.userCompanyManagement.documents;

export const getIsFetchingUMStore = (state) => state.userCompanyManagement.sites.isFetching;

export const getProManagementStore = (state) => state.userCompanyManagement.proManagement;

/** *************** requests Management *********************** */

export const getIncomingRequestsStore = (state) => state.requests.incomingRequests;

export const getClosedRequestsStore = (state) => state.requests.closedRequests;

export const getRequestDataStore = (state) => state.requests.requestData;

/** *************** Companies Management *********************** */
export const getInventoryListStore = (state) => state.companiesManagement.inventoryList;

export const getUploaderStore = (state) => state.companiesManagement.uploader;

export const getCompaniesStore = (state) => state.companiesManagement.companies;

export const getCompanyCustomer = (state) => state.companiesManagement.companyCustomer;

export const getUserSettingsStore = (state) => state.companiesManagement.userSettings;

export const getPnListStore = (state) => state.companiesManagement.pnList;

export const getDemandListStore = (state) => state.companiesManagement.demandList;

export const getActiveCompanyCustomersStore = (state) => state.companiesManagement.activeCompanyCustomers;

export const getLegalEntitiesStore = (state) => state.companiesManagement.legalEntities;

export const getVendorCategorizationStore = (state) => state.companiesManagement.vendorCategorization;

export const getCompanyWeightStore = (state) => state.companiesManagement.companyWeight;

export const getCustomSellersListsStore = (state) => state.companiesManagement.customSellersLists;

export const getBuyersListsStore = (state) => state.companiesManagement.buyersLists;

export const getPartsWatchlistStore = (state) => state.companiesManagement.partsWatchlist;

export const getUserPaymentProviders = (state) => state.companiesManagement.paymentProviders;

export const getUserRepositoryStore = (state) => state.companiesManagement.userRepository;

export const getWeightsStore = (state) => state.companiesManagement.weights;

export const getSpec2kStore = (state) => state.companiesManagement.spec2k;

export const getUserOrderLimitsStore = (state) => state.companiesManagement.orderLimits;

/** *************** News Feed Management *********************** */
export const getEmailManagementStore = (state) => state.dev.emails;

/** *************** Logs Management *********************** */
export const getLogsManagementStore = (state) => state.dev.logs;

export const getBounceEmailsStore = (state) => state.dev.bounceEmails;

/** *************** SFTP User *********************** */
export const getSftpUserStore = (state) => state.companiesManagement.sftpUser;

/** *************** Network Operation Center  *********************** */
export const getBuiltInQuoteStore = (state) => state.networkOperationsCenter.quote;

export const getPaymentsTasksStore = (state) => state.networkOperationsCenter.payments;

export const getTasksStore = (state) => state.networkOperationsCenter.tasks;

// eslint-disable-next-line camelcase
export const getSelectedTaskStatus = (state) => state.networkOperationsCenter.tasks.selectedTask?.task_status || null;

export const getNocFormStore = (state) => state.networkOperationsCenter.formManagement;

export const getWeightGroupsStore = (state) => state.settings.weightGroups;

export const getVendorCategoriesStore = (state) => state.settings.vendorCategories;

export const getEmailsSettingsStore = (state) => state.settings.emails;

export const getPaymentProviders = (state) => state.settings.paymentProviders;

export const getNewsListStore = (state) => state.settings.news;

export const getMailchimpStore = (state) => state.settings.mailchimp;

export const getOrderLimitsStore = (state) => state.settings.orderLimits;

export const getBankAccountStore = (state) => state.settings.bankAccount;

export const getProPriceStore = (state) => state.settings.proPrice;

export const getIgnoreTaskRulesStore = (state) => state.networkOperationsCenter.taskIgnoreList;

/** *************** Errors Management *********************** */
export const getErrorsStore = (state) => state.errorManagement;

/** *************** Comments Management *********************** */
export const getCommentsStore = (state) => state.chat;
/** *************** File upload Management *********************** */
export const getFileUploaderStore = (state) => state.fileUploader;

/** *************** Toasts Management *********************** */
export const getToastsStore = (state) => state.toasts;
