import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import {
  Accordion, Col, Row, Button, Tooltip, OverlayTrigger, Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import CommandsTable from './CommandsTable';

import {
  fetchMailchimpConditions,
  addMailchimpCommand,
  deleteMailchimpCommand,
  processCommands,
  fetchErrorLog,
} from '../../../actions/settings/mailchimp';
import { getMailchimpStore, getErrorsStore } from '../../../selectors';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import { StyledLinkButton, TabContainer, ButtonContainer } from '../../StyledComponents';
import ModalLoader from '../../ModalLoader';
import {
  mailchimpSearchFields,
  mailchimpConditionTypes,
  mailchimpActionTypes,
  mailchimpTags,
  syncStatus,
} from '../../../constants/mailchimp';
import RemoveModal from '../../Modals/RemoveModal';
import Loader from '../../Loader';
import LastSyncStatus from './LastSyncStatus';

const tooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

const ConditionContainer = styled.div`
  display: flex;
  & > div {
    margin-right: 5px;
  }
  .mailchimp-field {
    width: 240px;
  }
  .mailchimp-condition {
    width: 225px;
  }
  .mailchimp-value {
    input {
      height: 38px;
    }
  }
  .mailchimp-action {
    width: 160px;
  }
  .mailchimp-action-value {
    width: 180px;
  }
`;
const LinkButton = styled(StyledLinkButton)`
  padding: 0;
`;
const LastStatusContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: flex-start;
`;

const defaultState = () => ({
  field: null,
  condition: null,
  action: null,
  value: '',
  action_value: null,
});

const MailchimpPage = () => {
  const dispatch = useDispatch();
  const {
    items, status, errorLog, isFetching, isSaving, isFetchingError,
  } = useSelector(getMailchimpStore);
  const { errors } = useSelector(getErrorsStore);

  const [commandParams, setCommandParams] = useState(defaultState());
  const [removeParams, setRemoveParams] = useState({ showRemoveModal: false, id: null });
  const [selected, setSelected] = useState([]);
  const blockProcessButton = status && status.status === syncStatus.STATUS_PROGRESS;

  const selectOptions = useMemo(
    () => ({
      mailchimpSearchFields: Object.keys(mailchimpSearchFields)
        .map((v) => ({ label: mailchimpSearchFields[v].name, value: mailchimpSearchFields[v].id })),
      mailchimpConditionTypes: Object.keys(mailchimpConditionTypes)
        .map((v) => ({ label: mailchimpConditionTypes[v].name, value: mailchimpConditionTypes[v].id })),
      mailchimpTags: Object.keys(mailchimpTags).map((v) => ({ label: mailchimpTags[v], value: mailchimpTags[v] })),
    }),
    [],
  );

  const onSelect = useCallback((selectedRows) => {
    setSelected(selectedRows.map((v) => v.condition_id));
  }, []);

  useEffect(() => {
    dispatch(fetchMailchimpConditions());
  }, [dispatch]);

  const onSelectChange = (selectedOption, action) => {
    setCommandParams({ ...commandParams, [action.name]: selectedOption });
  };
  const onValueChange = (e) => {
    setCommandParams({ ...commandParams, value: e.target.value });
  };

  const onCommandAddClick = () => {
    const sendData = {
      field: commandParams.field && commandParams.field.value,
      condition: commandParams.condition && commandParams.condition.value,
      action: mailchimpActionTypes.SET_TAG.id,
      value: commandParams.value,
      action_value: commandParams.action_value && commandParams.action_value.value,
    };

    dispatch(addMailchimpCommand(sendData));
    setCommandParams(defaultState);
  };

  const onCommandRemove = () => {
    dispatch(deleteMailchimpCommand(removeParams.id));
    setRemoveParams({ showRemoveModal: false, id: null });
  };

  const onRemoveClick = useCallback((id) => setRemoveParams({ showRemoveModal: true, id }), []);

  const onProcessHandler = () => {
    dispatch(processCommands({ ids: selected }));
    setSelected([]);
  };

  const onUpdateHandler = useCallback(() => dispatch(fetchMailchimpConditions()), [dispatch]);

  const onErrorLogPanelClick = () => {
    if (!errorLog) dispatch(fetchErrorLog(status && status.batch_id));
  };

  const disableAddCommand = !commandParams.field || !commandParams.condition || !commandParams.action_value;

  if (!items && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <div>
      <FormTitleSection title="Mailchimp" showEditButton={false} />
      <TabContainer>
        <ConditionContainer>
          <div className="mailchimp-field">
            <Select
              value={commandParams.field}
              name="field"
              placeholder="Search field..."
              onChange={onSelectChange}
              options={selectOptions.mailchimpSearchFields}
              isClearable
            />
          </div>
          <div className="mailchimp-condition">
            <Select
              value={commandParams.condition}
              name="condition"
              placeholder="Condition..."
              onChange={onSelectChange}
              options={selectOptions.mailchimpConditionTypes}
              isClearable
            />
          </div>
          <div className="mailchimp-value">
            <BaseFormGroup
              placeholder="Value..."
              name="value"
              value={commandParams.value}
              onChange={onValueChange}
            />
          </div>
          <div className="mailchimp-action-value">
            <Creatable
              value={commandParams.action_value}
              name="action_value"
              placeholder="Tags..."
              onChange={onSelectChange}
              options={selectOptions.mailchimpTags}
              isClearable
            />
          </div>
        </ConditionContainer>
        <Row>
          <Col sm={1}>
            <Button onClick={onCommandAddClick} variant="success" size="sm" disabled={disableAddCommand}>
              <FontAwesomeIcon name="add" icon="plus" />
              &nbsp;Add
            </Button>
          </Col>
        </Row>

        <br />
        <hr />
        <LastStatusContainer>
          <LastSyncStatus status={status.status} date={status.date_time} errorMessage={status.error} />
          <LinkButton variant="link" onClick={onUpdateHandler}>
            <FontAwesomeIcon name="sync" icon="sync" />
            &nbsp;Refresh
          </LinkButton>
        </LastStatusContainer>

        <CommandsTable
          data={items}
          selectedRows={selected}
          onSelect={onSelect}
          onCommandRemove={onRemoveClick}
        />
        <Row>
          <Col sm={12}>
            <br />
            <ButtonContainer space="20px">
              <OverlayTrigger placement="bottom" overlay={tooltip('Process selected conditions')}>
                <Button onClick={onProcessHandler} variant="primary" disabled={blockProcessButton}>
                  <FontAwesomeIcon name="process" icon="cogs" />
                  &nbsp;
                  {blockProcessButton ? 'Processing...' : 'Process'}
                </Button>
              </OverlayTrigger>
            </ButtonContainer>
          </Col>
        </Row>

        {status && status.status === syncStatus.STATUS_ERROR && (
          <>
            <hr />
            <Accordion>
              <Card id="mailchimp-errors-panel" onClick={onErrorLogPanelClick}>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Show error log
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {errorLog && errorLog.map((v) => (
                      <div>
                        <pre>{JSON.stringify(v, null, 2) }</pre>
                      </div>
                    ))}
                    {isFetchingError && (
                      <FontAwesomeIcon icon="spinner" size="2x" spin style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }} />
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </>
        )}

        <RemoveModal
          show={removeParams.showRemoveModal}
          onRemove={onCommandRemove}
          onHide={() => setRemoveParams({ showRemoveModal: false, id: null })}
          text="Are you sure you want to remove this condition?"
        />
        <ModalLoader show={isFetching || isSaving} />
      </TabContainer>
    </div>
  );
};

export default MailchimpPage;
