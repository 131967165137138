import React from 'react';
import {
  Switch, Route, Redirect, useRouteMatch, useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import AccountSettingsPage from '../AccountSettingsPage';
import AssociateUserPage from '../AssociateUserPage';
import CommercialAgreementPage from '../../UserCompanyManagement/CommercialAgreementPage';
import NotFound from '../../NotFoundPage';
import CompanyDetailsPage from '../../CompaniesManagement/CompanyDetailsPage';
import RfqFilterPage from '../RfqFilterPage';
import PartsWatchlist from '../../CompaniesManagement/PartsWatchlist';
import NotificationPreferences from '../NotificationPreferences';
import Payments from '../Payments';
import ProManagementPage from '../../UserCompanyManagement/ProPage';
import ProFeaturesPage from '../../UserCompanyManagement/ProPage/ProFeaturesPage';
import NotVerifiedPage from '../../UserCompanyManagement/ProPage/NotVerifiedPage';

const TabsUM = React.memo(({ isCompany, isVerified, isExternal }) => {
  const match = useRouteMatch();
  const { userId, customerId } = useParams();
  let routes = null;

  if (isCompany) {
    routes = (
      <>
        <Route path={`${match.path}/accountSettings`}><AccountSettingsPage /></Route>
        <Route path={`${match.path}/associateUser`}><AssociateUserPage /></Route>
        <Route path={`${match.path}/rfqFilters`}><RfqFilterPage /></Route>
        <Route path={`${match.path}/notifications`}><NotificationPreferences /></Route>

        <Route path={`${match.path}/commercialAgreement`}><Redirect to={`/companies/${userId}/commercialAgreement`} /></Route>
        <Route path={`${match.path}/companyDetails`}><Redirect to={`/companies/${userId}/companyDetails`} /></Route>
        <Route path={`${match.path}/userDetails`}><Redirect to={`/companies/${userId}/userDetails`} /></Route>
        <Route path={`${match.path}/payments`}><NotFound /></Route>
      </>
    );
  } else {
    const url = `/userManagement/${userId}/${customerId}/accountSettings`;
    routes = (
      <>
        <Route path={`${match.path}/accountSettings`}><AccountSettingsPage /></Route>
        <Route path={`${match.path}/companyDetails`}><CompanyDetailsPage /></Route>
        <Route path={`${match.path}/associateUser`}><AssociateUserPage /></Route>
        <Route path={`${match.path}/commercialAgreement`}><CommercialAgreementPage /></Route>
        <Route path={`${match.path}/payments`}><Payments /></Route>
        <Route path={`${match.path}/rfqFilters`}><RfqFilterPage /></Route>
        <Route path={`${match.path}/notifications`}><NotificationPreferences /></Route>
        <Route path={`${match.path}/partsWatchlist`}><PartsWatchlist /></Route>

        <Route path={`${match.path}/userSettings`}><Redirect to={url} /></Route>
        <Route path={`${match.path}/userDetails`}><Redirect to={url} /></Route>
        <Route path={`${match.path}/blocking`}><Redirect to={url} /></Route>
        <Route path={`${match.path}/proManagement`}>{isVerified && !isExternal ? <ProManagementPage /> : <NotVerifiedPage /> }</Route>
        <Route path={`${match.path}/proFeatures`}>{isVerified && !isExternal ? <ProFeaturesPage /> : <NotVerifiedPage />}</Route>
      </>
    );
  }

  return (
    <Switch>
      <Redirect
        exact
        from={match.path}
        to={match.url[match.url.length - 1] === '/' ? `${match.url}accountSettings` : `${match.url}/accountSettings`}
      />
      {routes}
    </Switch>
  );
});

TabsUM.propTypes = {
  isCompany: PropTypes.bool,
  isVerified: PropTypes.bool,
  isExternal: PropTypes.bool,
};

export default TabsUM;
