import createErrorValidationObj from '../../../utils/createErrorValidationObj';
import emailValidator from '../../../utils/emailValidator';

const sitaAddrPattern = /^\w{7}$/i;
const supplierPattern = /^\w{3,32}$/i;

export const sitaAddressValidator = (value) => {
  if (!value) return createErrorValidationObj('Please enter a value');
  // eslint-disable-next-line no-restricted-globals
  if (!sitaAddrPattern.test(value)) return createErrorValidationObj('Please enter a correct SITA address');
  return null;
};

export const supplierCodeValidator = (value) => {
  if (!value) return createErrorValidationObj('Please enter a partner code');
  // eslint-disable-next-line no-restricted-globals
  if (!supplierPattern.test(value)) return createErrorValidationObj('Please enter a correct code of the partner (3-32 chars)');
  return null;
};

export const defautCodeValidator = (value) => {
  if (value?.isActive === true) {
    return supplierCodeValidator(value.code);
  }
  return null;
};

export const forwardEmailValidator = (email) => {
  const trimmedEmail = email.trim();
  if (!trimmedEmail) return createErrorValidationObj('Please enter an email address ');
  if (!emailValidator(trimmedEmail)) return createErrorValidationObj('Please enter a valid email address');
  return null;
};
