import axios from 'axios';

import createErrorValidationObj from '../../../utils/createErrorValidationObj';

export default async function newPartNumberValidator(value, userId) {
  if (!value.trim()) return createErrorValidationObj('Please enter a part number');
  if (value.trim().length < 3) {
    return createErrorValidationObj('Please enter at least 3 characters');
  }

  try {
    const response = await axios.get(`/suggest/parts-watchlist/part?q=${value}&user_id=${userId}`);
    if (response?.data?.payload?.items.some((pn) => pn.title.toUpperCase() === value.toUpperCase())) {
      return createErrorValidationObj('This part is already on your Watchlist');
    }
    return null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Watchlist suggest error');
    return null;
  }
}
