import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ActionsList from './HistoryBlock';
import TopBar from '../TasksSection/TopBar';
import BuiltInQuoteForm from '../ProvideQuote/BuiltInQuoteForm';
import PaymentRequestForm from '../PaymentsTasks/PaymentRequestForm';
import SendMessageForm from '../PaymentsTasks/SendMessageForm';
import WireTransferForm from '../PaymentsTasks/WireTransferForm';
import OrderStatusForm from '../PaymentsTasks/OrderStatusForm';
import ReportPaymentForm from '../PaymentsTasks/ReportPaymentForm';
import IgnoreTaskForm from '../ProvideQuote/TasksIgnoreForm';

import Img from '../../Img';
import { setHistoryBar } from '../../../actions/networkOperationsActions/formManagementActions';
import { getNocFormStore } from '../../../selectors';
import { ScrollableContainer as ListScrollableContainer } from '../../StyledComponents';
import BoxImg from '../../../assets/box.png';
import { color } from '../../../constants';
import { NOC_FORMS } from '../../../constants/operationCenter';
import { HistoryContainer } from '../StyledComponents';

const HistoryCollapseButton = styled.button`
  font-size: 26px;
  opacity: 50%;
  position: absolute;
  left: -32px;
  top: 50%;
  border-radius: 0px 10px 10px 0;
  z-index: 2;

  color: ${color.darkBirch};
  border: none;
  background-color: transparent;
  &:hover {
    opacity: 100%;
    transition: 0.5s all;
  }
  ${(props) => (!props.showBar && 'left: 0;')};
  transition: opacity 0.5s all;
`;
const Title = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  font-weight: 600;
  font-size: 20px;
  color: ${color.darkGrey};
`;
const Text = styled.p`
  margin-top: 20px;
  margin-left: 20px;
  font-size: 16px;
  color: ${color.grey};
`;
const Box = styled(Img)`
  width: 180px;
  height: 180px;
`;
const Container = styled.div`
  margin-top: 180px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 992px) {
    img {
      width: 100px;
      height: auto;
      margin: 0 auto;
      display: block;
      text-align: center;
    }
  }
`;
const BuildInQuoteContainer = styled.div`
  padding: 15px;
`;

const History = ({
  history, height, isEmptyTaskList, isUpdating, ...formProps
}) => {
  const { currentForm, showHistoryBar } = useSelector(getNocFormStore);
  const dispatch = useDispatch();
  const isLarge = currentForm?.type === NOC_FORMS.BUILT_IN_QUOTE || currentForm?.type === NOC_FORMS.PAYMENT_REQUEST;

  let content;
  if (isUpdating) content = <><Title>History</Title></>;
  else if (currentForm?.type === NOC_FORMS.BUILT_IN_QUOTE) {
    content = (
      <ListScrollableContainer maxHeight={height - 160}>
        <BuildInQuoteContainer>
          <BuiltInQuoteForm key={currentForm.messageId} messageId={currentForm.messageId} {...formProps} />
        </BuildInQuoteContainer>
      </ListScrollableContainer>
    );
  } else if (currentForm?.type === NOC_FORMS.ORDER_STATUS) {
    content = (
      <ListScrollableContainer maxHeight={height - 160}>
        <BuildInQuoteContainer>
          <OrderStatusForm orderId={formProps.orderId} taskId={formProps.taskId} />
        </BuildInQuoteContainer>
      </ListScrollableContainer>
    );
  } else if (currentForm?.type === NOC_FORMS.SEND_MESSAGE) {
    content = (
      <ListScrollableContainer maxHeight={height - 160}>
        <BuildInQuoteContainer>
          <SendMessageForm orderId={formProps.orderId} taskId={formProps.taskId} />
        </BuildInQuoteContainer>
      </ListScrollableContainer>
    );
  } else if (currentForm?.type === NOC_FORMS.PAYMENT_REQUEST) {
    content = (
      <ListScrollableContainer maxHeight={height - 160}>
        <BuildInQuoteContainer>
          <PaymentRequestForm orderId={formProps.orderId} />
        </BuildInQuoteContainer>
      </ListScrollableContainer>
    );
  } else if (currentForm?.type === NOC_FORMS.WIRE_TRANSFER) {
    content = (
      <ListScrollableContainer maxHeight={height - 160}>
        <BuildInQuoteContainer>
          <WireTransferForm orderId={formProps.orderId} taskId={formProps.taskId} />
        </BuildInQuoteContainer>
      </ListScrollableContainer>
    );
  } else if (currentForm?.type === NOC_FORMS.REPORT_PAYMENT) {
    content = (
      <ListScrollableContainer maxHeight={height - 160}>
        <BuildInQuoteContainer>
          <ReportPaymentForm orderId={formProps.orderId} taskId={formProps.taskId} />
        </BuildInQuoteContainer>
      </ListScrollableContainer>
    );
  } else if (currentForm?.type === NOC_FORMS.IGNORE_TASK) {
    content = (
      <ListScrollableContainer maxHeight={height - 160}>
        <BuildInQuoteContainer>
          <IgnoreTaskForm messageId={currentForm.messageId} />
        </BuildInQuoteContainer>
      </ListScrollableContainer>
    );
  } else if (!isEmptyTaskList && history && history.length) {
    content = (
      <ListScrollableContainer maxHeight={height - 160}>
        <Title>History</Title>
        {history.map((e) => <ActionsList key={e.title} title={e.title} actions={e.groupedHistory} />)}
      </ListScrollableContainer>
    );
  } else {
    content = (
      <Container>
        <div>
          <Text>No previous history</Text>
          <Box src={BoxImg} alt="No history" />
        </div>
      </Container>
    );
  }

  return (
    <HistoryContainer isHidden={!showHistoryBar} isLarge={isLarge}>
      <div className="history-sidebar">
        <TopBar renderHeaderText={() => null} />
        {content}
      </div>
      <HistoryCollapseButton
        showBar={showHistoryBar}
        type="button"
        aria-controls="history-sidebar"
        aria-expanded={showHistoryBar}
        onClick={() => dispatch(setHistoryBar(!showHistoryBar))}
      >
        {
        showHistoryBar
          ? <FontAwesomeIcon icon="chevron-right" title="Hide" />
          : <FontAwesomeIcon icon="chevron-left" title="Show" />
        }
      </HistoryCollapseButton>
    </HistoryContainer>
  );
};

History.propTypes = {
  collapsed: PropTypes.bool,
  history: PropTypes.arrayOf(PropTypes.object),
  orderId: PropTypes.string,
  height: PropTypes.number,
  isEmptyTaskList: PropTypes.bool,
  isUpdating: PropTypes.bool,
  onCollapse: PropTypes.func,
};

export default History;
