import React, { useMemo, useCallback } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TableStyles from '../../Table/TableStyles';
import Table from '../../Table/Table';
import header from '../../Table/HeaderWithSort';

import A from '../../A';
import ToggleButton from './NotificationToggle';
import { color, PUBLIC_URL } from '../../../constants';

import editButtonImg from '../../../assets/btn-edit-sprite.png';
import spriteCommonElements from '../../../assets/sprite-common-elements.png';
import { rfqFilterStatus, rfqFilterType } from '../../../constants/index';

const TableContainer = styled(TableStyles)`
  tbody tr td {
    cursor: pointer;
  }
`;
const EditButton = styled.div`
  cursor: pointer;
  display: inline-block;
  background: url(${editButtonImg}) no-repeat 0 0;
  width: 23px;
  height: 23px;
  
  &:hover {
    background-position: 0 -66px;
  }
`;
const RemoveButton = styled.div`
  cursor: pointer;
  display: inline-block;
  background: url(${spriteCommonElements}) no-repeat;
  background-position: -355px 0;
  width: 10px;
  height: 10px;

  &:hover {
    background-position: -375px 0;
  } 
`;
const Link = styled(A)`
  font-weight: bold;
  color: ${color.inputBorder};
  text-decoration: underline;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
    color: ${color.birch};
  }
  &:active {
    color: ${color.darkBirch};
  }
`;
const CellWithLink = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Owner = styled.div`
  max-width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const tooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

export default function RfqFilterTable({
  data, title, agents, customerId, notificationToggle, onEdit, onRemove, disabled,
}) {
  const editFormatter = useCallback(
    (row) => {
      const clickHandler = () => onEdit(row.filter_id);
      return row.filter_status === rfqFilterStatus.SYSTEM || row.customer_id !== customerId || disabled
        ? ''
        : (
          <div style={{ textAlign: 'center' }}>
            <EditButton onClick={clickHandler} title="Edit" data-testid={`edit_bttn_${row.filter_id}`} />
          </div>
        );
    }, [customerId, onEdit, disabled],
  );

  const removeFormatter = useCallback(
    (row) => {
      const clickHandler = () => (onRemove && onRemove(row.filter_id));
      return row.filter_status === rfqFilterStatus.SYSTEM || row.customer_id !== customerId || disabled
        ? ''
        : (
          <div style={{ textAlign: 'center' }}>
            <RemoveButton onClick={clickHandler} title="Remove" data-testid={`remove_bttn_${row.filter_id}`} />
          </div>
        );
    }, [customerId, onRemove, disabled],
  );

  const notificationFormatter = useCallback(
    (cell, row) => (
      cell === null || row.filter_type === rfqFilterType.AUTOPILOT
        ? ''
        : (
          <ToggleButton
            id={row.filter_id}
            checked={row.enabled}
            onNotificationChange={() => notificationToggle(!row.enabled, customerId, row.filter_id)}
          />
        )
    ), [customerId, notificationToggle],
  );

  const ownerFormatter = useCallback(
    (row, consts) => {
      let content = null;

      if (row.filter_status === consts.SYSTEM) content = '-';
      else if (row.customer_id !== customerId) {
        const findElement = agents.find((v) => v.value === +row.customer_id);
        content = (
          <CellWithLink>
            <OverlayTrigger placement="top" overlay={tooltip(findElement && findElement.label)}>
              <Owner>{findElement && findElement.label}</Owner>
            </OverlayTrigger>
            <div>
              &nbsp;&#124;&nbsp;
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={`${PUBLIC_URL}/userManagement/${row.user_id}/${row.customer_id}/rfqFilters`}
              >
                Filter Settings
              </Link>
            </div>
          </CellWithLink>
        );
      } else {
        const findElement = agents.find((v) => v.value === +row.customer_id);
        content = (findElement && findElement.label) || '';
      }
      return content;
    }, [agents, customerId],
  );

  const columns = useMemo(
    () => [
      {
        Header: () => header('Name'),
        accessor: 'filter_name',
      }, {
        Header: () => header('Owner'),
        accessor: 'owner',
        Cell: ({ row }) => ownerFormatter(row.original, rfqFilterStatus),
      }, {
        Header: () => header('Status'),
        accessor: 'filter_status',
        Cell: ({ value }) => statusFormat(value, rfqFilterStatus),
        style: {
          width: '70px',
        },
      }, {
        Header: () => header('Filter type'),
        accessor: 'filter_type',
        Cell: ({ value }) => filterTypeFormat(value, rfqFilterType),
        style: {
          width: '90px',
        },
      }, {
        Header: () => header('Notifications'),
        accessor: 'enabled',
        Cell: ({ value, row }) => notificationFormatter(value, row.original),
        style: {
          width: '100px',
        },
      }, {
        Header: () => header(''),
        accessor: 'edit',
        Cell: ({ row }) => editFormatter(row.original),
        style: {
          width: '50px',
        },
      }, {
        Header: () => header(''),
        accessor: 'remove',
        Cell: ({ row }) => removeFormatter(row.original),
        style: {
          width: '50px',
        },
      },
    ], [editFormatter, notificationFormatter, ownerFormatter, removeFormatter],
  );

  const options = {
    noDataContent: 'Sorry, there are no results matching your criteria',
  };

  return (
    <div>
      {title}
      <TableContainer>
        {title}
        <Table columns={columns} data={data} options={options} />
      </TableContainer>
    </div>
  );
}

const statusFormat = (cell, filterStatus) => {
  if (!filterStatus) return '';

  switch (cell) {
    case filterStatus.PRIVATE: return 'Private';
    case filterStatus.PUBLIC: return 'Public';
    case filterStatus.SYSTEM: return 'Private';
    default:
      // eslint-disable-next-line no-console
      console.error('Unexpected rfqFilterStatus value of the filter: ', cell);
      return '';
  }
};

const filterTypeFormat = (cell, filterType) => {
  if (!filterType) return '';

  switch (cell) {
    case filterType.AUTOPILOT: return 'Autopilot';
    case filterType.PURCHASES: return 'Purchases';
    case filterType.SALES: return 'Sales';
    default:
      // eslint-disable-next-line no-console
      console.error('Unexpected rfqFilterStatus value of the filter: ', cell);
      return '';
  }
};

RfqFilterTable.propTypes = {
  customerId: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  agents: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  notificationToggle: PropTypes.func,
};
