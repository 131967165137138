import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { FileLink as FileLinkNormal, StyledLinkButton as LinkButtonNormal } from '../StyledComponents';

const LinkButton = styled(LinkButtonNormal)`
  margin-top: 7px;
  margin-left: 5px;
  padding: 0;
  font-size: 16px;
`;
const RemoveButton = styled(LinkButton)`
  margin-left: 0;
  margin-right: 0;
  font-size: 14px;
`;
const FileLink = styled(FileLinkNormal)`
  margin-top: 8px;
  font-size: 14px;

  max-width: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const FileContainer = styled.div`
  display: flex;
`;

export default function FileChooser({ file, onFileAdd, onFileRemove }) {
  const fileInputRef = useRef(null);

  const handleUploadClick = () => fileInputRef.current.click();

  const element = file
    ? (
      <FileContainer>
        <FileLink target={file.url} rel="noopener noreferrer" to={`${file.url}`} title={file.name}>
          {file.name}
        </FileLink>
        <RemoveButton onClick={onFileRemove} variant="link" title="Remove file">
          <FontAwesomeIcon icon="times" />
        </RemoveButton>
      </FileContainer>
    ) : (
      <>
        <input
          ref={fileInputRef}
          type="file"
          onChange={onFileAdd}
          style={{ display: 'none' }}
        />
        <LinkButton onClick={handleUploadClick} variant="link" title="Upload tag">
          <FontAwesomeIcon icon="paperclip" />
          &nbsp;Upload
        </LinkButton>
      </>
    );

  return element;
}

FileChooser.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    thumbnail_url: PropTypes.string,
    url: PropTypes.string,
  }),
  onFileAdd: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func.isRequired,
};
