import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import TopBar from '../TasksSection/TopBar';
import ChatPage from '../../OrderManagement/ChatPage/ChatPage';
import NoTasks from './NoTasks';
import { getNocFormStore } from '../../../selectors';
import WelcomeToNoc from './WelcomeToNoc';
import WelcomeToPaymentsCenter from './WelcomeToPaymentsCenter';
import Loader from '../../Loader';
import { ScrollableContainer } from '../../StyledComponents';

const Main = styled.div`
  && {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 32px;
    position: relative;  
  }
`;
const ChatContainer = styled(ScrollableContainer)`
  padding: 20px 20px;
`;

const NetworkChatContainer = ({
  height, isEmptyTaskList, isTaskInfoFetching, renderButton, isPaymentSection, ...otherProps
}) => {
  const { currentForm } = useSelector(getNocFormStore);
  let chatSections = null;

  if (isTaskInfoFetching) chatSections = <Loader />;
  else if (otherProps.taskId) chatSections = <ChatPage {...otherProps} blockMessageProcessing />;
  else if (isEmptyTaskList) chatSections = <NoTasks />;
  else if (isPaymentSection) chatSections = <WelcomeToPaymentsCenter />;
  else chatSections = <WelcomeToNoc />;
  const headerText = otherProps.taskId ? `Order Management ${String.fromCharCode('0x2022')} ${otherProps.orderId}` : '';

  return (
    <Main>
      <TopBar renderHeaderText={() => headerText} />
      <ChatContainer maxHeight={height - 190}>
        {chatSections}
        {otherProps.taskId && (currentForm === null) && renderButton()}
      </ChatContainer>
    </Main>
  );
};

NetworkChatContainer.propTypes = {
  taskId: PropTypes.string,
  orderId: PropTypes.string,
  height: PropTypes.number,
  isEmptyTaskList: PropTypes.bool,
  isTaskInfoFetching: PropTypes.bool,
  isPaymentSection: PropTypes.bool,
  showActionsDropdown: PropTypes.bool,
  renderButton: PropTypes.func,
  showQuoteHandler: PropTypes.func,
};

export default NetworkChatContainer;
