import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { fetchBankAccount } from '../../../actions/settings/bankAccount';
import { getBankAccountStore } from '../../../selectors';

const DetailsContainer = styled.div`
  margin-bottom: 1em;
`;
const RowDetail = styled.div`
  display: flex;
  font-size: 16px;
`;
const Key = styled.div`
  min-width: 200px;
  font-weight: bold;
`;

const BankAccountDetails = ({ amount }) => {
  const dispatch = useDispatch();
  const { bankAccount } = useSelector(getBankAccountStore);

  useEffect(() => {
    dispatch(fetchBankAccount());
  }, [dispatch]);

  if (!bankAccount) return null;

  return (
    <DetailsContainer>
      {bankAccount.account_data.split('\n').map((v) => {
        const d = v.split(': ');
        return (
          <RowDetail key={d}>
            <Key>{d[0].concat(':')}</Key>
            <div>{d[1]}</div>
          </RowDetail>
        );
      })}
      <RowDetail>
        <Key>Amount:</Key>
        <div>{amount}</div>
      </RowDetail>
    </DetailsContainer>
  );
};

BankAccountDetails.propTypes = {
  amount: PropTypes.string,
};

export default BankAccountDetails;
