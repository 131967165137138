import React from 'react';
import {
  Form,
  FormLabel,
  InputGroup,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import renderValidation from '../renderHelpBlock';
import { SelectContainer } from '../../StyledComponents';

const StyledFormLabel = styled(FormLabel)`
  text-align: ${({ align }) => align || 'left'};
`;

const BaseFormGroup = ({
  options,
  helpText,
  labelText,
  validationState,
  addonText,
  maxWidth,
  inputRef,
  ...other
}) => {
  const controlValidation = {
    isValid: validationState === 'success',
    isInvalid: validationState === 'error',
  };

  let control;
  if (options) {
    const optionList = options.map((option) => {
      const key = option[0];
      const value = option[1];
      return <option key={key} value={key}>{value}</option>;
    });
    control = (
      <>
        <SelectContainer maxWidth={maxWidth}>
          <Form.Control as="select" custom {...controlValidation} {...other}>
            {optionList}
          </Form.Control>
        </SelectContainer>
        {renderValidation(helpText, validationState)}
      </>
    );
  } else if (addonText) {
    control = (
      <InputGroup>
        <Form.Control {...controlValidation} {...other} />
        <InputGroup.Append>
          <InputGroup.Text>
            {addonText}
          </InputGroup.Text>
        </InputGroup.Append>
        {renderValidation(helpText, validationState)}
      </InputGroup>
    );
  } else {
    control = (
      <>
        <Form.Control {...controlValidation} {...other} ref={inputRef} />
        {renderValidation(helpText, validationState)}
      </>
    );
  }

  return (
    <Form.Group>
      {labelText && <StyledFormLabel>{labelText}</StyledFormLabel>}
      {control}
    </Form.Group>
  );
};

BaseFormGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // Two-dimensional array in which Each row is considered as option tag of select.
  // Ex.: [ 'audi': 'Audi' ] transforms into <options value="audi">Audi</option>
  options: PropTypes.arrayOf(PropTypes.array),
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  labelText: PropTypes.string,
  validationState: PropTypes.string,
  addonText: PropTypes.string,
  maxWidth: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.element }),
  ]),
};

export default BaseFormGroup;
