import axios from 'axios';

import {
  CUSTOMERS_LIST_GET,
  CUSTOMERS_LIST_GET_SUCCESS,
  CUSTOMERS_LIST_FAIL,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';

const fetchCustomersListStart = () => ({ type: CUSTOMERS_LIST_GET });
const fetchCustomersListSuccess = (list) => ({
  type: CUSTOMERS_LIST_GET_SUCCESS,
  payload: list,
});

const fetchCustomersListFail = () => ({ type: CUSTOMERS_LIST_FAIL });

export default (params) => async (dispatch) => {
  dispatch(fetchCustomersListStart());
  try {
    const response = await axios('/rest/V1/eplane/backoffice/customers/search', { params });
    dispatch(fetchCustomersListSuccess((response?.data?.customers)));
  } catch (e) {
    requestErrorHandler(e.response, dispatch, fetchCustomersListFail, e);
  }
};
