import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

import { ButtonContainer } from '../StyleComponents';
import FileIcon from './FileIcon';
import UploadImage from '../../../assets/upload-icon.png';
import { color } from '../../../constants';

const LinkButtonContainer = styled(ButtonContainer)`
  margin-top: -10px;
  margin-bottom: 10px;
  button {
    font-size: 16px;
  }
`;
const Container = styled.section`
  margin-right: 20px;
  width: 350px;
  border: 3px dashed ${color.lightGrey};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 240px;
  padding: 10px 10px 10px 10px;
  div {
    text-align: center;
    color: ${color.grey};
    font-size: 18px;
    line-height: 30px;
  }
`;
const UploadImageElement = styled.div`
  width: 41px;
  height: 37px;
  background: url(${UploadImage}) no-repeat center;
`;
const FileNameContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
`;

// eslint-disable-next-line react/prop-types
export const dropezoneContent = (title, extensions, disabled) => ({ onDropAccepted, getRootProps, getInputProps }) => (
  <Container {...getRootProps()}>
    <input {...getInputProps()} />
    <UploadImageElement />
    <div>{title}</div>
    <div>{extensions}</div>
    <div>or</div>

    <ButtonContainer btnWidth="120px">
      <Button variant="info" onClick={onDropAccepted} disabled={disabled}>Browse</Button>
    </ButtonContainer>
  </Container>
);

export const filledDropezoneContent = (file, onRemove) => () => (
  <Container>
    <FileIcon extension={file?.name?.split('.').pop()} />
    <FileNameContainer>{file?.name}</FileNameContainer>

    <ButtonContainer btnWidth="120px">
      <Button variant="info" onClick={onRemove}>Remove</Button>
    </ButtonContainer>
  </Container>
);

export const filledDropezoneContentWithUpload = (file, onRemove, onUpload) => () => (
  <Container>
    <FileIcon extension={file?.name?.split('.').pop()} />
    <FileNameContainer>{file?.name}</FileNameContainer>

    <LinkButtonContainer btnWidth="120px">
      <Button variant="link" onClick={onRemove}>Remove</Button>
    </LinkButtonContainer>
    <ButtonContainer btnWidth="120px">
      <Button variant="info" onClick={onUpload}>Upload</Button>
    </ButtonContainer>
  </Container>
);
