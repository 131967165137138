import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import FormTitleSection from '../../FormComponents/TitleSection/index';
import { TabContainer } from '../../StyledComponents';
import DevEmailsTable from '../Emails';
import { authorizationFail } from '../../../actions/authActions';
import FormAlert from '../../FormComponents/Alert';
import ErrorModal from '../../ErrorModal';

// const templates = [{
//   id: 'password_reset_template',
//   data: '{"name": "Adam", "firstname" : "Smith"}',
// },
// ];

const DevEmails = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [alertMessage, setAlertMessage] = useState({
    text: '',
    title: '',
    type: '',
  });
  const [showErrorModal, setErrorModal] = useState(false);
  const [disabledButtonSend, setButtonSend] = useState(false);

  const onClickSendHandler = (dataForSend) => {
    setButtonSend(true);
    const promises = dataForSend.map((elem) => axios.post('/rest/default/V1/eplane/backoffice/email/send', elem));
    axios.all(promises).then(() => {
      setButtonSend(false);
      setAlertMessage({ text: 'Email successfully sent', type: 'success' });
    }).catch((error) => {
      setButtonSend(false);
      errorHandler(error);
    });
  };

  const errorHandler = (e) => {
    if (e && e.response && e.response.status === 403) {
      dispatch(authorizationFail(location.pathname));
    } else {
      setErrorModal(true);
    }
  };

  return (
    <>
      <FormTitleSection title="Emails" showEditButton={false} />
      <TabContainer>
        <FormAlert
          show={!!alertMessage.text}
          message={alertMessage.text}
          type={alertMessage.type}
          onClose={(() => setAlertMessage({ text: '', type: '' }))}
        />
        <DevEmailsTable
          setAlertMessage={() => setAlertMessage({ text: 'Email successfully sent', type: 'success' })}
          onClickSend={onClickSendHandler}
          disabled={disabledButtonSend}
        />
      </TabContainer>
      <ErrorModal show={showErrorModal} onHide={() => setErrorModal(false)} />
    </>
  );
};

export default DevEmails;
