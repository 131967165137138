import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

import TableStyles from '../../Table/TableStyles';
import Table from '../../Table/TableWithRowSelection';
import header from '../../Table/HeaderWithSort';

import { RemoveButton } from '../../UserCompanyManagement/Table';
import statusFormatter from './statusFormatter';
import {
  mailchimpConditionStatus, mailchimpSearchFields, mailchimpConditionTypes,
} from '../../../constants/mailchimp';
import { getNameById } from '../../../utils/constantsHandlers';
import { color } from '../../../constants';

const TableContainer = styled(TableStyles)`
  tr th:first-child {
    background-color: ${color.white};
  }
`;

const CommandsTable = React.memo(({
  data, options, onCommandRemove, ...other
}) => {
  const removeFormatter = useCallback((cell, row) => {
    const clickHandler = () => (onCommandRemove && onCommandRemove(row.condition_id));
    return cell === mailchimpConditionStatus.IN_PROGRESS.id
      ? ''
      : (
        <div title="Remove" style={{ textAlign: 'center' }}>
          <RemoveButton onClick={clickHandler} title="Remove" />
        </div>
      );
  }, [onCommandRemove]);

  const columns = useMemo(
    () => [
      {
        Header: () => header('Field'),
        accessor: 'field',
        Cell: ({ value }) => getNameById(value, mailchimpSearchFields),
      }, {
        Header: () => header('Condition'),
        accessor: 'condition',
        Cell: ({ value }) => `${getNameById(value, mailchimpConditionTypes)}`,
      }, {
        Header: () => header('Value'),
        accessor: 'value',
      }, {
        Header: () => header('Tag'),
        accessor: 'action_value',
      }, {
        Header: () => header('Status'),
        accessor: 'status',
        Cell: ({ value }) => statusFormatter(value, `${getNameById(value, mailchimpConditionStatus)}`),
        style: {
          width: '56px',
        },
      }, {
        Header: () => header(''),
        accessor: 'remove',
        Cell: ({ value, row }) => removeFormatter(value, row.original),
        style: {
          width: '50px',
        },
      },
    ], [removeFormatter],
  );

  return (
    <Row>
      <Col sm={12}>
        <TableContainer>
          <Table
            columns={columns}
            data={data}
            options={options}
            {...other}
          />
        </TableContainer>
      </Col>
    </Row>
  );
});

CommandsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.shape({}),
  onCommandRemove: PropTypes.func,
  selectRowProp: PropTypes.shape({}),
};

export default CommandsTable;
