import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as ButtonBS } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchLogsData } from '../../actions/dev/logsActions';
import { addComment } from '../../actions/chatActions';
import {
  getLogsManagementStore, getCommentsStore, getAdminCustomerId,
} from '../../selectors';
import { logObjects } from '../../constants/index';
import { LinkButton } from '../StyledComponents';
import Comments from './Comments';
import { INITIAL_COMMENTS_SIZE, color } from '../../constants';

const LoaderContainer = styled.div`
  margin: 0 10px;
  font-size: 24px;
  color: ${color.grey};
`;
const Counter = styled.div`
  color: ${color.white};
  background: ${color.googleBlue};
  border-radius: 7px;
  padding: 2px 5px;
  font-weight: bold;
  font-size: 11px;
  position: absolute;
  right: 8px;
  top: 3px;
`;
const CloseButton = styled.button`
  position: absolute;
  right: 5px;
  background: none;
  border: none;
  
  align-self: flex-end;
  margin-right: 15px;
  font-size: 24px;
  color: ${color.lightGrey};
  &:hover {
    color: ${color.borderLightGrey};
  }
`;
const SendButton = styled(ButtonBS)`
  && {
    background: none;
    border: none;

    margin: 0 10px;
    padding: 0;
    font-size: 24px;
    color: ${color.grey};
    text-decoration: none;
  }
  &&:hover {
    color: ${color.darkBirch};
  }
`;
const Container = styled.div`
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  margin: 0 50px 50px 0;
  max-width: 370px;
  position: fixed;
  z-index: 1;
  width: 90vw;
`;
const Widget = styled.div`
  -webkit-animation-delay: 0;
  -webkit-animation-duration: .5s;
  -webkit-animation-name: d;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: .5s;
  -moz-animation-name: d;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: .5s;
  animation-name: d;
  animation-fill-mode: forwards;
  border-radius: 10px;
  box-shadow: 0 2px 10px 1px #b5b5b5;

  .widget-header {
    background-color: ${color.birch};
    border-radius: 10px 10px 0 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 15px 0 15px;
  }
  .widget-show-more {
    padding: 10px 10px;
    min-height: 40px;
    background-color: ${color.whiteSmoke};
    text-align: center;
    color: ${color.grey};
  }
  .widget-comments {
    background-color: #fff;
    height: 50vh;
    max-height: 410px;
    overflow-y: scroll;
    padding-top: 10px;
    -webkit-overflow-scrolling: touch;
  }
  .widget-form {
    align-items: center;
    display: flex;
    background-color: #f4f7f9;
    height: 45px;
    padding: 5px;
    border-radius: 0 0 10px 10px;
    textarea {
      resize: none;
    }
  }
  .widget-new-comment {
    width: 100%;
    border: 0;
    background-color: #f4f7f9;
    height: 30px;
    padding-left: 15px;
    padding-top: 5px;
  }
  .widget-comment-text {
    margin-top: 10px;
  }
`;
const ButtonContainer = styled.div`
  align-self: flex-end;
  margin-top: 10px;
  padding: 5px 10px;
  border: solid 1px ${color.altoGrey};
  background: white;
  border-radius: 20px;
  width: 150px;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  &:hover {
    background: ${color.altoGrey};
  }
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
`;
const Text = styled.div`
  margin-left: 10px;
`;

const CommentsWidget = () => {
  const dispatch = useDispatch();
  const { userId, customerId } = useParams();

  const {
    isFetching,
  } = useSelector(getLogsManagementStore);
  const {
    comments, total, isSending, pageSize: pz, updateRequired,
  } = useSelector(getCommentsStore);
  const adminCustomerId = useSelector(getAdminCustomerId);

  const [isOpened, setOpenState] = useState(false);
  const [pageSize, setPageSize] = useState(INITIAL_COMMENTS_SIZE);
  const [commentText, setComment] = useState('');
  const startRef = React.createRef();
  const endRef = React.createRef();

  const addCommentHandler = () => {
    const data = { comment: commentText };
    setComment('');
    setPageSize(10);
    dispatch(addComment(data, customerId || userId, !!customerId));
  };

  const showMoreHandler = () => {
    if ((pageSize < total) || !isFetching) setPageSize(pageSize + getLoadMoreSize(total));
  };

  const onAddPressAdd = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && e.ctrlKey) {
      addCommentHandler();
    }
  };

  const openCommentsHandler = () => {
    if (!isFetching) setOpenState(!isOpened);
  };

  useEffect(() => {
    dispatch(fetchLogsData({
      s: 'desc',
      pageSize,
      objectId: customerId || userId,
      objectType: customerId ? logObjects.CUSTOMER.id : logObjects.USER.id,
    }));
  }, [dispatch, userId, customerId, pageSize]);

  useEffect(() => {
    if (updateRequired) {
      dispatch(fetchLogsData({
        s: 'desc',
        pageSize,
        objectId: customerId || userId,
        objectType: customerId ? logObjects.CUSTOMER.id : logObjects.USER.id,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId, customerId, updateRequired]);

  useEffect(() => {
    if (startRef && startRef.current) startRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  useEffect(() => {
    if (endRef && endRef.current) endRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, isOpened]);

  useEffect(() => {
    if (endRef && endRef.current) endRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, isOpened]);

  return (
    <Container>
      {isOpened && (
        <Widget>
          <div className="widget-header">
            <CloseButton onClick={() => setOpenState(!isOpened)}>
              <FontAwesomeIcon icon="times" />
            </CloseButton>
            <h4>Comments and Logs</h4>
          </div>
          <div className="widget-show-more">
            {isFetching && <FontAwesomeIcon icon="spinner" size="lg" spin />}
            &nbsp;
            {(pz < total) && <LinkButton onClick={showMoreHandler}><b>Show more...</b></LinkButton>}
          </div>
          <div className="widget-comments">
            <div ref={startRef} />
            <Comments comments={comments} adminCustomerId={adminCustomerId} />
            <div ref={endRef} />
          </div>
          <form className="widget-form">
            <textarea
              value={commentText}
              type="text"
              className="widget-new-comment"
              name="comment"
              placeholder="Add a comment..."
              autoComplete="off"
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={onAddPressAdd}
            />
            {
            isSending
              ? <LoaderContainer><FontAwesomeIcon icon="spinner" size="lg" spin /></LoaderContainer>
              : (
                <SendButton disabled={commentText.trim() === ''} onClick={addCommentHandler} title="Send">
                  <FontAwesomeIcon icon="paper-plane" />
                </SendButton>
              )
            }
          </form>
        </Widget>
      )}
      <ButtonContainer>
        <Button onClick={openCommentsHandler}>
          <FontAwesomeIcon icon="comments" size="2x" color={color.birch} />
          <Text>Comments</Text>
        </Button>
        {!isOpened && !isFetching && <Counter>{total || '0'}</Counter>}
      </ButtonContainer>
    </Container>
  );
};

const getLoadMoreSize = (total) => {
  const diff = total - INITIAL_COMMENTS_SIZE;
  if (diff < 500) return 100;
  if (diff > 5000) return 1000;
  return Math.round(diff * 0.2);
};

export default CommentsWidget;
