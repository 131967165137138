import React from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';

import NoDataRow from './NoData';

function ReactTable({
  columns,
  data = [],
  noDataContent = 'There is no data to display',
  onRowClick,
  options,
  ...props
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    visibleColumns,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
  );

  const tbodyContent = data.length !== 0
    ? (
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} onClick={() => onRowClick && onRowClick(row.original)}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    )
    : <NoDataRow colSpan={visibleColumns.length}>{options?.noDataContent ?? noDataContent}</NoDataRow>;

  return (
    <table {...getTableProps()} {...props}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                style={column.style}
                {...column.getHeaderProps()}
                className="table-header-cell"
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      {tbodyContent}
    </table>
  );
}

ReactTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.shape({
    noDataContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  noDataContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onRowClick: PropTypes.func,
};

export default ReactTable;
