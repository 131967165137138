import {
  CHAT_COMMENT_ADD,
  CHAT_COMMENT_ADD_SUCCESS,
  CHAT_UPDATE_REQUIRED,
  CHAT_FAIL,
  LOGS_DATA_FETCH_SUCCESS,
} from '../constants';

const initialState = {
  total: null,
  comments: null,
  pageSize: 10,
  isSending: false,

  updateRequired: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGS_DATA_FETCH_SUCCESS:
      return {
        ...state,
        total: payload.total,
        comments: payload.actions.slice().reverse(),
        pageSize: payload.page_size,

        updateRequired: false,
      };
    case CHAT_COMMENT_ADD:
      return {
        ...state,
        isSending: true,
      };
    case CHAT_COMMENT_ADD_SUCCESS:
      return {
        ...state,
        total: payload.total,
        comments: payload.actions.slice().reverse(),
        pageSize: payload.page_size,

        isSending: false,
        updateRequired: false,
      };
    case CHAT_UPDATE_REQUIRED:
      return {
        ...state,
        updateRequired: true,
      };
    case CHAT_FAIL:
      return {
        ...state,
        isSending: false,
      };

    default: return state;
  }
};
