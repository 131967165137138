import {
  LOGS_DATA_FETCH,
  LOGS_DATA_FETCH_SUCCESS,
  LOGS_DATA_FETCH_FAIL,
} from '../../constants';

const initialState = {
  actions: [],
  total: null,
  pageSize: null,
  page: null,
  isFetching: false,
  isError: false,
};

const logs = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGS_DATA_FETCH: return {
      ...state,
      isFetching: true,
      isError: false,
    };
    case LOGS_DATA_FETCH_SUCCESS:
      return {
        ...state,
        actions: payload.actions,
        pageSize: payload.page_size,
        total: payload.total,
        page: payload.page,
        isFetching: false,
      };
    case LOGS_DATA_FETCH_FAIL:
      return {
        ...state,
        isError: true,
        isFetching: false,
      };
    default: return state;
  }
};

export default logs;
