import axios from 'axios';

import {
  INVENTORY_UPLOADING_START,
  INVENTORY_UPLOADING_FINISH,
  INVENTORY_UPLOADING_PROGRESS_SET,
  INVENTORY_UPLOADING_PROGRESS_RESET,
  INVENTORY_UPLOADING_TERMINATE,
  INVENTORY_ERROR_OCCURRED,
  INVENTORY_ERROR_REMOVE,

  ERRORS,
  AXIOS_CANCELLED_MESSAGE,
  fileFormatsInventoryIM,
  fileFormatsArchiveIM,
  maxFileSizeIM,
  maxFileSizeImArc,
} from '../constants';
import errorHandler from './requestErrorHandler';

const startUploadingInventory = (source) => ({
  type: INVENTORY_UPLOADING_START,
  payload: source,
});
const finishUploadingInventory = (document) => ({
  type: INVENTORY_UPLOADING_FINISH,
  payload: document,
});

const setUploadingProgress = (percentOfUpload) => ({
  type: INVENTORY_UPLOADING_PROGRESS_SET,
  payload: percentOfUpload,
});

export const resetUploadingProgress = () => ({
  type: INVENTORY_UPLOADING_PROGRESS_RESET,
});

export const terminateUploadingProgress = () => ({
  type: INVENTORY_UPLOADING_TERMINATE,
});

export const setErrorCode = (errorCode) => ({
  type: INVENTORY_ERROR_OCCURRED,
  payload: errorCode,
});

export const removeErrorCode = () => ({
  type: INVENTORY_ERROR_REMOVE,
});

const uploadErrorHandler = (e, dispatch) => {
  if (e.message === AXIOS_CANCELLED_MESSAGE) {
    console.warn(AXIOS_CANCELLED_MESSAGE); // eslint-disable-line no-console
    return;
  }
  errorHandler(e.response, dispatch, setErrorCode, e);
};

const onUploadProgress = (progressEvent, dispatch) => {
  const { loaded, total } = progressEvent;
  dispatch(setUploadingProgress((loaded * 100) / total));
};

// Add progress
/**
 *
 * @param {File} file
 * @param {string|number} userID
 * @param {number} docType
 */
export const uploadDocumentToIM = (inventoryFile, archiveFile, userId, siteId, jobType) => (dispatch) => {
  if (inventoryFile && fileFormatsInventoryIM.every((mimeType) => mimeType !== inventoryFile.type)) {
    dispatch(setErrorCode(ERRORS.ERR_WRONG_FORMAT));
    return;
  }
  if (inventoryFile && inventoryFile.size > maxFileSizeIM) {
    dispatch(setErrorCode(ERRORS.ERR_FILE_IS_TOO_LARGE));
    return;
  }

  if (archiveFile && fileFormatsArchiveIM.every((mimeType) => mimeType !== archiveFile.type)) {
    dispatch(setErrorCode(ERRORS.ERR_WRONG_FORMAT));
    return;
  }
  if (archiveFile && archiveFile.size > maxFileSizeImArc) {
    dispatch(setErrorCode(ERRORS.ERR_SECOND_FILE_IS_TOO_LARGE));
    return;
  }

  const source = axios.CancelToken.source();
  dispatch(startUploadingInventory(source));
  const fd = new FormData(); // eslint-disable-line no-undef
  fd.append('user_id', userId);
  fd.append('site', siteId);
  fd.append('job_type', jobType);
  fd.append('file_xls', inventoryFile || null);
  fd.append('file_archive', archiveFile || null);

  const options = {
    onUploadProgress: (e) => onUploadProgress(e, dispatch),
    cancelToken: source.token,
  };
  axios.post('/backoffice/inventory/import_upload', fd, options).then((response) => {
    dispatch(finishUploadingInventory(response.data));
  }).catch((e) => {
    uploadErrorHandler(e, dispatch);
  });
};
