import React from 'react';
import { createUltimatePagination } from 'react-ultimate-pagination';
import { Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Glyphicon = styled(FontAwesomeIcon)`
  && {
    position: relative;
  }
  ${(props) => `margin-${props.align}: 10px`};
`;

const PaginationItemExtended = styled(Pagination.Item)`
  && {
    pointer-events: ${({ disabled }) => (disabled && 'none') || 'auto'};
  }
`;

/* eslint-disable react/prop-types */
const itemTypeToComponent = {
  PAGE: ({ value, isActive, onClick }) => <Pagination.Item active={isActive} onClick={onClick}>{value}</Pagination.Item>,

  ELLIPSIS: ({ onClick }) => <Pagination.Ellipsis onClick={onClick} />,

  FIRST_PAGE_LINK: ({ onClick }) => <Pagination.First onClick={onClick} />,

  PREVIOUS_PAGE_LINK: ({ onClick, isActive }) => (
    <PaginationItemExtended disabled={isActive} onClick={onClick}>
      <Glyphicon icon="chevron-left" align="right" />
      Previous
    </PaginationItemExtended>
  ),

  NEXT_PAGE_LINK: ({ onClick, isActive }) => (
    <PaginationItemExtended disabled={isActive} onClick={onClick}>
      Next
      <Glyphicon icon="chevron-right" align="left" />
    </PaginationItemExtended>
  ),

  LAST_PAGE_LINK: ({ onClick }) => <Pagination.Last onClick={onClick} />,
};
/* eslint-enable react/prop-types */

const PaginationComponent = createUltimatePagination({
  itemTypeToComponent,
  WrapperComponent: Pagination,
});

PaginationComponent.defaultProps = {
  boundaryPagesRange: 1,
  siblingPagesRange: 2,
  hideFirstAndLastPageLinks: true,
};

export default PaginationComponent;
