import React from 'react';
import {
  Col,
  Form,
  Row,
  Button,
  FormGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UniversalFormControl from '../../FormComponents/UniversalControl';
import PasswordControl from './PasswordControl';
import DeletePopup from './DeletePopup';
import FormTitleSection from '../../FormComponents/TitleSection';
import FormWrapperNormal from '../../FormComponents/Wrapper';
import ConfirmLeaveModal from '../../Modals/ConfirmLeaveModal';
import { TabContainer, StyledLinkButton as LinkButton, DangerLinkButton } from '../../StyledComponents';
import { getCustomerStore, getCompanyCustomer, getUserStore } from '../../../selectors';
import {
  fetchCustomer,
  customerAccountConfirmation,
  saveCustomer,
  saveCustomerPassword,
  sendEmailResetPassword,
  fetchCustomerFail,
  resendEmailConfirmation,
  clearCustomerError,
} from '../../../actions/userManagement/customerActions';
import EmailStatusMark from '../../EmailStatusMark';
import ModalLoader from '../../ModalLoader';
import ConfirmationLastUserModal from '../../Modals/ConfirmationLastUserModal';
import {
  removeCustomer, removeErrorsCompanyCustomer,
} from '../../../actions/companiesManagement/companyCustomerActions';
import ErrorModal from '../../ErrorModal';
import accountSettingsValidators from './AccountSettingsValidators';
import { customerStatus, userType, customerEmailStatus } from '../../../constants/index';
import { color } from '../../../constants';

const FormWrapper = styled(FormWrapperNormal)`
  &&& {
    margin-top: 0;
  }
`;
const Container = styled(FormGroup)`
  text-align: left;

  & Button {
    margin: 10px 35px;
    width: 145px;
  }
`;
const EmailInput = styled(UniversalFormControl)`
  &&& ~ .help-block {
    min-height: 40px;
  }
`;
const OperationTitle = styled.p`
  font-weight: bold;
  padding-left: 10px;
`;
const CopiedButton = styled(LinkButton)`
  border-radius: 10px;
  background: ${color.magicMint};
  :hover {
    background: ${color.magicMint};
  }
`;
const ExternalUserBadge = styled.div`
  color: ${color.darkorange};
  padding-left: 20px;
`;
const ColumnMark = styled(Col)`
  padding-top: 5px;
`;

class AccountSettingsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      editMode: false,
      showConfirmModal: false,
      customer: {
        firstname: '',
        lastname: '',
        signup_company_name: '',
        signup_phone: '',
        signup_mobile: '',
        email: '',
        signup_wechat_id: '',
        subscription: false,
      },
      showPasswordModal: false,
      showDeletePopup: false,
      showLastUserModal: false,
      isCopied: false,
      isChanged: false,
      validationState: {},
    };
    this.isVerificationEmail = false;
    this.bttnSaveClicked = false;
  }

  onSubscriptionChange = (e) => {
    const val = e.target.checked;
    this.setState((prevState) => {
      const customer = { ...prevState.customer };
      customer.subscription = +val;
      return { customer };
    });
  };

  onCustomerChangeHandler = (e) => {
    const { value, name } = e.target;

    this.setState((prevState) => {
      const customer = { ...prevState.customer };
      let { validationState } = prevState;
      customer[name] = value;

      if (name !== 'email' && accountSettingsValidators[name]) {
        validationState = { ...validationState };
        validationState[name] = accountSettingsValidators[name](value);
      }

      return { customer, validationState };
    });
  };

  onChange = (e) => {
    const { isChanged } = this.state;
    if (!isChanged) {
      this.setState({ isChanged: true });
    }
    if (e.target.name === 'subscription') {
      this.onSubscriptionChange(e);
    } else {
      this.onCustomerChangeHandler(e);
    }
  };

  onEmailFocusout = (e) => {
    const { customerData, fetchCustomerFail: fetchCustomerFailA } = this.props;
    this.isVerificationEmail = true;

    const { value, name } = e.target;
    const emailChanged = e.target.value !== customerData.customer.email;
    accountSettingsValidators[name](fetchCustomerFailA, value).then((errObj) => {
      this.setState((prevState) => {
        const customer = { ...prevState.customer };
        const { validationState } = prevState;
        validationState.email = emailChanged ? errObj : null;

        return { customer, validationState };
      });

      this.isVerificationEmail = false;
      if (this.bttnSaveClicked) this.save();
    });
  }

  onChangeEditMode = () => {
    const { customerData: { customer } } = this.props;
    this.setState((prevState) => {
      if (prevState.editMode) {
        return { editMode: false, isChanged: false, validationState: {} };
      }

      return {
        editMode: true,
        customer: {
          firstname: customer.firstname,
          lastname: customer.lastname,
          signup_company_name: customer.signup_company_name,
          signup_phone: customer.signup_phone,
          signup_mobile: customer.signup_mobile,
          signup_wechat_id: customer.signup_wechat_id,
          email: customer.email,
          subscription: customer.subscription,
        },
      };
    });
  };

  onSaveBttnHandler = () => {
    const { isChanged } = this.state;
    this.bttnSaveClicked = true;
    if (!this.isVerificationEmail && isChanged) this.save();
  };

  onShowPasswordModal = () => this.setState({ showPasswordModal: true })

  onCancelPasswordModal = () => this.setState({ showPasswordModal: false })

  onCancelDeletePopup = () => {
    this.onChangeEditMode();
    this.setState({
      showDeletePopup: false,
    });
  };

  onChangePassword = (pwd) => {
    const { customerData, saveCustomerPassword: saveCustomerPasswordA } = this.props;
    saveCustomerPasswordA(customerData.customer.entity_id, pwd);
    this.setState({
      showPasswordModal: false,
    });
  }

  onSendEmail = () => {
    const { customerData, sendEmailResetPassword: sendEmailResetPasswordA } = this.props;
    sendEmailResetPasswordA(customerData.customer.entity_id);
    this.setState({
      showPasswordModal: false,
    });
  }

  onDeleteAccount = (reason) => {
    const {
      customerData: {
        customer,
      },
      removeCustomer: removeCustomerA,
    } = this.props;

    if (customer) {
      removeCustomerA(customer.user_id, customer.entity_id, reason);
    }
    this.onCancelDeletePopup();
  }

  onHideConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  };

  onEditButtonClick = () => {
    const { editMode, isChanged } = this.state;
    if (editMode && isChanged) {
      this.setState({ showConfirmModal: true });
    } else {
      this.onChangeEditMode();
    }
  };

  setValidationObj = (uname) => {
    if (uname && !this.formValidationState[uname]) {
      this.formValidationState[uname] = null;
    }
  };

  getValidationObj = () => Object.keys(accountSettingsValidators).reduce((validationState, fieldName) => {
    const { customer, validationState: validationStateSt } = this.state;
    if (fieldName !== 'email') {
      // eslint-disable-next-line no-param-reassign
      validationState[fieldName] = accountSettingsValidators[fieldName](customer[fieldName]);
      // eslint-disable-next-line no-param-reassign
    } else validationState[fieldName] = validationStateSt.email;
    return validationState;
  }, {});

  save = () => {
    const { customerData, saveCustomer: saveCustomerData } = this.props;
    const { customer } = this.state;
    const newValidationState = this.getValidationObj();

    if (Object.values(newValidationState).some((value) => !!value)) {
      this.setState({ validationState: newValidationState });
    } else {
      const customerChanges = {};
      Object.keys(customer).forEach((item) => {
        if (customer[item] !== customerData.customer[item]) {
          customerChanges[item] = customer[item];
        }
      });
      if (Object.keys(customerChanges).length) {
        saveCustomerData(customerData.customer.entity_id, customerChanges);
      }
      this.onChangeEditMode();
    }
    this.bttnSaveClicked = false;
  }

  validationStateCallback = (obj) => {
    this.formValidationState = { ...this.formValidationState, [obj.name]: obj.state };
    return true;
  };

  deleteAccountHandler = () => {
    this.setState({
      showLastUserModal: false,
      showDeletePopup: true,
    });
  }

  showConfirmatinModal = () => {
    const { isLastUser } = this.props;
    if (isLastUser) {
      this.setState({ showLastUserModal: true });
    } else {
      this.deleteAccountHandler();
    }
  }

  hideConfirmatinModal = () => this.setState({ showLastUserModal: false });

  onResendClick = () => {
    const {
      customerData: {
        customer: { entity_id: entityId },
      },
      resendEmailConfirmation: resendEmailConfirmationA,
    } = this.props;
    resendEmailConfirmationA(entityId);
  }

  onCopyInvitationClick = (e) => {
    e.stopPropagation();
    const { customerData: { customer } } = this.props;
    this.setState({ isCopied: true });
    const textArea = global.document.createElement('textarea');
    textArea.value = customer && customer.invite_link;
    global.document.body.appendChild(textArea);
    textArea.select();

    try {
      global.document.execCommand('copy');
    } catch (err) {
      console.error('Oops, unable to copy'); // eslint-disable-line no-console
    }

    global.document.body.removeChild(textArea);
  }

  onErrorModalHide = () => {
    const { removeErrorsCompanyCustomer: clearCompanyCustomerError, clearCustomerError: clearCustomerErrors } = this.props;
    clearCompanyCustomerError();
    clearCustomerErrors();
  }

  renderCopyButton = (isCopied) => (
    isCopied
      ? (
        <CopiedButton variant="link" onClick={this.onCopyInvitationClick}>
          <FontAwesomeIcon icon="check" />
          &nbsp;Copied to Clipboard
        </CopiedButton>
      ) : (
        <LinkButton variant="link" onClick={this.onCopyInvitationClick}>
          <FontAwesomeIcon icon="share" />
          &nbsp;Copy Invitation Link
        </LinkButton>
      )
  )

  renderConfirmButton = () => {
    const { customerData: { customer, isAccountConfirmed }, customerAccountConfirmation: accountConfirmation } = this.props;
    return isAccountConfirmed
      ? (
        <CopiedButton variant="link">
          <FontAwesomeIcon icon="check" />
          &nbsp;Account Confirmed
        </CopiedButton>
      ) : (
        <LinkButton variant="link" onClick={() => accountConfirmation(customer && customer.entity_id)}>
          <FontAwesomeIcon icon="user-check" />
          &nbsp;Confirm Account
        </LinkButton>
      );
  }

  renderResendButton = () => {
    const { customerData: { isEmailResend } } = this.props;
    return isEmailResend
      ? (
        <CopiedButton variant="link">
          <FontAwesomeIcon icon="check" />
          &nbsp;Confirmation sent
        </CopiedButton>
      ) : (
        <LinkButton variant="link" onClick={this.onResendClick}>
          <FontAwesomeIcon icon="retweet" />
          &nbsp;Resend Confirmation
        </LinkButton>
      );
  }

  render() {
    const {
      customerData: {
        customer, isSaving, isAccountConfirmed, errorCode,
      },
      userId,
      hasInventory,
      hasPro,
      isFetching,
      isRemoving,
      removingErrorCode,
      customerData,
      isExternalUser,
    } = this.props;
    const {
      showConfirmModal,
      showPasswordModal,
      showLastUserModal,
      showDeletePopup,
      validationState,
      customer: customerSt,
      editMode,
      isChanged,
      isCopied,
    } = this.state;

    const {
      firstname,
      lastname,
      signup_company_name: signupCompany,
      signup_phone: signupPhone,
      signup_mobile: mobile,
      email,
      signup_wechat_id: wechatId,
    } = editMode || isSaving ? customerSt : customerData.customer || {};
    const deleteBttnDisabled = customer && customer.customer_status === customerStatus.DELETED.id;
    const isUnconfirmedCustomer = customer && customer.email_status_indication === customerEmailStatus.NOT_VERIFIED;
    const saveBttnDisabled = !isChanged;
    const emailStatusId = (isAccountConfirmed && customerEmailStatus.VERIFIED)
      || (customer && customer.email_status_indication);

    return (
      <FormWrapper>
        <ModalLoader show={isSaving || isRemoving || isFetching} />
        <FormTitleSection title="Account Settings" EditButtonClick={this.onEditButtonClick} showEditButton={!editMode} />
        <ConfirmLeaveModal show={showConfirmModal} onLeave={this.onChangeEditMode} onCancel={this.onHideConfirmModal} />

        <TabContainer>
          <Form>
            <Row>
              <Col sm={8}>
                <Row>
                  <Col sm={8}>
                    <UniversalFormControl
                      labelText="First name"
                      value={firstname}
                      name="firstname"
                      onChange={this.onChange}
                      disabled={!editMode}
                      {...validationState.firstname}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <UniversalFormControl
                      labelText="Last name"
                      value={lastname}
                      name="lastname"
                      onChange={this.onChange}
                      disabled={!editMode}
                      {...validationState.lastname}
                    />
                  </Col>
                </Row>
                <Row>
                  <OverlayTrigger placement="top" delayShow={300} overlay={tooltip('Company name entered by the user upon signup.')}>
                    <Col sm={8}>
                      <UniversalFormControl
                        labelText="Company"
                        value={signupCompany || ''}
                        name="signup_company_name"
                        onChange={this.onChange}
                        disabled={!editMode}
                        {...validationState.company}
                      />
                    </Col>
                  </OverlayTrigger>
                  {isExternalUser && <ColumnMark sm={4}><ExternalUserMark /></ColumnMark>}
                </Row>
                <Row>
                  <Col sm={8}>
                    <UniversalFormControl
                      labelText="Phone"
                      value={signupPhone || ''}
                      name="signup_phone"
                      onChange={this.onChange}
                      disabled={!editMode}
                      {...validationState.phone}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <UniversalFormControl
                      labelText="Mobile"
                      value={mobile || ''}
                      name="signup_mobile"
                      onChange={this.onChange}
                      disabled={!editMode}
                      {...validationState.mobile}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <UniversalFormControl
                      labelText="Wechat id"
                      value={wechatId || ''}
                      name="signup_wechat_id"
                      onChange={this.onChange}
                      disabled={!editMode}
                      {...validationState.wechat}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <EmailInput
                      labelText="Email"
                      value={email}
                      name="email"
                      onChange={this.onChange}
                      onBlur={this.onEmailFocusout}
                      disabled={!editMode}
                      {...validationState.email}
                    />
                  </Col>
                  <ColumnMark sm={4}>
                    <EmailStatusMark
                      statusId={emailStatusId}
                      customerEmailStatusConstants={customerEmailStatus}
                      withDescription
                    />
                  </ColumnMark>
                </Row>
                <Row>
                  <Col sm={8}>
                    <UniversalFormControl
                      labelText="Password"
                      placeholder="Password"
                      value="*********"
                      disabled
                    />
                  </Col>
                </Row>

                {editMode
                && (
                  <Container>
                    <Button variant="primary" onClick={this.onSaveBttnHandler} disabled={saveBttnDisabled}>SAVE</Button>
                    <Button variant="secondary" onClick={this.onChangeEditMode}>CANCEL</Button>
                  </Container>
                )}
                <PasswordControl
                  onReset={this.onChangePassword}
                  onSend={this.onSendEmail}
                  onHide={this.onCancelPasswordModal}
                  show={showPasswordModal}
                />
                <DeletePopup
                  onSubmit={this.onDeleteAccount}
                  onHide={this.onCancelDeletePopup}
                  show={showDeletePopup}
                />
              </Col>

              <Col sm={{ span: 3, offset: 1 }}>
                <OperationTitle>Account Management:</OperationTitle>
                <LinkButton variant="link" onClick={this.onShowPasswordModal}>
                  <FontAwesomeIcon icon="pencil-alt" />
                  &nbsp;Change Password
                </LinkButton>
                {isUnconfirmedCustomer && this.renderConfirmButton()}
                {isUnconfirmedCustomer && !isAccountConfirmed && this.renderResendButton()}
                {customer && customer.invite_link && this.renderCopyButton(isCopied)}
                <DangerLinkButton variant="link" disabled={deleteBttnDisabled} onClick={this.showConfirmatinModal}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;Delete Account
                </DangerLinkButton>
              </Col>
            </Row>
          </Form>
        </TabContainer>

        <ConfirmationLastUserModal
          show={showLastUserModal}
          confirm={this.deleteAccountHandler}
          onHide={this.hideConfirmatinModal}
          hasInventory={hasInventory}
          hasPro={hasPro}
          userId={userId}
        />
        <ErrorModal error={removingErrorCode || errorCode} onHide={this.onErrorModalHide} />
      </FormWrapper>
    );
  }
}

const ExternalUserMark = () => (
  <ExternalUserBadge>
    <FontAwesomeIcon icon="exclamation-triangle" />
    &nbsp;
    External User
  </ExternalUserBadge>
);

AccountSettingsForm.propTypes = {
  userId: PropTypes.string,
  customerData: PropTypes.shape({
    customer: PropTypes.shape({
      user_id: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      entity_id: PropTypes.string,
      invite_link: PropTypes.string,
      email_status_indication: PropTypes.number,
      customer_status: PropTypes.number,
      signup_company_name: PropTypes.string,
      signup_phone: PropTypes.string,
      signup_mobile: PropTypes.string,
      signup_wechat_id: PropTypes.string,
      subscription: PropTypes.bool,
    }),
    isSaving: PropTypes.bool,
    isAccountConfirmed: PropTypes.bool,
    isEmailResend: PropTypes.bool,
    errorCode: PropTypes.number,
  }),
  hasInventory: PropTypes.bool,
  hasPro: PropTypes.bool,
  isExternalUser: PropTypes.bool,
  isLastUser: PropTypes.bool,
  isFetching: PropTypes.bool,
  isRemoving: PropTypes.bool,
  removingErrorCode: PropTypes.bool,
  saveCustomer: PropTypes.func.isRequired,
  removeCustomer: PropTypes.func.isRequired,
  saveCustomerPassword: PropTypes.func.isRequired,
  removeErrorsCompanyCustomer: PropTypes.func.isRequired,
  sendEmailResetPassword: PropTypes.func.isRequired,
  fetchCustomerFail: PropTypes.func.isRequired,
  resendEmailConfirmation: PropTypes.func.isRequired,
  customerAccountConfirmation: PropTypes.func.isRequired,
  clearCustomerError: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { user } = getUserStore(state);
  const companyCustomerData = getCompanyCustomer(state);
  const isLastUser = companyCustomerData && companyCustomerData.customers
    && companyCustomerData.customers.filter((elem) => elem.customer_status === customerStatus.ACTIVE.id).length === 1
    && user && (user.user_type === userType.COMPANY.id);
  return {
    customerData: getCustomerStore(state),
    userId: user && user.user_id,
    hasInventory: user && user.has_published_inventory,
    hasPro: user && user.is_pro,
    isLastUser,
    isFetching: companyCustomerData.isFetching,
    isRemoving: companyCustomerData.isRemoving,
    removingErrorCode: companyCustomerData.removingErrorCode,
    isExternalUser: user && user.is_external,
  };
}

const actions = {
  customerAccountConfirmation,
  clearCustomerError,
  fetchCustomer,
  saveCustomer,
  saveCustomerPassword,
  removeCustomer,
  removeErrorsCompanyCustomer,
  sendEmailResetPassword,
  resendEmailConfirmation,
  fetchCustomerFail,
};

const tooltip = (text) => (
  <Tooltip id="tooltip">
    {text}
  </Tooltip>
);

export default connect(mapStateToProps, actions)(AccountSettingsForm);
