import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledLinkButton as LinkButton } from '../../StyledComponents';
import { WeightListTitle } from '../StyleComponents';

const ListName = styled.span`
  font-size: 16px;
`;

const DetachForm = ({ data, onDetachHandler, disabled }) => {
  if (!data || data.length === 0) {
    return <p>There is no attached lists.</p>;
  }

  const Tier3List = data && data.length > 0 && data.map((v, i) => (
    <Row key={v.value}>
      <Col sm={1}>
        <ListName>{`${i + 1}`}</ListName>
      </Col>
      <Col sm={4}>
        <ListName>{v.label}</ListName>
      </Col>
      <Col sm={2}>
        <LinkButton variant="link" onClick={() => onDetachHandler(v.value)} disabled={disabled}>
          <FontAwesomeIcon icon="unlink" />
          &nbsp;
          Detach
        </LinkButton>
      </Col>
    </Row>
  ));

  return (
    <>
      <Row>
        <Col sm={1}><WeightListTitle>#</WeightListTitle></Col>
        <Col sm={4}><WeightListTitle>List name</WeightListTitle></Col>
      </Row>
      {Tier3List}
    </>
  );
};

export default DetachForm;

DetachForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onDetachHandler: PropTypes.func,
  disabled: PropTypes.bool,
};
