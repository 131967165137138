import React from 'react';
import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';

import CompanyDetailsPage from '../CompanyDetailsPage';
import UserSettingsPage from '../UserSettingsPage';
import WhiteBlackListPage from '../UserSettingsPage/WhiteBlackListPage';
import CustomListsPage from '../SellersLists/CustomListsPage';
import BuyersListsPage from '../BuyersListsPage';
import PartsWatchlistPage from '../PartsWatchlist';
import WeightManagementPage from '../UserSettingsWeights';
import CommercialAgreementPage from '../../UserCompanyManagement/CommercialAgreementPage';
import CompanyInventoriesPage from '../CompanyInventoriesPage';
import CompanyCustomerManagementPage from '../CompanyCustomerManagementPage';
import NotFound from '../../NotFoundPage';
import ComingSoonPage from '../../ComingSoonPage';
import PNListPage from '../PNListPage';
import UploadDemandPage from '../UploadDemandPage';
import VendorCategorizationPage from '../VendorCategorizationPage';
import PaymentProviders from '../PaymentProviders';
import Spec2kPage from '../Spec2k';
import ApiPermissionsPage from '../Spec2k/ApiPermissionsPage';
import CodesManagementPage from '../Spec2k/CodesManagementPage';
import OrderLimitsPage from '../OrderLimitsPage';
import ProManagementPage from '../../UserCompanyManagement/ProPage';
import ProFeaturesPage from '../../UserCompanyManagement/ProPage/ProFeaturesPage';

export default () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Redirect
        exact
        from={match.path}
        to={match.url[match.url.length - 1] === '/' ? `${match.url}companyDetails` : `${match.url}/companyDetails`}
      />
      <Route path={`${match.path}/companyDetails`}><CompanyDetailsPage /></Route>
      <Route path={`${match.path}/userSettings`}><UserSettingsPage /></Route>
      <Route path={`${match.path}/proManagement`}><ProManagementPage /></Route>
      <Route path={`${match.path}/proFeatures`}><ProFeaturesPage /></Route>
      <Route path={`${match.path}/whiteBlackList`}><WhiteBlackListPage /></Route>
      <Route path={`${match.path}/weightManagement`}><WeightManagementPage /></Route>
      <Route path={`${match.path}/customLists`}><CustomListsPage /></Route>
      <Route path={`${match.path}/buyersLists`}><BuyersListsPage /></Route>
      <Route path={`${match.path}/partsWatchlist`}><PartsWatchlistPage /></Route>
      <Route path={`${match.path}/userDetails`}><CompanyCustomerManagementPage /></Route>
      <Route path={`${match.path}/commercialAgreement`}><CommercialAgreementPage /></Route>
      <Route path={`${match.path}/inventory`}><CompanyInventoriesPage /></Route>
      <Route path={`${match.path}/blocking`}><ComingSoonPage /></Route>
      <Route path={`${match.path}/pnList`}><PNListPage /></Route>
      <Route path={`${match.path}/demandList`}><UploadDemandPage /></Route>
      <Route path={`${match.path}/categorization`}><VendorCategorizationPage /></Route>
      <Route path={`${match.path}/paymentProviders`}><PaymentProviders /></Route>
      <Route path={`${match.path}/apiRequests`}><ApiPermissionsPage /></Route>
      <Route path={`${match.path}/integration`}><Spec2kPage /></Route>
      <Route path={`${match.path}/codesManagement`}><CodesManagementPage /></Route>
      <Route path={`${match.path}/orderLimits`}><OrderLimitsPage /></Route>
      <Route><NotFound /></Route>
    </Switch>
  );
};
