import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RadioStyled from '../../FormComponents/RadioStyled';

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
`;

export default function RadioGroup(props) {
  const {
    radioList,
    checkedValue,
    name,
    ...other
  } = props;
  const list = radioList.map(({ value, labelText }) => (
    <RadioStyled
      label={labelText}
      key={value}
      value={value}
      checked={value === checkedValue}
      name={name}
      {...other}
    />
  ));
  return (
    <FlexContainer>
      {list}
    </FlexContainer>
  );
}

const valuePT = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
RadioGroup.propTypes = {
  radioList: PropTypes.arrayOf(PropTypes.shape({
    value: valuePT,
    label: PropTypes.string,
  })),
  checkedValue: valuePT,
  name: PropTypes.string,
};
