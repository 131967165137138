import {
  PAYMENT_PROVIDERS_GET,
  PAYMENT_PROVIDERS_GET_SUCCESS,
  PAYMENT_PROVIDERS_SAVE,
  PAYMENT_PROVIDERS_SAVE_SUCCESS,
  PAYMENT_PROVIDERS_FAIL,
} from '../../constants';

const initialState = {
  providers: null,
  isFetching: false,
  isProcessing: false,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case PAYMENT_PROVIDERS_GET:
      return {
        ...state,
        isFetching: true,
      };
    case PAYMENT_PROVIDERS_GET_SUCCESS:
      return {
        ...state,
        providers: payload.items,
        isFetching: false,
      };
    case PAYMENT_PROVIDERS_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case PAYMENT_PROVIDERS_SAVE_SUCCESS:
      return {
        ...state,
        providers: payload.items,
        isProcessing: false,
      };
    case PAYMENT_PROVIDERS_FAIL:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
      };
    default: return state;
  }
};
