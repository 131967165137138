import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// import { color } from '../../constants';

const NoContentRr = styled.tr`
  && td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: auto;
    vertical-align: middle;
    text-align: center;
  }
`;

const NoDataRow = ({ children, ...other }) => (
  <tbody>
    <NoContentRr>
      <td {...other}>{children}</td>
    </NoContentRr>
  </tbody>
);

NoDataRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default NoDataRow;
