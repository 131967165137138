import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import dateFormat from '../../../../utils/dateFormat';
import { color } from '../../../../constants';
import { taskStatus } from '../../../../constants/index';

const BaseTaskContainer = styled.div`
  padding: 6px 7px 0px 15px;
  border-bottom: 1px solid ${color.altoGrey};
  border-top: 1px solid ${color.altoGrey};
  width:100%;
  height: 110px;
  font-weight: 400;
  color: ${color.grey};
  font-size: 16px;
  position: relative;
  cursor: pointer;
  &:hover {
    border: 1px solid ${color.inputBorder};;
  }
  background-color: ${(props) => (props.isActive ? `${color.lightGrey}` : '')};
`;
const NotReadTaskContainer = styled(BaseTaskContainer)`
  border-left: solid 8px ${color.birch};
  font-weight: 600;
  &:hover {
    border: 1px solid ${color.inputBorder};
    border-left: solid 8px ${color.birch};
  }
`;
const Date = styled.p`
  display: flex;
  font-size: 13px;
  justify-content: flex-end;
  margin-bottom: 0;
`;
const OrderID = styled.p`
  margin-bottom:5px;
  color: ${color.blue};
`;
const Status = styled.p`
  margin-bottom:5px;
`;
const SellerName = styled.p`
  width: ${(props) => (props.hasBanner ? 'calc(100% - 90px)' : '100%')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  color: ${color.grey};
  margin-bottom:5px;
`;
const ClosedBanner = styled.div`
  height:25px;
  width: 90px;
  position:absolute;
  right:0;
  top: 10px;
  padding:1px;
  background-color: ${color.grey};

  span {
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
    color: ${color.white};
    display: block;
  }
`;
const InProgressBanner = styled(ClosedBanner)`
  background-color: ${color.goldenTainoi};
`;
const OnHoldBanner = styled(ClosedBanner)`
background-color: ${color.googleBlue};`;

const Task = ({
  taskId,
  orderId,
  sellerName,
  date,
  message,
  status,
  isViewed,
  isChosen,
  onTaskClick,
}) => {
  const TaskInfo = (Wrapper, Banner, isActive) => (
    <li>
      <Wrapper isActive={isActive} onClick={() => onTaskClick(taskId)}>
        <SellerName hasBanner={!!Banner}>
          {sellerName}
        </SellerName>
        <OrderID>{orderId}</OrderID>
        <Status>{message}</Status>
        <Date>{dateFormat(date)}</Date>
        {Banner && <Banner />}
      </Wrapper>
    </li>
  );
  const Closed = () => <ClosedBanner><span>Closed</span></ClosedBanner>;
  const InProgress = () => <InProgressBanner><span>In Progress</span></InProgressBanner>;
  const OnHold = () => <OnHoldBanner><span>On Hold</span></OnHoldBanner>;

  const TaskContainer = isViewed ? BaseTaskContainer : NotReadTaskContainer;
  if (status === taskStatus.OPEN) return TaskInfo(TaskContainer, null, isChosen);
  if (status === taskStatus.CLOSED) return TaskInfo(TaskContainer, Closed, isChosen);
  if (status === taskStatus.IN_PROGRESS) return TaskInfo(TaskContainer, InProgress, isChosen);
  if (status === taskStatus.ON_HOLD) return TaskInfo(TaskContainer, OnHold, isChosen);
  return TaskInfo(TaskContainer);
};

Task.propTypes = {
  orderId: PropTypes.string,
  sellerName: PropTypes.string,
  date: PropTypes.string,
  message: PropTypes.string,
  status: PropTypes.string,
  isViewed: PropTypes.bool,
  isChosen: PropTypes.bool,
  onTaskClick: PropTypes.func.isRequired,
};

export default Task;
