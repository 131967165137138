import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,

  CHECK_LOGGED_IN_START,
  CHECK_LOGGED_IN_VERIFIED,
  CHECK_LOGGED_IN_FAILED,

  LOGOUT_REQUEST,
  AUTHORIZATION_FAIL,
} from '../constants';
import boRole from '../constants/backofficeRoles';

const initialState = {
  inProgress: false,
  path: null, // contains path where the authentication failed
  error: null,
  checkingTokenInProgress: false,
  tokenIsChecked: false,
  token: localStorage.getItem('token') || '', // eslint-disable-line
  adminCustomerId: localStorage.getItem('adminCustomerId') || '', // eslint-disable-line
  role: boRole.ADMIN,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        inProgress: true,
        error: null,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        inProgress: false,
        error: action.payload,
        tokenIsChecked: false,
        token: '',
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        inProgress: false,
        tokenIsChecked: true,
        path: null,
        token: action.payload.token,
        adminCustomerId: action.payload.customerId,
      };

    case LOGOUT_REQUEST:
      return {
        ...state,
        token: '',
        tokenIsChecked: false,
      };

    case AUTHORIZATION_FAIL:
      if (!state.token) return state;
      return {
        ...state,
        path: action.payload,
        token: '',
        tokenIsChecked: false,
      };

    case CHECK_LOGGED_IN_START:
      return {
        ...state,
        tokenIsChecked: false,
        checkingTokenInProgress: true,
      };

    case CHECK_LOGGED_IN_VERIFIED:
      return {
        ...state,
        tokenIsChecked: true,
        checkingTokenInProgress: false,
      };

    case CHECK_LOGGED_IN_FAILED:
      return {
        ...state,
        tokenIsChecked: false,
        checkingTokenInProgress: false,
        token: '',
      };

    default: return state;
  }
}
