import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseFormGroup from '../FormComponents/BaseFormGroup';

const GOOGLE_PLACES_SUGGEST_ID = 'google_places_suggest';

class GooglePlacesAutoSuggest extends Component {
  componentDidMount() {
    const options = {
      types: ['(cities)'],
    };
    /* global google document */
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(GOOGLE_PLACES_SUGGEST_ID),
      options,
    );
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  onBlur = (e) => {
    const { onBlurAutoSuggest } = this.props;
    const { value: inputValue } = e.target;
    if (e.relatedTarget) {
      onBlurAutoSuggest(inputValue);
    }
  }

  handlePlaceChanged = () => {
    const { onSelectGooglePlace } = this.props;
    const selectedPlace = this.autocomplete.getPlace();
    if (selectedPlace) {
      onSelectGooglePlace(selectedPlace);
    }
  }

  render() {
    const { validationState } = this.props;
    return (
      <div>
        <BaseFormGroup
          id={GOOGLE_PLACES_SUGGEST_ID}
          name="place"
          onBlur={this.onBlur}
          {...validationState.place}
        />
      </div>
    );
  }
}
GooglePlacesAutoSuggest.propTypes = {
  onSelectGooglePlace: PropTypes.func,
  onBlurAutoSuggest: PropTypes.func,
  validationState: PropTypes.shape({
    place: PropTypes.string,
  }),
};

export default GooglePlacesAutoSuggest;
