import axios from 'axios';

import {
  EMAIL_TEMPLATES_FETCH,
  EMAIL_TEMPLATES_IS_FETCHED,
  ERROR,
} from '../../constants';

const startFetchingExistingEmailTemplates = () => ({
  type: EMAIL_TEMPLATES_FETCH,
});

const finishFetchingExistingEmailTemplates = (templatesList) => ({
  type: EMAIL_TEMPLATES_IS_FETCHED,
  payload: templatesList,
});

const handleError = () => ({
  type: ERROR,
});
// eslint-disable-next-line
export const fetchingExistingEmailTemplates = () => async (dispatch) => {
  dispatch(startFetchingExistingEmailTemplates());
  try {
    const response = await axios('rest/default/V1/eplane/backoffice/email/templates');
    if (response && response.data) {
      const templatesArr = response.data;
      dispatch(finishFetchingExistingEmailTemplates(templatesArr));
    }
  } catch (e) {
    dispatch(handleError());
  }
};
