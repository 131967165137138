import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TableStyles from '../../Table/TableStyles';
import Table from '../../Table/TableWithRowSelection';
import header from '../../Table/HeaderWithSort';
import CellWithTitle from '../../Table/Cell';
import CellEditable from '../../Table/CellEditable';

import TemplateModal from './TemplateModal';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { color } from '../../../constants';
import { fetchingExistingEmailTemplates } from '../../../actions/dev/emailManagementActions';
import { getEmailManagementStore } from '../../../selectors';
import InternalErrorContainer from '../../InternalErrorContainer';

const TableContainer = styled(TableStyles)`
  margin-bottom: 10px;
  margin-top: 10px;
  tr th:first-child {
    background-color: ${color.white};
  }
`;

function jsonValidator(value) {
  if (value) {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
  }
  return true;
}

export default function DevEmailsTable({ disabled, onClickSend }) {
  const dispatch = useDispatch();

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const [modals, setModals] = useState({
    rowInsertModal: false,
    deleteConfirmationModal: false,
  });
  const [selected, setSelected] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState({
    // eslint-disable-next-line no-undef
    templates: localStorage.getItem('templates') ? JSON.parse(localStorage.getItem('templates')) : [],
    to: 'qa@eplane.com',
  });

  const { templatesList, isError } = useSelector(getEmailManagementStore);

  useEffect(() => {
    dispatch(fetchingExistingEmailTemplates());
  }, [dispatch]);

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  useEffect(() => {
    setSkipPageReset(false);
  }, [emailTemplates.templates]);

  const onSendHandler = () => {
    const { templates, to } = emailTemplates;

    const dataForSend = selected.map((selectTemplate) => {
      const template = templates.find((tmp) => tmp.id === selectTemplate);
      return {
        to,
        template: selectTemplate,
        mailVariablesJson: template.jsonData || '{}',
      };
    });

    onClickSend(dataForSend);
  };

  const onRowSelect = useCallback((selectedRows) => {
    setSelected(selectedRows.map((v) => v.id));
  }, []);

  const editCellValidation = useCallback((id, value) => {
    if (!jsonValidator(value)) {
      // eslint-disable-next-line no-undef, no-alert
      alert('Invalid json data');
      return false;
    }
    return true;
  }, []);

  const onAfterInsertRow = (row) => {
    if (!jsonValidator(row.jsonData)) {
      setModals((prev) => ({ ...prev, rowInsertModal: false }));
      // eslint-disable-next-line no-undef, no-alert
      alert('Invalid json data');
      return;
    }
    const newTemplate = {};
    Object.keys(row).forEach((prop) => {
      newTemplate[prop] = row[prop];
    });
    setEmailTemplates((prevState) => ({ ...prevState, templates: [...emailTemplates.templates, newTemplate] }));
    localStorage.setItem('templates', JSON.stringify([...emailTemplates.templates, newTemplate]));// eslint-disable-line
    setModals((prev) => ({ ...prev, rowInsertModal: false }));
  };

  const onAfterDeleteRow = () => {
    const { templates } = emailTemplates;
    const newTemplates = templates.filter((it) => selected.every((key) => key !== it.id));

    setEmailTemplates((prevState) => ({ ...prevState, templates: newTemplates }));
    localStorage.setItem('templates', JSON.stringify(newTemplates));// eslint-disable-line
    setModals((prev) => ({ ...prev, deleteConfirmationModal: false }));
  };

  localStorage.setItem('templates', JSON.stringify(emailTemplates.templates));// eslint-disable-line
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateTableData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setEmailTemplates((old) => {
      const newTemplates = {
        ...old,
        templates: old.templates.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old.templates[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        }),
      };
      localStorage.setItem('templates', JSON.stringify(newTemplates)); // eslint-disable-line

      return newTemplates;
    });
  };

  const { templates } = emailTemplates;

  const columns = useMemo(
    () => [
      {
        Header: () => header('Template'),
        accessor: 'id',
        Cell: CellWithTitle,
      }, {
        Header: () => header('jsonData'),
        accessor: 'jsonData',
        Cell: CellEditable,
      },
    ], [],
  );

  const noUsedTemplates = useMemo(
    () => (
      templatesList.filter((v) => templates.every((t) => t.id !== v))
    ), [templates, templatesList],
  );

  if (isError) {
    return (
      <InternalErrorContainer>
        Oops, something went wrong...
        <br />
        Please, try to&nbsp;
        <a href=".">refresh</a>
        &nbsp;this page.
      </InternalErrorContainer>
    );
  }

  return (
    <div>
      <TableContainer>
        <p>Email&apos;s Templates:</p>

        <ButtonGroup aria-label="Basic example">
          <Button variant="info" size="sm" onClick={() => setModals((prev) => ({ ...prev, rowInsertModal: true }))}>
            <FontAwesomeIcon icon="plus" color="white" />
            &nbsp;
            New
          </Button>
          <Button
            variant="warning"
            size="sm"
            onClick={() => setModals((prev) => ({ ...prev, deleteConfirmationModal: true }))}
            disabled={selected.length === 0}
          >
            <FontAwesomeIcon icon="trash-alt" color="black" />
            &nbsp;
            Delete
          </Button>
        </ButtonGroup>

        <TableContainer>
          <Table
            columns={columns}
            data={templates}
            onSelect={onRowSelect}
            updateTableData={updateTableData}
            editCellValidation={editCellValidation}
            skipPageReset={skipPageReset}
          />
        </TableContainer>
      </TableContainer>

      <br />
      <ButtonToolbar>
        <Button size="lg" variant="primary" onClick={onSendHandler} disabled={disabled || !selected || selected.length === 0}>
          SEND
        </Button>
      </ButtonToolbar>

      <TemplateModal
        show={modals.rowInsertModal}
        templates={noUsedTemplates}
        jsonValidator={jsonValidator}
        onAddClick={onAfterInsertRow}
        onHide={() => setModals((prev) => ({ ...prev, rowInsertModal: false }))}
      />
      <ConfirmationModal
        show={modals.deleteConfirmationModal}
        confirm={onAfterDeleteRow}
        onHide={() => setModals((prev) => ({ ...prev, deleteConfirmationModal: false }))}
      >
        Are you sure you want to delete?
      </ConfirmationModal>
    </div>
  );
}

DevEmailsTable.propTypes = {
  onClickSend: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
