import React from 'react';
import styled from 'styled-components';

const LoaderAnimation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, rgba(238, 238, 238, 0.7) 38%, rgba(221, 221, 221, 0.7) 48%, rgba(238, 238, 238, 0.7) 63%);
  height: 100%;
  width: 100%;
  background-size: 400% 400%;
  text-align: center;
  z-index: 100;

  @keyframes Gradient {
    0% {
      background-position: 0% 0%
    }
    50% {
      background-position: 100% 0%
    }
    100% {
      background-position: 0% 0%
    }
  }

  @-webkit-keyframes Gradient {
    0% {
      background-position: 0% 0%
    }
    50% {
      background-position: 100% 0%
    }
    100% {
      background-position: 0% 0%
    }
  }
  
  @-moz-keyframes Gradient {
    0% {
      background-position: 0% 0%
    }
    50% {
      background-position: 100% 0%
    }
    100% {
      background-position: 0% 0%
    }
  }

  animation: Gradient 3s ease infinite;
  animation-fill-mode: forwards;
`;

export default () => (
  <LoaderAnimation>
    <div className="lds-hourglass" />
  </LoaderAnimation>
);
