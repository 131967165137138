import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateTableData, // This is a custom function that we supplied to our table instance
  editCellValidation,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => setValue(e.target.value);

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    if (!editCellValidation(id, value)) {
      setValue(initialValue);
      return;
    }

    updateTableData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Form.Control
      plaintext
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      title={value}
    />
  );
};

EditableCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    index: PropTypes.string,
  }),
  column: PropTypes.shape({
    id: PropTypes.string,
  }),
  editCellValidation: PropTypes.func,
  updateTableData: PropTypes.func.isRequired,
};

export default EditableCell;
