import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import * as consts from '../../constants';

const FooterWrapper = styled(Row)`
  && {
    margin-left: 0;
    margin-right: 0;
    height: 32px;
    margin-top: -32px;
  }
`;

const FooterContainer = styled(Col)`
  border-top: 1px solid ${consts.color.borderLightGrey};
  padding: 5px 0 0;
  color: ${consts.color.silverGrey};
`;

const Footer = () => (
  <FooterWrapper>
    <FooterContainer sm={{ span: 10, offset: 1 }}>
      &copy; ePlane Inc. All rights reserved.
    </FooterContainer>
  </FooterWrapper>
);

export default Footer;
