import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';

import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import { GroupContainer } from '../StyledComponents';

const NotificationsGroups = ({ state, onChange }) => (
  <FormGroup>
    <b>Send an email notification as well to:</b>
    <GroupContainer>
      <CheckboxStyled
        id="sellerNotification"
        label="The seller"
        checked={state.sellerNotification.checked}
        name="sellerNotification"
        disabled={state.sellerNotification.disabled}
        onChange={onChange}
        readOnly={!onChange}
      />
      <CheckboxStyled
        id="buyerNotification"
        label="The buyer"
        checked={state.buyerNotification.checked}
        name="buyerNotification"
        disabled={state.buyerNotification.disabled}
        readOnly={!onChange}
        onChange={onChange}
      />
    </GroupContainer>
  </FormGroup>
);

NotificationsGroups.propTypes = {
  state: PropTypes.shape({
    sellerNotification: PropTypes.shape({
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
    buyerNotification: PropTypes.shape({
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  }),
  onChange: PropTypes.func,
};

export default NotificationsGroups;
