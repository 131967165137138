/*
 * LoginPage
 */
import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { GoogleLogin } from '@react-oauth/google';
import styled from 'styled-components';
import { Form, Col, Row } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';
import { pushRedirect } from '../../actions/redirectActions';
import { login } from '../../sagas/loginFlow';

import NormalH2 from '../../components/H2';
import { font, color, PUBLIC_URL } from '../../constants';

const Main = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;
const LoginForm = styled(Form)`
  background-color: ${color.lightGrey};
  border-radius: 5px;
  padding: 20px 20px 10px;
  width: 500px;
`;
const TitleForm = styled(NormalH2)`
  font-family: ${font.bold};
  font-size: 2em;
  text-align: center;
`;
const CenteredCol = styled(Col)`
  &&& {
    float: none;
    text-align: center;
    display: flex;
    justify-content: center;
  }
`;
const ErrorBlock = styled(Form.Text)`
  && {
    margin-bottom: 0;
    color: ${color.red};
  }
`;
const ErrorAuth = styled(ErrorBlock)`
  line-height: 2em;
`;

export default function LoginPage() {
  const dispatch = useDispatch();

  const { token, error } = useSelector((state) => state.authorization);
  const [errorDetails, setErrorDetails] = useState(null);

  useEffect(() => {
    if (token) {
      dispatch(pushRedirect(`${PUBLIC_URL}/companies`));
    }
  }, [token, dispatch]);

  const onLoginViaGoogle = (jwt) => dispatch(login(jwt));

  return (
    <Main>
      <Helmet title="Login Page" meta={[{ name: 'description', content: 'Login Page' }]} />
      <LoginForm validated={error || errorDetails} horizontal>
        <Form.Group as={Row}>
          <Col sm={12}>
            <TitleForm>Back Office Login</TitleForm>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <CenteredCol sm={12}>
            {/* placeholder for reCaptcha in future */}
            { error && <ErrorAuth muted>{error}</ErrorAuth>}
            { errorDetails && <ErrorAuth muted>{errorDetails}</ErrorAuth>}
          </CenteredCol>
        </Form.Group>

        <Form.Group as={Row}>
          <CenteredCol sm={12}>
            <GoogleLogin
              onSuccess={(response) => {
                onLoginViaGoogle({ token: response.credential });
              }}
              onError={({ err, details }) => {
                setErrorDetails(details);
                console.error('SIGN_IN_ERROR: ', err); // eslint-disable-line no-console
              }}
              auto_select
              width="220px"
              useOneTap
            />
          </CenteredCol>
        </Form.Group>
      </LoginForm>
    </Main>
  );
}
