import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Collapse,
  Button,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
} from 'react-bootstrap';

import createRegExpForString from '../../../utils/createRegExpForString';
import UniversalFormControl from '../../FormComponents/UniversalControl';
import AutosuggestControl from '../../FormComponents/AutosuggestControl';
import { companyWeightValidator } from './validators';

const ButtonContainer = styled.div`
  align-self: flex-start;
  padding-right: 10px;
`;
const SuggestionContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledCol = styled(Col)`
  .form-group {
    margin-right: 0;
    margin-left: 0;
  }
  & > div {
    margin-bottom: 0;
  }
`;
const IndexColumn = styled.div`
  padding-top: 6px;
`;

const renderSuggestion = (value) => <SuggestionContainer>{value.company_name}</SuggestionContainer>;

const tooltip = (text) => (
  <Tooltip id="tooltip">
    {text}
  </Tooltip>
);

const TwoInputForm = ({
  value: valuePr,
  companies,
  counter,

  onChange,
  onCompanyFocusout,
  handleDeleteBtn,
  disabled,
}) => {
  const [suggestedCompanies, setSuggestedCompanies] = useState([]);

  const onSuggestionCalculate = ({ value }) => {
    const re = createRegExpForString(value, 'i');
    const suggested = companies.filter((val) => re.test(val.company_name));
    setSuggestedCompanies(suggested);
  };

  const onClearSuggestions = () => setSuggestedCompanies([]);

  if (!valuePr.name && valuePr.name !== '') {
    const item = companies.find((v) => v.company_id === valuePr.seller_id);
    // eslint-disable-next-line no-param-reassign
    valuePr.name = item && item.company_name;
  }

  const companyName = valuePr.name || '';
  const weight = valuePr.seller_weight || '';

  return (
    <Collapse in appear>
      <Row>
        <StyledCol sm={1}>
          <IndexColumn>{+counter + 1}</IndexColumn>
        </StyledCol>
        <StyledCol sm={4}>
          <AutosuggestControl
            suggestions={suggestedCompanies.slice(0, 30)}
            onSuggestionsFetchRequested={onSuggestionCalculate}
            onSuggestionsClearRequested={onClearSuggestions}
            getSuggestionValue={(v) => v.company_name}
            renderSuggestion={renderSuggestion}
            name={`name_${counter}`}
            value={companyName}
            onChange={(e) => onChange('name', e.target.value, counter)}
            onBlur={(e) => onCompanyFocusout('name', e.target.value, counter)}
            disabled={disabled}
            {...valuePr.validation}
          />
        </StyledCol>
        <Col sm={2}>
          <UniversalFormControl
            labelColumnWidth={0}
            controlColumnWidth={12}
            name={`seller_weight_${valuePr.id || counter}`}
            value={weight}
            onChange={(e) => onChange('seller_weight', e.target.value, counter)}
            onBlur={(e) => onCompanyFocusout('seller_weight', e.target.value, counter)}
            disabled={disabled}
            autoComplete="off"
            {...companyWeightValidator(valuePr.seller_weight)}
          />
        </Col>

        <Col sm={2}>
          <ButtonContainer>
            <OverlayTrigger placement="top" overlay={tooltip('Remove weight for the company')}>
              <Button variant="outline-secondary" disabled={disabled} onClick={handleDeleteBtn}>Remove</Button>
            </OverlayTrigger>
          </ButtonContainer>
        </Col>
      </Row>
    </Collapse>
  );
};

TwoInputForm.propTypes = {
  value: PropTypes.shape({}),
  companies: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  handleDeleteBtn: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCompanyFocusout: PropTypes.func.isRequired,
  counter: PropTypes.string,
};

export default TwoInputForm;
