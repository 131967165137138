import React from 'react';
import styled from 'styled-components';

import { Title, Text } from './WelcomeToNoc';
import Img from '../../Img';
import PlaneImg from '../../../assets/Scrooge_McDuck.png';

const Plane = styled(Img)`
  height: 250px;
  margin-right: 50px;
`;
export const Container = styled.div`
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    img {
      width: 150px;
      margin: 0 auto;
      margin-bottom: 25px;
    }
  }
`;

export default () => (
  <Container>
    <Plane src={PlaneImg} alt="Welcome To NOC" />
    <div>
      <Title>Welcome to Payments Task Center!</Title>
      <Text>
        To view a chat and make payment operations open the relevant task from Tasks section.
      </Text>
    </div>
  </Container>
);
