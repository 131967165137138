import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import createURLtoUM from './createURLtoUM';
import A from '../A';
import { color } from '../../constants';

const Message = styled.div`
  color: ${color.textRed};
`;
const ReqFields = styled.ul`
  color: ${color.textRed};
`;
const UpdateDetailsModal = ({
  requestData,
  reqId,
  approve,
  onHide,
  approveDisabled,
  ...other
}) => (
  <Modal centered onHide={onHide} {...other}>
    <Modal.Header closeButton />
    <Modal.Body>
      The user must have the following information before being approved to be a seller:
      <ul>
        <li>Website (url)</li>
        <li>ISO certificates</li>
        <li>Contact person</li>
        <li>Phone</li>
        <li>Email</li>
        <li>Company logo</li>
      </ul>
      <Message>Please make sure all required fields are filled out correctly:</Message>
      <ReqFields>
        <li>Headquarters / Country</li>
        <li>Headquarters / State/Province</li>
      </ReqFields>
      Click&nbsp;
      <b>
        <A
          rel="noopener noreferrer"
          to={createURLtoUM(requestData, reqId)}
          onClick={onHide}
        >
          here
        </A>
      </b>
      &nbsp;to review and update this information
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={approve} disabled={approveDisabled}>APPROVE</Button>
      <Button variant="outline-secondary" onClick={onHide}>CANCEL</Button>
    </Modal.Footer>
  </Modal>
);

UpdateDetailsModal.propTypes = {
  requestData: PropTypes.shape({
    userId: PropTypes.string,
    customerId: PropTypes.string,
  }),
  reqId: PropTypes.string,
  approveDisabled: PropTypes.bool,
  approve: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default UpdateDetailsModal;
