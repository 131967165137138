import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import styled from 'styled-components';
import moment from 'moment';

import FileChooser from '../FileChooser';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import FormGroupHorizontal from '../../FormComponents/FormGroupHorizontal';
import GooglePlacesAutoSuggest from '../../GooglePlaceSearch';
import { DateRangePickerWrapper } from '../../DatesPicker';
import { RepairLeadTime, RowContainer, MultilineText } from '../StyledComponents';

import { OTHER_SELECT_PICKUP_KEY } from '../../../constants';
import { quoteTypes } from '../../../constants/index';

const MAX_TAG_LENGTH = 60;

const CheckContainer = styled.div`
  margin-top: 8px;
  margin-left: 10px;
`;
const Container = styled.div`
  margin: 0;
  input {
    height: 38px;
  }
`;
const OptionContainer = styled.div`
  margin-bottom: 15px;
`;

export default function FormContent({
  tagFile, type, data, validation, selectOptions,
  onChange, onFileAdd, onFileRemove, onSelectChange, onSelectGooglePlace, onBlurAutoSuggest, onTagDateChange,
}) {
  const [focus, setFocus] = useState(null);

  const showGoogleLocation = data?.pickup_site?.value === OTHER_SELECT_PICKUP_KEY;
  let formContent = null;
  let priceBlock = null;

  if (type === quoteTypes.QUOTE_BUY) {
    priceBlock = (
      <>
        <FormGroupHorizontal
          // eslint-disable-next-line react/jsx-one-expression-per-line
          labelText={<MultilineText>Price<br />(Per Unit):</MultilineText>}
          value={data.price}
          name="price"
          type="number"
          onChange={onChange}
          {...validation.price}
          addition={(
            <Select
              value={data.currency}
              name="currency"
              onChange={onSelectChange}
              options={selectOptions.supportedCurrenciesOptions}
            />
          )}
        />
        <FormGroupHorizontal
          labelText="Taxes:"
          value={data.taxes}
          name="taxes"
          type="number"
          onChange={onChange}
          addition={(
            <Select
              value={data.currency}
              name="currency"
              onChange={onSelectChange}
              options={selectOptions.supportedCurrenciesOptions}
              isDisabled
            />
          )}
          {...validation.taxes}
        />
        <FormGroupHorizontal
          labelText="Total Price:"
          value={data.total_price}
          name="total_price"
          type="number"
          onChange={onChange}
          addition={(
            <Select
              value={data.currency}
              name="currency"
              onChange={onSelectChange}
              options={selectOptions.supportedCurrenciesOptions}
            />
          )}
          {...validation.total_price}
        />
      </>
    );
  } else if (type === quoteTypes.QUOTE_LOAN) {
    priceBlock = (
      <>
        <FormGroupHorizontal
          // eslint-disable-next-line react/jsx-one-expression-per-line
          labelText={<MultilineText>Loan Fee<br />(Per Unit):</MultilineText>}
          value={data.fee}
          name="fee"
          type="number"
          onChange={onChange}
          addition={(
            <Select
              value={data.currency}
              name="currency"
              onChange={onSelectChange}
              options={selectOptions.supportedCurrenciesOptions}
            />
          )}
          {...validation.fee}
        />
        <FormGroupHorizontal
          // eslint-disable-next-line react/jsx-one-expression-per-line
          labelText={<MultilineText>Loan<br />Period:</MultilineText>}
          value={data.period}
          name="period"
          type="number"
          onChange={onChange}
          addition={(
            <Select
              value={selectOptions.timeOptions[1]}
              name="period_units"
              options={selectOptions.timeOptions}
              isDisabled
            />
          )}
          {...validation.period}
        />
        <FormGroupHorizontal
          // eslint-disable-next-line react/jsx-one-expression-per-line
          labelText={<MultilineText>Outright Price<br />(Per Unit):</MultilineText>}
          value={data.outright_price}
          name="outright_price"
          type="number"
          onChange={onChange}
          addition={(
            <Select
              value={data.currency}
              name="currency"
              onChange={onSelectChange}
              options={selectOptions.supportedCurrenciesOptions}
            />
          )}
          {...validation.outright_price}
        />
      </>
    );
  } else if (type === quoteTypes.QUOTE_EXCHANGE) {
    priceBlock = (
      <>
        <FormGroupHorizontal
          // eslint-disable-next-line react/jsx-one-expression-per-line
          labelText={<MultilineText>Exchange Fee<br />(Per Unit):</MultilineText>}
          value={data.fee}
          name="fee"
          type="number"
          onChange={onChange}
          addition={(
            <Select
              value={data.currency}
              name="currency"
              onChange={onSelectChange}
              options={selectOptions.supportedCurrenciesOptions}
            />
          )}
          {...validation.fee}
        />
        <FormGroupHorizontal
          // eslint-disable-next-line react/jsx-one-expression-per-line
          labelText={<MultilineText>Exchange<br />Period:</MultilineText>}
          value={data.period}
          name="period"
          type="number"
          onChange={onChange}
          addition={(
            <Select
              value={selectOptions.timeOptions[1]}
              name="period_units"
              options={selectOptions.timeOptions}
              isDisabled
            />
          )}
          {...validation.period}
        />
        <FormGroupHorizontal
          // eslint-disable-next-line react/jsx-one-expression-per-line
          labelText={<MultilineText>Outright Price<br />(Per Unit):</MultilineText>}
          value={data.outright_price}
          name="outright_price"
          type="number"
          onChange={onChange}
          addition={(
            <Select
              value={data.currency}
              name="currency"
              onChange={onSelectChange}
              options={selectOptions.supportedCurrenciesOptions}
            />
          )}
          {...validation.outright_price}
        />
      </>
    );
  }

  if (type === quoteTypes.QUOTE_REPAIR) {
    formContent = (
      <Row>
        <Col sm={6}>
          <FormGroupHorizontal labelText="Repair Shop:">
            <Select
              value={data.pickup_site}
              name="pickup_site"
              placeholder="Select..."
              onChange={onSelectChange}
              options={selectOptions.siteOptions || []}
              isSearchable={false}
              isClearable
              hideSelectedOptions={false}
            />
          </FormGroupHorizontal>
          {showGoogleLocation && (
            <FormGroupHorizontal labelText=" ">
              <Container>
                <GooglePlacesAutoSuggest
                  onSelectGooglePlace={onSelectGooglePlace}
                  onBlurAutoSuggest={onBlurAutoSuggest}
                  validationState={validation}
                />
              </Container>
            </FormGroupHorizontal>
          )}

          <FormGroupHorizontal
            value={data.warranty}
            labelText="Warranty:"
            addonText="months"
            name="warranty"
            maxLength={12}
            onChange={onChange}
            {...validation.warranty}
          />
          <FormGroupHorizontal
            value={data.comment}
            as="textarea"
            placeholder="Add comment..."
            labelText="Comment:"
            name="comment"
            onChange={onChange}
            rows="3"
          />

          <br />
          <FormGroupHorizontal
            value={data.qty}
            labelText="Qty:"
            name="qty"
            type="number"
            onChange={onChange}
            addition={(
              <Select
                value={data.qty_units}
                name="qty_units"
                onChange={onSelectChange}
                options={selectOptions.unitsOptions}
              />
            )}
            {...validation.qty}
          />
        </Col>
        <Col sm={6}>
          <Row>
            <Col sm={6}>
              <OptionContainer>
                <strong>Price (per unit):</strong>
                <Select
                  value={data.currency}
                  name="currency"
                  onChange={onSelectChange}
                  options={selectOptions.supportedCurrenciesOptions}
                />
              </OptionContainer>
            </Col>
            <Col sm={6}>
              <OptionContainer>
                <strong>Lead Time:</strong>
                <Select
                  value={data.lead_time_units}
                  name="lead_time_units"
                  onChange={onSelectChange}
                  options={selectOptions.timeOptions}
                />
              </OptionContainer>
            </Col>
          </Row>

          <RowContainer>
            <Col sm={6}>
              <BaseFormGroup
                labelText="Evaluation:"
                value={data.evaluation_price}
                name="evaluation_price"
                type="number"
                onChange={onChange}
                {...validation.evaluation_price}
              />
            </Col>
            <Col sm={6}>
              <RepairLeadTime>
                <BaseFormGroup
                  value={data.evaluation_time}
                  name="evaluation_time"
                  type="number"
                  onChange={onChange}
                  {...validation.evaluation_time}
                />
              </RepairLeadTime>
            </Col>
          </RowContainer>

          <RowContainer>
            <Col sm={6}>
              <BaseFormGroup
                labelText="Test:"
                value={data.test_price}
                name="test_price"
                type="number"
                onChange={onChange}
                {...validation.test_price}
              />
            </Col>
            <Col sm={6}>
              <RepairLeadTime>
                <BaseFormGroup
                  value={data.test_time}
                  name="test_time"
                  type="number"
                  onChange={onChange}
                  {...validation.test_time}
                />
              </RepairLeadTime>
            </Col>
          </RowContainer>

          <RowContainer>
            <Col sm={6}>
              <BaseFormGroup
                labelText="Repair"
                value={data.repair_price}
                name="repair_price"
                type="number"
                onChange={onChange}
                {...validation.repair_price}
              />
            </Col>
            <Col sm={6}>
              <RepairLeadTime>
                <BaseFormGroup
                  value={data.repair_time}
                  name="repair_time"
                  type="number"
                  onChange={onChange}
                  {...validation.repair_time}
                />
              </RepairLeadTime>
            </Col>
          </RowContainer>

          <RowContainer>
            <Col sm={6}>
              <BaseFormGroup
                labelText="Overhaul:"
                value={data.overhaul_price}
                name="overhaul_price"
                type="number"
                onChange={onChange}
                {...validation.overhaul_price}
              />
            </Col>
            <Col sm={6}>
              <RepairLeadTime>
                <BaseFormGroup
                  value={data.overhaul_time}
                  name="overhaul_time"
                  type="number"
                  onChange={onChange}
                  {...validation.overhaul_time}
                />
              </RepairLeadTime>
            </Col>
          </RowContainer>
        </Col>
      </Row>
    );
  } else {
    formContent = (
      <Row>
        <Col sm={6}>
          <FormGroupHorizontal
            value={data.part_number}
            labelText="Part Number:"
            name="part_number"
            type="text"
            maxLength={35}
            onChange={onChange}
            disabled={!data.is_alt_pn}
            addition={(
              <CheckContainer>
                <Form.Check label="ALT PN" checked={data.is_alt_pn} name="is_alt_pn" onChange={onChange} />
              </CheckContainer>
            )}
            {...validation.part_number}
          />
          <FormGroupHorizontal
            value={data.description}
            labelText="Description:"
            name="description"
            type="text"
            placeholder="Add item's description..."
            onChange={onChange}
            addition={null}
          />
          <FormGroupHorizontal labelText="Condition:">
            <Select
              value={data.condition}
              name="condition"
              placeholder="Select..."
              onChange={onSelectChange}
              options={selectOptions.conditionOptions}
              isSearchable={false}
              isClearable
            />
          </FormGroupHorizontal>
          <FormGroupHorizontal
            value={data.lead_time}
            labelText="Lead Time:"
            name="lead_time"
            type="number"
            onChange={onChange}
            addition={(
              <Select
                value={data.lead_time_units}
                name="lead_time_units"
                onChange={onSelectChange}
                options={selectOptions.timeOptions}
              />
            )}
            {...validation.lead_time}
          />

          <FormGroupHorizontal labelText="Pickup Site:">
            <Select
              value={data.pickup_site}
              name="pickup_site"
              placeholder="Select..."
              onChange={onSelectChange}
              options={selectOptions.siteOptions || []}
              isSearchable={false}
              isClearable
              hideSelectedOptions={false}
            />
          </FormGroupHorizontal>
          {showGoogleLocation && (
            <FormGroupHorizontal labelText=" ">
              <Container>
                <GooglePlacesAutoSuggest
                  onSelectGooglePlace={onSelectGooglePlace}
                  onBlurAutoSuggest={onBlurAutoSuggest}
                  validationState={validation}
                />
              </Container>
            </FormGroupHorizontal>
          )}

          <FormGroupHorizontal
            labelText="Tag:"
            addition={(
              <FileChooser
                file={tagFile}
                onFileAdd={onFileAdd}
                onFileRemove={onFileRemove}
              />
            )}
          >
            <Creatable
              value={data.tag_type}
              name="tag_type"
              placeholder="Select or type..."
              onChange={onSelectChange}
              options={selectOptions.tagOptions}
              onInputChange={(inputValue) => (inputValue?.length <= MAX_TAG_LENGTH ? inputValue : inputValue.substr(0, MAX_TAG_LENGTH))}
              menuPlacement="top"
              isClearable
            />
          </FormGroupHorizontal>
          <FormGroupHorizontal
            value={data.tagged_by}
            labelText="Tagged By:"
            name="tagged_by"
            type="text"
            onChange={onChange}
            addition={null}
          />
          <FormGroupHorizontal labelText="Tag Date:">
            <DateRangePickerWrapper>
              <SingleDatePicker
                date={data.tag_date ? moment.utc(data.tag_date) : null}
                onDateChange={onTagDateChange}
                focused={focus}
                displayFormat="M/D/YYYY"
                onFocusChange={({ focused }) => setFocus(focused)}
                isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                id="tag_date"
                placeholder="Select date"
                numberOfMonths={1}
                showDefaultInputIcon
                small
                block
                showClearDate
                openDirection="up"
              />
            </DateRangePickerWrapper>
          </FormGroupHorizontal>
          <FormGroupHorizontal labelText="Trace To:">
            <Creatable
              value={data.trace_to}
              name="trace_to"
              placeholder="Select or type..."
              onChange={onSelectChange}
              options={selectOptions.traceToOptions}
              menuPlacement="top"
              isClearable
            />
          </FormGroupHorizontal>
        </Col>
        <Col sm={6}>
          <FormGroupHorizontal
            value={data.warranty}
            labelText="Warranty:"
            addonText="months"
            name="warranty"
            maxLength={12}
            onChange={onChange}
            {...validation.warranty}
          />
          <FormGroupHorizontal
            value={data.qty}
            labelText="Qty:"
            name="qty"
            type="number"
            onChange={onChange}
            addition={(
              <Select
                value={data.qty_units}
                name="qty_units"
                onChange={onSelectChange}
                options={selectOptions.unitsOptions}
              />
            )}
            {...validation.qty}
          />

          {priceBlock}

          <FormGroupHorizontal
            labelText={<i>Minimum Order Value:</i>}
            value={data.minimum_order_value}
            name="minimum_order_value"
            type="number"
            onChange={onChange}
            addition={(
              <Select
                value={data.currency}
                name="currency"
                onChange={onSelectChange}
                options={selectOptions.supportedCurrenciesOptions}
                isDisabled
              />
            )}
            {...validation.minimum_order_value}
          />
          <FormGroupHorizontal
            value={data.comment}
            as="textarea"
            placeholder="Add comment..."
            labelText="Comment:"
            name="comment"
            onChange={onChange}
            rows="3"
          />
        </Col>
      </Row>
    );
  }

  return formContent;
}

FormContent.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    thumbnail_url: PropTypes.string,
    url: PropTypes.string,
  }),
  type: PropTypes.string,
  data: PropTypes.shape({
    part_number: PropTypes.string,
    is_alt_pn: PropTypes.bool,
    description: PropTypes.string,
    condition: PropTypes.shape({ value: PropTypes.number, label: PropTypes.string }),
    lead_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lead_time_units: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
    pickup_site: PropTypes.shape({
      site_id: PropTypes.string,
      name: PropTypes.string,
      location: PropTypes.string,
      place_id: PropTypes.string,
      value: PropTypes.string,
    }),
    tag_type: PropTypes.shape({ value: PropTypes.number, label: PropTypes.string }),
    tagged_by: PropTypes.string,
    tag_date: PropTypes.string,
    trace_to: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
    warranty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    qty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    qty_units: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    taxes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minimum_order_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    outright_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    period: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    evaluation_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    evaluation_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    test_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    test_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    repair_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    repair_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    overhaul_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    overhaul_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    currency: PropTypes.shape({ }),
    comment: PropTypes.string,
  }),
  selectOptions: PropTypes.shape({
    siteOptions: PropTypes.arrayOf(PropTypes.object),
    conditionOptions: PropTypes.arrayOf(PropTypes.object),
    builtInQuoteOptions: PropTypes.arrayOf(PropTypes.object),
    supportedCurrenciesOptions: PropTypes.arrayOf(PropTypes.object),
    unitsOptions: PropTypes.arrayOf(PropTypes.object),
    timeOptions: PropTypes.arrayOf(PropTypes.object),
  }),
  validation: PropTypes.shape({
    part_number: PropTypes.string,
    lead_time: PropTypes.shape({}),
    pickup_site: PropTypes.shape({}),
    warranty: PropTypes.shape({}),
    qty: PropTypes.shape({}),
    price: PropTypes.shape({}),
    taxes: PropTypes.shape({}),
    total_price: PropTypes.shape({}),

    period: PropTypes.shape({}),
    fee: PropTypes.shape({}),
    outright_price: PropTypes.shape({}),
    minimum_order_value: PropTypes.shape({}),

    evaluation_price: PropTypes.shape({}),
    evaluation_time: PropTypes.shape({}),
    test_price: PropTypes.shape({}),
    test_time: PropTypes.shape({}),
    repair_price: PropTypes.shape({}),
    repair_time: PropTypes.shape({}),
    overhaul_price: PropTypes.shape({}),
    overhaul_time: PropTypes.shape({}),
  }),
  onChange: PropTypes.func.isRequired,
  onFileAdd: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func.isRequired,
  onTagDateChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onSelectGooglePlace: PropTypes.func.isRequired,
  onBlurAutoSuggest: PropTypes.func.isRequired,
};
