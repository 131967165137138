import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import replaceDocImage from '../../../assets/ic-replace.jpg';
import {
  Text,
  ButtonsWrapper,
  Button,
} from './styledComponents';

const ReplaceFileImg = styled.div`
  background: url(${replaceDocImage}) no-repeat 0 0;
  width: 121px;
  height: 140px;
  margin: 40px auto 0;
  position: relative;
  left: -7px;
`;

const Input = styled.input`
  && {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  &:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
`;
const Label = styled.label`
&& {
  font-size: 16px;
  padding: 6px 20px;
}
`;

const ReplaceDocumentModal = ({ onReplace, onHide, ...other }) => (
  <Modal centered onHide={onHide} {...other}>
    <Modal.Body>
      <ReplaceFileImg />
      <Text>
        Are you sure you want to replace
        <br />
        this document?
      </Text>
    </Modal.Body>
    <Modal.Footer>
      <ButtonsWrapper>
        <Input
          id="replace_ID"
          type="file"
          onChange={(e) => {
            onReplace(e);
            onHide();
          }}
        />
        <Label htmlFor="replace_ID" className="btn btn-primary btn-file">
          Replace
        </Label>

        <Button variant="outline-secondary" onClick={onHide}>Cancel</Button>
      </ButtonsWrapper>
    </Modal.Footer>
  </Modal>
);

ReplaceDocumentModal.propTypes = {
  onHide: PropTypes.func,
  onReplace: PropTypes.func,
};
export default ReplaceDocumentModal;
