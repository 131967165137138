import axios from 'axios';

import {
  CHAT_DATA_FETCH,
  CHAT_DATA_IS_FETCHED,
  CHAT_DATA_CLEAR,
  CHAT_ERROR_IS_OCCURRED,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';

export const startFetchingData = () => ({ type: CHAT_DATA_FETCH });

export const finishFetchingData = (chatData) => ({
  type: CHAT_DATA_IS_FETCHED,
  payload: chatData,
});

export const clearChatData = () => ({ type: CHAT_DATA_CLEAR });

export const chatFail = () => ({ type: CHAT_ERROR_IS_OCCURRED });

export const fetchChatData = (orderId) => async (dispatch) => {
  dispatch(startFetchingData());
  try {
    const response = await axios(`/rest/V1/eplane/backoffice/rfq/${orderId}`);
    dispatch(finishFetchingData(response.data));
  } catch (e) {
    requestErrorHandler(e.response, dispatch, chatFail, e);
  }
};
