import axios from 'axios';

import { getBoUserRole } from '../../selectors/index';
import roles from '../../constants/backofficeRoles';
import {
  PRO_PLAN_PRICE_GET,
  PRO_PLAN_PRICE_GET_SUCCESS,
  PRO_PLAN_PRICE_SAVE,
  PRO_PLAN_PRICE_SAVE_SUCCESS,
  PRO_PLAN_PRICE_FAIL,
  PRO_PLAN_PRICE_RESET,
  ERRORS,
} from '../../constants';
import { addError, requestErrorHandler } from '../requestErrorHandler';

const startFetchingProPrice = () => ({ type: PRO_PLAN_PRICE_GET });
const fetchProPriceSuccess = (price) => ({
  type: PRO_PLAN_PRICE_GET_SUCCESS,
  payload: price,
});

const startSavingProPrice = () => ({ type: PRO_PLAN_PRICE_SAVE });
const saveProPriceSuccess = (price) => ({
  type: PRO_PLAN_PRICE_SAVE_SUCCESS,
  payload: price,
});

const proPriceFail = () => ({ type: PRO_PLAN_PRICE_FAIL });
export const resetProPrice = () => ({ type: PRO_PLAN_PRICE_RESET });

export const fetchProPrice = () => async (dispatch) => {
  dispatch(startFetchingProPrice());
  try {
    const response = await axios('rest/V2/eplane/backoffice/payment/settings/pro-subscription');
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchProPriceSuccess(response.data?.payload.data.items));
    } else {
      requestErrorHandler(response, dispatch, proPriceFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, proPriceFail, e);
  }
};

export const saveProPrice = (data) => async (dispatch, getState) => {
  if (getBoUserRole(getState()) === roles.VIEWER) {
    dispatch(addError(ERRORS.ERR_PERMISSION_DENIED));
    return;
  }

  dispatch(startSavingProPrice());
  try {
    const response = await axios.post('rest/V2/eplane/backoffice/payment/settings/pro-subscription', data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveProPriceSuccess(response.data?.payload.data.items));
    } else {
      requestErrorHandler(response, dispatch, proPriceFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, proPriceFail, e);
  }
};
