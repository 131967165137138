import {
  USER_GET,
  USER_GET_SUCCESS,
  USER_GET_FAIL,
  USER_ERROR_REMOVE,
  USER_SAVE,
  USER_SAVE_SUCCESS,
  USER_SAVE_FAIL,
  USER_LOGO_UPLOAD,
  USER_LOGO_UPLOAD_SUCCESS,
  USER_LOGO_UPLOAD_FAIL,
  USER_LOGO_UPLOADED_REMOVE,
  USER_ERROR_SET,
  USER_CLEAR,

  USER_CHECKING_CUSTOMER_START,
  USER_CHECKING_CUSTOMER_SUCCESS,
} from '../../constants';

const initialState = {
  user: null,
  isFetching: false,
  isSaving: false,
  isCheckingCustomer: false, // checking if customer belongs to company user
  isCustomerChecked: false,
  errorCode: null,
  uploadedLogo: null,
};

const userReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case USER_GET:
      return {
        ...state,
        isFetching: true,
        errorCode: null,
      };
    case USER_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: payload,
      };
    case USER_GET_FAIL:
      return {
        ...state,
        isFetching: false,
        errorCode: payload,
      };

    case USER_SAVE:
      return {
        ...state,
        isSaving: true,
        errorCode: null,
      };
    case USER_SAVE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        user: payload,
        uploadedLogo: null,
      };
    case USER_SAVE_FAIL:
      return {
        ...state,
        isSaving: false,
        errorCode: payload,
        uploadedLogo: null,
      };

    case USER_LOGO_UPLOAD:
      return {
        ...state,
        isSaving: true,
        errorCode: null,
      };
    case USER_LOGO_UPLOAD_SUCCESS:
      return {
        ...state,
        isSaving: false,
        uploadedLogo: {
          name: payload.name,
          thumbnail_url: payload.thumbnailUrl,
          originName: payload.origin_name,
          url: payload.url,
          uploadId: payload.upload_id,
        },
      };
    case USER_LOGO_UPLOAD_FAIL:
      return {
        ...state,
        isSaving: false,
        errorCode: payload,
        uploadedLogo: null,
      };
    case USER_LOGO_UPLOADED_REMOVE:
      return {
        ...state,
        uploadedLogo: null,
      };

    case USER_ERROR_SET:
      return {
        ...state,
        errorCode: payload,
        isFetching: false,
        isCheckingCustomer: false,
      };
    case USER_ERROR_REMOVE:
      return {
        ...state,
        errorCode: null,
        isCheckingCustomer: false,
      };

    case USER_CHECKING_CUSTOMER_START:
      return {
        ...state,
        isCheckingCustomer: true,
        isCustomerChecked: false,
      };
    case USER_CHECKING_CUSTOMER_SUCCESS:
      return {
        ...state,
        isCheckingCustomer: false,
        isCustomerChecked: true,
      };

    case USER_CLEAR:
      return initialState;

    default: return state;
  }
};

export default userReducer;
