import {
  USER_REPOSITORY_STATS_GET,
  USER_REPOSITORY_STATS_GET_SUCCESS,
  USER_REPOSITORY_STATS_GET_FAIL,

  USER_REPOSITORY_UNPUBLISH,
  USER_REPOSITORY_UNPUBLISH_SUCCESS,
  USER_REPOSITORY_UNPUBLISH_FAIL,

  USER_REPOSITORY_DAILY_STATUS_GET,
  USER_REPOSITORY_DAILY_STATUS_SUCCESS,
  USER_REPOSITORY_DAILY_STATUS_FAIL,

  USER_REPOSITORY_ERROR_REMOVE,
} from '../../constants';

const initialState = {
  inventoryStatistics: null,
  dailyStatus: null,

  isFetchingDaily: false,
  isFetchingStats: false,
  isUnpublishing: false,

  dailyErrorCode: null,
  fetchingErrorCode: null,
  unpublishErrorCode: null,
};

function userRepositoryReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case USER_REPOSITORY_STATS_GET:
      return {
        ...state,
        isFetchingStats: true,
        fetchingErrorCode: null,
      };

    case USER_REPOSITORY_STATS_GET_SUCCESS:
      return {
        ...state,
        inventoryStatistics: payload,
        isFetchingStats: false,
      };

    case USER_REPOSITORY_STATS_GET_FAIL:
      return {
        ...state,
        fetchingErrorCode: payload,
        isFetchingStats: false,
      };

    case USER_REPOSITORY_UNPUBLISH:
      return {
        ...state,
        isUnpublishing: true,
        fetchingErrorCode: null,
      };

    case USER_REPOSITORY_UNPUBLISH_SUCCESS:
      return {
        ...state,
        inventoryStatistics: payload,
        isUnpublishing: false,
      };

    case USER_REPOSITORY_UNPUBLISH_FAIL:
      return {
        ...state,
        isUnpublishing: false,
        unpublishErrorCode: payload,
      };

    case USER_REPOSITORY_DAILY_STATUS_GET:
      return {
        ...state,
        isFetchingDaily: true,
        dailyErrorCode: null,
      };

    case USER_REPOSITORY_DAILY_STATUS_SUCCESS:
      return {
        ...state,
        isFetchingDaily: false,
        dailyStatus: payload,
      };

    case USER_REPOSITORY_DAILY_STATUS_FAIL:
      return {
        ...state,
        isFetchingDaily: false,
        dailyErrorCode: payload,
      };

    case USER_REPOSITORY_ERROR_REMOVE:
      return {
        ...state,
        fetchingErrorCode: null,
        unpublishErrorCode: null,
      };

    default: return state;
  }
}

export default userRepositoryReducer;
