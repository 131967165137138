import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import Actions from './Actions';
import { getSellerCount, SelectedItemContainer } from './Helpers';
import { getMenuList } from './SellersListForm';
import { getCompanyListStore } from '../../../selectors';

const EditTableRow = styled.tr`
  td:first-child input {
    max-width: 180px;
    height: 38px;
  }
  .sellers-list {
    padding-right: 20px;
    overflow: visible;
    white-space: normal;
  }
`;

const SellersListRow = ({
  list,
  sellerOptions,

  onSave,
  onAddCustomSeller,
  onEditCancel,
  onNameChange,
  onSellerRemoveHandler,
  onSelectedSellerChange,
}) => {
  const editSellerOptions = useMemo(
    () => sellerOptions.filter((v) => list.selectedSellerList.every((t) => t.value !== v.value)),
    [sellerOptions, list.selectedSellerList],
  );
  const { isFetching: isFetchingCompanies } = useSelector(getCompanyListStore);
  const disableSave = list.nameValidation
    || (list.selectedSellerList?.length === 0 && list.selectedSeller === null);

  return (
    <EditTableRow>
      <td>
        <BaseFormGroup
          value={list.listName}
          placeholder="Enter a unique list name"
          name="name"
          onChange={onNameChange}
          {...list.nameValidation}
        />
        {getSellerCount(list.selectedSellerList)}
      </td>
      <td className="sellers-list">
        <Select
          value={list.selectedSeller}
          name="customer"
          placeholder="Search by seller's name"
          onChange={onSelectedSellerChange}
          options={editSellerOptions}
          components={{ MenuList: getMenuList(onAddCustomSeller) }}
          hideSelectedOptions={false}
          isLoading={isFetchingCompanies}
          isClearable
        />
        <br />
        <SelectedItemContainer list={list.selectedSellerList} onRemove={onSellerRemoveHandler} />
      </td>
      <td />
      <td>
        <Actions
          editMode
          saveDisabled={disableSave}
          onSaveHandler={onSave}
          onCancelHandler={onEditCancel}
        />
      </td>
    </EditTableRow>
  );
};

SellersListRow.propTypes = {
  list: PropTypes.shape({
    seller_id: PropTypes.string,
    listName: PropTypes.string,
    selectedSeller: PropTypes.shape({}),
    selectedSellerList: PropTypes.arrayOf(PropTypes.object),
    nameValidation: PropTypes.shape({
      validationState: PropTypes.string,
      helpText: PropTypes.string,
    }),
  }),
  sellerOptions: PropTypes.arrayOf(PropTypes.object),

  onSave: PropTypes.func.isRequired,
  onAddCustomSeller: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func,
  onNameChange: PropTypes.func,
  onSelectedSellerChange: PropTypes.func,
  onSellerRemoveHandler: PropTypes.func,
};

export default SellersListRow;
