import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Filters from '../NetworkOperationsFilters';
import Tasks from '../TasksList';
import TopBar from '../TopBar';
import Spinner from '../../spinner';
import { color } from '../../../../constants';

const TasksContainer = styled.div`
  height: 100%;
  width:100%;
  display: block;
  margin:0;
`;
const NoTasksContainer = styled.div`
  margin-top: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  p {
    font-size: 16px;
    color: ${color.grey};
  }
`;
const NoTasksBlock = (
  <NoTasksContainer>
    <p>There are no results that match your search.</p>
  </NoTasksContainer>
);

const TasksSideBar = ({
  totalTasksAmount, isTasksFetching, queryParams, formatStatusText, renderHeaderText, ...props
}) => (
  <div className="tasks-sidebar">
    <TopBar renderHeaderText={renderHeaderText} />

    <TasksContainer>
      <Filters queryParams={queryParams} {...props} />
      {totalTasksAmount > 0 && <Tasks isTasksFetching={isTasksFetching} {...props} />}
      {totalTasksAmount === 0 && NoTasksBlock}
      {isTasksFetching && <Spinner />}
    </TasksContainer>
  </div>
);

TasksSideBar.propTypes = {
  totalTasksAmount: PropTypes.number,
  tasksPerPage: PropTypes.number,
  totalTaskPages: PropTypes.number,
  isTasksFetching: PropTypes.bool,
  isLoadMore: PropTypes.bool,
  formatStatusText: PropTypes.func,
  setTaskFetchingParams: PropTypes.func,
  tasks: PropTypes.arrayOf(PropTypes.object),
  onTaskClick: PropTypes.func.isRequired,
  queryParams: PropTypes.shape({
    q: PropTypes.string,
    f: PropTypes.string,
    t: PropTypes.string,
    s: PropTypes.string,
    c: PropTypes.string,
  }),
  renderHeaderText: PropTypes.func,
};

export default TasksSideBar;
