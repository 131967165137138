import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Card, Accordion } from 'react-bootstrap';

import TooBigFileModal from '../Modals/TooBigFileModal';
import WrongFormatModal from '../Modals/WrongFileFormatModal';
import WarningModal from '../WarningModal';
import './ErrorModal.css';
import errorExtractor from '../../utils/errorExtractor';
import { ERRORS } from '../../constants';

const BodyContainer = styled.div`
  margin-top: 38px;
  text-align: center;
`;
const HeaderModal = styled.strong`
  font-size: 26px;
  letter-spacing: -0.2px;
  display: block;
`;
const Text = styled.div`
  font-size: 26px;
  letter-spacing: -0.2px;
  line-height: 1.35;
`;
const StyledPanelBody = styled(Card.Body)`
  max-height: 80px;
  overflow-y: scroll;
`;
const DetailsItem = styled.div`
  margin-bottom: 15px;
`;

const getModalBody = (error) => {
  const text = (typeof error === 'string' || error instanceof String)
    ? error
    : errorExtractor(error);
  let detailsPanel = null;

  if (Array.isArray(error) && error.length > 0) {
    detailsPanel = (
      <Accordion>
        <Card id="errors-details-panel">
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Show more details
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <StyledPanelBody>
              {error.map((v) => (
                <DetailsItem key={v.message}>
                  {v.url}
                  <br />
                  {v.message}
                </DetailsItem>
              ))}
            </StyledPanelBody>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }

  return (
    <BodyContainer>
      <HeaderModal>Something went wrong.</HeaderModal>
      <Text>{text}</Text>
      <br />
      {detailsPanel}
    </BodyContainer>
  );
};

const ErrorModal = ({
  show, error, onHide, ...props
}) => {
  const body = getModalBody(error);
  const showModal = show || (error !== null && error !== undefined && !Array.isArray(error)) || (Array.isArray(error) && error.length > 0);
  const footer = (
    <div>
      <Button variant="outline-secondary" onClick={onHide} data-testid="err_modal_close_bttn">CLOSE</Button>
    </div>
  );

  if (Array.isArray(error) && error.length === 1 && error[0].error === ERRORS.ERR_FILE_IS_TOO_LARGE) {
    const text = (
      <div>
        <p>
          The file you are trying to upload exceeds the&nbsp;
          {error[0]?.message?.maxFileSize}
          &nbsp;attachment limit.
        </p>
        <p>Please try again with a smaller file or contact us for assistance.</p>
      </div>
    );
    return (
      <TooBigFileModal
        centered
        show={showModal}
        text={text}
        headerText={`File Exceeds ${error[0]?.message?.maxFileSize}`}
        onHide={onHide}
        {...props}
      />
    );
  }

  if (Array.isArray(error) && error.length === 1 && error[0].error === ERRORS.ERR_WRONG_FORMAT) {
    const text = (
      <div>
        <p>
          The file you are trying to upload is not in a supported format&nbsp;
          <strong>{error[0]?.message?.supportedFormats}</strong>
        </p>
        <p>Please try again using one of the accepted formats.</p>
      </div>
    );
    return (
      <WrongFormatModal centered show={showModal} onHide={onHide} text={text} {...props} />
    );
  }

  if (Array.isArray(error) && error.some((e) => e.error === ERRORS.ERR_PERMISSION_DENIED)) {
    const denyBody = (
      <BodyContainer>
        <HeaderModal>Permission Denied.</HeaderModal>
        <p>You don&apos;t have necessary permission for this operation. Please contact your administrator.</p>
      </BodyContainer>
    );
    return (
      <WarningModal centered body={denyBody} footer={footer} show={showModal} {...props} />
    );
  }

  return (
    <WarningModal centered body={body} footer={footer} show={showModal} {...props} />
  );
};

ErrorModal.propTypes = {
  show: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      error: PropTypes.number,
      message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({}),
      ]),
    })),
  ]),
  onHide: PropTypes.func,
};

export default ErrorModal;
