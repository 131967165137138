import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { color } from '../../../constants';

const colorBarStates = {
  1: {
    color: '#4dbf7d',
    tooltipText: 'Your quote is one of the lowest quotes.',
  },
  2: {
    color: '#4dbf7d',
    tooltipText: 'There are quotes over 10% lower than yours.',
  },
  3: {
    color: '#f3ba64',
    tooltipText: 'There are quotes over 20% lower than yours.',
  },
  4: {
    color: '#f3ba64',
    tooltipText: 'There are quotes over 30% lower than yours.',
  },
  5: {
    color: '#f3ba64',
    tooltipText: 'There are quotes over 40% lower than yours.',
  },
  6: {
    color: color.tomatoRed,
    tooltipText: 'There are quotes over 50% lower than yours.',
  },
  7: {
    color: color.tomatoRed,
    tooltipText: 'There are quotes over 75% or more lower than yours.',
  },
};
const IndicatorContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;

  .bar {
    height: 3px;
    background-color: ${(props) => props.color};
  }
  .marker {
    margin-bottom: -5px;
    margin-left: calc((100% - 10px) * ((${(props) => props.value} - 1) / (${(props) => props.maxValue} - 1)));
  }
`;
const Container = styled.div`
  display: flex;
`;
const Icon = styled(FontAwesomeIcon)`
  margin-top: 16px;
  margin-left: 10px;
  padding: 0;
`;

export default function LowBidIndicator({ value, multipleGroups, isLive }) {
  let multipleGroupsInfo = null;

  if (multipleGroups === 1) {
    multipleGroupsInfo = (
      <>
        <FontAwesomeIcon icon="ellipsis-h" color={color.silverGrey} />
        &nbsp;Items from a different condition group is also offered to the buyer.
      </>
    );
  } else if (multipleGroups > 1) {
    multipleGroupsInfo = (
      <>
        <FontAwesomeIcon icon="ellipsis-h" color={color.silverGrey} />
        &nbsp;Items from different condition groups are also offered to the buyer.
      </>
    );
  }

  const content = isLive ? (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="bottom"
      overlay={(
        <Popover id="popover-positioned">
          <Popover.Title as="h3">This Quote vs. Other Quotes</Popover.Title>
          <Popover.Content>
            <div>{colorBarStates[value].tooltipText}</div>
            <br />
            <span>{multipleGroupsInfo}</span>
          </Popover.Content>
        </Popover>
      )}
    >
      <Container>
        <IndicatorContainer color={colorBarStates[value].color} value={value} maxValue={7}>
          <div>
            <FontAwesomeIcon className="marker" icon="map-marker" color={color.silverGrey} />
          </div>
          <div className="bar" />
        </IndicatorContainer>
        {(multipleGroups > 0) && <Icon icon="ellipsis-h" color={color.silverGrey} />}
      </Container>
    </OverlayTrigger>
  ) : (
    <Container>
      <IndicatorContainer color={colorBarStates[value].color} value={value} maxValue={7}>
        <div>
          <FontAwesomeIcon className="marker" icon="map-marker" color={color.silverGrey} />
        </div>
        <div className="bar" />
      </IndicatorContainer>
      {(multipleGroups > 0) && <Icon icon="ellipsis-h" color={color.silverGrey} />}
    </Container>
  );

  return (
    <>{content}</>
  );
}

LowBidIndicator.propTypes = {
  value: propTypes.number,
  multipleGroups: propTypes.number,
  isLive: propTypes.bool,
};
