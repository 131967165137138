import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { getPaymentsTasksStore } from '../../../selectors';
import {
  sendMessage, uploadDocument, removeDoc, terminateUploadingProgress,
} from '../../../actions/networkOperationsActions/paymentsActions';
import { hideForm } from '../../../actions/networkOperationsActions/formManagementActions';

import FormRadioGroup from '../../RadioGroupBasic';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import FormActionButtons from '../../FormComponents/ActionButtons';
import NotificationsGroup from './NotificationGroup';
import DocumentLoader from '../../FilesView/AttachFileLoader';
import ProgressModal from '../../Modals/ProgressModal';
import { Title, FormButtonContainer, TextAreaContainer } from '../StyledComponents';
import { chatAtchRfqRole, chatMsgType, tagType } from '../../../constants/index';

const ButtonGroup = styled.div`
  button {
    display: block;
    margin-bottom: 15px;
  }
`;

const attachRfqRoleOptions = [
  { value: chatAtchRfqRole.PO, labelText: 'PO' },
  { value: chatAtchRfqRole.TAG, labelText: 'Tag' },
  { value: chatAtchRfqRole.QUOTE, labelText: 'Quote' },
  { value: chatAtchRfqRole.INVOICE, labelText: 'Invoice' },
  { value: chatAtchRfqRole.GENERAL, labelText: 'Other' },
];
const repliesConsts = {
  PO: chatMsgType.EPLANE_PO_REQUEST,
  SHIPPED: chatMsgType.EPLANE_ORDER_SHIPPED,
};

const SendMessageForm = ({ orderId, taskId }) => {
  const dispatch = useDispatch();

  const { document, uploadingProgress, isUploading } = useSelector(getPaymentsTasksStore);

  const [formData, setFormData] = useState({
    status: chatMsgType.TEXT,
    comment: '',
    attachRfqRole: chatAtchRfqRole.PO,
    notification: {
      sellerNotification: {
        checked: false,
        disabled: false,
      },
      buyerNotification: {
        checked: false,
        disabled: false,
      },
    },
  });

  useEffect(() => () => {
    dispatch(removeDoc());
  }, [dispatch]);

  const onNotificationChange = (e) => {
    const newNotification = {
      ...formData.notification,
      [e.target.name]: {
        ...formData.notification[e.target.name],
        checked: e.target.checked,
      },
    };
    setFormData({ ...formData, notification: newNotification });
  };

  const onReplyChange = (e) => {
    let { notification } = formData;

    if (e.target.name === repliesConsts.PO && formData.status !== repliesConsts.PO) {
      notification = {
        sellerNotification: {
          checked: false,
          disabled: true,
        },
        buyerNotification: {
          checked: true,
          disabled: true,
        },
      };
    } else if (formData.status === repliesConsts.PO) {
      notification = {
        sellerNotification: {
          checked: false,
          disabled: false,
        },
        buyerNotification: {
          checked: false,
          disabled: false,
        },
      };
    }

    setFormData({
      ...formData,
      status: (formData.status !== e.target.name) ? e.target.name : chatMsgType.TEXT,
      notification,
    });
  };

  const onSendHandler = () => {
    const attachments = document
      ? [{
        name: document.name,
        upload_id: document.upload_id,
        rfq_role: formData.attachRfqRole,
        comment: null,
        tag_type: formData.attachRfqRole === chatAtchRfqRole.TAG ? tagType.OTHERS.id : null,
      }] : {};

    const sendData = {
      message_type: formData.status,
      message: {
        text: formData.comment,
      },
      attachments,
      notify_seller: formData.notification.sellerNotification.checked,
      notify_buyer: formData.notification.buyerNotification.checked,
      payment_task_id: taskId || null,
    };
    dispatch(sendMessage(sendData, orderId));
    dispatch(hideForm());
  };

  const onCommentChange = (e) => {
    if (!e.target.value.startsWith(' ') && !e.target.value.startsWith('\n')) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  return (
    <Form>
      <Title>Send message</Title>
      <p>You can use one of these custom replies and send it to the buyer and/or the seller:</p>
      <ButtonGroup>
        <Button
          variant="outline-secondary"
          name={repliesConsts.PO}
          onClick={onReplyChange}
          active={formData.status === repliesConsts.PO}
        >
          Please send PO to ePlane
        </Button>
        <Button
          variant="outline-secondary"
          name={repliesConsts.SHIPPED}
          onClick={onReplyChange}
          active={formData.status === repliesConsts.SHIPPED}
        >
          Order shipped
        </Button>
      </ButtonGroup>

      <TextAreaContainer>
        <BaseFormGroup
          value={formData.comment}
          as="textarea"
          placeholder="Type your message here..."
          name="comment"
          onChange={onCommentChange}
          rows="4"
        />
      </TextAreaContainer>

      <DocumentLoader
        file={document}
        onRemoveHandler={() => dispatch(removeDoc())}
        onChangeHandler={(e) => {
          dispatch(uploadDocument(e.target.files[0]));
          e.target.value = null;
        }}
        accept="*"
        testId="document-uploader"
      />

      {document && (
        <Form.Group>
          <Form.Label>
            Attached file type:
          </Form.Label>
          <div>
            <FormRadioGroup
              checkedValue={formData.attachRfqRole}
              radioList={attachRfqRoleOptions}
              onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
              name="attachRfqRole"
              inline
            />
          </div>
        </Form.Group>
      )}

      <br />
      <NotificationsGroup state={formData.notification} onChange={onNotificationChange} />

      <hr />
      <FormButtonContainer>
        <FormActionButtons
          saveHandler={onSendHandler}
          cancelHandler={() => dispatch(hideForm())}
          confirmButtonText="Send"
          cancelButtonText="Cancel"
          saveProps={{ disabled: formData.status === chatMsgType.TEXT && (!formData.comment || isUploading) }}
          width="100"
        />
      </FormButtonContainer>
      <ProgressModal show={isUploading} now={uploadingProgress} onCancel={() => dispatch(terminateUploadingProgress)} />
    </Form>
  );
};

SendMessageForm.propTypes = {
  orderId: PropTypes.string,
  taskId: PropTypes.string,
};

export default SendMessageForm;
