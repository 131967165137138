import React from 'react';
import PropTypes from 'prop-types';

import WrongFileFormatModal from '../../Modals/WrongFileFormatModal';
import TooBigFileModal from '../../Modals/TooBigFileModal';
import ErrorModal from '../../ErrorModal';
import { ERRORS } from '../../../constants';

const ErrorModalsCompanyDetails = ({ errorCode, onHide }) => {
  let text;

  if (!errorCode) return null;

  switch (errorCode) {
    case ERRORS.ERR_WRONG_FORMAT:
      text = (
        <div>
          <p>
            The file you are trying to upload is not on supported format&nbsp;
            <strong>(.jpg, .png, .gif, .bmp).</strong>
          </p>
          <p>Please try again using one of the accepted formats.</p>
        </div>
      );
      return (
        <WrongFileFormatModal
          show
          text={text}
          onHide={onHide}
        />
      );
    case ERRORS.ERR_FILE_IS_TOO_LARGE:
      text = (
        <div>
          <p>
            The file you are trying to upload exceeds the&nbsp;
            <strong>5MB</strong>
            &nbsp;attachment limit.
          </p>
          <p>Please try again with a smaller file.</p>
        </div>
      );
      return (
        <TooBigFileModal
          show
          text={text}
          headerText="File Exceeds 5MB"
          onHide={onHide}
        />
      );

    default:
      return (
        <ErrorModal
          show
          onHide={onHide}
        />
      );
  }
};

ErrorModalsCompanyDetails.propTypes = {
  errorCode: PropTypes.number,
  onHide: PropTypes.func.isRequired,
};

export default ErrorModalsCompanyDetails;
