import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { getClosedRequestsStore } from '../../selectors';
import { getClosedReqList } from '../../reducers/requests/closedRequests';
import { fetchClosedRequests, searchForClosedRequests } from '../../actions/closedRequestsActions';
import { pushRedirect } from '../../actions/redirectActions';
import ClosedReqTable from '../ClosedReqTable';
import SearchBar from '../SearchBar';
import Loader from '../Loader';
import ModalLoader from '../ModalLoader';
import Container from '../Container';

const TableRow = styled(Row)`
  margin: 30px 0px 50px;
`;

export default function ClosedRequests() {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const closedRequestStore = useSelector(getClosedRequestsStore);
  const requestList = getClosedReqList(closedRequestStore);

  useEffect(() => {
    const searchString = queryString.parse(location.search).q;
    if (searchString) {
      dispatch(searchForClosedRequests(searchString));
    } else {
      dispatch(fetchClosedRequests());
    }
  }, [dispatch, location.search]);

  const onSearchHandler = (searchString) => {
    const query = queryString.parse(location.search);
    if (searchString !== query.q) {
      history.push({
        pathname: location.pathname,
        search: `?q=${encodeURIComponent(searchString)}`,
      });
    }
  };

  const {
    tableOptions: { page, countPerPage },
    isFetching, isSearching, isSearchingMode,
  } = closedRequestStore;

  const searchValue = queryString.parse(location.search).q;
  const options = {
    noDataText: 'No data',
    page,
    sizePerPage: countPerPage,
    pagination: true,
  };
  let content;

  if (isFetching) content = <Loader />;
  else if (isSearchingMode && requestList.length === 0) {
    content = 'There are no closed requests that match your search.';
  } else {
    content = (
      <ClosedReqTable
        data={requestList}
        goTo={(...params) => dispatch(pushRedirect(...params))}
        options={options}
      />
    );
  }

  return (
    <Container links={[{ 'Incoming requests': 'incoming' }, { 'Closed request': null }]}>
      <SearchBar
        onSearchHandler={onSearchHandler}
        placeholder="Request No. / User email / Company"
        defaultInputValue={searchValue || ''}
        searchOnTheFly={false}
      />
      <TableRow>
        {content}
      </TableRow>

      <ModalLoader show={isSearching} />
    </Container>
  );
}
