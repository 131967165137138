import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import TableStyles from '../../Table/TableStyles';
import Table from '../../Table/TableWithExpanding';
import header from '../../Table/HeaderWithSort';
import CellExpandable from '../../Table/CellExpandable';
import CellWithTitle from '../../Table/Cell';

import ExpandComponent from './ExpandComponent';
import dateFormat from '../../../utils/dateFormat';

const TableContainer = styled(TableStyles)`
  margin-bottom: 10px;
  margin-top: 20px; 
`;

export default function BounceEmailsTable({ data }) {
  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: CellExpandable,
        style: { width: '35px' },
      }, {
        Header: () => header('Code'),
        accessor: 'delivery_status_code',
        style: { width: '50px' },
      }, {
        Header: () => header('Severity'),
        accessor: 'event_data_severity',
        Cell: CellWithTitle,
        style: { width: '90px' },
      }, {
        Header: () => header('Sender'),
        accessor: 'envelope_sender',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Recipient'),
        accessor: 'event_data_recipient',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Created'),
        accessor: 'created_at',
        Cell: ({ value }) => dateFormat(value),
        style: { width: '180px' },
      },
    ], [],
  );
  const options = useMemo(
    () => ({
      renderRowSubComponent: ExpandComponent,
    }), [],
  );

  return (
    <TableContainer>
      <Table columns={columns} data={data} options={options} />
    </TableContainer>
  );
}

BounceEmailsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.shape({
    onRowClick: PropTypes.func,
  }),
};
