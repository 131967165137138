import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledLinkButton } from '../../StyledComponents';

const LinkButton = styled(StyledLinkButton)`
  padding: 0;
  font-size: 14px
  font-weight: bold;
  .chevron {
    margin-left: 5px;
    font-size: 12px;
  }
`;
const SellerItem = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const List = ({ list }) => {
  const [fullList, setFullList] = useState(false);
  const shownList = (list && fullList) ? list : list.slice(0, 5);
  return (
    <>
      {shownList.map((v) => {
        const seller = `${v.name}${v.email ? ` <${v.email}>` : ''}`;
        return (
          <SellerItem key={v.seller_id} title={seller}>
            {seller}
          </SellerItem>
        );
      })}
      {list && list.length > 5 && (
        <LinkButton variant="link" onClick={() => setFullList(!fullList)}>
          <span>{`${fullList ? 'Show less' : 'Show more'}`}</span>
          <FontAwesomeIcon icon={fullList ? 'chevron-up' : 'chevron-down'} className="chevron" />
        </LinkButton>
      )}
    </>
  );
};

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    seller_id: PropTypes.string,
    name: PropTypes.string,
    emails: PropTypes.string,
  })),
};

export default List;
