import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledLinkButton as LinkButton } from '../../StyledComponents';
import dateFormat from '../../../utils/dateFormat';
import { color } from '../../../constants';

export const DisconnectButton = styled(Button)`
  &&& {
    color: ${color.blue};
    font-size: 16px;
    text-decoration: none;
  }
  &&&:hover {
    color: ${color.grey};
  }
`;
const DisableText = styled.span`
  color: ${color.borderLightGrey};
`;

const Disconnect = ({
  date, isConnected, wasConnected, platformPay, onDisconnectClick, onMethodsConfClick,
}) => (
  <>
    {isConnected ? (
      <>
        <LinkButton variant="link" onClick={onMethodsConfClick} disabled={platformPay}>
          <FontAwesomeIcon icon="cog" />
          &nbsp;Methods
        </LinkButton>
        <br />
        <DisconnectButton variant="link" onClick={onDisconnectClick}>
          <FontAwesomeIcon icon="unlink" />
          &nbsp;Disconnect
        </DisconnectButton>
      </>
    ) : (wasConnected && (
      <DisableText>
        {date && `Disconnected On ${dateFormat(date, false)}`}
      </DisableText>
    ))}
  </>
);

Disconnect.propTypes = {
  date: PropTypes.string,
  isConnected: PropTypes.bool,
  wasConnected: PropTypes.bool,
  platformPay: PropTypes.bool,
  onDisconnectClick: PropTypes.func,
  onMethodsConfClick: PropTypes.func,
};

export default Disconnect;
