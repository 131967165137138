import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Table from '../Table/Table';
import header from '../Table/HeaderWithSort';
import TableStyles from '../Table/TableStyles';
import CellWithTitle from '../Table/Cell';

import dateFormat from '../../utils/dateFormat';
import { BASE_DECLINE_COMMENT, PUBLIC_URL } from '../../constants';
import { fetchClosedRequests } from '../../actions/closedRequestsActions';
import { pushRedirect } from '../../actions/redirectActions';
import { getClosedRequestsStore } from '../../selectors';
import { getClosedRequestsOfUser } from '../../reducers/requests/closedRequests';
import SimpleLoader from '../SimpleLoader';
import statusFormat from '../ClosedReqTable/statusFormat';
import { crrResult } from '../../constants/index';

const TableContainer = styled(TableStyles)`
  tbody tr td {
    cursor: pointer;
  }
`;

const format = (cell) => (
  <p>{cell && cell.slice(BASE_DECLINE_COMMENT.length)}</p>
);

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const HistoryTable = ({ userID, excludedIds }) => {
  const dispatch = useDispatch();

  const closedReqStore = useSelector(getClosedRequestsStore);
  const closedRequests = getClosedRequestsOfUser(userID, closedReqStore, excludedIds);
  const reqIsFetching = closedReqStore.isFetching;

  useEffect(() => {
    dispatch(fetchClosedRequests());
  }, [dispatch]);

  const options = useMemo(
    () => ({
      noDataContent: <NoDataContent isLoading={reqIsFetching} />,
    }), [reqIsFetching],
  );

  const columns = useMemo(
    () => [
      {
        Header: () => header('Request No.'),
        id: 'entityId',
        accessor: 'entityId',
        style: {
          width: '6%',
        },
      }, {
        Header: () => header('Request type'),
        accessor: 'type',
        style: {
          width: '10%',
        },
      }, {
        Header: () => header('Closed date'),
        accessor: 'updatedAt',
        Cell: ({ value }) => dateFormat(value),
        style: {
          width: '20%',
        },
      }, {
        Header: () => header('Approved / Decline'),
        accessor: 'result',
        Cell: ({ value }) => statusFormat(value, crrResult),
        style: {
          width: '8%',
          whiteSpace: 'normal',
          textAlign: 'center',
        },
      }, {
        Header: () => header('Handled by'),
        accessor: 'boUser',
        Cell: CellWithTitle,
        style: {
          width: '15%',
        },
      }, {
        Header: () => header('Comments'),
        accessor: 'comment',
        Cell: ({ value }) => format(value),
      },
    ], [],
  );

  const onRowClick = useCallback(
    (row) => {
      dispatch(pushRedirect(`${PUBLIC_URL}/incoming/closed/${row.entityId}`));
    },
    [dispatch],
  );

  return (
    <div>
      {'User\'s requests history'}
      <TableContainer>
        <Table
          columns={columns}
          data={closedRequests}
          options={options}
          onRowClick={onRowClick}
        />
      </TableContainer>
    </div>
  );
};

HistoryTable.propTypes = {
  userID: PropTypes.string,
  excludedIds: PropTypes.arrayOf(PropTypes.string),
};

const NoDataContent = (props) => {
  if (props.isLoading) {
    return (
      <LoaderWrapper>
        <SimpleLoader />
      </LoaderWrapper>
    );
  }
  return 'No history to display';
};

export default HistoryTable;
