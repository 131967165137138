import { ROUTING } from '../constants';
import history from '../history';

// eslint-disable-next-line arrow-parens
const redirect = store => next => action => { // eslint-disable-line no-unused-vars
  if (action.type === ROUTING) {
    history[action.payload.method](action.payload.nextUrl);
  }

  return next(action);
};

export default redirect;
