import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, FormGroup, FormLabel, Row, Col,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { changeOrdersStatus } from '../../../actions/networkOperationsActions/paymentsActions';
import { hideForm } from '../../../actions/networkOperationsActions/formManagementActions';

import NotificationsGroup from './NotificationGroup';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import FormActionButtons from '../../FormComponents/ActionButtons';
import { Title, FormButtonContainer, TextAreaContainer } from '../StyledComponents';
import { paymentsOrderStatus, WIRETRANSFER } from '../../../constants/operationCenter';
import { chatMsgType } from '../../../constants/index';

const SendMessageForm = ({ orderId, taskId }) => {
  const dispatch = useDispatch();

  const [orderStatus, setOrderStatus] = useState({
    status: null,
    comment: '',
    notification: {
      sellerNotification: {
        checked: false,
        disabled: false,
      },
      buyerNotification: {
        checked: false,
        disabled: false,
      },
    },
  });

  const onNotificationChange = (e) => {
    const newNotification = {
      ...orderStatus.notification,
      [e.target.name]: {
        ...orderStatus.notification[e.target.name],
        checked: e.target.checked,
      },
    };
    setOrderStatus({ ...orderStatus, notification: newNotification });
  };

  const onSendHandler = () => {
    let sendData = null;
    if (orderStatus.status.value === WIRETRANSFER) {
      sendData = {
        message: {
          text: orderStatus.comment,
        },
        ep_wt_complete: true,
        message_type: chatMsgType.EPLANE_PAYMENT_COMPLETE,
        notify_seller: orderStatus.notification.sellerNotification.checked,
        notify_buyer: orderStatus.notification.buyerNotification.checked,
        payment_task_id: taskId || null,
      };
    } else {
      sendData = {
        message: {
          text: orderStatus.comment,
        },
        message_type: orderStatus.status.value,
        notify_seller: orderStatus.notification.sellerNotification.checked,
        notify_buyer: orderStatus.notification.buyerNotification.checked,
        payment_task_id: taskId || null,
      };
    }

    dispatch(changeOrdersStatus(sendData, orderId));
    dispatch(hideForm());
  };

  const onCommentChange = (e) => {
    if (!e.target.value.startsWith(' ') && !e.target.value.startsWith('\n')) {
      setOrderStatus({ ...orderStatus, [e.target.name]: e.target.value });
    }
  };

  return (
    <Form>
      <Title>Change order status</Title>
      <p>Change order status as required:</p>

      <FormGroup>
        <FormLabel>
          Status:
        </FormLabel>
        <Row>
          <Col sm={12}>
            <Select
              classNamePrefix="react-select"
              value={orderStatus.status}
              name="status"
              placeholder="Select status..."
              onChange={(selectedOption, action) => setOrderStatus({ ...orderStatus, [action.name]: selectedOption })}
              options={paymentsOrderStatus}
            />
          </Col>
        </Row>
      </FormGroup>
      {orderStatus.status !== null && (
        <TextAreaContainer>
          <BaseFormGroup
            value={orderStatus.comment}
            as="textarea"
            placeholder="Type your message here..."
            name="comment"
            onChange={onCommentChange}
            rows="5"
          />
        </TextAreaContainer>
      )}
      <NotificationsGroup
        state={orderStatus.notification}
        onChange={onNotificationChange}
      />

      <hr />
      <FormButtonContainer>
        <FormActionButtons
          saveHandler={onSendHandler}
          cancelHandler={() => dispatch(hideForm())}
          confirmButtonText="Send"
          cancelButtonText="Cancel"
          saveProps={{ disabled: orderStatus.status === null }}
          width="100"
        />
      </FormButtonContainer>
    </Form>
  );
};

SendMessageForm.propTypes = {
  orderId: PropTypes.string,
  taskId: PropTypes.string,
};

export default SendMessageForm;
