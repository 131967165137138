import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';

import { confirmWireTransfer } from '../../../actions/networkOperationsActions/paymentsActions';
import { hideForm } from '../../../actions/networkOperationsActions/formManagementActions';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import NotificationsGroup from './NotificationGroup';
import FormActionButtons from '../../FormComponents/ActionButtons';
import {
  Title, FormButtonContainer, TextAreaContainer, TwoColumnContainer, LeftColumn, RightColumn, UnitContainer,
} from '../StyledComponents';
import { getSupportedCurrencies, getSellerBuyerInfo } from '../../../selectors/rfq';

const notification = {
  sellerNotification: {
    checked: false,
    disabled: true,
  },
  buyerNotification: {
    checked: true,
    disabled: true,
  },
};

const ConfirmWireTransferForm = ({ orderId, taskId }) => {
  const dispatch = useDispatch();
  const supportedCurrenciesOptions = getSupportedCurrencies();

  const { seller, buyer } = useSelector(getSellerBuyerInfo);

  const [confirmData, setConfirmData] = useState({
    amount: '',
    currency: supportedCurrenciesOptions[0],
    comment: '',
  });

  useEffect(() => {
    setConfirmData({
      ...confirmData,
      comment: getPrefilledText(buyer.company_name, seller.company_name, confirmData.amount, confirmData.currency.label),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmData.amount, confirmData.currency]);

  const onAmountChange = (e) => {
    if (e.target.value === '' || /^(([1-9]\d*\.?\d{0,3})|(0?\.?\d{0,3}))$/.test(e.target.value)) {
      setConfirmData({ ...confirmData, amount: e.target.value });
    }
  };

  const onSendHandler = () => {
    dispatch(confirmWireTransfer({
      message: {
        confirm_wt: {
          amount: confirmData.amount,
          currency: confirmData.currency.value,
        },
        text: confirmData.comment,
      },
      payment_task_id: taskId || null,
    }, orderId));
    dispatch(hideForm());
  };

  const onCommentChange = (e) => {
    if (!e.target.value.startsWith(' ') || !e.target.value.startsWith('\n')) {
      setConfirmData({ ...confirmData, [e.target.name]: e.target.value });
    }
  };

  return (
    <Form>
      <Title>Confirm wire transfer received</Title>
      <p>Enter the total amount that was received at ePlane&apos;s bank account:</p>

      <TwoColumnContainer>
        <LeftColumn>
          <BaseFormGroup
            value={confirmData.amount}
            labelText="Received amount:"
            name="amount"
            onChange={onAmountChange}
          />
        </LeftColumn>
        <RightColumn>
          <UnitContainer>
            <Select
              value={confirmData.currency}
              name="currency"
              maxLength="10"
              onChange={(selectedOption, action) => setConfirmData({ ...confirmData, [action.name]: selectedOption })}
              options={supportedCurrenciesOptions}
            />
          </UnitContainer>
        </RightColumn>
      </TwoColumnContainer>

      {confirmData.amount && (
        <TextAreaContainer>
          <BaseFormGroup
            value={confirmData.comment}
            as="textarea"
            placeholder="Add comment..."
            name="comment"
            onChange={onCommentChange}
            rows="5"
          />
        </TextAreaContainer>
      )}

      <NotificationsGroup state={notification} />
      <hr />
      <FormButtonContainer>
        <FormActionButtons
          saveHandler={onSendHandler}
          saveProps={{ disabled: !confirmData.amount || !confirmData.comment }}
          cancelHandler={() => dispatch(hideForm())}
          confirmButtonText="Send"
          width="100"
        />
      </FormButtonContainer>
    </Form>
  );
};

ConfirmWireTransferForm.propTypes = {
  orderId: PropTypes.string,
  taskId: PropTypes.string,
};

const getPrefilledText = (buyer, seller, amount, currency) => (
  `${buyer || 'Buyer'}'s wire transfer payment to ePlane was received for the amount of ${amount} ${currency}.
ePlane will continue the process and purchase the item from ${seller || 'Seller'}.
You will be notified about the purchase status soon.`);

export default ConfirmWireTransferForm;
