import axios from 'axios';

import {
  PN_LIST_GET,
  PN_LIST_GET_SUCCESS,
  PN_LIST_GET_FAIL,

  PN_LIST_UPLOADING_START,
  PN_LIST_UPLOADING_FINISH,
  PN_LIST_UPLOADING_PROGRESS_SET,
  PN_LIST_UPLOADING_PROGRESS_RESET,
  PN_LIST_UPLOADING_TERMINATE,
  PN_LIST_ERROR_REMOVE,
  PN_LIST_UPLOADING_ERROR_OCCURRED,

  AXIOS_CANCELLED_MESSAGE,
} from '../../constants';
import errorHandler from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startFetchingPnList = () => ({
  type: PN_LIST_GET,
});
const finishFetchingPnList = (pnList) => ({
  type: PN_LIST_GET_SUCCESS,
  payload: pnList,
});
export const setFetchingErrorCode = (error) => ({
  type: PN_LIST_GET_FAIL,
  payload: error,
});

export const errorRemove = () => ({
  type: PN_LIST_ERROR_REMOVE,
});

const startUploadingPnList = (source) => ({
  type: PN_LIST_UPLOADING_START,
  payload: source,
});
const finishUploadingPnList = (document) => ({
  type: PN_LIST_UPLOADING_FINISH,
  payload: document,
});
const setUploadingProgress = (percentOfUpload) => ({
  type: PN_LIST_UPLOADING_PROGRESS_SET,
  payload: percentOfUpload,
});

export const resetUploadingProgress = () => ({
  type: PN_LIST_UPLOADING_PROGRESS_RESET,
});

export const terminateUploadingProgress = () => ({
  type: PN_LIST_UPLOADING_TERMINATE,
});

export const setUploadingErrorCode = (errorCode) => ({
  type: PN_LIST_UPLOADING_ERROR_OCCURRED,
  payload: errorCode,
});

export const fetchPnList = (userId) => (dispatch) => {
  dispatch(startFetchingPnList());

  axios(`/rest/default/V1/eplane/backoffice/pnlists/${userId}/activeImport`).then((response) => {
    dispatch(finishFetchingPnList(response.data));
  }).catch((e) => {
    errorHandler(e.response, dispatch, setFetchingErrorCode, e);
  });
};

export const uploadPnDocument = (pnFile, userId, mode = 'overwrite') => (dispatch) => {
  const source = axios.CancelToken.source();
  dispatch(startUploadingPnList(source));

  const fd = new FormData(); // eslint-disable-line no-undef
  fd.append('user_id', userId);
  fd.append('file_xls', pnFile);
  fd.append('mode', mode);

  const options = {
    onUploadProgress: (e) => onUploadProgress(e, dispatch),
    cancelToken: source.token,
  };
  axios.post('/backoffice/users/uploadPnList', fd, options).then((response) => {
    dispatch(finishUploadingPnList(response.data));
    dispatch(updateComments());
  }).catch((e) => {
    uploadingErrorHandler(e, dispatch);
  });
};

const onUploadProgress = (progressEvent, dispatch) => {
  const { loaded, total } = progressEvent;
  dispatch(setUploadingProgress((loaded * 100) / total));
};

const uploadingErrorHandler = (e, dispatch) => {
  if (e.message === AXIOS_CANCELLED_MESSAGE) {
    console.warn(AXIOS_CANCELLED_MESSAGE); // eslint-disable-line no-console
    return;
  }
  errorHandler(e.response, dispatch, setUploadingErrorCode, e);
};
