import React, { useState, useEffect } from 'react';
import {
  Button, Form, Row, Col,
} from 'react-bootstrap';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  fetchTaskIgnoreRules, saveTaskIgnoreRule, deleteTaskIgnoreRule,
} from '../../../actions/networkOperationsActions/ignoreListActions';
import { showForm } from '../../../actions/networkOperationsActions/formManagementActions';
import { getIgnoreTaskRulesStore, getNocFormStore, getErrorsStore } from '../../../selectors';

import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import { TabContainer } from '../../StyledComponents';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';
import IgnoreRuleModal from './IgnoreRuleModal';
import { color } from '../../../constants';
import { taskTriggerType } from '../../../constants/index';
import { NOC_FORMS } from '../../../constants/operationCenter';

const Container = styled.div`
  align-items: baseline;
`;

export default function TasksManagement() {
  const dispatch = useDispatch();

  const [ignoreRule, setIgnoreRule] = useState({ trigger: '', action: taskTriggerType.IGNORE });

  const { ignoreRules, isFetching, isProcessing } = useSelector(getIgnoreTaskRulesStore);
  const { currentForm } = useSelector(getNocFormStore);
  const { errors } = useSelector(getErrorsStore);

  useEffect(() => {
    dispatch(fetchTaskIgnoreRules());
  }, [dispatch]);

  const onStringChange = (e, item) => {
    const { value } = e.target;
    setIgnoreRule(() => ({ ...item, trigger: value }));
  };

  const onRuleBlur = () => {
    if (ignoreRule.id && ignoreRule.trigger) {
      dispatch(saveTaskIgnoreRule(ignoreRule, ignoreRule.id));
      setIgnoreRule({ trigger: '', action: taskTriggerType.IGNORE });
    } else if (ignoreRule.id && !ignoreRule.trigger) {
      dispatch(deleteTaskIgnoreRule(ignoreRule.id));
      setIgnoreRule({ trigger: '', action: taskTriggerType.IGNORE });
    }
  };

  const onCheckboxChange = (e, v) => {
    const { checked } = e.target;
    const data = {
      ...v,
      action: checked ? taskTriggerType.NOQUOTE : taskTriggerType.IGNORE,
    };
    dispatch(saveTaskIgnoreRule(data, v.id));
  };

  if ((!ignoreRules || isFetching) && (errors === null || errors.length === 0)) return <Loader />;

  const ignoreList = ignoreRules?.map((v, i) => {
    const item = v.id !== ignoreRule.id ? v : ignoreRule;
    return (
      <Row key={v.id} as={Container}>
        <Form.Group as={Col} sm={1}>
          {`${i + 1}`}
        </Form.Group>
        <Form.Group as={Col} sm={5}>
          <BaseFormGroup
            value={item.trigger}
            placeholder="Type your ignore text here..."
            name="text_trigger"
            onChange={(e) => onStringChange(e, item)}
            onBlur={onRuleBlur}
            maxLength={255}
            rows="2"
          />
        </Form.Group>
        <Form.Group as={Col} sm={3}>
          <CheckboxStyled
            id={`no_quote_checkbox_${i}`}
            label="Send 'No quote'"
            checked={item.action === taskTriggerType.NOQUOTE}
            name="action"
            onChange={(e) => onCheckboxChange(e, item)}
          />
        </Form.Group>

        <Form.Group as={Col} sm={1}>
          <Button
            variant="light"
            onClick={() => dispatch(deleteTaskIgnoreRule(item.id))}
          >
            <FontAwesomeIcon icon="trash-alt" color={color.darkBirch} size="lg" />
          </Button>
        </Form.Group>
      </Row>
    );
  });

  return (
    <>
      <FormTitleSection title="Tasks Management" showEditButton={false} />
      <TabContainer>
        <p>
          Text messages from the &apos;ignore list&apos; do not open a task.
          <br />
          Text messages from the &apos;no quote list&apos; will not open
          a task and an ePlane Bot message with the text &quot;No quote.&quot; will be added to the chat.
        </p>
        <Row>
          <Form.Group as={Col} sm={6}>
            <Button onClick={() => dispatch(showForm({ type: NOC_FORMS.IGNORE_TASK }))} variant="success">
              <FontAwesomeIcon icon="plus" />
              &nbsp;Add Ignore Rule
            </Button>
          </Form.Group>
        </Row>
        {ignoreList}

      </TabContainer>
      <IgnoreRuleModal show={currentForm?.type === NOC_FORMS.IGNORE_TASK} />
      <ModalLoader show={isProcessing} />
    </>
  );
}
