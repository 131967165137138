import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WarningModal from '../../WarningModal';

const BodyContainer = styled.div`
  margin-top: 38px;
  text-align: center;
`;

const HeaderModal = styled.strong`
  font-size: 26px;
  letter-spacing: -0.2px;
  display: block;
`;

const Text = styled.div`
  font-size: 26px;
  letter-spacing: -0.2px;
  line-height: 1.35;
`;

export default function ConfirmLeaveModal(props) {
  const {
    onLeave,
    onCancel,
    ...other
  } = props;
  const body = (
    <BodyContainer>
      <HeaderModal>Are you sure you want to leave this page?</HeaderModal>
      <Text>Your changes will not be save.</Text>
    </BodyContainer>
  );
  const footer = (
    <div>
      <Button variant="primary" onClick={onLeave}>Leave This Page</Button>
      <Button onClick={onCancel}>Cancel</Button>
    </div>
  );
  return (
    <WarningModal body={body} footer={footer} onHide={onCancel} {...other} />
  );
}

ConfirmLeaveModal.propTypes = {
  onLeave: PropTypes.func,
  onCancel: PropTypes.func,
};
