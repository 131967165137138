import createErrorValidatorObj from '../../../utils/createErrorValidationObj';
import {
  DEFAULT_COUNTRY_VALUE_SELECT,
  DEFAULT_REGION_VALUE_SELECT,
} from '../../../constants';

const createNotEmptyValidator = (errorText) => (value) => (value ? null : createErrorValidatorObj(errorText));

const billingValidators = {
  street: createNotEmptyValidator('Please enter the street address'),
  city: createNotEmptyValidator('Please enter the city'),

  country_id: (value) => {
    if (!value || value === DEFAULT_COUNTRY_VALUE_SELECT) {
      return createErrorValidatorObj('Please select the country');
    }
    return null;
  },

  region_id: (value) => {
    if (value === DEFAULT_REGION_VALUE_SELECT) {
      return createErrorValidatorObj('Please select the state/province');
    }
    return null;
  },

  zip_code: createNotEmptyValidator('Please enter the zip code'),

  phone: (value) => {
    if (!value || value.length < 6 || value.length > 128) {
      return createErrorValidatorObj('Please enter a phone number');
    }
    return null;
  },
};

export default billingValidators;
