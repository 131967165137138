import {
  SET_SHOWN_FORM,
  HIDE_SHOWN_FORM,
  SET_TASKS_BAR,
  SET_HISTORY_BAR,
} from '../../constants';

export const showForm = (form) => ({
  type: SET_SHOWN_FORM,
  payload: form,
});

export const setTasksBar = (isShown) => ({
  type: SET_TASKS_BAR,
  payload: isShown,
});
export const setHistoryBar = (isShown) => ({
  type: SET_HISTORY_BAR,
  payload: isShown,
});

export const hideForm = () => ({ type: HIDE_SHOWN_FORM });
