import createErrorValidationObj from '../../../utils/createErrorValidationObj';

export default {
  category: (value, categories) => {
    const newValue = value && value.trim();
    if (!newValue) return createErrorValidationObj('Please enter category id');
    if (categories && categories.reduce((acc, v) => ((v.category.toUpperCase() === newValue.toUpperCase()) ? acc + 1 : acc), 0) > 1) {
      return createErrorValidationObj('The id is already used');
    }
    return null;
  },
  category_name: (value, categories) => {
    const newValue = value && value.trim();
    if (!newValue) return createErrorValidationObj('Please enter a category name');
    if (categories && categories.reduce((acc, v) => ((v.category_name === newValue) ? acc + 1 : acc), 0) > 1) {
      return createErrorValidationObj('The name is already used');
    }
    return null;
  },
  validateIdentifiers: (values, initValues) => {
    let validations = [];
    const changesCounter = initValues.reduce((acc, v) => (
      values.every((el) => (el.category.toUpperCase() !== v.category.toUpperCase())) ? acc + 1 : acc
    ), 0);

    if (changesCounter > 1) {
      validations = values.map((v, i) => {
        if (i < initValues.length) {
          return {
            category: (values[i].category.toUpperCase() !== initValues[i].category.toUpperCase())
              ? createErrorValidationObj('Only one identifier can be modified at once')
              : null,
            category_name: null,
          };
        }
        return { category: null, category_name: null };
      });
    }

    return validations;
  },
};
