import axios from 'axios';

import {
  INVENTORY_LIST_FETCH,
  INVENTORY_LIST_IS_FETCHED,
  INVENTORY_LIST_FAIL,
} from '../constants';
import { requestErrorHandler } from './requestErrorHandler';

const startFetchingInventoryList = () => ({
  type: INVENTORY_LIST_FETCH,
});
const finishFetchingInventoryList = (inventoryList) => ({
  type: INVENTORY_LIST_IS_FETCHED,
  payload: inventoryList,
});

export const inventoryFail = () => ({
  type: INVENTORY_LIST_FAIL,
});

export const fetchInventoryList = (nexPage) => (dispatch) => {
  dispatch(startFetchingInventoryList());

  const reqParams = {
    p: nexPage,
  };
  axios('/rest/V1/eplane/backoffice/inventory/importQueue', { params: reqParams }).then((response) => {
    dispatch(finishFetchingInventoryList(response?.data));
  }).catch((e) => {
    requestErrorHandler(e.response, dispatch, inventoryFail, e);
  });
};
