import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Actions from './Actions';
import ListNames from './ListNames';
import Table from '../../Table/CustomListsTable';

import EditRow from './EditRow';
import { getSellerCount, EmptyListsContent, SortButton } from './Helpers';

const CustomListTable = ({
  data,
  editedList,
  sellerOptions,
  sortOrder,

  onEditHandler,
  onRemoveHandler,
  onSaveHandler,
  onAddCustomSeller,
  onEditCancel,
  onNameChange,
  onSellerRemoveHandler,
  onSelectedSellerChange,
  onSortOrderChange,
}) => {
  let tableBody = null;
  if (data.length === 0) {
    tableBody = (
      <tr className="no-content-data">
        <td colSpan="5"><EmptyListsContent text="You have no custom lists" /></td>
      </tr>
    );
  } else {
    tableBody = (
      <>
        {data.map((v) => (
          editedList && v.list_id === editedList.listId
            ? (
              <EditRow
                key={v.list_id}
                list={editedList}
                sellerOptions={sellerOptions}
                onSave={onSaveHandler}
                onAddCustomSeller={onAddCustomSeller}
                onEditCancel={onEditCancel}
                onNameChange={onNameChange}
                onSellerRemoveHandler={onSellerRemoveHandler}
                onSelectedSellerChange={onSelectedSellerChange}
              />
            )
            : (
              <tr key={v.list_id}>
                <td title={v.name}>
                  {v.name}
                  <br />
                  {getSellerCount(v.sellers, v.external_sellers)}
                </td>
                <td>
                  <ListNames
                    list={[...v.sellers, ...v.external_sellers]}
                  />
                </td>
                <td title={v.updated_by}>{v.updated_by}</td>
                <td>{`${moment.utc(v.updated_on).format('MMM D, YYYY, HH:mm')}`}</td>
                <td>
                  <Actions
                    editMode={false}
                    onSaveHandler={onSaveHandler}
                    onCancelHandler={onEditCancel}
                    onEditHandler={() => onEditHandler(v.list_id)}
                    onRemoveHandler={() => onRemoveHandler(v.list_id)}
                  />
                </td>
              </tr>
            )
        ))}
      </>
    );
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>
            List Name
            <SortButton variant="link" name="name" onClick={onSortOrderChange}>
              {getSortIcon(sortOrder?.columnName === 'name' && sortOrder.order)}
            </SortButton>
          </th>
          <th>Sellers</th>
          <th>
            Updated By
            <SortButton variant="link" name="updated_by" onClick={onSortOrderChange}>
              {getSortIcon(sortOrder?.columnName === 'updated_by' && sortOrder.order)}
            </SortButton>
          </th>
          <th>
            Updated On (GMT)
            <SortButton variant="link" name="updated_on" onClick={onSortOrderChange}>
              {getSortIcon(sortOrder?.columnName === 'updated_on' && sortOrder.order)}
            </SortButton>
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {tableBody}
      </tbody>
    </Table>
  );
};

CustomListTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  editedList: PropTypes.shape(PropTypes.object),
  sellerOptions: PropTypes.arrayOf(PropTypes.object),
  sortOrder: PropTypes.shape({
    columnName: PropTypes.string,
    order: PropTypes.string,
  }),

  onEditHandler: PropTypes.func.isRequired,
  onRemoveHandler: PropTypes.func.isRequired,
  onSaveHandler: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  onAddCustomSeller: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onSellerRemoveHandler: PropTypes.func.isRequired,
  onSelectedSellerChange: PropTypes.func.isRequired,
  onSortOrderChange: PropTypes.func.isRequired,
};

const getSortIcon = (order) => {
  if (order === 'asc') return <FontAwesomeIcon icon="sort-up" />;
  if (order === 'desc') return <FontAwesomeIcon icon="sort-down" />;

  return <FontAwesomeIcon icon="sort" />;
};

export default CustomListTable;
