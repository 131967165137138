import React from 'react';
import styled from 'styled-components';
import {
  Form,
  Row,
  Col,
  InputGroup,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import renderValidation from '../renderHelpBlock';

const StyledFormControl = styled(Form.Control)`
  height: 38px;
  && {
    z-index: 0;
  }
`;
const InputCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  .wrapper {
    width: 100%;
  }
`;
const Addition = styled.div`
  padding-left: 5px;
  align-self: flex-start;
  min-width: 90px;
`;
const LabelCol = styled(Form.Label)`
  padding-right: 0;
  && {
    text-align: left;
  }
`;
const ChildrenContainer = styled.div`
  width: ${({ hasAddition }) => (hasAddition ? 'calc(100% - 90px)' : '100%')};
`;

const FormGroupHorizontal = ({
  id,
  helpText,
  labelText,
  validationState,
  addonText,
  inputRef,
  addition,
  children,
  ...other
}) => {
  let FormControl = StyledFormControl;
  if (other.as === 'select' || other.as === 'textarea') {
    FormControl = Form.Control;
  }

  let content = null;
  if (children) {
    content = <ChildrenContainer hasAddition={!!addition}>{children}</ChildrenContainer>;
  } else if (addonText) {
    content = (
      <div className="wrapper">
        <InputGroup>
          <FormControl
            {...other}
            ref={inputRef}
            isValid={validationState === 'success'}
            isInvalid={validationState === 'error'}
          />
          {addonText && (
            <InputGroup.Append>
              <InputGroup.Text>{addonText}</InputGroup.Text>
            </InputGroup.Append>
          )}
          {renderValidation(helpText, validationState)}
        </InputGroup>
      </div>
    );
  } else {
    content = (
      <div className="wrapper">
        <FormControl
          {...other}
          ref={inputRef}
          isValid={validationState === 'success'}
          isInvalid={validationState === 'error'}
        />
        {renderValidation(helpText, validationState)}
      </div>
    );
  }
  return (
    <Form.Group as={Row} controlId={id}>
      {labelText && <LabelCol column sm={3}>{labelText}</LabelCol>}
      <InputCol sm={9}>
        {content}
        {addition && <Addition>{addition}</Addition>}
      </InputCol>
    </Form.Group>
  );
};

FormGroupHorizontal.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helpText: PropTypes.string,
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  validationState: PropTypes.string,
  addonText: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.element }),
  ]),
  addition: PropTypes.element,
  children: PropTypes.element,
};

export default FormGroupHorizontal;
