import {
  MESSAGE_SEND,
  MESSAGE_SEND_SUCCESS,
  DOC_UPLOADING_PAYMENTS_PROGRESS_SET,
  DOC_UPLOADING_PAYMENTS_START,
  DOC_UPLOADING_PAYMENTS_FINISH,
  DOC_UPLOADING_PAYMENTS_TERMINATE,
  DOC_UPLOADING_PAYMENTS_REMOVE,

  CHANGE_ORDER_STATUS,
  CHANGE_ORDER_STATUS_SUCCESS,

  WIRE_TRANSFER_CONFIRM,
  WIRE_TRANSFER_CONFIRM_SUCCESS,

  EPLANE_PAYMENT_REPORT,
  EPLANE_PAYMENT_REPORT_SUCCESS,

  PAYMENT_REQUEST_ADD,
  PAYMENT_REQUEST_ADD_SUCCESS,

  NOC_PAYMENTS_FAIL,
  AXIOS_CANCELLED_MESSAGE,
} from '../../constants';

const initialState = {
  modifiedMessageId: null,

  document: null,
  uploadingProgress: null,
  source: null,
  isUploading: false,

  isSending: false,
  isProcessing: false,
};

export default function quoteReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case MESSAGE_SEND:
      return {
        ...state,
        isSending: true,
      };
    case MESSAGE_SEND_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case CHANGE_ORDER_STATUS:
      return {
        ...state,
        isSending: true,
      };
    case CHANGE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case WIRE_TRANSFER_CONFIRM:
      return {
        ...state,
        isSending: true,
      };
    case WIRE_TRANSFER_CONFIRM_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case EPLANE_PAYMENT_REPORT:
      return {
        ...state,
        isSending: true,
      };
    case EPLANE_PAYMENT_REPORT_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case PAYMENT_REQUEST_ADD:
      return {
        ...state,
        isSending: true,
      };
    case PAYMENT_REQUEST_ADD_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case NOC_PAYMENTS_FAIL:
      return {
        ...state,
        isSending: false,
        isProcessing: false,

        document: null,
        uploadingProgress: null,
        source: null,
        isUploading: false,
      };

    case DOC_UPLOADING_PAYMENTS_START:
      return {
        ...state,
        isUploading: true,
        uploadingProgress: null,
        source: payload,
      };
    case DOC_UPLOADING_PAYMENTS_FINISH:
      return {
        ...state,
        isUploading: false,
        document: payload?.files[0],
        source: false,
      };
    case DOC_UPLOADING_PAYMENTS_REMOVE:
      return {
        ...state,
        document: null,
      };
    case DOC_UPLOADING_PAYMENTS_PROGRESS_SET:
      return { ...state, uploadingProgress: payload };
    case DOC_UPLOADING_PAYMENTS_TERMINATE:
      if (state.source) {
        state.source.cancel(AXIOS_CANCELLED_MESSAGE);
      }
      return {
        ...state,
        uploadingProgress: null,
        isUploading: false,
        source: null,
      };
    default: return state;
  }
}
