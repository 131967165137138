import styled from 'styled-components';

const SimpleLoader = styled.div`
  @keyframes loading {
    to { transform: rotate(360deg); }
  }
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;

  border-top: 4px solid #fff;
  border-right: 4px solid #fff;
  border-bottom: 4px solid #777;
  border-left: 4px solid #777;

  animation: loading 1.2s infinite linear;
`;
export default SimpleLoader;
