import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TabContainer, StyledLinkButton as LinkButton } from '../../StyledComponents';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import { getNewsListStore, getErrorsStore } from '../../../selectors';
import {
  fetchFeaturedInList, uploadFeaturedIn, deleteSelectedFeaturedIn, reorderFeaturedIn,
} from '../../../actions/settings/newsFeedActions';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';
import PublishPost from './PublishPost';
import FeaturedInList from './FeaturedInList';

const OperationTitle = styled.p`
  font-weight: bold;
  padding-left: 10px;
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DevNews = () => {
  const dispatch = useDispatch();

  const { featuredIn, isFetching, isProcessing } = useSelector(getNewsListStore);
  const { errors } = useSelector(getErrorsStore);

  const [showUploadNewsModal, setUploadNewsModal] = useState(false);
  const [news, setNews] = useState(null);
  const [selectedNewsId, setSelectedNewsId] = useState(null);

  useEffect(() => {
    dispatch(fetchFeaturedInList());
  }, [dispatch]);

  useEffect(() => {
    setNews(featuredIn);
  }, [featuredIn]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const quotes = reorder(
      news,
      result.source.index,
      result.destination.index,
    );
    setNews(quotes);
  };

  const hideUploadNewsModal = () => {
    setUploadNewsModal(false);
    setSelectedNewsId(null);
  };

  const reorderNewsHandler = () => {
    dispatch(reorderFeaturedIn({
      items: news && news.map((v, i) => ({ item_id: v.item_id, position: (news.length - i - 1).toString() })),
    }));
  };

  const selectedNewsItem = featuredIn?.find((v) => v.item_id === selectedNewsId);
  const isOrderChanged = useMemo(
    () => news && news.length > 0 && featuredIn?.some((v, i) => v.item_id !== news[i].item_id),
    [news, featuredIn],
  );

  if (isFetching && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <div>
      <FormTitleSection title="Featured In" showEditButton={false} />
      <TabContainer>
        <Row>
          <Col sm={8}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <FeaturedInList items={news} onRowClickHandler={(id) => setSelectedNewsId(id)} />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <br />
          </Col>
          <Col sm={1} />
          <Col sm={3}>
            <OperationTitle>Featured In Management:</OperationTitle>
            <LinkButton variant="link" onClick={() => setUploadNewsModal(true)}>
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              Publish Featured In
            </LinkButton>
            <br />
            <LinkButton variant="link" onClick={reorderNewsHandler} disabled={!isOrderChanged}>
              <FontAwesomeIcon icon="list-ol" />
              &nbsp;
              Save Order
            </LinkButton>
          </Col>
        </Row>
      </TabContainer>

      <PublishPost
        selectedNewsItem={selectedNewsItem}
        isShown={showUploadNewsModal || !!selectedNewsId}
        onHide={hideUploadNewsModal}
        onUpload={(...args) => dispatch(uploadFeaturedIn(...args))}
        onDelete={(id) => dispatch(deleteSelectedFeaturedIn(id))}
      />
      <ModalLoader show={isProcessing} />
    </div>
  );
};

export default DevNews;
