import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, useRowSelect } from 'react-table';

import NoDataRow from './NoData';

const IndeterminateCheckbox = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

/* eslint-disable react/prop-types, no-shadow */
function ReactTable({
  columns,
  data = [],
  skipPageReset,
  selectedRows,
  resetSelection,
  onRowClick,
  onSelect,
  editCellValidation,
  updateTableData,
  noDataContent = 'There is no data to display',
  options,
  ...props
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    selectedFlatRows,
    // state: { selectedRowIds },
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      state: {
        selectedRowPaths: selectedRows,
      },
      autoResetPage: !skipPageReset,
      updateTableData,
      editCellValidation,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div style={{ textAlign: 'center' }}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          style: {
            width: '30px',
            textAlign: 'center',
          },
        },
        ...columns,
      ]);
    },
  );
  /* eslint-enable react/prop-types, no-shadow */

  useEffect(() => {
    toggleAllRowsSelected(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSelection]);

  useEffect(() => {
    if (onSelect) onSelect(selectedFlatRows.map((d) => d.original));
  }, [onSelect, selectedFlatRows]);

  const tbodyContent = data.length !== 0
    ? (
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} onClick={() => onRowClick && onRowClick(row.original)}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} title={cell.value}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    )
    : <NoDataRow colSpan={visibleColumns.length} className="no-content-data">{options?.noDataContent ?? noDataContent}</NoDataRow>;

  return (
    <table {...getTableProps()} {...props}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                style={column.style}
                {...column.getHeaderProps()}
                className="table-header-cell"
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      {tbodyContent}
    </table>
  );
}

ReactTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.shape({
    noDataContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  noDataContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  resetSelection: PropTypes.bool,
  onRowClick: PropTypes.func,
  editCellValidation: PropTypes.func,
};

export default ReactTable;
