import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Container } from 'react-bootstrap';
import Dropzone from 'react-dropzone';

import { filledDropezoneContent, dropezoneContent } from './dropezoneContent';
import { ButtonsWrapper, Button, Text as Title } from '../../Modals/styledComponents';

const Text = styled.p`
  text-align: center;
`;

export default function UploadPartsModal({ onUpload, onHide, ...other }) {
  const [uploadFile, setUploadFile] = useState([]);

  let uploadContent = null;
  if (uploadFile.length > 0) {
    uploadContent = filledDropezoneContent(uploadFile[0], () => setUploadFile([]));
  } else {
    uploadContent = dropezoneContent('Drag PN list here', '(.xls / .xlsx / .csv)');
  }

  const onConfirm = () => {
    onUpload(uploadFile);
    setUploadFile([]);
  };

  return (
    <Modal centered onHide={onHide} {...other}>
      <Modal.Body>
        <Title>Upload Watchlist</Title>
        <br />
        <Text>
          Drag and drop your part numbers list below.
        </Text>
        <Text>
          The parts will be added to your Watchlist and you will be notified by email
          when new matching items are added to ePlane&apos;s inventory.
        </Text>
        <Container>
          <Dropzone
            onDrop={(files) => setUploadFile([...files])}
            accept=".xls,.xlsx,.csv"
            onDropRejected={null}
            multiple={false}
            disableClick
          >
            {uploadContent}
          </Dropzone>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsWrapper>
          <Button
            variant="primary"
            onClick={onConfirm}
            disabled={uploadFile.length === 0}
          >
            Upload
          </Button>
          <Button variant="outline-secondary" onClick={onHide}>Cancel</Button>
        </ButtonsWrapper>
      </Modal.Footer>
    </Modal>
  );
}

UploadPartsModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};
