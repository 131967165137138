import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ToggleButton from '../RfqFilterPage/NotificationToggle';
import { color } from '../../../constants';

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FormContainer = styled.div`
  padding: 10px 10px;
  background-color: ${color.whiteSmoke};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, .1);
`;
const FormTitle = styled.p`
  margin-bottom: 5px;
  font-weight: 800;
  font-size: 16px;
`;
const ItemType = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  ${(props) => props.disabled && css`
    color: ${color.grey};
  `}
`;
const ItemDescription = styled.p`
  margin-bottom: 5px;
  font-weight: 100;
  font-size: 14px;
  color: ${color.grey};
`;
const ToggleButtonContainer = styled.div`
  margin-top: 10px;
  margin-right: 20px;
`;
export const HorizontalLine = styled.hr`
  margin: 5px 0;
  border-top: 2px solid ${color.lightGrey};
`;

const ToggleForm = ({
  data, title, onChange,
}) => {
  if (!data) return null;

  return (
    <FormContainer>
      <FormTitle>{title}</FormTitle>
      {data.map((v, i) => (
        <div key={i.toString()}>
          <ItemContainer>
            <div>
              <ItemType disabled={v.locked}>{v.type}</ItemType>
              <ItemDescription>{v.description}</ItemDescription>
            </div>
            <ToggleButtonContainer>
              <ToggleButton
                id={v.name}
                name={v.name}
                checked={v.enabled}
                disabled={v.locked}
                onNotificationChange={onChange}
              />
            </ToggleButtonContainer>
          </ItemContainer>
          {(i !== data.length - 1) && <HorizontalLine />}
        </div>
      ))}
    </FormContainer>
  );
};

ToggleForm.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
};

export default ToggleForm;
