import React from 'react';

import InternalErrorContainer from '../InternalErrorContainer';

const InternalErrorContent = () => (
  <InternalErrorContainer>
    Oops, something went wrong in this tab...
    <br />
    Please, try to navigate to another tab or&nbsp;
    <a href=".">reload</a>
    &nbsp;the page.
  </InternalErrorContainer>
);

export default InternalErrorContent;
