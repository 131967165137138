import { createSelector } from 'reselect';

import { getChatStore, getUserSitesStore } from './index';
import {
  supportedCurrencies, units, leadTimeUnits, condition as conditionConst, builtInQuoteOptions, tagType, traceTo, dealTypes,
} from '../constants/index';
import { OTHER_SELECT_PICKUP_KEY } from '../constants';

const tagOptions = [
  { value: tagType.FAA.id, label: tagType.FAA.name },
  { value: tagType.FAA_EASA.id, label: tagType.FAA_EASA.name },
  { value: tagType.EASA.id, label: tagType.EASA.name },
  { value: tagType.CAAC.id, label: tagType.CAAC.name },
  { value: tagType.COC.id, label: tagType.COC.name },
  { value: tagType.MATERIALS.id, label: tagType.MATERIALS.name },
];

export const getSupportedCurrencies = createSelector(
  [() => supportedCurrencies],
  (currencies) => Object.values(currencies).map((v) => ({ label: v, value: v })),
);

export const getUnits = createSelector(
  [() => units],
  (u) => Object.values(u).map((v) => ({ label: v.id, value: v.id })),
);

export const getTimeUnits = createSelector(
  [() => leadTimeUnits],
  (u) => Object.values(u).map((v) => ({ label: v.name, value: v.id })),
);

export const getConditions = createSelector(
  [() => conditionConst],
  (u) => Object.values(u).map((v) => ({ value: v.id, label: v.name })),
);

export const getTraceToOptions = createSelector(
  [() => traceTo],
  (trTo) => Object.values(trTo).map((v) => ({ label: v.name, value: v.name })),
);

export const getSites = createSelector(
  [getUserSitesStore],
  (u) => {
    const options = u?.sites?.filter((v) => v.site_type === 2)
      .map((v) => ({ value: v.site_id, label: `${v.name} - ${v.location}` }));
    if (options) {
      options.push({ value: OTHER_SELECT_PICKUP_KEY, label: 'Other' });
    }
    return options;
  }
);

export const getSellerBuyerInfo = createSelector(
  getChatStore,
  (u) => ({
    seller: u.order.seller ?? {},
    buyer: u.order.buyer ?? {},
  }),
);

export const getLastQuoteLBI = createSelector(
  getChatStore,
  (u) => {
    const msgWithLBI = u.messages.filter((msg) => msg?.quote?.low_bid_indicator);
    return msgWithLBI.length > 0
      ? msgWithLBI[msgWithLBI.length - 1].quote.low_bid_indicator
      : null;
  },
);

export const getQuoteOptions = createSelector(
  [
    getSupportedCurrencies,
    getUnits,
    getTimeUnits,
    getConditions,
    getSites,
    getTraceToOptions,
  ],
  (
    supportedCurrenciesOptions,
    unitsOptions,
    timeOptions,
    conditionOptions,
    siteOptions,
    traceToOptions,
  ) => ({
    supportedCurrenciesOptions,
    unitsOptions,
    timeOptions,
    builtInQuoteOptions,
    conditionOptions,
    siteOptions,
    traceToOptions,
    tagOptions,
  }),
);

export const getChatPartNumber = createSelector(
  getChatStore,
  (chatStore) => {
    let pn = null;
    const item = (chatStore?.order.items && chatStore?.order.items[0]);
    if (chatStore?.order.deal_type_list.includes(dealTypes.REPAIR.id)) {
      pn = item.product && item.product.p_n;
    } else {
      pn = item?.part?.p_n || item?.p_n;
    }
    return pn;
  },
);
