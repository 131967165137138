import Big from 'big.js';

const ROUND_UP = 3;

export const getTotalPrice = (price, qty, taxes) => {
  if (!price) return '';
  if (!qty) return price;
  const decPrice = Big(price);

  return decPrice.times(qty).plus(taxes || 0).round(3, ROUND_UP);
};

export const getReqPricePerUnit = (totalPrice, qty, taxes) => {
  if (!totalPrice) return '';
  if (!qty) return totalPrice;

  const decTotalPrice = Big(totalPrice);
  return decTotalPrice.minus(taxes || 0).div(qty).round(3, ROUND_UP);
};

export const getReqTotalPrice = (price, qty, taxes) => {
  if (!price) return '';
  if (!qty) return price;
  const decPrice = Big(price);

  return decPrice.times(qty).plus(taxes || 0).round(2, ROUND_UP);
};

export const getPricePerUnit = (totalPrice, qty, taxes) => {
  if (!totalPrice) return '';
  if (!qty) return totalPrice;

  const decTotalPrice = Big(totalPrice);
  return decTotalPrice.minus(taxes || 0).div(qty).round(2, ROUND_UP);
};
