import React from 'react';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import NormalA from '../A';
import * as consts from '../../constants';

const height = 30;

const TopbarWrapper = styled(Col)`
  height: ${(props) => props.height}px;
  background-color: ${consts.color.grey};
  padding-left: 0;
  padding-right: 0; 
  position: relative;
`;

const TitleWrapper = styled(Col)`
  && {
    height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-family: ${consts.font.normal};
  color: ${consts.color.white};
  text-transform: uppercase;
  line-height: ${(props) => props.height}px;
`;

const A = styled(NormalA)`
  color: ${consts.color.white};
  text-transform: uppercase;
  
  &:hover, &:active {
    color: ${consts.color.white};
  }
`;

class TopBar extends React.PureComponent {
  render() {
    const { links: linksProps } = this.props;
    const links = linksProps || [];
    const linksLine = [];

    links.forEach((link, index) => {
      const caption = Object.keys(link).shift();
      const url = link[caption] || null;

      linksLine.push(
        <span key={linksLine.length}>
          {url !== null ? <A key={caption} to={`${consts.PUBLIC_URL}/${url}`}>{caption}</A> : <span>{caption}</span>}
          {(index < links.length - 1) ? String.fromCharCode('160') + String.fromCharCode('0x2022') + String.fromCharCode('160') : ''}
        </span>,
      );
    });

    return (
      <TopbarWrapper sm={12} height={height}>
        <TitleWrapper sm={{ span: 10, offset: 1 }}>
          <Title height={height}>{linksLine}</Title>
        </TitleWrapper>
      </TopbarWrapper>
    );
  }
}

TopBar.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

export default TopBar;
