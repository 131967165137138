import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Img from '../../Img';
import { StyledLinkButton as LinkButton } from '../../StyledComponents';
import { FormSubTitleItem } from '../../FormComponents/TitleSection/index';
import CommissionModal from '../../Modals/PaymentModals/CommissionModal';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { paymentProviders } from '../../../constants/index';
import WireTransferImg from '../../../assets/wire-transfer-logo.png';

const Logo = styled(Img)`
  height: 45px;
`;
const StripeContainer = styled.div`
  display: flex;
  .provider-logo {
    flex: 1;
    text-align: center;
  }
  .provider-label {
    flex: 6;
    margin-top: 10px;
  }
  .provider-control {
    flex: 5;
  }
`;
const WireTransferContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  .provider-logo {
    flex: 1;
    text-align: center;
  }
  .provider-label {
    flex: 6;
  }
  .provider-control {
    flex: 5;
  }
`;
const Label = styled.div`
  margin-top: 15px;
`;

const conf = {
  [paymentProviders.STRIPE_BROKER]: {
    editModalTitle: 'Edit buyer\'s custom commission (Stripe)',
    setModalTitle: 'Set buyer\'s custom commission (Stripe)',
    removeModalTitle: 'Remove buyer\'s custom commission (Stripe)',
  },
  [paymentProviders.WIRETRANSFER_BROKER]: {
    editModalTitle: 'Edit buyer\'s custom commission (Wire)',
    setModalTitle: 'Set buyer\'s custom commission (Wire)',
    removeModalTitle: 'Remove buyer\'s custom commission (Wire)',
  },
};

const CustomCommissionForm = ({
  title, providers, saveCustomCommission, removeCustomCommission, disabled,
}) => {
  const [removeModal, setRemoveModal] = useState(null);
  const [fee, setFee] = useState(null);

  const saveProviderCommission = () => {
    saveCustomCommission({
      fee: fee.amount,
      provider_code: fee.code,
      currency: fee.currency,
      is_percent: fee.is_percent,
    });
    setFee(null);
  };

  const onCommissionEditClick = (code) => {
    const editedProvider = providers.find((v) => v.code === code);
    setFee({
      code,
      ...(editedProvider && (editedProvider.fee || editedProvider.default_fee)),
    });
  };

  const onCommissionRemoveClick = () => {
    removeCustomCommission(removeModal);
    setRemoveModal(null);
  };

  return (
    <>
      <FormSubTitleItem title={title} />
      <Label>
        <p>The commission ePlane charges from buyers, for transactions they pay directly to ePlane.</p>
        <p>Paying ePlane can be done through Stripe or through a wire transfer, each one of these options has it&apos;s own commission:</p>
      </Label>

      <StripeContainer>
        <div className="provider-logo"><FontAwesomeIcon icon={['fab', 'stripe']} size="3x" /></div>
        <div className="provider-label"><p>The ePlane commission charged when paying ePlane through Stripe:</p></div>
        <div className="provider-control">
          {providers.find((v) => v.code === paymentProviders.STRIPE_BROKER)?.fee
            ? (
              <>
                {`${providers.find((v) => v.code === paymentProviders.STRIPE_BROKER).fee.amount} %`}
                <LinkButton
                  variant="link"
                  name={paymentProviders.STRIPE_BROKER}
                  onClick={() => onCommissionEditClick(paymentProviders.STRIPE_BROKER)}
                  disabled={disabled}
                >
                  Edit&nbsp;
                  <FontAwesomeIcon icon="pencil-alt" />
                </LinkButton>
                <LinkButton
                  variant="link"
                  name={paymentProviders.STRIPE_BROKER}
                  onClick={(e) => setRemoveModal(e.target.name)}
                  disabled={disabled}
                >
                  Remove&nbsp;
                  <FontAwesomeIcon icon="trash-alt" />
                </LinkButton>
              </>
            ) : (
              <LinkButton
                variant="link"
                name={paymentProviders.STRIPE_BROKER}
                onClick={() => onCommissionEditClick(paymentProviders.STRIPE_BROKER)}
                disabled={disabled}
              >
                Set&nbsp;
                <FontAwesomeIcon icon="pencil-alt" />
              </LinkButton>
            )}
        </div>
      </StripeContainer>

      <WireTransferContainer>
        <div className="provider-logo"><Logo src={WireTransferImg} alt="Wire Transfer Logo" /></div>
        <div className="provider-label"><p>The ePlane commission charged when paying ePlane through a wire transfer:</p></div>
        <div className="provider-control">
          {providers.find((v) => v.code === paymentProviders.WIRETRANSFER_BROKER)?.fee
            ? (
              <>
                {`${providers.find((v) => v.code === paymentProviders.WIRETRANSFER_BROKER).fee.amount} %`}
                <LinkButton
                  variant="link"
                  name={paymentProviders.WIRETRANSFER_BROKER}
                  onClick={() => onCommissionEditClick(paymentProviders.WIRETRANSFER_BROKER)}
                  disabled={disabled}
                >
                  Edit&nbsp;
                  <FontAwesomeIcon icon="pencil-alt" />
                </LinkButton>
                <LinkButton
                  variant="link"
                  name={paymentProviders.WIRETRANSFER_BROKER}
                  onClick={(e) => setRemoveModal(e.target.name)}
                  disabled={disabled}
                >
                  Remove&nbsp;
                  <FontAwesomeIcon icon="trash-alt" />
                </LinkButton>
              </>
            ) : (
              <LinkButton
                variant="link"
                name={paymentProviders.WIRETRANSFER_BROKER}
                onClick={() => onCommissionEditClick(paymentProviders.WIRETRANSFER_BROKER)}
                disabled={disabled}
              >
                Set&nbsp;
                <FontAwesomeIcon icon="pencil-alt" />
              </LinkButton>
            )}
        </div>
      </WireTransferContainer>

      <CommissionModal
        title={fee && conf[fee.code]?.editModalTitle}
        text="Please make sure you supply the correct value:"
        fee={fee}
        onConfirm={saveProviderCommission}
        onCommissionChange={(e) => setFee({ ...fee, amount: e.target.value })}
        show={fee !== null}
        onHide={() => setFee(null)}
      />
      <ConfirmationModal
        show={!!removeModal}
        confirm={onCommissionRemoveClick}
        onHide={() => setRemoveModal(null)}
      >
        <h3>{removeModal && conf[removeModal]?.removeModalTitle}</h3>
        <br />
        <p>Are you sure you wish to remove this buyer&apos;s custom commission?</p>
      </ConfirmationModal>
    </>
  );
};

CustomCommissionForm.propTypes = {
  title: PropTypes.string,
  providers: PropTypes.arrayOf(PropTypes.shape({})),
  saveCustomCommission: PropTypes.func.isRequired,
  removeCustomCommission: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default CustomCommissionForm;
