import styled from 'styled-components';
import Sprite from '../../assets/sprite-common-elements.png';

export const FileIcon = styled.div`
  margin: 0 auto;
  background: url(${Sprite}) no-repeat top left;
  width: 22px;
  height: 26px;
  display: inline-block;
`;

export const PlaceholderText = styled.p`
  max-width: 90px;
  font-weight: 300;
  text-align: center;
  line-height: 0.9;
  margin: 4px auto 0;  
  font-size: 14px;
`;
