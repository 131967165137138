import React from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text, Button } from '../StyleComponents';
import { color } from '../../../constants';

const ModalTitle = styled(Text)`
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
`;
const ModalText = styled.div`
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
`;
const BottomText = styled(ModalText)`
  margin-top: 20px;
`;
const IconContainer = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export default function UploadingProgressModal({
  onConfirm, now, onTerminate, onHide, text, ...other
}) {
  let content = null;
  if (now < 100 && other.show) {
    content = (
      <>
        <ModalTitle>Please Wait...</ModalTitle>
        <ModalText>We are uploading your Parts Watchlist...</ModalText>
        <ProgressBar animated now={now} />
        <BottomText>
          Changed your mind?
          <Button variant="link" onClick={onTerminate}>Cancel upload</Button>
        </BottomText>
      </>
    );
  } else {
    content = (
      <>
        <ModalTitle>Upload Completed!</ModalTitle>
        <br />
        <ProgressBar now={now} />
        <IconContainer>
          <FontAwesomeIcon icon="check" color={`${color.iconGreen}`} size="2x" />
        </IconContainer>
        <ModalText>
          We are currently processing your Watchlist.
          <br />
          It will be available on your Parts Watchlist in a short while.
        </ModalText>
      </>
    );
  }

  return (
    <Modal centered onHide={onHide} {...other}>
      <Modal.Body>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onConfirm}>Ok</Button>
      </Modal.Footer>
    </Modal>
  );
}

UploadingProgressModal.propTypes = {
  text: PropTypes.string,
  now: PropTypes.number,
  onConfirm: PropTypes.func,
  onHide: PropTypes.func,
  onTerminate: PropTypes.func.isRequired,
};
