import {
  BUILTIN_QUOTE_SEND,
  BUILTIN_QUOTE_SEND_SUCCESS,
  BUILTIN_QUOTE_EDIT,
  BUILTIN_QUOTE_EDIT_SUCCESS,
  BUILTIN_QUOTE_REMOVE,
  BUILTIN_QUOTE_REMOVE_SUCCESS,
  BUILTIN_NOQUOTE_SEND,
  BUILTIN_NOQUOTE_SEND_SUCCESS,
  BUILTIN_QUOTE_MARK_PO,
  BUILTIN_QUOTE_MARK_PO_SUCCESS,
  BUILTIN_VALIDATE,
  BUILTIN_VALIDATE_SUCCESS,

  MESSAGE_SEND,
  MESSAGE_SEND_SUCCESS,
  CHANGE_ORDER_STATUS,
  CHANGE_ORDER_STATUS_SUCCESS,
  WIRE_TRANSFER_CONFIRM,
  WIRE_TRANSFER_CONFIRM_SUCCESS,
  EPLANE_PAYMENT_REPORT,
  EPLANE_PAYMENT_REPORT_SUCCESS,
  PAYMENT_REQUEST_ADD,
  PAYMENT_REQUEST_ADD_SUCCESS,

  TASKS_OPERATION_FAIL,
  BUILTIN_QUOTE_SCROLLED,
} from '../../constants';

const initialState = {
  modifiedMessageId: null,
  isSending: false,
  isRemoving: false,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case BUILTIN_QUOTE_SEND:
      return {
        ...state,
        isSending: true,
      };
    case BUILTIN_QUOTE_SEND_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case BUILTIN_NOQUOTE_SEND:
      return {
        ...state,
        isSending: true,
      };
    case BUILTIN_NOQUOTE_SEND_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case BUILTIN_VALIDATE:
      return {
        ...state,
        isSending: true,
      };
    case BUILTIN_VALIDATE_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case BUILTIN_QUOTE_EDIT:
      return {
        ...state,
        isSending: true,
      };
    case BUILTIN_QUOTE_EDIT_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case BUILTIN_QUOTE_MARK_PO:
      return {
        ...state,
        isSending: true,
      };
    case BUILTIN_QUOTE_MARK_PO_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case BUILTIN_QUOTE_REMOVE:
      return {
        ...state,
        isRemoving: true,
      };
    case BUILTIN_QUOTE_REMOVE_SUCCESS:
      return {
        ...state,
        isRemoving: false,
      };

    case MESSAGE_SEND:
      return {
        ...state,
        isSending: true,
      };
    case MESSAGE_SEND_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case CHANGE_ORDER_STATUS:
      return {
        ...state,
        isSending: true,
      };
    case CHANGE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case WIRE_TRANSFER_CONFIRM:
      return {
        ...state,
        isSending: true,
      };
    case WIRE_TRANSFER_CONFIRM_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case PAYMENT_REQUEST_ADD:
      return {
        ...state,
        isSending: true,
      };
    case PAYMENT_REQUEST_ADD_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case EPLANE_PAYMENT_REPORT:
      return {
        ...state,
        isSending: true,
      };
    case EPLANE_PAYMENT_REPORT_SUCCESS:
      return {
        ...state,
        modifiedMessageId: payload,
        isSending: false,
      };

    case TASKS_OPERATION_FAIL:
      return {
        ...state,
        isSending: false,
        isRemoving: false,
      };
    case BUILTIN_QUOTE_SCROLLED:
      return { ...state, modifiedMessageId: null };
    default: return state;
  }
};
