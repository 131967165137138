import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import Table from '../Table/Table';
import header from '../Table/HeaderWithSort';
import TableStyles from '../Table/TableStyles';
import CellWithTitle from '../Table/Cell';

import dateFormat from '../../utils/dateFormat';
import { PUBLIC_URL } from '../../constants';
import BaseFormGroup from '../FormComponents/BaseFormGroup';
import * as serverConstants from '../../constants/index';

const TableContainer = styled(TableStyles)`
  .table-header-cell {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: inset 0 0 #ddd, 0 -1px #ddd;
  }

  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  tbody tr td {
    cursor: pointer;
  }
`;
const UpperContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & label.control-label {
    margin-right: 5px;
  }

  & form {
    margin-bottom: 5px;
  }
`;

export default function OpenReqTable({
  title, data, goTo, noDataContent, status, onStatusChange,
}) {
  const noDataText = `There are no incoming requests with "${getStatusNameById(status, serverConstants) || 'All'}" status`;

  const onRowClick = useCallback(
    (row) => {
      goTo(`${PUBLIC_URL}/incoming/${row.entityId}`);
    },
    [goTo],
  );

  const columns = useMemo(
    () => [
      {
        Header: () => header('Request No.'),
        id: 'entityId',
        accessor: 'entityId',
        style: {
          width: '100px',
        },
      }, {
        Header: () => header('Status'),
        accessor: 'result',
        Cell: ({ value }) => statusFormat(value, serverConstants.crrResult),
        style: {
          width: '100px',
        },
      }, {
        Header: () => header('Type'),
        accessor: 'type',
        Cell: CellWithTitle,
        style: {
          width: '200px',
        },
      }, {
        Header: () => header('Date of request'),
        accessor: 'createdAt',
        Cell: ({ value }) => dateFormat(value),
        style: {
          width: '180',
        },
      }, {
        Header: () => header('Open for (days, hours)'),
        accessor: 'openFor',
        Cell: CellWithTitle,
        style: {
          width: '170px',
        },
      }, {
        Header: () => header('User'),
        accessor: 'userName',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Email'),
        accessor: 'userEmail',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Company'),
        accessor: 'companyName',
        Cell: CellWithTitle,
      },
    ], [],
  );

  if (!data.length && status === 'open') return noDataContent;

  return (
    <div>
      <Row>
        <Col sm={12}>
          <UpperContentWrapper>
            {title}
            <BaseFormGroup
              labelText="Status:"
              value={status}
              onChange={onStatusChange}
              options={getStatusOptionList(serverConstants.crrCompleteStatus)}
              as="select"
              data-testid="select_status"
            />
          </UpperContentWrapper>
        </Col>
      </Row>

      <TableContainer>
        <Table
          columns={columns}
          data={data}
          noDataContent={noDataText}
          onRowClick={onRowClick}
        />
      </TableContainer>
    </div>
  );
}

OpenReqTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array]),
  status: PropTypes.string,
  noDataContent: PropTypes.element,
  goTo: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

const statusFormat = (cell, crrResultConst) => {
  switch (cell) {
    case crrResultConst.INPROGRESS.id: return 'In Progress';
    case crrResultConst.ONHOLD.id: return 'On Hold';
    case crrResultConst.PENDING.id: return 'Open';
    default:
      // eslint-disable-next-line no-console
      console.error('Unexpected crrResult value of open Request: ', cell);
      return '';
  }
};

const getStatusOptionList = ({
  INPROGRESS,
  ONHOLD,
  PENDING,
  OPEN,
}) => ([
  [OPEN, 'All'],
  [PENDING, 'Open'],
  [INPROGRESS, 'In Progress'],
  [ONHOLD, 'On Hold'],
]);

const getStatusNameById = (id, constants) => {
  if (!id || !constants) return null;
  const statuses = constants.crrCompleteStatus;
  switch (id) {
    case statuses.PENDING: return 'Open';
    case statuses.INPROGRESS: return 'In Progress';
    case statuses.ONHOLD: return 'On Hold';
    default: return null;
  }
};
