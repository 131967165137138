import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ButtonsWrapper, Button, Text } from '../styledComponents';

const Body = styled(Modal.Body)`
  text-align: center;
  margin-bottom: 20px;
`;

const RemoveModal = ({
  onHide,
  onConfirm,
  companyName,
  ...other
}) => (
  <Modal centered onHide={onHide} {...other}>
    <Body>
      <Text>Remove custom commission</Text>
      <br />
      <p>
        Are you sure you wish to remove the custom commission for&nbsp;
        {companyName}
        ?
      </p>
    </Body>
    <Modal.Footer>
      <ButtonsWrapper>
        <Button variant="primary" onClick={onConfirm}>Remove</Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
      </ButtonsWrapper>
    </Modal.Footer>
  </Modal>
);

RemoveModal.propTypes = {
  show: PropTypes.bool,
  companyName: PropTypes.string,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default RemoveModal;
