import React, {
  useRef, useState, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';

import {
  getCustomSellersListsStore, getUserSettingsStore, getErrorsStore, getCompanyListStore,
} from '../../../selectors';
import { getProActiveState } from '../../../selectors/pro';
import {
  fetchCustomSellersLists, saveSellersList, removeSellersList, fetchBlockedApprovedSellers,
} from '../../../actions/companiesManagement/sellersListsActions';
import { fetchCompanyList } from '../../../actions/companyListActions';
import { fetchUserSettings } from '../../../actions/companiesManagement/userSettingsActions';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import CustomListTable from './CustomListsTable';
import SellerListForm from './SellersListForm';
import { TabContainer } from '../../StyledComponents';
import AddSellerModal from '../../Modals/SellersListsModal/AddSellerModal';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';
import { sellersListTypes } from '../../../constants/index';
import { listNameValidator, editListNameValidator } from './validators';
import { color } from '../../../constants';

const CustomListsPage = () => {
  const sortOrder = useRef(null);

  const dispatch = useDispatch();
  const { userId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const {
    approvedBlockedList,
    items,
    isFetching: isFetchingLists,
    isProcessing: isProcessingLists,
  } = useSelector(getCustomSellersListsStore);
  const { companies } = useSelector(getCompanyListStore);
  const { data: userData, isFetching: isUserSettingsFetching } = useSelector(getUserSettingsStore);
  const isProActive = useSelector(getProActiveState);
  const { errors } = useSelector(getErrorsStore);

  const [unregisteredSeller, setUnregisteredSeller] = useState({ name: '', email: '' });
  const [newList, setNewList] = useState({
    listName: '',
    selectedSeller: null,
    selectedSellerList: [],
    nameValidation: null,
    sellerListValidation: null,
  });
  const [editedList, setEditedList] = useState(null);
  const [lists, setLists] = useState([]);

  const [showModals, setModals] = useState({
    showAddSellerModal: false,
    showRemoveModal: false,
    showConflictModal: false,
    showAddSellerWarningModal: null,
  });

  const sellerOptions = useMemo(
    () => companies && companies.map((v) => {
      const isBlocked = approvedBlockedList?.type === sellersListTypes.APPROVED
        ? approvedBlockedList?.sellers?.every((c) => c.seller_id !== v.company_id)
        : approvedBlockedList?.sellers?.some((c) => c.seller_id === v.company_id);

      return {
        value: v.company_id,
        label: v.company_name,
        approvedList: approvedBlockedList?.type === sellersListTypes.APPROVED,
        isBlocked,
      };
    }).filter((v) => newList.selectedSellerList.every(((el) => el.value !== v.value))),
    [companies, newList.selectedSellerList, approvedBlockedList],
  );

  const listNames = useMemo(
    () => items?.map((v) => v.name),
    [items],
  );

  useEffect(() => {
    dispatch(fetchCustomSellersLists(userId, { sortBy: '-updated_on' }));
    dispatch(fetchCompanyList());
    dispatch(fetchUserSettings(userId));
    dispatch(fetchBlockedApprovedSellers(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (location.search === '') {
      dispatch(fetchCustomSellersLists(userId, { sortBy: '-updated_on' }));
    } else {
      const currQuery = queryString.parse(location.search);
      sortOrder.current = {
        columnName: currQuery?.sortBy.replace(/-/g, ''),
        order: currQuery?.sortBy.startsWith('-') ? 'asc' : 'desc',
      };
      dispatch(fetchCustomSellersLists(userId, currQuery));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isProcessingLists, location.search]);

  useEffect(() => {
    setLists(items);
  }, [items]);

  const onEditList = (id) => {
    const item = items.find((v) => v.list_id === id);
    const sellers = item.sellers.map((v) => ({ label: v.name, value: v.seller_id }));
    const externalSellers = item.external_sellers.map((v) => ({
      label: v.name,
      value: v.seller_id,
      email: v.email,
    }));

    setEditedList({
      listId: item.list_id,
      listName: item.name,
      selectedSeller: null,
      selectedSellerList: [...sellers, ...externalSellers],
      nameValidation: null,
    });
  };

  const onRemoveList = () => {
    dispatch(removeSellersList(showModals.showRemoveModal, userId));
    setModals({ ...showModals, showRemoveModal: false });
  };

  const saveValidation = () => {
    const nameValidation = listNameValidator(newList.listName, listNames);
    const sellerListValidation = (newList.selectedSeller === null)
      && (newList.selectedSellerList === null || newList.selectedSellerList.length === 0);

    if (nameValidation || sellerListValidation) {
      setNewList((prev) => ({
        ...prev,
        nameValidation,
        sellerListValidation,
      }));
      return;
    }

    const warningCounter = newList.selectedSellerList.reduce((acc, seller) => (seller.isBlocked ? acc + 1 : acc), 0);
    if (newList?.selectedSellerList?.length > 0 && warningCounter === 1) {
      setModals({
        ...showModals,
        showAddSellerWarningModal: {
          shown: true,
          data: newList.selectedSellerList.find((seller) => seller.isBlocked),
        },
      });
    } else if (newList?.selectedSellerList?.length > 0 && warningCounter > 1) {
      setModals({ ...showModals, showConflictModal: true });
    } else {
      onSaveList();
    }
  };

  const onSaveList = () => {
    setNewList({
      listName: '',
      selectedSeller: null,
      selectedSellerList: [],
      nameValidation: null,
      sellerListValidation: null,
    });
    setModals({ ...showModals, showConflictModal: false, showAddSellerWarningModal: null });

    let sellers = null;
    if (newList.selectedSellerList && newList.selectedSellerList.length > 0) {
      sellers = newList.selectedSellerList.map((v) => (
        v.email
          ? { name: v.label, email: v.email }
          : { seller_id: v.value }
      ));
    } else {
      sellers = newList.selectedSeller.email
        ? [{ name: newList.selectedSeller.label, email: newList.selectedSeller.email }]
        : [{ seller_id: newList.selectedSeller.value }];
    }

    const data = {
      user_id: userId,
      list_type: sellersListTypes.CUSTOM,
      list_name: newList.listName,
      sellers,
    };
    dispatch(saveSellersList(data));
  };

  const onUpdateList = () => {
    setEditedList(null);

    let sellers = null;
    if (editedList.selectedSellerList && editedList.selectedSellerList.length > 0) {
      sellers = editedList.selectedSellerList.map((v) => (
        v.email
          ? { name: v.label, email: v.email }
          : { seller_id: v.value }
      ));
    } else {
      sellers = editedList.selectedSeller.email
        ? [{ name: editedList.selectedSeller.label, email: editedList.selectedSeller.email }]
        : [{ seller_id: editedList.selectedSeller.value }];
    }

    const data = {
      user_id: userId,
      list_id: editedList.listId,
      list_name: editedList.listName,
      sellers,
    };
    dispatch(saveSellersList(data, true));
  };

  const onAddToListHandler = (selectedOption) => {
    if (!selectedOption || selectedOption.length === 0) return;
    setNewList({
      ...newList,
      selectedSeller: null,
      selectedSellerList: [...newList.selectedSellerList, selectedOption],
      sellerListValidation: null,
    });
  };

  const onRemoveFromListHandler = (id) => {
    const sellerListValidation = (newList.selectedSeller === null)
      && (newList.selectedSellerList === null || newList.selectedSellerList.length === 0);
    setNewList({
      ...newList,
      selectedSellerList: newList.selectedSellerList.filter((v) => v.value !== id),
      sellerListValidation,
    });
  };

  const onAddToEditListHandler = (selectedOption) => {
    if (!selectedOption || selectedOption.length === 0) return;
    setEditedList({
      ...editedList,
      selectedSeller: null,
      selectedSellerList: [...editedList.selectedSellerList, selectedOption],
    });
  };

  const onRemoveFromEditedListHandler = (id) => {
    setEditedList({
      ...editedList,
      selectedSellerList: editedList.selectedSellerList.filter((v) => v.value !== id),
    });
  };

  const onUnregisteredSellerChange = (e) => {
    setUnregisteredSeller({ ...unregisteredSeller, [e.target.name]: e.target.value });
  };

  const onUnregisteredSellerAdd = (name) => {
    setUnregisteredSeller({ ...unregisteredSeller, name });
    setModals({ ...showModals, showAddSellerModal: true });
  };

  const onUnregisteredSellerAddConfirm = () => {
    const addedItem = {
      label: unregisteredSeller.name,
      value: unregisteredSeller.email,
      email: unregisteredSeller.email,
    };
    if (editedList) {
      setEditedList({
        ...editedList,
        selectedSellerList: [...editedList.selectedSellerList, addedItem],
      });
    } else {
      setNewList({
        ...newList,
        selectedSellerList: [...newList.selectedSellerList, addedItem],
        sellerListValidation: null,
      });
    }
    setModals({ ...showModals, showAddSellerModal: false });
    setUnregisteredSeller({ name: '', email: '' });
  };

  const onHideAddCustomSellerModal = () => {
    setModals({ ...showModals, showAddSellerModal: false });
    setUnregisteredSeller({ name: '', email: '' });
  };

  const onEditNameChange = (e) => {
    const currentName = items.find((v) => editedList.listId === v.list_id)?.name;
    setEditedList({
      ...editedList,
      listName: e.target.value,
      nameValidation: editListNameValidator(e.target.value, currentName, listNames),
    });
  };

  const onNewNameChange = (e) => {
    setNewList({
      ...newList,
      listName: e.target.value,
      nameValidation: listNameValidator(e.target.value, listNames),
    });
  };

  const onSortOrderChange = (e) => {
    const { sortBy } = queryString.parse(location.search);
    sortOrder.current = {
      columnName: e.target.name,
      order: (sortBy && sortBy.startsWith('-')) ? 'asc' : 'desc',
    };

    history.push({
      pathname: location.pathname,
      search: `sortBy=${sortOrder.current.order === 'desc' ? '-' : ''}${sortOrder.current.columnName}`,
    });
  };

  if ((!items) && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <>
      <FormTitleSection title="Seller Lists Management" showEditButton={false} />
      <TabContainer>
        <p><b>Custom Lists</b></p>
        {userData && !(userData.autopilot_enabled && (userData.sellers_lists_enabled || isProActive)) ? (
          <p>Feature is available for companies with enabled autopilot and seller lists or activated Pro Plan.</p>
        ) : (
          <>
            <p>
              Create custom lists of manually selected sellers that will receive RFQs from your company.
              <br />
              A seller can be listed in multiple lists.
            </p>
            <br />

            <SellerListForm
              newList={newList}
              sellerOptions={sellerOptions}
              onAddCustomSellerHandler={onUnregisteredSellerAdd}
              onNewListSaveHandler={saveValidation}
              onSellerRemoveHandler={onRemoveFromListHandler}
              onSelectedSellerChange={onAddToListHandler}
              onListNameChange={onNewNameChange}
            />
            <br />

            <CustomListTable
              data={lists || []}
              editedList={editedList}
              sellerOptions={sellerOptions}
              sortOrder={sortOrder.current}
              onEditHandler={onEditList}
              onRemoveHandler={(id) => setModals({ ...showModals, showRemoveModal: id })}
              onSaveHandler={onUpdateList}
              onEditCancel={() => setEditedList(null)}
              onAddCustomSeller={onUnregisteredSellerAdd}
              onNameChange={onEditNameChange}
              onSellerRemoveHandler={onRemoveFromEditedListHandler}
              onSortOrderChange={onSortOrderChange}
              onSelectedSellerChange={onAddToEditListHandler}
            />
          </>
        )}
      </TabContainer>

      <AddSellerModal
        unregisteredSeller={unregisteredSeller}
        onChange={onUnregisteredSellerChange}
        show={showModals.showAddSellerModal}
        onConfirm={onUnregisteredSellerAddConfirm}
        onHide={onHideAddCustomSellerModal}
      />

      <ConfirmationModal
        show={!!showModals.showRemoveModal}
        confirm={onRemoveList}
        onHide={() => setModals({ ...showModals, showRemoveModal: false })}
        confirmBtnText="I'm sure"
        cancelBtnText="Cancel"
      >
        <>
          <FontAwesomeIcon icon="exclamation-circle" color={`${color.goldenTainoi}`} size="2x" />
          <h3>Are you sure you wish to remove this list?</h3>
        </>
      </ConfirmationModal>

      <ConfirmationModal
        show={showModals.showConflictModal}
        confirm={onSaveList}
        onHide={() => setModals({ ...showModals, showConflictModal: false })}
        confirmBtnText="I'm sure"
        cancelBtnText="Cancel"
      >
        <>
          <FontAwesomeIcon icon="exclamation-circle" color={`${color.goldenTainoi}`} size="2x" />
          <br />
          <br />
          <p>
            {`Some sellers are
            ${approvedBlockedList?.type === sellersListTypes.APPROVED ? 'not listed in your approved' : 'listed in your blocked'}
             sellers.
            Adding these sellers to a custom list will
            ${approvedBlockedList?.type === sellersListTypes.APPROVED
              ? 'add them to approved'
              : 'remove them from blocked'}
                seller lists.`}
          </p>
          <p>Are you sure you want to continue?</p>
        </>
      </ConfirmationModal>

      <ConfirmationModal
        show={showModals.showAddSellerWarningModal !== null}
        confirm={onSaveList}
        onHide={() => setModals({ ...showModals, showAddSellerWarningModal: null })}
        confirmBtnText="I'm sure"
        cancelBtnText="Cancel"
      >
        <>
          <FontAwesomeIcon icon="exclamation-circle" color={`${color.goldenTainoi}`} size="2x" />
          <h3>
            Are you sure you wish to add&nbsp;
            {showModals.showAddSellerWarningModal?.data.label}
            &nbsp;to this list?
          </h3>
          <p>
            {showModals.showAddSellerWarningModal?.data.label}
            {
            `${approvedBlockedList?.type === sellersListTypes.APPROVED
              ? ' is not listed in your approved sellers. Adding it to a custom list will also add it to your approved sellers.'
              : ' is listed in your blocked sellers. Adding it to custom list, will remove it from blocked sellers.'}`
            }
          </p>
        </>
      </ConfirmationModal>

      <ModalLoader show={isFetchingLists || isProcessingLists || isUserSettingsFetching} />
    </>
  );
};

export default CustomListsPage;
