import {
  BANK_ACCOUNT_GET,
  BANK_ACCOUNT_GET_SUCCESS,
  BANK_ACCOUNT_SAVE,
  BANK_ACCOUNT_SAVE_SUCCESS,

  BANK_ACCOUNT_FAIL,
  BANK_ACCOUNT_RESET,
} from '../../constants';

const initialState = {
  bankAccount: null,
  isFetching: false,
  isProcessing: false,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case BANK_ACCOUNT_GET:
      return {
        ...state,
        isFetching: true,
      };
    case BANK_ACCOUNT_GET_SUCCESS:
      return {
        ...state,
        bankAccount: payload,
        isFetching: false,
      };

    case BANK_ACCOUNT_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case BANK_ACCOUNT_SAVE_SUCCESS:
      return {
        ...state,
        bankAccount: payload,
        isProcessing: false,
      };

    case BANK_ACCOUNT_FAIL:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
      };
    case BANK_ACCOUNT_RESET:
      return {
        ...initialState,
      };
    default: return state;
  }
};
