import React from 'react';
import styled from 'styled-components';

import Img from '../Img';
import loadingAnimation from '../../assets/loading-animation-v2.gif';

const loader = {
  width: 251,
  height: 61,
};

const LoaderAnimation = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -${(props) => (props.width || loader.width) / 2}px;
  margin-top: -${(props) => (props.height || loader.height) / 2}px;
  
  img: {
    display: block;
    width: ${(props) => props.width || loader.width}px;
    height: ${(props) => props.height || loader.height}px;
  }
`;

const Loader = (props) => (
  <LoaderAnimation {...props}>
    <Img src={loadingAnimation} alt="ePlane Animation" />
  </LoaderAnimation>
);

export default Loader;
