import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import { TabContainer, StyledLinkButton as LinkButton } from '../../StyledComponents';
import { fetchProviders, saveProviders } from '../../../actions/settings/paymentProviders';
import { getPaymentProviders, getErrorsStore } from '../../../selectors';

import Img from '../../Img';
import FormTitleSection, { FormSubTitleItem } from '../../FormComponents/TitleSection/index';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';
import CommissionModal from '../../Modals/PaymentModals/CommissionModal';

import StripeLogoImg from '../../../assets/stripe-logo.svg';
import WireTransferImg from '../../../assets/wire-transfer-logo.png';
import { color } from '../../../constants';
import { paymentProviders } from '../../../constants/index';

const StripeLogo = styled(Img)`
  height: 25px;
`;
const PaymentProvidersTable = styled.table`
  margin-top: 20px;
  td {
    min-width: 90px;
    text-align: center;
    vertical-align: middle;
  }
`;
const HorizontalLine = styled.hr`
  border-top: 2px solid ${(props) => props.color || color.lightGrey};
`;
const Logo = styled(Img)`
  height: 45px;
`;
const StripeContainer = styled.div`
  display: flex;
  .provider-logo {
    flex: 1;
    text-align: center;
  }
  .provider-label {
    flex: 6;
    margin-top: 10px;
  }
  .provider-control {
    flex: 5;
  }
`;
const WireTransferContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  .provider-logo {
    flex: 1;
    text-align: center;
  }
  .provider-label {
    flex: 6;
  }
  .provider-control {
    flex: 5;
  }
`;
const Label = styled.div`
  margin-top: 15px;
`;

const conf = {
  [paymentProviders.STRIPE]: {
    editModalTitle: 'Edit seller\'s commission for (Stripe)',
    editModalText: 'Please make sure you supply the correct value for ePlane commission for Stripe\'s transactions:',
  },
  [paymentProviders.STRIPE_BROKER]: {
    editModalTitle: 'Edit buyer\'s commission (Stripe)',
    editModalText: 'Please make sure you supply the correct value for transactions paid directly to ePlane through Stripe:',
  },
  [paymentProviders.WIRETRANSFER_BROKER]: {
    editModalTitle: 'Edit buyer\'s commission (Wire)',
    editModalText: 'Please make sure you supply the correct value for transactions paid directly to ePlane through a wire transfer:',
  },
};

const CommissionsPage = () => {
  const [fee, setFee] = useState(null);

  const dispatch = useDispatch();
  const {
    providers, isFetching, isProcessing,
  } = useSelector(getPaymentProviders);
  const { errors } = useSelector(getErrorsStore);

  useEffect(() => {
    dispatch(fetchProviders());
  }, [dispatch]);

  const saveCommission = () => {
    dispatch(saveProviders({
      fee: fee.amount,
      provider_code: fee.code,
      currency: fee.currency,
      is_percent: fee.is_percent,
    }));
    setFee(null);
  };

  const onCommissionEditClick = (code) => {
    const editedProvider = providers.find((v) => v.code === code);
    setFee({
      code,
      ...(editedProvider && editedProvider.default_fee),
    });
  };

  if ((!providers || isFetching) && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <div>
      <FormTitleSection title="General commissions" showEditButton={false} />
      <TabContainer>
        <p>ePlane&apos;s general commissions with supported payment providers:</p>

        <FormSubTitleItem title="Buyer's general commission when purchasing directly from ePlane" />
        <Label>
          <p>The general commissions ePlane charges from buyers, for transactions they pay directly to ePlane.</p>
          <p>
            Paying ePlane can be done through Stripe or through a wire transfer, each one of these options has it&apos;s own commission:
          </p>
        </Label>

        <StripeContainer>
          <div className="provider-logo"><FontAwesomeIcon icon={['fab', 'stripe']} size="3x" /></div>
          <div className="provider-label"><p>The ePlane commission charged when paying ePlane through Stripe:</p></div>
          <div className="provider-control">
            {`${providers.find((v) => v.code === paymentProviders.STRIPE_BROKER).default_fee.amount} %`}
            <LinkButton
              variant="link"
              name={paymentProviders.STRIPE_BROKER}
              onClick={() => onCommissionEditClick(paymentProviders.STRIPE_BROKER)}
            >
              Edit&nbsp;
              <FontAwesomeIcon icon="pencil-alt" />
            </LinkButton>
          </div>
        </StripeContainer>

        <WireTransferContainer>
          <div className="provider-logo"><Logo src={WireTransferImg} alt="Wire Transfer Logo" /></div>
          <div className="provider-label"><p>The ePlane commission charged when paying ePlane through a wire transfer:</p></div>
          <div className="provider-control">
            {`${providers.find((v) => v.code === paymentProviders.WIRETRANSFER_BROKER).default_fee.amount} %`}
            <LinkButton
              variant="link"
              name={paymentProviders.WIRETRANSFER_BROKER}
              onClick={() => onCommissionEditClick(paymentProviders.WIRETRANSFER_BROKER)}
            >
              Edit&nbsp;
              <FontAwesomeIcon icon="pencil-alt" />
            </LinkButton>
          </div>
        </WireTransferContainer>
        <br />

        <FormSubTitleItem title="Sellers general commissions charged according to each payment provider transaction" />
        <br />
        <p>
          The general commission ePlane charges sellers for transactions they get paid for through their company&apos;s payment providers:
        </p>
        <PaymentProvidersTable>
          <tbody>
            <tr>
              <td>
                <StripeLogo src={StripeLogoImg} alt="Stripe Logo" />
              </td>
              <td>
                {`${providers.find((v) => v.code === paymentProviders.STRIPE).default_fee.amount}%`}
              </td>
              <td>
                <LinkButton variant="link" name={paymentProviders.STRIPE} onClick={() => onCommissionEditClick(paymentProviders.STRIPE)}>
                  Edit&nbsp;
                  <FontAwesomeIcon icon="pencil-alt" />
                </LinkButton>
              </td>
            </tr>
          </tbody>
        </PaymentProvidersTable>
        <HorizontalLine />
      </TabContainer>

      <CommissionModal
        title={fee && conf[fee.code]?.editModalTitle}
        text={fee && conf[fee.code]?.editModalText}
        fee={fee}
        onConfirm={saveCommission}
        onCommissionChange={(e) => setFee({ ...fee, amount: e.target.value })}
        show={fee !== null}
        onHide={() => setFee(null)}
      />
      <ModalLoader show={isProcessing} />
    </div>
  );
};

export default CommissionsPage;
