import React from 'react';
import PropTypes from 'prop-types';

const defaultStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const CellWithTitle = ({ value, style }) => (
  <div style={{ ...defaultStyle, ...style }}>
    <span title={value}>{value}</span>
  </div>
);

CellWithTitle.propTypes = {
  value: PropTypes.string,
  style: PropTypes.shape({}),
};

export default CellWithTitle;
