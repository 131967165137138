import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { paymentStatus, paymentRequestStatus, paymentMethods } from '../../../constants/index';
import { color } from '../../../constants';

export const HighlightedText = styled.span`
  color: ${(props) => (props.color || '')};
  font-size: 16px;
`;

export const getPaymentStatus = (status, method) => {
  const methodText = method && Object.values(paymentMethods).find((v) => v.id === method)?.name;
  let formatedStatus = null;
  if (status === paymentStatus.COMPLETED) {
    formatedStatus = (
      <HighlightedText color={color.textGreen}>
        <FontAwesomeIcon icon="check-circle" />
        {
          methodText
            ? ` Payment completed successfully with ${methodText}`
            : ' Payment completed successfully'
        }
      </HighlightedText>
    );
  } else if (status === paymentStatus.PENDING) {
    formatedStatus = (
      <HighlightedText color={color.grey}>
        <FontAwesomeIcon icon={['far', 'clock']} />
        {
          methodText
            ? ` Payment is being processed with ${methodText}`
            : ' Payment is being processed'
        }
      </HighlightedText>
    );
  } else if (status === paymentStatus.FAILED) {
    formatedStatus = (
      <HighlightedText color={color.textRed}>
        <FontAwesomeIcon icon="exclamation-circle" />
        {
          methodText
            ? ` Payment failed with ${methodText}`
            : ' Payment failed'
        }
      </HighlightedText>
    );
  }
  return formatedStatus;
};

export const getPaymentToEplaneStatus = (status, method, buyerName) => {
  const methodText = method && Object.values(paymentMethods).find((v) => v.id === method)?.name;
  let formatedStatus = null;
  if (status === paymentStatus.COMPLETED) {
    formatedStatus = (
      <HighlightedText color={color.textGreen}>
        <FontAwesomeIcon icon="check-circle" />
        {
          methodText
            ? ` ${buyerName}'s ${methodText} payment to ePlane has been completed successfully.`
            : ' Payment completed successfully.'
        }
      </HighlightedText>
    );
  } else if (status === paymentStatus.PENDING) {
    formatedStatus = (
      <HighlightedText color={color.grey}>
        <FontAwesomeIcon icon={['far', 'clock']} />
        {
          methodText
            ? ` ${buyerName}'s ${methodText} payment to ePlane is being processed.`
            : ' Payment to ePlane is being processed.'
        }
      </HighlightedText>
    );
  } else if (status === paymentStatus.FAILED) {
    formatedStatus = (
      <HighlightedText color={color.textRed}>
        <FontAwesomeIcon icon="exclamation-circle" />
        {
          methodText
            ? ` ${buyerName}'s ${methodText} payment to ePlane has failed.`
            : ' Payment to ePlane has failed.'
        }
      </HighlightedText>
    );
  }
  return formatedStatus;
};

export const getPaymentRequestStatus = (prStatus) => {
  let status = null;
  if (prStatus === paymentRequestStatus.COMPLETED
    || prStatus === paymentRequestStatus.COMPLETED_TO_EPLANE
    || prStatus === paymentRequestStatus.COMPLETED_VIA_EPLANE) {
    status = (
      <HighlightedText color={color.textGreen}>
        <FontAwesomeIcon icon="check-circle" />
        &nbsp;
        Payment completed
      </HighlightedText>
    );
  } else if (prStatus === paymentRequestStatus.PROCESSING
    || prStatus === paymentRequestStatus.PROCESSING_TO_EPLANE) {
    status = (
      <HighlightedText color={color.grey}>
        <FontAwesomeIcon icon={['far', 'clock']} />
        &nbsp;
        Processing
      </HighlightedText>
    );
  } else if (prStatus === paymentRequestStatus.EXPIRED) {
    status = (
      <HighlightedText color={color.birch}>
        <FontAwesomeIcon icon="history" />
        &nbsp;
        Request expired
      </HighlightedText>
    );
  }
  return status;
};
