import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as consts from '../../../constants';
import Sprite from '../../../assets/sprite-common-elements.png';

const UploadBox = styled.div`
  width: 133px;
  border: 3px solid ${(props) => (props.isActive ? consts.color.birch : consts.color.lightGrey)};
  box-sizing: border-box;
  position: relative;
  margin: 10px 15px 10px 0px;

  &:hover {
    border-color: ${consts.color.birch};
  }

  & .uploadBoxBottom {
    background-color: ${(props) => (props.isActive ? consts.color.birch : 'inherit')};
    color: ${(props) => (props.isActive ? consts.color.white : consts.color.darkGrey)};
  }

  &:hover .uploadBoxBottom {
    background-color: ${consts.color.birch};
    color: ${consts.color.white};
  }

  letter-spacing: 0.35px;
  display: block;

  cursor: pointer;
`;

const BtnRemove = styled.button`
  outline: none;
  padding: 6px;
  background: url(${Sprite}) no-repeat top left;
  background-position: -621px 1px;
  border: 2px solid gray;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  border-radius: 50%;
  background-color: gray;

  &:active {
    outline: none;
    border: none;
  }

  &:hover {
    background-color: #227f94;
    border: 2px solid #227f94;
  }

  &:disabled {
    display: none;
  }
`;

const UploadBoxMain = styled.div`
  background: ${consts.color.white};
  box-sizing: border-box;
`;

const Link = styled.a`
  &&{
    text-decoration: none;
  }
`;

const UploadBoxBottom = styled.div`
  background: ${consts.color.lightGrey};
  width: 100%;
  padding: 8px 7px 5px 8px;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: ${consts.color.darkGrey};
  cursor: pointer;
`;

const Input = styled.input`
  && {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  &:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
`;

const Label = styled.label`
  margin: 0;
  display: block;
  cursor: pointer;
`;
const Title = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
`;

export default class FileLoader extends React.PureComponent {
  remove = (e) => {
    // prevent basic behaviour of browser's link
    e.preventDefault();
    const { onRemoveHandler } = this.props;
    if (onRemoveHandler) onRemoveHandler(e);
  };

  render() {
    const {
      mainTile,
      title,
      type = 'link',
      showRemoveBtn,
      url = '#',
      isActive,
      onChangeHandler,
      accept,
      ...other
    } = this.props;

    const commonContent = (
      <div>
        {showRemoveBtn && <BtnRemove type="button" onClick={this.remove} />}
        <UploadBoxMain>
          {mainTile}
        </UploadBoxMain>
        <UploadBoxBottom className="uploadBoxBottom">
          <Title>{title}</Title>
        </UploadBoxBottom>
      </div>
    );
    if (type === 'input') {
      // Generate random 5 character id string
      const idString = Math.random().toString(36).substring(5);
      return (
        <UploadBox isActive={isActive} className="upload-box">
          <Input id={idString} type="file" onChange={onChangeHandler} accept={accept} data-testid={other.testId} />
          <Label className="label-file-loader" htmlFor={idString} {...other}>
            {commonContent}
          </Label>
        </UploadBox>
      );
    }
    if (type === 'link') {
      return (
        <UploadBox isActive={isActive} className="upload-box">
          <Link href={url} target="_blank" className="a-file-loader" {...other}>
            {commonContent}
          </Link>
        </UploadBox>
      );
    }
    return null;
  }
}

FileLoader.propTypes = {
  isActive: PropTypes.bool,
  mainTile: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['link', 'input']),
  url: PropTypes.string,
  accept: PropTypes.string,
  showRemoveBtn: PropTypes.bool,
  onRemoveHandler: PropTypes.func,
  onChangeHandler: PropTypes.func,
};

FileLoader.defaultProps = {
  type: 'link',
  url: '#',
  showRemoveBtn: false,
};
