/* eslint-disable camelcase */
import axios from 'axios';

import {
  MESSAGE_SEND,
  MESSAGE_SEND_SUCCESS,
  CHANGE_ORDER_STATUS,
  CHANGE_ORDER_STATUS_SUCCESS,
  WIRE_TRANSFER_CONFIRM,
  WIRE_TRANSFER_CONFIRM_SUCCESS,
  PAYMENT_REQUEST_ADD,
  PAYMENT_REQUEST_ADD_SUCCESS,
  EPLANE_PAYMENT_REPORT,
  EPLANE_PAYMENT_REPORT_SUCCESS,

  DOC_UPLOADING_PAYMENTS_PROGRESS_SET,
  DOC_UPLOADING_PAYMENTS_START,
  DOC_UPLOADING_PAYMENTS_FINISH,
  DOC_UPLOADING_PAYMENTS_REMOVE,
  DOC_UPLOADING_PAYMENTS_TERMINATE,

  NOC_PAYMENTS_FAIL,
  ERRORS,
  maxFileSizeSendMsg,
  fileFormatsAny,
} from '../../constants';
import { addError, requestErrorHandler } from '../requestErrorHandler';

const startSendMessage = () => ({
  type: MESSAGE_SEND,
});
const sendMessageSuccess = (message) => ({
  type: MESSAGE_SEND_SUCCESS,
  payload: message,
});

const startChangeOrderStatus = () => ({
  type: CHANGE_ORDER_STATUS,
});
const changeOrderStatusSuccess = (message) => ({
  type: CHANGE_ORDER_STATUS_SUCCESS,
  payload: message,
});

const startWireTransferConfirmation = () => ({
  type: WIRE_TRANSFER_CONFIRM,
});
const wireTransferConfirmationSuccess = (message) => ({
  type: WIRE_TRANSFER_CONFIRM_SUCCESS,
  payload: message,
});

const startPaymentConfirmation = () => ({
  type: EPLANE_PAYMENT_REPORT,
});
const paymentConfirmationSuccess = (message) => ({
  type: EPLANE_PAYMENT_REPORT_SUCCESS,
  payload: message,
});

const startAddPaymentRequest = () => ({
  type: PAYMENT_REQUEST_ADD,
});
const addPaymentRequestSuccess = (message) => ({
  type: PAYMENT_REQUEST_ADD_SUCCESS,
  payload: message,
});

const tasksOperationFail = () => ({
  type: NOC_PAYMENTS_FAIL,
});

const setUploadingProgress = (percentOfUpload) => ({
  type: DOC_UPLOADING_PAYMENTS_PROGRESS_SET,
  payload: percentOfUpload,
});
const startUploadingDocument = (source) => ({
  type: DOC_UPLOADING_PAYMENTS_START,
  payload: source,
});
const finishUploadingDocument = (document) => ({
  type: DOC_UPLOADING_PAYMENTS_FINISH,
  payload: document,
});
export const removeDoc = () => ({ type: DOC_UPLOADING_PAYMENTS_REMOVE });

export const terminateUploadingProgress = () => ({ type: DOC_UPLOADING_PAYMENTS_TERMINATE });
export const sendMessage = (data, orderId) => async (dispatch) => {
  dispatch(startSendMessage());

  const path = `/rest/V2/eplane/backoffice/rfq/${orderId}/message`;

  try {
    const response = await axios.post(path, data);
    if (response.data.code === ERRORS.SUCCESS) {
      const msg = response.data.messages && Object.values(response.data.messages)[0];
      dispatch(sendMessageSuccess(msg?.message_id));
    } else {
      requestErrorHandler(response, dispatch, tasksOperationFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, tasksOperationFail, e);
  }
};

export const changeOrdersStatus = (data, orderId) => async (dispatch) => {
  dispatch(startChangeOrderStatus());

  const path = `/rest/V2/eplane/backoffice/rfq/${orderId}/orderStatus`;

  try {
    const response = await axios.post(path, data);
    if (response.data.code === ERRORS.SUCCESS) {
      const msg = response.data.messages && Object.values(response.data.messages)[0];
      dispatch(changeOrderStatusSuccess(msg?.message_id));
    } else {
      requestErrorHandler(response, dispatch, tasksOperationFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, tasksOperationFail, e);
  }
};

export const confirmWireTransfer = (data, orderId) => async (dispatch) => {
  dispatch(startWireTransferConfirmation());

  const path = `/rest/V2/eplane/backoffice/rfq/${orderId}/confirmWiretransfer`;

  try {
    const response = await axios.post(path, data);
    if (response.data.code === ERRORS.SUCCESS) {
      const msg = response.data.messages && Object.values(response.data.messages)[0];
      dispatch(wireTransferConfirmationSuccess(msg?.message_id));
    } else {
      requestErrorHandler(response, dispatch, tasksOperationFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, tasksOperationFail, e);
  }
};

export const paymentRequestAdd = (data, orderId) => async (dispatch) => {
  dispatch(startAddPaymentRequest());

  try {
    const response = await axios.post(`/rest/V1/eplane/backoffice/rfq/${orderId}/message/paymentRequest`, data);
    if (response.data.code === ERRORS.SUCCESS) {
      const msg = response.data.messages && Object.values(response.data.messages)[0];
      dispatch(addPaymentRequestSuccess(msg?.message_id));
    } else {
      requestErrorHandler(response, dispatch, tasksOperationFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, tasksOperationFail, e);
  }
};

const onUploadProgress = (progressEvent, dispatch) => {
  const { loaded, total } = progressEvent;
  dispatch(setUploadingProgress((loaded * 100) / total));
};

export const uploadDocument = (file) => (dispatch) => {
  if (file && fileFormatsAny.every((mimeType) => mimeType !== file.type)) {
    dispatch(addError(ERRORS.ERR_WRONG_FORMAT));
    return;
  }

  if (file.size > maxFileSizeSendMsg) {
    const maxSizeFormat = `${maxFileSizeSendMsg / 1024 / 1024} MB`;
    dispatch(addError(ERRORS.ERR_FILE_IS_TOO_LARGE, { maxFileSize: maxSizeFormat }));
    return;
  }

  const source = axios.CancelToken.source();
  dispatch(startUploadingDocument(source));

  const fd = new FormData(); // eslint-disable-line no-undef
  fd.append('files', file);
  const options = {
    onUploadProgress: (e) => onUploadProgress(e, dispatch),
    cancelToken: source.token,
  };

  axios.post('/backoffice/rfq/sendmessage_attach', fd, options).then((response) => {
    dispatch(finishUploadingDocument(response.data));
  }).catch((e) => requestErrorHandler(e.response, dispatch, tasksOperationFail, e));
};

export const reportEplanePayment = (data, orderId) => async (dispatch) => {
  dispatch(startPaymentConfirmation());

  const path = `/rest/V2/eplane/backoffice/rfq/${orderId}/reportEplanePayment`;

  try {
    const response = await axios.post(path, data);
    if (response.data.code === ERRORS.SUCCESS) {
      const msg = response.data.messages && Object.values(response.data.messages)[0];
      dispatch(paymentConfirmationSuccess(msg?.message_id));
    } else {
      requestErrorHandler(response, dispatch, tasksOperationFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, tasksOperationFail, e);
  }
};
