import {
  VENDOR_CATEGORIES_GET,
  VENDOR_CATEGORIES_GET_SUCCESS,

  VENDOR_CATEGORIES_SAVE,
  VENDOR_CATEGORIES_SAVE_SUCCESS,
  VENDOR_CATEGORIES_FAIL,

  MISC_SETTINGS_EDIT_MODE_TOGGLE,
} from '../../constants';

const initialState = {
  categories: null,
  isFetching: false,
  isSaving: false,
  editMode: false,
};

const categoryComparator = (a, b) => {
  const nameCompare = a.category_name.localeCompare(b.category_name);
  if ((a.is_enabled && !b.is_enabled) || (a.is_enabled === b.is_enabled && nameCompare < 0)) {
    return -1;
  }
  if ((!a.is_enabled && b.is_enabled) || (a.is_enabled === b.is_enabled && nameCompare > 0)) {
    return 1;
  }

  return 0;
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case VENDOR_CATEGORIES_GET:
      return {
        ...state,
        isFetching: true,
      };
    case VENDOR_CATEGORIES_GET_SUCCESS:
      return {
        ...state,
        categories: payload.sort(categoryComparator),
        isFetching: false,
      };
    case VENDOR_CATEGORIES_SAVE:
      return {
        ...state,
        isSaving: true,
      };
    case VENDOR_CATEGORIES_SAVE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        editMode: false,
      };
    case VENDOR_CATEGORIES_FAIL:
      return {
        ...state,
        isFetching: false,
        isSaving: false,
        editMode: false,
      };
    case MISC_SETTINGS_EDIT_MODE_TOGGLE:
      return {
        ...state,
        editMode: !state.editMode,
      };
    default: return state;
  }
};
