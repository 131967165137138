import {
  USER_SITES_GET,
  USER_SITES_GET_SUCCESS,
  USER_SITES_GET_FAIL,

  USER_SITE_SAVE,
  USER_SITE_SAVE_SUCCESS,
  USER_SITE_SAVE_FAIL,

  USER_SITE_DELETING_START,
  USER_SITE_DELETE_SUCCESS,
  USER_SITE_DELETE_FAIL,

  USER_SITE_MODAL_CREATE_OPEN,
  USER_SITE_MODAL_EDIT_OPEN,
  USER_SITE_MODAL_CLOSE,

  USER_SITE_MODAL_REMOVE_OPEN,
  USER_SITE_MODAL_REMOVE_CLOSE,
  USER_SITE_SELECT,

  USER_SITES_ERRORS_REMOVE,
} from '../../constants';

const initialState = {
  sites: null,

  currSiteId: null,
  newSiteType: null,

  showSiteModal: false,
  showRemoveSiteModal: false,

  isFetching: false,
  isSaving: false,
  isDeleting: false,

  fetchingErrorCode: null,
  savingErrorCode: null,
  deletingErrorCode: null,
};

const sitesReducer = (state = initialState, action) => {
  const { payload } = action;
  let newSiteList;
  let isNewOne = true;
  switch (action.type) {
    case USER_SITES_GET: return { ...state, isFetching: true };
    case USER_SITES_GET_SUCCESS: return {
      ...state,
      isFetching: false,
      sites: payload,
      fetchingErrorCode: null,
    };
    case USER_SITES_GET_FAIL: return { ...state, isFetching: false, fetchingErrorCode: payload };

    case USER_SITE_SAVE: return { ...state, isSaving: true, savingErrorCode: null };
    case USER_SITE_SAVE_SUCCESS:
      newSiteList = state.sites.map((s) => {
        if (s.site_id === payload.site_id) {
          isNewOne = false;
          return payload;
        }
        return s;
      });
      if (isNewOne) newSiteList = state.sites.concat(payload);
      return {
        ...state,
        sites: newSiteList,
        isSaving: false,
        showSiteModal: false,
      };
    case USER_SITE_SAVE_FAIL: return { ...state, isSaving: false, savingErrorCode: payload };

    case USER_SITE_DELETING_START: return { ...state, isDeleting: true, deletingErrorCode: null };
    case USER_SITE_DELETE_SUCCESS: return {
      ...state,
      showRemoveSiteModal: false,
      currSiteId: null,
      isDeleting: false,
      sites: state.sites.filter((s) => s.site_id !== payload),
    };
    case USER_SITE_DELETE_FAIL:
      return {
        ...state,
        isDeleting: false,
        showRemoveSiteModal: false,
        deletingErrorCode: payload,
      };

    case USER_SITE_MODAL_CREATE_OPEN: return {
      ...state,
      showSiteModal: true,
      newSiteType: payload,
      currSiteId: null,
    };
    case USER_SITE_MODAL_EDIT_OPEN: return {
      ...state,
      showSiteModal: true,
      newSiteType: null,
      currSiteId: payload,
    };
    case USER_SITE_MODAL_CLOSE: return {
      ...state,
      showSiteModal: false,
      savingErrorCode: null,
    };

    case USER_SITE_MODAL_REMOVE_OPEN: return {
      ...state,
      currSiteId: payload,
      showRemoveSiteModal: true,
    };
    case USER_SITE_SELECT: return {
      ...state,
      currSiteId: payload,
    };

    case USER_SITE_MODAL_REMOVE_CLOSE: return {
      ...state,
      currSiteId: null,
      showRemoveSiteModal: false,
    };

    case USER_SITES_ERRORS_REMOVE: return {
      ...state,
      fetchingErrorCode: null,
      savingErrorCode: null,
      deletingErrorCode: null,
    };
    default: return state;
  }
};

export default sitesReducer;
