import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../constants';
import avatarLight from '../../../assets/user-avatar-lightblue.png';
import avatarDark from '../../../assets/user-avatar-darkblue.png';
import avatarEplaneBot from '../../../assets/user-avatar-eplane-bot.png';

const AvatarContainer = styled.div`
  position: absolute;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 3px solid;
  left: -67px;
  top: 9px;
  box-sizing: border-box;
  border-color: ${(props) => (getAvatarBorderColor(props.isBuyer))};
  padding-top: 6px;
  overflow: hidden;

  & > div {
    background: url("${(props) => getAvatarUrl(props.isBuyer, props.isBot)}") no-repeat center;
    background-position: ${(props) => (props.isBot ? ' 1px -3px' : '0 0')};
    height: 100%;
  }
`;

const Avatar = (props) => (
  <AvatarContainer {...props}>
    <div />
  </AvatarContainer>
);

Avatar.propTypes = {
  isBot: PropTypes.bool,
};

export default Avatar;

const getAvatarBorderColor = (isBuyer) => (isBuyer ? color.birch : color.blue);
const getAvatarUrl = (isBuyer, isBot) => {
  if (isBot) return avatarEplaneBot;
  if (isBuyer) return avatarLight;
  return avatarDark;
};
