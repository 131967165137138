import isIPRange from 'validator/lib/isIPRange';
import isIP from 'validator/lib/isIP';
import isFQDN from 'validator/lib/isFQDN';

import createErrorValidationObj from '../../../utils/createErrorValidationObj';

const sshUserManageentValidators = {
  user_name: (value) => {
    if (value === '') return createErrorValidationObj('Please enter the SFTP user name');
    if (value.length > 31) {
      return createErrorValidationObj('The length should be less than 32 characters');
    }
    if (!value.match(/^[a-z_][a-z0-9_-]*[$]?$/)) {
      return createErrorValidationObj(
        'Name should start with a lowercase letter/underscore and include lowercase letters/digits/underscores/dashes',
      );
    }
    return null;
  },

  sshKeyValidator: (value) => {
    if (!value) return createErrorValidationObj('Please enter the SSH key');
    return null;
  },

  whitelistedIP: (value) => {
    if (!value) return createErrorValidationObj('Please enter a whitelisted IP address');
    if (!isIPRange(value) && !isIP(value) && !isFQDN(value)) {
      return createErrorValidationObj('Please check correctness of the IP address');
    }
    return null;
  },
};

export default sshUserManageentValidators;
