import axios from 'axios';

import {
  WEIGHT_GROUP_LIST_GET,
  WEIGHT_GROUP_LIST_GET_SUCCESS,

  WEIGHT_GROUPS_GET,
  WEIGHT_GROUPS_GET_SUCCESS,

  WEIGHT_GROUP_REMOVE,
  WEIGHT_GROUP_REMOVE_SUCCESS,

  WEIGHT_GROUP_LIST_SAVE,
  WEIGHT_GROUP_LIST_SAVE_SUCCESS,
  WEIGHT_GROUP_LIST_UPDATE_SUCCESS,

  WEIGHT_GROUPS_ERROR_REMOVE,
  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';

const startFetchGroupList = () => ({ type: WEIGHT_GROUP_LIST_GET });
const fetchGroupListSuccess = (LIST) => ({
  type: WEIGHT_GROUP_LIST_GET_SUCCESS,
  payload: LIST,
});

const startSaveGroupList = () => ({ type: WEIGHT_GROUP_LIST_SAVE });
const saveGroupListSuccess = (item) => ({
  type: WEIGHT_GROUP_LIST_SAVE_SUCCESS,
  payload: item,
});
const updateGroupListSuccess = (item) => ({
  type: WEIGHT_GROUP_LIST_UPDATE_SUCCESS,
  payload: item,
});

const startFetchWeightGroups = () => ({ type: WEIGHT_GROUPS_GET });
const fetchWeightGroupsSuccess = (LIST) => ({
  type: WEIGHT_GROUPS_GET_SUCCESS,
  payload: LIST,
});

const startRemoveGroup = () => ({ type: WEIGHT_GROUP_REMOVE });
const removeGroupSuccess = (removedGroup) => ({
  type: WEIGHT_GROUP_REMOVE_SUCCESS,
  payload: removedGroup,
});

export const removeWeightGroupError = () => ({
  type: WEIGHT_GROUPS_ERROR_REMOVE,
});

export const fetchWeightGroups = () => async (dispatch) => {
  dispatch(startFetchWeightGroups());
  try {
    const response = await axios('/rest/default/V2/eplane/backoffice/tier3lists');
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchWeightGroupsSuccess(response.data && response.data.payload.lists));
    } else {
      requestErrorHandler(response, dispatch, removeWeightGroupError);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, removeWeightGroupError, e);
  }
};

export const removeGroup = (id) => async (dispatch) => {
  dispatch(startRemoveGroup());
  try {
    const response = await axios.delete(`/rest/default/V2/eplane/backoffice/tier3list/${id}`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(removeGroupSuccess(response.data && response.data.payload));
    } else {
      requestErrorHandler(response, dispatch, removeWeightGroupError);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, removeWeightGroupError, e);
  }
};

export const fetchGroupList = (listId) => async (dispatch) => {
  dispatch(startFetchGroupList());
  try {
    const response = await axios(`/rest/default/V2/eplane/backoffice/tier3list/${listId}`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchGroupListSuccess(response.data && response.data.payload.sellers));
    } else {
      requestErrorHandler(response, dispatch, removeWeightGroupError);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, removeWeightGroupError, e);
  }
};

export const saveGroup = (data, editMode) => async (dispatch) => {
  dispatch(startSaveGroupList());
  let response = null;
  try {
    if (editMode) {
      response = await axios.post(`/rest/default/V2/eplane/backoffice/tier3list/${data.id}/update`, data);
    } else {
      response = await axios.post('/rest/default/V2/eplane/backoffice/tier3list/create', data);
    }
    if (response.data.code === ERRORS.SUCCESS) {
      if (editMode) dispatch(updateGroupListSuccess(response.data && response.data.payload));
      else dispatch(saveGroupListSuccess(response.data && response.data.payload.list));
    } else {
      requestErrorHandler(response, dispatch, removeWeightGroupError);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, removeWeightGroupError, e);
  }
};
