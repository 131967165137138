export default function getAllowedRoutes(routes) {
  // eslint-disable-next-line no-undef
  const role = localStorage.getItem('role');

  return routes.filter(({ permission }) => {
    if (!permission || !role) return true;
    if (!(Array.isArray(permission) && permission.length)) return true;

    return permission.some((p) => (p === role));
  });
}
