import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormTitleSection from '../../FormComponents/TitleSection';
import { TabContainer } from '../../StyledComponents';
import FormActionButtons from '../../FormComponents/ActionButtons';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import Loader from '../../Loader';
import {
  getErrorsStore, getUserStore, getCompanyListStore, getCustomSellersListsStore, getUserSettingsStore,
} from '../../../selectors';
import { getProActiveState } from '../../../selectors/pro';
import { fetchCompanyList } from '../../../actions/companyListActions';
import { fetchUserSettings } from '../../../actions/companiesManagement/userSettingsActions';
import {
  fetchCustomSellersLists, fetchBlockedApprovedSellers, saveApprovedBlockedList, removeSellersList,
} from '../../../actions/companiesManagement/sellersListsActions';
import ModalLoader from '../../ModalLoader';
import { userType, sellersListTypes } from '../../../constants/index';
import WhiteBlackListForm from './WhiteBlackListForm';
import { color } from '../../../constants';

const ApprovedBlockedSellersPage = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();

  const {
    approvedBlockedList,
    items,
    isApprovedBlockedListFetching: isFetchingLists,
    isProcessing: isProcessingLists,
  } = useSelector(getCustomSellersListsStore);
  const { user } = useSelector(getUserStore);
  const { data, isFetching } = useSelector(getUserSettingsStore);
  const { companies } = useSelector(getCompanyListStore);
  const isProActive = useSelector(getProActiveState);
  const { errors } = useSelector(getErrorsStore);

  const [showModal, setModal] = useState({
    saveConfirmation: false,
    removeConfirmation: false,
  });
  const [lists, setLists] = useState({});
  const [chosenListType, setChosenListType] = useState(sellersListTypes.BLOCKED);
  const [editMode, setEditMode] = useState(false);
  const isCompanyUser = user && (user.user_type === userType.COMPANY.id);

  useEffect(() => {
    if (approvedBlockedList) {
      setChosenListType(approvedBlockedList.type || chosenListType);
      setLists({
        listId: approvedBlockedList.list_id || null,
        type: approvedBlockedList.type || null,
        whiteList: (approvedBlockedList.type === sellersListTypes.APPROVED
          && approvedBlockedList.sellers.map((v) => ({ value: v.seller_id, label: v.name }))) || [],
        blackList: (approvedBlockedList.type === sellersListTypes.BLOCKED
          && approvedBlockedList.sellers.map((v) => ({ value: v.seller_id, label: v.name }))) || [],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, approvedBlockedList]);

  useEffect(() => {
    dispatch(fetchCustomSellersLists(userId));
    dispatch(fetchUserSettings(userId));
    dispatch(fetchBlockedApprovedSellers(userId));
    dispatch(fetchCompanyList());
  }, [dispatch, userId]);

  const customListSellerIds = useMemo(
    () => items?.reduce((acc, list) => acc.concat(list?.sellers?.map((seller) => seller.seller_id)), []),
    [items],
  );

  const companiesOptions = useMemo(
    () => companies?.map((company) => ({
      value: company.company_id,
      label: company.company_name,
      warning: items?.some((list) => list?.sellers?.some((seller) => seller.seller_id === company.company_id)),
    })).filter((v) => v.value !== userId),
    [companies, items, userId],
  );

  const onWhiteBlackListChange = (listChanges) => setLists({ ...lists, ...listChanges });

  const onHideConfirmationModal = () => setModal({ ...showModal, saveConfirmation: false });

  const onShowConfirmationModal = () => setModal({ ...showModal, saveConfirmation: true });

  const save = () => {
    onHideConfirmationModal();

    let changes = null;
    if (lists.whiteList.length !== 0) {
      changes = {
        list_name: '',
        user_id: userId,
        sellers: lists.whiteList.filter((v) => v.value).map((v) => ({ seller_id: v.value })),
      };

      if (!lists.listId) changes.list_type = sellersListTypes.APPROVED;
      else changes.list_id = lists.listId;
    } else if (lists.blackList.length !== 0) {
      changes = {
        list_name: '',
        user_id: userId,
        sellers: lists.blackList.filter((v) => v.value).map((v) => ({ seller_id: v.value })),
      };

      if (!lists.listId) changes.list_type = sellersListTypes.BLOCKED;
      else changes.list_id = lists.listId;
    }

    if (changes !== null) dispatch(saveApprovedBlockedList(changes, !!lists.listId));
    else if (lists.listId) dispatch(removeSellersList(lists.listId, userId));

    setEditMode(false);
  };

  const onCancelHandler = () => {
    setEditMode(false);
    setLists({
      listId: approvedBlockedList.list_id,
      type: approvedBlockedList.type || null,
      whiteList: (approvedBlockedList.type === sellersListTypes.APPROVED
        && approvedBlockedList.sellers.map((v) => ({ value: v.seller_id, label: v.name }))) || [],
      blackList: (approvedBlockedList.type === sellersListTypes.BLOCKED
        && approvedBlockedList.sellers.map((v) => ({ value: v.seller_id, label: v.name }))) || [],
    });
  };

  const onListTypeChange = (e) => {
    if (approvedBlockedList.list_id) {
      setModal({ ...showModal, removeConfirmation: e.target.value });
    } else setChosenListType(e.target.value);
  };

  const onListRemove = () => {
    setChosenListType(showModal.removeConfirmation);
    dispatch(removeSellersList(lists.listId, userId));
    setLists({
      listId: null,
      type: null,
      whiteList: [],
      blackList: [],
    });
    setModal({ ...showModal, removeConfirmation: false });
  };

  if (!approvedBlockedList && (errors === null || errors.length === 0)) return <Loader />;

  const title = isCompanyUser ? 'Blocked/Approved Sellers' : "Warning! This user doesn't belong to any approved Company";
  const saveBtnProps = { disabled: !editMode };
  const isFeatureDisabled = data && !(data.autopilot_enabled && (data.sellers_lists_enabled || isProActive));

  return (
    <div>
      <FormTitleSection
        title={title}
        EditButtonClick={() => setEditMode(!editMode)}
        showEditButton={!isFeatureDisabled && !editMode}
      />

      <TabContainer marginTop="10px">
        <br />
        { isFeatureDisabled ? (
          <p>
            Blocked/approved seller list management is available for companies with enabled autopilot and seller lists
            or activated Pro Plan.
          </p>
        ) : (
          <WhiteBlackListForm
            whiteList={lists.whiteList}
            blackList={lists.blackList}
            customListSellerIds={customListSellerIds}
            isApprovedList={chosenListType === sellersListTypes.APPROVED}
            options={companiesOptions}
            disabled={!editMode}
            onChange={onWhiteBlackListChange}
            onListTypeChange={onListTypeChange}
          />
        )}

        {editMode && (
          <>
            <hr />
            <FormActionButtons
              saveProps={saveBtnProps}
              saveHandler={onShowConfirmationModal}
              cancelHandler={onCancelHandler}
            />
          </>
        )}

        <ConfirmationModal
          show={showModal.saveConfirmation}
          confirm={save}
          onHide={onHideConfirmationModal}
        >
          Are you sure you want to apply the changes?
        </ConfirmationModal>
        <ConfirmationModal
          show={!!showModal.removeConfirmation}
          confirm={onListRemove}
          onHide={() => setModal({ ...showModal, removeConfirmation: false })}
          confirmBtnText="I'm sure"
          cancelBtnText="Cancel"
        >
          <>
            <FontAwesomeIcon icon="exclamation-circle" color={`${color.goldenTainoi}`} size="2x" />
            <h3>You cannot create both approved &amp; blocked seller lists.</h3>
            <br />
            <p>
              {`Creating ${chosenListType === sellersListTypes.APPROVED ? 'a blocked' : 'an approved'}
                list will remove all the sellers from your
                ${chosenListType === sellersListTypes.APPROVED ? 'approved' : 'blocked'} list. Are you sure you wish to proceed?`}
            </p>
          </>
        </ConfirmationModal>
        <ModalLoader show={isProcessingLists || isFetchingLists || isFetching} />
      </TabContainer>
    </div>
  );
};

export default ApprovedBlockedSellersPage;
