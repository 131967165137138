import React, { useEffect } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { fetchIncomingRequests, searchForIncomingRequests } from '../../actions/incomingRequestsActions';
import { fetchClosedRequests, searchForClosedRequests } from '../../actions/closedRequestsActions';
import { pushRedirect } from '../../actions/redirectActions';
import { getIncomingRequestsStore, getClosedRequestsStore } from '../../selectors';
import { getIncomingReqList } from '../../reducers/requests/incomingRequests';
import { getClosedReqList } from '../../reducers/requests/closedRequests';
import OpenReqTable from './OpenReqTable';
import ClosedReqTable from '../ClosedReqTable';
import SearchBar from '../SearchBar';
import A from '../A';
import { TableRow } from '../StyledComponents';
import Container from '../Container';
import Loader from '../Loader';
import ModalLoader from '../ModalLoader';
import { PUBLIC_URL } from '../../constants';

const PastRequestsWrapper = styled.div`
  padding: 10px 0;
`;

export default function IncomingRequests() {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const incomingRequests = useSelector(getIncomingRequestsStore);
  const closedRequests = useSelector(getClosedRequestsStore);
  const incomingReqList = getIncomingReqList(incomingRequests);
  const closedReqList = getClosedReqList(closedRequests);

  useEffect(() => {
    const query = queryString.parse(location.search);
    const { status, q: searchString } = query;

    if (searchString || status) {
      dispatch(searchForIncomingRequests(searchString, status));
      if (searchString) dispatch(searchForClosedRequests(searchString));
      else dispatch(searchForClosedRequests(searchString, 10));
    } else {
      batch(() => {
        dispatch(fetchIncomingRequests());
        dispatch(fetchClosedRequests(10));
      });
    }
  }, [dispatch, location.search]);

  const onSearchHandler = (searchString) => {
    const query = queryString.parse(location.search);

    if (searchString !== query.q) {
      query.q = searchString;
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(query),
      });
    }
  };

  const onStatusChange = (e) => {
    const newStatus = e.target.value;
    const query = queryString.parse(location.search);

    if (newStatus !== query.status) {
      query.status = newStatus;
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(query),
      });
    }
  };

  const {
    q: searchValue,
    status,
  } = queryString.parse(location.search);
  const requestsAreFetching = incomingRequests.isFetching || closedRequests.isFetching;
  const requestsAreSearching = closedRequests.isSearching || closedRequests.isSearching;
  let incomingReqTitle;
  let closedReqTitle;
  let noDataIncomingReq = <span>There are no open requests that match your search.</span>;
  const noDataClosedReq = <span>There are no closed requests that match your search.</span>;
  let commonInfo;
  let content;

  if (requestsAreFetching) content = <Loader />;
  else {
    if (!incomingRequests.isSearchingMode) {
      incomingReqTitle = (incomingReqList.length === 1)
        ? 'There is 1 open request:'
        : `There are ${incomingReqList.length} open requests:`;
      noDataIncomingReq = <span>All Of The requests have been approved</span>;
      closedReqTitle = 'Closed requests:';
    } else {
      const openLen = incomingReqList.length;
      const closedLen = closedReqList.length;

      if (openLen === 0 && closedLen === 0) {
        commonInfo = 'There are no requests that match your search.';
      } else {
        if (openLen === 0) {
          incomingReqTitle = 'There are no open requests that match your search.';
        } else if (openLen === 1) {
          incomingReqTitle = 'There is 1 open request that match your search:';
        } else {
          incomingReqTitle = `There are ${openLen} open requests that match your search:`;
        }

        if (closedLen === 0) {
          closedReqTitle = 'There are no closed requests that match your search.';
        } else if (closedLen === 1) {
          closedReqTitle = 'There is 1 closed request that match your search:';
        } else {
          closedReqTitle = `There are ${closedLen} closed requests that match your search:`;
        }
      }
    }

    const closedUrl = searchValue
      ? `${PUBLIC_URL}/incoming/closed?q=${encodeURIComponent(searchValue)}`
      : `${PUBLIC_URL}/incoming/closed`;

    content = (
      <div>
        {
          commonInfo || (
            <div>
              <TableRow>
                <OpenReqTable
                  title={incomingReqTitle}
                  data={incomingReqList}
                  goTo={(...params) => dispatch(pushRedirect(...params))}
                  noDataContent={noDataIncomingReq}
                  onStatusChange={onStatusChange}
                  status={status}
                />
              </TableRow>
              <TableRow>
                <ClosedReqTable
                  title={closedReqTitle}
                  data={closedReqList}
                  goTo={(...params) => dispatch(pushRedirect(...params))}
                  noDataContent={noDataClosedReq}
                />
              </TableRow>
            </div>
          )
        }

        <PastRequestsWrapper>
          <A to={closedUrl}>View all past requests</A>
        </PastRequestsWrapper>
      </div>
    );
  }

  return (
    <Container links={[{ 'Incoming requests': null }]}>
      <ModalLoader show={requestsAreSearching} />
      <SearchBar
        searchOnTheFly={false}
        onSearchHandler={onSearchHandler}
        defaultInputValue={searchValue || ''}
        placeholder="Request No. / User email / Company"
      />
      {content}
    </Container>
  );
}
