import axios from 'axios';

import {
  SFTP_USER_GET,
  SFTP_USER_GET_SUCCESS,
  SFTP_USER_GET_FAIL,

  SFTP_USER_SAVE,
  SFTP_USER_SAVE_SUCCESS,
  SFTP_USER_SAVE_FAIL,
  SFTP_USER_ERROR_REMOVE,
} from '../../constants';
import errorHandler from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startFetchingSftpUser = () => ({
  type: SFTP_USER_GET,
});
const fetchSftpUserSuccess = (USER) => ({
  type: SFTP_USER_GET_SUCCESS,
  payload: USER,
});
const fetchSftpUserFail = (errCode) => ({
  type: SFTP_USER_GET_FAIL,
  payload: errCode,
});

const startSaveSftpUser = () => ({
  type: SFTP_USER_SAVE,
});
const saveSftpUserSuccess = (user) => ({
  type: SFTP_USER_SAVE_SUCCESS,
  payload: user,
});
const saveSftpUserFail = (errCode) => ({
  type: SFTP_USER_SAVE_FAIL,
  payload: errCode,
});

export const removeSftpUserErrorCode = () => ({
  type: SFTP_USER_ERROR_REMOVE,
});

export const fetchSftpUser = (userId) => (dispatch) => {
  dispatch(startFetchingSftpUser());

  axios(`rest/default/V1/eplane/backoffice/user/${userId}/pubdriveUser`).then((response) => {
    dispatch(fetchSftpUserSuccess(response.data));
  }).catch((e) => {
    errorHandler(e.response, dispatch, fetchSftpUserFail, e);
  });
};

export const saveSftpUser = (userId, saveData) => (dispatch) => {
  dispatch(startSaveSftpUser());

  axios.post(`/rest/default/V1/eplane/backoffice/user/${userId}/pubdriveUser`, { req: saveData }).then((response) => {
    dispatch(saveSftpUserSuccess(response.data));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, saveSftpUserFail, e);
  });
};
