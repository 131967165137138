import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { showForm } from '../../../actions/networkOperationsActions/formManagementActions';
import { NOC_FORMS } from '../../../constants/operationCenter';
import { chatMsgType, quoteValidationStatuses, quoteTypes } from '../../../constants/index';

const StyledUL = styled.ul`
  list-style:none;
  padding:0;
  margin: 0;
`;
const StyledFA = styled(FontAwesomeIcon)`
  fonts-size: 16px;
  margin-right: 5px;
`;
const ArrowDownIcon = styled(FontAwesomeIcon)`
  margin-left:2px;
`;
const LinkButton = styled(Button)`
  &&& {
    outline: 0;
    outline-offset: 0;
    text-decoration:none;
  }
  font-weight:600;
  color:black;
`;

const StyledLi = styled.li`
    width:100%;
`;
const StyledOption = styled(Button)`
  &:hover {
       text-decoration: none;
    }
   &&& {
    outline: 0;
    outline-offset: 0;
    text-decoration:none;
  }
  padding-right: 0;
  padding-left: 0;
  display:block;
  width:100%;
  text-align: left;
`;
const EXCEPTION_LIST = [
  chatMsgType.PR_MSG,
  chatMsgType.PAYMENT_MSG,
  chatMsgType.PR_EXPIRED,
  chatMsgType.EPLANE_PAYMENT_REQUEST,
  chatMsgType.EPLANE_PO_REQUEST,
  chatMsgType.EPLANE_BOUGHT_ITEM,
  chatMsgType.EPLANE_ORDER_SHIPPED,
  chatMsgType.EPLANE_REVIEW,
  chatMsgType.EPLANE_PAYMENT_COMPLETE,
  chatMsgType.EPLANE_PAYMENT_FAILED,
  chatMsgType.EPLANE_PAYMENT_PROCESSING,
  chatMsgType.EPLANE_WT_CONFIRM,
  chatMsgType.PAYMENT_COMPLETE,
  chatMsgType.PAYMENT_FAILED,
  chatMsgType.PAYMENT_PROCESSING,
  chatMsgType.EPP_REPORT,
];

const PopoverDropDown = ({
  messageId,
  quoteType,
  isAltPn,
  quoteValidationStatus,
  messageType,
  isServiceMessage,
  isMessageSentByBot,
  isPaymentRequestDisabled,

  onClickEdit,
  onClickAdd,
  onClickRemove,
  onClickAddNoQuote,
  onQuoteValidation,

  onEnter,
  onLeave,
}) => {
  const dispatch = useDispatch();

  if (isMessageSentByBot && !isServiceMessage) {
    if (messageType === chatMsgType.TEXT || messageType === chatMsgType.ATCH_ADD) {
      return null;
    }
  }
  if (EXCEPTION_LIST.includes(messageType)) return null;

  let dropDownContent = null;
  if (messageType === chatMsgType.QUOTE && isMessageSentByBot) {
    dropDownContent = (
      <StyledUL>
        <StyledLi key="1asd">
          <StyledOption variant="link" onClick={onClickEdit}>
            <StyledFA icon="edit" />
            Edit built-in quote
          </StyledOption>
        </StyledLi>
        <StyledLi key="2asd">
          <StyledOption variant="link" onClick={onClickRemove}>
            <StyledFA icon="trash-alt" />
            Remove
          </StyledOption>
        </StyledLi>
        {quoteType === quoteTypes.QUOTE_BUY && !isAltPn && (quoteValidationStatus === quoteValidationStatuses.INVALID_BY_BO
          || quoteValidationStatus === quoteValidationStatuses.INVALID_BY_ALGO) && (
          <StyledLi key="3asd">
            <StyledOption variant="link" onClick={() => onQuoteValidation(true)}>
              <StyledFA icon="ban" />
              Mark valid
            </StyledOption>
          </StyledLi>
        )}
        {quoteType === quoteTypes.QUOTE_BUY && !isAltPn && (quoteValidationStatus === quoteValidationStatuses.VALID_BY_ALGO
          || quoteValidationStatus === quoteValidationStatuses.VALID_BY_BO) && (
          <StyledLi key="3asd">
            <StyledOption variant="link" onClick={() => onQuoteValidation(false)}>
              <StyledFA icon="ban" />
              Mark invalid
            </StyledOption>
          </StyledLi>
        )}
      </StyledUL>
    );
  } else if (messageType === chatMsgType.QUOTE) {
    dropDownContent = (
      <StyledUL>
        <StyledLi key="1asd">
          <StyledOption variant="link" onClick={onClickAdd}>
            <StyledFA icon="plus" />
            Add built-in quote
          </StyledOption>
        </StyledLi>
        <StyledLi key="2asd">
          <StyledOption variant="link" onClick={onClickAddNoQuote}>
            <StyledFA icon="times" />
            Send no quote
          </StyledOption>
        </StyledLi>
        {quoteType === quoteTypes.QUOTE_BUY && !isAltPn && (quoteValidationStatus === quoteValidationStatuses.INVALID_BY_BO
          || quoteValidationStatus === quoteValidationStatuses.INVALID_BY_ALGO) && (
          <StyledLi key="3asd">
            <StyledOption variant="link" onClick={() => onQuoteValidation(true)}>
              <StyledFA icon="ban" />
              Mark valid
            </StyledOption>
          </StyledLi>
        )}
        {quoteType === quoteTypes.QUOTE_BUY && !isAltPn && (quoteValidationStatus === quoteValidationStatuses.VALID_BY_ALGO
          || quoteValidationStatus === quoteValidationStatuses.VALID_BY_BO) && (
          <StyledLi key="3asd">
            <StyledOption variant="link" onClick={() => onQuoteValidation(false)}>
              <StyledFA icon="ban" />
              Mark invalid
            </StyledOption>
          </StyledLi>
        )}
      </StyledUL>
    );
  } else if (messageType === chatMsgType.NO_QUOTE && isMessageSentByBot) {
    dropDownContent = (
      <StyledUL>
        <StyledLi key="2asd">
          <StyledOption variant="link" onClick={onClickRemove}>
            <StyledFA icon="trash-alt" />
            Remove
          </StyledOption>
        </StyledLi>
      </StyledUL>
    );
  } else if (isServiceMessage) {
    dropDownContent = (
      <StyledUL>
        <StyledLi key="1asd">
          <StyledOption variant="link" onClick={onClickAdd}>
            <StyledFA icon="plus" />
            Add built-in quote
          </StyledOption>
        </StyledLi>
        <StyledLi key="2asd">
          <StyledOption variant="link" onClick={onClickAddNoQuote}>
            <StyledFA icon="times" />
            Send no quote
          </StyledOption>
        </StyledLi>

        <StyledLi key="3asd">
          <StyledOption variant="link" onClick={() => dispatch(showForm({ type: NOC_FORMS.SEND_MESSAGE }))}>
            <StyledFA icon="paper-plane" />
            Send a message
          </StyledOption>
        </StyledLi>
        <StyledLi key="4asd">
          <StyledOption variant="link" onClick={() => dispatch(showForm({ type: NOC_FORMS.ORDER_STATUS }))}>
            <StyledFA icon="pencil-alt" />
            Change order status
          </StyledOption>
        </StyledLi>
        <StyledLi key="7asd">
          <StyledOption variant="link" onClick={() => dispatch(showForm({ type: NOC_FORMS.REPORT_PAYMENT }))}>
            <StyledFA icon="comment-dollar" />
            Report ePlane&apos;s payment to the seller
          </StyledOption>
        </StyledLi>
        <StyledLi key="5asd">
          <StyledOption variant="link" onClick={() => dispatch(showForm({ type: NOC_FORMS.WIRE_TRANSFER }))}>
            <StyledFA icon="hand-holding-usd" />
            Confirm wire transfer received
          </StyledOption>
        </StyledLi>
        <StyledLi key="6asd">
          <StyledOption
            variant="link"
            onClick={() => dispatch(showForm({ type: NOC_FORMS.PAYMENT_REQUEST }))}
            disabled={isPaymentRequestDisabled}
          >
            <StyledFA icon="plus" />
            Add payment request to ePlane
          </StyledOption>
        </StyledLi>
      </StyledUL>
    );
  } else if (messageType === chatMsgType.TEXT) {
    dropDownContent = (
      <StyledUL>
        <StyledLi key="1asd">
          <StyledOption variant="link" onClick={onClickAdd}>
            <StyledFA icon="plus" />
            Add built-in quote
          </StyledOption>
        </StyledLi>
        <StyledLi key="2asd">
          <StyledOption variant="link" onClick={onClickAddNoQuote}>
            <StyledFA icon="times" />
            Send no quote
          </StyledOption>
        </StyledLi>
        <StyledLi key="1asd">
          <StyledOption variant="link" onClick={() => dispatch(showForm({ type: NOC_FORMS.IGNORE_TASK, messageId }))}>
            <StyledFA icon="cogs" />
            Add to ignore list
          </StyledOption>
        </StyledLi>
      </StyledUL>
    );
  } else {
    dropDownContent = (
      <StyledUL>
        <StyledLi key="1asd">
          <StyledOption variant="link" onClick={onClickAdd}>
            <StyledFA icon="plus" />
            Add built-in quote
          </StyledOption>
        </StyledLi>
        <StyledLi key="2asd">
          <StyledOption variant="link" onClick={onClickAddNoQuote}>
            <StyledFA icon="times" />
            Send no quote
          </StyledOption>
        </StyledLi>
      </StyledUL>
    );
  }

  return (
    <OverlayTrigger
      trigger="focus"
      placement="right"
      delay={{ show: 100, hide: 200 }}
      overlay={(
        <Popover id="popover-basic" onMouseEnter={onEnter} onMouseLeave={onLeave}>
          <Popover.Content>
            {dropDownContent}
          </Popover.Content>
        </Popover>
    )}
    >
      <div role="button" tabIndex="0">
        <LinkButton variant="link" tabIndex={0}>
          Actions
          <ArrowDownIcon icon="caret-down" />
        </LinkButton>
      </div>
    </OverlayTrigger>
  );
};

PopoverDropDown.propTypes = {
  messageId: PropTypes.string,
  quoteType: PropTypes.string,
  isAltPn: PropTypes.bool,
  quoteValidationStatus: PropTypes.string,
  messageType: PropTypes.string,
  isMessageSentByBot: PropTypes.bool,
  isServiceMessage: PropTypes.bool,
  isPaymentRequestDisabled: PropTypes.bool,

  onClickEdit: PropTypes.func,
  onClickAdd: PropTypes.func,
  onClickAddNoQuote: PropTypes.func,
  onClickRemove: PropTypes.func,
  onQuoteValidation: PropTypes.func,

  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
};

export default PopoverDropDown;
