import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const TemplateModal = ({
  templates, onAddClick, onHide, jsonValidator, ...other
}) => {
  const [row, setRow] = useState({ id: templates[0], jsonData: '' });

  useEffect(() => {
    if (!other.show) setRow({ id: templates[0], jsonData: '' });
  }, [other.show, templates]);

  return (
    <Modal centered onHide={onHide} {...other}>
      <Modal.Header closeButton>
        <Modal.Title>Add row</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="Template">
            <Form.Label>Template</Form.Label>
            <Form.Control
              name="id"
              value={row.id}
              as="select"
              onChange={(e) => setRow({ ...row, [e.target.name]: e.target.value })}
            >
              {templates.map((name) => (<option key={name} value={name}>{name}</option>))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="jsonData">
            <Form.Label>jsonData</Form.Label>
            <Form.Control
              name="jsonData"
              value={row.jsonData}
              onChange={(e) => setRow({ ...row, [e.target.name]: e.target.value })}
              isInvalid={!jsonValidator(row.jsonData)}
            />
            <Form.Control.Feedback type="invalid">Invalid json data</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" disabled={!jsonValidator(row.jsonData)} onClick={() => onAddClick(row)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

TemplateModal.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.string),
  jsonValidator: PropTypes.func,
  onAddClick: PropTypes.func,
  onHide: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default TemplateModal;
