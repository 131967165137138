import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  fetchDocuments,
  deleteDocument,
  uploadDocumentToUM,
  terminateUploadingProgress,
  removeErrorCode,
} from '../../../actions/userCompanyManagement/documentsActions';
import { getDocumentsStore, getUserStore } from '../../../selectors';
import { getDocumentList, getDocumentMap } from '../../../reducers/userCompanyManagement/documents';
import DocumentLoader from '../../FilesView/DocumentLoader';
import Loader from '../../Loader';
import FormTitleSection from '../../FormComponents/TitleSection';
import { TabContainer, DocumentUploader } from '../../StyledComponents';
import RemoveDocumentModal from '../../Modals/RemoveModal';
import ReplaceDocumentModal from './ReplaceDocumentModal';
import ProgressModal from './ProgressModal';
import ErrorModalsForDocSection from './ErrorModalsForDocSection';
import * as constants from '../../../constants/index';

const accept = '.pdf,.doc,.docx';
const RemoveDocModalContent = (
  <div>
    Are you sure you want to remove
    <br />
    this document?
  </div>
);

export default function CommercialAgreementPage() {
  const { userId } = useParams();
  const dispatch = useDispatch();

  const [modals, setModals] = useState({
    showRemoveDocModal: false,
    showReplaceDocModal: false,
  });
  const [document, setDocument] = useState({
    docId: null,
    docType: null,
  });
  const documentsStore = useSelector(getDocumentsStore);
  const { user: userEntity } = useSelector(getUserStore);
  const documentList = getDocumentList(documentsStore);
  const documentMap = getDocumentMap(documentList, constants);
  const {
    isFetching,
    isUploading,
    uploadingProgress,
    errorCode,
  } = documentsStore;

  useEffect(() => {
    dispatch(fetchDocuments(userId));
  }, [dispatch, userId]);

  const onShowRemoveDocModal = (docId) => {
    setModals((prev) => ({ ...prev, showRemoveDocModal: true }));
    setDocument((prev) => ({ ...prev, docId }));
  };

  const onShowReplaceDocModal = (docType) => {
    setModals((prev) => ({ ...prev, showReplaceDocModal: true }));
    setDocument((prev) => ({ ...prev, docType }));
  };

  const onHideModal = () => {
    setModals({
      showRemoveDocModal: false,
      showReplaceDocModal: false,
    });
    setDocument({ docId: null, docType: null });
  };

  if (isFetching) return <Loader />;
  if (!userEntity || !documentMap) return null;

  const { documentType } = constants;
  const isCompany = userEntity.user_type === constants.userType.COMPANY.id;
  let content;

  const genericDocumentList = documentMap.genericDocument.map((doc) => (
    <DocumentLoader
      key={doc.id}
      file={doc}
      accept={accept}
      onRemoveHandler={() => onShowRemoveDocModal(doc.id)}
    />
  ));
  const isoCertificatesList = documentMap.isoCertificate.map((doc) => (
    <DocumentLoader
      key={doc.id}
      file={doc}
      accept={accept}
      onRemoveHandler={() => onShowRemoveDocModal(doc.id)}
    />
  ));
  const repairCertificateList = documentMap.repairCertificate.map((doc) => (
    <DocumentLoader
      key={doc.id}
      file={doc}
      accept={accept}
      onRemoveHandler={() => onShowRemoveDocModal(doc.id)}
    />
  ));

  genericDocumentList.push(<DocumentLoader
    key="newGenericDocument"
    onChangeHandler={(e) => {
      dispatch(uploadDocumentToUM(e.target.files[0], userId, documentType.GENERIC_DOCUMENT.id));
      e.target.value = null;
    }}
    accept={accept}
  />);
  isoCertificatesList.push(<DocumentLoader
    key="newISOCertificate"
    onChangeHandler={(e) => {
      dispatch(uploadDocumentToUM(e.target.files[0], userId, documentType.ISO_CERTIFICATE.id));
      e.target.value = null;
    }}
    accept={accept}
  />);
  repairCertificateList.push(<DocumentLoader
    key="newRepairCertificate"
    onChangeHandler={(e) => {
      dispatch(uploadDocumentToUM(e.target.files[0], userId, documentType.REPAIR_CERTIFICATE.id));
      e.target.value = null;
    }}
    accept={accept}
  />);

  if (isCompany) {
    content = (
      <div>
        <FormTitleSection title="Commercial Agreements" showEditButton={false} />
        <TabContainer>
          <p>Company&#39;s commercial agreement (.pdf, .doc, .docx)</p>
          <DocumentUploader>

            <DocumentLoader
              file={documentMap.salesAgreement}
              placeholderText="Sales Agreement"
              onRemoveHandler={() => onShowReplaceDocModal(documentType.SALES_AGREEMENT.id)}
              onChangeHandler={(e) => {
                dispatch(uploadDocumentToUM(e.target.files[0], userId, documentType.SALES_AGREEMENT.id));
                e.target.value = null;
              }}
              accept={accept}
            />

            <DocumentLoader
              file={documentMap.purchaseAgreement}
              placeholderText="Purchase Agreement"
              onRemoveHandler={() => onShowRemoveDocModal(documentMap.purchaseAgreement.id)}
              onChangeHandler={(e) => {
                dispatch(uploadDocumentToUM(e.target.files[0], userId, documentType.PURCHASE_AGREEMENT.id));
                e.target.value = null;
              }}
              accept={accept}
            />

            <DocumentLoader
              file={documentMap.exchangeAgreement}
              placeholderText="Exchange Agreement"
              onRemoveHandler={() => onShowRemoveDocModal(documentMap.exchangeAgreement.id)}
              onChangeHandler={(e) => {
                dispatch(uploadDocumentToUM(e.target.files[0], userId, documentType.EXCHANGE_AGREEMENT.id));
                e.target.value = null;
              }}
              accept={accept}
            />

            <DocumentLoader
              file={documentMap.loanAgreement}
              placeholderText="Loan Agreement"
              onRemoveHandler={() => onShowRemoveDocModal(documentMap.loanAgreement.id)}
              onChangeHandler={(e) => {
                dispatch(uploadDocumentToUM(e.target.files[0], userId, documentType.LOAN_AGREEMENT.id));
                e.target.value = null;
              }}
              accept={accept}
            />
            <DocumentLoader
              file={documentMap.repairAgreement}
              placeholderText="Repair Agreement"
              onRemoveHandler={() => onShowRemoveDocModal(documentMap.repairAgreement.id)}
              onChangeHandler={(e) => {
                dispatch(uploadDocumentToUM(e.target.files[0], userId, documentType.REPAIR_AGREEMENT.id));
                e.target.value = null;
              }}
              accept={accept}
            />
            {genericDocumentList}
          </DocumentUploader>
        </TabContainer>

        <FormTitleSection title="ISO Certificates" showEditButton={false} />
        <TabContainer>
          <p>Company&#39;s ISO Certificates (.pdf, .doc, .docx)</p>
          <DocumentUploader>
            {isoCertificatesList}
          </DocumentUploader>
        </TabContainer>

        <FormTitleSection title="Repair Shop Certificates (MRO)" showEditButton={false} />
        <TabContainer>
          <p>Company&#39;s  FAA145/EASA145 certificate or equivalent (.pdf, .doc, .docx)</p>
          <DocumentUploader>
            {repairCertificateList}
          </DocumentUploader>
        </TabContainer>
      </div>
    );
  } else {
    content = (
      <div>
        <FormTitleSection title="Commercial Agreements" showEditButton={false} />
        <TabContainer>
          <p>User&#39;s purchase agreement (.pdf, .doc, .docx)</p>
          <DocumentUploader>
            <DocumentLoader
              file={documentMap.purchaseAgreement}
              placeholderText="Purchase Agreement"
              onRemoveHandler={() => onShowRemoveDocModal(documentMap.purchaseAgreement.id)}
              onChangeHandler={(e) => {
                dispatch(uploadDocumentToUM(e.target.files[0], userId, documentType.PURCHASE_AGREEMENT.id));
                e.target.value = null;
              }}
              accept={accept}
            />
          </DocumentUploader>
        </TabContainer>
      </div>
    );
  }

  return (
    <div>
      <RemoveDocumentModal
        show={modals.showRemoveDocModal}
        onRemove={() => {
          onHideModal();
          dispatch(deleteDocument(userId, document.docId));
        }}
        onHide={onHideModal}
        text={RemoveDocModalContent}
      />

      <ReplaceDocumentModal
        show={modals.showReplaceDocModal}
        onReplace={(e) => dispatch(uploadDocumentToUM(e.target.files[0], userId, document.docType))}
        onHide={onHideModal}
      />

      <ErrorModalsForDocSection errorCode={errorCode} onHide={() => dispatch(removeErrorCode())} />

      <ProgressModal
        show={isUploading}
        now={uploadingProgress}
        onCancel={() => dispatch(terminateUploadingProgress())}
      />
      {content}
    </div>
  );
}

const docPT = PropTypes.shape({
  id: PropTypes.string,
  document_id: PropTypes.string,
  document_type: PropTypes.number,
  file_name: PropTypes.string,
  url: PropTypes.string,
});

CommercialAgreementPage.propTypes = {
  documentMap: PropTypes.shape({
    exchangeAgreement: docPT,
    genericDocument: PropTypes.arrayOf(docPT),
    isoCertificate: PropTypes.arrayOf(docPT),
    loanAgreement: docPT,
    purchaseAgreement: docPT,
    repairAgreement: docPT,
    repairCertificate: PropTypes.arrayOf(docPT),
    salesAgreement: docPT,
  }),
  constants: PropTypes.shape({
    documentType: PropTypes.shape({
      GENERIC_DOCUMENT: PropTypes.shape({ id: PropTypes.number }),
      ISO_CERTIFICATE: PropTypes.shape({ id: PropTypes.number }),
      REPAIR_CERTIFICATE: PropTypes.shape({ id: PropTypes.number }),
      REPAIR_AGREEMENT: PropTypes.shape({ id: PropTypes.number }),
      SALES_AGREEMENT: PropTypes.shape({ id: PropTypes.number }),
      PURCHASE_AGREEMENT: PropTypes.shape({ id: PropTypes.number }),
      EXCHANGE_AGREEMENT: PropTypes.shape({ id: PropTypes.number }),
      LOAN_AGREEMENT: PropTypes.shape({ id: PropTypes.number }),
    }),
    userType: PropTypes.shape({
      COMPANY: PropTypes.shape({ id: PropTypes.number }),
    }),
  }),
  userEntity: PropTypes.shape({
    user_type: PropTypes.number,
  }),
};
