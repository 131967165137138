import emailValidator from '../../../utils/emailValidator';
import createErrorValidationObj from '../../../utils/createErrorValidationObj';

export default {
  invoice_email: (email) => {
    if (email && !emailValidator(email)) {
      return createErrorValidationObj('Please enter a valid email address');
    }
    return null;
  },
  price: (value) => {
    if (value === '') return null;
    const numVal = +value;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(numVal) || numVal < 0) {
      return createErrorValidationObj('Please enter a positive number');
    }
    if (numVal < 0.001 || numVal > 10000) {
      return createErrorValidationObj('Price range is $0.001-10,000');
    }
    return null;
  },
  expiration_date: (date) => {
    if (!date) {
      return createErrorValidationObj('Please enter a valid expiration date');
    }
    return null;
  },
};
