import history from '../history';
import { AUTHORIZATION_FAIL } from '../constants';

// eslint-disable-next-line import/prefer-default-export
export function authorizationFail(pathArg) {
  const path = pathArg || history.location.pathname;
  return {
    type: AUTHORIZATION_FAIL,
    payload: path,
  };
}
