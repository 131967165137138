export const mailchimpActionTypes = {
  SET_TAG: {
    id: 'set_tag',
    name: 'Set tag',
  },
};
export const mailchimpConditionTypes = {
  EQUAL: {
    id: 'eq',
    name: 'Equals',
  },
  NOT_EQUAL: {
    id: 'ne',
    name: 'Not equals',
  },
  GREATER_THAN: {
    id: 'gt',
    name: 'Greater than',
  },
  GREATER_THAN_OR_EQUAL: {
    id: 'gte',
    name: 'Greater than or equals',
  },
  LESS_THAN: {
    id: 'lt',
    name: 'Less than',
  },
  LESS_THAN_OR_EQUAL: {
    id: 'lte',
    name: 'Less than or equals',
  },
  IN_LIST: {
    id: 'in',
    name: 'In list',
  },
};
export const mailchimpConditionStatus = {
  SYNCHRONIZED: {
    id: 1,
    name: 'Synchronized',
  },
  IN_PROGRESS: {
    id: 2,
    name: 'In progress',
  },
  ARCHIVED: {
    id: 3,
    name: 'Archived',
  },
  SYNCHRONIZE_ERROR: {
    id: 4,
    name: 'Synchronize error',
  },
};
export const mailchimpSearchFields = {
  USER_AP_ENABLED: {
    id: 4,
    name: 'Company: AP enabled',
  },
  USER_ACTIVE_SELLER: {
    id: 12,
    name: 'Company: Active as Seller',
  },
  USER_AIRLINE: {
    id: 3,
    name: 'Company: Airline',
  },
  USER_BROKER: {
    id: 7,
    name: 'Company: Broker',
  },
  USER_CITY: {
    id: 17,
    name: 'Company: City',
  },
  USER_COMPANY_NAME: {
    id: 14,
    name: 'Company: Company Name',
  },
  USER_COUNTRY: {
    id: 15,
    name: 'Company: Country',
  },
  USER_DISTRIBUTOR: {
    id: 6,
    name: 'Company: Distributor',
  },
  HAS_AP_AL_BARRING: {
    id: 25,
    name: 'Company: Has AutoPilot Airline barring record',
  },
  USER_HAS_INVENTORY: {
    id: 9,
    name: 'Company: Has inventory',
  },
  USER_HAS_REPAIR: {
    id: 10,
    name: 'Company: Has repair',
  },
  USER_MRO: {
    id: 1,
    name: 'Company: MRO',
  },
  USER_OEM: {
    id: 2,
    name: 'Company: OEM',
  },
  USER_OTHER: {
    id: 8,
    name: 'Company: Other',
  },
  USER_PMA: {
    id: 23,
    name: 'Company: PMA',
  },
  USER_STATE: {
    id: 16,
    name: 'Company: State',
  },
  USER_ZIP_CODE: {
    id: 18,
    name: 'Company: Zip code',
  },
  USER_AP_REQUESTED: {
    id: 5,
    name: 'Company/User: AP requested',
  },
  USER_ACTIVE_BUYER: {
    id: 11,
    name: 'Company/User: Active as Buyer',
  },
  USER_CREATED_AT: {
    id: 19,
    name: 'Company/User: Create Date',
  },
  USER_UPDATED_AT: {
    id: 20,
    name: 'Company/User: Last Update Date',
  },
  CUSTOMER_COUNTRY: {
    id: 21,
    name: 'User: Country',
  },
  USER_HAS_PRO: {
    id: 24,
    name: 'User: Is PRO',
  },
  CUSTOMER_SUBSCRIPTION: {
    id: 22,
    name: 'User: Subscription',
  },
  USER_USER_TYPE: {
    id: 13,
    name: 'User: Type',
  },
};
export const mailchimpSubscriptionLists = {
  CHIEF: {
    id: 'chief',
    name: 'Main subscription list',
  },
  PRODUCT: {
    id: 'product',
    name: 'Product updates subscription',
  },
  NEWS_EVENT: {
    id: 'news_event',
    name: 'News and events subscription',
  },
};
export const mailchimpTags = {
  AUTOPILOT: 'Autopilot',
  PRO: 'PRO',
  COUNTRY: 'Country',
  COMPANY_TYPE_AIRLINE: 'Airline',
  COMPANY_TYPE_MRO: 'MRO',
  COMPANY_TYPE_PMA: 'PMA',
  COMPANY_TYPE_OEM: 'OEM',
  COMPANY_TYPE_BROKER: 'Broker',
  COMPANY_TYPE_DISTRIBUTOR: 'Distributor',
  COMPANY_TYPE_OTHER: 'Other',
};
export const syncStatus = {
  STATUS_OK: 'ok',
  STATUS_PROGRESS: 'progress',
  STATUS_ERROR: 'error',
  STATUS_DISABLED: 'disabled',
};
