import React from 'react';
import { Fade, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import * as consts from '../../../constants';
import EditButtonImg from '../../../assets/btn-edit-sprite.png';
import H2Normal from '../../H2/index';

const TitleSection = styled.div`
  border-bottom: 4px solid ${consts.color.lightGrey};
  overflow: hidden;
`;

const Title = styled(H2Normal)`
  font-weight: 700;
  color: ${consts.color.blue};
  letter-spacing: 0.55px;
  font-size: 22px;
  float: left;
  display: block;
  margin-top: 0;
`;

/* eslint-disable no-confusing-arrow */
const EditButton = styled.div`
  cursor: pointer;
  display: block;
  float: right;
  width: 23px;
  height: 23px;
  background: url(${EditButtonImg}) no-repeat 0 0;
  ${(props) => props.disabled ? 'filter: grayscale(100%); cursor: default;' : ''}
  
  &:hover {
    ${(props) => props.disabled ? '' : 'background-position: 0 -66px;'} 
  }
`;
/* eslint-enable */

const SubTitleSection = styled(TitleSection)`
  margin-top: 25px;
  margin-bottom: 10px;
  border-bottom: 2px solid ${consts.color.lightGrey};
  margin-right: 70px;
`;

const SubTitle = styled(Title)`
  font-weight: 700;
  font-size: 20px;
`;

const SubTitleItem = styled(Title)`
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 15px 0;
`;

export default class FormTitleSection extends React.PureComponent {
  render() {
    const {
      title,
      EditButtonClick,
      showEditButton,
    } = this.props;
    return (
      <Row>
        <Col sm={12}>
          <TitleSection>
            <Title>{title}</Title>
            <Fade in={showEditButton} timeout={100}>
              <EditButton title="Edit Form" disabled={EditButtonClick === null} onClick={EditButtonClick} data-testid="edit_form" />
            </Fade>
          </TitleSection>
        </Col>
      </Row>
    );
  }
}

FormTitleSection.propTypes = {
  title: PropTypes.string,
  EditButtonClick: PropTypes.func,
  showEditButton: PropTypes.bool,
};

FormTitleSection.defaultProps = {
  showEditButton: true,
};

export const FormSubTitle = ({ title, showEditButton, EditButtonClick }) => (
  <Row>
    <Col sm={12}>
      <SubTitleSection>
        <SubTitle as="h3">{title}</SubTitle>
        {showEditButton
          && <EditButton title="Edit Form" disabled={EditButtonClick === null} onClick={EditButtonClick} data-testid="edit_form" />}
      </SubTitleSection>
    </Col>
  </Row>
);

export const FormSubTitleItem = ({ title }) => (
  <Row>
    <Col sm={12}>
      <SubTitleItem as="h4">{title}</SubTitleItem>
    </Col>
  </Row>
);

FormSubTitle.propTypes = {
  title: PropTypes.string,
  EditButtonClick: PropTypes.func,
  showEditButton: PropTypes.bool,
};

FormSubTitleItem.propTypes = {
  title: PropTypes.string,
};

FormSubTitle.defaultProps = {
  showEditButton: true,
};
