import React, { useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Fade } from 'react-bootstrap';

import { color, BOT_SERVICE_MSG } from '../../../constants';
import Avatar from './Avatar';
import chatMessageContent from './chatMessageContent';
import dateFormat from '../../../utils/dateFormat';
import PopoverDropDown from '../../NetworkOperationsCenter/PopoverDropDown';
import { chatMsgType, creatorTypes } from '../../../constants/index';
import ContextMenu from '../../ContextMenu/ContextMenu';

const WrapperTop = styled.div`
  transform: scaleX(${({ alignRight }) => (alignRight ? -1 : 1)});
  display: flex;
  &:hover {
    ${(props) => (!props.isHighlighted ? `background: ${color.whiteSmoke}` : '')};
  }
  border-left: ${(props) => (props.isOpenedTask ? '5px solid #f0b152' : '')};
  ${(props) => (props.isOpenedTask || props.isHighlighted ? 'padding-left: 10px' : '')};
  background: ${(props) => (props.isHighlighted ? '#f6d6a7' : '')};
  justify-content: space-between;
`;
const MessageBlock = styled.div`
  margin-left: 67px;
  max-width: 480px;
  min-width: 278px;
  display: inline-block;
  position: relative;
  margin: 10px 0 10px 67px;

  &::before {
    border-color: transparent ${(props) => props.arrowColor} transparent transparent;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 9px 6px 0;
    display: block;
    position: absolute;
    left: -7px;
    top: 26px;
  }
`;
const BubbleWrapper = styled.div`
  transform: scaleX(${({ alignRight }) => (alignRight ? -1 : 1)});

  border: 1px solid rgba(0,0,0,0);
  overflow: hidden;
`;
const Date = styled.div`
  font-weight: 300;
  font-size: 13px;
  color: ${color.grey};
  letter-spacing: 0.325px;
  margin-top: 6px;

  text-align: ${(props) => props.textAlign};
`;
const ActionsContainer = styled.div`
  margin-left: 20px;
  margin-right: 10px;
  margin-top: -18px;
  font-weight: 900;
  align-self: center;
  transform: scaleX(${({ alignRight }) => (alignRight ? -1 : 1)});
`;

const ChatMessage = ({
  msg,
  buyerSellerInfo,
  showQuoteModal,
  removeBotQuote,
  addBotNoQuote,
  addToIgnoreList,
  quoteValidate,
  blockQuotesDropdown,
  isOpenedTask,
  isIntoView,
  isHighlighted,
}) => {
  const [hoverState, setHoverState] = useState(false);
  const onMouseEnter = () => setHoverState(true);
  const onMouseLeave = () => setHoverState(false);
  const ref = createRef();

  useEffect(() => {
    if (isIntoView && ref.current.scrollIntoView) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntoView]);

  const noQuote = msg.messageType === chatMsgType.NO_QUOTE;
  const isMessageSentByBot = msg.creatorType === creatorTypes.BOT || msg.creatorType === creatorTypes.EPLANE;
  const isServiceMessage = isMessageSentByBot && msg.messageId === BOT_SERVICE_MSG;
  const isMessageHighlighted = hoverState && !msg.isBuyer && !blockQuotesDropdown && !(noQuote && !isMessageSentByBot);

  return (
    <ContextMenu addToIgnoreList={addToIgnoreList}>
      <WrapperTop
        ref={ref}
        isOpenedTask={isOpenedTask}
        isHighlighted={isHighlighted}
        alignRight={msg.isBuyer}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <MessageBlock arrowColor={getMainColor(msg.isBuyer)}>
          <Avatar isBuyer={msg.isBuyer} isBot={isMessageSentByBot} />
          <BubbleWrapper alignRight={msg.isBuyer}>
            {chatMessageContent(msg, buyerSellerInfo)}

            <Date textAlign={msg.isBuyer ? 'right' : 'left'}>
              {dateFormat(msg.date)}
            </Date>
          </BubbleWrapper>
        </MessageBlock>

        <Fade in={isMessageHighlighted} timeout={200} unmountOnExit>
          <ActionsContainer alignRight={msg.isBuyer}>
            <PopoverDropDown
              messageId={msg.messageId}
              isAltPn={msg?.quoteRaw?.is_alt_pn}
              quoteType={msg.quoteType}
              messageType={msg.messageType}
              isMessageSentByBot={isMessageSentByBot}
              isServiceMessage={isServiceMessage}
              isPaymentRequestDisabled={msg.extraInfo?.sellerHasActiveStripe}
              onClickAdd={() => showQuoteModal(msg.messageId)}
              onClickAddNoQuote={() => addBotNoQuote(msg.messageId)}
              onClickEdit={() => showQuoteModal(msg.messageId)}
              onClickRemove={() => removeBotQuote(msg.messageId)}
              onEnter={onMouseEnter}
              onLeave={onMouseLeave}
              quoteValidationStatus={msg.quoteValidationStatus}
              onQuoteValidation={(...params) => quoteValidate(...params, msg.messageId)}
            />
          </ActionsContainer>
        </Fade>
      </WrapperTop>
    </ContextMenu>
  );
};

ChatMessage.propTypes = {
  msg: PropTypes.shape({
    messageId: PropTypes.string,
    isBuyer: PropTypes.bool,
    quoteType: PropTypes.string,
    quoteValidationStatus: PropTypes.string,

    senderInfo: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      company_name: PropTypes.string,
    }),
    quote: PropTypes.arrayOf(PropTypes.string),
    quotePrice: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    pnDetailsAvailable: PropTypes.bool,
    text: PropTypes.string,
    attachedDocs: PropTypes.arrayOf(PropTypes.object),
    removedDocNames: PropTypes.arrayOf(PropTypes.string),
    date: PropTypes.string,
    messageType: PropTypes.string,
    creatorType: PropTypes.string,
    quoteRaw: PropTypes.shape({
      is_alt_pn: PropTypes.bool,
    }),
    extraInfo: PropTypes.shape({
      sellerHasActiveStripe: PropTypes.bool,
    }),
  }),
  buyerSellerInfo: PropTypes.shape({
    buyer: PropTypes.shape({
      company_name: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    seller: PropTypes.shape({
      company_name: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
  }),
  blockQuotesDropdown: PropTypes.bool,
  isOpenedTask: PropTypes.bool,
  isIntoView: PropTypes.bool,
  isHighlighted: PropTypes.bool,

  showQuoteModal: PropTypes.func.isRequired,
  removeBotQuote: PropTypes.func.isRequired,
  addBotNoQuote: PropTypes.func.isRequired,
  addToIgnoreList: PropTypes.func.isRequired,
  quoteValidate: PropTypes.func.isRequired,
};

export default ChatMessage;

const getMainColor = (isBuyer) => (isBuyer ? color.lightCyan : color.lightGrey);
