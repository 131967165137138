import axios from 'axios';

import {
  COMPANIES_GET_LIST,
  COMPANIES_GET_LIST_SUCCESS,
  COMPANIES_SEARCH_START,
  COMPANIES_SEARCH_FINISHED,

  COMPANIES_GET_LIST_FAIL,
  COMPANIES_ERROR_REMOVE,
} from '../../constants';
import errorHandler from '../requestErrorHandler';
import { updateComments } from '../chatActions';

export const startFetchingCompanies = () => ({
  type: COMPANIES_GET_LIST,
});
export const finishFetchingCompanies = (data) => ({
  type: COMPANIES_GET_LIST_SUCCESS,
  payload: data,
});

export const startSearchingForCompanies = () => ({
  type: COMPANIES_SEARCH_START,
});
export const finishSearchingForCompanies = (data) => ({
  type: COMPANIES_SEARCH_FINISHED,
  payload: data,
});
export const failRequestForCompanies = () => ({
  type: COMPANIES_GET_LIST_FAIL,
});

export const removeCompaniesSearchError = () => ({
  type: COMPANIES_ERROR_REMOVE,
});

export const fetchCompanies = (searchParams) => (dispatch) => {
  dispatch(startFetchingCompanies());

  const reqParams = {
    q: '',
    o: (searchParams && searchParams.o) || 'updated_at',
    p: searchParams && searchParams.p,
    c: (searchParams && searchParams.c) || 10,
  };

  axios('rest/V1/eplane/backoffice/companies/search', { params: reqParams }).then((response) => {
    dispatch(finishFetchingCompanies(response?.data));
  }).catch((e) => {
    errorHandler(e.response, dispatch, failRequestForCompanies, e);
  });
};

export const searchForCompanies = (searchParams) => (dispatch) => {
  dispatch(startSearchingForCompanies());

  const reqParams = {
    q: (searchParams && searchParams.q) || '',
    o: searchParams.o,
    p: searchParams && searchParams.p,
    c: (searchParams && searchParams.c) || 100,
    s: (searchParams && searchParams.s) || 'asc',
  };

  axios('rest/V1/eplane/backoffice/companies/search', { params: reqParams }).then((response) => {
    dispatch(finishSearchingForCompanies(response?.data));
    dispatch(updateComments());
  }).catch((e) => {
    errorHandler(e.response, dispatch, failRequestForCompanies, e);
  });
};
