import {
  WEIGHT_LIST_CLONE,
  WEIGHT_LIST_CLONE_SUCCESS,
  WEIGHT_LIST_CLONE_FAIL,

  WEIGHT_LIST_UPLOADING_START,
  WEIGHT_LIST_UPLOADING_FINISH,
  WEIGHT_LIST_UPLOADING_PROGRESS_SET,
  WEIGHT_LIST_UPLOADING_PROGRESS_RESET,
  WEIGHT_LIST_UPLOADING_TERMINATE,
  WEIGHT_LIST_ERROR_REMOVE,
  WEIGHT_LIST_UPLOADING_ERROR_OCCURRED,

  AXIOS_CANCELLED_MESSAGE,
} from '../../constants';

const initialState = {
  cloneResults: null,
  uploadingProgress: null,
  source: null,
  isCloning: false,
  isUploading: false,
  errorCode: null,
};

export default function weightListReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case WEIGHT_LIST_CLONE:
      return {
        ...state,
        isCloning: true,
      };
    case WEIGHT_LIST_CLONE_SUCCESS:
      return {
        ...state,
        cloneResults: payload,
        isCloning: false,
      };
    case WEIGHT_LIST_CLONE_FAIL:
      return {
        ...state,
        errorCode: payload,
        isCloning: false,
      };

    case WEIGHT_LIST_UPLOADING_START:
      return {
        ...state,
        isUploading: true,
        uploadingProgress: null,
        source: payload,
      };

    case WEIGHT_LIST_UPLOADING_FINISH:
      return {
        ...state,
        isUploading: false,
        source: false,
      };

    case WEIGHT_LIST_UPLOADING_PROGRESS_SET:
      return { ...state, uploadingProgress: payload };
    case WEIGHT_LIST_UPLOADING_PROGRESS_RESET:
      return { ...state, uploadingProgress: null };
    case WEIGHT_LIST_UPLOADING_TERMINATE:
      if (state.source) {
        state.source.cancel(AXIOS_CANCELLED_MESSAGE);
      }
      return {
        ...state,
        uploadingProgress: null,
        isUploading: false,
        source: null,
      };

    case WEIGHT_LIST_UPLOADING_ERROR_OCCURRED:
      return {
        ...state,
        errorCode: payload,
        isUploading: false,
        uploadingProgress: null,
      };
    case WEIGHT_LIST_ERROR_REMOVE:
      return { ...state, errorCode: null };

    default: return state;
  }
}
