import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Action from './Action';
import { color } from '../../../constants';

const StyledUl = styled.ul`
  list-style:none;
  padding: 0;
`;
const DateDevider = styled.div`
  background-color: ${color.altoGrey};
  padding: 4px 5px 2px 4px;
  margin-top: 10px;
  p {
    margin: 0 auto;
    color: ${color.grey};
    padding: 6px 5px 5px 19px;
  }
`;
const HorizontalLine = styled.hr`
  border-top: 2px solid ${color.lightGrey};
  margin: 10px 15px 0 15px;
`;

const ActionsList = ({ title, actions }) => {
  const renderItem = actions && actions.map((task, index) => (
    <div key={index.toString()}>
      <Action
        sellerName={task.admin_customer_full_name}
        date={task.created_at}
        message={task.display_text && task.display_text.join(', ')}
      />
      {index !== (actions.length - 1) && <HorizontalLine />}
    </div>
  ));

  return (
    <>
      <DateDevider>
        <p>{title}</p>
      </DateDevider>
      <StyledUl>{renderItem}</StyledUl>
    </>
  );
};

ActionsList.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape({
    customerName: PropTypes.string,
    date: PropTypes.string,
    message: PropTypes.string,
  })),
};

export default ActionsList;
