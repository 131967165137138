import {
  CUSTOMER_GET,
  CUSTOMER_GET_SUCCESS,
  CUSTOMER_GET_FAIL,
  CUSTOMER_ERROR_REMOVE,
  CUSTOMER_SAVE,
  CUSTOMER_SAVE_SUCCESS,
  CUSTOMER_SAVE_FAIL,
  CUSTOMER_PASSWORD_SAVE,
  CUSTOMER_PASSWORD_SAVE_SUCCESS,
  CUSTOMER_PASSWORD_SAVE_FAIL,
  CUSTOMER_PASSWORD_RESET,
  CUSTOMER_PASSWORD_RESET_SUCCESS,
  CUSTOMER_PASSWORD_RESET_FAIL,
  CUSTOMER_STATE_CLEAR,
  CUSTOMER_EMAIL_RESEND,
  CUSTOMER_EMAIL_RESEND_SUCCESS,
  CUSTOMER_EMAIL_RESEND_FAIL,
  CUSTOMER_ACCOUNT_CONFIRMATION,
  CUSTOMER_ACCOUNT_CONFIRMATION_SUCCESS,
  CUSTOMER_ACCOUNT_CONFIRMATION_FAIL,
  CUSTOMER_ERROR_CLEAR,
  // update current customer if it's removed
  COMPANY_CUSTOMER_REMOVE_SUCCESS,

  USER_CHECKING_CUSTOMER_SUCCESS,
} from '../../constants';

const initialState = {
  customer: null,
  isFetching: false,
  isSaving: false,
  isAccountConfirmed: false,
  isEmailResend: false,
  errorCode: null,
};

const customerReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case CUSTOMER_GET: return { ...state, isFetching: true };
    case CUSTOMER_GET_SUCCESS: return {
      ...state,
      isFetching: false,
      customer: payload,
      errorCode: null,
      isAccountConfirmed: false,
      isEmailResend: false,
    };
    case CUSTOMER_GET_FAIL: return { ...state, isFetching: false, errorCode: payload };
    case CUSTOMER_SAVE: return { ...state, isSaving: true };
    case CUSTOMER_SAVE_SUCCESS: return {
      ...state,
      isSaving: false,
      customer: payload,
      errorCode: null,
      isAccountConfirmed: false,
      isEmailResend: false,
    };
    case CUSTOMER_SAVE_FAIL: return { ...state, isSaving: false, errorCode: payload };
    case CUSTOMER_PASSWORD_SAVE: return { ...state, isSaving: true };
    case CUSTOMER_PASSWORD_SAVE_SUCCESS: return {
      ...state,
      isSaving: false,
      errorCode: null,
    };
    case CUSTOMER_PASSWORD_SAVE_FAIL: return { ...state, isSaving: false, errorCode: payload };
    case CUSTOMER_PASSWORD_RESET: return { ...state, isSaving: true };
    case CUSTOMER_PASSWORD_RESET_SUCCESS: return {
      ...state,
      isSaving: false,
      errorCode: null,
    };
    case CUSTOMER_PASSWORD_RESET_FAIL: return { ...state, isSaving: false, errorCode: payload };
    case CUSTOMER_EMAIL_RESEND: return { ...state, isSaving: true };
    case CUSTOMER_EMAIL_RESEND_SUCCESS:
      return {
        ...state,
        isSaving: false,
        isEmailResend: true,
        errorCode: null,
      };
    case CUSTOMER_EMAIL_RESEND_FAIL:
      return {
        ...state,
        isSaving: false,
        errorCode: payload,
      };
    case CUSTOMER_ACCOUNT_CONFIRMATION: return { ...state, isSaving: true };
    case CUSTOMER_ACCOUNT_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isSaving: false,
        isAccountConfirmed: true,
        errorCode: null,
      };
    case CUSTOMER_ACCOUNT_CONFIRMATION_FAIL:
      return {
        ...state,
        isSaving: false,
        errorCode: payload,
      };
    case CUSTOMER_ERROR_REMOVE: return { ...state, errorCode: null };

    case CUSTOMER_STATE_CLEAR: return initialState;
    case USER_CHECKING_CUSTOMER_SUCCESS: return {
      ...state,
      customer: payload,
      isAccountConfirmed: false,
      isEmailResend: false,
    };

    case COMPANY_CUSTOMER_REMOVE_SUCCESS: {
      // payload is an array of customers from a common company, one of that has been removed
      const currCustomer = state.customer && payload.find((c) => c.entity_id === state.customer.entity_id);
      if (currCustomer) {
        return {
          ...state,
          customer: currCustomer,
        };
      }
      return state;
    }
    case CUSTOMER_ERROR_CLEAR: return { ...state, errorCode: null };

    default: return state;
  }
};

export default customerReducer;
