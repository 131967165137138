import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Pagination from '../../Pagination';

const Container = styled.div`
  margin: 20px 0;
  text-align: ${(props) => props.alignment || 'right'};
  ul {
    justify-content: ${(props) => (props.alignment === 'left' ? 'flex-start' : 'flex-end')};
  }
`;
export default function PaginationTable({ alignment, ...other }) {
  return (
    <Container alignment={alignment}>
      <Pagination {...other} />
    </Container>
  );
}

PaginationTable.propTypes = {
  alignment: PropTypes.string,
};
