import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { FormSubTitle } from '../../FormComponents/TitleSection';
import { TabContainer, StyledLinkButton as LinkButton } from '../../StyledComponents';
import InventoryUploadModal from '../Table/InventoryUploadModal';

class UploadInventorySubsection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showInventoryModal: false,
    };
  }

  onUploadClick = () => {
    this.setState({ showInventoryModal: true });
  }

  onUploadHide = () => {
    this.setState({ showInventoryModal: false });
  }

  render() {
    const { showInventoryModal } = this.state;
    const { userId } = this.props;
    return (
      <>
        <FormSubTitle
          title="Upload Inventory / Capabilities"
          showEditButton={false}
        />
        <TabContainer>
          <LinkButton variant="link" onClick={this.onUploadClick}>
            <FontAwesomeIcon icon="file" />
            &nbsp;
            Upload List
          </LinkButton>
        </TabContainer>
        <InventoryUploadModal userId={userId} show={showInventoryModal} onHide={this.onUploadHide} />
      </>
    );
  }
}

UploadInventorySubsection.propTypes = {
  userId: PropTypes.string,
};

export default UploadInventorySubsection;
