import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import warnImage from './ic-warn-blue-big.png';

const WarnImg = styled.div`
  background: url(${warnImage}) no-repeat;
  width: 170px;
  height: 124px;
  margin: 20px auto 30px;
  position: relative;
  left: 8px;
`;

export default function WarningModal({
  header,
  body,
  footer,
  ...other
}) {
  return (
    <Modal centered {...other}>
      {header && <Modal.Header closeButton>{header}</Modal.Header>}
      <Modal.Body>
        <WarnImg />
        {body}
      </Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
}
const basicType = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.string,
]);
WarningModal.propTypes = {
  header: basicType,
  body: basicType,
  footer: basicType,
};
