import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import WarningModal from '../../WarningModal';
import { ButtonContainer, Button } from '../styledComponents';

const ReasonsListCaption = styled.h3`
  font-size: 16px;
  padding: 0 30px;
`;
const ReasonsList = styled.ul`
  font-size: 16px;
  padding: 0 50px;
`;
const Text = styled.div`
  font-size: 20px;
  text-align: center;
  padding: 0 20px;
`;

const ConfirmationLastUserModal = ({
  confirm,
  confirmBtnText,
  confirmBtnProcessingText,
  cancelBtnText,
  children,
  isProcessing,
  hasInventory,
  hasPro,
  userId,
  ...other
}) => {
  const body = (() => {
    if (hasInventory || hasPro) {
      return (
        <>
          <Text>
            This is the last active user in the company. The action can&apos;t be performed.
          </Text>
          <br />
          <ReasonsListCaption>The reason might be the following:</ReasonsListCaption>
          <ReasonsList>
            {hasInventory && <li>the company has inventory in its catalog</li>}
            {hasInventory && <li>the company has non-synced inventory (Tier 2)</li>}
            {hasPro && <li>the company has a pro plan, cancel it and try again</li>}
          </ReasonsList>
        </>
      );
    }
    return (
      <Text>
        This is the last active user in the company. Are you sure you want to proceed?
        <br />
        <br />
      </Text>
    );
  })();

  const footer = (
    <div>
      <ButtonContainer btnWidth="120px" space="10px">
        <Button
          disabled={hasInventory || hasPro}
          variant="primary"
          onClick={confirm}
        >
          {isProcessing ? confirmBtnProcessingText : confirmBtnText}
        </Button>
        <Button onClick={other.onHide} variant="secondary">{cancelBtnText}</Button>
      </ButtonContainer>
    </div>
  );
  return <WarningModal body={body} footer={footer} {...other} />;
};

ConfirmationLastUserModal.defaultProps = {
  confirmBtnText: 'Yes',
  cancelBtnText: 'Cancel',
};

ConfirmationLastUserModal.propTypes = {
  onHide: PropTypes.func,
  confirm: PropTypes.func,
  children: PropTypes.node,
  confirmBtnText: PropTypes.string,
  confirmBtnProcessingText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  hasInventory: PropTypes.bool,
  hasPro: PropTypes.bool,
  isProcessing: PropTypes.bool,
  userId: PropTypes.string,
};

export default ConfirmationLastUserModal;
