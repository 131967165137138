import React from 'react';
import PropTypes from 'prop-types';
import { Col, Button as ButtonBS } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import history from '../../history';
import TopBar from '../TopBar';

const Main = styled(Col)`
  && {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 32px;
    position: relative;
  }
`;
const Button = styled(ButtonBS)`
  position: absolute;
  left: 40px;
  top: 40px;
  @media (max-width: 1200px) {
    && {
      display: none;
    }
  }
`;

export default function Container(props) {
  const { links, children } = props;
  const isGoBack = history.location.key != null && history.length > 1;
  return (
    <Main>
      <TopBar links={links} />
      {isGoBack
      && (
        <Button variant="outline-secondary" onClick={history.goBack}>
          <FontAwesomeIcon icon="chevron-left" />
          &nbsp;
          Back
        </Button>
      )}
      <Col xl={{ span: 10, offset: 1 }}>
        {children}
      </Col>
    </Main>
  );
}

Container.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};
