import axios from 'axios';

import {
  USER_CUSTOM_WEIGHTS_GET,
  USER_CUSTOM_WEIGHTS_GET_SUCCESS,
  USER_CUSTOM_WEIGHTS_SAVE,
  USER_CUSTOM_WEIGHTS_SAVE_SUCCESS,
  USER_ATTACHED_LISTS_GET,
  USER_ATTACHED_LISTS_GET_SUCCESS,

  WEIGHT_GROUP_ATTACH_DETACH,
  WEIGHT_GROUP_ATTACH_SUCCESS,
  WEIGHT_GROUP_DETACH_SUCCESS,

  USER_CUSTOM_WEIGHTS_FAIL,

  ERRORS,
} from '../../constants';
import { requestErrorHandler } from '../requestErrorHandler';
import { updateComments } from '../chatActions';

const startFetchingCustomWeights = () => ({
  type: USER_CUSTOM_WEIGHTS_GET,
});
const fetchCustomWeightsSuccess = (weights) => ({
  type: USER_CUSTOM_WEIGHTS_GET_SUCCESS,
  payload: weights,
});

const startSavingCustomWeights = () => ({
  type: USER_CUSTOM_WEIGHTS_SAVE,
});
const saveCustomWeightsSuccess = (weights) => ({
  type: USER_CUSTOM_WEIGHTS_SAVE_SUCCESS,
  payload: weights,
});

const startAttachDetachList = () => ({ type: WEIGHT_GROUP_ATTACH_DETACH });
const attachListSuccess = (LIST) => ({
  type: WEIGHT_GROUP_ATTACH_SUCCESS,
  payload: LIST,
});
const detachListSuccess = (LIST) => ({
  type: WEIGHT_GROUP_DETACH_SUCCESS,
  payload: LIST,
});

const startFetchingAttachedLists = () => ({
  type: USER_ATTACHED_LISTS_GET,
});
const fetchAttachedListsSuccess = (weights) => ({
  type: USER_ATTACHED_LISTS_GET_SUCCESS,
  payload: weights,
});

const weightsFail = () => ({ type: USER_CUSTOM_WEIGHTS_FAIL });

export const fetchCustomWeights = (userId) => async (dispatch) => {
  dispatch(startFetchingCustomWeights());
  try {
    const response = await axios(`rest/V2/eplane/backoffice/tier3listprivate/${userId}`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchCustomWeightsSuccess((response.data && response.data.payload && response.data.payload.sellers) || []));
    } else {
      requestErrorHandler(response, dispatch, weightsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, weightsFail, e);
  }
};

export const saveCustomWeights = (data) => async (dispatch) => {
  dispatch(startSavingCustomWeights());
  try {
    const response = await axios.post('rest/V2/eplane/backoffice/tier3listprivate/update', data);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(saveCustomWeightsSuccess(response.data && response.data.payload.sellers));
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, weightsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, weightsFail, e);
  }
};

export const attachDetachList = (data, detach) => async (dispatch) => {
  dispatch(startAttachDetachList());
  const url = `/rest/default/V2/eplane/backoffice/tier3list/${detach ? 'detach' : 'attach'}`;
  try {
    const response = await axios.post(url, data);
    if (response.data.code === ERRORS.SUCCESS) {
      if (detach) {
        dispatch(detachListSuccess(response.data && response.data.payload.lists));
      } else {
        dispatch(attachListSuccess(response.data && response.data.payload.lists));
      }
      dispatch(updateComments());
    } else {
      requestErrorHandler(response, dispatch, weightsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, weightsFail, e);
  }
};

export const fetchAttachedLists = (userId) => async (dispatch) => {
  dispatch(startFetchingAttachedLists());
  try {
    const response = await axios(`rest/V2/eplane/backoffice/tier3lists/${userId}/attached`);
    if (response.data.code === ERRORS.SUCCESS) {
      dispatch(fetchAttachedListsSuccess(response.data && response.data.payload.lists));
    } else {
      requestErrorHandler(response, dispatch, weightsFail);
    }
  } catch (e) {
    requestErrorHandler(e.response, dispatch, weightsFail, e);
  }
};
