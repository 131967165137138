import React from 'react';
import PropTypes from 'prop-types';

import { ERRORS, maxFileSizePWL } from '../../../constants';
import TooBigFileModal from '../../Modals/TooBigFileModal/StyledModal';
import WrongFormatModal from '../../Modals/WrongFileFormatModal';

export default function UploadErrorModals({ error, onHide, ...props }) {
  if (error === ERRORS.ERR_FILE_IS_TOO_LARGE) {
    return (
      <TooBigFileModal
        show
        maxSize={maxFileSizePWL / 1024 / 1024}
        onHide={onHide}
        {...props}
      />
    );
  }

  if (error === ERRORS.ERR_FILE_CONTENT_NOT_SUPPORTED || error === ERRORS.ERR_WRONG_FORMAT) {
    const text = (
      <div>
        <p>
          The file you are trying to upload is not in a supported format
          <br />
          <strong>(.xls, .xlsx, .csv)</strong>
        </p>
        <p>
          Please try again using one of the accepted formats.
        </p>
      </div>
    );

    return (
      <WrongFormatModal
        show
        onHide={onHide}
        text={text}
        {...props}
      />
    );
  }

  return null;
}

UploadErrorModals.propTypes = {
  error: PropTypes.number,
  onHide: PropTypes.func,
  onHideCompanies: PropTypes.func,
};
