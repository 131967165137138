import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import * as consts from '../../../constants';
import FileLoader from '../FileLoader';
import { FileIcon, PlaceholderText as PlaceholderTextNormal } from '../styledComponents';

const ImageIcon = styled(FileIcon)`
  background-position: -509px 0px;
  width: 39px;
  height: 31px;
`;

const ImageThumbnail = styled.img`
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 100%;

`;
const MainTile = styled.div`
  background: ${consts.color.white};
  min-height: 100px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;

const PlaceholderText = styled(PlaceholderTextNormal)`
  margin-top: 10px;
`;
export default class ImageLoader extends React.PureComponent {
  render() {
    const {
      title,
      file,
      placeholderText,
      ...other
    } = this.props;
    const titleText = (file && file.name) || title
    || (
      <span>
        <FontAwesomeIcon icon="plus" />
        &nbsp;Add image
      </span>
    );

    let mainTile;
    let type;
    let showRemoveBtn;
    if (file) {
      type = 'link';
      showRemoveBtn = true;
      mainTile = (
        <MainTile>
          <ImageThumbnail src={file.thumbnail_url} />
        </MainTile>
      );
    } else {
      type = 'input';
      showRemoveBtn = false;
      mainTile = (
        <MainTile>
          <ImageIcon />
          <PlaceholderText>{placeholderText}</PlaceholderText>
        </MainTile>
      );
    }
    return (
      <FileLoader
        type={type}
        title={titleText}
        url={file && file.url}
        mainTile={mainTile}
        showRemoveBtn={showRemoveBtn}
        target="_blank"
        {...other}
      />
    );
  }
}

const fileObj = PropTypes.shape({
  name: PropTypes.string,
  thumbnail_url: PropTypes.string,
  url: PropTypes.string,
});
ImageLoader.propTypes = {
  file: fileObj,
  placeholderText: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ImageLoader.defaultProps = {
  placeholderText: 'Image',
};
