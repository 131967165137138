import axios from 'axios';

import {
  WEIGHT_LIST_CLONE,
  WEIGHT_LIST_CLONE_SUCCESS,
  WEIGHT_LIST_CLONE_FAIL,

  WEIGHT_LIST_UPLOADING_START,
  WEIGHT_LIST_UPLOADING_FINISH,
  WEIGHT_LIST_UPLOADING_PROGRESS_SET,
  WEIGHT_LIST_UPLOADING_PROGRESS_RESET,
  WEIGHT_LIST_UPLOADING_TERMINATE,
  WEIGHT_LIST_ERROR_REMOVE,
  WEIGHT_LIST_UPLOADING_ERROR_OCCURRED,

  AXIOS_CANCELLED_MESSAGE,
  ERRORS,
  maxFileSizeWeightList,
} from '../../constants';
import errorHandler from '../requestErrorHandler';
import { updateComments } from '../chatActions';

export const removeErrorCode = () => ({
  type: WEIGHT_LIST_ERROR_REMOVE,
});

const startCloneWeight = () => ({
  type: WEIGHT_LIST_CLONE,
});
const cloneWeightSuccess = (weightList) => ({
  type: WEIGHT_LIST_CLONE_SUCCESS,
  payload: weightList,
});
const cloneWeightFail = (errorCode) => ({
  type: WEIGHT_LIST_CLONE_FAIL,
  payload: errorCode,
});

const startUploadingWeightList = (source) => ({
  type: WEIGHT_LIST_UPLOADING_START,
  payload: source,
});
const finishUploadingWeightList = (document) => ({
  type: WEIGHT_LIST_UPLOADING_FINISH,
  payload: document,
});

const setUploadingProgress = (percentOfUpload) => ({
  type: WEIGHT_LIST_UPLOADING_PROGRESS_SET,
  payload: percentOfUpload,
});

export const resetUploadingProgress = () => ({
  type: WEIGHT_LIST_UPLOADING_PROGRESS_RESET,
});

export const terminateUploadingProgress = () => ({
  type: WEIGHT_LIST_UPLOADING_TERMINATE,
});

export const setUploadingErrorCode = (errorCode) => ({
  type: WEIGHT_LIST_UPLOADING_ERROR_OCCURRED,
  payload: errorCode,
});

export const uploadWeightList = (userId, file) => (dispatch) => {
  const source = axios.CancelToken.source();
  dispatch(startUploadingWeightList(source));

  if (file && file.size > maxFileSizeWeightList) {
    dispatch(setUploadingErrorCode(ERRORS.ERR_FILE_IS_TOO_LARGE));
    return;
  }

  const fd = new FormData(); // eslint-disable-line no-undef
  fd.append('user_id', userId);
  fd.append('file_xls', file);

  const options = {
    onUploadProgress: (e) => onUploadProgress(e, dispatch),
    cancelToken: source.token,
  };
  axios.post('/backoffice/users/uploadSellersWeightsList', fd, options).then((response) => {
    dispatch(finishUploadingWeightList(response.data));
    dispatch(updateComments());
  }).catch((e) => uploadingErrorHandler(e, dispatch));
};

const onUploadProgress = (progressEvent, dispatch) => {
  const { loaded, total } = progressEvent;
  dispatch(setUploadingProgress((loaded * 100) / total));
};

const uploadingErrorHandler = (e, dispatch) => {
  if (e.message === AXIOS_CANCELLED_MESSAGE) {
    console.warn(AXIOS_CANCELLED_MESSAGE); // eslint-disable-line no-console
    return;
  }
  errorHandler(e.response, dispatch, setUploadingErrorCode, e);
};

export const cloneWeightList = (userId, companiesIds) => async (dispatch) => {
  dispatch(startCloneWeight());
  const reqBody = { fromId: userId, toIds: companiesIds };
  try {
    const response = await axios.post('rest/default/V1/eplane/backoffice/user/cloneSellersWeights', reqBody);
    dispatch(cloneWeightSuccess(response.data && response.data.results));
    dispatch(updateComments());
  } catch (e) {
    errorHandler(e.response, dispatch, cloneWeightFail, e);
  }
};
