import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import { naturalNumberValidator } from './validators';

const DefaultLabel = styled.div`
  width: 200px;
  background-color: lightgray;
  height: 20px;
  background: #e0e0e0;
  margin-bottom: 5px;
`;

const DefaultFormContainer = styled.div`
  margin-bottom: 15px;
`;

const DefaultInput = styled.div`
  height: 34px;
  background: #e0e0e0;
`;

const QuotingTimeLabel = styled.p`
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 50px;
`;

const QuotingTimeContainer = styled.div`
  max-width: 350px;
`;

const DefaultForm = () => (
  <DefaultFormContainer>
    <DefaultLabel />
    <DefaultInput />
  </DefaultFormContainer>
);

const createAddonText = (value) => (
  `≈ ${moment.duration(parseInt(value, 10), 'minutes').humanize()}`
);

const QuotingTimeForm = ({
  constants,
  quotingTime,
  disabled,
  onChange,
}) => {
  let content;

  if (!constants || !quotingTime) {
    content = (
      <>
        <DefaultForm />
        <DefaultForm />
        <DefaultForm />
      </>
    );
  } else {
    const { demandPriority } = constants;
    content = (
      <QuotingTimeContainer>
        {getFormList(demandPriority, quotingTime, disabled, onChange)}
      </QuotingTimeContainer>
    );
  }

  return (
    <div>
      <QuotingTimeLabel>Quoting times for different demand priorities:</QuotingTimeLabel>
      {content}
    </div>
  );
};

const getFormList = (demandPriority, quotingTime, disabled, onChange) => {
  if (!demandPriority || !quotingTime) return null;

  return Object.keys(demandPriority).map((name) => (
    <BaseFormGroup
      key={name}
      labelText={`${demandPriority[name].name} (min)`}
      name={demandPriority[name].id}
      value={quotingTime[demandPriority[name].id].toString()}
      disabled={disabled}
      onChange={onChange}
      addonText={createAddonText(quotingTime[demandPriority[name].id])}
      {...naturalNumberValidator(quotingTime[demandPriority[name].id])}
    />
  ));
};

QuotingTimeForm.propTypes = {
  constants: PropTypes.shape({
    demandPriority: PropTypes.shape({}),
  }),
  quotingTime: PropTypes.shape({
  }),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default QuotingTimeForm;
