import React from 'react';
import styled from 'styled-components';

import Img from '../../Img';
import CoffeeImg from '../../../assets/coffee.png';
import { color } from '../../../constants';

const Coffee = styled(Img)`
  width: 150px;
  height: 150px;
`;
const Container = styled.div`
  margin-top: 180px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
const Text = styled.p`
  font-size: 16px;
  margin-bottom: 0;
  color:  ${color.grey};
`;

export default () => (
  <Container>
    <Text>Well done, you don&apos;t have any open tasks!</Text>
    <Text>It&apos;s coffee time</Text>
    <br />
    <br />
    <Coffee src={CoffeeImg} alt="Cofee Time" />
  </Container>
);
