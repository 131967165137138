import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Row, Col } from 'react-bootstrap';
import Select from 'react-select';

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  && button {
    min-width: 70px;
  }
`;

const SelectWithButton = ({
  selectValue,
  selectOptions,
  onSelectChange,
  buttonText,
  onButtonClick,
  disabled,
  ...other
}) => (
  <StyledRow>
    <Col sm={6}>
      <Select
        value={selectValue}
        name={`${buttonText}_list`}
        onChange={onSelectChange}
        options={selectOptions}
        hideSelectedOptions
        closeMenuOnSelect={false}
        isDisabled={disabled}
        isMulti
        {...other}
      />
    </Col>
    <Col sm={1}>
      <Button
        onClick={onButtonClick}
        name={`${buttonText}_button`}
        disabled={disabled || !selectValue || selectValue.length === 0}
        variant="info"
      >
        {buttonText}
      </Button>
    </Col>
  </StyledRow>
);

SelectWithButton.propTypes = {
  selectValue: PropTypes.arrayOf(PropTypes.object),
  selectOptions: PropTypes.arrayOf(PropTypes.object),
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  onSelectChange: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default SelectWithButton;
