import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { clearScrollToMessage } from '../../actions/networkOperationsActions/builtInQuoteActions';
import {
  fetchTasksList, fetchTaskData, startTask, closeTask, reopenTask, stepForwardTask,
} from '../../actions/networkOperationsActions/tasksActions';
import { fetchCompanyList } from '../../actions/companyListActions';
import { showForm, hideForm, setTasksBar } from '../../actions/networkOperationsActions/formManagementActions';
import {
  getTasksStore, getChatStore, getCompanyListStore, getErrorsStore, getNocFormStore,
} from '../../selectors';
import {
  Container, TasksContainer, ChatContainer, ButtonContainer, CloseButton, StyledButton, Styled, TasksCollapseButton,
} from './StyledComponents';

import TasksSideBar from './TasksSection/TasksSideBar';
import NetworkChatContainer from './ChatSection/NetworkChatContainer';
import HistorySidebar from './HistorySection';
import Loader from '../Loader';
import ModalLoader from '../ModalLoader';
import { taskStatus } from '../../constants/index';
import createRegExpForString from '../../utils/createRegExpForString';
import {
  PUBLIC_URL, DEFAULT_TASK_AMOUNT_PER_PAGE, AXIOS_CANCELLED_MESSAGE,
} from '../../constants';
import { NOC_FORMS } from '../../constants/operationCenter';

const formatStatusObject = (statusString) => {
  switch (statusString) {
    case 'closed':
      return {
        label: 'Closed tasks', value: statusString,
      };
    case 'open':
      return {
        label: 'Open tasks', value: statusString,
      };
    case 'in_progress':
      return {
        label: 'In progress', value: statusString,
      };
    default:
      return null;
  }
};
const formatStatusText = (text) => {
  switch (text) {
    case taskStatus.OPEN:
      return 'OPEN';
    case taskStatus.IN_PROGRESS:
      return 'IN PROGRESS';
    case taskStatus.CLOSED:
      return 'CLOSED';
    default:
      return '';
  }
};

const NetworkOperationCenterPage = () => {
  const historyContainerRef = useRef(null);
  const isLoadMore = useRef(false);
  const source = useRef(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { taskId } = useParams();

  const [fetchingTaskParams, setTaskFetchingPrams] = useState({});
  const [height, setHeight] = useState(550);

  const { showTasksBar } = useSelector(getNocFormStore);
  const { order } = useSelector(getChatStore);
  const {
    selectedTask,
    tasksList: tasks,
    taskHistory,

    isTaskInfoFetching,
    isTasksFetching,
    isQuoteSending,
    isProcessing,
    isClosingStepForward,

    tasksPerPage,
    totalTaskPages,
    totalTasksAmount,
  } = useSelector(getTasksStore);
  const {
    companies,
    isFetching: isCompanyListFetching,
  } = useSelector(getCompanyListStore);
  const { errors } = useSelector(getErrorsStore);

  const queryParams = queryString.parse(location.search);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.body.style.overflowY = 'hidden';
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateWindowDimensions);
    updateWindowDimensions();

    const currQuery = queryString.parse(location.search);
    setTaskFetchingPrams({
      startDate: currQuery.f && moment(currQuery.f, 'YYYY-MM-DD'),
      endDate: currQuery.t && moment(currQuery.t, 'YYYY-MM-DD'),
      taskStatus: currQuery.s && formatStatusObject(currQuery.s),
      searchQuery: currQuery.q,
      sortColumn: currQuery.o,
      sortDirection: currQuery.d,
    });
    dispatch(clearScrollToMessage());
    dispatch(fetchTasksList(currQuery));
    dispatch(fetchCompanyList());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (taskId) {
      dispatch(clearScrollToMessage());
      dispatch(fetchTaskData(taskId));
      dispatch(hideForm());
    }
  }, [dispatch, taskId]);

  useEffect(() => {
    const currQuery = queryString.parse(location.search);
    if (location.search === '') {
      setTaskFetchingPrams({
        startDate: null,
        endDate: null,
        taskStatus: null,
        searchQuery: '',
        clearFiltersState: true,
      });
    }
    dispatch(fetchTasksList(currQuery));
  }, [dispatch, location.search]);

  useEffect(() => () => {
    // eslint-disable-next-line no-undef
    document.body.style.overflowY = 'auto';
    // eslint-disable-next-line no-undef
    window.removeEventListener('resize', updateWindowDimensions);
    dispatch(hideForm());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tasks && !isClosingStepForward) {
      const itemIndex = tasks.findIndex((item) => item.task_id === taskId);
      if (itemIndex < tasks.length - 1 && itemIndex >= 0) {
        let nextOpenTaskIndex = itemIndex + 1;
        for (let i = nextOpenTaskIndex; i < tasks.length; i += 1) {
          if (tasks[i].task_status === taskStatus.OPEN) {
            nextOpenTaskIndex = i;
            break;
          }
        }

        const formattedParams = formatTaskListFetchingParams();
        history.push({
          pathname: `${PUBLIC_URL}/networkOperations/${tasks[nextOpenTaskIndex].task_id}`,
          search: queryString.stringify(formattedParams),
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosingStepForward]);

  // eslint-disable-next-line no-undef
  const updateWindowDimensions = () => setHeight(window.innerHeight);

  const fetchTasksListWithParams = (params) => {
    const formattedParams = formatTaskListFetchingParams(params);
    isLoadMore.current = (params?.loadMore) || false;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(formattedParams),
    });
  };

  const formatTaskListFetchingParams = (params) => {
    const endDate = params && (params.endDate || params.startDate === null) ? params.endDate : fetchingTaskParams.endDate;
    const startDate = params && (params.startDate || params.startDate === null) ? params.startDate : fetchingTaskParams.startDate;
    const status = params && (params.taskStatus || params.taskStatus === null) ? params.taskStatus : fetchingTaskParams.taskStatus;
    const isDatesSame = endDate && startDate && moment(startDate).isSame(endDate);
    const formattedSearchQuery = params && params.searchQuery ? params.searchQuery : fetchingTaskParams.searchQuery;

    let formattedEndDate = null;
    if (endDate) {
      formattedEndDate = (endDate && isDatesSame)
        ? moment(endDate).add(1, 'days').format('YYYY-MM-DD')
        : endDate.format('YYYY-MM-DD');
    }

    return {
      f: startDate && startDate.format('YYYY-MM-DD'),
      t: formattedEndDate,
      s: status?.value,
      c: params?.loadMore ? tasksPerPage + DEFAULT_TASK_AMOUNT_PER_PAGE : tasksPerPage,
      q: params?.fetchWithEmptyQuery ? null : formattedSearchQuery,
      o: params && (params.sortColumn || params.sortColumn === null) ? params.sortColumn : fetchingTaskParams.sortColumn,
      d: params && (params.sortDirection || params.sortDirection === null) ? params.sortDirection : fetchingTaskParams.sortDirection,
    };
  };

  const showBuildInQuoteForm = (messageId) => dispatch(showForm({ type: NOC_FORMS.BUILT_IN_QUOTE, messageId }));

  const hideQuoteBar = () => dispatch(hideForm());

  const onTaskChosen = (id) => {
    hideQuoteBar();
    const formattedParams = formatTaskListFetchingParams();
    history.push({
      pathname: `${PUBLIC_URL}/networkOperations/${id}`,
      search: queryString.stringify(formattedParams),
    });
  };

  const calcSearchSuggestions = async (value) => {
    const re = createRegExpForString(value, 'i');
    let suggestedCompanies = [];
    let suggestedOrders = [];

    if (value && value.length > 2) {
      suggestedCompanies = companies.filter((val) => re.test(val.company_name)).map((val) => val.company_name);
      // enable autocomplete for order id suggestions
      if (source.current) source.current.cancel(AXIOS_CANCELLED_MESSAGE);
      source.current = axios.CancelToken.source();
      try {
        const response = await axios(
          `/rest/V1/eplane/backoffice/orders/search/suggest?order_id=${value}`,
          { cancelToken: source.current.token },
        );
        suggestedOrders = response && response.data;
        source.current = null;
      } catch (e) {
        // eslint-disable-next-line no-console
        if (e.message === AXIOS_CANCELLED_MESSAGE) ;
        else console.warn('Can\'t get order id suggestions'); // eslint-disable-line
      }
    }

    return [...suggestedCompanies, ...suggestedOrders].slice(0, 10);
  };

  const renderButton = () => {
    switch (selectedTask && selectedTask.task_status) {
      case taskStatus.OPEN:
        return (
          <ButtonContainer>
            <StyledButton onClick={() => dispatch(startTask(taskId))}>
              {isProcessing && <Styled icon="spinner" size="2x" spin />}
              <b>Start task</b>
            </StyledButton>
            <CloseButton variant="link" title="Close and open next task" onClick={() => dispatch(stepForwardTask(taskId))}>
              <FontAwesomeIcon icon="step-forward" size="2x" />
            </CloseButton>
          </ButtonContainer>
        );
      case taskStatus.IN_PROGRESS:
        return (
          <ButtonContainer>
            <StyledButton onClick={() => dispatch(closeTask(taskId))}>
              {isProcessing && <Styled icon="spinner" size="2x" spin />}
              <b>Close task</b>
            </StyledButton>
          </ButtonContainer>
        );
      case taskStatus.CLOSED:
        return (
          <ButtonContainer>
            <StyledButton onClick={() => dispatch(reopenTask(taskId))}>
              {isProcessing && <Styled icon="spinner" size="2x" spin />}
              <b>Reopen task</b>
            </StyledButton>
          </ButtonContainer>
        );
      default:
        return null;
    }
  };

  if (!tasks && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <>
      <Container>
        <TasksContainer isHidden={!showTasksBar}>
          <TasksSideBar
            queryParams={queryParams}
            totalTasksAmount={totalTasksAmount}
            taskListFetchingParams={fetchingTaskParams}
            fetchTasksListWithParams={fetchTasksListWithParams}
            changeTaskListFetchingParams={(params) => setTaskFetchingPrams(params)}
            tasksPerPage={tasksPerPage}
            totalTaskPages={totalTaskPages}
            openedTaskId={taskId}
            tasks={tasks}
            height={height}
            onTaskClick={onTaskChosen}
            formatStatusText={formatStatusText}
            getSearchSuggestions={calcSearchSuggestions}
            isTasksFetching={isTasksFetching}
            isLoadMore={isLoadMore.current}
            search={location.search}
            setTaskFetchingParams={setTaskFetchingPrams}
            renderHeaderText={() => !isTasksFetching
              && `${totalTasksAmount} ${queryParams.s ? formatStatusText(queryParams.s) : ''} task${totalTasksAmount !== 1 ? 's' : ''}`}
          />
          <TasksCollapseButton
            showTasksBar={showTasksBar}
            type="button"
            aria-controls="tasks-sidebar"
            aria-expanded={showTasksBar}
            onClick={() => dispatch(setTasksBar(!showTasksBar))}
          >
            {
            showTasksBar
              ? <FontAwesomeIcon icon="chevron-left" title="Hide" />
              : <FontAwesomeIcon icon="chevron-right" title="Show" />
            }
          </TasksCollapseButton>
        </TasksContainer>
        <ChatContainer>
          {(!(!order && !!taskId) || isTaskInfoFetching) && (
            <NetworkChatContainer
              renderButton={renderButton}
              taskId={taskId}
              orderId={(selectedTask && selectedTask.order_increment_id) || ''}
              scrolledMessageId={(selectedTask && selectedTask.chat_message_id) || ''}
              height={height}
              isEmptyTaskList={tasks?.length === 0}
              isTaskInfoFetching={isTaskInfoFetching}
              isQuoteSending={isQuoteSending}
              showActionsDropdown={selectedTask && selectedTask.task_status === taskStatus.IN_PROGRESS}
              showQuoteHandler={showBuildInQuoteForm}
            />
          )}
        </ChatContainer>
        <HistorySidebar
          ref={historyContainerRef}
          orderId={selectedTask && selectedTask.order_id}
          cancelHandler={hideQuoteBar}
          isEmptyTaskList={!taskId}
          history={taskHistory}
          isUpdating={isTaskInfoFetching}
          height={height}
        />
      </Container>

      <ModalLoader show={!tasks || isCompanyListFetching} />
    </>
  );
};

export default NetworkOperationCenterPage;
