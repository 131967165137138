/**
 * Function compares values of obj1 with values of obj2
 * and if the values of same property is different, records changes in the result object.
 *
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {Object} object that contains all property-value pair that are different
 */
export default function changesMapper(obj1, obj2) {
  if (!obj1 || !obj2) return null;

  const changesObj = {};

  Object.keys(obj1).forEach((p) => {
    if (Array.isArray(obj1[p])) {
      if (obj1[p].length !== obj2[p].length
      || obj1[p].some((a, i) => Object.keys(a).some((k) => a[k] !== obj2[p][i][k]))) changesObj[p] = obj1[p];
    } else if (obj1[p] !== obj2[p]) changesObj[p] = obj1[p];
  });
  return changesObj;
}
