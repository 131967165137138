import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import Table from '../../Table/Table';
import CellWithTitle from '../../Table/Cell';

import { StyledLinkButton as LinkButtonNormal } from '../../StyledComponents';
import { color } from '../../../constants';

const LinkButton = styled(LinkButtonNormal)`
  padding: 0;
`;
const TableStyles = styled.div`
  margin-top: 1em;
  table {
    table-layout: fixed;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    thead {
      border-bottom: 2px solid ${color.grey};
    }

    thead tr th {
      font-weight: normal;
    }
    tbody tr {
      border-bottom: 1px solid ${color.lightGrey};
    }
    th, td {
      padding: 10px 0;
      padding-right: 10px;
      text-align: left;
      vertical-align: top;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
const Status = styled.span`
  color: ${color.textRed};
  text-transform: uppercase;
`;
const header = (title) => <div title={title}>{title}</div>;

export default function BillingTable({ data }) {
  const transactionFormatter = useCallback(
    (link, status) => {
      if (status === 'failed') return <Status>FAILED</Status>;
      return (
        <LinkButton
          variant="link"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          VIEW
        </LinkButton>
      );
    },
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: () => header('Date'),
        accessor: 'date',
        Cell: ({ value }) => (value ? moment.utc(value).format('MMM D, YYYY') : ''),
        style: {
          width: '120px',
        },
      },
      {
        Header: () => header('Reference'),
        accessor: 'reference',
        Cell: CellWithTitle,
        style: {
          width: '150px',
        },
      }, {
        Header: () => header('Price'),
        accessor: 'price',
        Cell: ({ value }) => <CellWithTitle value={`${value.currency} ${value.amount}`} />, // eslint-disable-line react/prop-types
        style: {
          width: '100px',
        },
      }, {
        Header: () => header(''),
        id: 'billing',
        accessor: 'download_link',
        Cell: ({ value, row }) => transactionFormatter(value, row.original.status),
        style: {
          width: '70px',
        },
      },
    ], [transactionFormatter],
  );

  return (
    <TableStyles>
      <Table columns={columns} data={data} />
    </TableStyles>
  );
}

BillingTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
