import { combineReducers } from 'redux';

import authorization from './authorization';
import requests from './requests';
import orderManagement from './orderManagement';
import serverConstants from './serverConstants';
import userManagement from './userManagement';
import companyList from './companyList';
import companiesManagement from './companiesManagement';
import userCompanyManagement from './userCompanyManagement';
import networkOperationsCenter from './networkOperationsCenter';
import settings from './settings';
import dev from './Dev';
import errorManagement from './errors';
import chat from './chat';
import fileUploader from './fileUploader';
import toasts from './toasts';

export default combineReducers({
  authorization,
  requests,
  orderManagement,
  serverConstants,
  networkOperationsCenter,
  userManagement,
  userCompanyManagement,
  companiesManagement,
  companyList,
  settings,
  dev,
  chat,

  errorManagement,
  fileUploader,
  toasts,
});
