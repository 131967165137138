import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormGroup, Row, Col, FormLabel,
} from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchBankAccount, saveBankAccount } from '../../../actions/settings/bankAccount';
import { getBankAccountStore, getErrorsStore } from '../../../selectors';

import FormActionButtons from '../../FormComponents/ActionButtons';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import { TabContainer, StyledLinkButton as LinkButton } from '../../StyledComponents';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';

const TextArea = styled.textarea`
  &&& { 
    resize: vertical;
    height: 150px;
    max-height: 500px;
    min-height: 100px;
    border-radius: 15px;
  }
`;

const BankAccountPage = () => {
  const dispatch = useDispatch();

  const [accountData, setAccountData] = useState(null);

  const { bankAccount, isFetching, isProcessing } = useSelector(getBankAccountStore);
  const { errors } = useSelector(getErrorsStore);

  useEffect(() => {
    dispatch(fetchBankAccount());
  }, [dispatch]);

  const onEditHandler = () => setAccountData(bankAccount.account_data);

  const onSaveAccount = () => {
    dispatch(saveBankAccount({ account_data: accountData }));
    setAccountData(null);
  };

  if ((!bankAccount || isFetching) && (errors === null || errors.length === 0)) return <Loader />;

  return (
    <>
      <FormTitleSection title="Bank Account Details" showEditButton={false} />
      <TabContainer>
        <FormGroup>
          <FormLabel>
            ePlane&apos;s bank account details for wire transfers
          </FormLabel>
          <Row>
            <Col sm={10} xl={6}>
              <BaseFormGroup
                value={accountData || bankAccount.account_data}
                as={TextArea}
                placeholder="Type here the bank details (e.g. Swift code: 1234567,)..."
                onChange={(e) => setAccountData(e.target.value)}
                readOnly={accountData === null}
              />
            </Col>
            <Col sm={2} xl={2}>
              {accountData === null && (
                <LinkButton variant="link" onClick={onEditHandler}>
                  Edit&nbsp;
                  <FontAwesomeIcon icon="pencil-alt" />
                </LinkButton>
              )}
            </Col>
          </Row>
        </FormGroup>

        {accountData !== null && (
          <FormActionButtons
            confirmButtonText="Save account"
            saveProps={{ }}
            saveHandler={onSaveAccount}
            cancelHandler={() => setAccountData(null)}
          />
        )}
      </TabContainer>
      <ModalLoader show={isProcessing} />
    </>
  );
};

export default BankAccountPage;
