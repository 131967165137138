import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';

import { getCustomerStore, getCountries } from '../../../selectors';

import { TabContainer, FileLink } from '../../StyledComponents';
import FormTitleSection from '../../FormComponents/TitleSection';
import UniversalFormControl from '../../FormComponents/UniversalControl';
import dateFormat from '../../../utils/dateFormat';
import { PUBLIC_URL } from '../../../constants';

const LinkContainer = styled.div`
  margin-top: 6px;
`;

export default function RegistrationDetailsForm() {
  const [inviter, setInviter] = useState(null);

  const { customer } = useSelector(getCustomerStore);
  const countries = useSelector(getCountries);

  useEffect(() => {
    if (customer.invited_by) {
      axios(`/rest/default/V1/eplane/backoffice/customer/${customer.invited_by}`).then((response) => {
        setInviter(response?.data);
      }).catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }
  }, [customer]);

  if (!customer || !countries) return null;

  const countryName = customer.signup_country
    ? countries.find((v) => v.id === customer.signup_country.toUpperCase())?.full_name_english
    : 'no information';

  return (
    <>
      <FormTitleSection title="Registration Details" showEditButton={false} />

      <TabContainer>
        <Form>
          <Row>
            <Col sm={8}>
              <Row>
                <Col sm={8}>
                  <UniversalFormControl
                    labelText="Registration time"
                    value={dateFormat(customer.created_at)}
                    name="created_at"
                    plaintext
                    readOnly
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={8}>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="4">
                      Registration type
                    </Form.Label>
                    <Col sm="8">
                      <LinkContainer>
                        {!customer.invited_by
                          ? 'Self registered'
                          : (
                            <>
                              Invited by&nbsp;
                              <FileLink
                                target="_blank"
                                rel="noopener noreferrer"
                                to={`${PUBLIC_URL}/userManagement/${1000}/${customer.invited_by}/`}
                              >
                                {inviter?.email}
                              </FileLink>
                            </>
                          )}
                      </LinkContainer>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={8}>
                  <UniversalFormControl
                    labelText="Country"
                    value={countryName}
                    name="signup_country"
                    plaintext
                    readOnly
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={8}>
                  <UniversalFormControl
                    labelText="IP address"
                    value={customer.signup_ip || 'no information'}
                    name="signup_ip"
                    plaintext
                    readOnly
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </TabContainer>
    </>
  );
}
