import isURL from 'validator/lib/isURL';
import createErrorValidationObj from '../../../utils/createErrorValidationObj';
import {
  DEFAULT_COUNTRY_VALUE_SELECT,
  DEFAULT_REGION_VALUE_SELECT,
} from '../../../constants';

const createNotEmptyValidator = (errorText) => (value) => {
  const newValue = value && value.trim();
  return newValue ? null : createErrorValidationObj(errorText);
};

const createPhoneNumberValidator = (errorText) => (value) => {
  const newValue = value && value.trim();
  if (!newValue) return null;
  if (newValue.length < 6 || newValue.length > 128) return createErrorValidationObj(errorText);
  return null;
};

const validators = {
  company_name: createNotEmptyValidator('Please enter the company name'),

  country_id: (value) => {
    if (value === DEFAULT_COUNTRY_VALUE_SELECT) {
      return createErrorValidationObj('Please select the country');
    }
    return null;
  },

  region_id: (value) => {
    if (value === DEFAULT_REGION_VALUE_SELECT) {
      return createErrorValidationObj('Please enter the state/province');
    }
    return null;
  },

  phone: createPhoneNumberValidator('Please enter the company\'s phone number'),

  company_website: (value) => {
    const newValue = value && value.trim();
    if (!newValue) return null;
    if (!isURL(`${newValue}`)) return createErrorValidationObj('Please enter a valid URL (www.example.com)');
    return null;
  },

  contact_name: (value) => {
    const newValue = value && value.trim();

    if (!newValue) return null;
    if (!/^[^\s]+( [^\s]+)+$/.test(newValue)) return createErrorValidationObj('Please enter a full name');
    return null;
  },
};

export default validators;
