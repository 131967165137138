import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import * as consts from '../../../constants';
import FileLoader from '../FileLoader/index';
import { FileIcon, PlaceholderText } from '../styledComponents';

const MainTile = styled.div`
  /*background-color: ${(props) => (props.isActive ? consts.color.lightBirch : 'inherit')};
  color: ${(props) => (props.isActive ? consts.color.birch : consts.color.darkGrey)};*/
  
  &&& {color: ${consts.color.darkGrey};
    padding: 20px 14px 9px;
    text-align: center;
    min-height: 100px;
    box-sizing: border-box; 
    font-size: 14px;
    font-family: "Lato", sans-serif;
  }
  .upload-box:hover &&& {
    background-color: ${consts.color.lightBirch};
    color: ${consts.color.birch};
  }

  .a-file-loader &&& {
    background-color: ${consts.color.lightBirch};
    color: ${consts.color.birch};
  }
`;

const DocumentFileIcon = styled(FileIcon)`
  background-position: -395px 0;
  .a-file-loader &&& {
    background-position: -427px 0px;
  }

  .upload-box:hover &&& {
    background-position: -427px 0px;
  }
`;

const DocumentLoader = ({
  file,
  placeholderText,
  accept,
  ...other
}) => {
  let title;
  let url = null;
  let showRemoveBtn = false;
  let type;
  let isActive = false;
  if (file) {
    title = file.name;
    ({ url } = file);
    showRemoveBtn = true;
    isActive = true;
    type = 'link';
  } else {
    title = (
      <span>
        <FontAwesomeIcon icon="paperclip" />
        &nbsp;Attach file
      </span>
    );
    type = 'input';
  }
  const mainTile = (
    <MainTile>
      <DocumentFileIcon />
      <PlaceholderText>{placeholderText}</PlaceholderText>
    </MainTile>
  );
  return (
    <FileLoader
      isActive={isActive}
      title={title}
      url={url}
      type={type}
      showRemoveBtn={showRemoveBtn}
      mainTile={mainTile}
      accept={accept}
      {...other}
    />
  );
};

export default DocumentLoader;

const fileObj = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
});
DocumentLoader.propTypes = {
  file: fileObj, // is not required, if file is specified, component behaves as a link, otherwise as an file input
  placeholderText: PropTypes.string,
  accept: PropTypes.string,
};

DocumentLoader.defaultProps = {
  placeholderText: '',
};
