import React from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';

class UpdateDetailsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { approveComment: '' };
  }

  onChangeComment = (e) => {
    this.setState({ approveComment: e.target.value });
  }

  onApprove = () => {
    const { approve } = this.props;
    const { approveComment } = this.state;
    approve(approveComment);
  }

  render() {
    const {
      approve,
      onHide,
      ...other
    } = this.props;
    const { approveComment } = this.state;
    return (
      <Modal centered onHide={onHide} {...other}>
        <Modal.Header closeButton>
          Approving this request allows this user to sell parts on ePlane. Are you sure you want to approve the
          request?
        </Modal.Header>
        <Modal.Body>
          <FormControl
            name="approveComment"
            value={approveComment}
            onChange={this.onChangeComment}
            rows={3}
            className="PopUp-Comment"
            as="textarea"
            placeholder="Please add useful comments to this request, if you have any."
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onApprove}>APPROVE</Button>
          <Button variant="outline-secondary" onClick={onHide}>CANCEL</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

UpdateDetailsModal.propTypes = {
  approve: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default UpdateDetailsModal;
