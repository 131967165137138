import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { color } from '../../../../constants';

export const DatesContainer = styled.div`
  .DateInput__small {
     width: 48%;
  }
  .DateRangePickerInput_calendarIcon {
      display: none;
  }
`;
export const Container = styled.div`
  width: 160px;
`;
export const Icon = styled(FontAwesomeIcon)`
  margin-left: 2px;
  font-size: 1.1em;
`;
export const LinkButton = styled(Button)`
  &&& {
    outline: 0;
    outline-offset: 0;
    text-decoration:none;
    box-shadow: none;
  }
  font-weight:600;
  color: ${color.grey};
`;
export const LabelText = styled.p`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 15px;
`;
export const ApplyButton = styled(Button)`
  &&& {
    outline: 0;
    outline-offset: 0;
    text-decoration:none;
    background-color: inherit;
    color: black;
  }
  &:hover {
    border-color: ${color.birch};
    color: black;
  }
  border-color: ${color.borderLightGrey};
   padding: 3px 6px;
   width: 80px;
   border-radius: 25px;
   float: right;
   margin: 25px 0 10px 0px;
`;
export const ClearAllButton = styled(Button)`
  &&& {
    outline: 0;
    outline-offset: 0;
    text-decoration:none;
    box-shadow: none;
    background-color: inherit;
  }
  &&&:hover {
    color: ${color.birch};
  }
  color: ${color.grey};
   padding: 0;
   border: none;
   float: right;
`;
