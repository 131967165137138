import axios from 'axios';
import { API_URL } from '../constants';

const request = axios.create({
  baseURL: API_URL,
});

export function setAPIUrl() {
  axios.defaults.baseURL = API_URL;
}

export function setAuthToken(token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export default request;
