import axios from 'axios';

import {
  COMPANIES_GET,
  COMPANIES_GET_SUCCESS,
  ORDERS_SEARCH,
  ORDERS_SEARCH_SUCCESS,
  ORDERS_SEARCH_SHOW_ALL_SUCCESS,

  REQUEST_FOR_DATA_FAIL,
  NUM_OF_ORDERS_PER_PAGE,
} from '../../constants';
import { getSearchOrdersStore } from '../../selectors';
import { requestErrorHandler } from '../requestErrorHandler';

export const requestCompanies = () => ({
  type: COMPANIES_GET,
});
export const receiveSuccessCompanies = (data) => ({
  type: COMPANIES_GET_SUCCESS,
  payload: data,
});

export const failRequestForData = () => ({
  type: REQUEST_FOR_DATA_FAIL,
});

export const fetchCompanies = () => (dispatch) => {
  dispatch(requestCompanies());

  axios('/rest/V1/eplane/backoffice/companies').then((response) => {
    dispatch(receiveSuccessCompanies(response?.data));
  }).catch((e) => {
    requestErrorHandler(e.response, dispatch, failRequestForData, e);
  });
};

export const searchForOrders = (searchFields) => ({
  type: ORDERS_SEARCH,
  payload: searchFields,
});

/**
 *
 * @param {Object} data - is response from server while searching
 */
export const searchForOrdersSuccess = (data) => ({
  type: ORDERS_SEARCH_SUCCESS,
  payload: data,
});

/**
 * Function fetches orders from server by parameters
 *
 * @param {Object} [searchParams]
 * @param {Number} [searchParams.fields.orderID] //!!!
 * @param {String} [searchParams.fields.purchaseOrder] //!!!
 * @param {Number} [searchParams.fields.company]
 * @param {String} [searchParams.fields.email]
 * @param {String} [searchParams.fields.partNumber] //!!!
 * @param {Number} [searchParams.fields.status] //!!!
 * @param {String} [searchParams.fields.from]
 * @param {String} [searchParams.fields.to]
 * @param {Number} [searchParams.page]
 * @param {Number} [searchParams.count]
 *
 * ***Params that was marked as '//!!!' are named differently in the сlient application and endpoint
 */
export const fetchSearchingOrders = (searchParams) => (dispatch, getState) => {
  const { isFetching } = getSearchOrdersStore(getState());
  const { count } = searchParams;
  const fields = searchParams.fields || {};
  let searchFields;

  if (isFetching) return;
  // if user entered orderID, search should be by orderID,
  // and other fields should be ignored. (Acc. to spec)
  if (fields.orderID) {
    searchFields = { orderID: fields.orderID };
  } else {
    searchFields = fields;
  }
  dispatch(searchForOrders(searchFields));

  const reqParams = {
    order_id: searchFields.orderID,
    PO: searchFields.purchaseOrder,
    company_id: searchFields.company,
    email: searchFields.email,
    PN: searchFields.partNumber,
    status_id: searchFields.status,
    from: (searchFields.from && searchFields.from.isValid() && searchFields.from.format('YYYY-MM-DD')) || null,
    to: (searchFields.to && searchFields.to.isValid() && searchFields.to.add(1, 'd').format('YYYY-MM-DD')) || null,
    page: searchFields.page,
    count: (count || NUM_OF_ORDERS_PER_PAGE),
  };
  axios('/rest/V1/eplane/backoffice/orders/search', { params: reqParams }).then((response) => {
    dispatch(searchForOrdersSuccess(response.data));
  }).catch((e) => {
    requestErrorHandler(e.response, dispatch, failRequestForData, e);
  });
};

export const ordersSearchShowAllSuccess = (data) => ({
  type: ORDERS_SEARCH_SUCCESS,
  payload: data,
});
// /**
//  *
//  * @param {Object} searchParams
//  * @param {Number} [searchParams.orderID]
//  * @param {String} [searchParams.purchaseOrder]
//  * @param {Number} [searchParams.company]
//  * @param {String} [searchParams.email]
//  * @param {String} [searchParams.partNumber]
//  * @param {Number} [searchParams.status]
//  * @param {String} [searchParams.from]
//  * @param {String} [searchParams.to]
//  */
// export const ordersSearchSubmit = searchParams => (
//   (dispatch) => {
//     dispatch({
//       type: consts.ORDERS_SEARCH_SUBMIT,
//       payload: searchParams,
//     });

//     dispatch(fetchSearchingOrders(searchParams));
//   }
// );

export const ordersSearchPagination = (page) => (
  (dispatch, getState) => {
    const { lastSearchParams } = getSearchOrdersStore(getState());
    const searchParams = { fields: lastSearchParams, page };
    dispatch(fetchSearchingOrders(searchParams));
  }
);
/**
 * This function fetches all orders from server,
 * and clears last search params in store after successful requests.
 */
export const showAllSuccess = (data) => ({
  type: ORDERS_SEARCH_SHOW_ALL_SUCCESS,
  payload: data,
});

export const fetchAllOrders = () => (dispatch) => {
  dispatch(searchForOrders());

  const reqParams = { count: NUM_OF_ORDERS_PER_PAGE };
  axios('/rest/V1/eplane/backoffice/orders/search', { params: reqParams }).then((response) => {
    dispatch(showAllSuccess(response.data));
  }).catch((e) => {
    requestErrorHandler(e.response, dispatch, failRequestForData, e);
  });
};
