import { createGlobalStyle } from 'styled-components';
import { color } from './constants';

export default createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
    font-size: 14px;
  }

  body {
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    overflow-y: overlay;
  }
  strong {
    font-weight: bold;
  }
  #app {
    background-color: ${color.backgroundGrey};
    min-height: 100%;
    min-width: 100%;
    display: flex;
  }

  .viewtablerow {
    cursor: pointer;
  }

  .built-in-quote-modal {
    min-width: 750px;
  }

  .modal {
    pointer-events: none;
    .modal-dialog {
      pointer-events: none;
      .modal-content {
        pointer-events: auto;
      }
    }
  }
  .pac-container {
    z-index: 100000;
  }
  .btn-google {
    width: 240px;
    height: 50px;
    background-color: ${color.googleBlue};
    border: none;
    color: ${color.white};

    font-size: 16px;
    line-height: 48px;
    font-family: Roboto,arial,sans-serif;
    font-weight: 500;
    letter-spacing: .21px;
    margin-left: 6px;
    margin-right: 6px;
    vertical-align: top;
    padding-left: 1px;

    border-radius: 1px;
    transition: background-color .218s,border-color .218s,box-shadow .218s;
  }

  .btn-google:hover {
    box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
  }

  p {
    font-size: 16px;
  }
  .service-worker-notification {
    background-color: rgba(34,34,34,0.8);
    color: #fff;
    display:block;
    position: fixed;
    bottom:10px;
    right:10px;
    padding:0.8em 1.2em;
    text-decoration: none;
    &:hover {
      color: ${color.lightGrey};
    }
    &:active {
      color: ${color.silverGrey};
    }
  }

  .aggregation_email_modal {
    min-width: 650px;
  }
`;
