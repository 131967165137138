import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { useParams, useLocation } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';

import { getUserStore } from '../../../selectors';
import { fetchUser } from '../../../actions/userManagement/userActions';
import { fetchProSub, clearProSub } from '../../../actions/userCompanyManagement/proManagement';

import SideNavigation from '../../SideNavigation';
import Container from '../../Container';
import TabsCompanies from './TabsCompanies';
import CommentsWidget from '../../CommentsWidget';
import { color, PUBLIC_URL } from '../../../constants';

const MenuWrapper = styled(Col)`
  border-right: 2px solid ${color.lightGrey};
  margin-bottom: 10px;
  padding-bottom: 10px;
`;
const Content = styled(Col)`
  && {
  padding-left: 45px;
  clear: right;
  display: flex;
  flex-direction: column;
  }
`;
const RowContent = styled(Row)`
  margin-top: 30px;
`;

const mapRoutesToTitles = {
  companyDetails: 'Company Details',
  userSettings: {
    tabHeader: 'Company Settings',
    mapRoutes: {
      userSettings: 'General Settings',
      weightManagement: 'Tier 3 Lists Management',
      customLists: 'Custom Lists',
      whiteBlackList: 'Blocked/Approved Sellers',
      buyersLists: 'Buyer Lists Management',
      partsWatchlist: 'Parts Watchlist',
    },
  },
  pro: {
    tabHeader: 'Pro',
    mapRoutes: {
      proManagement: 'Pro Management',
      proFeatures: 'Pro Features',
    },
  },
  userDetails: 'User Management',
  paymentProviders: 'General Commissions',
  commercialAgreement: 'Agreements & Certificates',
  inventory: 'Inventory / Capabilities',
  pnList: 'Tier 2 – PN Management',
  demandList: 'Autopilot – Upload Demand List',
  categorization: 'Vendor Categories',
  orderLimits: 'Customized Orders Limited Values',
  spec2k: {
    tabHeader: 'Spec2k Management',
    mapRoutes: {
      apiRequests: 'Incoming API requests',
      integration: 'Outbound integration',
      codesManagement: 'CIC & SPL codes',
    },
  },
};

const EditCompanyPage = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const location = useLocation();

  useEffect(() => {
    batch(() => {
      dispatch(fetchUser(userId));
      dispatch(fetchProSub(userId));
    });
    return () => {
      dispatch(clearProSub());
    };
  }, [dispatch, userId]);

  const { user } = useSelector(getUserStore);

  const companyName = (user && user.company_name) || '';
  const urlPrefix = `${PUBLIC_URL}/companies/${userId}/`;
  const navLinks = [{ Companies: 'companies' }, { [companyName]: null }].filter((v) => v);

  return (
    <Container links={navLinks}>
      <RowContent>
        <MenuWrapper sm={3}>
          <SideNavigation
            routes={mapRoutesToTitles}
            urlPrefix={urlPrefix}
            currentPath={location.pathname}
          />
        </MenuWrapper>
        <Content sm={9}>
          <div>
            <TabsCompanies />
          </div>
        </Content>

        <CommentsWidget />
      </RowContent>
    </Container>
  );
};

export default EditCompanyPage;
