import React, { useState, useMemo } from 'react';
import {
  Button as ButtonBS, OverlayTrigger, Popover,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flags from 'country-flag-icons/react/3x2';
import { hasFlag } from 'country-flag-icons';
import { useSelector } from 'react-redux';

import { getCountries } from '../../selectors';

import Table from '../Table/Table';
import header from '../Table/HeaderWithSort';
import TableStyles from '../Table/TableStyles';
import CellWithTitle from '../Table/Cell';

import statusFormat from '../CompaniesManagement/CompaniesPage/statusFormat';
import dateFormat from '../../utils/dateFormat';
import { PUBLIC_URL } from '../../constants';
import emailStatusFormatter from '../../utils/emailStatusFormatter';
import copyImage from '../../assets/copy-email-address.png';
import { ButtonContainer } from '../StyledComponents';
import * as serverConsts from '../../constants/index';

const { document } = global;

const FlagContainer = styled.div`
  text-align: center;
  &&:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
`;

function countryFormatter(countryCode, ip, countries) {
  if (!countryCode || !countries) return null;

  const Flag = hasFlag(countryCode.toUpperCase()) ? Flags[countryCode.toUpperCase()] : null;
  const countryName = countries.find((v) => v.id === countryCode.toUpperCase())?.full_name_english;

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      rootClose
      overlay={(
        <Popover id="popover-country">
          <Popover.Content>
            <strong>{countryName}</strong>
            <div>{`IP: ${ip}`}</div>
          </Popover.Content>
        </Popover>
      )}
    >
      <FlagContainer title="Click to show more info">
        {
        hasFlag(countryCode.toUpperCase())
          ? <Flag style={{ maxWidth: '2em' }} />
          : countryCode.toUpperCase()
        }
      </FlagContainer>
    </OverlayTrigger>
  );
}

const CopyableCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const imageStyles = `
  background-image: url("${copyImage}");
  background-repeat: no-repeat;
  background-position: 3px 3px;

  padding-left: 27px;
`;
const Button = styled(({ showImage, ...props }) => <ButtonBS {...props} />)`
  && {
    padding: 3px 6px 3px 6px;
    ${(props) => (props.showImage ? imageStyles : '')}
  }
  &&:hover {
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
  }
`;

const UsersTable = ({ data, title, pushRedirect }) => {
  const [copyState, setCopyState] = useState({
    copiedRow: null,
    copiedCell: null,
  });

  const countries = useSelector(getCountries);

  const onCopyBtnClick = (e, rowIndex, copyText) => {
    e.stopPropagation();
    setCopyState({
      copiedRow: rowIndex,
      copiedCell: copyText,
    });

    // copy
    const textArea = document.createElement('textarea');
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Oops, unable to copy'); // eslint-disable-line no-console
    }

    document.body.removeChild(textArea);
  };

  // eslint-disable-next-line no-unused-vars
  const copyableDataFormat = (cell, row, _, rowIndex) => (
    cell && CopyableFormatter(cell, rowIndex, rowIndex === copyState.copiedRow && cell === copyState.copiedCell, onCopyBtnClick)
  );

  const columns = useMemo(
    () => [
      {
        Header: () => header('Customer id'),
        id: 'entity_id',
        accessor: 'entity_id',
        Cell: ({ value, row }) => tdClickableFormatter(value, row.original, pushRedirect),
        style: {
          width: '100px',
        },
      }, {
        Header: () => header('User name'),
        accessor: 'fullName',
        Cell: CellWithTitle,
        style: {
          width: '18%',
        },
      }, {
        Header: () => header('Email'),
        accessor: 'email',
        Cell: CellWithTitle,
        style: {
          width: '18%',
        },
      }, {
        Header: () => header('User\'s email status'),
        accessor: 'email_status_indication',
        Cell: ({ value }) => emailStatusFormatter(value, serverConsts),
      }, {
        Header: () => header('Company'),
        accessor: 'company_name',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Role'),
        Cell: CellWithTitle,
        accessor: 'company_role',
      }, {
        Header: () => header('Signup Company'),
        accessor: 'signup_company_name',
        Cell: CellWithTitle,
      }, {
        Header: () => header('Registration Date'),
        accessor: 'created_at',
        Cell: ({ value }) => dateFormat(value),
        style: {
          width: '180px',
        },
      }, {
        Header: () => header('Pro plan'),
        accessor: 'is_pro',
        Cell: statusFormat,
        style: {
          width: '70px',
          textAlign: 'center',
        },
      }, {
        Header: () => header('Country'),
        accessor: 'signup_country',
        Cell: ({ value, row }) => countryFormatter(value, row.original.signup_ip, countries),
        style: {
          width: '70px',
          textAlign: 'center',
        },
      },
    ], [pushRedirect, countries],
  );

  return (
    <TableStyles>
      {title}
      <Table columns={columns} data={data} />
    </TableStyles>
  );
};

UsersTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  pushRedirect: PropTypes.func,
};

export const tdClickableFormatter = (value, row, goTo) => {
  let button = (
    <Button
      variant="info"
      onClick={() => goTo(`${PUBLIC_URL}/userManagement/${row.user_id}/${row.entity_id}`)}
    >
      <FontAwesomeIcon icon="user" />
      &nbsp;
      {value}
    </Button>
  );

  if (row.is_external) {
    button = (
      <Button
        variant="outline-secondary"
        onClick={() => goTo(`${PUBLIC_URL}/userManagement/${row.user_id}/${row.entity_id}`)}
        title="External user"
      >
        <FontAwesomeIcon icon="user" />
        &nbsp;
        {value}
      </Button>
    );
  } else if (row.is_pro) {
    button = (
      <Button
        variant="outline-info"
        onClick={() => goTo(`${PUBLIC_URL}/userManagement/${row.user_id}/${row.entity_id}`)}
        title="Pro user"
      >
        <FontAwesomeIcon icon="crown" />
        &nbsp;
        {value}
      </Button>
    );
  }

  return (
    <ButtonContainer btnWidth="80px">
      {button}
    </ButtonContainer>
  );
};

const CopyableFormatter = (data, rowIndex, isCopied, onCopyBtnClick) => (
  <CopyableCell>
    <span>{data}</span>
    {' '}
    <Button
      onClick={(e) => onCopyBtnClick(e, rowIndex, data)}
      variant={isCopied ? 'success' : undefined}
      showImage={!isCopied}
    >
      <span>{isCopied ? 'Copied' : 'Copy'}</span>
    </Button>
  </CopyableCell>
);

export default UsersTable;
