import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Row, Col, Form, FormLabel, FormGroup,
} from 'react-bootstrap';
import Select from 'react-select';

import { ButtonsWrapper, Button, Text } from '../../Modals/styledComponents';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import { supplierCodeValidator } from './validators';

const SupplierCodeModal = ({
  data, companiesOptions, onCompanyChange, onCodeChange, onConfirm, onHide, ...other
}) => (
  <Modal centered onHide={onHide} {...other}>
    <Modal.Body>
      <Text>{data ? 'Change partner code' : 'Add partner code'}</Text>
      <br />
      <Form>
        <FormGroup>
          <FormLabel>
            Company
          </FormLabel>
          <Row>
            <Col sm={12}>
              <Select
                value={(data && data.company_name) ?? ''}
                name="company_name"
                placeholder="Choose company..."
                isDisabled={data}
                options={companiesOptions}
                onChange={onCompanyChange}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <BaseFormGroup
            value={(data && data.code) ?? ''}
            name="code"
            labelText="Code"
            onChange={onCodeChange}
            maxLength="32"
            {...supplierCodeValidator(data?.code)}
          />
        </FormGroup>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <ButtonsWrapper>
        <Button
          variant="primary"
          onClick={onConfirm}
          disabled={!data || !data.company_name || supplierCodeValidator(data?.code)}
        >
          {data ? 'Save Changes' : 'Add'}
        </Button>
        <Button onClick={onHide} variant="secondary">Cancel</Button>
      </ButtonsWrapper>
    </Modal.Footer>
  </Modal>
);

SupplierCodeModal.propTypes = {
  data: PropTypes.shape({
    company_name: PropTypes.string,
    code: PropTypes.string,
  }),
  companiesOptions: PropTypes.arrayOf(PropTypes.object),
  onCompanyChange: PropTypes.func.isRequired,
  onCodeChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default SupplierCodeModal;
