import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Form, Button,
} from 'react-bootstrap';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';

import {
  CustomOption, SingleValue, SelectValidationWrapper, SelectedItemContainer,
} from './Helpers';
import { StyledLinkButton as LinkButtonNormal } from '../../StyledComponents';
import { getCompanyListStore } from '../../../selectors';
import BaseFormGroup from '../../FormComponents/BaseFormGroup';
import { color } from '../../../constants';

const FormContainer = styled.div`
  border: 1px solid ${color.altoGrey};
  padding: 25px 15px;
`;
const SelectCol = styled(Col)`

`;
const ListNameCol = styled(Col)`
  input {
    height: 38px;
  }
`;
const SellerCol = styled(Form.Label)`
  text-align: right;
`;
const LinkButton = styled(LinkButtonNormal)`
  font-size: 16px;
`;

const menuHeaderStyle = {
  padding: '8px 12px',
  borderTop: `1px solid ${color.lightGrey}`,
};

const SellersListForm = ({
  newList,
  sellerOptions,
  onSellerRemoveHandler,
  onSelectedSellerChange,
  onAddCustomSellerHandler,
  onNewListSaveHandler,
  onListNameChange,
}) => {
  const { isFetching: isFetchingCompanies } = useSelector(getCompanyListStore);
  const disableSave = !!newList.sellerListValidation || !!newList.nameValidation;

  return (
    <FormContainer>
      <Form>
        <p><b>Create new custom list</b></p>
        <br />
        <Row>
          <Col sm={8}>
            <Form.Group as={Row}>
              <SellerCol column sm={2}>
                <b>List Name</b>
              </SellerCol>
              <ListNameCol sm={5}>
                <BaseFormGroup
                  value={newList.listName}
                  placeholder="Enter a unique list name"
                  name="company_name"
                  onChange={onListNameChange}
                  disabled={false}
                  {...newList.nameValidation}
                />
              </ListNameCol>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <Form.Group as={Row}>
              <SellerCol column sm={2}>
                <b>Seller</b>
              </SellerCol>
              <SelectCol sm={5}>
                <SelectValidationWrapper
                  isError={newList.sellerListValidation}
                  errorText="At least one seller required"
                >
                  <Select
                    value={newList.selectedSeller}
                    name="seller"
                    placeholder="Search by seller's name"
                    onChange={onSelectedSellerChange}
                    options={sellerOptions}
                    components={{ MenuList: getMenuList(onAddCustomSellerHandler), Option: CustomOption, SingleValue }}
                    hideSelectedOptions={false}
                    isLoading={isFetchingCompanies}
                    isClearable
                  />
                </SelectValidationWrapper>
              </SelectCol>
            </Form.Group>
          </Col>
        </Row>
        <Row noGutters>
          <Col sm={8}>
            <Row noGutters>
              <Col sm={{ span: 9, offset: 2 }}>
                <SelectedItemContainer list={newList.selectedSellerList} onRemove={onSellerRemoveHandler} />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row noGutters>
          <Col sm={8}>
            <Row noGutters>
              <Col sm={{ span: 6, offset: 2 }}>
                <Button
                  variant="primary"
                  onClick={onNewListSaveHandler}
                  disabled={disableSave}
                >
                  Save list
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
};

// eslint-disable-next-line react/prop-types
export const getMenuList = (onClickHandler) => ({ children, ...props }) => (
  <components.MenuList {...props}>
    {children}
    <div style={menuHeaderStyle}>
      Didn&apos;t find the seller?&nbsp;
      <LinkButton
        variant="link"
        // eslint-disable-next-line react/prop-types
        onClick={() => onClickHandler(props.selectProps.inputValue)}
      >
        Add it here
      </LinkButton>
    </div>
  </components.MenuList>
);

SellersListForm.propTypes = {
  newList: PropTypes.shape({
    listName: PropTypes.string,
    selectedSeller: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.object,
    ]),
    selectedSellerList: PropTypes.arrayOf(PropTypes.object),
    nameValidation: PropTypes.shape({
    }),
    sellerListValidation: PropTypes.bool,
  }),
  sellerOptions: PropTypes.arrayOf(PropTypes.object),

  onSellerRemoveHandler: PropTypes.func.isRequired,
  onSelectedSellerChange: PropTypes.func.isRequired,
  onAddCustomSellerHandler: PropTypes.func.isRequired,
  onNewListSaveHandler: PropTypes.func.isRequired,
  onListNameChange: PropTypes.func.isRequired,
};

export default SellersListForm;
