import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';

import Pagination from '../Pagination';

const Container = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
`;

export default function PaginationTable({
  pageSize,
  onPageSizeChange,
  ...other
}) {
  return (
    <Container>
      <div>
        <Form>
          <Form.Group controlId="selectCustom">
            <Form.Control
              value={pageSize}
              onChange={(e) => onPageSizeChange(Number(e.target.value))}
              as="select"
              custom
            >
              {[10, 25, 30, 50].map((ps) => (
                <option key={ps} value={ps}>
                  {ps}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </div>
      <Pagination {...other} />
    </Container>
  );
}

PaginationTable.propTypes = {
  pageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPageSizeChange: PropTypes.func,
};
