import {
  SET_SHOWN_FORM,
  HIDE_SHOWN_FORM,
  SET_TASKS_BAR,
  SET_HISTORY_BAR,
} from '../../constants';

const initialState = {
  currentForm: null,
  showHistoryBar: true,
  showTasksBar: true,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_SHOWN_FORM:
      return {
        ...state,
        showHistoryBar: true,
        showTasksBar: false,
        currentForm: payload,
      };

    case HIDE_SHOWN_FORM:
      return {
        ...state,
        showTasksBar: true,
        currentForm: null,
      };

    case SET_TASKS_BAR:
      return {
        ...state,
        showTasksBar: payload,
      };

    case SET_HISTORY_BAR:
      return {
        ...state,
        showHistoryBar: payload,
      };
    default: return state;
  }
};
