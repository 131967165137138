import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AutoSuggestStyled from '../AutosuggestStyled';
import BaseFormGroup from '../BaseFormGroup';
import renderValidation from '../renderHelpBlock';
import { color } from '../../../constants';

const InputContainer = styled.div`
  .form-control {
    ${(props) => (props.hasClear && 'padding-right: 30px')};
  }
`;
const InlineFormContainer = styled.div`
  && .form-group {
    margin-bottom: 0px;
  }
  margin-bottom: 15px;
`;
const ClearElement = styled.span`
  position: absolute;
  top: 7px;
  right: 25px;
  z-index: 3;
  pointer-events: all;
  color: ${color.silverGrey};
`;

const AutosuggestControl = ({
  horizontal,

  id,
  validationState,
  labelColumnWidth,
  controlColumnWidth,
  helpText,
  feedBack,
  labelText,

  onChange,
  onClear,
  onBlur,
  maxLength,
  value,
  name,
  placeholder,
  ...other
}) => {
  /**
   * Function emulates behavior of native event generation
   */
  const onInputChange = (_, { newValue }) => {
    onChange({
      target: {
        value: newValue,
        name,
      },
    });
  };

  const renderInputComponent = (inputProps) => {
    const { hasClear, ...otherProps } = inputProps;
    const newProps = { ...otherProps };

    newProps.ref = undefined;
    return (
      <InputContainer hasClear={hasClear}>
        <BaseFormGroup inputRef={inputProps.ref} {...newProps} />
      </InputContainer>
    );
  };

  const renderHorizontalInput = (inputProps) => {
    const { hasClear, ...otherProps } = inputProps;
    const newProps = { ...otherProps };
    const controlValidation = {
      isValid: validationState === 'success',
      isInvalid: validationState === 'error',
    };

    return (
      <>
        <Form.Control {...controlValidation} ref={inputProps.ref} {...newProps} />
        {renderValidation(helpText, validationState)}
      </>
    );
  };

  const inputProps = {
    value,
    name,
    maxLength,
    hasClear: !!onClear,
    placeholder,
    onChange: onInputChange,
    onBlur,
    disabled: other.disabled,
  };

  if (horizontal) {
    return (
      <Form.Group as={Row} controlId={id}>
        {labelColumnWidth !== 0 && (
          <Form.Label column sm={labelColumnWidth}>
            {labelText}
          </Form.Label>
        )}
        <Col sm={controlColumnWidth}>
          <AutoSuggestStyled
            inputProps={inputProps}
            {...other}
            renderInputComponent={renderHorizontalInput}
          />
          {onClear && value && (
            <ClearElement
              onClick={onClear}
              role="button"
              tabIndex={-1}
              onKeyPress={null}
              title="Clear"
            >
              <FontAwesomeIcon icon="times" />
            </ClearElement>
          )}
        </Col>
      </Form.Group>
    );
  }

  const inputPropsInline = {
    ...inputProps,
    labelText,
    helpText,
    validationState,
  };

  return (
    <InlineFormContainer>
      <Form.Group controlId={id}>
        <AutoSuggestStyled
          inputProps={inputPropsInline}
          {...other}
          renderInputComponent={renderInputComponent}
        />
        {onClear && value && (
          <ClearElement
            onClick={onClear}
            role="button"
            tabIndex={-1}
            onKeyPress={null}
            title="Clear"
          >
            <FontAwesomeIcon icon="times" />
          </ClearElement>
        )}
      </Form.Group>
    </InlineFormContainer>
  );
};

AutosuggestControl.propTypes = {
  inline: PropTypes.bool,
  horizontal: PropTypes.bool,

  id: PropTypes.number,
  validationState: PropTypes.oneOf(['success', 'warning', 'error']),
  labelColumnWidth: PropTypes.number,
  controlColumnWidth: PropTypes.number,
  helpText: PropTypes.string,
  feedBack: PropTypes.bool,
  labelText: PropTypes.string,

  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

AutosuggestControl.defaultProps = {
  inline: true,
  labelColumnWidth: 4,
  controlColumnWidth: 8,
};

export default AutosuggestControl;
