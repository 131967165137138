import {
  CHAT_DATA_FETCH,
  CHAT_DATA_IS_FETCHED,
  CHAT_DATA_CLEAR,
  CHAT_ERROR_IS_OCCURRED,
} from '../../constants';

const initialState = {
  order: null,
  messages: null,
  attachments: null,
  providers: null,
  platformPay: null,
  isFetching: false,
};

const chat = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHAT_DATA_FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case CHAT_DATA_IS_FETCHED:
      return {
        ...state,
        isFetching: false,
        order: payload.order,
        messages: payload.messages,
        attachments: payload.attachments,
        providers: payload.payment_providers,
        platformPay: payload.platform_pay,
      };

    case CHAT_ERROR_IS_OCCURRED:
      return {
        ...state,
        isFetching: false,
      };

    case CHAT_DATA_CLEAR:
      return initialState;

    default: return state;
  }
};

export default chat;

export const getOrderData = (state) => state.order;
