import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import Img from '../../Img';
import EmptyListImg from '../../../assets/empty-parts.png';

const Container = styled.div`
  margin-top: 35px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
const Image = styled(Img)`
  width: 90px;
`;
const Title = styled.h3`
  font-size: 1.4em;
  font-weight: 700;
`;
const Text = styled.div`
  font-size: 1.1em;
`;
const LinkButton = styled(Button)`
  margin-top: 2px;
  padding: 0 5px;
  font-weight: bold;
`;
export default function EmptyPartsContent({ onAddPnHandler, onUploadHandler }) {
  return (
    <Container>
      <Image src={EmptyListImg} alt="Empty List" />
      <br />
      <Title>Your Parts Watchlist is empty</Title>
      <Text>
        <LinkButton variant="link" onClick={onUploadHandler}>Upload</LinkButton>
        or
        <LinkButton variant="link" onClick={onAddPnHandler}>add part number manually</LinkButton>
        to be notified by email
        <br />
        when new matching items are added to ePlane&apos;s inventory.
      </Text>
    </Container>
  );
}

EmptyPartsContent.propTypes = {
  onAddPnHandler: propTypes.func.isRequired,
  onUploadHandler: propTypes.func.isRequired,
};
