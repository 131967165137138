import {
  PARTS_WATCHLIST_GET,
  PARTS_WATCHLIST_GET_SUCCESS,
  PARTS_WATCHLIST_ALL_GET,
  PARTS_WATCHLIST_ALL_GET_SUCCESS,
  PARTS_WATCHLIST_ADD,
  PARTS_WATCHLIST_ADD_SUCCESS,
  PARTS_WATCHLIST_DELETE,
  PARTS_WATCHLIST_DELETE_SUCCESS,
  PARTS_WATCHLIST_FAIL,
} from '../../constants';

const initialState = {
  partNumbers: [],
  watchlistItems: [],
  page: 0,
  lastPage: 0,
  total: 0,

  isFetching: false,
  isProcessing: false,
  isUploading: false,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case PARTS_WATCHLIST_GET:
      return {
        ...state,
        isFetching: true,
      };
    case PARTS_WATCHLIST_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        watchlistItems: payload.watchlist_items,
        page: payload.page,
        lastPage: Math.ceil(payload.total / payload.per_page),
        total: payload.total,
      };

    case PARTS_WATCHLIST_ALL_GET:
      return {
        ...state,
        isProcessing: true,
      };
    case PARTS_WATCHLIST_ALL_GET_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        partNumbers: payload,
      };

    case PARTS_WATCHLIST_ADD:
      return {
        ...state,
        isProcessing: true,
      };
    case PARTS_WATCHLIST_ADD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        watchlistItems: payload.watchlist_items,
        page: payload.page,
        lastPage: Math.ceil(payload.total / payload.per_page),
        total: payload.total,
      };

    case PARTS_WATCHLIST_DELETE:
      return {
        ...state,
        isProcessing: true,
      };
    case PARTS_WATCHLIST_DELETE_SUCCESS:
      return {
        ...state,
        watchlistItems: payload.watchlist_items,
        page: payload.page,
        lastPage: Math.ceil(payload.total / payload.per_page),
        total: payload.total,
        isProcessing: false,
      };
    case PARTS_WATCHLIST_FAIL:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
      };

    default:
      return state;
  }
};
