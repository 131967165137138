import { leadTimeUnits, tagType, traceTo } from '../../constants/index';
import { getNameById } from '../../utils/constantsHandlers';

export const getSiteTitle = (isRepair, pickupSite) => (
  isRepair ? `Repair Shop: ${pickupSite || '-'}` : `Pickup Site: ${pickupSite || '-'}`
);

export const formatTime = (time, units) => {
  let value = '';
  let unitsText = '';
  switch (units) {
    case leadTimeUnits.HOURS.id:
      value = time;
      unitsText = (value === 1) ? 'hour' : 'hours';
      break;
    case leadTimeUnits.DAYS.id:
      value = Math.floor(time / 24);
      unitsText = (value === 1) ? 'day' : 'days';
      break;
    default:
      value = time;
      unitsText = `${units}${value === 1 ? '' : 's'}`;
  }
  return time ? `${value} ${unitsText}` : '-';
};

export const getTime = (time, units) => {
  let unitsText = '';
  switch (units) {
    case leadTimeUnits.HOURS.id:
      unitsText = (time === 1) ? 'hour' : 'hours';
      break;
    case leadTimeUnits.DAYS.id:
      unitsText = (time === 1) ? 'day' : 'days';
      break;
    default:
      unitsText = `${units}${time === 1 ? '' : 's'}`;
  }
  return time ? `${time} ${unitsText}` : '-';
};

export const formatUnits = (value, measureUnits) => (
  value ? `${value} ${measureUnits}` : '-'
);

export const getTagName = (type, name, isTagDocRemoved) => {
  if ((!type && !name) || isTagDocRemoved) return '-';
  if (type === tagType.CUSTOM.id) return name;
  return getNameById(type, tagType);
};

export const getTraceTo = (trace) => {
  if (!trace) return '-';
  return getNameById(trace, traceTo) || trace;
};

export const getPriceForQuote = (quote, quoteFinal) => {
  let finalRemark = null;
  if (!quote.price && quoteFinal.unit_price_amount_icr && quoteFinal.unit_price_amount) {
    finalRemark = `Price (per unit): ${quoteFinal.unit_price_amount_icr} ${quoteFinal.unit_price_amount}`;
  }
  return finalRemark;
};
