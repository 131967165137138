import { delay, call, put } from 'redux-saga/effects';

import { addToast, deleteToast } from '../actions/toastsActions';
import { taskStatus } from '../constants/index';
import { notificationTypes } from '../constants';
import Api from '../API';

export default function* tasksChecker() {
  let taskNotificationEnabled = +localStorage.getItem('task_notification_enabled') ?? true; // eslint-disable-line no-undef
  let time = localStorage.getItem('task_notification_range') ?? 300000; // eslint-disable-line no-undef
  localStorage.setItem('task_notification_range', time); // eslint-disable-line no-undef

  while (true) {
    try {
      if (taskNotificationEnabled) {
        const params = { s: taskStatus.OPEN };
        const { data } = yield call(Api.getNocTasksList, params);
        if (data?.payload.total_items > 0) {
          yield put(deleteToast(null, notificationTypes.TASKS));
          yield put(addToast(
            'New open tasks',
            `${data?.payload.total_items > 1
              ? `There are ${data?.payload.total_items} open tasks`
              : `There is ${data?.payload.total_items} open task`}`,
            -1,
            notificationTypes.TASKS,
          ));
        }
      }
      yield delay(time);
      taskNotificationEnabled = +localStorage.getItem('task_notification_enabled'); // eslint-disable-line no-undef
      time = localStorage.getItem('task_notification_range'); // eslint-disable-line no-undef
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}
