import {
  NEWS_LIST_FETCH,
  NEWS_LIST_IS_FETCHED,
  NEWS_UPLOADING_START,
  NEWS_UPLOADING_FINISH,
  NEWS_DELETING_START,
  NEWS_DELETING_FINISH,
  NEWS_REORDER,
  NEWS_REORDER_SUCCESS,

  FEATURED_IN_LIST_FETCH,
  FEATURED_IN_LIST_IS_FETCHED,
  FEATURED_IN_UPLOADING_START,
  FEATURED_IN_UPLOADING_FINISH,
  FEATURED_IN_DELETING_START,
  FEATURED_IN_DELETING_FINISH,
  FEATURED_IN_REORDER,
  FEATURED_IN_REORDER_SUCCESS,

  NEWS_ERROR,
} from '../../constants';

const initialState = {
  featuredIn: [],
  newsList: [],
  isFetching: false,
  isProcessing: false,
  page: 1,
  total: 1,
  pageSize: 50,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case NEWS_LIST_FETCH: return {
      ...state,
      isFetching: true,
    };
    case NEWS_LIST_IS_FETCHED:
      return {
        ...state,
        newsList: payload.newsList,
        page: payload.page,
        total: payload.total,
        isFetching: false,
      };
    case NEWS_UPLOADING_START: return {
      ...state,
      isProcessing: true,
    };
    case NEWS_UPLOADING_FINISH: {
      return {
        ...state,
        newsList: payload.newsList,
        page: payload.page,
        total: payload.total,
        isProcessing: false,
      };
    }
    case NEWS_DELETING_START:
      return {
        ...state,
        isProcessing: true,
      };
    case NEWS_DELETING_FINISH:
      return {
        ...state,
        newsList: state.newsList && state.newsList.filter((v) => v.news_id !== payload),
        isProcessing: false,
      };
    case NEWS_REORDER:
      return {
        ...state,
        isProcessing: true,
      };
    case NEWS_REORDER_SUCCESS:
      return {
        ...state,
        newsList: payload.newsList,
        page: payload.page,
        total: payload.total,
        isProcessing: false,
      };

    case FEATURED_IN_LIST_FETCH: return {
      ...state,
      isFetching: true,
    };
    case FEATURED_IN_LIST_IS_FETCHED:
      return {
        ...state,
        featuredIn: payload.featuredIn,
        page: payload.page,
        total: payload.total,
        isFetching: false,
      };
    case FEATURED_IN_UPLOADING_START: return {
      ...state,
      isProcessing: true,
    };
    case FEATURED_IN_UPLOADING_FINISH: {
      return {
        ...state,
        featuredIn: payload.featuredIn,
        page: payload.page,
        total: payload.total,
        isProcessing: false,
      };
    }
    case FEATURED_IN_DELETING_START:
      return {
        ...state,
        isProcessing: true,
      };
    case FEATURED_IN_DELETING_FINISH:
      return {
        ...state,
        featuredIn: state?.featuredIn.filter((v) => v.item_id !== payload),
        isProcessing: false,
      };
    case FEATURED_IN_REORDER:
      return {
        ...state,
        isProcessing: true,
      };
    case FEATURED_IN_REORDER_SUCCESS:
      return {
        ...state,
        featuredIn: payload.featuredIn,
        page: payload.page,
        total: payload.total,
        isProcessing: false,
      };

    case NEWS_ERROR:
      return {
        ...state,
        isProcessing: false,
        isFetching: false,
      };
    default: return state;
  }
};
