import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getCompanyCustomer, getUserStore } from '../../../selectors';
import {
  fetchCompanyCustomerList,
  companyCustomerInvite,
  companyCustomerReinvite,
  companyCustomerUpdate,
  companyCustomerDeactivate,
  companyCustomerReactivate,
  removeErrorsCompanyCustomer,
  companyCustomerDismiss,
} from '../../../actions/companiesManagement/companyCustomerActions';
import { pushRedirect } from '../../../actions/redirectActions';
import CustomerModal from './CustomerModal/index';
import ErrorModal from '../../ErrorModal';
import ErrorDeactivateModal from './ErrorDeactivateModal';
import ConfirmationLastUserModal from '../../Modals/ConfirmationLastUserModal';
import RemoveModal from '../../Modals/RemoveModal';
import { TabContainer, LinkButton as LinkButtonNormal } from '../../StyledComponents';
import FormTitleSection from '../../FormComponents/TitleSection';
import UMTable from '../../UserCompanyManagement/Table';
import statusButtonImg from '../../../assets/btn-status-sprite.png';

import changesMapper from '../../../utils/diffMapper';
import Loader from '../../Loader';
import { customerStatus, userType } from '../../../constants/index';
import * as constants from '../../../constants/index';

const LinkButton = styled(LinkButtonNormal)`
  font-size: 16px;
`;
const LinkExpiredButton = styled(LinkButtonNormal)`
  font-size: 13px;
`;
const PendingButton = styled.span`
  display: inline-block;
  background: url(${statusButtonImg}) no-repeat 0 0;
  background-position: 0 0px;
  width: 25px;
  height: 25px;
`;
const ActiveButton = styled.span`
  display: inline-block;
  background: url(${statusButtonImg}) no-repeat 0 0;
  background-position: 0 -35px;
  width: 20px;
  height: 15px;
`;
const InactiveButton = styled.span`
  display: inline-block;
  background: url(${statusButtonImg}) no-repeat 0 0;
  background-position: 0 -96px;
  width: 25px;
  height: 25px;
`;
const ExpiredButton = styled.span`
  display: inline-block;
  background: url(${statusButtonImg}) no-repeat 0 0;
  background-position: 0 -60px;
  width: 27px;
  height: 25px;
`;
const DeletedButton = styled.span`
  display: inline-block;
  background: url(${statusButtonImg}) no-repeat 0 0;
  background-position: 0 -131px;
  width: 27px;
  height: 27px;
`;
const usersColumns = [
  {
    name: 'Status',
    dataField: 'customer_status',
    width: '10%',
    tdStyle: { textAlign: 'center' },
    thStyle: { textAlign: 'center' },
  },
  {
    name: 'Email',
    dataField: 'email',
    width: '30%',
    tdStyle: { textAlign: 'center' },
    thStyle: { textAlign: 'center' },
  },
  {
    name: 'First Name',
    dataField: 'firstname',
    width: '20%',
    tdStyle: { textAlign: 'center' },
    thStyle: { textAlign: 'center' },
  },
  {
    name: 'Last Name',
    dataField: 'lastname',
    width: '20%',
    tdStyle: { textAlign: 'center' },
    thStyle: { textAlign: 'center' },
  },
  {
    name: 'Role',
    dataField: 'company_role',
    width: '15%',
    tdStyle: { textAlign: 'center' },
    thStyle: { textAlign: 'center' },
  },
];

export default function CompanyCustomerManagementPage() {
  const companyCustomerRef = useRef(null);

  const dispatch = useDispatch();
  const { userId } = useParams();

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [currCustomerData, setCurrCustomerData] = useState(null);
  const [dismissCustomerId, setDismissCustomerId] = useState(null);
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [isReactivate, setReactivate] = useState(false);
  const [modals, setModals] = useState({
    showRemoveModalForOne: false,
    showDismissModal: false,
    showCustomerModal: false,
    showLastUserModal: false,
  });

  const { user } = useSelector(getUserStore);
  const companyCustomer = useSelector(getCompanyCustomer);
  const hasInventory = user && user.has_published_inventory;
  const hasPro = user && user.is_pro;
  const isCompany = user && user.user_type === userType.COMPANY.id;

  useEffect(() => {
    dispatch(fetchCompanyCustomerList(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (companyCustomerRef.current) {
      if ((!companyCustomerRef.current.companyCustomer?.deactivatingErrorCode && companyCustomer.deactivatingErrorCode)
        || (companyCustomerRef.current.companyCustomer?.isDeactivating && !companyCustomer.isDeactivating)) {
        closeRemoveModal();
      }
      if ((companyCustomerRef.current.companyCustomer?.isUpdating && !companyCustomer.isUpdating && !companyCustomer.updateErrorCode)
        || (companyCustomerRef.current.companyCustomer?.isInviting && !companyCustomer.isInviting && !companyCustomer.inviteErrorCode)) {
        closeCustomerModal();
      }
    }
    companyCustomerRef.current = companyCustomer;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCustomer]);

  useEffect(() => {
    if (!companyCustomer.isInviting && !companyCustomer.inviteErrorCode) {
      closeCustomerModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCustomer.isInviting, companyCustomer.inviteErrorCode]);

  useEffect(() => {
    if (!companyCustomer.isUpdating && !companyCustomer.updateErrorCode) {
      closeCustomerModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCustomer.isUpdating, companyCustomer.updateErrorCode]);

  const onSaveCustomerData = (savingData) => {
    if (addNewCustomer) {
      dispatch(companyCustomerInvite(userId, savingData));
    } else if (selectedCustomer) {
      const diffObject = changesMapper(savingData, currCustomerData);
      if (Object.keys(diffObject).length || isReactivate) {
        dispatch(companyCustomerUpdate(userId, selectedCustomer, diffObject, isReactivate));
      } else {
        closeCustomerModal();
      }
    }
  };

  const onClickDeactivate = (id) => {
    const { customers } = companyCustomer;
    const activeCustomers = customers.filter((elem) => elem.customer_status === customerStatus.ACTIVE.id);

    setSelectedCustomer(id);
    setModals((prev) => ({
      ...prev,
      showRemoveModalForOne: true,
      showLastUserModal: customers
        && activeCustomers.length === 1
        && activeCustomers[0].entity_id === id
        && isCompany,
    }));
  };

  const onClickReactivate = (id) => dispatch(companyCustomerReactivate(userId, id));

  const onClickDismiss = (id) => {
    const { customers } = companyCustomer;
    const activeCustomers = customers.filter((elem) => elem.customer_status === customerStatus.ACTIVE.id);

    setDismissCustomerId(id);
    setModals((prev) => ({
      ...prev,
      showDismissModal: true,
      showLastUserModal: customers
        && activeCustomers.length === 1
        && activeCustomers[0].entity_id === id
        && isCompany,
    }));
  };

  const onClickAddCustomer = () => {
    setModals((prev) => ({ ...prev, showCustomerModal: true }));
    setAddNewCustomer(true);
    setReactivate(false);
  };

  const deactivateOne = () => {
    dispatch(companyCustomerDeactivate(userId, [selectedCustomer]));
    closeRemoveModal();
  };

  const dismissCustomer = () => {
    dispatch(companyCustomerDismiss(dismissCustomerId, userId));
    closeRemoveModal();
  };

  const statusFormatter = (status, row) => {
    const clickHandler = () => dispatch(companyCustomerReinvite(userId, row.entity_id));
    let statusCell = null;

    switch (status) {
      case customerStatus.PENDING.id:
        statusCell = (
          <LinkExpiredButton onClick={clickHandler}>
            <PendingButton title="Pending" />
            <br />
            Resend
            <br />
            Invitation
          </LinkExpiredButton>
        ); // PENDING
        break;
      case customerStatus.ACTIVE.id:
        statusCell = <ActiveButton title="Active" />; // ACTIVE
        break;
      case customerStatus.INACTIVE.id:
        statusCell = <InactiveButton title="Inactive" />; // INACTIVE
        break;
      case customerStatus.DELETED.id:
        statusCell = <DeletedButton title="Deleted" />; // Deleted
        break;
      default:
        statusCell = (
          <LinkExpiredButton onClick={clickHandler}>
            <ExpiredButton title="Expired" />
            <br />
            Resend
            <br />
            Invitation
          </LinkExpiredButton>
        ); // EXPIRED_STATUS
    }
    return <div style={{ textAlign: 'center' }}>{statusCell}</div>;
  };

  const openCustomerModal = (id) => {
    const currCustomer = companyCustomer.customers.find((elem) => elem.entity_id === id);

    setCurrCustomerData({
      email: currCustomer.email,
      firstname: currCustomer.firstname,
      lastname: currCustomer.lastname,
      company_role: currCustomer.company_role,
    });
    setReactivate(currCustomer.customer_status === customerStatus.INACTIVE.id);
    setSelectedCustomer(id);
    setModals((prev) => ({ ...prev, showCustomerModal: true }));
  };

  const closeCustomerModal = () => {
    if (addNewCustomer) {
      setAddNewCustomer(false);
      setModals((prev) => ({ ...prev, showCustomerModal: false }));
    } else {
      setSelectedCustomer(null);
      setCurrCustomerData(null);
      setModals((prev) => ({ ...prev, showCustomerModal: false }));
      setReactivate(false);
    }
    if (companyCustomer.inviteErrorCode || companyCustomer.updateErrorCode) {
      dispatch(removeErrorsCompanyCustomer());
    }
  };

  const closeRemoveModal = () => {
    if (modals.showRemoveModalForOne) {
      setModals((prev) => ({ ...prev, showRemoveModalForOne: false }));
      setSelectedCustomer(null);
    }
    if (modals.showDismissModal) {
      setModals((prev) => ({ ...prev, showDismissModal: false }));
      setDismissCustomerId(null);
    }
    if (modals.showLastUserModal) {
      setModals((prev) => ({ ...prev, showLastUserModal: false }));
    }
  };

  const hideErrorModal = () => dispatch(removeErrorsCompanyCustomer());

  const hideWarningModal = () => {
    setModals((prev) => ({ ...prev, showWarningModal: false }));
  };

  const closeLastUserModal = () => {
    setModals((prev) => ({ ...prev, showLastUserModal: false }));
  };

  const customersTableData = (customers) => {
    let customersData = customers;
    if (customers) {
      customersData = customers.map((elem) => {
        const customer = { ...elem };
        // Change to custom status (resend invitation)
        if (customer.customer_status === constants.customerStatus.PENDING.id && !customer.invite_is_alive) {
          customer.customer_status = -1;
        }
        if (customer.customer_status !== constants.customerStatus.INACTIVE.id) {
          customer.canCheck = true;
          customer.remove = true;
        } else {
          customer.canCheck = false;
          customer.remove = false;
        }
        return customer;
      });
      const columnStatus = usersColumns.find((elem) => elem.dataField === 'customer_status');
      columnStatus.dataFormat = statusFormatter;
    }
    return customersData;
  };

  const {
    customers,
    isFetching,
    isInviting,
    isReinviting,
    isUpdating,
    isDeactivating,
    isDismissing,
    fetchingErrorCode,
    inviteErrorCode,
    reinviteErrorCode,
    updateErrorCode,
    deactivatingErrorCode,
  } = companyCustomer;

  if (isFetching || isReinviting) return <Loader />;

  const showErrorModal = !!(fetchingErrorCode || reinviteErrorCode || deactivatingErrorCode);
  const customersData = customersTableData(customers);

  const addAndDeactivate = (
    <LinkButton onClick={onClickAddCustomer}>
      <FontAwesomeIcon icon="plus" />
      &nbsp;Add user
    </LinkButton>
  );

  return (
    <div>
      <FormTitleSection title="User Management" showEditButton={false} />
      <TabContainer>
        <div>
          <p>Manage the users with access to your company’s ePlane account:</p>
          <UMTable
            maxHeight={605}
            columns={usersColumns}
            data={customersData}
            keyField="entity_id"
            btnControls={addAndDeactivate}
            onDismiss={onClickDismiss}
            onEdit={openCustomerModal}
            onRemove={onClickDeactivate}
            onUndoRemove={onClickReactivate}
            removeText="Deactivate"
            undoRemoveText="Reactivate"
            trClassName="text-center"
            pushRedirect={(...props) => dispatch(pushRedirect(...props))}
            showEntityColumn
            showDismissColumn
            showUndoRemove
          />
        </div>
      </TabContainer>

      <CustomerModal
        key={currCustomerData && currCustomerData.email}
        show={modals.showCustomerModal}
        customerData={currCustomerData}
        isReactivate={isReactivate}
        onHide={closeCustomerModal}
        onSaveHandler={onSaveCustomerData}
        errorCode={inviteErrorCode || updateErrorCode}
        isSaving={isInviting || isUpdating}
      />
      <RemoveModal
        show={modals.showRemoveModalForOne && !modals.showLastUserModal}
        onRemove={deactivateOne}
        onHide={closeRemoveModal}
        onExited={closeRemoveModal}
        isRemoving={isDeactivating}
        textRemoving="Deactivating"
        textRemove="Deactivate"
        text="Are you sure you want to deactivate this user on this company's ePlane account?"
      />
      <RemoveModal
        show={modals.showDismissModal && !modals.showLastUserModal}
        onRemove={dismissCustomer}
        onHide={closeRemoveModal}
        onExited={closeRemoveModal}
        isRemoving={isDismissing}
        textRemoving="Dismissing"
        textRemove="Dismiss"
        text="Are you sure you want to dismiss this user on this company's ePlane account?"
      />
      <ConfirmationLastUserModal
        show={modals.showLastUserModal}
        userId={userId}
        hasInventory={hasInventory}
        hasPro={hasPro}
        confirm={closeLastUserModal}
        onHide={closeRemoveModal}
      />
      <ErrorModal show={showErrorModal} onHide={hideErrorModal} />
      <ErrorDeactivateModal show={modals.showWarningModal} onHide={hideWarningModal} />
    </div>
  );
}
