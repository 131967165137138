/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchTimeZones, saveAggregationEmailsSettings } from '../../../actions/userManagement/filterActions';
import { getFiltersStore, getUserStore } from '../../../selectors';
import { StyledLinkButton as LinkButtonNormal } from '../../StyledComponents';
import CheckboxStyled from '../../FormComponents/CheckboxStyled';
import NotificationModal from './NotificationModal';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { userType } from '../../../constants/index';
import { defaultNotificationSettings, aggregationTypes, DEFAULT_TIMEZONE } from '../../../constants/aggregationEmails';

const Label = styled.div`
  font-size: 16px;
`;
const EmailAggregationForm = () => {
  const saveAggregationType = useRef(null);

  const dispatch = useDispatch();
  const { customerId } = useParams();

  const {
    aggregationSettings, timeZones, isFetching, isTimeZonesFetching,
  } = useSelector(getFiltersStore);
  const { user } = useSelector(getUserStore);

  const isCompany = user.user_type === userType.COMPANY.id;

  const [modals, setModals] = useState({
    inquiriesScheduleModal: false,
    quotesScheduleModal: false,
    confirmationModal: false,
  });
  const [aggregatedEmails, setAggregatedEmails] = useState({
    inquiriesSchedule: {},
    quotesSchedule: {},
  });

  useEffect(() => {
    dispatch(fetchTimeZones());
  }, [dispatch]);

  useEffect(() => {
    if (aggregationSettings) {
      setAggregatedEmails({
        inquiriesSchedule: aggregationSettings.find((v) => v.aggregation_type === aggregationTypes.NEW_SALES_INQURIES),
        quotesSchedule: aggregationSettings.find((v) => v.aggregation_type === aggregationTypes.NEW_QUOTES_AND_MESSAGES),
      });
    }
  }, [aggregationSettings]);

  const onChange = (e) => {
    if (e.target.checked && e.target.name === 'salesNotifications') {
      setModals({ ...modals, inquiriesScheduleModal: true });
    } else if (e.target.checked && e.target.name === 'quotesNotifications') {
      setModals({ ...modals, quotesScheduleModal: true });
    } else {
      saveAggregationType.current = (e.target.name === 'salesNotifications')
        ? aggregationTypes.NEW_SALES_INQURIES
        : aggregationTypes.NEW_QUOTES_AND_MESSAGES;
      setModals({ ...modals, confirmationModal: true });
    }
  };

  const onHideModals = () => {
    saveAggregationType.current = null;
    setModals({
      inquiriesScheduleModal: false,
      quotesScheduleModal: false,
      confirmationModal: false,
    });
  };

  const onSaveHandler = (formData) => {
    onHideModals();
    dispatch(saveAggregationEmailsSettings(customerId, formData));
  };

  const onDisableNotification = () => {
    dispatch(saveAggregationEmailsSettings(customerId, {
      ...defaultNotificationSettings,
      aggregation_type: saveAggregationType.current,
      is_enabled: false,
      time_zone: DEFAULT_TIMEZONE,
    }));
    onHideModals();
  };

  if ((user && user.is_external) || !aggregationSettings || isFetching) return null;

  return (
    <>
      <Form>
        {isCompany && (
          <Form.Group as={Row} controlId="salesNotifications">
            <Col sm={5}>
              <CheckboxStyled
                id="checkbox-inquiries-schedule"
                label={<Label>Notify me of <strong>new sales inquiries</strong> in email digest.</Label>}
                checked={aggregatedEmails.inquiriesSchedule.is_enabled ?? false}
                disabled={false}
                name="salesNotifications"
                onChange={onChange}
              />
            </Col>
            <Col sm={2}>
              <LinkButtonNormal
                variant="link"
                disabled={!aggregatedEmails.inquiriesSchedule.is_enabled}
                onClick={() => setModals({ ...modals, inquiriesScheduleModal: true })}
              >
                <FontAwesomeIcon icon="bell" />
                &nbsp;Schedule email
              </LinkButtonNormal>
            </Col>
          </Form.Group>
        )}

        <Form.Group as={Row} controlId="quotesNotifications">
          <Col sm={5}>
            <CheckboxStyled
              id="checkbox-quote-schedule"
              label={<Label>Notify me of <strong>new quotes &amp; other messages</strong> in email digest.</Label>}
              checked={aggregatedEmails.quotesSchedule.is_enabled ?? false}
              disabled={false}
              name="quotesNotifications"
              onChange={onChange}
            />
          </Col>
          <Col sm={2}>
            <LinkButtonNormal
              variant="link"
              disabled={!aggregatedEmails.quotesSchedule.is_enabled}
              onClick={() => setModals({ ...modals, quotesScheduleModal: true })}
            >
              <FontAwesomeIcon icon="bell" />
              &nbsp;Schedule email
            </LinkButtonNormal>
          </Col>
        </Form.Group>
      </Form>

      <NotificationModal
        aggregationData={aggregatedEmails.inquiriesSchedule}
        timeZones={timeZones}
        isTimeZonesFetching={isTimeZonesFetching}
        modalName="inquiries"
        title="Schedule email digest"
        show={modals.inquiriesScheduleModal}
        onSaveHandler={onSaveHandler}
        onHide={onHideModals}
      />
      <NotificationModal
        aggregationData={aggregatedEmails.quotesSchedule}
        timeZones={timeZones}
        isTimeZonesFetching={isTimeZonesFetching}
        modalName="quotes & other messages"
        title="Schedule email digest"
        show={modals.quotesScheduleModal}
        onSaveHandler={onSaveHandler}
        onHide={onHideModals}
      />

      <ConfirmationModal
        show={modals.confirmationModal}
        confirm={onDisableNotification}
        onHide={onHideModals}
        confirmBtnText="I'm sure"
        cancelBtnText="Cancel"
      >
        <>
          <h3>Are you sure you wish to disable this notification?</h3>
        </>
      </ConfirmationModal>
    </>
  );
};

export default EmailAggregationForm;
