import styled from 'styled-components';
import { Form } from 'react-bootstrap';

const RadioStyled = styled(Form.Check)`

  & input {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  & input[type=radio]::-ms-check {
    display: none;
  }

  &&& input {
    position: relative;
    width: 22px;
    height: 22px;
    vertical-align: -6px;
    border: 2px solid grey;
    border-radius: 50%;
    margin-right: 8px;
    cursor: pointer;
    outline: none;
  }

  input::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: black;
    right: 4px;
    top: 4px;
    visibility: hidden;
    cursor: pointer;
  }

  input:checked::before {
    visibility: visible;
  }
  
  input:disabled {
    border-color: black;
    background: #ddd;
    color: gray;
    opacity: 0.8;
  }
`;

export default RadioStyled;
