import React from 'react';
import {
  Row, Col, FormGroup, FormLabel,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import renderValidation from '../renderHelpBlock';
import CheckboxGroup from '../../CheckboxGroupBasic/index';
import { formOptions } from '../../../constants';

const FormCheckboxGroup = ({
  id,
  validationState,
  labelText,
  helpText,
  feedBack,
  labelColumnWidth,
  controlColumnWidth,
  ...other
}) => (
  <FormGroup as={Row} controlId={id}>
    <FormLabel column sm={labelColumnWidth}>
      {labelText}
    </FormLabel>
    <Col sm={controlColumnWidth}>
      <CheckboxGroup {...other} />
      {renderValidation(helpText, validationState)}
    </Col>
  </FormGroup>
);
const valuePT = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

FormCheckboxGroup.propTypes = {
  id: PropTypes.string,
  validationState: PropTypes.oneOf(['success', 'warning', 'error', null]),
  helpText: PropTypes.string,
  feedBack: PropTypes.bool,
  labelColumnWidth: PropTypes.number,
  controlColumnWidth: PropTypes.number,
  labelText: PropTypes.string,

  // CheckBoxes properties:
  checkList: PropTypes.arrayOf(PropTypes.shape({
    value: valuePT,
    labelText: PropTypes.string,
  })),
  checkedValues: PropTypes.arrayOf(valuePT),
  name: PropTypes.string.isRequired,
};

FormCheckboxGroup.defaultProps = {
  feedBack: true,
  labelColumnWidth: formOptions.labelColumnWidth,
  controlColumnWidth: formOptions.controlColumnWidth,
};

export default FormCheckboxGroup;
