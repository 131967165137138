import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';

import A from '../../A';
import { ButtonContainer, HorizontalLine, FixedWidthContainer } from '../StyleComponents';
import { TabContainer } from '../../StyledComponents';
import FormTitleSection from '../../FormComponents/TitleSection';
import FormRadioGroup from '../UploadInventory/RadioGroup';
import {
  fetchPnList, uploadPnDocument, resetUploadingProgress, errorRemove,
} from '../../../actions/companiesManagement/pnListActions';
import { fetchUserSettings } from '../../../actions/companiesManagement/userSettingsActions';
import { getPnListStore, getUserSettingsStore } from '../../../selectors';
import { dropezoneContent, filledDropezoneContent } from '../UploadInventory/dropezoneContent';
import ProgressModal from '../../Modals/ProgressModal';
import SuccessModal from '../UploadInventory/SuccessModal';
import ErrorModal from '../../ErrorModal';
import Loader from '../../Loader';
import lastPnInfo from './lastPnInfo';
import { color, PUBLIC_URL } from '../../../constants';

const PageContainer = styled.div`
  padding-bottom: 30px;
`;
const WarningMessage = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: ${color.textRed};
  & a {
    text-decoration: underline;
    color: ${color.darkBirch};
  }
  & a:hover {
    color: ${color.birch};
  }
`;

const PNListPage = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();

  const [uploadData, setUploadData] = useState({
    pnFiles: [],
    pnMode: 'merge',
  });

  const userSettings = useSelector(getUserSettingsStore);
  const pnListsEnabled = userSettings.data && userSettings.data.pn_lists_enabled;
  const {
    pnData: propsPnData,
    isFetching,
    isUploading,
    uploadingProgress,
    fetchingErrorCode,
    uploadingErrorCode,
  } = useSelector(getPnListStore);

  useEffect(() => {
    dispatch(fetchUserSettings(userId));
    dispatch(fetchPnList(userId));
  }, [dispatch, userId]);

  const onDocumentDrop = (files) => (
    setUploadData((prev) => ({ ...prev, pnFiles: [...files] }))
  );

  const onUploadClick = () => (
    dispatch(uploadPnDocument(uploadData.pnFiles[0], userId, uploadData.pnMode))
  );

  const onPnModeChanged = (e) => {
    const { name, value } = e.target;
    setUploadData((prevState) => {
      const newData = { ...prevState };
      newData[name] = value;

      return newData;
    });
  };

  const onDocumentRemove = () => (
    setUploadData((prev) => ({ ...prev, pnFiles: [] }))
  );

  const uploadingSuccess = () => {
    dispatch(resetUploadingProgress());
    dispatch(fetchPnList(userId));
    setUploadData({
      pnFiles: [],
      pnMode: 'merge',
    });
  };

  const uploadingFail = () => {
    dispatch(errorRemove());
    uploadingSuccess();
  };

  const showSuccessModal = !isUploading && uploadingProgress === 100;
  const showErrorModal = !!(fetchingErrorCode || uploadingErrorCode);
  const uploadDisabled = !pnListsEnabled || uploadData.pnFiles.length === 0;
  let pnContent = null;

  if (isFetching) return <Loader />;

  if (uploadData.pnFiles.length > 0) {
    pnContent = filledDropezoneContent(uploadData.pnFiles[0], onDocumentRemove);
  } else {
    pnContent = dropezoneContent('Drag file here', '(.xls / .xlsx / .csv)', !pnListsEnabled);
  }

  return (
    <PageContainer>
      <FormTitleSection title="Tier 2 – PN Management" showEditButton={false} />
      <TabContainer>
        {
          !pnListsEnabled
            ? (
              <WarningMessage>
                Uploading is disabled for this company.
                Go to the&nbsp;
                <A rel="noopener noreferrer" to={`${PUBLIC_URL}/companies/${userId}/userSettings`}>
                  Company Settings
                </A>
                &nbsp;to enable this feature.
              </WarningMessage>
            )
            : null
        }
        {propsPnData && lastPnInfo(propsPnData, userId)}
        <FixedWidthContainer maxWidth="380px">
          <FormRadioGroup
            checkedValue={uploadData.pnMode}
            radioList={[{ value: 'merge', labelText: 'Merge' }, { value: 'overwrite', labelText: 'Replace' }]}
            disabled={!pnListsEnabled}
            name="pnMode"
            onChange={onPnModeChanged}
            inline
          />
        </FixedWidthContainer>
        <Dropzone
          onDrop={onDocumentDrop}
          disabled={!pnListsEnabled}
          accept=".xls,.xlsx,.csv"
          onDropRejected={null}
          multiple={false}
          disableClick
        >
          {pnContent}
        </Dropzone>
        <HorizontalLine color={color.darkBlue} />
        <ButtonContainer btnWidth="120px">
          <Button onClick={onUploadClick} variant="primary" disabled={uploadDisabled}>Upload</Button>
        </ButtonContainer>

        <ProgressModal show={isUploading} now={uploadingProgress} />
        <SuccessModal show={showSuccessModal} text="We are currently processing your PN list." onHide={uploadingSuccess} />
        <ErrorModal show={showErrorModal} onHide={uploadingFail} />
      </TabContainer>
    </PageContainer>
  );
};

export default PNListPage;
