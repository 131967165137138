import React from 'react';
import axios from 'axios';
import { CLIENT_URL } from '../../constants';

export default class Version extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
  }

  componentDidMount() {
    const version = `${CLIENT_URL}/version.txt`;
    this.readTextFile(version);
  }

  readTextFile = (file) => {
    axios({
      method: 'get',
      baseURL: '',
      url: file,
    }).then((response) => {
      const rawFile = response.data;
      this.setState({
        text: rawFile,
      });
    }).catch(() => {
      this.setState({
        text: 'Unknown version',
      });
    });
  };

  render() {
    const { text } = this.state;
    return (
      <div>
        {text.split('\n').map((item) => (
          <span key={item.id}>
            {item}
            <br />
          </span>
        ))}
      </div>
    );
  }
}
