import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button, Modal, Form, Row, Col,
} from 'react-bootstrap';

import { ModalTitle, ButtonContainer } from '../../StyledComponents';
import {
  frequencyOptions, foLast, daysOptions, DEFAULT_TIMEZONE, ALL_DAYS,
} from '../../../constants/aggregationEmails';
import { color } from '../../../constants';

const Text = styled.p`
  margin-left: 15px;
`;
const FormGroupContainer = styled(Row)`
  .select_hours__value-container, .select_days__value-container {
    max-height: 32px;
    text-overflow: ellipsis;
    overflow-y: auto;
    margin-right: 5px;

    scrollbar-width: thin;
    ::-webkit-scrollbar-track
    {
      border-radius: 10px;
      background-color: ${color.lightGrey};
    }

    ::-webkit-scrollbar
    {
      width: 4px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      background-color: #808080;
    }
  }
`;

function* generateHours() {
  yield { label: '12:00 AM', value: 0 };
  for (let i = 1; i <= 11; i += 1) {
    yield { label: `${i.toString().padStart(2, '0')}:00 AM`, value: i };
  }
  yield { label: '12:00 PM', value: 12 };
  for (let i = 1; i <= 11; i += 1) {
    yield { label: `${i.toString().padStart(2, '0')}:00 PM`, value: i + 12 };
  }
}

const NotificationModal = ({
  aggregationData,
  timeZones: timeZoneOptions,
  isTimeZonesFetching,
  title,
  onHide,
  modalName,
  onSaveHandler,
  ...other
}) => {
  const [data, setData] = useState({
    frequency: frequencyOptions[2],
    days: [],
    hours: [],
    timeZone: null,
  });

  const hoursOptions = useMemo(
    () => [...generateHours()],
    [],
  );

  useEffect(() => {
    if (aggregationData && timeZoneOptions) {
      let days = [daysOptions[0]];
      if (aggregationData.sending_days?.length !== 7) {
        days = aggregationData.sending_days?.map((v) => daysOptions.find((el) => v === el.value));
      }

      setData({
        days,
        frequency: frequencyOptions.find((v) => v.value === aggregationData.aggregation_frequency) ?? frequencyOptions[2],
        hours: aggregationData.sending_hours?.map((v) => hoursOptions.find((el) => v === el.value)) ?? [hoursOptions[0]],
        timeZone: timeZoneOptions.find((v) => v.value === aggregationData.sending_timezone),
      });
    }
  }, [aggregationData, hoursOptions, timeZoneOptions]);

  const onSelectChange = (selectedOption, action) => {
    if ((action.name === 'hours' || action.name === 'days')
      && (!selectedOption || selectedOption.length === 0)) {
      return;
    }

    if (action.name === 'days' && selectedOption.some((v) => v.value === ALL_DAYS) && data.days.every((v) => v.value !== ALL_DAYS)) {
      setData({ ...data, days: [daysOptions[0]] });
    } else if (action.name === 'days') {
      setData({ ...data, days: selectedOption.filter((v) => v.value !== ALL_DAYS) });
    } else if (action.name === 'frequency') {
      setData({
        ...data,
        frequency: selectedOption,
        hours: [hoursOptions[5]],
        timeZone: timeZoneOptions.find((v) => v.value === DEFAULT_TIMEZONE),
      });
    } else {
      setData({ ...data, [action.name]: selectedOption });
    }
  };

  const onSaveClickHandler = () => {
    const sendData = {
      is_enabled: true,
      aggregation_type: aggregationData.aggregation_type,
      aggregation_frequency: data.frequency.value,
      sending_days: data.days?.some((v) => v.value === ALL_DAYS)
        ? daysOptions.filter((v) => v.value !== ALL_DAYS).map((v) => v.value)
        : data.days.map((v) => v.value),
      sending_hours: [],
      time_zone: aggregationData.sending_timezone,
    };

    if (data.frequency.value === frequencyOptions[foLast].value) {
      onSaveHandler({
        ...sendData,
        time_zone: data.frequency.value === frequencyOptions[foLast].value ? data.timeZone.value : aggregationData.sending_timezone,
        sending_hours: data.frequency.value === frequencyOptions[foLast].value ? data.hours.map((v) => v.value) : null,
      });
    } else {
      onSaveHandler(sendData);
    }
  };

  const onCancelHandler = () => {
    onHide();
    let days = [daysOptions[0]];
    if (aggregationData.sending_days?.length !== 7) {
      days = aggregationData.sending_days?.map((v) => daysOptions.find((el) => v === el.value));
    }
    setData({
      days,
      frequency: frequencyOptions.find((v) => v.value === aggregationData.aggregation_frequency) ?? frequencyOptions[2],
      hours: aggregationData.sending_hours?.map((v) => hoursOptions.find((el) => v === el.value)) ?? [hoursOptions[0]],
      timeZone: timeZoneOptions.find((v) => v.value === aggregationData.sending_timezone),
    });
  };

  return (
    <Modal centered onHide={onCancelHandler} {...other} dialogClassName="aggregation_email_modal">
      <Modal.Header>
        <ModalTitle>{title}</ModalTitle>
      </Modal.Header>

      <Modal.Body>
        <Text>
          Schedule your email digest for&nbsp;
          <strong>{`new ${modalName}`}</strong>
          &nbsp;notifications:
        </Text>
        <br />
        <Form>
          <Form.Group as={FormGroupContainer}>
            <Form.Label column sm="2">
              Email user
            </Form.Label>
            <Col sm={5}>
              <Select
                value={data.frequency}
                name="frequency"
                onChange={onSelectChange}
                options={frequencyOptions}
                hideSelectedOptions={false}
                isSearchable={false}
              />
            </Col>
            <Col sm={5}>
              <Select
                defaultValue={[daysOptions[0]]}
                value={data.days}
                name="days"
                onChange={onSelectChange}
                options={daysOptions}
                hideSelectedOptions={false}
                isMulti
                closeMenuOnSelect={false}
                isClearable={false}
                isSearchable={false}
                classNamePrefix="select_days"
              />
            </Col>
          </Form.Group>

          {data.frequency.value === frequencyOptions[foLast].value && (
            <Form.Group as={FormGroupContainer}>
              <Col sm={{ span: 5, offset: 2 }}>
                <Select
                  defaultValue={[hoursOptions[6]]}
                  value={data.hours}
                  name="hours"
                  onChange={onSelectChange}
                  options={hoursOptions}
                  hideSelectedOptions={false}
                  isMulti
                  closeMenuOnSelect={false}
                  isClearable={false}
                  isSearchable={false}
                  classNamePrefix="select_hours"
                />
              </Col>
              <Col sm={5}>
                <Select
                  value={data.timeZone}
                  name="timeZone"
                  onChange={onSelectChange}
                  defaultValue={timeZoneOptions.find((v) => v.value === DEFAULT_TIMEZONE)}
                  options={timeZoneOptions}
                  isLoading={isTimeZonesFetching}
                  hideSelectedOptions={false}
                  isSearchable
                />
              </Col>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <ButtonContainer btnWidth="120px" space="10px">
          <Button variant="primary" onClick={onSaveClickHandler}>Save</Button>
          <Button variant="outline-secondary" onClick={onCancelHandler}>Cancel</Button>
        </ButtonContainer>
      </Modal.Footer>
    </Modal>
  );
};

NotificationModal.propTypes = {
  aggregationData: PropTypes.shape({
    aggregation_type: PropTypes.number,
    aggregation_frequency: PropTypes.number,
    sending_days: PropTypes.arrayOf(PropTypes.number),
    sending_hours: PropTypes.arrayOf(PropTypes.number),
    sending_timezone: PropTypes.string,
  }),
  timeZones: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  isTimeZonesFetching: PropTypes.bool,
  title: PropTypes.string,
  modalName: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onSaveHandler: PropTypes.func.isRequired,
};

export default NotificationModal;
