import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { resetUploadState } from '../../../actions/fileUploaderActions';
import {
  saveBuiltInQuote, editBuiltInQuote, uploadTagFile, removeTagFile,
} from '../../../actions/networkOperationsActions/builtInQuoteActions';
import { getChatStore, getFileUploaderStore } from '../../../selectors';
import { getQuoteOptions, getChatPartNumber } from '../../../selectors/rfq';

import FormContent from './FormContent';
import FormActionButtons from '../../FormComponents/ActionButtons';
import FormRadioGroup from '../../FormComponents/RadioGroup';

import BuiltInQuoteValidators from './BuiltInQuoteValidators';
import { OTHER_SELECT_PICKUP_KEY, BOT_SERVICE_MSG } from '../../../constants';
import { Title } from '../StyledComponents';
import {
  creatorTypes, quoteTypes, leadTimeUnits, chatMsgType, builtInQuoteOptions, chatAtchRfqRole, tagType as tagTypes,
} from '../../../constants/index';
import { getTotalPrice, getPricePerUnit } from '../utils';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
export const WarningMessage = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: #a94442;
`;

const formatTime = (time, units) => {
  let convertedTime = '';
  if (units === leadTimeUnits.HOURS.id) {
    convertedTime = time;
  } else if (units === leadTimeUnits.DAYS.id) {
    convertedTime = Math.floor(time / 24);
  }
  return convertedTime.toString();
};

const timeConverter = (time, units) => {
  let convertedTime = null;
  if (units === leadTimeUnits.HOURS.id) {
    convertedTime = time;
  } else if (units === leadTimeUnits.DAYS.id) {
    convertedTime = time * 24;
  }
  return (time && convertedTime) || null;
};

function getFormData(quotedMsgData, selectOptions, defaultPn) {
  const repairLeadTime = quotedMsgData?.quote?.repair_lead_time;
  const repairPrice = quotedMsgData?.quote?.repair_price;
  const condition = quotedMsgData?.quote
    && selectOptions?.conditionOptions?.find((v) => v.value === quotedMsgData.quote.condition);
  const pickup = quotedMsgData?.quote?.pickup_site
  && {
    value: quotedMsgData?.quote.pickup_site.site_id || '',
    label: `${quotedMsgData?.quote.pickup_site.name} - ${quotedMsgData?.quote.pickup_site.location}`,
  };
  const timeUnits = (quotedMsgData?.quote?.lead_time_units
  && {
    label: Object.values(leadTimeUnits).find((v) => v.id === quotedMsgData?.quote.lead_time_units).name,
    value: quotedMsgData?.quote.lead_time_units,
  }) || selectOptions.timeOptions[1];

  const tagType = quotedMsgData?.quote?.tag_type === tagTypes.CUSTOM.id
    ? { value: quotedMsgData.quote.tag_name, label: quotedMsgData.quote.tag_name }
    : selectOptions?.tagOptions?.find((v) => v.value === quotedMsgData?.quote?.tag_type);
  const traceTo = (quotedMsgData?.quote?.trace_to
      && (selectOptions?.traceToOptions?.find((v) => v.value === quotedMsgData.quote.trace_to)
      || { label: quotedMsgData?.quote?.trace_to, value: quotedMsgData?.quote?.trace_to }))
      || null;

  return {
    quote_type: selectOptions?.builtInQuoteOptions[0]?.value,

    part_number: quotedMsgData?.quote?.part_number || defaultPn || '',
    is_alt_pn: quotedMsgData?.quote?.is_alt_pn || false,
    description: quotedMsgData?.quote?.description || '',
    tag_type: tagType,
    tagged_by: quotedMsgData?.quote?.tagged_by || '',
    tag_date: quotedMsgData?.quote?.tag_date || '',
    trace_to: traceTo,
    minimum_order_value: quotedMsgData?.quote?.minimum_order_value || '',

    lead_time_units: timeUnits,
    currency: (quotedMsgData?.quote?.currency && {
      label: quotedMsgData.quote.currency,
      value: quotedMsgData.quote.currency,
    }) || selectOptions.supportedCurrenciesOptions[0],
    qty: quotedMsgData?.quote?.qty || '1',
    qty_units: (quotedMsgData?.quote?.qty_units && {
      label: quotedMsgData?.quote.qty_units,
      value: quotedMsgData?.quote.qty_units,
    }) || selectOptions.unitsOptions[0],
    condition: condition || null,
    pickup_site: pickup || null,
    lead_time: (quotedMsgData?.quote?.lead_time
      && formatTime(quotedMsgData.quote.lead_time, quotedMsgData.quote.lead_time_units).toString()) || '',
    price: quotedMsgData?.quote?.price || '',
    taxes: quotedMsgData?.quote?.taxes || '',
    total_price: quotedMsgData?.quote?.total_price || '',
    warranty: quotedMsgData?.quote?.warranty || '',

    fee: quotedMsgData?.quote?.fee || '',
    outright_price: quotedMsgData?.quote?.outright_price || '',
    period: quotedMsgData?.quote?.period || '',
    place: quotedMsgData?.quote?.pickup_site?.place_id
      ? quotedMsgData?.quote.pickup_site
      : '',
    min_order: quotedMsgData?.quote?.min_order || '',

    evaluation_price: repairPrice?.evaluation ?? '',
    test_price: repairPrice?.test || '',
    repair_price: repairPrice?.repair || '',
    overhaul_price: repairPrice?.overhaul || '',

    evaluation_time: (repairLeadTime?.evaluation
      && formatTime(repairLeadTime.evaluation, quotedMsgData?.quote.lead_time_units).toString()) || '',
    test_time: (repairLeadTime?.test
      && formatTime(repairLeadTime.test, quotedMsgData?.quote.lead_time_units).toString()) || '',
    repair_time: (repairLeadTime?.repair
      && formatTime(repairLeadTime.repair, quotedMsgData?.quote.lead_time_units).toString()) || '',
    overhaul_time: (repairLeadTime?.overhaul
      && formatTime(repairLeadTime.overhaul, quotedMsgData?.quote.lead_time_units).toString()) || '',

    comment: quotedMsgData?.quote && quotedMsgData?.text ? quotedMsgData.text : '',
  };
}

export default function BuiltInQuoteForm({ orderId, messageId, ...props }) {
  const deletedAttachRef = useRef(null);
  const dispatch = useDispatch();

  const [formQuoteType, setFormQuoteType] = useState(quoteTypes.QUOTE_BUY);
  const [tagFile, setTagFile] = useState(null);
  const [validation, setValidation] = useState({});
  const [isChanged, setChanged] = useState(false);

  const { files, isUploading } = useSelector(getFileUploaderStore);
  const { attachments: attch } = useSelector(getChatStore);
  const selectOptions = useSelector(getQuoteOptions);
  // Prefilled part number
  const chatPartNumber = useSelector(getChatPartNumber);
  const { messages } = useSelector(getChatStore);

  const quotedMsgData = messages?.find((v) => v.message_id === messageId);
  const editMode = quotedMsgData?.creator_type === creatorTypes.BOT && quotedMsgData.message_type === chatMsgType.QUOTE;
  const messageQuoteType = quotedMsgData?.quote?.quote_type;

  const [formData, setFormData] = useState(() => getFormData(quotedMsgData, selectOptions, chatPartNumber));

  useEffect(() => setTagFile(files), [files]);

  useEffect(() => {
    if (editMode) {
      setTagFile(attch?.filter((att) => (
        att.message_id === messageId
        && att.rfq_role === chatAtchRfqRole.TAG
        && att.deleted_at === null
      )));
    }
  }, [messageId, attch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (messageQuoteType) setFormQuoteType(messageQuoteType);
  }, [messageQuoteType]);

  useEffect(() => {
    setFormData(getFormData(quotedMsgData, selectOptions, chatPartNumber));
    return () => dispatch(resetUploadState());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageId, quotedMsgData, selectOptions, formQuoteType, dispatch]);

  const onChange = (e) => {
    const { value, name, checked } = e.target;

    setFormData((prevState) => {
      const itemValidation = {};
      let calcTotalPrice = prevState.total_price;
      let calcPricePerUnit = prevState.price;

      if (name === 'is_alt_pn') {
        itemValidation.part_number = BuiltInQuoteValidators.part_number(checked ? '' : chatPartNumber);
        setValidation(itemValidation);
        return {
          ...prevState,
          part_number: checked ? '' : chatPartNumber,
          [name]: checked,
        };
      }

      if (name === 'price') {
        calcTotalPrice = getTotalPrice(value, formData.qty, formData.taxes);
        itemValidation.total_price = BuiltInQuoteValidators.total_price(calcTotalPrice);
      } else if (name === 'taxes') {
        calcTotalPrice = getTotalPrice(formData.price, formData.qty, value);
        itemValidation.total_price = BuiltInQuoteValidators.total_price(calcTotalPrice);
      } else if (name === 'total_price') {
        calcPricePerUnit = getPricePerUnit(value, formData.qty, formData.taxes);
        itemValidation.price = BuiltInQuoteValidators.price(calcPricePerUnit);
      }

      if (name === 'qty') {
        calcTotalPrice = getTotalPrice(formData.price, value, formData.taxes);
        itemValidation.total_price = BuiltInQuoteValidators.total_price(calcTotalPrice);
        itemValidation[name] = BuiltInQuoteValidators[name](value, formData.qty_units.value);
      } else {
        itemValidation[name] = BuiltInQuoteValidators[name](value);
      }

      setValidation((prState) => ({ ...prState, ...itemValidation }));
      return {
        ...prevState,
        total_price: calcTotalPrice,
        price: calcPricePerUnit,
        [name]: value,
      };
    });
    setChanged(true);
  };

  const onSelectChange = (selectedOption, action) => {
    if (selectedOption !== OTHER_SELECT_PICKUP_KEY && action.name === 'pickup_site') {
      const selectedPlace = '';
      setValidation((prev) => ({
        ...prev,
        place: BuiltInQuoteValidators.place(selectedPlace),
      }));
      setFormData((prevState) => ({
        ...prevState,
        [action.name]: selectedOption,
        place: selectedPlace,
      }));
    } else if (action.name === 'qty_units') {
      setValidation((prevState) => ({
        ...prevState,
        qty: BuiltInQuoteValidators.qty(formData.qty, selectedOption.value),
      }));
      setFormData((prevState) => ({ ...prevState, [action.name]: selectedOption }));
    } else {
      setFormData((prev) => ({ ...prev, [action.name]: selectedOption }));
    }
    setChanged(true);
  };

  const onSelectGooglePlace = (selectedPlace) => {
    setValidation((prevState) => ({ ...prevState, place: BuiltInQuoteValidators.place(selectedPlace) }));
    setFormData((prevState) => ({ ...prevState, place: selectedPlace }));
    setChanged(true);
  };

  const onBlurAutoSuggest = (selectedPlace) => {
    setValidation((prevState) => ({ ...prevState, place: BuiltInQuoteValidators.place(selectedPlace) }));
    setFormData((prevState) => ({ ...prevState, place: selectedPlace }));
    setChanged(true);
  };

  const onSendClick = () => {
    const newValidation = {};
    // eslint-disable-next-line no-return-assign
    Object.keys(BuiltInQuoteValidators).forEach((v) => newValidation[v] = BuiltInQuoteValidators[v](formData[v]));
    newValidation.qty = BuiltInQuoteValidators.qty(formData.qty, formData.qty_units.value);

    if (formData.pickup_site && formData.pickup_site.value === OTHER_SELECT_PICKUP_KEY && !formData.place) {
      newValidation.place = BuiltInQuoteValidators.place(null);
    }

    if (Object.values(newValidation).some((v) => !!v)) {
      setValidation(newValidation);
      return;
    }

    const message = formSendData();

    let tagType = null;
    if (formData.tag_type && selectOptions.tagOptions.every((t) => t.value !== formData.tag_type.value)) {
      tagType = tagTypes.CUSTOM.id;
    } else if (formData.tag_type) {
      tagType = formData.tag_type.value;
    }
    const attachments = tagFile && tagFile[0] && !tagFile[0].attachment_id
      ? [{
        upload_id: files[0].upload_id,
        name: files[0].name,
        comment: null,
        rfq_role: chatAtchRfqRole.TAG,
        tag_type: tagType,
        tag_name: tagType === tagTypes.CUSTOM.id ? formData.tag_type.value : null,
      }]
      : null;

    if (editMode) {
      const isSiteChanged = (
        (quotedMsgData.pickup_site
          && ((quotedMsgData.pickup_site.place_id && (message.quote.place_id !== quotedMsgData.pickup_site.place_id))
            || (quotedMsgData.pickup_site.site_id && (message.quote.pickup_site_id !== quotedMsgData.pickup_site.site_id))))
        || (message.quote.place_id && !quotedMsgData.pickup_site)
        || (message.quote.pickup_site_id && !quotedMsgData.pickup_site));

      const isDiffer = Object.keys(message.quote).some((v) => {
        if (typeof message.quote[v] === 'object' && message.quote[v] !== null) {
          return Object.keys(message.quote[v]).some((v1) => message.quote[v][v1] !== quotedMsgData.quote[v][v1]);
        }
        return (quotedMsgData.quote[v] !== undefined && message.quote[v] !== quotedMsgData.quote[v]) || isSiteChanged;
      }) || (quotedMsgData.text !== message.text);

      if (!isDiffer && tagFile !== files) {
        setChanged(false);
        return;
      }

      if (deletedAttachRef.current) dispatch(removeTagFile(orderId, deletedAttachRef.current));
      dispatch(editBuiltInQuote(message, attachments, orderId, messageId));
    } else {
      if (Object.values(message.quote).every((v) => !v)) return;
      dispatch(saveBuiltInQuote(message, attachments, orderId));
    }

    props.cancelHandler();
  };

  const formSendData = () => {
    const quoteType = editMode ? messageQuoteType : formQuoteType;
    let details;

    if (quoteType === quoteTypes.QUOTE_REPAIR) {
      details = {
        currency: formData.currency.value || null,
        qty: formData.qty || '1',
        qty_units: formData.qty_units.value || null,
        lead_time_units: ((formData.evaluation_time || formData.test_time || formData.repair_time || formData.overhaul_time)
          && formData.lead_time_units.value) || quotedMsgData?.lead_time_units || null,
        pickup_site_id: formData.pickup_site && formData.pickup_site?.value !== OTHER_SELECT_PICKUP_KEY && !formData.place
          ? formData.pickup_site.value
          : null,
        place_id: formData.place?.place_id || null,
        repair_price: {
          evaluation: formData.evaluation_price || null,
          test: formData.test_price || null,
          repair: formData.repair_price || null,
          overhaul: formData.overhaul_price || null,
        },
        repair_lead_time: {
          evaluation: timeConverter(formData.evaluation_time, formData.lead_time_units.value),
          test: timeConverter(formData.test_time, formData.lead_time_units.value),
          repair: timeConverter(formData.repair_time, formData.lead_time_units.value),
          overhaul: timeConverter(formData.overhaul_time, formData.lead_time_units.value),
        },
        warranty: +formData.warranty || null,
      };
    } else if (quoteType === quoteTypes.QUOTE_BUY || quoteType === quoteTypes.QUOTE_LOAN || quoteType === quoteTypes.QUOTE_EXCHANGE) {
      let tagType = null;
      if (formData.tag_type && selectOptions.tagOptions.every((t) => t.value !== formData.tag_type.value)) {
        tagType = tagTypes.CUSTOM.id;
      } else if (formData.tag_type) {
        tagType = formData.tag_type.value;
      }

      details = {
        part_number: formData.part_number || null,
        is_alt_pn: formData.is_alt_pn || false,
        tag_type: tagType,
        tag_name: tagType === tagTypes.CUSTOM.id ? formData.tag_type.value : null,
        tagged_by: formData.tagged_by || null,
        tag_date: formData.tag_date?.toString() || null,
        trace_to: formData.trace_to?.value || null,
        description: formData.description || null,
        minimum_order_value: formData.minimum_order_value || null,

        currency: formData.currency.value || null,
        qty: formData.qty || '1',
        qty_units: formData.qty_units.value || null,
        condition: formData.condition?.value || null,
        pickup_site_id: formData.pickup_site && formData.pickup_site?.value !== OTHER_SELECT_PICKUP_KEY && !formData.place
          ? formData.pickup_site.value
          : null,
        place_id: formData.place?.place_id || null,
        lead_time: timeConverter(formData.lead_time, formData.lead_time_units.value),
        lead_time_units: formData?.lead_time_units.value || quotedMsgData?.lead_time_units || null,
        warranty: +formData.warranty || null,
      };

      if (quoteType === quoteTypes.QUOTE_BUY) {
        details = {
          ...details,
          price: formData.price?.toString() || null,
          taxes: formData.taxes?.toString() || null,
          total_price: formData.total_price?.toString() || null,
        };
      } else if (quoteType === quoteTypes.QUOTE_LOAN || quoteType === quoteTypes.QUOTE_EXCHANGE) {
        details = {
          ...details,
          fee: formData.fee || null,
          outright_price: formData.outright_price || null,
          period: formData.period || null,
        };
      }
    }

    return {
      quote: { ...details, no_quote: false, quote_type: quoteType },
      cited_message_id: (messageId === BOT_SERVICE_MSG) ? null : messageId,
      text: formData.comment?.toString() || '',
    };
  };

  const checkNotEmptyState = (state) => state.qty === null || state.qty === '';

  const onFileAdd = (e) => {
    dispatch(uploadTagFile(e.target.files[0]));
    setTagFile(null);
    setChanged(true);
  };

  const onFileRemove = () => {
    deletedAttachRef.current = tagFile[0].attachment_id;
    setTagFile(null);
    setChanged(true);
  };

  const type = editMode ? messageQuoteType : formQuoteType;
  const saveButtonProps = {
    disabled: checkNotEmptyState(formData) || (editMode && !isChanged) || isUploading
      || ((files?.length > 0 || tagFile?.length > 0) && !formData.tag_type),
  };

  const onTagDateChange = (date) => {
    setFormData((prev) => ({ ...prev, tag_date: date }));
    setChanged(true);
  };

  return (
    <>
      <Title>Provide Quote</Title>
      <div>Select a built-in quote type:</div>
      <FormRadioGroup
        checkedValue={formQuoteType}
        radioList={builtInQuoteOptions}
        onChange={(e) => setFormQuoteType(e.target.value)}
        name="quote_type"
        labelColumnWidth={0}
        controlColumnWidth={12}
        inline
        disabled={editMode}
      />

      <div>Please fill in the following details for the requested parts</div>
      <br />
      <Form>
        <FormContent
          tagFile={tagFile && tagFile[0]}
          type={type}
          data={formData}
          validation={validation}
          selectOptions={selectOptions}
          onChange={onChange}
          onFileAdd={onFileAdd}
          onFileRemove={onFileRemove}
          onSelectGooglePlace={onSelectGooglePlace}
          onBlurAutoSuggest={onBlurAutoSuggest}
          onSelectChange={onSelectChange}
          onTagDateChange={onTagDateChange}
        />
      </Form>
      {editMode && !isChanged && <WarningMessage>You cannot send unchanged data</WarningMessage>}
      {(files?.length > 0 || tagFile?.length > 0) && !formData.tag_type && <WarningMessage>Select or type a tag</WarningMessage>}

      <ButtonContainer>
        <FormActionButtons
          saveHandler={onSendClick}
          cancelHandler={() => props.cancelHandler()}
          confirmButtonText={editMode ? 'Update' : 'Send'}
          saveProps={saveButtonProps}
          width="100"
          showLoader={isUploading}
        />
      </ButtonContainer>
    </>
  );
}

BuiltInQuoteForm.propTypes = {
  orderId: PropTypes.string,
  messageId: PropTypes.string,
  cancelHandler: PropTypes.func.isRequired,
};
