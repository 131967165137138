import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getChatStore } from '../../../selectors';
import { getChatDocuments } from '../../../reducers/orderManagement/chatSelectors';
import { color } from '../../../constants';
import DocFolder from './DocFolder';
import * as constants from '../../../constants/index';

export const DocSectionContainer = styled.div`
  & > div:last-child {
    border-bottom: none;
  }

  position: sticky;
`;
export const Section = styled.div`
  padding-top: 16px;
  padding-bottom: 15px;
  border-bottom: 2px solid #e1e1e1;
`;
export const Title = styled.h3`
  text-transform: uppercase;
  color: ${color.blue};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin: 0;
  margin-bottom: 1px;
  padding-bottom: 8px;
  border-bottom: 3px solid ${color.blue};
  line-height: 1;
`;
const SubTitle = styled.h4`
  margin: 0 0 15px;
  color: ${color.blue};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.4px;
  line-height: 1;
  overflow-wrap: break-word;
`;

const DocSection = ({
  documents,
  orderType,

  buyerCompanyName,
  buyerName,

  sellerCompanyName,
}) => {
  if (!documents) return null;

  const { chatAtchRfqRole, orderDealTypes } = constants;
  const { buyer, seller, eplane } = documents;
  const tagsTitle = orderType === orderDealTypes.REPAIR.id ? 'Tags to be provided ' : 'Item\'s tags ';

  const { singleTags, tagsData } = createTagData(seller.originalTags, seller[chatAtchRfqRole.TAG]);

  return (
    <DocSectionContainer>
      <Title>Related Documents</Title>

      <Section>
        <SubTitle>{`Uploads by ${sellerCompanyName} (seller)`}</SubTitle>

        <DocFolder title={tagsTitle} tagsData={tagsData} singleTags={singleTags} />
        <DocFolder title="Quotes " documents={seller[chatAtchRfqRole.QUOTE]} />
        <DocFolder title="Invoices " documents={seller[chatAtchRfqRole.INVOICE]} />
        <DocFolder title="General docs " documents={seller[chatAtchRfqRole.GENERAL]} />
      </Section>

      <Section>
        <SubTitle>{`Uploads by ${buyerCompanyName || buyerName} (buyer)`}</SubTitle>

        <DocFolder title="POs " documents={buyer[chatAtchRfqRole.PO]} />
        <DocFolder title="General docs " documents={buyer[chatAtchRfqRole.GENERAL]} />
      </Section>

      <Section>
        <SubTitle>Uploaded by ePlane</SubTitle>

        <DocFolder title="PO " documents={eplane[chatAtchRfqRole.PO]} />
        <DocFolder title={tagsTitle} tagsData={eplane[chatAtchRfqRole.TAG]} singleTags={null} />
        <DocFolder title="Quote " documents={eplane[chatAtchRfqRole.QUOTE]} />
        <DocFolder title="Invoice " documents={eplane[chatAtchRfqRole.INVOICE]} />
        <DocFolder title="Other " documents={eplane[chatAtchRfqRole.GENERAL]} />
      </Section>
    </DocSectionContainer>
  );
};

DocSection.propTypes = {
  documents: PropTypes.shape({
    buyer: PropTypes.shape({}),
    seller: PropTypes.shape({
      originalTags: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.object,
      ]),
    }),
    eplane: PropTypes.shape({}),
  }),
  orderType: PropTypes.number,

  buyerCompanyName: PropTypes.string,
  buyerName: PropTypes.string,

  sellerCompanyName: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { order, attachments, messages } = getChatStore(state);
  const { buyer, seller } = order || {};

  return {
    documents: getChatDocuments(attachments, order, messages),

    orderType: order && order.deal_type_list && order.deal_type_list[0],

    buyerCompanyName: buyer && buyer.company_name,
    buyerName: buyer && buyer.contact_name,

    sellerCompanyName: seller && seller.company_name,
  };
};

export default connect(mapStateToProps)(DocSection);

const createTagData = (originalTags, attachedTags) => {
  let singleTags = null;
  let tagsData;

  if (Array.isArray(originalTags)) {
    singleTags = originalTags;
    tagsData = attachedTags;
  } else if (originalTags) {
    tagsData = { ...originalTags };

    Object.keys(tagsData).forEach((tagName) => {
      tagsData[tagName] = tagsData[tagName].map((tagDoc) => ({ ...tagDoc, name: tagDoc.name && tagDoc.name.concat(' (original)') }));
    });

    Object.keys(attachedTags || {}).forEach((tagName) => {
      if (tagsData[tagName]) tagsData[tagName] = tagsData[tagName].concat(attachedTags[tagName]);
      else tagsData[tagName] = attachedTags[tagName];
    });
  } else {
    tagsData = attachedTags;
  }

  return { singleTags, tagsData };
};
