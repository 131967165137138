import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchUserSites } from '../../../actions/userCompanyManagement/sitesActions';
import {
  getSftpUserStore, getUserRepositoryStore, getUserSitesStore, getUserSettingsStore,
} from '../../../selectors';
import {
  fetchUserDailyStatus, fetchUserRepositoryStats, userRepositoryUnpublish,
} from '../../../actions/companiesManagement/userRepositoryActions';
import { fetchSftpUser, saveSftpUser, removeSftpUserErrorCode } from '../../../actions/companiesManagement/sftpUserActions';
import { fetchUserSettings } from '../../../actions/companiesManagement/userSettingsActions';
import InventoryStatisticsManagement from './InventoryStatisticsManagment';
import PickupSitesTable from './PickupSitesTable';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';
import InternalErrorContainer from '../../InternalErrorContainer';
import { PICKUP_SITE } from '../../../constants';
import SFTPManagement from './SFTPManagement';
import UploadSubsection from './UploadSubsection';
import { TabContainer } from '../../StyledComponents';
import ErrorModal from '../../ErrorModal';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import UserDailyStatus from './UserDailyStatus';
import FormTitleSection from '../../FormComponents/TitleSection/index';
import ERPSystem from './ERPSystem';

export default function CompanyInventoriesPage() {
  const [showConfirmationModal, setConfirmationModal] = useState(false);

  const { userId } = useParams();
  const dispatch = useDispatch();

  const sitesData = useSelector(getUserSitesStore);
  const sftpUser = useSelector(getSftpUserStore);
  const userSettings = useSelector(getUserSettingsStore);
  const {
    dailyStatus,
    inventoryStatistics,
    isUnpublishing,
    isFetchingStats,
    isFetchingDaily,
    unpublishErrorCode,
    fetchingErrorCode,
    dailyErrorCode,
  } = useSelector(getUserRepositoryStore);

  const {
    sites, currSiteId, newSiteType,
    isFetching: siteIsFetching,
  } = sitesData;
  const {
    sftpUserData,
    isFetching: sftpUserIsFetching,
    sftpSavingError,
  } = sftpUser;
  const isUserExists = sftpUserData && sftpUserData.is_user_exist;
  const pickupSites = sites ? sites.filter((site) => site.site_type === PICKUP_SITE) : [];
  const currSiteData = currSiteId && sites?.find((site) => site.site_id === currSiteId);
  const listOfSiteNames = getListOfSiteNames(sites, newSiteType || (currSiteData && currSiteData.site_type));
  const erpSystem = userSettings?.data?.erp ? userSettings.data.erp : '';

  useEffect(() => {
    batch(() => {
      dispatch(fetchUserDailyStatus(userId));
      dispatch(fetchUserSites(userId));
      dispatch(fetchUserRepositoryStats(userId));
      dispatch(fetchSftpUser(userId));
      dispatch(fetchUserSettings(userId));
    });
  }, [dispatch, userId]);

  const onUnpublishInventory = () => {
    dispatch(userRepositoryUnpublish(userId));
    hideUnpublishModal();
  };

  const onUnpublishHandler = () => setConfirmationModal(true);

  const hideUnpublishModal = () => setConfirmationModal(false);

  if (unpublishErrorCode || fetchingErrorCode) {
    return (
      <InternalErrorContainer>
        Oops, something went wrong...
        <br />
      </InternalErrorContainer>
    );
  }

  if (isFetchingStats || siteIsFetching || sftpUserIsFetching) return <Loader />;

  return (
    <div>
      <FormTitleSection title="Inventory and Repair Capabilities" showEditButton={false} />
      <TabContainer>
        <UserDailyStatus dailyData={dailyStatus} errorCode={dailyErrorCode} isLoading={isFetchingDaily} />
        <InventoryStatisticsManagement
          inventoryStatistics={inventoryStatistics}
          userRepositoryUnpublish={onUnpublishHandler}
        />
        <UploadSubsection userId={userId} />
        <SFTPManagement
          sftpUser={sftpUser}
          saveSftpUser={(...params) => dispatch(saveSftpUser(...params))}
        />
        <PickupSitesTable
          userId={userId}
          sitesData={sitesData}
          currSiteData={currSiteData}
          pickupSites={pickupSites}
          listOfSiteNames={listOfSiteNames}
          isUserExists={isUserExists}
        />
        <ERPSystem data={erpSystem} userId={userId} />

        <ModalLoader show={isUnpublishing} />
        <ConfirmationModal
          show={showConfirmationModal}
          confirmBtnText="Unpublish"
          cancelBtnText="Cancel"
          confirm={onUnpublishInventory}
          onHide={hideUnpublishModal}
        >
          Are you sure you wish to unpublish all the inventory?
        </ConfirmationModal>
        <ErrorModal
          error={sftpSavingError}
          onHide={(...params) => dispatch(removeSftpUserErrorCode(...params))}
        />
      </TabContainer>
    </div>
  );
}

const getListOfSiteNames = (sites, siteType) => {
  if (!sites || !siteType) return [];
  return sites.filter((s) => s.site_type === siteType).map((s) => s.name);
};
