import {
  PRO_PLAN_PRICE_GET,
  PRO_PLAN_PRICE_GET_SUCCESS,

  PRO_PLAN_PRICE_SAVE,
  PRO_PLAN_PRICE_SAVE_SUCCESS,

  PRO_PLAN_PRICE_FAIL,
  PRO_PLAN_PRICE_RESET,
} from '../../constants';

const initialState = {
  price: null,
  isFetching: false,
  isProcessing: false,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case PRO_PLAN_PRICE_GET:
      return {
        ...state,
        isFetching: true,
      };
    case PRO_PLAN_PRICE_GET_SUCCESS:
      return {
        ...state,
        price: payload,
        isFetching: false,
      };

    case PRO_PLAN_PRICE_SAVE:
      return {
        ...state,
        isProcessing: true,
      };
    case PRO_PLAN_PRICE_SAVE_SUCCESS:
      return {
        ...state,
        price: payload,
        isProcessing: false,
      };

    case PRO_PLAN_PRICE_FAIL:
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
      };
    case PRO_PLAN_PRICE_RESET:
      return {
        ...initialState,
      };
    default: return state;
  }
};
