import React from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination } from 'react-table';

import Pagination from './Pagination';
import NoDataRow from './NoData';

function ReactTable({
  columns,
  data,
  noDataContent = 'There is no data to display',
  onRowClick,
  options,
  ...props
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    visibleColumns,
    prepareRow,

    gotoPage,
    setPageSize,
    pageCount,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: options.page - 1,
        pageSize: options.sizePerPage,
      },
    },
    usePagination,
  );

  const tbodyContent = data.length !== 0
    ? (
      <tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} onClick={() => onRowClick(row.original)}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    )
    : <NoDataRow colSpan={visibleColumns.length}>{noDataContent}</NoDataRow>;

  return (
    <>
      <table {...getTableProps()} {...props}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  style={column.style}
                  {...column.getHeaderProps()}
                  className="table-header-cell"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {tbodyContent}
      </table>

      {
        (pageCount > pageIndex) && (
          <Pagination
            currentPage={pageIndex + 1}
            totalPages={pageCount}
            onChange={(p) => gotoPage(p ? Number(p) - 1 : 0)}
            pageSize={pageSize}
            onPageSizeChange={(size) => setPageSize(size)}
          />
        )
      }
    </>
  );
}

ReactTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.shape({
    page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sizePerPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  noDataContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onRowClick: PropTypes.func,
};

export default ReactTable;
