import React from 'react';
import {
  Button, Modal, Form, Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ModalTitle, ButtonContainer } from '../../StyledComponents';
import FormRadioGroup from '../../FormComponents/RadioGroup';
import UniversalControl from '../../FormComponents/UniversalControl';
import Toggle from '../../FormComponents/Toggle';
import DatesPicker from '../../DatesPicker';
import rfqFilterNameValidator from './validators';
import getMultiSelectPlaceholder from '../../../utils/getMultiSelectPlaceholder';
import { filterStatusExceptions } from '../../../constants';
import { rfqFilterType, rfqFilterStatus } from '../../../constants/index';

const TextContainer = styled.p`
  text-align: center;
`;
const FilterIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  font-size: 20px;
`;
const InputContainer = styled(Form.Group)`
  input, select {
    height: 38px;
  }
`;

function initialFilterObj(isCompany) {
  return {
    filter: {
      filter_name: '',
      filter_type: isCompany ? 'sales' : 'purchases',
      filter_status: 'private',
      ata_chapter: '',
      agent: null,
      priority: null,
      deal_type: null,
      order_status: null,
      order_increment_id: '',
      buyer_id: null,
      buyer_company_type: null,
      seller_id: null,
      seller_company_type: null,
      date_from: null,
      date_to: null,
      replies: null,
      quote_position: null,
      enabled: true,
    },
    nameValidation: null,
  };
}

export default class FilterModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.filterMemory = {
      sales: null,
      purchases: null,
      autopilot: null,
    };
    // eslint-disable-next-line react/state-in-constructor
    this.state = initialFilterObj(props.isCompany);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editData && state.filter.filter_id !== props.editData.filter_id) {
      return {
        filter: props.editData,
        nameValidation: null,
      };
    }
    return null;
  }

  handleChange = (selectedOption, action) => {
    this.setState((prevState) => {
      const newData = { ...prevState.filter };
      newData[action.name] = selectedOption;
      if (action.name === 'order_status' && newData.filter_type === 'sales' && selectedOption.length > 0
      && selectedOption.every((v) => filterStatusExceptions.sales.some((v1) => v1 === v.value))) {
        newData.disableToggle = true;
      } else if (action.name === 'order_status' && newData.filter_type === 'purchases' && selectedOption.length > 0
      && selectedOption.every((v) => filterStatusExceptions.purchases.some((v1) => v1 === v.value))) {
        newData.disableToggle = true;
      } else newData.disableToggle = false;
      return { filter: newData };
    });
  }

  onChange = (e) => {
    const { isCompany } = this.props;
    const { filter, nameValidation: nameValidationSt } = this.state;
    const { value, name, checked } = e.target;
    const nameValidation = (name === 'filter_name')
      ? rfqFilterNameValidator(value)
      : nameValidationSt;
    if (name === 'filter_type') {
      let newData;
      if (this.filterMemory[value] === null) {
        newData = initialFilterObj(isCompany);
      } else {
        newData = this.filterMemory[value];
      }
      this.filterMemory[filter.filter_type] = this.state;
      newData.filter[name] = value;

      this.setState({ ...newData });
    } else {
      this.setState((prevState) => {
        const newData = { ...prevState.filter };
        if (name === 'enabled') newData[name] = checked;
        else newData[name] = value;
        return { filter: newData, nameValidation };
      });
    }
  }

  onSaveHandler = () => {
    const { saveFilter, isCompany } = this.props;
    const { filter, nameValidation } = this.state;

    if (filter.filter_name === '') {
      this.setState({ nameValidation: rfqFilterNameValidator(filter.filter_name) });
    }
    if (nameValidation || filter.filter_name === '') return;

    saveFilter(filter, true);
    this.setState(initialFilterObj(isCompany));
    this.filterMemory = {
      sales: null,
      purchases: null,
      autopilot: null,
    };
  }

  onSaveAsHandler = () => {
    const { saveFilter, isCompany } = this.props;
    const { filter, nameValidation } = this.state;

    if (filter.filter_name === '') {
      this.setState({ nameValidation: rfqFilterNameValidator(filter.filter_name) });
    }
    if (nameValidation || filter.filter_name === '') return;

    saveFilter(filter);
    this.setState(initialFilterObj(isCompany));
    this.filterMemory = {
      sales: null,
      purchases: null,
      autopilot: null,
    };
  }

  onCalendarChange = ({ startDate, endDate }) => {
    this.setState((prevState) => {
      const newData = { ...prevState.filter };
      newData.date_from = startDate;
      newData.date_to = endDate;
      return { filter: newData };
    });
  }

  onHide = () => {
    const { onHide, isCompany } = this.props;
    onHide();
    this.setState(initialFilterObj(isCompany));
    this.filterMemory = {
      sales: null,
      purchases: null,
      autopilot: null,
    };
  }

  render() {
    const {
      onHide,
      editMode,
      editData,
      filterSelectOptions,
      saveFilter,
      isCompany,
      isAutopilotEnabled,
      ...other
    } = this.props;
    const {
      filter,
      filter: { filter_type: filterType, disableToggle },
      nameValidation,
    } = this.state;
    const STANDALONE = {
      value: -1,
      label: 'Standalone buyer',
    };
    const buyersCompanyType = [STANDALONE, ...filterSelectOptions.companyType];
    const title = editMode
      ? 'Edit Filter'
      : 'Add New Filter';
    const filterTypeRadio = getFilterTypeOptions(rfqFilterType, !isAutopilotEnabled);
    let orderStatus = null;
    if (filterType === rfqFilterType.SALES) orderStatus = filterSelectOptions.salesStatus;
    else if (filterType === rfqFilterType.PURCHASES) orderStatus = filterSelectOptions.purchasesStatus;
    else orderStatus = filterSelectOptions.autopilotStatus;

    let filterTypeHeader = null;
    if (editMode) {
      filterTypeHeader = (
        <TextContainer>
          Filter type:&nbsp;
          {filterTypeRadio.find((v) => v.value === filterType).labelText}
        </TextContainer>
      );
    } else {
      filterTypeHeader = (
        <>
          <TextContainer>Select a filter type:</TextContainer>
          <FormRadioGroup
            checkedValue={filterType}
            radioList={filterTypeRadio}
            onChange={this.onChange}
            name="filter_type"
            labelColumnWidth={3}
            disabled={!isCompany}
            inline
          />
        </>
      );
    }

    let filterFormContent = null;

    if (filterType === rfqFilterType.SALES) {
      filterFormContent = (
        <>
          <Form.Row>
            <InputContainer as={Col} sm={12}>
              <UniversalControl
                value={filter.order_increment_id || ''}
                name="order_increment_id"
                placeholder="Search order by part/order no."
                onChange={this.onChange}
                labelColumnWidth={0}
                controlColumnWidth={12}
              />
            </InputContainer>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.agent}
                name="agent"
                placeholder={getMultiSelectPlaceholder('Agent', filter.agent, filterSelectOptions.agent)}
                onChange={this.handleChange}
                options={filterSelectOptions.agent}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.priority}
                name="priority"
                placeholder={getMultiSelectPlaceholder('Priority', filter.priority, filterSelectOptions.demandPriority)}
                onChange={this.handleChange}
                options={filterSelectOptions.demandPriority}
                controlShouldRenderValue={false}
                isSearchable={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.order_status}
                placeholder={getMultiSelectPlaceholder('Status', filter.order_status, orderStatus)}
                name="order_status"
                onChange={this.handleChange}
                options={orderStatus}
                controlShouldRenderValue={false}
                isSearchable={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={12}>
              <DatesPicker
                startDate={filter.date_from}
                endDate={filter.date_to}
                onChange={this.onCalendarChange}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.deal_type}
                placeholder={getMultiSelectPlaceholder('Deal Type', filter.deal_type, filterSelectOptions.dealTypes)}
                name="deal_type"
                onChange={this.handleChange}
                options={filterSelectOptions.dealTypes}
                isSearchable={false}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.buyer_id}
                placeholder={getMultiSelectPlaceholder('Buyer', filter.buyer_id, filterSelectOptions.companies)}
                name="buyer_id"
                onChange={this.handleChange}
                options={filterSelectOptions.legalEntities}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isMulti
              />
            </Form.Group>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.buyer_company_type}
                placeholder={getMultiSelectPlaceholder('Buyer\'s Company Type', filter.buyer_company_type, buyersCompanyType)}
                name="buyer_company_type"
                onChange={this.handleChange}
                options={buyersCompanyType}
                controlShouldRenderValue={false}
                isSearchable={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <InputContainer as={Col} sm={6}>
              <UniversalControl
                name="ata_chapter"
                value={filter.ata_chapter || ''}
                placeholder="ATA chapter..."
                onChange={this.onChange}
                labelColumnWidth={0}
                controlColumnWidth={12}
              />
            </InputContainer>
            <Form.Group as={Col} sm={6}>
              <Select
                value={filter.quote_position}
                placeholder={getMultiSelectPlaceholder('Quote Position', filter.quote_position, filterSelectOptions.quotePositions)}
                name="quote_position"
                onChange={this.handleChange}
                options={filterSelectOptions.quotePositions}
                isSearchable={false}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <InputContainer as={Col} sm={12}>
              <UniversalControl
                value={filter.filter_name}
                labelColumnWidth={0}
                controlColumnWidth={12}
                placeholder="Filter Name"
                name="filter_name"
                onChange={this.onChange}
                defaultInputLength={30}
                {...nameValidation}
              />
            </InputContainer>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={6}>
              <Toggle
                checked={+filter.enabled === 1 && !disableToggle}
                labelText="Receive email notification"
                name="enabled"
                labelColumnWidth={9}
                controlColumnWidth={2}
                onChange={this.onChange}
              />
            </Form.Group>
            <InputContainer as={Col} sm={6}>
              <UniversalControl
                value={filter.filter_status}
                name="filter_status"
                as="select"
                type="text"
                labelColumnWidth={0}
                controlColumnWidth={12}
                optionsForSelect={getStatusOptionList(rfqFilterStatus)}
                onChange={this.onChange}
              />
            </InputContainer>
          </Form.Row>
        </>
      );
    } else if (filterType === rfqFilterType.PURCHASES) {
      filterFormContent = (
        <>
          <Form.Row>
            <InputContainer as={Col} sm={12}>
              <UniversalControl
                value={filter.order_increment_id || ''}
                name="order_increment_id"
                placeholder="Search order by part/order no."
                onChange={this.onChange}
                labelColumnWidth={0}
                controlColumnWidth={12}
              />
            </InputContainer>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.agent}
                name="agent"
                placeholder={getMultiSelectPlaceholder('Agent', filter.agent, filterSelectOptions.agent)}
                onChange={this.handleChange}
                options={filterSelectOptions.agent}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.priority}
                name="priority"
                placeholder={getMultiSelectPlaceholder('Priority', filter.priority, filterSelectOptions.demandPriority)}
                onChange={this.handleChange}
                options={filterSelectOptions.demandPriority}
                controlShouldRenderValue={false}
                isSearchable={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.order_status}
                placeholder={getMultiSelectPlaceholder('Status', filter.order_status, orderStatus)}
                name="order_status"
                onChange={this.handleChange}
                options={orderStatus}
                controlShouldRenderValue={false}
                isSearchable={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={12}>
              <DatesPicker
                startDate={filter.date_from}
                endDate={filter.date_to}
                onChange={this.onCalendarChange}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.deal_type}
                placeholder={getMultiSelectPlaceholder('Deal Type', filter.deal_type, filterSelectOptions.dealTypes)}
                name="deal_type"
                onChange={this.handleChange}
                options={filterSelectOptions.dealTypes}
                isSearchable={false}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.seller_id}
                placeholder={getMultiSelectPlaceholder('Seller', filter.seller_id, filterSelectOptions.companies)}
                name="seller_id"
                onChange={this.handleChange}
                options={filterSelectOptions.companies}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isMulti
              />
            </Form.Group>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.seller_company_type}
                placeholder={
                  getMultiSelectPlaceholder('Seller\'s Company Type', filter.seller_company_type, filterSelectOptions.companyType)
                }
                name="seller_company_type"
                onChange={this.handleChange}
                options={filterSelectOptions.companyType}
                controlShouldRenderValue={false}
                isSearchable={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <InputContainer as={Col} sm={12}>
              <UniversalControl
                name="ata_chapter"
                value={filter.ata_chapter || ''}
                placeholder="ATA chapter..."
                onChange={this.onChange}
                labelColumnWidth={0}
                controlColumnWidth={12}
              />
            </InputContainer>
          </Form.Row>

          <Form.Row>
            <InputContainer as={Col} sm={12}>
              <UniversalControl
                value={filter.filter_name}
                labelColumnWidth={0}
                controlColumnWidth={12}
                placeholder="Filter Name"
                name="filter_name"
                onChange={this.onChange}
                defaultInputLength={30}
                {...nameValidation}
              />
            </InputContainer>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={6}>
              <Toggle
                checked={+filter.enabled === 1 && !disableToggle}
                labelText="Receive email notification"
                name="enabled"
                labelColumnWidth={9}
                controlColumnWidth={2}
                onChange={this.onChange}
              />
            </Form.Group>
            <InputContainer as={Col} sm={6}>
              <UniversalControl
                value={filter.filter_status}
                name="filter_status"
                as="select"
                type="text"
                labelColumnWidth={0}
                controlColumnWidth={12}
                optionsForSelect={getStatusOptionList(rfqFilterStatus)}
                onChange={this.onChange}
              />
            </InputContainer>
          </Form.Row>
        </>
      );
    } else if (filterType === rfqFilterType.AUTOPILOT) {
      filterFormContent = (
        <>
          <Form.Row>
            <InputContainer as={Col} sm={12}>
              <UniversalControl
                value={filter.order_increment_id || ''}
                name="order_increment_id"
                placeholder="Search demand by part / internal number"
                onChange={this.onChange}
                labelColumnWidth={0}
                controlColumnWidth={12}
              />
            </InputContainer>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.order_status}
                placeholder={getMultiSelectPlaceholder('Status', filter.order_status, orderStatus)}
                name="order_status"
                onChange={this.handleChange}
                options={orderStatus}
                controlShouldRenderValue={false}
                isSearchable={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.priority}
                name="priority"
                placeholder={getMultiSelectPlaceholder('Priority', filter.priority, filterSelectOptions.demandPriority)}
                onChange={this.handleChange}
                options={filterSelectOptions.demandPriority}
                controlShouldRenderValue={false}
                isSearchable={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
            <Form.Group as={Col} sm={4}>
              <Select
                value={filter.replies}
                name="replies"
                placeholder="Replies (All)"
                onChange={this.handleChange}
                options={filterSelectOptions.replies}
                hideSelectedOptions={false}
                isClearable
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={12}>
              <DatesPicker
                startDate={filter.date_from}
                endDate={filter.date_to}
                onChange={this.onCalendarChange}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={6}>
              <Select
                value={filter.agent}
                name="agent"
                placeholder={getMultiSelectPlaceholder('Agent', filter.agent, filterSelectOptions.agent)}
                onChange={this.handleChange}
                options={filterSelectOptions.agent}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </Form.Group>
            <InputContainer as={Col} sm={6}>
              <UniversalControl
                name="ata_chapter"
                value={filter.ata_chapter || ''}
                placeholder="ATA chapter..."
                onChange={this.onChange}
                labelColumnWidth={0}
                controlColumnWidth={12}
              />
            </InputContainer>
          </Form.Row>

          <Form.Row>
            <InputContainer as={Col} sm={6}>
              <UniversalControl
                value={filter.filter_name}
                labelColumnWidth={0}
                controlColumnWidth={12}
                placeholder="Filter Name"
                name="filter_name"
                onChange={this.onChange}
                defaultInputLength={30}
                {...nameValidation}
              />
            </InputContainer>
            <InputContainer as={Col} sm={6}>
              <UniversalControl
                value={filter.filter_status}
                name="filter_status"
                as="select"
                type="text"
                labelColumnWidth={0}
                controlColumnWidth={12}
                optionsForSelect={getStatusOptionList(rfqFilterStatus)}
                onChange={this.onChange}
              />
            </InputContainer>
          </Form.Row>
        </>
      );
    }

    return (
      <Modal centered onHide={this.onHide} {...other}>
        <Modal.Header>
          <ModalTitle>
            <FilterIcon icon="filter" />
            {title}
          </ModalTitle>
        </Modal.Header>

        <Modal.Body>
          {filterTypeHeader}
          <Form>{filterFormContent}</Form>
        </Modal.Body>

        <Modal.Footer>
          <ButtonContainer btnWidth="120px" space="10px">
            { editMode ? <Button variant="outline-primary" onClick={this.onSaveHandler}>Save Changes</Button> : null }
            <Button variant="primary" onClick={this.onSaveAsHandler}>Save Filter As</Button>
            <Button variant="outline-secondary" onClick={this.onHide}>Cancel</Button>
          </ButtonContainer>
        </Modal.Footer>
      </Modal>
    );
  }
}

FilterModal.propTypes = {
  editData: PropTypes.shape({
    filter_id: PropTypes.string,
  }),
  editMode: PropTypes.bool,
  filterSelectOptions: PropTypes.shape({
    agent: PropTypes.arrayOf(PropTypes.object),
    companies: PropTypes.arrayOf(PropTypes.object),
    legalEntitites: PropTypes.arrayOf(PropTypes.object),
    salesStatus: PropTypes.arrayOf(PropTypes.object),
    purchasesStatus: PropTypes.arrayOf(PropTypes.object),
    companyType: PropTypes.arrayOf(PropTypes.object),
    rfqFilterStatus: PropTypes.arrayOf(PropTypes.object),
    dealTypes: PropTypes.arrayOf(PropTypes.object),
    demandPriority: PropTypes.arrayOf(PropTypes.object),
    legalEntities: PropTypes.arrayOf(PropTypes.object),
    replies: PropTypes.arrayOf(PropTypes.object),
    autopilotStatus: PropTypes.arrayOf(PropTypes.object),
    quotePositions: PropTypes.arrayOf(PropTypes.object),
  }),
  isCompany: PropTypes.bool,
  isAutopilotEnabled: PropTypes.bool,
  onHide: PropTypes.func,
  onCancel: PropTypes.func,
  saveFilter: PropTypes.func,
};

function getFilterTypeOptions(consts, autopilotDisabled) {
  const options = [];
  options.push({
    value: consts.SALES,
    labelText: consts.SALES.charAt(0).toUpperCase() + consts.SALES.slice(1),
  });
  options.push({
    value: consts.PURCHASES,
    labelText: consts.PURCHASES.charAt(0).toUpperCase() + consts.PURCHASES.slice(1),
  }, {
    value: consts.AUTOPILOT,
    labelText: consts.AUTOPILOT.charAt(0).toUpperCase() + consts.AUTOPILOT.slice(1),
    disabled: autopilotDisabled,
  });

  return options;
}

const getStatusOptionList = (constant) => {
  if (!constant) return null;
  return [
    [constant.PRIVATE, 'Classification (Private)'],
    [constant.PUBLIC, 'Classification (Public)'],
  ];
};
